import {
  AppRouteGuardContext,
  AppRouteGuardError,
} from '_01_CORE/app-core/app-router';
import { appRoutesBuilder } from '../appRoutesBuilder.service';

export const redirectToDefaultRoute = (source: string) => {
  return (context: AppRouteGuardContext) => {
    const whiteLabel = context?.appSettings?.universe?.whiteLabel;

    throw new AppRouteGuardError({
      redirectToUrl: appRoutesBuilder.getDefaultUrl({
        appCacheContext: context.appCacheContext,
        whiteLabel,
      }),
    });
  };
};
