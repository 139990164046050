import { appLogger } from 'lib-web-logger';
import {
  AppRouteGuard,
  AppRouteGuardContext,
  AppRouteGuardError,
} from '_01_CORE/app-core/app-router';
import { authenticationStore } from '_01_CORE/app-core/app-security';
export const testRedirectFromOldVersion: AppRouteGuard = async (
  context: AppRouteGuardContext
) => {
  // const oldVersion = await authenticationStore.redirectToOldVersionPage
  //   .get()
  //   .toPromise();
  const oldVersion = await authenticationStore.redirectToOldVersionPage.getSnapshot();
  if (!oldVersion) {
    appLogger.warn(
      '[testRedirectFromOldVersion] NOT too old version: redirect to home'
    );
    throw new AppRouteGuardError({ redirectToUrl: '/' });
  }
  return context; // ok
};
