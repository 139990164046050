import { authApiClient } from '_01_CORE/app-core/app-api';
import { persistentCache } from '_01_CORE/app-core/app-platform';
import * as ky from 'ky';
import {
  AppSecurityUser,
  AuthenticationResult,
  AuthUserProfile,
  BUProfile,
  CMProfile,
  CURRENT_JWT_TOKEN_VERSION,
  CustomerMobileAuthenticationResult,
} from 'lib-common-model';
import {
  ApiClientRequestOverrideConfig,
  apiClientStoreProvider,
} from 'lib-web-api-client';
import { appLogger } from 'lib-web-logger';
import { tokenParser } from 'lib-web-security';
import { JWT_TOKEN_BROWSER_STORAGE_ID } from '../../app-core/app-bootstrap/JWT_TOKEN_BROWSER_STORAGE_ID.const';
import { AuthenticationClientError } from './AuthenticationClientError.type';
import { authenticationStore } from './authenticationStore.service';
import { AppAuth } from './model/AppAuth.model';

export const authenticationClient = {
  authenticateByLoginPassword,
  authenticateByActivationToken,
  authenticateByToken,
  clearAppAuth,
  logout,
  refreshToken,
  setAppAuth,
  updateTokenAndProfile,
};

async function refreshToken({
  overrideConfig,
}: {
  overrideConfig?: ApiClientRequestOverrideConfig;
} = {}): Promise<CustomerMobileAuthenticationResult> {
  try {
    const response = await authApiClient.refreshToken({ overrideConfig });
    return updateTokenAndProfile(response);
  } catch (err) {
    const httpError = err as ky.HTTPError;
    if (httpError.response) {
      const authenticationClientError: AuthenticationClientError = {
        message: 'Authentication denied',
        originalMessage: httpError.message,
        status: httpError.response.status,
      };
      throw authenticationClientError;
    } else {
      const authenticationClientError: AuthenticationClientError = {
        message: 'Http network error',
        originalMessage: httpError.message,
        status: undefined,
      };
      throw authenticationClientError;
    }
  }
}

async function updateTokenAndProfile(
  response: CustomerMobileAuthenticationResult
): Promise<AuthenticationResult<'multi-users-v2', CMProfile | BUProfile>> {
  if (response && response.token) {
    try {
      await setAppAuthToken(response.token, response.profile);
      await persistentCache.set(JWT_TOKEN_BROWSER_STORAGE_ID, response.token, {
        ignoreError: true,
      });
      return response;
    } catch (err) {
      console.error('[updateTokenAndProfile] err:', err);
      const authenticationClientError: AuthenticationClientError = {
        message: 'Invalid token',
        originalMessage: undefined,
        status: undefined,
      };
      throw authenticationClientError;
    }
  }
  const authenticationClientError: AuthenticationClientError = {
    message: 'Invalid response from server',
    originalMessage: undefined,
    status: undefined,
  };
  throw authenticationClientError;
}

async function authenticateByToken(
  token: string,
  expectedProfiles: AuthUserProfile[],
  {
    context,
    overrideConfig,
  }: { context: string; overrideConfig?: ApiClientRequestOverrideConfig }
): Promise<CustomerMobileAuthenticationResult> {
  if (token) {
    appLogger.warn(
      `[authenticationClient.authenticateByToken: token] (${context})`
    );
    const payload = await setAppAuthToken(token, undefined);
    if (payload && expectedProfiles.includes(payload.profile)) {
      appLogger.warn(
        `[authenticationClient.authenticateByToken: refreshToken] (${context})`,
        payload.roles
      );
      try {
        const auth = await authenticationClient.refreshToken({
          overrideConfig,
        });
        appLogger.warn('[authenticationClient.authenticateByToken] success');
        return auth;
      } catch (err) {
        const authError = err as AuthenticationClientError;
        appLogger.warn('[authenticationClient.authenticateByToken] error', err);
        if (err && authError.status === 401) {
          appLogger.warn(
            '[authenticationClient.authenticateByToken] security error: logout'
          );
          authenticationStore.logoutRequired.set(true);
        }
        throw err;
      }
    }

    appLogger.warn(
      '[authenticationClient.authenticateByToken] unexpected roles',
      { roles: payload.roles, expectedProfiles }
    );
    throw new Error('Invalid token: unexpected roles');
  }
  throw new Error('Invalid token: empty');
}

async function authenticateByLoginPassword({
  login,
  password,
}: {
  login: string;
  password: string;
}): Promise<CustomerMobileAuthenticationResult> {
  try {
    const response = await authApiClient.authenticateByLoginPassword({
      login,
      password,
    });
    if (response && response.token) {
      await setAppAuthToken(response.token, response.profile);
      await persistentCache.set(JWT_TOKEN_BROWSER_STORAGE_ID, response.token, {
        ignoreError: true,
      });
      return response;
    }
    // invalid response
    appLogger.warn(
      '[authenticationClient.authenticateByLoginPassword] Invalid response from server',
      response
    );
    throw new Error('Invalid response from server');
  } catch (err) {
    appLogger.warn(
      '[authenticationClient.authenticateByLoginPassword] Authentication denied',
      err
    );
    throw err;
  }
}
async function authenticateByActivationToken({
  email,
  activationToken,
}: {
  email: string;
  activationToken: string;
}): Promise<CustomerMobileAuthenticationResult> {
  try {
    const response = await authApiClient.authenticateByActivationToken({
      email,
      activationToken,
    });
    if (response && response.token) {
      await setAppAuthToken(response.token, response.profile);
      await persistentCache.set(JWT_TOKEN_BROWSER_STORAGE_ID, response.token, {
        ignoreError: true,
      });
      return response;
    }
    // invalid response
    appLogger.warn(
      '[authenticationClient.authenticateByActivationToken] Invalid response from server',
      response
    );
    throw new Error('Invalid response from server');
  } catch (err) {
    appLogger.warn(
      '[authenticationClient.authenticateByActivationToken] Authentication denied',
      err
    );
    throw err;
  }
}

async function logout() {
  appLogger.info('[authenticationClient.logout]');
  await persistentCache.remove(JWT_TOKEN_BROWSER_STORAGE_ID, {
    ignoreError: true,
  });
  clearAppAuth();
}

function clearAppAuth() {
  setAppAuth(
    {
      token: undefined,
      appAuth: undefined,
    },
    'clearAppAuth'
  );
}

async function setAppAuthToken(
  token: string,
  profile: CMProfile | BUProfile
): Promise<AppSecurityUser> {
  const payload = tokenParser.parse(token, profile);
  appLogger.debug('[authenticationClient.setAppAuthToken] payload: ', payload);

  const securityUser = tokenParser.parse(token, profile);

  if (
    !securityUser?.tokenInfo ||
    securityUser?.tokenInfo?.version !== CURRENT_JWT_TOKEN_VERSION
  ) {
    appLogger.warn(
      '[authenticationClient.setAppAuthToken] invalid token version: ',
      securityUser
    );
    clearAppAuth();
    throw new Error('Invalid token version');
  }

  if (securityUser.tokenInfo.expirationDate.getTime() < new Date().getTime()) {
    appLogger.warn('[authenticationClient.setAppAuthToken] token expired');
    clearAppAuth();
    throw new Error('Expired token');
  }

  const appAuth = buildAppAuthFromPayload({ securityUser, profile });

  setAppAuth({ appAuth, token }, 'authenticate');

  return securityUser;
}

function setAppAuth(
  {
    appAuth,
    token,
  }: {
    appAuth?: AppAuth;
    token: string;
  },
  actionId: string
): void {
  appLogger.debug(
    `[authenticationClient.setAppAuth] appAuth (actionId=${actionId}): `,
    appAuth
  );

  const apiClientStore = apiClientStoreProvider.get();

  apiClientStore.authenticationToken.set(token, actionId);
  if (appAuth) {
    authenticationStore.auth.set(appAuth, actionId);
    authenticationStore.logoutRequired.set(false);
  } else {
    const auth: AppAuth = {
      isAuthenticated: false,
    };
    authenticationStore.auth.set(auth, actionId);
    authenticationStore.logoutRequired.set(false);
  }

  return;
}

function buildAppAuthFromPayload({
  securityUser,
  profile,
}: {
  securityUser: AppSecurityUser;
  profile: CMProfile | BUProfile;
}): AppAuth {
  const isSuperAdmin =
    securityUser.impersonate?.root?.profile === 'super-admin';

  const auth: AppAuth = {
    appId: securityUser.appId,
    isAuthenticated: true,
    securityUser,
    businessProfile:
      securityUser.profile === 'company' ? (profile as BUProfile) : undefined,
    isSuperAdmin,
    customerProfile:
      securityUser.profile === 'customer' ? (profile as CMProfile) : undefined,
  };
  return auth;
}

// function buildAppAuthFromPayload({
//   payload,
//   profile,
// }: {
//   payload: JwtTokenPayLoadFull;
//   profile: CMProfile;
// }): AppAuth {
//   const userType = buildUserTypeFromRoles(payload.roles);
//   const realUserType = buildUserTypeFromRoles(payload.realUser?.roles);
//   const isSuperAdmin =
//     userType === 'super-admin' || realUserType === 'super-admin';
//   const auth: AppAuth = {
//     isAuthenticated: true,
//     payload,
//     customerProfile: userType === 'customer' ? profile : undefined,
//     businessProfile:
//       userType === 'business-anim' ? (profile as any) : undefined,
//     userType,
//     realUserType,
//     isSuperAdmin,
//     // NOTE: anim can be connected directly from planning to create a new customer, so userType is defined then
//     isAnyAdmin:
//       isSuperAdmin ||
//       realUserType === 'business-anim' ||
//       userType === 'business-anim',
//   };
//   return auth;
// }
// function buildUserTypeFromRoles(roles: any): UserType {
//   if (!roles) {
//     return undefined;
//   }
//   return roles.includes('admin')
//     ? 'super-admin'
//     : roles.includes('anim') || roles.includes('business')
//     ? 'business-anim'
//     : 'customer';
// }
