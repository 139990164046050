import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import { CustomerJourneyActivitiesPageUrlParams } from '_02_APP_COMMON/ACT-activity';
import { AppClientNotification } from 'lib-common-model';

export const notificationTargetUrlBuilder = {
  buildActivityUrl,
};

export function buildActivityUrl(notification: AppClientNotification): string {
  const activity = notification?.activity;
  const activitySession = notification?.activitySession;
  const customerJourneyId = notification?.customerJourneyId;

  if (customerJourneyId && activity && activitySession) {
    const params: CustomerJourneyActivitiesPageUrlParams = {
      category: undefined,
      selectedDate: activitySession.beginDateTime,
      focusActivitySessionId: activitySession._id,
      focusActivityId: activity._id,
      focusActivityOpenDialog: true,
    };

    const url = appRoutesBuilder.getJourneyActivitiesWithParamsUrl({
      customerJourneyId,
      params,
    });
    return url;
  }
}
