import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { DayComponentProps } from './DayComponentProps.type';

type ViewState = 'date' | 'month' | 'year';

interface MonthYear {
  month: number;
  year: number;
}

interface AppDatePickerContextType {
  date: Date | null;
  visible: MonthYear;
  view: ViewState;
  dateFormat: string;
  placeholder: string;
  nextMonth: () => void;
  prevMonth: () => void;
  nextYear: () => void;
  prevYear: () => void;
  nextDecade: () => void;
  prevDecade: () => void;
  selectMonth: (m: number) => void;
  selectYear: (y: number) => void;
  selectDate: (d: number) => void;
  viewMonths: () => void;
  viewYears: () => void;
  isVisible: boolean;
  showCalendar: () => void;
  toggleCalendar: () => void;
  getDayComponent: (props: DayComponentProps) => JSX.Element;
  getLegendComponent?: () => JSX.Element;
  isSelectedDate: (d: number) => boolean;
  isDisabledDate: (d: number) => boolean;
}

export const AppDatePickerCtx = createContext<AppDatePickerContextType>({
  date: new Date(),
  visible: {
    month: 0,
    year: 1970,
  },
  view: 'date',
  dateFormat: '',
  placeholder: '',
  nextMonth: () => {
    //
  },
  prevMonth: () => {
    //
  },
  nextYear: () => {
    //
  },
  prevYear: () => {
    //
  },
  nextDecade: () => {
    //
  },
  prevDecade: () => {
    //
  },
  selectMonth: () => {
    //
  },
  selectYear: () => {
    //
  },
  selectDate: () => {
    //
  },
  viewMonths: () => {
    //
  },
  viewYears: () => {
    //
  },
  isVisible: false,
  showCalendar: () => {
    //
  },
  toggleCalendar: () => {
    //
  },
  getDayComponent: function getDayComponent() {
    return <></>;
  },
  getLegendComponent: function getLegend() {
    return <></>;
  },
  isSelectedDate: () => false,
  isDisabledDate: () => false,
});

export type AppDatePickerCtxProps = {
  value?: Date;
  defaultView?: ViewState;
  onChange: (d: Date) => void;
  ref?: React.MutableRefObject<HTMLElement | undefined>;
  minDate?: Date;
  maxDate?: Date;
  dateFormat?: string;
  placeholder?: string;
  dayComponent?: (props: DayComponentProps) => JSX.Element;
  legendComponent?: () => JSX.Element;
};

export function useDatePickerCtx(
  props: AppDatePickerCtxProps
): AppDatePickerContextType {
  const {
    value,
    onChange,
    ref,
    dayComponent,
    minDate,
    maxDate,
    legendComponent,
  } = props;
  const { formatMessage: t } = useIntl();

  let date = new Date(value);

  if (isNaN(date.getTime())) {
    date = null;
  }

  // const realDate = useMemo<Date>(() => (date ? new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())) : null), [date]);
  const realMinDate = useMemo<Date>(
    () => (minDate ? new Date(minDate) : null),
    [minDate]
  );
  const realMaxDate = useMemo<Date>(
    () => (maxDate ? new Date(maxDate) : null),
    [maxDate]
  );

  const dateFormat = props.dateFormat ?? t({ id: 'common.date.format' });
  const placeholder = props.placeholder ?? 'Date';

  const [monthYear, setMonthYear] = useState<MonthYear>({
    month: date ? date.getMonth() : new Date().getMonth(),
    year: date ? date.getFullYear() : new Date().getFullYear(),
  });

  const [view, setView] = useState<ViewState>(
    props.defaultView ? props.defaultView : 'date'
  );

  const [isVisible, setVisible] = useState<boolean>(false);

  const selectDate = (d: number) => {
    onChange?.(new Date(Date.UTC(monthYear.year, monthYear.month, d)));
    setVisible(false);
  };

  const isSelectedDate = (d: number): boolean => {
    const cDate = date ?? new Date();
    if (
      d === cDate.getDate() &&
      monthYear.month === cDate.getMonth() &&
      monthYear.year === cDate.getFullYear()
    ) {
      return true;
    }
    return false;
  };

  const isDisabledDate = (d: number): boolean => {
    const dateChecked = new Date(monthYear.year, monthYear.month, d);
    return (
      (minDate && dateChecked < realMinDate) ||
      (maxDate && dateChecked > realMaxDate)
    );
  };

  const selectMonth = (m: number) => {
    setMonthYear((state) => ({ ...state, month: m }));
    setView('date');
  };

  const selectYear = (y: number) => {
    setMonthYear((state) => ({ ...state, year: y }));
    setView('month');
  };

  const getDayComponent = (props: DayComponentProps) => {
    const { day, isSelected, isDisabled, isToday, onClick } = props;
    if (dayComponent) {
      return <span key={`day${day}`}>{dayComponent(props)}</span>;
    }

    let classes =
      'flex justify-self-center place-self-center text-center rounded-full p-1 text-sm w-8 h-auto justify-center m-1';
    if (isSelected) {
      classes += ' border-2 border-red-500';
    }
    if (isDisabled) {
      classes += ' text-gray-300';
    } else {
      classes += ' cursor-pointer hover:bg-gray-200';
    }
    if (isToday) {
      classes += ' font-bold';
    }
    return (
      <div
        key={`day${day}`}
        className={classes}
        onClick={isDisabled ? undefined : onClick}
      >
        {day}
      </div>
    );
  };

  const getLegendComponent = () => (legendComponent ? legendComponent() : null);

  useEffect(() => {
    function mouseDownListener(e: MouseEvent) {
      if (ref && ref.current && !ref.current.contains(e.target as Node)) {
        setVisible(false);
      }
    }

    if (isVisible) {
      const cDate = date ?? new Date();
      setMonthYear({ month: cDate.getMonth(), year: cDate.getFullYear() });
      ref && document.addEventListener('mousedown', mouseDownListener);
    }

    return () => {
      ref && document.removeEventListener('mousedown', mouseDownListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return {
    // date: realDate,
    date,
    visible: monthYear,
    dateFormat,
    placeholder,
    view,
    nextMonth: () =>
      setMonthYear((state) =>
        state.month >= 11
          ? { month: 0, year: state.year + 1 }
          : { month: state.month + 1, year: state.year }
      ),
    prevMonth: () =>
      setMonthYear((state) =>
        state.month <= 0
          ? { month: 11, year: state.year - 1 }
          : { month: state.month - 1, year: state.year }
      ),
    nextYear: () =>
      setMonthYear((state) => ({ ...state, year: state.year + 1 })),
    prevYear: () =>
      setMonthYear((state) => ({ ...state, year: state.year - 1 })),
    nextDecade: () =>
      setMonthYear((state) => ({ ...state, year: state.year + 12 })),
    prevDecade: () =>
      setMonthYear((state) => ({ ...state, year: state.year - 12 })),
    selectMonth,
    selectYear,
    selectDate,
    viewMonths: () => setView('month'),
    viewYears: () => setView('year'),
    isVisible,
    showCalendar: () => setVisible(true),
    toggleCalendar: () => setVisible((state) => !state),
    isSelectedDate,
    isDisabledDate,
    getDayComponent,
    getLegendComponent,
  };
}
