import { Transition } from '@headlessui/react';
import { useOutsideClick } from '_01_CORE/_hooks';
import clsx from 'clsx';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { HiCheck, HiSelector } from 'react-icons/hi';
import { AppIcons } from '../icons';
import { AppSelectOption } from './AppSelectOption.type';
import { AppSelectProps } from './AppSelectProps.type';

export function AppSelect<T extends string>({
  className,
  label,
  placeholder,
  elements,
  onChange,
  value,
  required,
  maxWidth,
  width,
  disableClearButton,
  btnClassName,
}: AppSelectProps<T>) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const initialSelected = useMemo<AppSelectOption<T>>(() => {
    return elements.find((v) => v.id === value);
  }, [value, elements]);

  const [selected, setSelected] = useState<AppSelectOption<T>>(initialSelected);

  const refWrapper = useRef<HTMLDivElement>(null);
  useOutsideClick({ ref: refWrapper, callback: () => setIsOpen(false) });

  // const selected = useMemo<AppSelectOption<T>>(() => {
  //   return selectedIndex !== undefined
  //     ? elements[selectedIndex]
  //     : elements.find((v) => v.id === value);
  // }, [value, elements, selectedIndex]);

  // const index = useMemo(
  //   () => elements.findIndex((v) => v.id === selected?.id),
  //   [elements, selected?.id]
  // );

  useEffect(() => {
    if (initialSelected?.id !== selected?.id) {
      setSelected(initialSelected);
    }
  }, [initialSelected, selected?.id]);

  const onSelect = useCallback(
    (option: AppSelectOption<T>) => {
      setSelected(option);
      setIsOpen(false);
      onChange?.(option?.id);
    },
    [onChange]
  );
  const mwClass = maxWidth ? `max-w-${maxWidth}` : '';
  const wClass = width ? `w-${width} ` : 'w-full';

  return (
    <div ref={refWrapper} className={`${className}`}>
      {label && (
        <label className="mb-1 text-gray-500 font-bold text-xs block text-left">
          {label} {required && <span className="text-red-400">*</span>}
        </label>
      )}
      <button
        type="button"
        aria-haspopup="listbox"
        aria-expanded="true"
        aria-labelledby="listbox-label"
        className={`app--border--important bg-white cursor-pointer relative ${wClass} ${btnClassName} ${mwClass} border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left focus:outline-none focus:border-primary sm:text-sm`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex gap-1">
          {!disableClearButton && (
            <div className={`${value ? 'inline-block' : 'hidden'} pr-2`}>
              <AppIcons.actionCancel
                className={
                  'inline-block h-6 w-6 text-gray-300 hover:text-gray-600'
                }
                onClick={(e) => {
                  onChange(null);
                  // e.preventDefault();
                  e.stopPropagation();
                }}
              />
            </div>
          )}

          <span className="block truncate app-theme-anim-live-primary font-bold">
            {selected ? selected.label : placeholder}
            {!(selected ? selected.label : placeholder) && (
              <span className="invisible">I</span>
            )}
          </span>

          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <HiSelector className="h-5 w-5 text-gray-400" />
          </span>
        </div>
      </button>
      <Transition
        show={isOpen}
        enter="transition ease-in duration-100"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className={clsx(
          `absolute mt-1 text-left rounded-md bg-white shadow-lg z-10`,
          'app--w-[90vw] sm:w-2/3 md:w-1/2' // ajouté dans tailwind.scss (car on est encore avec tailwind v2 ici)
        )}
      >
        {() => (
          <div className="z-10">
            <ul
              tabIndex={-1}
              role="listbox"
              //   aria-labelledby="listbox-label"
              //   aria-activedescendant="listbox-item-3"
              className="rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
              style={{ maxHeight: '90vh' }}
            >
              {elements.map((option, i) => {
                const isSelected = option.id === value;

                return (
                  <li
                    key={option.id}
                    id={`listbox-option-${i}`}
                    role="option"
                    className={`text-gray-900 cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-gray-300`}
                    onClick={() => onSelect(option)}
                  >
                    <div
                      className={`${
                        isSelected ? 'font-semibold' : 'font-normal'
                      } block truncate`}
                    >
                      {option.label}
                      {!option.comment ? null : (
                        <div className="text-xs text-secondary">
                          {option.comment}
                        </div>
                      )}
                      {!option.label && !option.comment && (
                        <span className="invisible">I</span>
                      )}
                    </div>

                    {!isSelected ? null : (
                      <span
                        className={`app-theme-anim-live-primary absolute inset-y-0 right-0 flex items-center pr-4`}
                      >
                        <HiCheck className="h-5 w-5" />
                      </span>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </Transition>
    </div>
  );
}
