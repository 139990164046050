import './BannerList.css';
import React from 'react';

export const BannerList: React.FC = ({ children }) => {
  return (
    <div className="banner-list-content">
      <div className="infos-area">{children}</div>
    </div>
  );
};
