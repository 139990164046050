import { useAppRouter } from '_01_CORE/_hooks';
import { useAppCacheContext } from '_01_CORE/app-contexts';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import { NotificationMessageDetailsDialog } from '_02_APP_COMMON/NOT-notifications/NOT-06-notifications-list';
import { AppCustomerRoutesAccropark } from '_03_ACCROPARK/AppCustomerRoutesAccropark';
import { AppCustomerRoutesAnimLive } from '_04_ANIM_LIVE/AppCustomerRoutesAnimLive';
import React, { useEffect } from 'react';

export const AppRoutes: React.FC = () => {
  const appRouter = useAppRouter();

  const {
    loaded,
    dispatchCacheContextAction,
    activitySessionsToDisplay,
    notificationToDisplay,
    redirectTo,
  } = useAppCacheContext();

  useEffect(() => {
    if (redirectTo?.path) {
      dispatchCacheContextAction({
        type: 'redirect-to',
        path: null,
        context: 'redirected',
      });
      appRouter.navigate(redirectTo?.path, {
        cause: `appContext.redirectTo (${redirectTo?.cause})`,
      });
    }
  }, [appRouter, dispatchCacheContextAction, redirectTo]);

  const appId = appWebConfig().appId;
  return (
    loaded && (
      <>
        {notificationToDisplay && (
          <NotificationMessageDetailsDialog
            notification={notificationToDisplay}
            onClose={() => {
              dispatchCacheContextAction({
                type: 'set-notification-to-display',
                notificationToDisplay: null,
                context: 'notification-dialog-closed',
              });
            }}
          />
        )}
        {appId === 'accropark' ? (
          <AppCustomerRoutesAccropark />
        ) : (
          <AppCustomerRoutesAnimLive />
        )}
      </>
    )
  );
};
