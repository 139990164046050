import { IonPage } from '@ionic/react';
import React, { Fragment } from 'react';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import { AppMenuItemId } from '../AppMenu/_model';
import { FooterToolbar } from '../FooterToolbar';

export const AppPage = ({
  children,
  displayFooter = true,
  tabId,
}: {
  children: React.ReactNode;
  displayFooter?: boolean;
  tabId?: AppMenuItemId;
}) => {
  const appId = appWebConfig().appId;
  return (
    <IonPage className={`max-w-5xl m-auto app-id-${appId}`}>
      <Fragment>{children}</Fragment>
      {displayFooter && <FooterToolbar tabId={tabId} />}
    </IonPage>
  );
};
