import { IonInput } from '@ionic/react';
import { calendarOutline } from 'ionicons/icons';
import React, { useEffect, useMemo, useState } from 'react';
import {
  AppInputIonic,
  dateTransformer,
} from '../../../../_01_CORE/_components-core';
import { dateServiceCore } from '../../../services';
import { AppHackableCalendarDialog } from '../../03-advanced/AppHackableCalendar';
import { AppInputDatePickerProps } from './AppInputDatePicker';

export const AppInputDatePickerIonics = ({
  placeholder,
  value: inputValue,
  onChange,
  minDate: minDateInput,
  maxDate: maxDateInput,
  minYear,
  maxYear,
  initialView,
  className,
}: AppInputDatePickerProps) => {
  const [open, setOpen] = useState(false);

  const value = useMemo(() => dateServiceCore.truncateTimeUTC(inputValue), [
    inputValue,
  ]);

  const minDate = useMemo(() => {
    if (minDateInput) {
      return dateServiceCore.truncateTimeUTC(minDateInput);
    } else if (minYear !== undefined && minYear !== null) {
      return new Date(Date.UTC(minYear, 0, 1));
    }
  }, [minDateInput, minYear]);

  const maxDate = useMemo(() => {
    if (maxDateInput) {
      return dateServiceCore.truncateTimeUTC(maxDateInput);
    } else if (maxYear !== undefined && maxYear !== null) {
      return new Date(Date.UTC(maxYear, 11, 31));
    }
  }, [maxDateInput, maxYear]);

  useEffect(() => {
    if (inputValue) {
      const d = new Date(inputValue);
      if (
        d.getUTCHours() ||
        d.getUTCMinutes() ||
        d.getUTCSeconds() ||
        d.getUTCMilliseconds()
      ) {
        // eslint-disable-next-line no-console
        console.info(
          '[AppInputDatePickerIonics] remove hours/mn/s/ms from input date',
          value
        );
        onChange && onChange(value);
      }
    }
    // trigger only if 'inputValue' changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  const [innerDate, setInnerDate] = useState(value);

  const [innerDateString, setInnerDateString] = useState(
    value ? dateTransformer.formatUTC(value, 'dd/MM/yyyy') : ''
  );

  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    if (value) {
      const str = dateTransformer.formatUTC(value, 'dd/MM/yyyy');
      if (str !== innerDateString) {
        setInnerDateString(str);
      }
    }
    // only trigger if "value" is updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const allowManualInput = true;

  return (
    <AppHackableCalendarDialog
      open={open}
      setOpen={setOpen}
      selectedDate={innerDate}
      minDate={minDate}
      maxDate={maxDate}
      initialView={initialView}
      onSelectDate={(date) => {
        setInnerDate(date);
        setInnerDateString(dateTransformer.formatUTC(date, 'dd/MM/yyyy'));
        onChange && onChange(date);
      }}
    >
      <div className={`relative  ${className}`}>
        <AppInputIonic
          className={`w-full pr-5`}
          placeholder={placeholder}
          value={innerDateString}
          required={true}
          icon={calendarOutline}
          onClickIcon={() => {
            setOpen(!open);
          }}
        >
          <IonInput
            autoCapitalize="on"
            onIonChange={(e) => {
              const inputDateString = e.detail.value;
              if (inputDateString?.trim().length) {
                const { date, isValid } = dateTransformer.parseUTCFromDDMMYYYY(
                  inputDateString,
                  {
                    minDate,
                    maxDate,
                  }
                );
                setIsValid(isValid);
                if (
                  isValid &&
                  dateTransformer.formatUTC(innerDate, 'dd/MM/yyyy') !==
                    inputDateString
                ) {
                  setInnerDate(date);
                  setInnerDateString(inputDateString);
                  onChange && onChange(date);
                } else {
                  setInnerDateString(inputDateString);
                }
              } else {
                setInnerDate(undefined);
                setInnerDateString('');
                onChange && onChange(null);
                setIsValid(false);
              }
            }}
            readonly={!allowManualInput}
            onIonBlur={() => {
              const inerDateValidString = dateTransformer.formatUTC(
                innerDate,
                'dd/MM/yyyy'
              );
              if (inerDateValidString !== innerDateString) {
                setInnerDateString(inerDateValidString);
                setIsValid(true);
              }
            }}
          />
        </AppInputIonic>

        {/* <CalendarIcon
          onClick={() => {
            setOpen(!open);
          }}
          className={`absolute z-1000 top-2 right-3 text-gray-400 hover:text-gray-600 w-7 h-7 cursor-pointer`}
        /> */}
      </div>
    </AppHackableCalendarDialog>
  );
};
