import { CMJourneyEditData, JourneyChildAllowedPeople } from 'lib-common-model';
import { useMemo } from 'react';
import { useAppCacheContext } from '_01_CORE/app-contexts';
import { JourneyCreateDetailsFormData } from './JourneyCreateDetailsFormData.type';

export const useJourneyCreateDetailsAnimLiveDefaultValues = () => {
  const { journeyEditData } = useAppCacheContext();

  const defaultValues = useMemo<Partial<JourneyCreateDetailsFormData>>(() => {
    const formData: JourneyCreateDetailsFormData = {
      journey: {
        arrivalTime: journeyEditData?.journey?.arrivalTime,
        comment: journeyEditData?.journey?.comment,
        immatriculation: journeyEditData?.journey?.immatriculation,
      },
      allowedPeople4Array: buildAllowedPeople4Array(journeyEditData),
    };
    return formData;
  }, [journeyEditData]);

  return defaultValues;
};

function buildAllowedPeople4Array(
  journeyEditData: CMJourneyEditData
): [
  JourneyChildAllowedPeople,
  JourneyChildAllowedPeople,
  JourneyChildAllowedPeople,
  JourneyChildAllowedPeople
] {
  const arr: JourneyChildAllowedPeople[] = (
    journeyEditData?.allowedPeopleList ?? []
  ).concat([]);
  while (arr.length < 4) {
    arr.push({
      name: undefined,
      relationShip: undefined,
    });
  }
  return (arr as unknown) as any;
}
