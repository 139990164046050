export const colorGenerator = {
  parseColor,
  isLight,
  darken,
  lighten,
  rgba,
};
function lighten(inputColor: string, ratio: number): string {
  return shadeColor(inputColor, ratio);
}
function darken(inputColor: string, ratio: number): string {
  return shadeColor(inputColor, -ratio);
}
function shadeColor(inputColor: string, ratio: number): string {
  // https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors
  const c = parseColor(inputColor);

  const amt = Math.round(ratio * 100);

  const r = Math.max(Math.min(255, c.r + amt), 0).toString(16);
  const g = Math.max(Math.min(255, c.g + amt), 0).toString(16);
  const b = Math.max(Math.min(255, c.b + amt), 0).toString(16);

  const rr = (r.length < 2 ? '0' : '') + r;
  const gg = (g.length < 2 ? '0' : '') + g;
  const bb = (b.length < 2 ? '0' : '') + b;

  return `#${rr}${gg}${bb}`;
}

function rgba(inputColor: string, alpha: number): string {
  const c = parseColor(inputColor);
  return `rgba(${c.r},${c.g},${c.b},${alpha})`;
}

function parseColor(
  inputColor: string
): {
  r: number;
  g: number;
  b: number;
} {
  let result: {
    r: number;
    g: number;
    b: number;
  };
  // Check the format of the color, HEX or RGB?
  if (inputColor.match(/^rgb/)) {
    // If RGB --> store the red, green, blue values in separate variables
    const chunks = inputColor.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    );

    result = {
      r: parseInt(chunks[1], 10),
      g: parseInt(chunks[2], 10),
      b: parseInt(chunks[3], 10),
    };
  } else {
    let col = inputColor.replace(/^#/, '');
    if (col.length === 3) {
      col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2];
    }
    const [r, g, b] = col.match(/.{2}/g);

    result = {
      r: parseInt(r, 16),
      g: parseInt(g, 16),
      b: parseInt(b, 16),
    };
  }
  return result;
}

function isLight(inputColor: string): boolean {
  if (!inputColor) {
    return false;
  }
  // https://awik.io/determine-color-bright-dark-using-javascript/
  const { r, g, b } = parseColor(inputColor);

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return true;
  } else {
    return false;
  }
}
