export const AppLoadingErrorPanel = () => {
  return (
    <div className="">
      <div
        className={`bg-red-200 p-4 text-left p-2 px-4 font-bold text-sm text-gray-600`}
      >
        <div className="flex-grow text-left">
          {`Erreur innatendue lors chargement des données`}
        </div>
      </div>
      <div className="p-4">
        <div className="my-2 text-gray-600">
          Veuillez vérifier votre connexion Internet et ré-essayer.
        </div>
        <div className="mt-10 text-left">
          <button
            className="w-full max-w-sm p-4 font-bold bg-white border-2 border-blue-400 shadow uppercase text-gray-600 hover:text-gray-800"
            onClick={() => {
              window.location.reload();
            }}
          >
            Rafraîchir la page
          </button>
        </div>
      </div>
    </div>
  );
};
