import { AppPriceLabel } from '_01_CORE/_common-layout';
import { dateTransformer } from '_01_CORE/_components-core';
import { useAppCacheContext } from '_01_CORE/app-contexts';
import {
  APP_CURRENCIES_MAP,
  Company,
  EcommerceBookingJourney,
} from 'lib-common-model';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { AccroparkBookingResumeTableOfferRow } from './AccroparkBookingResumeTableOfferRow';
import { AccroparkBookingResumeTablePaymentRow } from './AccroparkBookingResumeTablePaymentRow';

export const AccroparkBookingResumeTable = ({
  booking,
  company,
  hideReplaceInfo,
  hideGiftCode,
}: {
  booking: EcommerceBookingJourney;
  company: Pick<Company, 'description'>;
  hideReplaceInfo?: boolean;
  hideGiftCode?: boolean;
}) => {
  const { formatMessage: t } = useIntl();

  const appCurrency = APP_CURRENCIES_MAP[booking?.purchase.currency];

  const { auth } = useAppCacheContext();

  // NOTE: avant: isAnyAdmin limitait aux pro admin (maintenant, plus de restriction en fonction du profil, peut-être qu'il faudra parser les roles "az")
  const isAnyAdmin =
    auth?.isSuperAdmin || auth?.securityUser?.profile === 'company';

  const hasAnyOfferWeatherInsurance = useMemo(
    () =>
      booking.purchase.offers.some(
        (offer) => offer.type === 'weather-insurance'
      ),
    [booking.purchase.offers]
  );

  const hasAnyOfferActivity = useMemo(
    () =>
      booking.purchase.offers.some(
        (offer) =>
          offer.type === 'activity-session' ||
          offer.type === 'activity-session-product'
      ),
    [booking.purchase.offers]
  );

  return (
    booking && (
      <div className="w-full flex flex-col shadow-md border rounded-md text-left mb-4 bg-white text-sm">
        <div className="bg-gray-600 text-white py-1 px-2 rounded-md">
          <div className="text-base font-semibold">
            {t(
              { id: 'accropark.ecommerce.title.my-booking' },
              {
                where: booking.calculated.company.name,
                date: dateTransformer.formatUTC(
                  booking.calculated.firstActivityDateTimeUTC,
                  'dd/MM/yyyy'
                ),
              }
            )}
          </div>
          {company?.description && (
            <div className="text-sm  font-normal">{company.description}</div>
          )}
        </div>
        {/* offers */}
        {booking.purchase.offers.map((offer, i) => (
          <AccroparkBookingResumeTableOfferRow
            key={offer.reference}
            offer={offer}
          />
        ))}
        {!hideReplaceInfo && hasAnyOfferActivity && (
          <div className="px-2 border-b py-1">
            {t({
              id: hasAnyOfferWeatherInsurance
                ? 'component.journey.replace.infoWithWeather'
                : 'component.journey.replace.infoWithoutWeather',
            })}
          </div>
        )}
        {isAnyAdmin &&
          booking.purchase.taxes.map((x) => (
            <div
              key={x.taxPercentage}
              className="grid grid-cols-4 gap-x-4 text-right px-2 border-b py-1 sm:grid-cols-6 text-gray-500"
            >
              <div className="col-span-3 sm:col-span-5">
                {t({ id: 'vat' }, { pct: 100 * x.taxPercentage })}
              </div>
              <AppPriceLabel
                cents={2} // force 2 decimals
                centsClassName="text-xs"
                amount={x.taxAmount}
                appCurrency={appCurrency}
                addSpacesToLargeNumbers={true}
              />
            </div>
          ))}
        {(!isAnyAdmin || booking.purchase.taxes.length !== 1) && (
          <div className="grid grid-cols-4 gap-x-4 text-right px-2 border-b py-1 sm:grid-cols-6 text-gray-500">
            <div className="col-span-3 sm:col-span-5">
              {t({ id: 'vat-total' })}
            </div>
            <AppPriceLabel
              amount={booking.purchase.totalTax}
              appCurrency={appCurrency}
              addSpacesToLargeNumbers={true}
            />
          </div>
        )}
        {/* Sub-total */}
        <div className="grid grid-cols-4 gap-x-4 text-right px-2 border-b py-1 sm:grid-cols-6">
          <div className="col-span-3 sm:col-span-5">
            {t({ id: 'accropark.ecommerce.booking-card.total' })}:
          </div>
          <AppPriceLabel
            amount={booking.purchase.totalPurchase}
            appCurrency={appCurrency}
            addSpacesToLargeNumbers={true}
          />
        </div>
        {/* Discounts */}
        {booking.purchase.giftPromoDiscounts &&
          booking.purchase.giftPromoDiscounts.map((giftPromoDiscount, i) => {
            return (
              <div
                key={i}
                className="grid grid-cols-4 gap-x-4 text-right px-2 border-b font-bold py-1 sm:grid-cols-6 text-app-theme-accropark-success"
              >
                {giftPromoDiscount.type === 'gift-card' && (
                  <>
                    <div className="col-span-3 sm:col-span-5">
                      {t({
                        id: 'accropark.ecommerce.booking-card.offer.gift-code',
                      })}
                      :
                    </div>
                    <AppPriceLabel
                      amount={giftPromoDiscount.discountAmount}
                      appCurrency={appCurrency}
                      addSpacesToLargeNumbers={true}
                    />
                  </>
                )}
                {giftPromoDiscount.type === 'promo-code' && (
                  <>
                    <div className="col-span-3 sm:col-span-5">
                      {t({
                        id: 'accropark.ecommerce.booking-card.offer.promo-code',
                      })}
                      :
                    </div>
                    <AppPriceLabel
                      amount={
                        booking.purchase.totalDiscountAfterTaxes +
                        booking.purchase.totalDiscountBeforeTaxes
                      }
                      appCurrency={appCurrency}
                      addSpacesToLargeNumbers={true}
                    />
                  </>
                )}
              </div>
            );
          })}
        {/* Solde */}
        {booking.calculated.remainingBalance !== 0 && (
          <div className="grid grid-cols-4 gap-x-4 text-right px-2 border-b py-1 sm:grid-cols-6">
            {booking.calculated.dueBalance !== 0 && (
              <>
                <div className="col-span-3 sm:col-span-5 text-green-400 font-bold">
                  {t({ id: 'accropark.ecommerce.booking-card.balance' })}:
                </div>
                <div className="text-green-400 font-bold whitespace-nowrap">
                  <AppPriceLabel
                    amount={-booking.calculated.dueBalance}
                    appCurrency={appCurrency}
                    addSpacesToLargeNumbers={true}
                  />
                </div>
              </>
            )}
            {booking.calculated.remainingBalance < 0 && (
              <>
                <div className="col-span-3 sm:col-span-5">
                  {t({
                    id: 'accropark.ecommerce.booking-card.balance.remaining',
                  })}
                  :
                </div>
                <AppPriceLabel
                  amount={-booking.calculated.remainingBalance}
                  appCurrency={appCurrency}
                  addSpacesToLargeNumbers={true}
                />
              </>
            )}
          </div>
        )}
        {/* Total */}
        <div className="grid grid-cols-4 gap-x-4 text-right px-2 border-b font-bold py-1 sm:grid-cols-6">
          <div className="col-span-3 sm:col-span-5">
            {t({ id: 'accropark.ecommerce.booking-card.totalToPay' })}:
          </div>
          <AppPriceLabel
            amount={booking.calculated.totalToPayNow}
            appCurrency={appCurrency}
            addSpacesToLargeNumbers={true}
          />
        </div>
        {booking.status === 'completed' && (
          <div className="p-2">
            {booking.purchase.giftCardOptions?.code && !hideGiftCode && (
              <div className="py-2 font-bold text-app-theme-accropark-primary">
                <span className="uppercase">
                  {t({
                    id: 'accropark.ecommerce.booking-card.offer.gift-code',
                  })}
                </span>
                :{' '}
                <span className="uppercase">
                  {booking.purchase.giftCardOptions.code}
                </span>
              </div>
            )}
            {booking.paymentStatus === 'payment-accepted' && (
              <>
                <div className="text-success font-bold">
                  {t({ id: 'booking.payment.confirmed' })}
                </div>
                {(booking.calculated.previousBookingsPayments ?? []).map(
                  (lastPaymentResume, i) => {
                    return (
                      <AccroparkBookingResumeTablePaymentRow
                        className="mb-2"
                        key={i}
                        lastPaymentResume={lastPaymentResume}
                      />
                    );
                  }
                )}
                <AccroparkBookingResumeTablePaymentRow
                  lastPaymentResume={booking.lastPaymentResume}
                />
              </>
            )}
            {booking.paymentStatus === 'payment-later' && (
              <>
                <div className="text-danger font-bold">Paiement sur place</div>
              </>
            )}
            {booking.paymentStatus === 'payment-pending' && (
              <>
                <div className="text-danger font-bold">Non payé</div>
              </>
            )}
          </div>
        )}
      </div>
    )
  );
};
