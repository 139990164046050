import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import React, { useCallback, useState } from 'react';

export const AppFormLabel: React.FC<{
  id?: string;
  required?: boolean;
  label: React.ReactNode;
  hasError?: boolean;
  disabled?: boolean;
  helpDescription?: string;
  className?: string;
}> = ({
  id,
  label,
  required,
  hasError,
  disabled,
  helpDescription,
  className = '',
  children,
}) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(undefined);
  const toggleTooltip = useCallback(() => {
    if (helpDescription) {
      // une fois passé en mode "manuel" (activation au clic), il le reste
      setIsTooltipVisible(!isTooltipVisible);
    }
  }, [helpDescription, isTooltipVisible]);

  const labelComponent = (
    <label
      className={`mb-1 font-bold text-xs block text-left truncate
    ${
      hasError
        ? 'text-app-danger'
        : disabled
        ? 'text-gray-400 italic'
        : 'text-gray-500 '
    }
  `}
      htmlFor={id}
    >
      {label} {required && <span className="text-red-400">*</span>}
    </label>
  );

  return (
    <div className={clsx('focus:outline-none', className)}>
      {label && (
        <div
          className={clsx(helpDescription && 'cursor-pointer')}
          onClick={() => toggleTooltip()}
        >
          {helpDescription ? (
            <Tippy
              visible={isTooltipVisible}
              disabled={!helpDescription?.trim()}
              delay={[100, 100]}
              placement="right"
              content={helpDescription}
              onClickOutside={() => setIsTooltipVisible(false)}
            >
              {labelComponent}
            </Tippy>
          ) : (
            labelComponent
          )}
        </div>
      )}
      {children}
    </div>
  );
};
