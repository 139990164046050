import { usePlatform } from '@capacitor-community/react-hooks/platform';
import {
  briefcase,
  call,
  chatbubbles,
  checkmark,
  earth,
  football,
  home,
  power,
  trash,
} from 'ionicons/icons';

import { AppIcons } from '_01_CORE/_components-core';
import { useAppRouter } from '_01_CORE/_hooks';
import { accropark } from '_01_CORE/_icons/string/accropark';
import { basketCheckmark } from '_01_CORE/_icons/string/basketCheckmark';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import {
  useAppCacheContext,
  useCompany,
  useGroup,
  useJourney,
  useJourneyEnabledActions,
} from '_01_CORE/app-contexts';
import { appWebConfig, useAppFeatures } from '_01_CORE/app-core/app-config';
import { useAppSettings } from '_01_CORE/app-core/app-security';
import { HACK_SPECIAL_ACCOUNTS } from '_02_APP_COMMON/AUT-auth';
import { useNavigateToNewJourneyPage } from '_02_APP_COMMON/JOU-journey/_common';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { AppMenu, AppMenuItem, AppMenuItemId } from './_model';

export const useAppMenu = ({
  tabId,
  type,
  hideLogin,
  hideLogout,
  onClickLogout,
  onClickDeleteAccount,
}: {
  tabId?: AppMenuItemId;
  type: 'bottom-items-menu' | 'side-menu';
  hideLogin: boolean;
  hideLogout: boolean;
  onClickLogout: () => void;
  onClickDeleteAccount?: () => void;
}): AppMenu => {
  const appId = appWebConfig().appId;

  const appRouter = useAppRouter();
  const {
    unreadNotification,
    auth,
    companyValidReferenceCode,
  } = useAppCacheContext();

  const { formatMessage: t } = useIntl();

  const appSettings = useAppSettings();
  const whiteLabel = appSettings?.universe?.whiteLabel;

  const location = useLocation();

  const navigateToNewJourneyPage = useNavigateToNewJourneyPage({
    context: 'useAppTabs.hook',
  });
  const { company } = useCompany({
    companyReference: companyValidReferenceCode,
  }); // if !companyValidReferenceCode, use journey.company

  const isOnlinePaymentsEnabled =
    appSettings?.features?.payments?.stripe?.enabled &&
    company?.options?.payments?.online?.platformsEnabled;

  const { journey } = useJourney({ companyReference: company?.reference });

  const { platform } = usePlatform();

  const handleHomeClick = useCallback(() => {
    if (company?.reference) {
      appRouter.navigate(
        appRoutesBuilder.getCompanyBookletUrl({
          companyReference: company?.reference,
        }),
        { cause: 'items-home' }
      );
    } else if (whiteLabel?.appGroupReference) {
      appRouter.navigate(
        appRoutesBuilder.getGroupHomeUrl({
          groupReference: whiteLabel?.appGroupReference,
          isAuthenticated: auth?.isAuthenticated,
        }),
        { cause: 'items-home' }
      );
    } else if (auth?.isAuthenticated) {
      appRouter.navigate(
        appRoutesBuilder.getJourneyDefaultUrl({
          journeys: auth?.customerProfile?.journeys,
        }),
        { cause: 'items-home' }
      );
    } else {
      appRouter.navigate(
        appRoutesBuilder.getCompanyBookletUrl({
          companyReference: company?.reference,
        }),
        {
          cause: 'items-home',
        }
      );
    }
  }, [
    appRouter,
    auth?.customerProfile?.journeys,
    auth?.isAuthenticated,
    company?.reference,
    whiteLabel?.appGroupReference,
  ]);

  const isAnimationsTabVisible =
    journey &&
    appId === 'anim-live' &&
    isVisibleItem({
      visibility: 'on-connected',
      isAuthenticated: auth?.isAuthenticated,
    });

  const { isFeatureAuthEnabled } = useAppFeatures();

  const group = useGroup({ groupReference: company?.companyGroupReference });

  const { isCreateJourneyEnabled } = useJourneyEnabledActions({});

  return useMemo(() => {
    /**
     * Get destinations icon label which is different for Anim Live & Accropark
     */
    function getDestinationsIcon() {
      return appId === 'anim-live' ? earth : accropark;
    }
    function getBookingsIcon() {
      return appId === 'anim-live' ? briefcase : basketCheckmark;
    }

    let items: AppMenuItem[] = [];

    if (!auth || auth?.securityUser?.profile === 'customer') {
      // not authenticated OR authenticated as "customer"
      const bookingsItem: AppMenuItem = {
        id: 'bookings',
        icon: getBookingsIcon(),
        iconApp:
          appId === 'anim-live'
            ? AppIcons.bookingIconAnimLive
            : AppIcons.bookingIconAccropark,
        onClick: () =>
          appRouter.navigate(appRoutesBuilder.getJourneysUrl(), {
            cause: 'items-bookings',
          }),
        label: t({ id: `menu.journeys.${appId}` }),
        isVisibleItem: isVisibleItem({
          visibility: 'on-connected',
          isAuthenticated: auth?.isAuthenticated,
        }),
        iconBadge: AppIcons.notification2,
        iconBadgeVisible:
          auth?.customerProfile?.extJourneysToActivate?.length > 0,
      };
      const contactItem: AppMenuItem = {
        id: 'contact',
        icon: call,
        iconApp: AppIcons.contact,
        onClick: () =>
          appRouter.navigate(
            appRoutesBuilder.getCompanyContactUrl({
              companyReference: company?.reference,
            }),
            { cause: 'menu-contact' }
          ),
        label: t({ id: `menu.contact` }),
        isVisibleItem: isVisibleItem({
          visibility: 'on-connected',
          isAuthenticated: auth?.isAuthenticated,
        }),
      };
      if (type === 'side-menu') {
        items.push(bookingsItem);
        items.push(contactItem);
      }
      items = items.concat([
        {
          id: 'home',
          icon: home,
          iconApp: AppIcons.home,
          onClick: handleHomeClick,

          label: t({ id: 'menu.tab.home' }),
          isVisibleItem: isVisibleItem({
            visibility: 'always',
            isAuthenticated: auth?.isAuthenticated,
          }),
        },
        {
          id: 'destinations',
          icon: getDestinationsIcon(),
          onClick: () => {
            const destinationsUrl = appRoutesBuilder.getGroupHomeUrl({
              groupReference:
                company?.companyGroupReference ?? whiteLabel?.appGroupReference,
              isAuthenticated: auth?.isAuthenticated,
            });
            appRouter.navigate(destinationsUrl, {
              cause: 'items-destinations',
            });
          },
          label: t({ id: `menu.tab.destinations.${appId}` }),
          isVisibleItem: group?.companies.length > 1,
        },
        {
          id: 'animations',
          icon: football,
          onClick: () => {
            const animationsUrl = appRoutesBuilder.getJourneyActivitiesUrl({
              customerJourneyId: journey?._id,
              category: 'animator',
            });
            appRouter.navigate(animationsUrl, { cause: 'items-animations' });
          },
          label: t({ id: `page04.button.animations` }),
          isVisibleItem: isAnimationsTabVisible,
        },
      ]);

      if (type === 'bottom-items-menu') {
        items.push(bookingsItem);
      }
      if (isCreateJourneyEnabled) {
        if (appId === 'accropark') {
          items.push({
            id: 'book',
            icon: checkmark,
            onClick: navigateToNewJourneyPage,
            label: t({ id: `menu.tab.book.${appId}` }),
            isVisibleItem: !isAnimationsTabVisible,
          });
        } else {
          // items.push({
          //   id: 'book',
          //   icon: checkmark,
          //   onClick: () =>
          //     // on n'est pas connecté, donc page d'accueil du login
          //     appRouter.navigate(appRoutesBuilder.getLoginHomeUrl(), {
          //       cause: 'items-login',
          //     }),
          //   label: t({ id: `menu.tab.book.${appId}` }),
          //   isVisibleItem: !isAnimationsTabVisible,
          // });
        }
      }
      items = items.concat([
        {
          id: 'notifications',
          icon: chatbubbles,
          iconBadge: AppIcons.alert,
          iconBadgeVisible: unreadNotification,
          onClick: () =>
            appRouter.navigate('/notifications', {
              cause: 'items-notifications',
            }),
          label: t({ id: 'menu.tab.notifications' }),
          isVisibleItem: isVisibleItem({
            visibility: 'on-connected',
            isAuthenticated: auth?.isAuthenticated,
          }),
        },
      ]);

      if (!hideLogin) {
        items.push({
          id: 'login',
          icon: power,
          onClick: () =>
            appRouter.navigate(appRoutesBuilder.getLoginHomeUrl(), {
              cause: 'items-login',
            }),
          label: t({ id: 'menu.login' }),
          className: `text-primary font-bold`,
          isVisibleItem:
            isFeatureAuthEnabled &&
            isVisibleItem({
              visibility: 'on-disconnected',
              isAuthenticated: auth?.isAuthenticated,
            }),
        });
      }
    }

    if (auth?.securityUser?.profile === 'customer') {
      // authenticated as customer

      if (
        type === 'side-menu' &&
        appId === 'anim-live' &&
        isOnlinePaymentsEnabled
      ) {
        items.push({
          id: 'orders',
          iconApp: AppIcons.shoppingCart,
          onClick: () => {
            const animationsUrl = appRoutesBuilder.getJourneyShoppingCartHistoryUrl(
              {
                customerJourneyId: journey?._id,
              }
            );
            appRouter.navigate(animationsUrl, { cause: 'items-orders' });
          },
          label: t({ id: 'menu.tab.orders' }),
          icon: 'basket-outline',
          isVisibleItem: true,
        });
      }
    }

    if (auth?.isAuthenticated && !hideLogout) {
      items.push({
        id: 'logout',
        icon: power,
        onClick: () => onClickLogout(),
        label: t({ id: 'menu.logout' }),
        className: `text-primary font-bold`,
        isVisibleItem: isFeatureAuthEnabled,
      });
    }

    if (
      auth?.isSuperAdmin ||
      HACK_SPECIAL_ACCOUNTS.find(
        (x) => x.login === auth?.customerProfile?.account?.email
      )?.enableDevDebug
    ) {
      items.push({
        id: 'debug',
        icon: power,
        onClick: () =>
          appRouter.navigate(appRoutesBuilder.getDebugUrl(), {
            cause: 'items-debug',
          }),
        label: 'debug',
        className: `text-primary font-bold`,
        isVisibleItem: true,
      });
    }
    if (onClickDeleteAccount && auth?.securityUser?.profile === 'customer') {
      items.push({
        id: 'delete-account',
        icon: trash,
        onClick: onClickDeleteAccount,
        label: t({ id: 'menu.delete-account' }),
        className: `text-primary font-bold`,
        isVisibleItem: isFeatureAuthEnabled,
      });
    }

    const model: AppMenu = {
      hidden:
        location.pathname === '/login' ||
        // location.pathname === '/login/connexion' ||
        // location.pathname === '/login/register' ||
        location.pathname === '/login/auth' ||
        (location.pathname.search('/group/') === 0 && !auth?.isAuthenticated),
      activeId: tabId,
      items,
    };
    return model;
  }, [
    appId,
    appRouter,
    auth,
    company?.companyGroupReference,
    company?.reference,
    group?.companies.length,
    handleHomeClick,
    hideLogin,
    hideLogout,
    isAnimationsTabVisible,
    isCreateJourneyEnabled,
    isFeatureAuthEnabled,
    isOnlinePaymentsEnabled,
    journey?._id,
    location.pathname,
    navigateToNewJourneyPage,
    onClickDeleteAccount,
    onClickLogout,
    t,
    tabId,
    type,
    unreadNotification,
    whiteLabel?.appGroupReference,
  ]);
};

function isVisibleItem({
  visibility,
  isAuthenticated,
}: {
  visibility: 'on-connected' | 'on-disconnected' | 'always';
  isAuthenticated: boolean;
}): boolean {
  return (
    visibility === 'always' ||
    (visibility === 'on-connected' && isAuthenticated) ||
    (visibility === 'on-disconnected' && !isAuthenticated)
  );
}
