import { useIntl } from 'react-intl';
import { AppLoaderSpinner } from './AppLoaderSpinner';
import { AppLoaderType } from './AppLoaderType';

export const AppLoader = ({
  className = '',
  type = 'loading',
  message,
  iconSize = 6,
}: {
  className?: string;
  message?: string;
  iconSize?: 6 | 8;
  type?: AppLoaderType;
}) => {
  const { formatMessage: t } = useIntl();
  return (
    <div
      className={`font-bold text-sm text-gray-600 ${className} flex gap-2 items-center`}
    >
      <div>
        <AppLoaderSpinner className={iconSize === 6 ? 'w-6 h-6' : 'w-8 h-8'} />
      </div>
      <div className="flex-grow text-center">
        {message ??
          t({
            id:
              type === 'persist'
                ? `common.message.persist-in-progress`
                : `common.message.loading-in-progress`,
          })}
        ...
      </div>
      <div></div>
    </div>
  );
};
