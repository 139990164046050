import { Disclosure } from '@headlessui/react';
import { AppIcons, AppInputBooleanSwitch } from '_01_CORE/_components-core';
import { useAppRouter } from '_01_CORE/_hooks';
import { JourneyActivitiesLocalState } from '_02_APP_COMMON/ACT-activity';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { AnimLiveJourneysListPageCriteriaBarExtraFilters } from './AnimLiveJourneysActivitiesPageCriteriaBarExtraFilters';
import { AnimLiveJourneysListPageCriteriaBarFiltersResume } from './AnimLiveJourneysActivitiesPageCriteriaBarFiltersResume';

export const AnimLiveJourneysActivitiesPageCriteriaBar = ({
  localState,
  className,
}: {
  localState: JourneyActivitiesLocalState;
  className?: string;
}) => {
  const { formatMessage: t } = useIntl();
  const { scrollToTop } = useAppRouter();

  const { localFilters, setLocalFilters, isSearchTextActive } = localState;

  return (
    <div className={clsx('max-w-full overflow-hidden grid gap-2', className)}>
      <div className=" bg-white rounded flex gap-2 items-center w-full shadow-sm border border-gray-200">
        <button className="outline-none focus:outline-none">
          <AppIcons.search className="w-5 h-5 text-gray-400 cursor-pointer" />
        </button>
        <input
          className="px-0 h-8 w-full border-none text-sm outline-none focus:outline-none bg-transparent focus:ring-0"
          type="search"
          name=""
          value={localFilters.searchText ?? ''}
          placeholder={'Rechercher une activité'}
          // placeholder={t({
          //   id: 'ec-01.booking-table.search.placeholder',
          // })}
          onChange={(e) =>
            setLocalFilters({
              ...localFilters,
              searchText: e.target.value,
            })
          }
        />
      </div>
      {!isSearchTextActive && (
        <div
          className="flex flex-col md:flex-row md:items-end gap-2 flex-grow cursor-default"
          onClick={(e) => e.stopPropagation()}
        >
          <AppInputBooleanSwitch
            labelWhiteSpace="nowrap"
            value={localFilters?.registeredOrPresentOnly}
            onChange={(value) => {
              setLocalFilters({
                ...localFilters,
                registeredOrPresentOnly: value,
              });
            }}
            label={t({
              id: 'page16.form.select-participant.my-bookings',
            })}
          />
        </div>
      )}
      <Disclosure
        as="div"
        className="max-w-full overflow-hidden"
        defaultOpen={false}
      >
        {({ open }) => (
          <>
            <Disclosure.Button
              as={'div'}
              className={clsx(
                'grid gap-2 cursor-pointer focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75 ',
                'focus:outline-none'
              )}
            >
              <div className="flex gap-2 w-full justify-start items-center md:items-center">
                <div
                  className={clsx(
                    'py-0.5 px-1 focus:outline-none',
                    'border border-gray-300 hover:border-gray-400 rounded',
                    'bg-white hover:bg-gray-100 text-gray-800 hover:text-gray-900'
                  )}
                >
                  <AppIcons.actionSettingsDisplay
                    className={`${
                      open ? 'rotate-180 transform' : ''
                    } h-6 w-6 m-1 text-gray-500 `}
                  />
                </div>

                <AnimLiveJourneysListPageCriteriaBarFiltersResume
                  className={clsx('max-w-full overflow-hidden truncate')}
                  localState={localState}
                />
              </div>

              {/* {!open && (
                <AnimLiveJourneysListPageCriteriaBarFiltersResume
                  className={clsx('')}
                  localState={localState}
                />
              )} */}
            </Disclosure.Button>
            <Disclosure.Panel className="pt-2 pr-2 text-sm text-gray-500 ">
              <AnimLiveJourneysListPageCriteriaBarExtraFilters
                localState={localState}
              />
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};
