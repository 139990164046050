import { AppCacheStore, AppCacheTTLStore } from '_01_CORE/app-core/app-cache';
import {
  CMFetchActivitiesCriteria,
  CMFetchActivitiesData,
} from 'lib-common-model';

const store: AppCacheStore<any> = AppCacheTTLStore({
  ttl: 7 * 24 * 60 * 60 * 1000, // 1 semaine
  maxItems: 10, // 10 séjours maxi
});
// TODO: clear store on logout

export type CMFetchActivitiesCacheData = {
  _cachedAt: Date;
  data: CMFetchActivitiesData;
};

export const customerJourneyActivitiesCache = {
  get,
  set,
  reduce,
};

async function get(
  criteria: CMFetchActivitiesCriteria
): Promise<CMFetchActivitiesCacheData> {
  const criteriaKey = buildCacheKey(criteria);

  const cachedData: CMFetchActivitiesCacheData = await store.get(criteriaKey);
  return cachedData;
}
async function set(
  criteria: CMFetchActivitiesCriteria,
  data: CMFetchActivitiesCacheData
) {
  const criteriaKey = buildCacheKey(criteria);

  await store.set(criteriaKey, data);
}

async function reduce(
  criteria: CMFetchActivitiesCriteria,
  fn: (data: CMFetchActivitiesCacheData) => Promise<CMFetchActivitiesCacheData>
) {
  const criteriaKey = buildCacheKey(criteria);

  const cachedData: CMFetchActivitiesCacheData = await store.get(criteriaKey);
  if (cachedData) {
    const updatedData: CMFetchActivitiesCacheData = await fn(cachedData);

    await store.set(criteriaKey, updatedData);
  } else {
    console.error(
      '[customerJourneyActivitiesCache] data is not in cache',
      criteria
    );
    return;
  }
}

function buildCacheKey(criteria: CMFetchActivitiesCriteria) {
  const {
    customerAccountId,
    customerJourneyId,
    date,
    platformLanguageCode,
    appLanguageCode,
  } = criteria;

  const criteriaKey = [
    customerAccountId,
    customerJourneyId,
    date.getTime(),
    platformLanguageCode,
    appLanguageCode,
  ]
    .map((x) => x.toString())
    .join(';');
  return criteriaKey;
}
