import {
  AppRichTextElementTextAlign,
  CompanyOptionsTheme,
} from 'lib-common-model';
import React, { useMemo } from 'react';
import { appRichTextRenderer } from '../services';

export function AppRichTextH1<A>({
  attributes = {} as any,
  textAlign,
  theme,
  children,
}: {
  attributes?: A;
  textAlign?: AppRichTextElementTextAlign;
  theme: CompanyOptionsTheme;
  children: any;
}) {
  const style: React.CSSProperties = useMemo(
    () => ({ color: theme.title1Color }),
    [theme.title1Color]
  );

  const className = useMemo(() => {
    return [appRichTextRenderer.getAlignmentClass(textAlign)]
      .filter((x) => !!x)
      .join(' ');
  }, [textAlign]);

  return (
    <h1
      className={`mt-2 font-bold text-lg mm:text-xl ${className}`}
      {...attributes}
      style={style}
    >
      {children}
    </h1>
  );
}
