import { AppPage, PageContainer } from '_01_CORE/_common-layout';
import { AppButtonUniverse, AppLoader } from '_01_CORE/_components-core';
import { useAppRouter } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import { useAppCacheContext } from '_01_CORE/app-contexts';
import { customerJourneyApiClient } from '_01_CORE/app-core/app-api';
import { useAppSettings } from '_01_CORE/app-core/app-security';
import { AppUniverseHeader } from '_02_APP_COMMON/_global';
import { AuthPageMultiLoginCheckKeyPanel } from '_04_ANIM_LIVE/AUT-auth/02-login/check-key';
import { useState } from 'react';
import { useIntl } from 'react-intl';

export const AnimLiveJourneyActivateByKeyPage = () => {
  const [persistInProgress, setPersistInProgress] = useState(false);

  const appSettings = useAppSettings();
  const identity = appSettings?.universe?.identity;

  const appCacheContext = useAppCacheContext();
  const { auth } = appCacheContext;
  const customerAccount = auth?.customerProfile?.account;
  const customerAccountMembers = auth?.customerProfile?.members;

  // get extJourneyGrouId from url param
  const params = new URLSearchParams(window.location.search);
  const extCustomerJourneyBookingGroupId = params.get(
    'extCustomerJourneyBookingGroupId'
  );
  const extCustomerAccountActivationEmail = params.get(
    'extCustomerAccountActivationEmail'
  );

  const appRouter = useAppRouter();
  const { formatMessage: t } = useIntl();

  const title1Color = appSettings?.universe?.theme?.title1Color;

  return (
    <AppPage tabId="login">
      <PageContainer
        className={`h-full bg-white text-center flex flex-col gap-4`}
        style={{
          color: title1Color,
        }}
      >
        <AppUniverseHeader
          label="Activation du séjour"
          showLargeHeader={true}
        />
        {persistInProgress ? (
          <AppLoader
            className="my-4 p-2 px-4"
            type="persist"
            message="Connexion en cours"
          />
        ) : (
          <div className="h-full w-full max-w-sm md:max-w-md mx-auto flex flex-col">
            <AuthPageMultiLoginCheckKeyPanel
              context="journey-add-activation"
              extCustomerAccountActivationEmail={
                extCustomerAccountActivationEmail
              }
              extCustomerJourneyBookingGroupId={
                extCustomerJourneyBookingGroupId
              }
              onJourneyFinderResultUpdated={async (journeyFinderResult) => {
                if (journeyFinderResult) {
                  const fetchData = await customerJourneyApiClient.fetchExternalJourneyDetailsFromActivationToken(
                    {
                      activationToken: journeyFinderResult.activationToken,
                      companyReference: journeyFinderResult.companyReference,
                    }
                  );

                  appCacheContext.dispatchCacheContextAction({
                    type: 'set-journey-to-edit-from-external-data',
                    payload: {
                      externalData: fetchData,
                      customerAccount,
                      customerAccountMembers,
                      activationToken: journeyFinderResult.activationToken,
                      companyReference: journeyFinderResult.companyReference,
                    },
                  });
                  appRouter.navigate(
                    appRoutesBuilder.getJourneyCreateParticipantsListUrl({
                      companyReference: journeyFinderResult.companyReference,
                    }),
                    {
                      cause:
                        'create-journey-from-external-with activation-code',
                    }
                  );
                }
              }}
            />
            <div className="flex-grow"></div>
            <div className="mt-8 ion-justify-content-center">
              <AppButtonUniverse
                style="light"
                className="w-full mt-4 uppercase"
                isFullWidth={true}
                onClick={() => {
                  if (auth?.customerProfile) {
                    appRouter.navigate(appRoutesBuilder.getJourneysUrl(), {
                      cause: 'back-from-add-journey-activation',
                    });
                  } else {
                    appRouter.navigate(appRoutesBuilder.getLoginHomeUrl(), {
                      cause: 'back-from-add-journey-activation',
                    });
                  }
                }}
              >
                {t({ id: 'common.action.back' })}
              </AppButtonUniverse>
            </div>
          </div>
        )}
      </PageContainer>
    </AppPage>
  );
};
