import { CompanyOptionsTheme, COMPANY_DEFAULT_THEME } from 'lib-common-model';
import { useMemo } from 'react';
import { useCompany } from '_01_CORE/app-contexts';

export function useAppTheme({
  companyReference,
}: {
  companyReference: string;
}) {
  const { company } = useCompany({ companyReference });

  const theme: CompanyOptionsTheme = useMemo(
    () => mergeThemes(company?.options?.theme, COMPANY_DEFAULT_THEME),
    [company?.options?.theme]
  );

  return theme;
}

function mergeThemes(
  t1?: CompanyOptionsTheme,
  t2?: CompanyOptionsTheme,
  t3?: CompanyOptionsTheme
) {
  return {
    title1Color: t1?.title1Color ?? t2?.title1Color ?? t3?.title1Color,
    title2Color: t1?.title2Color ?? t2?.title2Color ?? t3?.title2Color,
    textColor: t1?.textColor ?? t2?.textColor ?? t3?.textColor,
    buttonPrimaryBgColor:
      t1?.buttonPrimaryBgColor ??
      t2?.buttonPrimaryBgColor ??
      t3?.buttonPrimaryBgColor,
    buttonPrimaryTextColor:
      t1?.buttonPrimaryTextColor ??
      t2?.buttonPrimaryTextColor ??
      t3?.buttonPrimaryTextColor,
    buttonCancelBgColor:
      t1?.buttonCancelBgColor ??
      t2?.buttonCancelBgColor ??
      t3?.buttonCancelBgColor,
    buttonCancelTextColor:
      t1?.buttonCancelTextColor ??
      t2?.buttonCancelTextColor ??
      t3?.buttonCancelTextColor,
  };
}
