import { AppPage, PageContainer } from '_01_CORE/_common-layout';
import { dateTransformer } from '_01_CORE/_components-core';
import { useAppRouter, useAppToasts } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import { useCompany } from '_01_CORE/app-contexts';
import { customerJourneyApiClient } from '_01_CORE/app-core/app-api';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import { useAppLogoUrl, useAppSettings } from '_01_CORE/app-core/app-security';
import { AppUniverseHeader } from '_02_APP_COMMON/_global';
import { CMExtJourneyFinderResult } from 'lib-common-model';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { AnimLiveJourneyActivateByTokenPanel } from './AnimLiveJourneyActivateByTokenPanel';

export const AnimLiveJourneyActivateByTokenPage = () => {
  const appRouter = useAppRouter();
  const { formatMessage: t } = useIntl();
  const { pushError, pushSuccess } = useAppToasts();

  const { companyReference } = useParams<{ companyReference: string }>();

  const appSettings = useAppSettings();
  const identity = appSettings?.universe?.identity;

  const appLabel = identity.appLabel;
  const appLogoUrl = useAppLogoUrl('md');

  const { company } = useCompany({ companyReference });

  const params = new URLSearchParams(window.location.search);

  const activationToken = params.get('activationToken');

  const [loadingState, setLoadingState] = useState<
    'fetching' | 'success' | 'error'
  >('fetching');

  const [fetchData, setFetchData] = useState<CMExtJourneyFinderResult>();

  const fakeCustomerJourney = fetchData?.fakeCustomerJourney;

  const [emailAddress, setEmailAddress] = useState<string>(
    appWebConfig().authentication.defaultAuthLogin?.login || undefined
  );

  useEffect(() => {
    if (activationToken) {
      customerJourneyApiClient
        .fetchExternalJourneyDetailsFromActivationToken({
          activationToken,
          companyReference,
        })
        .then((data) => {
          setFetchData(data);
          setLoadingState('success');
          if (data?.existingJourneyCustomerAccounts?.length === 1) {
            setEmailAddress(data?.existingJourneyCustomerAccounts[0].email);
          }
        })
        .catch((err) => {
          console.error(err);
          pushError(
            t({ id: 'page30-journey-activation-token.message.fetching-error' })
          );
          setLoadingState('error');
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!activationToken) {
      appRouter.navigate(appRoutesBuilder.getLoginHomeUrl(), {
        cause: 'activationToken missing',
      });
    }
  }, [activationToken, appRouter]);

  return (
    <AppPage tabId="login">
      <PageContainer
        className={`bg-white text-center text-app-theme-anim-live-primary`}
      >
        <AppUniverseHeader showLargeHeader={false} />
        {loadingState === 'success' && !!fakeCustomerJourney && (
          <p
            className={`my-8 text-left text-base text-app-theme-anim-live-primary`}
          >
            {t(
              {
                id: 'page30-journey-activation-token.message.vous-avez-reserve',
              },
              {
                campingName: company?.name,
                beginDate: dateTransformer.formatUTC(
                  fakeCustomerJourney.beginDateUTC,
                  t({ id: 'common.date.format' })
                ),
                endDate: dateTransformer.formatUTC(
                  fakeCustomerJourney.endDateInclusiveUTC,
                  t({ id: 'common.date.format' })
                ),
              }
            )}
          </p>
        )}
        {loadingState === 'success' && fetchData && (
          <AnimLiveJourneyActivateByTokenPanel
            fetchData={fetchData}
            setPersistInProgress={(inProgress) => {
              if (inProgress) {
                setLoadingState('fetching');
              } else {
                setLoadingState('success');
              }
            }}
          />
        )}
      </PageContainer>
    </AppPage>
  );
};
