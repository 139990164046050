import { Preferences } from '@capacitor/preferences';
import { appLogger } from 'lib-web-logger';
import { from, of, throwError } from 'rxjs';
import { catchError, mapTo, switchMap, tap } from 'rxjs/operators';

export const persistentCache = {
  get,
  remove,
  set,
};

Preferences.migrate(); // migrate storage data from v2 : https://capacitorjs.com/docs/updating/3-0#backward-incompatible-plugin-changes

async function set<T>(
  key: string,
  value: T,
  { ignoreError }: { ignoreError: boolean }
): Promise<T> {
  if (!Preferences) {
    return value;
  }
  return of(new Date())
    .pipe(
      switchMap((startDate) =>
        from(
          Preferences.set({
            key,
            value: JSON.stringify(value),
          })
        ).pipe(
          tap(() => {
            const methodDuration = new Date().getTime() - startDate.getTime();
            if (methodDuration > 1000) {
              appLogger.warn(`[set] method duration: ${methodDuration}ms`);
              appLogger.error('Unexpected method duration');
            }
          }),
          catchError((err) => {
            appLogger.error(
              `[persistentCache] Error storing data with key "${key}" into browser storage`,
              { err }
            );
            if (ignoreError) {
              return of(value);
            } else {
              return throwError(err);
            }
          })
        )
      ),
      mapTo(value)
    )
    .toPromise();
}

async function remove(
  key: string,
  { ignoreError }: { ignoreError: boolean }
): Promise<boolean> {
  if (!Preferences) {
    return false;
  }
  return from(Preferences.remove({ key }))
    .pipe(
      mapTo(true),
      catchError((err) => {
        appLogger.error(
          `[persistentCache] Error removing data with key "${key}" into browser storage`,
          { err }
        );
        if (ignoreError) {
          return of(false);
        } else {
          return throwError(err);
        }
      })
    )
    .toPromise();
}

function get<T = string>(
  key: string,
  { failsIfNull, ignoreError }: { failsIfNull?: boolean; ignoreError: boolean }
): Promise<T> {
  if (!Preferences) {
    return undefined;
  }
  return from(Preferences.get({ key }))
    .pipe(
      switchMap(({ value }) => {
        if (value == null && failsIfNull) {
          appLogger.error(`[persistentCache] ERROR key "${key}" not found:`);
          return throwError(new Error('Key not found'));
        } else {
          // appLogger.debug('[Sec][PersistentCacheService] SUCCESS get key "%s" with value:', key, value);
          return of(JSON.parse(value));
        }
      }),
      catchError((err) => {
        appLogger.error(
          `[persistentCache] Error getting data with key "${key}" into browser storage`,
          { err }
        );
        if (ignoreError) {
          return of(undefined);
        } else {
          return throwError(err);
        }
      })
    )
    .toPromise();
}
