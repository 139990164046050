import { LanguageCode } from './LanguageCode.type';

export const NOTIFICATIONS_LANGUAGES_CODES: LanguageCode[] = [
  'fre/fra',
  'en',
  'spa',
  'ger/deu',
  'ita',
  'dut/nld',
];
