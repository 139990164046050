import { useMemo } from 'react';
import { AppHackableCalendarVM } from '../model';
import { AppHackableCalendarWeeksPanelVMDay } from './model';

function clsx(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export const AppHackableCalendarWeeksPanelDay = ({
  vm,
  day,
  dayIndex,
  daysCount,
}: {
  vm: AppHackableCalendarVM;
  day: AppHackableCalendarWeeksPanelVMDay;
  dayIndex: number;
  daysCount: number;
}) => {
  const dayCorner = useMemo(() => {
    return dayIndex === 0
      ? 'top-left'
      : dayIndex === 6
      ? 'top-right'
      : dayIndex === daysCount - 7
      ? 'bottom-left'
      : dayIndex === daysCount - 1
      ? 'bottom-right'
      : undefined;
  }, [dayIndex, daysCount]);

  const dayCornerClass = useMemo(() => {
    switch (dayCorner) {
      case 'top-left':
        return 'rounded-tl-lg';
      case 'top-right':
        return 'rounded-tr-lg';
      case 'bottom-left':
        return 'rounded-bl-lg';
      case 'bottom-right':
        return 'rounded-br-lg';
    }
  }, [dayCorner]);

  const isSelectable = useMemo(() => {
    if (vm.minDate && vm.minDate.getTime() > day.date.getTime()) {
      return false;
    }
    if (vm.maxDate && vm.maxDate.getTime() < day.date.getTime()) {
      return false;
    }
    return true;
  }, [day.date, vm.maxDate, vm.minDate]);

  const bgColor = useMemo(() => {
    return day.isCurrentViewMonth && isSelectable ? 'bg-white' : 'bg-gray-50';
  }, [day.isCurrentViewMonth, isSelectable]);

  const fontWeight = useMemo(() => {
    if (day.isSelected || day.isToday || day.isCurrentViewMonth) {
      return 'font-semibold';
    }
  }, [day.isCurrentViewMonth, day.isSelected, day.isToday]);

  const textColor = useMemo(() => {
    if (day.isSelected) {
      return day.isToday ? 'text-white' : 'text-gray-500';
    }
    if (day.isCurrentViewMonth) {
      if (day.isToday && !day.isSelected) {
        return 'text-sky-500';
      } else {
        return isSelectable ? 'text-gray-500' : 'text-gray-400';
      }
    } else {
      return 'text-gray-400';
    }
  }, [day.isCurrentViewMonth, day.isSelected, day.isToday, isSelectable]);

  return (
    <button
      disabled={!isSelectable}
      key={day.date.getTime()}
      type="button"
      className={clsx(
        'py-0.5 focus:z-10',
        isSelectable
          ? 'cursor-pointer hover:bg-gray-200'
          : 'cursor-not-allowed',
        bgColor,
        fontWeight,
        textColor,
        dayCornerClass
      )}
      onClick={() => {
        if (isSelectable) {
          vm.props.onSelectDate && vm.props.onSelectDate(day.date);
        }
      }}
    >
      <time
        dateTime={day.dateIsoString}
        className={clsx(
          'mx-auto flex h-8 w-8 items-center justify-center rounded-full',
          day.isSelected && day.isToday && 'bg-sky-500',
          day.isSelected && !day.isToday && 'border-2 border-sky-500'
        )}
      >
        {day.date.getUTCDate()}
      </time>
    </button>
  );
};
