import { isAfter, isBefore, isEqual, isWithinInterval } from 'date-fns';
import { TimePeriodDetails } from 'lib-common-model';
import { appLogger } from 'lib-web-logger';

export const dateUtils = {
  isDateWithinOneRange,
  haveCommonDays,
  isWithinRange,
  isAfterIgnoreTime,
  isEqualIgnoreTime,
  isBeforeIgnoreTime,
};

function isAfterIgnoreTime(d1: Date, d2: Date): boolean {
  return isAfter(setTimeUTC(d1), setTimeUTC(d2));
}

function isEqualIgnoreTime(d1: Date, d2: Date): boolean {
  return isEqual(setTimeUTC(d1), setTimeUTC(d2));
}

function isBeforeIgnoreTime(d1: Date, d2: Date): boolean {
  return isBefore(setTimeUTC(d1), setTimeUTC(d2));
}

function isWithinRange(
  dateToCheck: Date,
  dateStart: Date,
  dateEnd: Date
): boolean {
  if (!isValidRange(dateStart, dateEnd)) {
    appLogger.error('[DateService.isWithinRange] invalid dates', {
      data: { dateToCheck, dateStart, dateEnd },
    });
    return false;
  }
  return isWithinInterval(dateToCheck, { start: dateStart, end: dateEnd });
}

function setTimeUTC(
  date: Date,
  hours = 0,
  minutes = 0,
  seconds = 0,
  ms = 0
): Date {
  if (!date) {
    return date;
  }
  // logger.debug('setTimeUTCUTC date:', date);
  const dateTime = new Date(date);
  dateTime.setUTCHours(hours);
  dateTime.setUTCMinutes(minutes);
  dateTime.setUTCSeconds(seconds);
  dateTime.setUTCMilliseconds(ms);
  // logger.debug('setTimeUTCUTC dateTime:', dateTime);
  return dateTime;
}

function isWithinRangeIgnoreTime(
  dateToCheck: Date,
  startDate: Date,
  endDate: Date
): boolean {
  return isWithinRange(
    setTimeUTC(dateToCheck, 12),
    setTimeUTC(startDate, 4),
    setTimeUTC(endDate, 20)
  );
}

function isDateWithinOneRange(
  dateToCheck: Date,
  datesRange: TimePeriodDetails[]
): boolean {
  if (!dateToCheck) {
    return false;
  }
  dateToCheck = new Date(dateToCheck);
  if (datesRange) {
    for (const date of datesRange) {
      const isInRange = isWithinRangeIgnoreTime(
        dateToCheck,
        date.beginDate,
        date.endDateInclusive
      );
      if (isInRange) {
        return true;
      }
    }
  }
  return false;
}

function isValidRange(dateStart: Date, dateEnd: Date): boolean {
  if (new Date(dateStart).getTime() > new Date(dateEnd).getTime()) {
    return false;
  } else {
    return true;
  }
}

function haveCommonDays(
  min1: Date,
  max1: Date,
  min2: Date,
  max2: Date
): boolean {
  const min1Fix = setTimeUTC(min1, 12);
  const max1Fix = setTimeUTC(max1, 12);
  const min2Fix = setTimeUTC(min2, 12);
  const max2Fix = setTimeUTC(max2, 12);
  return (
    isWithinRange(min1Fix, min2Fix, max2Fix) ||
    isWithinRange(max1Fix, min2Fix, max2Fix) ||
    isWithinRange(min2Fix, min1Fix, max1Fix) ||
    isWithinRange(max2Fix, min1Fix, max1Fix)
  );
}
