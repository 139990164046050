import { useMemo } from 'react';
import { useCompanyBooklet } from './useCompanyBooklet.hook';

export function useCompanyBookletTheme({
  companyReference,
  bookletThemeReference,
}: {
  companyReference: string;
  bookletThemeReference: string;
}) {
  const booklet = useCompanyBooklet({ companyReference });

  const theme = useMemo(
    () =>
      booklet?.themes.find(
        (theme) => theme.reference === bookletThemeReference
      ),
    [booklet?.themes, bookletThemeReference]
  );

  return { booklet, theme };
}
