import { useAppRouter, useAppToasts } from '_01_CORE/_hooks';
import { useAppCacheContext } from '_01_CORE/app-contexts';
import { useIsNetworkConnected } from '_01_CORE/app-core/app-platform';
import { authenticationClient } from '_01_CORE/app-core/app-security';
import { CustomerMobileAuthenticationResult } from 'lib-common-model';
import { appLogger } from 'lib-web-logger';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { AuthMultiLoginAnimLivePageBaseLocalState } from '../useAuthMultiLoginAnimLivePageLocalState.hook';
import { AuthMultiLoginAction_ProcessAuthenticationSuccess } from './useAuthMultiLoginAction_ProcessAuthenticationSuccess.hook';

export function useAuthMultiLoginAction_AuthenticateByActivationToken(
  baseLocalState: AuthMultiLoginAnimLivePageBaseLocalState,
  actions: {
    processAuthenticationSuccess: AuthMultiLoginAction_ProcessAuthenticationSuccess;
  }
) {
  const appCacheContext = useAppCacheContext();
  const { pushError, pushSuccess } = useAppToasts();
  const { dispatchCacheContextAction } = useAppCacheContext();
  const appRouter = useAppRouter();

  const { formatMessage: t } = useIntl();

  const isNetworkConnected = useIsNetworkConnected();

  const { viewState, setViewState } = baseLocalState;

  const authenticateByActivationToken = useCallback(
    async ({
      email,
      activationToken,
    }: {
      email: string;
      activationToken: string;
    }) => {
      if (!isNetworkConnected()) {
        return;
      }

      setViewState({
        operationInProgress: {
          type: 'loading',
          message: 'Connexion en cours',
        },
        ...viewState,
      });

      try {
        const authResult: CustomerMobileAuthenticationResult = await authenticationClient.authenticateByActivationToken(
          {
            email,
            activationToken,
          }
        );

        actions.processAuthenticationSuccess(authResult);
      } catch (err) {
        appLogger.warn('Authentication err', { err });
        pushError(t({ id: 'page01.connectionNotOK' }));
      } finally {
        setViewState({
          operationInProgress: undefined,
          ...viewState,
        });
      }
    },
    [isNetworkConnected, setViewState, viewState, actions, pushError, t]
  );
  return authenticateByActivationToken;
}

export type AuthMultiLoginAction_AuthenticateByActivationToken = ReturnType<
  typeof useAuthMultiLoginAction_AuthenticateByActivationToken
>;
