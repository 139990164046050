import * as dateFns from 'date-fns';
import { fr } from 'date-fns/locale';
import { DateIsoWeekDay, TimeHoursMinutes } from 'lib-common-model';
import { MessageDescriptor } from 'react-intl';

export const dateTransformer = {
  getISODay,
  getISOWeek,
  addDays,
  getUTCDateSetTime,
  getUTCFromLocalDate,
  format,
  formatUTC,
  parseUTCFromDDMMYYYY,
  formatDuration,
  formatTime,
  formatWeekDay,
  getFirstWeekDay,
  minDate,
  maxDate,
  getAge,
};

function parseUTCFromDDMMYYYY(
  str: string,
  {
    minDate,
    maxDate,
  }: {
    minDate?: Date;
    maxDate?: Date;
  } = {}
): {
  isValid: boolean;
  date?: Date;
} {
  // BUG: dayjs ne tient pas compte du format lors du parsing !!! => donc on utilise Date.parse au format ISO
  const format = 'dd/MM/yyyy';
  if (!str || str.length === 0) {
    return { isValid: true };
  }
  const separator = '/';

  const regex = /^([0-9]|[0-2][0-9]|(3)[0-1])(\/)(([0-9]|(0)[0-9])|((1)[0-2]))(\/)\d{4}$/;
  if (!regex.test(str)) {
    return { isValid: false };
  }
  const chunks = str.split(separator);
  if (chunks.length !== 3) {
    return { isValid: false };
  }
  const strISO = `${chunks[2]}-${chunks[1]}-${chunks[0]}`;
  const date = new Date(Date.parse(strISO));
  if (date.toString() === 'Invalid Date') {
    return { isValid: false };
  }
  if (formatUTC(date, format) === str) {
    if (minDate && minDate.getTime() > date.getTime()) {
      return { isValid: false };
    }
    if (maxDate && maxDate.getTime() < date.getTime()) {
      return { isValid: false };
    }
    return { date, isValid: true };
  }

  return { isValid: false };
}

function formatWeekDay(
  date: Date,
  {
    format,
    formatMessage,
  }: {
    format: 'short' | 'long';
    formatMessage: (descriptor: MessageDescriptor) => string;
  }
) {
  const isoDay = dateFns.getISODay(date);
  let str = `${isoDay}`;
  switch (isoDay) {
    case 1:
      str = formatMessage({ id: 'common.weekday.monday' });
      break;
    case 2:
      str = formatMessage({ id: 'common.weekday.tuesday' });
      break;
    case 3:
      str = formatMessage({ id: 'common.weekday.wednesday' });
      break;
    case 4:
      str = formatMessage({ id: 'common.weekday.thursday' });
      break;
    case 5:
      str = formatMessage({ id: 'common.weekday.friday' });
      break;
    case 6:
      str = formatMessage({ id: 'common.weekday.saturday' });
      break;
    case 7:
      str = formatMessage({ id: 'common.weekday.sunday' });
      break;
  }
  if (str && format == 'short') {
    return str.substring(0, 3);
  }
  return str;
}
function formatTime(date: Date) {
  return dateTransformer.formatUTC(date, 'HH:mm');
}
function formatDuration(
  duration: TimeHoursMinutes,
  { hoursLabel = 'h' }: { hoursLabel: string }
) {
  const { hours, minutes } = duration;
  return `${hours}${hoursLabel}${
    minutes === 0 ? '' : minutes < 10 ? `0${minutes}` : minutes
  }`;
}
function getAge(originDate: Date, referenceDate = new Date()): number {
  if (!originDate) {
    return 0;
  }
  originDate = getUTCDateSetTime(originDate, 12);
  referenceDate = getUTCDateSetTime(referenceDate, 12);
  let age = referenceDate.getUTCFullYear() - originDate.getUTCFullYear();
  const m = referenceDate.getUTCMonth() - originDate.getUTCMonth();
  if (
    m < 0 ||
    (m === 0 && referenceDate.getUTCDate() < originDate.getUTCDate())
  ) {
    age--;
  }
  if (age < 0) {
    age = 0;
  }
  return age;
}

function getISODay(date: Date): DateIsoWeekDay {
  return dateFns.getISODay(getUTCDateSetTime(date, 12)) as any;
}
function getISOWeek(date: Date) {
  return dateFns.getISOWeek(date);
}

function addDays(originDate: Date, daysToAdd: number): Date {
  if (originDate) {
    if (!originDate.getTime) {
      originDate = new Date(originDate);
    }
    return new Date(originDate.getTime() + 3600000 * 24 * daysToAdd);
  }
  return originDate;
}

function getUTCDateSetTime(
  date: Date,
  hours?: number,
  minutes?: number,
  seconds?: number,
  ms?: number
) {
  if (!date) {
    date = new Date();
  }
  if (!date.getUTCFullYear) {
    date = new Date(date);
  }
  return new Date(
    Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      hours ? hours : 0,
      minutes ? minutes : 0,
      seconds ? seconds : 0,
      ms ? ms : 0
    )
  );
}

function getUTCFromLocalDate(date: Date) {
  if (!date.getTime) {
    date = new Date(date);
  }
  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    )
  );
}

function format(date: Date, format: string) {
  if (!date) {
    return '';
  }
  return dateFns.format(date, format, { locale: fr });
}
function addMinutes(originDate: Date, minutesToAdd: number): Date {
  const finalDate = new Date(originDate.getTime());
  finalDate.setUTCMinutes(finalDate.getUTCMinutes() + minutesToAdd);
  return finalDate;
}
function formatUTC(date: Date, format: string) {
  if (!date) {
    return '';
  }
  date = new Date(date);
  date = addMinutes(date, date.getTimezoneOffset());
  return dateFns.format(date, format, {
    locale: fr,
  });
}

function getFirstWeekDay({
  refDate,
  firstIsoWeekDay,
}: {
  refDate: Date;
  firstIsoWeekDay: number;
}) {
  const diffWithFirstDay =
    (7 + dateTransformer.getISODay(refDate) - firstIsoWeekDay) % 7;
  const firstDay = dateTransformer.addDays(refDate, -diffWithFirstDay);
  return firstDay;
}

function minDate(d1: Date, d2: Date) {
  if (!d1.getTime) {
    d1 = new Date(d1);
  }
  if (!d2.getTime) {
    d2 = new Date(d2);
  }
  return new Date(Math.min(d1.getTime(), d2.getTime()));
}

function maxDate(d1: Date, d2: Date) {
  if (!d1.getTime) {
    d1 = new Date(d1);
  }
  if (!d2.getTime) {
    d2 = new Date(d2);
  }
  return new Date(Math.max(d1.getTime(), d2.getTime()));
}
