import { AppEnvConfigPublic } from 'lib-common-model';
import { Loadable, useLoadable } from 'lib-web-redux';
import { distinctUntilChanged } from 'rxjs/operators';
import { appStore } from '../app-store';

export function useAppEnvConfigPublic(): AppEnvConfigPublic & Loadable {
  return useLoadable({
    debugName: 'appEnvConfigPublic',
    initialValueFromLoadSnapshot: true,
    load: () => appStore.appEnvConfigPublic.get().pipe(distinctUntilChanged()),
  });
}
