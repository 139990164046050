import { AppRemoteEnvId } from 'lib-web-common';
import React from 'react';
import { appWebConfig } from '_01_CORE/app-core/app-config';

export const DebugSwitchRemoteEnvButton = ({
  env,
  onSwitch,
  className,
}: {
  env: 'alpha' | 'beta' | 'prod';
  onSwitch: (remoteEnvId: AppRemoteEnvId) => void;
  className?: string;
}) => {
  const remoteEnvId: AppRemoteEnvId =
    appWebConfig().appId === 'accropark' ? `accro-${env}` : env;

  return (
    <button
      className={`ring-2 py-1 px-2 hover:outline-none ${
        appWebConfig().remoteEnvId === remoteEnvId
          ? 'text-red-500 ring-red-300'
          : 'text-blue-500 ring-blue-300'
      } ${className ?? ''}`}
      onClick={() => {
        onSwitch(remoteEnvId);
      }}
    >
      {env.toUpperCase()}
    </button>
  );
};
