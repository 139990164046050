import { useIntl } from 'react-intl';
import { AppIcons } from '_01_CORE/_components-core';

export const ActivitySessionDetailsDialogRegistrationPaidAreaSuccessMessage = ({
  className = '',
}: {
  className?: string;
}) => {
  const { formatMessage: t } = useIntl();

  return (
    <p className={`text-justify font-bold ${className}`}>
      <AppIcons.check className="inline-block w-4 h-4" />{' '}
      {t({
        id: 'page16.activity-session-details-dialog.paiement-recu',
      })}
    </p>
  );
};
