import { ResponsiveImage } from 'lib-common-model';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { imageUrlBuilder } from '_01_CORE/app-core/app-api';

export const AppTranslationContentImage = ({
  image,
  showEmptyImage,
  className,
}: {
  image: ResponsiveImage;
  showEmptyImage?: boolean;
  className?: string;
}) => {
  const { formatMessage: t } = useIntl();

  const imageSrc = useMemo(() => {
    const imageSrc = imageUrlBuilder.getImageFullUrl({
      image: image?.md,
      defaultUrl: showEmptyImage ? '/assets/images/empty.png' : undefined,
    });
    return imageSrc;
  }, [image, showEmptyImage]);

  return (
    imageSrc && (
      <div className={`w-full object-cover ${className}`}>
        <img className="w-full object-cover" src={imageSrc} />
      </div>
    )
  );
};
