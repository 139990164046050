import { IonButton, IonIcon } from '@ionic/react';
import {
  activityDateTester,
  dateTransformer,
  useAppModalContext,
} from '_01_CORE/_components-core';
import {
  useAppCacheContext,
  useAppLocalizationContext,
} from '_01_CORE/app-contexts';
import { UpdateActivitySessionParticipantAttendanceParams } from '_01_CORE/app-core/app-api';
import { useAppSettings } from '_01_CORE/app-core/app-security';
import { checkmark, closeOutline } from 'ionicons/icons';
import {
  ActivitySessionParticipantStatusUpdateReturnValue,
  CustomerActivityUpdateParticipantStateAction,
  PreRegistrationStatus,
  WaitingRegistrationStatus,
} from 'lib-common-model';
import { ApiClientHttpError } from 'lib-web-api-client';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import {
  CustomerActivityUpdateParticipantStateParams,
  JourneyActivitiesLocalState,
} from '../model';
import { RegistrationConfirmDeleteModal } from './RegistrationConfirmDeleteModal';

export function useJourneyActivitiesPageOnClickParticipant(
  localState: JourneyActivitiesLocalState
) {
  const { formatMessage: t } = useIntl();
  const { openModal, closeModal } = useAppModalContext();
  const appCacheContext = useAppCacheContext();
  const appSettings = useAppSettings();
  const { auth, dispatchCacheContextAction } = appCacheContext;

  const {
    locales: { platformLanguageCode, appLanguageCode },
  } = useAppLocalizationContext();

  const {
    networkStatus,
    pushError,
    customerJourneyId,
    journey,
    journeyActivitiesRepo,
    openWarningModal,
    openWarningModalFromCause,
    companyOptions,
    pendingOperations,
    setPendingOperations,
    aggregatedData,
  } = localState;

  const updateRegistration = useCallback(
    async ({
      journeyParticipantId,
      activityId,
      activitySessionId,
      preRegistrationStatus,
      waitingRegistrationStatus,
      action,
      throwError,
    }: {
      journeyParticipantId: string;
      activityId: string;
      activitySessionId: string;
      preRegistrationStatus?: PreRegistrationStatus;
      waitingRegistrationStatus?: WaitingRegistrationStatus;
      action: CustomerActivityUpdateParticipantStateAction;
      throwError?: boolean;
    }): Promise<ActivitySessionParticipantStatusUpdateReturnValue> => {
      try {
        setPendingOperations([
          ...pendingOperations,
          {
            id: 'toogle-attendance',
            activitySessionId,
            journeyParticipantId,
          },
        ]);
        const params: UpdateActivitySessionParticipantAttendanceParams = {
          customerJourneyId,
          activityId,
          activitySessionId,
          journeyParticipantId,
          preRegistrationStatus,
          waitingRegistrationStatus,
          platformLanguageCode,
          appLanguageCode,
          action,
        };
        const result = await journeyActivitiesRepo.actions.updateParticipantAttendance(
          params
        );
        return result;
      } catch (err: unknown) {
        const httpStatus = (err as ApiClientHttpError)?.response?.status;

        if (httpStatus === 423) {
          const cause = (err as ApiClientHttpError).json?.message;
          openWarningModalFromCause(cause);
        } else {
          if (!networkStatus?.connected) {
            pushError(t({ id: 'error.network.down' }));
          } else {
            pushError(t({ id: 'error.unexpected' }));
          }
          if (throwError) {
            throw err;
          }
        }
      } finally {
        setPendingOperations(
          pendingOperations.filter(
            (x) =>
              x.id === 'toogle-attendance' &&
              x.activitySessionId === activitySessionId &&
              x.journeyParticipantId === journeyParticipantId
          )
        );
      }
    },
    [
      appLanguageCode,
      customerJourneyId,
      journeyActivitiesRepo.actions,
      networkStatus?.connected,
      openWarningModalFromCause,
      pendingOperations,
      platformLanguageCode,
      pushError,
      setPendingOperations,
      t,
    ]
  );

  const openRegistrationConfirmDeleteModal = useCallback(
    (
      journeyParticipantId: string,
      activityId: string,
      activitySessionId: string,
      preRegistrationStatus: PreRegistrationStatus
    ) => {
      return openModal({
        title: t({ id: 'page16.registration.delete.title' }),
        content: null,
        actions: (
          <RegistrationConfirmDeleteModal
            onConfirm={async () => {
              try {
                return await updateRegistration({
                  journeyParticipantId,
                  activityId,
                  activitySessionId,
                  preRegistrationStatus,
                  throwError: true,
                  action: 'registration-cancel',
                });
              } finally {
                closeModal();
              }
            }}
            onCancel={async () => {
              closeModal();
            }}
          />
        ),
      });
    },
    [closeModal, openModal, t, updateRegistration]
  );

  const openWaitingListConfirmDeleteModal = useCallback(
    ({
      journeyParticipantId,
      activityId,
      activitySessionId,
      newWaitingRegistrationStatus,
    }: {
      journeyParticipantId: string;
      activityId: string;
      activitySessionId: string;
      newWaitingRegistrationStatus: WaitingRegistrationStatus;
    }) => {
      openModal({
        title: t({ id: 'page16.registration-waiting-list.delete.title' }),
        content: null,
        actions: (
          <div className="w-full">
            <IonButton
              className="w-full"
              color="primary"
              onClick={async () => {
                // register to waiting-list
                await updateRegistration({
                  journeyParticipantId,
                  activityId,
                  activitySessionId,
                  waitingRegistrationStatus: newWaitingRegistrationStatus,
                  action: 'registration-waiting-list-cancel',
                });
                closeModal();
              }}
            >
              <IonIcon icon={checkmark} className="mr-2" />
              {t({ id: 'common.action.ok' })}
            </IonButton>
            <IonButton className="w-full" color="danger" onClick={closeModal}>
              <IonIcon icon={closeOutline} className="mr-2" />
              {t({ id: 'common.action.cancel' })}
            </IonButton>
          </div>
        ),
      });
    },
    [closeModal, openModal, t, updateRegistration]
  );

  return useCallback(
    async ({
      dailyActivity,
      participantState,
      skipConfirmDelete,
      action,
    }: CustomerActivityUpdateParticipantStateParams) => {
      if (!journey?.status) {
        return;
      }
      const { selectedSession } = dailyActivity;

      if (
        selectedSession.registrationStatus === 'registration-customer-disabled'
      ) {
        journeyActivitiesRepo.actions.refreshPageActivities({
          cause: 'activity-action-disabled',
          activitySessionId: selectedSession.activitySession.activitySessionId,
        });
        openWarningModal({
          message: selectedSession.registrationCustomerDisabledMessage,
        });
        return;
      }

      if (
        !aggregatedData?.dailyActivities ||
        !journey?.company.options.preRegistration.enabled
      ) {
        openWarningModalFromCause('camping-registration-not-enabled');
        return;
      }

      const {
        journeyParticipant,
        activitySessionParticipant,
      } = participantState;

      if (
        activitySessionParticipant.preRegistrationStatus === 'no' &&
        selectedSession.registrationStatus === 'registration-not-open-yet'
      ) {
        journeyActivitiesRepo.actions.refreshPageActivities({
          cause: 'activity-action-disabled',
          activitySessionId: selectedSession.activitySession.activitySessionId,
        });
        const registrationBeginDate =
          selectedSession.activitySession.registrationBeginDate;
          
        const registrationBeginTimeId =
          selectedSession.activitySession.registrationBeginTimeId;
        const registrationBeginDateTimeUTC = activityDateTester.getRegistrationBeginDateTimeUTC(
          {
            appSettings,
            companyOptions,
            registrationBeginDate,
            registrationBeginTimeId,
          }
        );
        const date = dateTransformer.formatUTC(
          registrationBeginDateTimeUTC,
          'EEEE dd/MM/yyyy'
        );
        const time = dateTransformer.formatUTC(
          registrationBeginDateTimeUTC,
          'HH:mm'
        );

        const message = t(
          { id: 'page16.activity.registration.start' },
          { date, time }
        );
        openWarningModal({ message });
        return;
      }

      if (
        activitySessionParticipant.preRegistrationStatus === 'no' &&
        selectedSession.registrationStatus === 'registration-full'
      ) {
        const waitingListEnabled = companyOptions?.waitingList?.enabled;
        if (waitingListEnabled) {
          if (
            activitySessionParticipant.waitingRegistration?.status === 'waiting'
          ) {
            openWaitingListConfirmDeleteModal({
              journeyParticipantId: journeyParticipant.journeyParticipantId,
              activityId: activitySessionParticipant.activityId,
              activitySessionId: activitySessionParticipant.activitySessionId,
              newWaitingRegistrationStatus: 'no',
            });
            return;
          } else {
            // register to waiting-list
            const result = await updateRegistration({
              action: 'registration-waiting-list-join',
              journeyParticipantId: journeyParticipant.journeyParticipantId,
              activityId: activitySessionParticipant.activityId,
              activitySessionId: activitySessionParticipant.activitySessionId,
              waitingRegistrationStatus: !activitySessionParticipant.waitingRegistration
                ? 'waiting'
                : 'no',
            });
            return result;
          }
        } else {
          openWarningModalFromCause('registration-full');
          return;
        }
      }

      if (
        activitySessionParticipant.preRegistrationStatus === 'no' &&
        selectedSession.registrationStatus === 'registration-not-open'
      ) {
        openWarningModalFromCause('registration-not-open');
        return;
      }

      if (selectedSession.registrationStatus === 'activity-finished') {
        openWarningModalFromCause('activity-finished');
        return;
      }
      if (selectedSession.registrationStatus === 'activity-started') {
        openWarningModalFromCause('activity-started');
        return;
      }

      const newPreRegistrationStatus: PreRegistrationStatus =
        activitySessionParticipant.preRegistrationStatus === 'yes'
          ? 'no'
          : 'yes';

      if (newPreRegistrationStatus === 'no') {
        if (
          activitySessionParticipant.paymentStatus === 'payment-accepted' &&
          !activitySessionParticipant.packId
        ) {
          openWarningModalFromCause(
            'customer-payed-activity-cancel-registration-disabled'
          );
          return;
        } else if (skipConfirmDelete) {
          await updateRegistration({
            journeyParticipantId: journeyParticipant.journeyParticipantId,
            activityId: activitySessionParticipant.activityId,
            activitySessionId: activitySessionParticipant.activitySessionId,
            preRegistrationStatus: newPreRegistrationStatus,
            action: 'registration-cancel',
          });
        } else {
          return openRegistrationConfirmDeleteModal(
            journeyParticipant.journeyParticipantId,
            activitySessionParticipant.activityId,
            activitySessionParticipant.activitySessionId,
            newPreRegistrationStatus
          );
        }
        return;
      } else {
        // newPreRegistrationStatus === 'yes'
        if (journey?.status === 'draft') {
          dispatchCacheContextAction({
            type: 'update-journey-status',
            payload: {
              customerJourneyId,
              status: 'booking-in-progress',
            },
          });
        }
        const result = await updateRegistration({
          journeyParticipantId: journeyParticipant.journeyParticipantId,
          activityId: activitySessionParticipant.activityId,
          activitySessionId: activitySessionParticipant.activitySessionId,
          preRegistrationStatus: newPreRegistrationStatus,
          action: 'registration-confirm',
        });
        return result;
      }
    },
    [
      aggregatedData?.dailyActivities,
      appSettings,
      companyOptions,
      customerJourneyId,
      dispatchCacheContextAction,
      journey?.company.options.preRegistration.enabled,
      journey?.status,
      journeyActivitiesRepo.actions,
      openRegistrationConfirmDeleteModal,
      openWaitingListConfirmDeleteModal,
      openWarningModal,
      openWarningModalFromCause,
      t,
      updateRegistration,
    ]
  );
}
