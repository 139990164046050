import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { useCompanyBookletThemeSheet } from '_01_CORE/app-contexts';
import { appBrowser, useAppPlatform } from '_01_CORE/app-core/app-platform';
import {
  AppPage,
  HeaderToolbar,
  PageBackLink,
  PageContainer,
} from '_01_CORE/_common-layout';
import { AppTranslationContentPanel } from '_01_CORE/_components-core';
import { useAppRouter } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import './CompanyBookletSheetPage.css';

export const CompanyBookletSheetPage = React.memo(
  function CompanyBookletSheetPage() {
    const appRouter = useAppRouter();
    const { platform } = useAppPlatform();
    const {
      companyReference,
      bookletThemeReference,
      bookletSheetReference,
    } = useParams<{
      companyReference: string;
      bookletThemeReference: string;
      bookletSheetReference: string;
    }>();

    const { theme, sheet } = useCompanyBookletThemeSheet({
      companyReference,
      bookletThemeReference,
      bookletSheetReference,
    });

    const backUrl = useMemo(
      () =>
        theme?.sheets.length === 1
          ? appRoutesBuilder.getCompanyBookletUrl({ companyReference })
          : appRoutesBuilder.getCompanyBookletThemeUrl({
              companyReference,
              bookletThemeReference: theme?.reference,
            }),
      [companyReference, theme?.reference, theme?.sheets.length]
    );

    useEffect(() => {
      if (!sheet) {
        appRouter.navigateAfterDelay(
          appRoutesBuilder.getCompanyBookletThemeUrl({
            companyReference,
            bookletThemeReference,
          }),
          { cause: 'company-booklet-sheet-missing' }
        );
      } else {
        if (sheet?.redirect) {
          if (sheet?.redirect.url) {
            appBrowser.openUrl({
              platform,
              url: sheet?.redirect.url,
            });
          } else if (sheet?.redirect.page === 'animations') {
            // appBrowser.openActivitiesPage({ platform });
          }
        }
      }
    }, [
      appRouter,
      companyReference,
      platform,
      sheet,
      bookletSheetReference,
      theme,
      bookletThemeReference,
    ]);

    return !theme || !sheet ? null : (
      <AppPage>
        <HeaderToolbar />
        <PageContainer className="text-center bg-gray-50 relative">
          <PageBackLink mode="absolute" spacing="small" backLink={backUrl} />
          {/* <PageTitle spacing="small" title={sheet?.title} backLink={backUrl} /> */}
          <div className="CompanyBookletSheetPage-content px-2">
            <div className="infos-area">
              {/* {renderInfoSheet({ sheet, theme })} */}
              {sheet.contentItems.map((item, i) => (
                <AppTranslationContentPanel
                  companyReference={companyReference}
                  key={i}
                  item={item}
                />
              ))}
            </div>
          </div>
        </PageContainer>
      </AppPage>
    );
  }
);
