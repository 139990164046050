import clsx from 'clsx';
import {
  APP_CURRENCIES_MAP,
  AppCurrency,
  AppCurrencyIsoCode,
} from 'lib-common-model';
import { useMemo } from 'react';

export const AppPriceLabel = ({
  amount,
  cents: centsOverride,
  appCurrency: appCurrencyInput,
  appCurrencyCode,
  addSpacesToLargeNumbers,
  preserveSignClass,
  centsClassName,
  signClassName,
  className = '',
}: {
  amount: number;
  cents?: number;
  appCurrency?: AppCurrency;
  appCurrencyCode?: AppCurrencyIsoCode;
  addSpacesToLargeNumbers?: boolean;
  preserveSignClass?: boolean;
  centsClassName?: string;
  signClassName?: string;
  className?: string;
}) => {
  const appCurrency: AppCurrency = useMemo(
    () => appCurrencyInput ?? APP_CURRENCIES_MAP[appCurrencyCode],
    [appCurrencyInput, appCurrencyCode]
  );
  const signFontSize = preserveSignClass
    ? undefined
    : appCurrency.symbol.length > 1
    ? 'text-app-xxs1'
    : 'text-xs';

  const integersString = useMemo(() => {
    if (amount === null || amount === undefined) {
      return null;
    }
    if (addSpacesToLargeNumbers) {
      return `${Math.floor(amount)}`.replace(/(\d)(?=(\d{3})+$)/g, '$1 ');
    }
    return Math.floor(amount);
  }, [addSpacesToLargeNumbers, amount]);

  const cents = useMemo(() => {
    if (centsOverride !== undefined && centsOverride !== null) {
      return centsOverride;
    }
    return appCurrency.cents;
  }, [appCurrency.cents, centsOverride]);

  const centsString = useMemo(() => {
    if (amount === null || amount === undefined) {
      return null;
    }
    return cents > 0
      ? `${(amount - Math.floor(amount)).toFixed(cents)}`.substring(2)
      : '';
  }, [amount, cents]);

  return integersString === null ? null : (
    <span className={`inline-block whitespace-nowrap ${className}`}>
      <span className="">{integersString}</span>
      <span className={centsClassName}>{centsString && `,${centsString}`}</span>
      <span
        className={clsx(
          signClassName
            ? signClassName
            : clsx(
                signFontSize,
                'ml-0.5 text-bold'
                // !preserveSignClass && 'text-gray-600'
              )
        )}
      >
        {/* {isKiloDisplay && 'K'} */}
        {appCurrency.symbol}
      </span>
    </span>
  );
};
