import { IonIcon } from '@ionic/react';
import { chevronBack, chevronForward } from 'ionicons/icons';
import React, { useContext } from 'react';
import { AppDatePickerCtx } from './AppDatePickerContext';

/**
 * Year Selection Component
 * @param props
 */
export const AppYearSelection: React.FC<Record<string, unknown>> = () => {
  const {
    selectYear,
    prevDecade,
    nextDecade,
    visible: { year },
  } = useContext(AppDatePickerCtx);

  const years = [];
  const [minYear, maxYear] = [year - 6, year + 6];

  for (let i = minYear; i < maxYear; i++) {
    years.push(
      <button key={`year-${i}`} onClick={() => selectYear(i)}>
        {i}
      </button>
    );
  }

  return (
    <div
      className="h-48"
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        gridTemplateRows: '2rem auto',
        alignItems: 'stretch',
      }}
    >
      <div className="flex" style={{ gridColumn: '1/5' }}>
        <button onClick={() => prevDecade()}>
          <IonIcon icon={chevronBack} />
        </button>
        <button className="flex-grow">{`${minYear} - ${maxYear - 1}`}</button>
        <button onClick={() => nextDecade()}>
          <IonIcon icon={chevronForward} />
        </button>
      </div>

      {years}
    </div>
  );
};
