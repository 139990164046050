import { simpleStoreFactory } from 'lib-web-redux';
import { appWebConfig } from '../app-config';

export const baseStore = simpleStoreFactory.create(
  appWebConfig().debug.debugStore
);

export function getBaseStore() {
  return baseStore;
}
