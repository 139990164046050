import { useAppToasts } from '_01_CORE/_hooks';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useNetworkStatus } from './useNetworkStatus.hook';

export function useIsNetworkConnected(): () => boolean {
  const { networkStatus } = useNetworkStatus();
  const { pushError, pushSuccess } = useAppToasts();
  const { formatMessage: t } = useIntl();

  const isNetworkConnected = useCallback(() => {
    if (networkStatus && !networkStatus?.connected) {
      pushError(t({ id: 'page01.connectionOK' }));
      return false;
    } else {
      return true;
    }
  }, [networkStatus, pushError, t]);

  return isNetworkConnected;
}
