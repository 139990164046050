import { AppPriceLabel } from '_01_CORE/_common-layout';
import clsx from 'clsx';
import { AppCurrencyIsoCode } from 'lib-common-model';
import { CMRestoMenuDish } from 'lib-common-model/entities/app-customer-mobile-web/CMResto';
import { MouseEventHandler } from 'react';

export const CompanyRestoMenuSectionCardDish = ({
  dish,
  onClick,
}: {
  dish: CMRestoMenuDish;
  onClick?: MouseEventHandler<HTMLDivElement>;
}) => {
  const appCurrencyCode: AppCurrencyIsoCode = 'EUR';
  return (
    <div
      className={clsx(
        'group relative flex overflow-hidden rounded-lg border border-gray-200 hover:border-gray-400 bg-white',
        onClick && 'cursor-pointer'
      )}
      onClick={onClick}
    >
      <div className="text-left flex flex-1 flex-col space-y-2 p-4">
        <h3 className="text-sm sm:text-base font-bold  text-gray-700 group-hover:text-gray-900">
          <span aria-hidden="true" className="absolute inset-0" />
          {dish.translation.name}
        </h3>
        {dish.translation.comment?.trim()?.length > 0 && (
          <p className="text-xs text-gray-500 group-hover:text-gray-700">
            {dish.translation.comment}
          </p>
        )}
        <div className="flex flex-1 flex-col justify-end">
          {/* <p className="text-sm italic text-gray-500">
                          {product.options}
                        </p> */}
          <p className="text-right text-base font-medium text-gray-600 group-hover:text-gray-800">
            <AppPriceLabel
              amount={dish.basePrice?.unitPrice}
              appCurrencyCode={appCurrencyCode}
            />
          </p>
        </div>
      </div>
      <div
        className={clsx(
          'border-l border-gray-100 bg-gray-50 group-hover:opacity-75 w-40'
        )}
        style={{ height: '120px' }}
      >
        <div className="aspect-h-1 aspect-w-1 bg-gray-50 sm:aspect-none h-full max-w-full">
          {dish.image?.original && (
            <img
              src={dish.image?.original}
              // alt={product.imageAlt}
              className={clsx(
                'h-full w-full bg-gray-50 object-cover object-center'
              )}
            />
          )}
        </div>
      </div>
    </div>
  );
};
