import { IonIcon } from '@ionic/react';
import isPast from 'date-fns/isPast';
import isToday from 'date-fns/isToday';
import { chevronBack, chevronForward } from 'ionicons/icons';
import { MONTHS, WEEKDAYS } from 'lib-common-model';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { buttonClassName } from './AppDatePicker';
import { AppDatePickerCtx } from './AppDatePickerContext';
import { DayComponentProps } from './DayComponentProps.type';
/**
 * Beginning of Day of Week of a Month
 * @param date
 */
function beginningDayOfWeek(m: number, y: number): number {
  return new Date(y, m, 0).getDay();
}

/**
 * Days in month
 */
function daysInMonth(month: number, year: number) {
  switch (month) {
    case 0:
    case 2:
    case 4:
    case 6:
    case 7:
    case 9:
    case 11:
      return 31;
    case 1:
      return isLeapYear(year) ? 29 : 28;
    default:
      return 30;
  }
}

/**
 * Is Leap Year
 * @param year
 */
function isLeapYear(year: number): boolean {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}

/**
 * Date Selection Component
 * @param props
 */
export const AppDateSelection: React.FC<Record<string, unknown>> = () => {
  const {
    nextMonth,
    prevMonth,
    viewMonths,
    viewYears,
    visible: { month, year },
    isSelectedDate,
    isDisabledDate,
    selectDate,
    getDayComponent,
    getLegendComponent,
  } = useContext(AppDatePickerCtx);
  const { formatMessage: t } = useIntl();
  const daysOfWeekNames = WEEKDAYS.map((d) => t({ id: `common.weekday.${d}` }));
  const monthNames = MONTHS.map((m) => t({ id: `month.${m}` }));

  const dates = [];

  for (let i = 0; i < beginningDayOfWeek(month, year); i++) {
    dates.push(<div key={`emptybefore${i}`}></div>);
  }

  for (let day = 1; day <= daysInMonth(month, year); day++) {
    const date = new Date(Date.UTC(year, month, day));
    const isDateToday = isToday(date);
    const props: DayComponentProps = {
      date,
      day,
      isSelected: isSelectedDate(day),
      isDisabled: isDisabledDate(day),
      isPast: !isDateToday && isPast(date),
      isToday: isDateToday,
      onClick: () => selectDate(day),
    };
    dates.push(getDayComponent(props));
  }

  return (
    <div>
      <div
        className="text-gray-800 items-center"
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
          gridTemplateRows: '2rem auto',
        }}
      >
        <button className={buttonClassName} onClick={() => prevMonth()}>
          <IonIcon size={'20'} className="stroke-current" icon={chevronBack} />
        </button>

        <button
          className={`${buttonClassName} font-semibold`}
          style={{ gridColumn: '2/5' }}
          onClick={() => {
            viewMonths();
          }}
        >
          {monthNames[month]}
        </button>

        <button
          className={`${buttonClassName} font-semibold`}
          style={{ gridColumn: '5/7' }}
          onClick={() => viewYears()}
        >
          {year}
        </button>

        <button className={buttonClassName} onClick={() => nextMonth()}>
          <IonIcon
            size={'20'}
            className="stroke-current"
            icon={chevronForward}
          />
        </button>

        {daysOfWeekNames.map((day) => (
          <div
            key={(200 + day).toString()}
            className="p-1 text-sm font-semibold text-center"
          >
            {day[0]}
          </div>
        ))}

        {dates}
      </div>
      {getLegendComponent()}
    </div>
  );
};
