import { IonIcon, IonRouterLink } from '@ionic/react';
import {
  AppPage,
  FooterActions,
  HeaderToolbar,
  PageContainer,
} from '_01_CORE/_common-layout';
import {
  AppLoader,
  dateTransformer,
  fakeDataGenerator,
} from '_01_CORE/_components-core';
import {
  useAppRouter,
  useAppToasts,
  useJourneyCancellation,
  useOperationPending,
} from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import { journeyHelper } from '_01_CORE/_services';
import { useAppCacheContext } from '_01_CORE/app-contexts';
import { customerJourneyApiClient } from '_01_CORE/app-core/app-api';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import { useNetworkStatus } from '_01_CORE/app-core/app-platform';
import { useDisplayPickupKids } from '_02_APP_COMMON/JOU-journey/_common';
import { CustomerAccountEditFormData } from '_02_APP_COMMON/_model';
import { build, chevronBackOutline } from 'ionicons/icons';
import {
  CMJourneyEditCustomerAccount,
  CustomerAccountCreatePayload,
} from 'lib-common-model';
import { appLogger } from 'lib-web-logger';
import { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { AnimLiveCustomerAccountForm } from './AnimLiveCustomerAccountForm';
import { useAnimLiveCustomerAccountEditDefaultValues } from './useAnimLiveCustomerAccountEditDefaultValues.hook';

export const AnimLiveCustomerAccountEditPage = () => {
  const appRouter = useAppRouter();
  const { formatMessage: t } = useIntl();
  const {
    auth,
    dispatchCacheContextAction,
    journeyEditData,
  } = useAppCacheContext();

  const { companyReference } = useParams<{ companyReference: string }>();
  const { pushError } = useAppToasts();
  const { networkStatus } = useNetworkStatus();
  const { openModalCancelJourneyDraft } = useJourneyCancellation({
    companyReference,
  });

  const displayPickUpKids = useDisplayPickupKids();

  const defaultValues = useAnimLiveCustomerAccountEditDefaultValues({
    journeyEditData,
  });

  const form = useForm<CustomerAccountEditFormData>({
    defaultValues,
    mode: 'onChange',
  });
  const {
    handleSubmit,
    formState,
    getValues,
    setValue,
    control,
    trigger,
  } = form;

  const [email] = useWatch({
    control,
    name: ['email'],
  });

  const goBack = () => {
    if (displayPickUpKids) {
      appRouter.navigate(
        appRoutesBuilder.getJourneyCreateDetailsAnimLiveUrl({
          companyReference: companyReference,
        }),
        { cause: 'customer-account-back' }
      );
    } else {
      appRouter.navigate(
        appRoutesBuilder.getJourneyCreateParticipantsListUrl({
          companyReference,
        }),
        { cause: 'customer-account-back' }
      );
    }
  };
  // NOTE: avant: isAnyAdmin limitait aux pro admin (maintenant, plus de restriction en fonction du profil, peut-être qu'il faudra parser les roles "az")
  const isAnyAdmin =
    auth?.isSuperAdmin || auth?.securityUser?.profile === 'company';

  function autoFillForm() {
    const defaultValues = getValues();
    const lastName = fakeDataGenerator.getLastName();
    const firstName = fakeDataGenerator.getLastName(); 
    if (!defaultValues.lastName?.trim()?.length) {
      setValue('lastName', lastName);
    }
    if (!defaultValues.firstName?.trim()?.length) {
      setValue('firstName', firstName);
    }
    if (!defaultValues.gender) {
      setValue('gender', fakeDataGenerator.getGender());
    }
    if (!defaultValues.country) {
      setValue('country', fakeDataGenerator.getCountry().id);
    }
    if (!defaultValues.email) {
      setValue('email', fakeDataGenerator.getEmail({ lastName, firstName }));
    }
    if (!defaultValues.language) {
      setValue('language', fakeDataGenerator.getLanguage().id);
    }
    if (!defaultValues.language) {
      setValue('phone1', fakeDataGenerator.getPhone());
    }
    setValue('password', 'a');
    setValue('consent', true);
    setValue('electronicSignature', true);
    setValue('locationArea', '75000 Paris');
  }
  const [persistInProgress, setPersistInProgress] = useState(false);

  /*
   * Submit form callback
   */
  const onSubmit = useOperationPending(
    async (data: CustomerAccountEditFormData) => {
      if (persistInProgress) {
        return;
      }
      setPersistInProgress(true);
      try {
        const customerAccount: CMJourneyEditCustomerAccount = journeyHelper.buildCustomerAccount(
          {
            existingCustomerAccount: journeyEditData.customerAccount,
            data,
          }
        );

        const payload: CustomerAccountCreatePayload = {
          ...journeyEditData,
          customerAccount,
        };

        // anim live: create account now
        const {
          result,
          err,
          errStatus,
          errMessage,
          customerJourneyId,
        } = await customerJourneyApiClient.createOrUpdateCustomerAccountJourney(
          payload
        );

        if (result !== 'success') {
          // Save in redux cache
          dispatchCacheContextAction({
            type: 'update-journey-account-profile',
            payload: data,
          });
        }
        switch (result) {
          case 'email-already-exists':
            appLogger.error(
              'Erreur: un compte avec cette adresse e-mail existe déjà'
            );
            pushError(t({ id: 'error.account.exist' }));
            break;
          case 'unexpected-error':
            if (!networkStatus?.connected) {
              appLogger.warn(
                'Erreur: veuillez vérifier votre connexion Internet'
              );
              pushError(t({ id: 'error.network.down' }));
            } else {
              appLogger.error('Account creation error', { err, data });
              pushError(
                t({ id: 'error.unexpected' }) +
                  `: ${errStatus ? `[${errStatus}]` : ''} ${
                    errMessage ?? err?.name
                  } ${errMessage ?? err?.message} (${dateTransformer.formatUTC(
                    new Date(),
                    'dd/MM/yyyy HH:mm:ss'
                  )} - ${appWebConfig().appVersion})`,
                10000
              );
            }
            break;
          case 'success':
            // redirect to journey time tables
            appRouter.navigate(
              appRoutesBuilder.getJourneyHomeUrl({
                customerJourneyId: customerJourneyId,
              }),
              { cause: 'customer-account-submit' }
            );
            // user is authenticated: clear cache
            dispatchCacheContextAction({
              type: 'clear-update-journey-cache',
              context: 'AnimLiveCustomerAccountEditPage:onSubmit:success',
            });
            break;
        }
      } finally {
        setPersistInProgress(false);
      }
    },
    [
      appRouter,
      dispatchCacheContextAction,
      journeyEditData,
      networkStatus?.connected,
      persistInProgress,
      pushError,
      t,
    ]
  );

  const onInvalid = () => {
    pushError(t({ id: 'common.message.error.invalid-form' }));
  };

  return (
    journeyEditData && (
      <AppPage displayFooter={false}>
        <HeaderToolbar hideLogout={true} />
        <PageContainer className="text-center bg-gray-50" spacing="normal">
          <div className="flex mb-2 place-items-center">
            <IonRouterLink className="cursor-pointer" onClick={goBack}>
              <IonIcon className="text-primary" icon={chevronBackOutline} />
            </IonRouterLink>
            <div className="ml-8 text-primary text-left flex-grow place-self-start text-xl mm:text-2xl ml:text-3xl sm:text-4xl">
              {t({ id: 'page14.holder' })}
            </div>
            {appWebConfig().envId === 'dev' && (
              <div className="absolute right-8 text-2xl cursor-pointer">
                <IonIcon
                  className="mr-2"
                  color="primary"
                  icon={build}
                  onClick={() => autoFillForm()}
                />
              </div>
            )}
          </div>
          {persistInProgress ? (
            <AppLoader className="my-4 p-2 px-4" type="persist" />
          ) : (
            <AnimLiveCustomerAccountForm
              form={form}
              onSubmit={onSubmit}
              onInvalid={onInvalid}
            />
          )}
        </PageContainer>
        <FooterActions
          hidden={persistInProgress}
          onCancel={openModalCancelJourneyDraft}
          onBack={goBack}
          onSubmit={handleSubmit(onSubmit, onInvalid)}
        />
      </AppPage>
    )
  );
};
