import React, { FC, useMemo } from 'react';

export const AppButtonV2: FC<{
  className?: string;
  icon?: (props: React.ComponentProps<'svg'>) => JSX.Element;
  buttonStyle:
    | 'outline-transparent-on-hover'
    | 'outline-transparent'
    | 'outline-primary'
    | 'primary'
    // | 'primary-theme'
    | 'primary-accropark'
    | 'success'
    | 'cancel'
    | 'danger';
  size?: 'small' | 'normal' | 'large';
  centerText?: boolean;
  href?: string;
  target?: string;
  onClick?: () => void;
}> = ({
  children,
  icon,
  buttonStyle,
  size = 'normal',
  centerText,
  className,
  href,
  target,
  onClick,
}) => {
  const buttonStyleClass = useMemo(() => {
    let classes =
      size === 'large'
        ? 'text-base sm:text-lg px-4 sm:px-8 py-4'
        : size === 'normal'
        ? 'text-sm px-4 py-2'
        : 'text-xs px-2 py-1';
    switch (buttonStyle) {
      case 'primary':
        classes +=
          ' border-gray-300 text-white bg-app-primary hover:bg-app-dark-blue';
        break;
      case 'success':
        classes +=
          ' border-app-success-shade text-white bg-app-success hover:bg-app-success-shade';
        break;
      case 'danger':
        classes +=
          ' border-app-danger-shade text-gray-200 bg-app-danger hover:bg-app-danger-shade';
        break;
      // case 'primary-theme':
      //   classes += ` border-gray-300 text-gray-200 bg-app-theme-${appTheme}-primary hover:bg-app-theme-${appTheme}-primary-shade`;
      //   break;
      case 'primary-accropark':
        classes += ` border-gray-300 text-gray-200 bg-app-theme-accropark-primary hover:bg-app-theme-accropark-primary-shade`;
        break;
      case 'outline-primary':
        classes +=
          ' border-gray-300 text-gray-200 bg-app-primary hover:bg-app-dark-blue';
        break;
      case 'cancel':
        classes +=
          ' border-gray-800 text-gray-700 bg-gray-200 hover:bg-gray-300';
        break;
      case 'outline-transparent':
        classes += ' border-gray-300 text-gray-700 bg-white hover:bg-gray-100';
        break;
      case 'outline-transparent-on-hover':
        classes +=
          'border-transparent hover:border-gray-300 text-gray-700 bg-white hover:bg-gray-100';
        break;
    }
    return classes;
  }, [buttonStyle, size]);

  const iconStyleClass = useMemo(
    () =>
      size === 'large'
        ? 'mr-4 h-14 w-14 text-gray-800 rounded bg-white p-2'
        : size === 'normal'
        ? 'mr-2 h-5 w-5'
        : 'mr-1 h-3 w-3',
    [size]
  );

  const AppButtonIcon = icon;
  return href ? (
    <a
      target={target}
      href={href}
      onClick={onClick}
      className={`ring-0 cursor-pointer relative inline-flex items-center border shadow-sm font-medium rounded focus:outline-none ${buttonStyleClass} ${
        className ?? ''
      }`}
    >
      {AppButtonIcon && (
        <AppButtonIcon
          className={`-ml-1 ${iconStyleClass}`}
          aria-hidden="true"
        />
      )}
      <div
        className={`flex-grow text-center ${
          AppButtonIcon && centerText ? '-ml-5' : ''
        }`}
      >
        {children}
      </div>
    </a>
  ) : (
    <button
      onClick={onClick}
      className={`ring-0 cursor-pointer relative inline-flex items-center border shadow-sm font-medium rounded focus:outline-none ${buttonStyleClass} ${
        className ?? ''
      }`}
    >
      {AppButtonIcon && (
        <AppButtonIcon
          className={`-ml-1 ${iconStyleClass}`}
          aria-hidden="true"
        />
      )}
      <div
        className={`flex-grow text-center ${
          AppButtonIcon && centerText ? '-ml-5' : ''
        }`}
      >
        {children}
      </div>
    </button>
  );
};
