import { useIntl } from 'react-intl';
import { CustomerActivityParticipantsDailyActivitySession } from './model';
import { CustomerActivityParticipantsDailyActivity } from './model/CustomerActivityParticipantsDailyActivity.type';

// NOTE: version light de JourneyDailyActivityAvailability
export const JourneyDailyActivityAvailabilityTimeOption = ({
  selectedSession: selectedSessionInput,
  dailyActivity,
  className = '',
}: {
  selectedSession?: CustomerActivityParticipantsDailyActivitySession;
  dailyActivity: CustomerActivityParticipantsDailyActivity;
  className?: string;
}) => {
  const { formatMessage: t } = useIntl();

  const selectedSession = selectedSessionInput ?? dailyActivity.selectedSession;

  const activitySession = selectedSession.activitySession;

  return (
    <>
      <div
        className={`${className} mt-0.5 ${
          activitySession.availableRegistrationsStatus === 'full'
            ? 'text-danger'
            : activitySession.availableRegistrationsStatus === 'busy'
            ? 'text-warning'
            : 'text-app-success-shade'
        }`}
      >
        {selectedSession?.isSessionFinished ? (
          <div>-</div>
        ) : dailyActivity.openSessionsFuture.length !== 0 ? (
          <>
            {activitySession.availableRegistrationsStatus === 'full' ? (
              <div className="flex-grow uppercase">
                {t({
                  id: 'page16.slots.full', // 'Complet'
                })}
              </div>
            ) : selectedSession.registrationStatus === 'open' &&
              activitySession.availableRegistrationsCount > 0 ? (
              <div className="flex-grow">
                {t(
                  {
                    id: 'page16.slots.available.short', // '{count} places'
                  },
                  { count: activitySession.availableRegistrationsCount }
                )}
              </div>
            ) : (
              <div>-</div>
            )}
          </>
        ) : (
          <div>-</div>
        )}
      </div>
    </>
  );
};
