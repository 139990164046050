import { Browser } from '@capacitor/browser';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import { authApiClient } from '_01_CORE/app-core/app-api';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import { appLogger } from 'lib-web-logger';

export const appBrowser = {
  openAnimLivePaymentPage,
  buildAnimLivePaymentPageURL,
  openBamboraPaymentPage,
  openUrl,
  closeBrowser,
  openProHomePage,
};

function buildAnimLivePaymentPageURL({
  customerJourneyId,
  shoppingCartId,
}: {
  customerJourneyId: string;
  shoppingCartId: string;
}): Promise<string> {
  return authApiClient.getTmpToken().then(
    (res) => {
      const { token } = res;
      const pageBaseUrl = appRoutesBuilder.getJourneyShoppingCartPaymentUrl({
        customerJourneyId,
        shoppingCartId,
      });
      const url = `${
        appWebConfig().urls.customerWebMobile
      }${pageBaseUrl}?authenticationToken=${token}&page-mode=app-slave`;
      return url;
    },
    (err) => {
      appLogger.error(
        '[appBrowser.openBamboraPaymentPage] ERROR getting token',
        {
          err,
        }
      );
      return undefined;
    }
  );
}

function openAnimLivePaymentPage({
  url,
  platform,
}: {
  url: string;
  platform: string;
}) {
  return openUrl({ platform, url, windowName: 'anim-live-payment' });
}
function openBamboraPaymentPage({
  customerJourneyId,
  platform,
}: {
  customerJourneyId: string;
  platform: string;
}) {
  return authApiClient.getTmpToken().then(
    (res) => {
      const { token } = res;
      const url = `${
        appWebConfig().urls.customerWebMobile
      }/journey/${customerJourneyId}/payment?authenticationToken=${token}&open-from-app=true`;
      return openUrl({ platform, url, windowName: 'bambora-payment' });
    },
    (err) => {
      appLogger.error(
        '[appBrowser.openBamboraPaymentPage] ERROR getting token',
        {
          err,
        }
      );
    }
  );
}

function openProHomePage({ platform }: { platform: string }) {
  return authApiClient.getTmpToken().then(
    (res) => {
      const { token } = res;
      const url = `${
        appWebConfig().urls.proWeb
      }/?authenticationToken=${token}&open-from-app=true`;
      return openUrl({ platform, url, windowName: 'pro-web' });
    },
    (err) => {
      appLogger.error('[appBrowser.openProHomePage] ERROR getting token', {
        err,
      });
    }
  );
}

function openUrl({
  platform,
  url,
  windowName,
}: {
  platform: string;
  url: string;
  windowName?: string;
}) {
  if (platform === 'web') {
    // web: external browser navigation
    // window.location.href = url;
    return Browser.open({ url, windowName });
  } else {
    // native: open in-app browser
    return Browser.open({ url, windowName });
  }
}

function closeBrowser() {
  return Browser.close().catch((err) => {
    console.error('[appBrowser.closeBrowser] ERROR closing browser', {
      err,
    });
  });
}
