import { useAppImageUrl } from '_01_CORE/app-core/app-security/useAppImageUrl.hook';
import clsx from 'clsx';
import { CMExtJourneyToActivate } from 'lib-common-model';
import React from 'react';
import { useIntl } from 'react-intl';
import { dateTransformer } from '../date';
import { AppIcons } from '../icons';

export const ExtJourneyToActivateCard: React.FC<{
  extJourneyToActivate: CMExtJourneyToActivate;
  onClick?: () => void;
}> = ({ extJourneyToActivate, onClick }) => {
  const { formatMessage: t } = useIntl();
  const {
    company,
    extCustomerJourneyBookingGroup,
    extCustomerJourney,
  } = extJourneyToActivate;

  const { beginDateUTC, endDateInclusiveUTC } = extCustomerJourneyBookingGroup;

  const logo = useAppImageUrl(company?.logo, {
    defaultImage: 'app',
  });

  return (
    <>
      <div
        className={clsx(
          'group bg-white flex items-center gap-4 cursor-pointer text-gray-600',
          onClick && 'hover:bg-gray-200 hover:text-gray-800'
        )}
        onClick={() => {
          onClick && onClick();
        }}
        style={{
          // style from IonItem
          minHeight: '48px',
        }}
      >
        <div
          className="flex-shrink-0"
          style={{
            // style from IonItem
            paddingLeft: '16px',
          }}
        >
          {logo && (
            <img
              style={{
                // style from IonItem
                width: '40px',
                height: '40px',
              }}
              src={logo}
            />
          )}
        </div>
        <div className="py-2 flex-grow flex flex-col gap-2 justify-between items-start">
          <div className="w-full flex-grow flex gap-2 justify-between">
            <h2 className="whitespace-normal ">{company.name}</h2>
            {/* {extCustomerJourney.accommodationSites.length > 0 && (
              <div
                className={clsx(
                  'text-xs font-bold text-gray-400 flex gap-2',
                  onClick && 'group-hover:text-gray-500 '
                )}
              >
                {extCustomerJourney.accommodationSites.map(
                  (site, siteIndex) => (
                    <div key={siteIndex}>{site.reference}</div>
                  )
                )}
              </div>
            )} */}
          </div>
          <div className="flex text-sm gap-x-4">
            <span>
              {t({ id: 'component.journey.startDate' })}:{' '}
              {dateTransformer.formatUTC(
                beginDateUTC,
                t({ id: 'common.date.format' })
              )}
            </span>
            <span>
              {t({ id: 'component.journey.endDate' })}:{' '}
              {dateTransformer.formatUTC(
                endDateInclusiveUTC,
                t({ id: 'common.date.format' })
              )}
            </span>
          </div>
        </div>
        <div className="flex-shrink-0 flex flex-wrap gap-x-4 text-sm cursor-pointer pr-2">
          <span className="flex flex-col text-app-success">
            <AppIcons.actionAddCircle className="w-6 h-6 m-auto" />
            <span
              className={clsx(
                'text-gray-800 uppercase text-app-xxs1 sm:text-xs font-bold',
                onclick && 'group-hover:text-gray-900'
              )}
            >
              {'Activer'}
            </span>
          </span>
        </div>
      </div>
    </>
  );
};
