import { IonIcon, IonLabel, IonTabButton, IonToolbar } from '@ionic/react';
import { arrowBack, checkmark, close } from 'ionicons/icons';
import React from 'react';
import { useIntl } from 'react-intl';

type FooterActionsProps = {
  cancelText?: string;
  backText?: string;
  submitText?: string;
  onCancel?: () => void;
  onBack?: () => void;
  onSubmit?: () => void;
  hidden?: boolean;
};

export const FooterActions: React.FC<FooterActionsProps> = (props) => {
  const { formatMessage: t } = useIntl();

  const cancelText = props.cancelText ?? t({ id: 'common.action.cancel' });
  const backText = props.backText ?? t({ id: 'common.action.back' });
  const validateText = props.submitText ?? t({ id: 'common.action.validate' });

  if (!props.onCancel && !props.onBack && !props.onSubmit) {
    throw new Error(
      `You should not instanciate a FooterActions without at least one of the following props: onCancel, onBack, onSubmit`
    );
  }

  return (
    <>
      <IonToolbar slot="bottom" hidden={props.hidden}>
        <div className="max-w-screen-sm mx-auto flex justify-between items-center border-t">
          {props.onCancel ? (
            <div className="ml-4" onClick={() => props.onCancel?.()}>
              <IonTabButton>
                <IonIcon
                  className="bg-danger w-10 h-10 rounded-full mt-2 text-white"
                  icon={close}
                />
                <IonLabel color="danger">{cancelText}</IonLabel>
              </IonTabButton>
            </div>
          ) : (
            <div className="w-16"></div>
          )}
          {props.onBack ? (
            <div onClick={() => props.onBack?.()}>
              <IonTabButton>
                <IonIcon
                  className="bg-secondary w-10 h-10 rounded-full mt-2 text-white"
                  icon={arrowBack}
                />
                <IonLabel>{backText}</IonLabel>
              </IonTabButton>
            </div>
          ) : (
            <div className="w-16"></div>
          )}
          {props.onSubmit ? (
            <div className="mr-4" onClick={() => props.onSubmit?.()}>
              <IonTabButton>
                <IonIcon
                  className="bg-primary w-10 h-10 rounded-full mt-2 text-white"
                  icon={checkmark}
                />
                <IonLabel color="primary">{validateText}</IonLabel>
              </IonTabButton>
            </div>
          ) : (
            <div className="w-16"></div>
          )}
        </div>
      </IonToolbar>
    </>
  );
};
