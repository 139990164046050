import {
  AppPage,
  HeaderToolbar,
  PageContainer,
  PageTitle,
} from '_01_CORE/_common-layout';
import { useAppRouter } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import {
  useAppCacheContext,
  useAppLocalizationContext,
} from '_01_CORE/app-contexts';
import { customerNotificationsApiClient } from '_01_CORE/app-core/app-api';
import { AppClientNotification } from 'lib-common-model';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { NotificationItem } from './NotificationItem';
import { NotificationMessageDetailsDialog } from './NotificationMessageDetailsDialog';

export const NotificationsListPage = () => {
  const { formatMessage: t } = useIntl();
  const { notifications, dispatchCacheContextAction } = useAppCacheContext();
  const sortedNotifications = useMemo(
    () => notifications.sort((a, b) => (a.sendDate < b.sendDate ? 1 : -1)),
    [notifications]
  );

  const {
    locales: { platformLanguageCode, appLanguageCode },
  } = useAppLocalizationContext();

  useEffect(() => {
    (async () => {
      //Hide the new notification chip when user read notifications
      const notifications = await customerNotificationsApiClient.readNotifications(
        {
          appLanguageCode,
          platformLanguageCode,
        }
      );
      dispatchCacheContextAction({
        type: 'set-notifications',
        notifications,
        context: 'fetch-notif-page',
      });
    })();
  }, [appLanguageCode, dispatchCacheContextAction, platformLanguageCode]);

  const [
    notificationMessageDetailsDialogState,
    setNotificationMessageDetailsDialogState,
  ] = useState<{
    notification?: AppClientNotification;
  }>();

  const appRouter = useAppRouter();

  return (
    <AppPage tabId="notifications">
      <HeaderToolbar />
      <PageContainer className="text-center bg-gray-50" spacing="normal">
        <PageTitle
          title={t({ id: 'page06.journal' })}
          backLink={appRoutesBuilder.getRedirectToDefaultUrl()}
        />
        {sortedNotifications.map((notification, i) => (
          <NotificationItem
            key={i}
            notification={notification}
            onClick={() => {
              setNotificationMessageDetailsDialogState({ notification });
            }}
          />
        ))}
        {notificationMessageDetailsDialogState?.notification && (
          <NotificationMessageDetailsDialog
            notification={notificationMessageDetailsDialogState?.notification}
            onClose={() => {
              setNotificationMessageDetailsDialogState({
                notification: null,
              });
            }}
          />
        )}
      </PageContainer>
    </AppPage>
  );
};
