import {
  IonIcon,
  IonInput,
  IonRouterLink,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import { chevronBackOutline } from 'ionicons/icons';
import { CMJourneyEditData, RELATIONSHIP_OPTIONS } from 'lib-common-model';
import { appLogger } from 'lib-web-logger';
import { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { useAppCacheContext, useCompany } from '_01_CORE/app-contexts';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import { useAppSettings } from '_01_CORE/app-core/app-security';
import {
  AccountCreationTitle,
  AppPage,
  FooterActions,
  HeaderToolbar,
  PageContainer,
} from '_01_CORE/_common-layout';
import { FormError } from '_01_CORE/_components-core';
import { AppInputIonic } from '_01_CORE/_components-core/AppInput';
import {
  useAppRouter,
  useAppToasts,
  useJourneyCancellation,
} from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import { useDisplayPickupKids } from '_02_APP_COMMON/JOU-journey/_common';
import { JourneyCreateDetailsFormData } from './JourneyCreateDetailsFormData.type';
import { useJourneyCreateDetailsAnimLiveDefaultValues } from './useJourneyCreateDetailsAnimLiveDefaultValues.hook';

export const JourneyCreateDetailsAnimLivePage = () => {
  const appRouter = useAppRouter();
  const { formatMessage: t } = useIntl();
  const appCacheContext = useAppCacheContext();
  const { auth, journeyEditData, dispatchCacheContextAction } = appCacheContext;
  const appId = appWebConfig().appId;

  const { companyReference } = useParams<{ companyReference: string }>();
  const { company } = useCompany({ companyReference });
  const { pushError } = useAppToasts();
  const { openModalCancelJourneyDraft } = useJourneyCancellation({
    companyReference,
  });

  const defaultValues = useJourneyCreateDetailsAnimLiveDefaultValues();

  const displayPickUpKids = useDisplayPickupKids();

  const {
    handleSubmit,
    formState,
    control,
    reset,
  } = useForm<JourneyCreateDetailsFormData>({
    defaultValues,
    mode: 'onChange',
  });
  const errors = formState.errors;

  const appSettings = useAppSettings();
  const whiteLabel = appSettings?.universe?.whiteLabel;
  /**
   * Choose another destination button callback
   */
  const handleChooseDestination = useCallback(() => {
    appRouter.navigate(
      appRoutesBuilder.getJourneyCompanyChooseUrl({
        companyReference: companyReference,
        whiteLabel,
      }),
      { cause: 'participant-details-select-company' }
    );
  }, [appRouter, companyReference, whiteLabel]);

  /*
   * Submit form callback
   */
  const saveFormData = useCallback(
    (data: JourneyCreateDetailsFormData) => {
      appLogger.debug('submit data', data);

      // Save in redux cache
      const payload: Pick<
        CMJourneyEditData,
        'journey' | 'allowedPeopleList'
      > = {
        journey: data.journey,
        allowedPeopleList:
          data.allowedPeople4Array ?? [].filter((x) => !!x.name),
      };
      dispatchCacheContextAction({
        type: 'update-journey-details',
        payload,
      });
    },
    [dispatchCacheContextAction]
  );

  useEffect(() => {
    if (!displayPickUpKids) {
      saveFormData(defaultValues);

      appRouter.navigate(
        appRoutesBuilder.getAccountEditAnimLiveUrl({ companyReference }),
        { cause: 'participant-details-submit' }
      );
    }
  }, [
    appRouter,
    companyReference,
    defaultValues,
    displayPickUpKids,
    reset,
    saveFormData,
  ]);

  const onSubmitForm = () => {
    handleSubmit(
      (data) => {
        saveFormData(data);
        appRouter.navigate(
          appRoutesBuilder.getAccountEditAnimLiveUrl({ companyReference }),
          { cause: 'participant-details-submit' }
        );
      },
      (err) => {
        // TODO
        console.log('Invalid form', err);
      }
    )();
  };

  const goBack = () => {
    appRouter.navigate(
      appRoutesBuilder.getJourneyCreateParticipantsListUrl({
        companyReference,
      }),
      { cause: 'participant-details-back' }
    );
  };

  return !journeyEditData || !defaultValues ? null : (
    <AppPage displayFooter={false}>
      <HeaderToolbar hideLogout={true} />
      <PageContainer className="text-center bg-gray-50" spacing="normal">
        <AccountCreationTitle
          company={company}
          displayAddress={true}
          displayButtonChooseDestination={
            !whiteLabel.appCompanyReference && auth?.isAuthenticated
          }
          buttonText={t({ id: `page10.choose.${appId}` })}
          onClick={handleChooseDestination}
        />

        <div className="flex gap-2 mb-2 items-start">
          <IonRouterLink className="cursor-pointer" onClick={() => goBack()}>
            <IonIcon className="text-primary" icon={chevronBackOutline} />
          </IonRouterLink>
          <div className="text-primary text-left flex-grow text-md mm:text-xl ml:text-2xl sm:text-3xl">
            {t({ id: 'page13.authorizedPersons' })}
          </div>
        </div>

        <form onSubmit={onSubmitForm}>
          {displayPickUpKids && (
            <>
              {Array.from(Array(4).keys()).map((i) => {
                return (
                  <div
                    className="w-full grid grid-cols-2 gap-2"
                    key={`related-${i}`}
                  >
                    <div className="mt-2">
                      <Controller
                        render={({ field: { value, onChange } }) => (
                          <AppInputIonic
                            required={i === 0}
                            value={value}
                            placeholder={t(
                              { id: 'page13.person' },
                              { number: i + 1 }
                            )}
                          >
                            <IonInput onIonChange={onChange} />
                          </AppInputIonic>
                        )}
                        control={control}
                        name={`allowedPeople4Array[${i}].name` as any}
                        defaultValue=""
                        rules={{
                          required: {
                            value: i === 0,
                            message: t({ id: 'validation.required' }),
                          },
                        }}
                      />
                      <FormError
                        error={errors?.allowedPeople4Array?.[i]?.name?.message}
                      />
                    </div>
                    <div className="mt-2">
                      <Controller
                        render={({ field: { value, onChange } }) => (
                          <AppInputIonic
                            required={i === 0}
                            value={value}
                            placeholder={t({ id: 'page13.relationship' })}
                          >
                            <IonSelect
                              interface="action-sheet"
                              okText={t({ id: 'common.action.ok' })}
                              cancelText={t({ id: 'common.action.cancel' })}
                              onIonChange={onChange}
                            >
                              {RELATIONSHIP_OPTIONS.map((relationship) => (
                                <IonSelectOption
                                  key={`relation-${i}-${relationship.id}`}
                                  value={relationship.id}
                                >
                                  {t({ id: `relationship.${relationship.id}` })}
                                </IonSelectOption>
                              ))}
                            </IonSelect>
                          </AppInputIonic>
                        )}
                        control={control}
                        name={`allowedPeople4Array[${i}].relationShip` as any}
                        defaultValue=""
                        rules={{
                          required: {
                            value: i === 0,
                            message: t({ id: 'validation.required' }),
                          },
                        }}
                      />
                      <FormError
                        error={
                          errors?.allowedPeople4Array?.[i]?.relationShip
                            ?.message
                        }
                      />
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </form>
      </PageContainer>
      <FooterActions
        onCancel={openModalCancelJourneyDraft}
        onBack={goBack}
        onSubmit={onSubmitForm}
      />
    </AppPage>
  );
};
