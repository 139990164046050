import { useAppCacheContext } from '_01_CORE/app-contexts';
import {
  ConfirmWaitingRegistrationsParams,
  UpdateActivitySessionParticipantAttendanceParams,
  customerActivitiesApiClient,
} from '_01_CORE/app-core/app-api';
import { useAppSettings } from '_01_CORE/app-core/app-security';
import {
  ActivitySessionParticipantStatusUpdateReturnValue,
  CMConfirmWaitingRegistrationCriteria,
  CMFetchActivitiesCriteria,
  CMFetchActivitiesData,
  CMFetchActivitiesResponse,
  CMFetchActivitiesReturnValue,
  CMJourneyActivities,
  CMJourneyActivitySession,
  CMJourneyActivitySessionActivity,
  CMJourneyActivitySessionActivitySchedule,
  CMJourneyClubParticipant,
  CMJourneyDailyActivity,
  CMJourneyParticipant,
  CMProfileActivitySessionToDisplay,
  CMToggleActivityAttendanceCriteria,
} from 'lib-common-model';
import { AppSettingsFeaturesCustomersApi } from 'lib-common-model/entities/postgres/app-settings/AppSettingsFeaturesCustomersApi.type';
import { useCallback, useMemo, useState } from 'react';
import {
  CMFetchActivitiesCacheData,
  customerJourneyActivitiesCache,
} from '../services';

export type JourneyActivitiesRefreshCause =
  | 'day-initial-load'
  | 'activity-open-details'
  | 'activity-action-error'
  | 'activity-action-success'
  | 'activity-action-disabled';

export type JourneyActivitiesFetchStrategy = 'full' | 'activity-only' | 'none';

export type JourneyActivitiesRepositoryState = {
  isLoaded: boolean;
  isRefreshInProgress: boolean;
  criteriaKey?: string;
  criteria: CMFetchActivitiesCriteria;
  data: CMFetchActivitiesData;
};

export function useJourneyActivitiesRepository(
  criteriaInput: CMFetchActivitiesCriteria
) {
  const appSettings = useAppSettings();
  const apiConfig = appSettings?.features?.customers?.api;
  const appCacheContext = useAppCacheContext();

  const criteria: CMFetchActivitiesCriteria = useMemo(
    () => ({
      // memoise to avoid infinite re-render
      customerAccountId: criteriaInput.customerAccountId,
      customerJourneyId: criteriaInput.customerJourneyId,
      date: criteriaInput.date,
      platformLanguageCode: criteriaInput.platformLanguageCode,
      appLanguageCode: criteriaInput.appLanguageCode,
    }),
    [
      criteriaInput.appLanguageCode,
      criteriaInput.customerAccountId,
      criteriaInput.customerJourneyId,
      criteriaInput.date,
      criteriaInput.platformLanguageCode,
    ]
  );
  const [state, setState] = useState<JourneyActivitiesRepositoryState>({
    isLoaded: false,
    isRefreshInProgress: false,
    criteriaKey: undefined,
    criteria: undefined,
    data: undefined,
  });

  const updateCustomerJourneyActivitiesInCache = useCallback(
    async (customerJourneyActivities: CMJourneyActivities) => {
      await customerJourneyActivitiesCache.reduce(criteria, async (value) => ({
        ...value,
        data: {
          ...value.data,
          customerJourneyActivities,
        },
      }));
      setState({
        ...state,
        data: {
          ...state.data,
          customerJourneyActivities,
        },
      });
    },
    [criteria, state]
  );

  const updateCustomerJourneyActivityInCache = useCallback(
    async (
      cachedData: CMFetchActivitiesData,
      activityId: string,
      journeyDailyActivity: CMJourneyDailyActivity
    ) => {
      const customerJourneyActivities = cachedData.customerJourneyActivities;

      const activities = customerJourneyActivities.activities
        .filter((x) => x._id !== activityId)
        .concat([journeyDailyActivity.activity]);

      const activitySessions = customerJourneyActivities.activitySessions
        .filter((x) => x.activityId !== activityId)
        .concat(journeyDailyActivity.activitySessions);

      const activitySchedules = customerJourneyActivities.activitySchedules
        .filter((x) => x.activityId !== activityId)
        .concat(journeyDailyActivity.activitySchedules);
      const activitySessionsParticipants = customerJourneyActivities.activitySessionsParticipants
        .filter((x) => x.activityId !== activityId)
        .concat(journeyDailyActivity.activitySessionsParticipants);

      const updatedCustomerJourneyActivities = {
        ...customerJourneyActivities,
        activities,
        activitySessions,
        activitySchedules,
        activitySessionsParticipants,
      };

      const updatedData: CMFetchActivitiesData = {
        ...cachedData,
        customerJourneyActivities: updatedCustomerJourneyActivities,
      };

      customerJourneyActivitiesCache.set(criteria, {
        data: updatedData,
        _cachedAt: new Date(),
      });

      setState({
        ...state,
        data: updatedData as CMFetchActivitiesData,
        isLoaded: true,
        isRefreshInProgress: false,
      });
    },
    [criteria, state]
  );

  const fetchJourneyDailyActivityAndUpdateCache = useCallback(
    async ({
      activityId,
      criteria,
      cachedDataWithMeta,
      criteriaKey,
      fetchStrategy,
    }: {
      activityId: string;
      criteria: Omit<CMFetchActivitiesCriteria, 'activityId'>;
      cachedDataWithMeta: CMFetchActivitiesCacheData;
      criteriaKey: string;
      fetchStrategy: JourneyActivitiesFetchStrategy;
    }) => {
      const {
        customerAccountId,
        customerJourneyId,
        date,
        platformLanguageCode,
        appLanguageCode,
      } = criteria;

      try {
        const response = await customerActivitiesApiClient.fetchJourneyDailyActivity(
          {
            activityId,
            customerAccountId,
            customerJourneyId,
            date,
            platformLanguageCode,
            appLanguageCode,
            companyClubIds: cachedDataWithMeta.data.customerJourneyActivities.clubsParticipants.map(
              (x) => x.club?._id
            ),
            journeyParticipantsIds: cachedDataWithMeta.data.customerJourneyActivities.journeyParticipants.map(
              (x) => x.journeyParticipantId
            ),
          }
        );
        const journeyDailyActivity: CMJourneyDailyActivity = buildCustomerJourneyActivity(
          response.journeyDailyActivity
        );
        // mise à jour du cache
        updateCustomerJourneyActivityInCache(
          cachedDataWithMeta.data,
          activityId,
          journeyDailyActivity
        );
      } catch (err) {
        const httpStatus = (err as any).response?.status;
        if (httpStatus === 404) {
          // on est sur une ancienne version, la méthode n'existe pas, donc on utilise la requête "full"
          fetchStrategy = 'full';
        } else {
          throw err;
        }
      }
      return fetchStrategy;
    },
    [updateCustomerJourneyActivityInCache]
  );

  const fetchJourneyDailyActivitiesAndUpdateCache = useCallback(
    async ({
      criteria,
      criteriaKey,
    }: {
      criteria: CMFetchActivitiesCriteria;
      criteriaKey: string;
    }) => {
      const {
        customerAccountId,
        customerJourneyId,
        date,
        platformLanguageCode,
        appLanguageCode,
      } = criteria;

      const returnValue: CMFetchActivitiesReturnValue = {
        activities: true,
        activitySessions: true,
        activitySchedules: true,
        activitySessionsParticipants: true,
        journeyParticipants: true,
        clubsParticipants: true,
      };
      const response: CMFetchActivitiesResponse = await customerActivitiesApiClient.fetchJourneyDailyActivities(
        {
          customerAccountId,
          customerJourneyId,
          date,
          platformLanguageCode,
          appLanguageCode,
          returnValue,
        }
      );
      if (response.customerJourneyActivities) {
        response.customerJourneyActivities = buildCustomerJourneyActivities(
          response.customerJourneyActivities as CMJourneyActivities
        );
      }
      customerJourneyActivitiesCache.set(criteria, {
        data: response as CMFetchActivitiesData,
        _cachedAt: new Date(),
      });

      setState({
        data: response as CMFetchActivitiesData,
        criteriaKey,
        criteria,
        isLoaded: true,
        isRefreshInProgress: false,
      });
    },
    []
  );

  const refreshPageActivities = useCallback(
    async ({
      cause,
      activityId,
      activitySessionId,
    }: {
      cause: JourneyActivitiesRefreshCause;
      activityId?: string;
      activitySessionId?: string;
    }) => {
      const {
        customerAccountId,
        customerJourneyId,
        date,
        platformLanguageCode,
        appLanguageCode,
      } = criteria;

      if (date) {
        try {
          const criteriaKey = [
            customerAccountId,
            customerJourneyId,
            date.getTime(),
            platformLanguageCode,
            appLanguageCode,
          ]
            .map((x) => x.toString())
            .join(';');

          setState({
            ...state,
            data: null,
            criteriaKey,
            criteria,
            isLoaded: false,
            isRefreshInProgress: true,
          });

          const cachedDataWithMeta: CMFetchActivitiesCacheData = await customerJourneyActivitiesCache.get(
            criteria
          );

          if (cachedDataWithMeta) {
            // value is in cache
            setState({
              ...state,
              data: cachedDataWithMeta.data,
              criteriaKey,
              criteria,
              isLoaded: true,
              isRefreshInProgress: false,
            });
          } else {
            if (state.criteriaKey === criteriaKey) {
              // re-fetch
              setState({
                ...state,
                isRefreshInProgress: true,
              });
            } else {
              // new fetch
              setState({
                ...state,
                data: undefined,
                criteriaKey,
                criteria,
                isLoaded: false,
                isRefreshInProgress: true,
              });
            }
          }
          // déterminer la stratégie de fetch, en fonction du paramétrage

          if (!activityId && activitySessionId && cachedDataWithMeta) {
            // find activity from session
            activityId = (
              cachedDataWithMeta?.data?.customerJourneyActivities
                ?.activitySessions ?? []
            ).find((x) => x.activitySessionId === activitySessionId)
              ?.activityId;
          }

          let fetchStrategy: JourneyActivitiesFetchStrategy = buildFetchStrategy(
            { cachedDataWithMeta, cause, activityId, apiConfig }
          );

          if (fetchStrategy === 'activity-only') {
            fetchStrategy = await fetchJourneyDailyActivityAndUpdateCache({
              criteria,
              activityId,
              cachedDataWithMeta,
              criteriaKey,
              fetchStrategy,
            });
          }
          if (fetchStrategy === 'full') {
            await fetchJourneyDailyActivitiesAndUpdateCache({
              criteria,
              criteriaKey,
            });
          }
        } catch (err) {
          console.error(err);
          setState({
            ...state,
            isRefreshInProgress: false,
          });
        }
      }
    },
    [
      apiConfig,
      criteria,
      fetchJourneyDailyActivitiesAndUpdateCache,
      fetchJourneyDailyActivityAndUpdateCache,
      state,
    ]
  );

  const updateParticipantAttendance = useCallback(
    async (
      params: UpdateActivitySessionParticipantAttendanceParams
    ): Promise<ActivitySessionParticipantStatusUpdateReturnValue> => {
      try {
        const fetchParams: CMToggleActivityAttendanceCriteria = {
          ...params,
          companyClubIds: state.data.customerJourneyActivities.clubsParticipants.map(
            (x) => x.club?._id
          ),
          journeyParticipantsIds: state.data.customerJourneyActivities.journeyParticipants.map(
            (x) => x.journeyParticipantId
          ),
          customerAccountId: criteriaInput.customerAccountId,
          date: criteriaInput.date,
        };
        const {
          result,
          customerJourneyActivities: customerJourneyActivitiesPartial,
          customerJourney,
          journeyDailyActivity,
        } = await customerActivitiesApiClient.updateActivitySessionParticipantAttendance(
          fetchParams
        );
        if (customerJourney) {
          appCacheContext.dispatchCacheContextAction({
            type: 'set-journey',
            customerJourney,
            context: 'updateActivitySessionParticipantAttendance',
          });
        }

        if (journeyDailyActivity) {
          // v5+
          await updateCustomerJourneyActivityInCache(
            state.data,
            params.activityId,
            buildCustomerJourneyActivity(journeyDailyActivity)
          );
        } else {
          // < v5
          await updateCustomerJourneyActivitiesInCache(
            buildCustomerJourneyActivities({
              ...state.data?.customerJourneyActivities,
              ...customerJourneyActivitiesPartial,
            } as CMJourneyActivities)
          );
        }

        return result;
      } catch (err) {
        // async refresh
        refreshPageActivities({
          cause: 'activity-action-error',
          activitySessionId: params.activitySessionId,
        }).catch();
        throw err;
      }
    },
    [
      appCacheContext,
      criteriaInput.customerAccountId,
      criteriaInput.date,
      refreshPageActivities,
      state.data,
      updateCustomerJourneyActivitiesInCache,
      updateCustomerJourneyActivityInCache,
    ]
  );

  const confirmWaitingRegistrations = useCallback(
    async (
      params: ConfirmWaitingRegistrationsParams
    ): Promise<{
      result: ActivitySessionParticipantStatusUpdateReturnValue;
      activitySessionsToDisplay: CMProfileActivitySessionToDisplay[];
    }> => {
      try {
        const fetchParams: CMConfirmWaitingRegistrationCriteria = {
          fetchActivityCriteria: {
            companyClubIds: state.data.customerJourneyActivities.clubsParticipants.map(
              (x) => x.club?._id
            ),
            journeyParticipantsIds: state.data.customerJourneyActivities.journeyParticipants.map(
              (x) => x.journeyParticipantId
            ),
            customerAccountId: params.customerAccountId,
            date: params.date,
            activityId: params.activityId,
            customerJourneyId: params.customerJourneyId,
            platformLanguageCode: params.platformLanguageCode,
            appLanguageCode: params.appLanguageCode,
          },
          confirmation: params.confirmation,
          activitySessionId: params.activitySessionId,
          journeyParticipantsIds: params.journeyParticipantsIds,
          journeyParticipantsIdsToConfirm: params.journeyParticipantsIds,
        };

        const {
          result,
          customerJourneyActivities: customerJourneyActivitiesPartial,
          activitySessionsToDisplay,
          journeyDailyActivity,
        } = await customerActivitiesApiClient.confirmWaitingRegistrations(
          fetchParams
        );

        if (journeyDailyActivity) {
          // v5+

          await updateCustomerJourneyActivityInCache(
            state.data,
            params.activityId,
            buildCustomerJourneyActivity(journeyDailyActivity)
          );
        } else {
          // < v5
          await updateCustomerJourneyActivitiesInCache(
            buildCustomerJourneyActivities({
              ...state.data?.customerJourneyActivities,
              ...customerJourneyActivitiesPartial,
            } as CMJourneyActivities)
          );
        }

        return { result, activitySessionsToDisplay };
      } catch (err) {
        // async refresh
        refreshPageActivities({
          cause: 'activity-action-error',
          activitySessionId: params.activitySessionId,
        }).catch();
        throw err;
      }
    },
    [
      refreshPageActivities,
      state.data,
      updateCustomerJourneyActivitiesInCache,
      updateCustomerJourneyActivityInCache,
    ]
  );
  return {
    state,
    actions: {
      refreshPageActivities,
      confirmWaitingRegistrations,
      updateParticipantAttendance,
    },
  };
}

export type JourneyActivitiesRepository = ReturnType<
  typeof useJourneyActivitiesRepository
>;

function buildFetchStrategy({
  cause,
  activityId,
  cachedDataWithMeta,
  apiConfig,
}: {
  cause: JourneyActivitiesRefreshCause;
  activityId?: string;
  cachedDataWithMeta: CMFetchActivitiesCacheData;
  apiConfig: AppSettingsFeaturesCustomersApi;
}): JourneyActivitiesFetchStrategy {
  if (!cachedDataWithMeta) {
    return 'full';
  }
  if (cause === 'day-initial-load') {
    // CHARGEMENT INITIAL
    if (
      isCacheExpired({
        _cachedAt: cachedDataWithMeta._cachedAt,
        cacheTTL: apiConfig.dailyActivities?.pageLoadingFetchTTL,
      })
    ) {
      // cache complet
      return 'full';
    } else {
      return 'none';
    }
  }

  if (activityId) {
    if (cause === 'activity-open-details') {
      // OUVERTURE D'UNE ACTIVITÉ
      if (
        isCacheExpired({
          _cachedAt: cachedDataWithMeta._cachedAt,
          cacheTTL: apiConfig?.dailyActivities?.activityLoadingFetchTTL,
        })
      ) {
        // cache complet
        return 'full';
      }
      // cache activité
      return apiConfig?.singleActivity?.refreshStrategy === 'single'
        ? 'activity-only' // 18/07/2023 la nouvelle version active le chargement unitaire
        : 'full'; // 18/07/2023 si l'api n'est pas à jour, on recharge tout (comme avant)
    } else {
      // ACTION SUR UNE ACTIVITÉ
      if (
        isCacheExpired({
          _cachedAt: cachedDataWithMeta._cachedAt,
          cacheTTL: apiConfig?.dailyActivities?.activityActionFetchTTL,
        })
      ) {
        // cache complet
        return 'full';
      } else {
        // cache activité
        return apiConfig?.singleActivity?.refreshStrategy === 'single'
          ? 'activity-only' // 18/07/2023 la nouvelle version active le chargement unitaire
          : 'full'; // 18/07/2023 si l'api n'est pas à jour, on recharge tout (comme avant)
      }
    }
  }

  return 'none';
}

function isCacheExpired({
  cacheTTL,
  _cachedAt,
}: {
  cacheTTL: number;
  _cachedAt: Date;
}): boolean {
  if (cacheTTL > 0) {
    // calculer l'age de _cachedAt
    const cacheAge = new Date().getTime() - _cachedAt.getTime();
    if (cacheAge > cacheTTL) {
      return true;
    }
    return false;
  }
  return true; // always expires
}

function buildCustomerJourneyActivities(
  customerJourneyActivities: CMJourneyActivities
): CMJourneyActivities {
  if (customerJourneyActivities) {
    // 2022-06-09: "activity" ne sera bientôt plus renvoyé par le serveur pour chaque session
    // il est désormais envoyé séparément (AVEC la description de la traduction) et reconstruit côté client, pour optimiser la taille des données transférées
    // NOTE: temporairement, on le renvoie encore (SANS la description de la traduction) pour conserver la compatibilité avec les anciennes versions
    // 2022-07-05: correction bug quand on s'inscrivait, côté client, et utilisation d'un nouveau end-point sur l'api pour conserver la compatibilité
    // 2023-07-04 : optimisations ajoutées dans customerJourneyActivitiesFinder, et liées au end-point v3
    const activitiesMap: {
      [activityId: string]: CMJourneyActivitySessionActivity;
    } = customerJourneyActivities.activities.reduce((acc, act) => {
      acc[act._id] = act;
      return acc;
    }, {} as { [activityId: string]: CMJourneyActivitySessionActivity });

    const activitySchedulesMap: {
      [activityScheduleId: string]: CMJourneyActivitySessionActivitySchedule;
    } = customerJourneyActivities.activitySchedules.reduce(
      (acc, actSch) => {
        acc[actSch._id] = actSch;
        return acc;
      },
      {} as {
        [activityScheduleId: string]: CMJourneyActivitySessionActivitySchedule;
      }
    );

    const journeyParticipantsMap: {
      [journeyParticipantId: string]: CMJourneyParticipant;
    } = customerJourneyActivities.journeyParticipants.reduce(
      (acc, jp) => {
        acc[jp.journeyParticipantId] = jp;
        return acc;
      },
      {} as {
        [journeyParticipantId: string]: CMJourneyParticipant;
      }
    );

    const activitySessions: CMJourneyActivitySession[] = customerJourneyActivities.activitySessions.map(
      (actSes) => ({
        ...actSes,
        activity: activitiesMap[actSes.activityId],
        activitySchedule: activitySchedulesMap[actSes.activityScheduleId],
      })
    );

    const clubsParticipants: CMJourneyClubParticipant[] = customerJourneyActivities.clubsParticipants.map(
      (cp) => ({
        ...cp,
        journeyParticipants: cp.journeyParticipantsIds.map(
          (journeyParticipantId) => journeyParticipantsMap[journeyParticipantId]
        ),
      })
    );
    const fullJourneyActivities: CMJourneyActivities = {
      ...customerJourneyActivities,
      activitySessions,
      clubsParticipants,
    };

    return fullJourneyActivities;
  }
}

function buildCustomerJourneyActivity(
  customerJourneyActivity: CMJourneyDailyActivity
): CMJourneyDailyActivity {
  if (customerJourneyActivity) {
    const activitySchedulesMap: {
      [activityScheduleId: string]: CMJourneyActivitySessionActivitySchedule;
    } = customerJourneyActivity.activitySchedules.reduce(
      (acc, actSch) => {
        acc[actSch._id] = actSch;
        return acc;
      },
      {} as {
        [activityScheduleId: string]: CMJourneyActivitySessionActivitySchedule;
      }
    );

    const activitySessions: CMJourneyActivitySession[] = customerJourneyActivity.activitySessions.map(
      (actSes) => ({
        ...actSes,
        activity: customerJourneyActivity.activity,
        activitySchedule: activitySchedulesMap[actSes.activityScheduleId],
      })
    );

    const fullJourneyActivity: CMJourneyDailyActivity = {
      ...customerJourneyActivity,
      activitySessions,
    };

    return fullJourneyActivity;
  }
}
