import { AppRemoteEnvId } from 'lib-web-common';
import React, { useCallback } from 'react';
import { appBootstrap } from '_01_CORE/app-core/app-bootstrap/appBootstrap.service';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import { DebugSwitchRemoteEnvButton } from './DebugSwitchRemoteEnvButton';

export const DebugSwitchRemoteEnv = ({
  onSwitch,
  className,
}: {
  onSwitch: (remoteEnvId: AppRemoteEnvId) => void;
  className?: string;
}) => {
  const switchEnv = useCallback(
    (remoteEnvId: AppRemoteEnvId) => {
      appBootstrap.switchEnv({
        remoteEnvId,
      });
      onSwitch(remoteEnvId);
    },
    [onSwitch]
  );

  return (
    <div className={className}>
      {appWebConfig().envId === 'dev' && (
        <button
          className={`mx-1 text-xs ring-2 py-1 px-2 hover:outline-none ${
            appWebConfig().remoteEnvId === 'local'
              ? 'text-red-500 ring-red-300'
              : 'text-blue-500 ring-blue-300'
          }`}
          onClick={() => {
            switchEnv('local');
          }}
        >
          LOCAL
        </button>
      )}
      {appWebConfig().appId === 'anim-live' && (
        <DebugSwitchRemoteEnvButton
          env="alpha"
          onSwitch={switchEnv}
          className="mx-1 text-xs"
        />
      )}
      <DebugSwitchRemoteEnvButton
        env="beta"
        onSwitch={switchEnv}
        className="mx-1 text-xs"
      />
      <DebugSwitchRemoteEnvButton
        env="prod"
        onSwitch={switchEnv}
        className="mx-1 text-xs"
      />
    </div>
  );
};
