import {
  ActivitySessionParticipantStatusUpdateReturnValue,
  ActivityTranslation,
  CMConfirmWaitingRegistrationCriteria,
  CMFetchActivitiesFetchParams,
  CMFetchActivitiesResponse,
  CMFetchActivityCriteria,
  CMJourney,
  CMJourneyActivities,
  CMJourneyDailyActivity,
  CMProfileActivitySessionToDisplay,
  CMToggleActivityAttendanceCriteria,
  CustomerActivityUpdateParticipantStateAction,
  DailySessionsAvailabilities,
  LanguageCode,
  PreRegistrationStatus,
  WaitingRegistrationStatus,
} from 'lib-common-model';
import { apiClient } from 'lib-web-api-client';

export const customerActivitiesApiClient = {
  fetchJourneyDailyActivity,
  fetchJourneyDailyActivities,
  fetchAgendaSessionsAvailabilities,
  updateActivitySessionParticipantAttendance,
  fetchActivityTranslation,
  confirmWaitingRegistrations,
};

async function fetchActivityTranslation({
  activityId,
  platformLanguageCode,
  appLanguageCode,
}: {
  activityId: string;
  platformLanguageCode: LanguageCode;
  appLanguageCode: LanguageCode;
}): Promise<ActivityTranslation> {
  return await apiClient.get<any>(
    `/customer-web-mobile-activities/activity-translation/${activityId}`,
    {
      options: {
        authenticate: true,
        searchParams: {
          platformLanguageCode,
          appLanguageCode,
        },
      },
    }
  );
}

async function fetchJourneyDailyActivity({
  activityId,
  customerAccountId,
  customerJourneyId,
  platformLanguageCode,
  appLanguageCode,
  date,
  companyClubIds,
  journeyParticipantsIds,
}: CMFetchActivityCriteria): Promise<{
  journeyDailyActivity: CMJourneyDailyActivity;
}> {
  return await apiClient.post<any>(
    `/customer-web-mobile-activities/journey-daily-activity-v5`,
    // nouvelle route ajoutée le 19/07/2023, si elle répond 404, alors faire un appel à fetchJourneyDailyActivities
    {
      options: {
        authenticate: true,
        alwaysThrowError: true,
        maxTries: 2, // on retente la requête si besoin
        json: {
          activityId,
          customerJourneyId,
          customerAccountId,
          date: new Date(date).getTime(),
          platformLanguageCode,
          appLanguageCode,
          companyClubIds,
          journeyParticipantsIds,
        },
      },
    }
  );
}
async function fetchJourneyDailyActivities({
  customerAccountId,
  customerJourneyId,
  platformLanguageCode,
  appLanguageCode,
  date,
  returnValue,
}: Omit<
  CMFetchActivitiesFetchParams,
  'activityId'
>): Promise<CMFetchActivitiesResponse> {
  return await apiClient.get<any>(
    `/customer-web-mobile-activities/journey-daily-activities-v5`,
    // note, sur les anciens environnements, la v3 va répondre à toutes les versions
    {
      options: {
        authenticate: true,
        alwaysThrowError: true,
        maxTries: 2, // on retente la requête si besoin
        searchParams: {
          customerJourneyId,
          customerAccountId,
          date: date.toString(), // à mettre à jour quand la version 5.60.x sera adoptée et pérénisée en prod et sur les mb
          // date: new Date(date).getTime(),
          platformLanguageCode,
          appLanguageCode,
          returnValue: JSON.stringify(returnValue),
        },
      },
    }
  );
}
async function fetchAgendaSessionsAvailabilities({
  participantsCount,
}: {
  participantsCount: number;
}): Promise<DailySessionsAvailabilities[]> {
  return await apiClient.get<any>(
    `/customer-web-mobile-activities/agenda-sessions-availabilities`,
    {
      options: {
        authenticate: true,
        searchParams: {
          participantsCount,
        },
      },
    }
  );
}

export type UpdateActivitySessionParticipantAttendanceParams = {
  action: CustomerActivityUpdateParticipantStateAction;
  customerJourneyId: string;
  activityId: string;
  activitySessionId: string;
  journeyParticipantId: string;
  preRegistrationStatus?: PreRegistrationStatus;
  waitingRegistrationStatus?: WaitingRegistrationStatus;
  platformLanguageCode: LanguageCode;
  appLanguageCode: LanguageCode;
};

async function updateActivitySessionParticipantAttendance({
  action,
  customerJourneyId,
  activitySessionId,
  journeyParticipantId,
  preRegistrationStatus,
  waitingRegistrationStatus,
  platformLanguageCode,
  appLanguageCode,
  companyClubIds,
  journeyParticipantsIds,
  activityId,
  customerAccountId,
  date,
}: CMToggleActivityAttendanceCriteria): Promise<{
  result: ActivitySessionParticipantStatusUpdateReturnValue;
  customerJourney: CMJourney;
  journeyDailyActivity: CMJourneyDailyActivity;
  /***
   * @deprecated depuis la v5, remplacé par journeyDailyActivity
   */
  customerJourneyActivities: Partial<CMJourneyActivities>;
}> {
  return await apiClient.post<any>(
    `/customer-web-mobile-activities/toogle-attendance-v5`,
    {
      options: {
        authenticate: true,
        alwaysThrowError: true,
        json: {
          action,
          customerJourneyId,
          journeyParticipantId,
          activitySessionId,
          preRegistrationStatus,
          waitingRegistrationStatus,
          platformLanguageCode,
          appLanguageCode,
          companyClubIds,
          journeyParticipantsIds,
          activityId,
          customerAccountId,
          date: date.toString(), // à mettre à jour quand la version 5.60.x sera adoptée et pérénisée en prod et sur les mb
          // date: new Date(date).getTime(),
        },
      },
    }
  );
}
export type ConfirmWaitingRegistrationsParams = {
  confirmation: boolean;
  activityId: string;
  activitySessionId: string;
  journeyParticipantsIds: string[];
  customerJourneyId: string;
  customerAccountId: string;
  date: Date;
  platformLanguageCode: LanguageCode;
  appLanguageCode: LanguageCode;
};
async function confirmWaitingRegistrations({
  confirmation,
  activitySessionId,
  journeyParticipantsIds,
  fetchActivityCriteria,
  journeyParticipantsIdsToConfirm,
}: CMConfirmWaitingRegistrationCriteria): Promise<{
  result: ActivitySessionParticipantStatusUpdateReturnValue;
  activitySessionsToDisplay: CMProfileActivitySessionToDisplay[];
  journeyDailyActivity: CMJourneyDailyActivity;
  /***
   * @deprecated depuis la v5, remplacé par journeyDailyActivity
   */
  customerJourneyActivities: Partial<CMJourneyActivities>;
}> {
  const {
    customerJourneyId,
    customerAccountId,
    date,
    platformLanguageCode,
    appLanguageCode,
    activityId,
    companyClubIds,
  } = fetchActivityCriteria;

  return await apiClient.put<any>(
    `/customer-web-mobile-activities/confirm-waiting-registrations-v5`,
    {
      options: {
        authenticate: true,
        alwaysThrowError: true,
        json: {
          confirmation,
          activitySessionId,
          journeyParticipantsIds: journeyParticipantsIdsToConfirm, // les participants qui prennent la place (remplacé par journeyParticipantsIdsToConfirm à partir de la v5)
          journeyParticipantsIdsToConfirm: journeyParticipantsIdsToConfirm, // les participants qui prennent la place
          journeyParticipantsIdsAll:
            fetchActivityCriteria.journeyParticipantsIds, // tous les participants du séjour
          customerJourneyId,
          customerAccountId,
          date: date.toString(), // à mettre à jour quand la version 5.60.x sera adoptée et pérénisée en prod et sur les mb
          // date: new Date(date).getTime(),
          platformLanguageCode,
          appLanguageCode,
          activityId,
          companyClubIds,
        },
      },
    }
  );
}
