import { IonInput } from '@ionic/react';
import clsx from 'clsx';
import React from 'react';

type IonInputInputProps = Omit<
  React.ComponentProps<typeof IonInput>,
  'onChange'
>;

export const AppInput = React.forwardRef<
  Omit<HTMLIonInputElement, 'onChange'>,
  {
    before?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
    icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
    iconPosition?: 'right' | 'left';
    unit?: string;
    label?: string;
    error?: boolean;
    iconClasses?: string;
    uppercase?: boolean;
    onChange?: (
      e:
        | {
            target: {
              value: string | number;
            };
          }
        | string
        | number
    ) => any;
  } & IonInputInputProps
>(
  (
    {
      icon,
      iconPosition = 'left',
      unit,
      label,
      error,
      required,
      iconClasses,
      className,
      uppercase,
      onIonInput,
      onChange,
      ...nativeInputProps
    },
    ref
  ) => {
    const {
      id = nativeInputProps.id ?? `appinput-${Math.random() * 10000}`,
    } = nativeInputProps;

    const classNameInput = `text-left focus:outline-none shadow-sm ${
      error
        ? 'focus:border-gray-200 focus-visible:border-gray-200 text-app-danger'
        : 'focus:border-gray-200 text-app-primary'
    } block w-full px-2 py-1.5 border border-gray-300 rounded-lg`;

    const Icon = icon;

    return (
      <div className={`group focus:outline-none ${className ?? ''}`}>
        {label && (
          <label
            className="mb-1 text-gray-400 font-bold text-xs block text-left"
            htmlFor={id}
          >
            {label} {required && <span className="text-red-400">*</span>}
          </label>
        )}
        <div className="relative">
          {Icon && (
            <Icon
              className={clsx(
                iconClasses ??
                  (iconPosition === 'right' ? 'right-2.5' : 'left-2.5'),
                `absolute top-4 text-gray-300 w-6 h-6 z-10`
              )}
            />
          )}
          {unit && (
            <span className="absolute top-2 right-2 text-base text-gray-400 z-10">
              {unit}
            </span>
          )}
          {/* NOTE: si on n'utilise pas un champ ionic, on a des soucis sur ios quand le clavier s'ouvre au clic sur le champ "input" (il y a un gros espace blanc en trop) */}
          <IonInput
            style={{
              '--padding-start':
                Icon && iconPosition === 'left' ? '2.5rem' : '0.5rem',
              '--padding-end':
                unit || (Icon && iconPosition === 'right')
                  ? '2.5rem'
                  : '0.5rem',
              // 'padding-top': '0 !important',
              // 'padding-bottom': '0 !important',
              '--padding-top': '0.375rem',
              '--padding-bottom': '0.375rem',
            }}
            className={clsx(
              'bg-white text-gray-800',
              uppercase && 'uppercase',
              classNameInput
            )}
            ref={ref}
            id={id}
            required={required}
            onIonInput={(e) => {
              onIonInput && onIonInput(e);
              onChange &&
                onChange({
                  target: {
                    value: e.target.value,
                  },
                });
            }}
            {...nativeInputProps}
          />
        </div>
      </div>
    );
  }
);
AppInput.displayName = 'AppInput';
