import { IonIcon } from '@ionic/react';
import { CompanyOptionsTheme } from 'lib-common-model';
import { AppButtonUniverse } from '_01_CORE/_components-core';
import { AppMenuItem } from '../_model';

export const AppSideMenuItems = ({
  theme,
  item,
}: {
  theme: CompanyOptionsTheme;
  item: AppMenuItem;
}) => {
  // TODO: passer à AppButtonCompany, mais pour que ça fonctionne, il faut d'abord remplacer toutes les icones ionics de type "string" par des AppIcons
  return !item.isVisibleItem ? null : (
    <>
      {/* <AppButtonCompany
        className="w-full uppercase mx-auto"
        onClick={() => item.onClick()}
        style="primary"
        theme={theme}
        label={item.label}
        icon={item.icon3}
      /> */}
      <AppButtonUniverse
        className="w-full uppercase mx-auto"
        onClick={() => item.onClick()}
        style="primary"
        label={item.label}
        icon={
          item.iconApp
            ? item.iconApp
            : item.icon
            ? () => (
                <IonIcon
                  className="text-2xl mr-3"
                  icon={item.icon}
                  slot="icon-only"
                />
              )
            : undefined
        }
        iconBadge={
          !item.iconBadge || !item.iconBadgeVisible
            ? undefined
            : () => (
                <div className="absolute -top-2 -right-2 border border-red-600 p-px bg-white rounded-full">
                  <item.iconBadge className="h-6 w-6 rounded-full bg-white text-red-600" />
                </div>
              )
        }
      />
    </>
  );
};
