import { usePlatform } from '@capacitor-community/react-hooks/platform';
import {
  AppTranslationContentItemButton,
  CompanyOptionsTheme,
} from 'lib-common-model';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { mapUrlBuilder } from '_01_CORE/app-core/app-api';
import { appBrowser } from '_01_CORE/app-core/app-platform';
import { AppIcons } from '_01_CORE/_components-core';
import { useAppRouter } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import { AppButtonCompany } from '../AppButton/AppButtonCompany';
import { AppButtonFreeColorProps } from '../AppButton/AppButtonFreeColor';

export const AppTranslationContentButton = ({
  button,
  theme,
  className,
}: {
  button: AppTranslationContentItemButton;
  theme: CompanyOptionsTheme;
  className?: string;
}) => {
  const { formatMessage: t } = useIntl();

  const appRouter = useAppRouter();

  const isFullWidth = useMemo(
    () => (className ?? '').split(' ').includes('w-full'),
    [className]
  );

  const locationUrl: string = useMemo(() => {
    if (button.type === 'direction') {
      return mapUrlBuilder.getMapUrl(button.value);
    }
    return '';
  }, [button.type, button.value]);

  const { platform } = usePlatform();

  const internalLinkHref = useMemo(() => {
    if (button.type === 'internal-link') {
      switch (button.internalLink?.page) {
        case 'gift-card':
          return appRoutesBuilder.getGiftCardCreatePage({
            companyReference: button.internalLink?.companyReference,
          });
      }
    }
  }, [
    button.internalLink?.companyReference,
    button.internalLink?.page,
    button.type,
  ]);
  const buttonProps: Pick<
    AppButtonFreeColorProps,
    'icon' | 'href' | 'target' | 'onClick'
  > = useMemo(() => {
    switch (button.type) {
      case 'phone': {
        return {
          icon: AppIcons.phone,
          href: `tel:${button.value}`,
        };
      }
      case 'email': {
        return {
          icon: AppIcons.mail,
          href: `mailto:${button.value}`,
        };
      }
      case 'url': {
        return {
          icon: AppIcons.linkExternal,
          onClick: () =>
            appBrowser.openUrl({
              platform,
              url: button.value,
            }),
        };
      }
      case 'internal-link': {
        return {
          icon: AppIcons.link,
          onClick: () =>
            appRouter.navigate(internalLinkHref, {
              cause: 'internal-button-click',
            }),
        };
      }
      case 'direction': {
        return {
          icon: AppIcons.location,
          href: locationUrl,
          target: '_blank',
        };
      }
    }
  }, [
    appRouter,
    button.type,
    button.value,
    internalLinkHref,
    locationUrl,
    platform,
  ]);

  return (
    button &&
    !!button.label &&
    !!button.value && (
      <AppButtonCompany
        {...buttonProps}
        style="primary"
        className={className}
        isFullWidth={isFullWidth}
        theme={theme}
        label={button.label}
        label2={button.label2}
      />
    )
  );
};
