import { useMemo } from 'react';
import { DailySessionsAvailabilitiesMap } from './DailySessionsAvailabilitiesMap.type';

export function useSessionCounts({
  date,
  dailySessionsAvailabilitiesMap,
}: {
  date: Date;
  dailySessionsAvailabilitiesMap: DailySessionsAvailabilitiesMap;
}): {
  currentCompanySessionsCount: any;
  currentCompanyAvailableSessionsCount: any;
  otherCompanySessionsCount: any;
  otherCompanyAvailableSessionsCount: any;
} {
  return useMemo(() => {
    return buildSessionCounts({ date, dailySessionsAvailabilitiesMap });
  }, [dailySessionsAvailabilitiesMap, date]);
}

// export type SessionCounts = {};

export function buildSessionCounts({
  date,
  dailySessionsAvailabilitiesMap,
}: {
  date: Date;
  dailySessionsAvailabilitiesMap: DailySessionsAvailabilitiesMap;
}) {
  const dayDateString =
    date && date.toUTCString ? date.toUTCString() : undefined;
  const availabilities =
    dailySessionsAvailabilitiesMap &&
    dailySessionsAvailabilitiesMap[dayDateString];
  const currentCompanySessionsCount =
    availabilities?.currentCompany?.sessionsCount ?? 0;
  const currentCompanyAvailableSessionsCount =
    availabilities?.currentCompany?.availableSessionsCount ?? 0;
  const otherCompanySessionsCount =
    availabilities?.otherCompany?.sessionsCount ?? 0;
  const otherCompanyAvailableSessionsCount =
    availabilities?.otherCompany?.availableSessionsCount ?? 0;

  // const {

  // }

  return {
    currentCompanySessionsCount,
    currentCompanyAvailableSessionsCount,
    otherCompanySessionsCount,
    otherCompanyAvailableSessionsCount,
  };
}

export type SessionCountsStatus =
  | 'available'
  | 'busy-1'
  | 'busy-2'
  | 'full'
  | 'closed';

export function buildStatusFromSessionCounts({
  currentCompanyAvailableSessionsCount,
  otherCompanyAvailableSessionsCount,
  currentCompanySessionsCount,
}: {
  currentCompanyAvailableSessionsCount: number;
  otherCompanyAvailableSessionsCount: number;
  currentCompanySessionsCount: number;
}): SessionCountsStatus {
  if (currentCompanyAvailableSessionsCount >= 5) {
    return 'available';
  } else if (currentCompanyAvailableSessionsCount >= 3) {
    return 'busy-1';
  } else if (
    currentCompanyAvailableSessionsCount > 0 ||
    otherCompanyAvailableSessionsCount > 0
  ) {
    return 'busy-2';
  } else if (currentCompanySessionsCount > 0) {
    return 'full';
  } else {
    return 'closed';
  }
}
