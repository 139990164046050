import { Device } from '@capacitor/device';

export const appLanguageCodeReader = {
  getPlatformLanguageCode,
};

async function getPlatformLanguageCode(): Promise<string> {
  return await Device.getLanguageCode()
    .then((x) => x?.value)
    .catch(() => navigator.language);
}
