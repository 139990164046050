import { LANGUAGES_ARRAY } from 'lib-common-model';
import { CustomerAppLanguage, localizations } from './AppLocalizationContext';

// si on ajoute des langues ici, il faut adapter "useBookletLanguage" sur l'autre appli
export const CUSTOMERS_APP_LANGUAGES_CODES: CustomerAppLanguage[] = [
  'en',
  'fr',
  'sv',
];
export type CUSTOMERS_APP_LANGUAGES_CODES = keyof typeof localizations;

export const CUSTOMERS_APP_LANGUAGES = LANGUAGES_ARRAY.filter((c) =>
  CUSTOMERS_APP_LANGUAGES_CODES.includes(c.ietfBcp45 as CustomerAppLanguage)
);
