import { Country } from './Country.model';

// @see https://fr.wikipedia.org/wiki/ISO_3166-1#Table_de_codage
export const COUNTRIES_ARRAY: Country[] = [
  {
    defaultLanguage: 'ger/deu',
    id: 'DEU',
    imgUrl: 'assets/images/countries/DEU-20px.png',
  },
  {
    defaultLanguage: null,
    id: 'AUT',
    imgUrl: 'assets/images/countries/AUT-20px.png',
  },
  {
    defaultLanguage: null,
    id: 'BEL',
    imgUrl: 'assets/images/countries/BEL-20px.png',
  },
  {
    defaultLanguage: 'dan',
    id: 'DNK',
    imgUrl: 'assets/images/countries/DNK-20px.png',
  },
  {
    defaultLanguage: 'spa',
    id: 'ESP',
    imgUrl: 'assets/images/countries/ESP-20px.png',
  },
  {
    defaultLanguage: 'fin',
    id: 'FIN',
    imgUrl: 'assets/images/countries/FIN-20px.png',
  },
  {
    defaultLanguage: 'fre/fra',
    id: 'FRA',
    imgUrl: 'assets/images/countries/FRA-20px.png',
  },
  {
    defaultLanguage: 'ita',
    id: 'ITA',
    imgUrl: 'assets/images/countries/ITA-20px.png',
  },
  {
    defaultLanguage: 'gle',
    id: 'IRL',
    imgUrl: 'assets/images/countries/IRL-20px.png',
  },
  {
    defaultLanguage: null,
    id: 'MCO',
    imgUrl: 'assets/images/countries/MCO-20px.png',
  },
  {
    defaultLanguage: 'nor',
    id: 'NOR',
    imgUrl: 'assets/images/countries/NOR-20px.png',
  },
  {
    defaultLanguage: 'dut/nld',
    id: 'NDL',

    imgUrl: 'assets/images/countries/NDL-20px.png',
    // coquille, en fait c'est NLD
  },
  {
    defaultLanguage: 'en',
    id: 'GBR',
    imgUrl: 'assets/images/countries/GBR-20px.png',
  },
  {
    defaultLanguage: 'por',
    id: 'PRT',
    imgUrl: 'assets/images/countries/PRT-20px.png',
  },
  {
    defaultLanguage: 'swe',
    id: 'SWE',
    imgUrl: 'assets/images/countries/SWE-20px.png',
  },
  {
    defaultLanguage: null,
    id: 'CHE',
    imgUrl: 'assets/images/countries/CHE-20px.png',
  },
  {
    defaultLanguage: null,
    id: 'LIE',
    imgUrl: 'assets/images/countries/LIE-20px.png',
  },
  {
    defaultLanguage: null,
    id: 'LUX',
    imgUrl: 'assets/images/countries/LUX-20px.png',
  },
  {
    defaultLanguage: null,
    id: '@@@',

    imgUrl: 'assets/images/countries/Other-20px.png',
    // depecated : remplacé par OTHER
  },
  {
    defaultLanguage: null,
    id: 'OTHER',
    imgUrl: 'assets/images/countries/Other-20px.png',
  },
];
