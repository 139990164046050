import { LanguageCode } from './LanguageCode.type';

export const LANGUAGES_VALID_VALUES: LanguageCode[] = [
  '@@@', // deprecated: remplacé par OTHER
  'OTHER',
  'ger/deu',
  'en',
  'spa',
  'fre/fra',
  'gle',
  'ita',
  'gsw',
  'dut/nld',
];
