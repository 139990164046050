import {
  CMJourney,
  CMShoppingCartPaymentResume,
  CMShoppingCartResume,
  CMShoppingCartResumesFetchCriteria,
  EcommercePaymentPlatformLinkInfo,
  LanguageCode,
  ParticipantPaymentMode,
} from 'lib-common-model';
import { apiClient } from 'lib-web-api-client';

export const ecommerceShoppingCartApiClient = {
  fetchJourneyShoppingCarts,
  createJourneyShoppingCartPayment,
  cancelJourneyShoppingCartPayment,
  cancelAndDeleteJourneyShoppingCart,
  confirmJourneyShoppingCart,
  unconfirmJourneyShoppingCart,
  fetchJourneyShoppingCartPaymentResume,
};

async function fetchJourneyShoppingCarts({
  criteria,
  platformLanguageCode,
  appLanguageCode,
}: {
  criteria: CMShoppingCartResumesFetchCriteria;
  platformLanguageCode: LanguageCode;
  appLanguageCode: LanguageCode;
}): Promise<{
  shoppingCartResumes: CMShoppingCartResume[];
  customerJourney: CMJourney;
}> {
  return apiClient.post<any>(
    `/customer-web-mobile-ecommerce-shopping-cart/list`,
    {
      options: {
        authenticate: true,
        json: {
          criteria,
          platformLanguageCode,
          appLanguageCode,
        },
      },
    }
  );
}

async function fetchJourneyShoppingCartPaymentResume({
  shoppingCartId,
  removeCancelStatus,
  maxTries,
}: {
  shoppingCartId?: string;
  removeCancelStatus?: boolean;
  maxTries: number;
}): Promise<{
  shoppingCartPaymentResume: CMShoppingCartPaymentResume;
}> {
  return apiClient.get<any>(
    `/customer-web-mobile-ecommerce-shopping-cart/${shoppingCartId}/payment-resume?${
      removeCancelStatus ? 'remove-cancel-status=true' : ''
    }`,
    {
      options: {
        authenticate: true,
        maxTries,
      },
    }
  );
}

async function createJourneyShoppingCartPayment({
  shoppingCartId,
  pageModeId,
}: {
  shoppingCartId: string;
  pageModeId: string;
}): Promise<{
  paymentLinkInfo?: EcommercePaymentPlatformLinkInfo; // seulement si succès
  shoppingCartPaymentResume: CMShoppingCartPaymentResume;
}> {
  return apiClient.put<any>(
    `/customer-web-mobile-ecommerce-shopping-cart/${shoppingCartId}/create-online-payment`,
    {
      options: {
        authenticate: true,
        json: {
          pageModeId,
        },
      },
    }
  );
}

async function cancelJourneyShoppingCartPayment({
  shoppingCartId,
}: {
  shoppingCartId: string;
}): Promise<{
  paymentLinkInfo?: EcommercePaymentPlatformLinkInfo; // seulement si succès
  shoppingCartPaymentResume: CMShoppingCartPaymentResume;
}> {
  return apiClient.put<any>(
    `/customer-web-mobile-ecommerce-shopping-cart/${shoppingCartId}/cancel-online-payment`,
    {
      options: {
        authenticate: true,
      },
    }
  );
}

async function cancelAndDeleteJourneyShoppingCart({
  customerJourneyId,
  platformLanguageCode,
  appLanguageCode,
}: {
  customerJourneyId: string;
  platformLanguageCode: LanguageCode;
  appLanguageCode: LanguageCode;
}): Promise<{ customerJourney: CMJourney }> {
  return apiClient.put<any>(
    `/customer-web-mobile-ecommerce-shopping-cart/journey/${customerJourneyId}/delete-shopping-cart`,
    {
      options: {
        authenticate: true,
        json: {
          platformLanguageCode,
          appLanguageCode,
        },
      },
    }
  );
}
async function confirmJourneyShoppingCart({
  customerJourneyId,
  platformLanguageCode,
  appLanguageCode,
  paymentMode,
}: {
  customerJourneyId: string;
  platformLanguageCode: LanguageCode;
  appLanguageCode: LanguageCode;
  paymentMode: ParticipantPaymentMode;
}): Promise<CMShoppingCartResume> {
  return apiClient.put<any>(
    `/customer-web-mobile-ecommerce-shopping-cart/journey/${customerJourneyId}/confirm-shopping-cart`,
    {
      options: {
        authenticate: true,
        json: {
          platformLanguageCode,
          appLanguageCode,
          paymentMode,
        },
      },
    }
  );
}

async function unconfirmJourneyShoppingCart({
  customerJourneyId,
  platformLanguageCode,
  appLanguageCode,
}: {
  customerJourneyId: string;
  platformLanguageCode: LanguageCode;
  appLanguageCode: LanguageCode;
}): Promise<CMShoppingCartResume> {
  return apiClient.put<any>(
    `/customer-web-mobile-ecommerce-shopping-cart/journey/${customerJourneyId}/unconfirm-shopping-cart`,
    {
      options: {
        authenticate: true,
        json: {
          platformLanguageCode,
          appLanguageCode,
        },
      },
    }
  );
}
