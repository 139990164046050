import { isFuture, isPast, isWithinInterval } from 'date-fns';
import { CMJourney, CMJourneyStatus } from 'lib-common-model';

export const journeyStatusBuilder = {
  getJourneyStatus,
};
function getJourneyStatus(
  journey: Pick<CMJourney, 'beginDateUTC' | 'endDateExclusiveUTC'>,
  date: Date = new Date()
): CMJourneyStatus {
  if (!journey) {
    return undefined;
  }
  const { beginDateUTC, endDateExclusiveUTC } = journey;
  let journeyCategory: CMJourneyStatus;

  if (
    isWithinInterval(date, { end: endDateExclusiveUTC, start: beginDateUTC })
  ) {
    // select first matching current journey
    journeyCategory = 'current';
  } else if (isFuture(beginDateUTC)) {
    // else select next journey
    journeyCategory = 'next';
  } else if (isPast(endDateExclusiveUTC)) {
    // else select last journey
    journeyCategory = 'last';
  }
  return journeyCategory;
}
