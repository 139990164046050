import { appWebConfig } from '_01_CORE/app-core/app-config';
import { appLogger } from 'lib-web-logger';
import { Observable, of } from 'rxjs';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import {
  HttpClientFetchOptions,
  HttpClientMethodRead,
  HttpClientMethodWrite,
} from '../http';
import {
  ApiClientRequestOptions,
  ApiClientRequestWithBodyOptions,
} from './ApiClientRequestOptions.model';
import { apiClientStoreProvider } from './apiClientStoreProvider.service';

const versionNumber = appWebConfig().appVersion;

export const apiClientHttpClientFetchOptionsBuilder = {
  buildHttpClientFetchOptions,
};

function buildHttpClientFetchOptions({
  options,
  method,
}:
  | {
      method: HttpClientMethodWrite;
      options: ApiClientRequestWithBodyOptions;
    }
  | {
      method: HttpClientMethodRead;
      options: ApiClientRequestOptions;
    }): Observable<HttpClientFetchOptions> {
  const httpClientFetchOptions: HttpClientFetchOptions = {
    ...options,
    method,
    timeout: options.timeout ?? 60000, // 60s par default (on laisse le serveur ajuster le timeout globalement ou par requête dans app.config.server.ts)
  } as any;

  const apiClientStore = apiClientStoreProvider.get();

  return of(options).pipe(
    switchMap(() => {
      return apiClientStore.authenticationToken.get().pipe(
        first(),
        map((authenticationToken) => {
          try {
            return {
              ...httpClientFetchOptions,
              headers: {
                ...httpClientFetchOptions.headers,
                'Miniclub-Api-Client-Id': 'app-customer-web-mobile-2021',
                'Miniclub-Api-Client-Version': versionNumber,
                'Miniclub-Api-Key': 'QMaXG4KQRD14T61lmVJHPNdQ',
                authorization: authenticationToken
                  ? `Bearer ${authenticationToken}`
                  : undefined,
              },
            } as any;
          } catch (err) {
            console.error(err);
            appLogger.error(
              'Error trying to append client id and version http headers'
            );
            return httpClientFetchOptions;
          }
        })
      );
    }),
    switchMap((httpClientFetchOptions) => {
      if (options.authenticate) {
        return apiClientStore.authenticationToken.get().pipe(
          first(),
          map((authenticationToken) => {
            try {
              return {
                ...httpClientFetchOptions,
                headers: {
                  ...httpClientFetchOptions.headers,
                  authorization: authenticationToken
                    ? `Bearer ${authenticationToken}`
                    : undefined,
                },
              } as any;
            } catch (err) {
              console.error(err);
              appLogger.error(
                'Error trying to append authorization http headers'
              );
              return httpClientFetchOptions;
            }
          }),
          catchError((err) => {
            console.error(err);
            appLogger.error(
              'Error 2 trying to append authorization http headers'
            );
            return of(httpClientFetchOptions);
          })
        );
      }
      return of(httpClientFetchOptions);
    }),
    switchMap((httpClientFetchOptions) => {
      return apiClientStore.appClientId.get().pipe(
        first(),
        map(
          (appClientId) =>
            ({
              ...httpClientFetchOptions,
              headers: {
                ...httpClientFetchOptions.headers,
                'app-client-id': appClientId,
              },
            } as any)
        ),
        catchError((err) => {
          console.error(err);
          appLogger.error('Error trying to append client id http header');
          return of(httpClientFetchOptions);
        })
      );
    }),
    switchMap((httpClientFetchOptions) => {
      return apiClientStore.appVersion.get().pipe(
        first(),
        map(
          (appVersion) =>
            ({
              ...httpClientFetchOptions,
              headers: {
                ...httpClientFetchOptions.headers,
                'app-version': appVersion,
              },
            } as any)
        ),
        catchError((err) => {
          console.error(err);
          appLogger.error('Error trying to append app version http header');
          return of(httpClientFetchOptions);
        })
      );
    })
  );
}
