import { PushNotifications } from '@capacitor/push-notifications';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import { useAppPlatform } from '_01_CORE/app-core/app-platform';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AppNotificationActionPerformed,
  AppNotificationPermissionStatus,
  AppNotificationPermissionsState,
  AppNotificationPushNotificationSchema,
  AppNotificationRegistrationState,
  AppNotificationsState,
} from './model';

// https://capacitorjs.com/docs/apis/push-notifications#example
export const useNotificationState = ({
  enableNotificationsDebug,
}: {
  enableNotificationsDebug: boolean;
}) => {
  const [
    lastActionPerformed,
    setLastActionPerformed,
  ] = useState<AppNotificationActionPerformed>();
  const [
    registrationState,
    setRegistrationState,
  ] = useState<AppNotificationRegistrationState>();

  const [
    lastNotification,
    setLastNotification,
  ] = useState<AppNotificationPushNotificationSchema>();

  const [
    permissionsState,
    setPermissionsState,
  ] = useState<AppNotificationPermissionsState>();

  const [listenersRegistered, setListenersRegistered] = useState<boolean>(
    false
  );

  const { platform } = useAppPlatform();

  const notificationsState = useMemo(() => {
    const state: AppNotificationsState = {
      registrationState,
      lastNotification,
      lastActionPerformed,
      permissionsState,
      listenersRegistered,
    };
    return state;
  }, [
    lastActionPerformed,
    lastNotification,
    permissionsState,
    registrationState,
    listenersRegistered,
  ]);

  const registerListeners = useCallback(async () => {
    if (enableNotificationsDebug) {
      console.log('[useNotificationState] register listeners');
    }

    await PushNotifications.addListener('registration', (token) => {
      if (token) {
        console.info(`[useNotificationState] Registration token received`);
        console.info(
          `[useNotificationState] Registration token: ${token.value}`
        );
        setRegistrationState({
          success: true,
          token,
        });
      } else {
        console.warn(
          `[useNotificationState] Registration empty token received`
        );
      }
    });
    await PushNotifications.addListener('registrationError', (error) => {
      if (error) {
        console.error('[useNotificationState] Registration error received');
        console.error(
          '[useNotificationState] Registration error: ',
          error.error
        );
        console.error('[useNotificationState] Registration error 2: ', error);
        console.error(
          '[useNotificationState] Registration error 3: ',
          JSON.stringify(error)
        );
        setRegistrationState({
          success: false,
          error,
        });
      } else {
        console.warn(
          `[useNotificationState] Registration empty error received`
        );
      }
    });
    await PushNotifications.addListener(
      'pushNotificationReceived', // notification reçue quand l'appli est au premier plan
      (pushNotification) => {
        if (pushNotification) {
          console.log('[useNotificationState] Push notification received');
          console.log(
            '[useNotificationState] Push notification received: ',
            pushNotification
          );
          setLastNotification(pushNotification);
        } else {
          console.warn(
            `[useNotificationState] Registration empty notification received`
          );
        }
      }
    );

    await PushNotifications.addListener(
      'pushNotificationActionPerformed', // notification reçue quand l'appli est fermée ou en arrière plan, au moment ou l'user clique sur le lien
      (action) => {
        if (action) {
          console.log(
            '[useNotificationState] Push notification action performed',
            action.actionId,
            action.inputValue
          );
          setLastActionPerformed(action);
          if (action.notification) {
            setLastNotification(action.notification);
          }
        } else {
          console.warn(
            `[useNotificationState] Registration empty action received`
          );
        }
      }
    );
    setListenersRegistered(true);
  }, [enableNotificationsDebug]);

  useEffect(() => {
    if (isNotificationSupported(platform)) {
      registerListeners();
      return () => {
        if (enableNotificationsDebug) {
          console.log('[useNotificationState] UNregister listeners');
        }
        setListenersRegistered(false);
        PushNotifications?.removeAllListeners();
      };
    }
  }, [enableNotificationsDebug, platform, registerListeners]);

  const initNotifications = useCallback(async () => {
    if (enableNotificationsDebug) {
      console.log('[useNotificationState] init notifications');
    }
    if (listenersRegistered) {
      console.error(
        '[useNotificationState] notifications init run before listeners are registered!'
      );
    }
    if (isNotificationSupported(platform)) {
      try {
        if (enableNotificationsDebug) {
          console.log('[useNotificationState] notifications are supported');
        }

        const checkStatus = await PushNotifications.checkPermissions();
        setPermissionsState({
          checkStatus,
        });
        if (enableNotificationsDebug) {
          console.log(
            `[useNotificationState] checkStatus: ${checkStatus.receive}`
          );
        }
        let requestStatus: AppNotificationPermissionStatus;

        const force = true;
        if (force || requestStatus.receive === 'prompt') {
          requestStatus = await PushNotifications.requestPermissions();
          if (enableNotificationsDebug) {
            console.log(
              `[useNotificationState] requestStatus: ${requestStatus.receive}`
            );
          }
          setPermissionsState({
            checkStatus,
            requestStatus,
          });
        }

        if (
          checkStatus.receive === 'granted' ||
          requestStatus?.receive === 'granted'
        ) {
          if (enableNotificationsDebug) {
            console.log('[useNotificationState] register notifications NOW');
          }
          if (enableNotificationsDebug) {
            console.log('[useNotificationState] register now');
          }
          await PushNotifications.register();
          if (enableNotificationsDebug) {
            console.log('[useNotificationState] registration done');
          }
        } else {
          // throw new Error('User denied permissions!');
          console.error(
            '[useNotificationState] User denied notifications permissions!'
          );
        }
      } catch (err) {
        console.error('[useNotificationState] Registration error');
        console.error('[useNotificationState] Registration error', err);
        console.error(err);
        console.error(JSON.stringify(err));
      }
    } else {
      // notification not supported
      if (appWebConfig().envId === 'dev') {
        console.log('[useNotificationState] use fake token');
        setRegistrationState({
          success: true,
          token: { value: 'fake-token-dev' },
        });
      }
    }
  }, [enableNotificationsDebug, listenersRegistered, platform]);

  return {
    notificationsState,
    initNotifications,
  };
};

function isNotificationSupported(platform: string) {
  return platform && platform !== 'web';
}
