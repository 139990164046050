import { IonBadge, IonToolbar } from '@ionic/react';
import React from 'react';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import { useNetworkStatus } from '_01_CORE/app-core/app-platform';
import { AppBottomTabsMenu } from '../AppMenu';
import { useAppMenu } from '../AppMenu/useAppMenu.hook';
import { AppMenu, AppMenuItemId } from '../AppMenu/_model';
import { NetworkStatus } from '../NetworkStatus/NetworkStatus';

export const FooterToolbar: React.FC<{ tabId: AppMenuItemId }> = ({
  tabId,
}) => {
  const { networkStatus } = useNetworkStatus();

  const tabsMenu: AppMenu = useAppMenu({
    tabId,
    type: 'bottom-items-menu',
    hideLogin: false,
    hideLogout: true,
    onClickLogout: () => {
      // always hidden
    },
  });

  return (
    // TODO Toub: il faudra remplacer ce composant par un composant maison, pour pouvoir virer le IonRouterOutlet qui pose problème!
    <div className="">
      {networkStatus?.connected !== false ? null : (
        <IonToolbar>
          {appWebConfig().debug.showEnvId && (
            <IonBadge>
              {appWebConfig().envId}/{appWebConfig().remoteEnvId}
            </IonBadge>
          )}
          {appWebConfig().debug.showAppVersion && (
            <IonBadge>({appWebConfig().appVersion})</IonBadge>
          )}
          <NetworkStatus showLabel={true} />
        </IonToolbar>
      )}
      <AppBottomTabsMenu tabsMenu={tabsMenu} />
    </div>
  );
};
