import { useLoadable } from 'lib-web-redux';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { authenticationStore } from './authenticationStore.service';

export function useAppSecurityUser() {
  return useLoadable({
    debugName: 'useAppSecurityUser',
    initialValueFromLoadSnapshot: true,
    load: () =>
      authenticationStore.auth.get().pipe(
        map(({ securityUser }) => securityUser),
        distinctUntilChanged()
      ),
  });
}
