import { CompanyOptionsTheme } from './CompanyOptionsTheme.type';

export const COMPANY_DEFAULT_THEME: CompanyOptionsTheme = {
  title1Color: '#366292',
  title2Color: '#333',
  textColor: '#555',
  buttonPrimaryBgColor: '#2a4b92',
  buttonPrimaryTextColor: '#ffffff',
  buttonCancelBgColor: '#fafafa',
  buttonCancelTextColor: '#555',
};
