import clsx from 'clsx';
import React, { FC, useMemo } from 'react';
import { colorGenerator } from '../data/color';
import { AppButtonUnstyled, AppButtonUnstyledProps } from './AppButtonUnstyled';

export type AppButtonFreeColorProps = AppButtonUnstyledProps & {
  color: string;
  textColor?: string;
  className?: string;
  roundedClassName?: string;
  fontWeightClassName?: string;
};

export const AppButtonFreeColor: FC<AppButtonFreeColorProps> = ({
  textSize = 'text-sm',
  iconStyleClass = 'ml-3 -mr-7 h-5 w-5',
  className,
  roundedClassName = 'rounded-full',
  fontWeightClassName = 'font-medium',
  color: bgColorInput,
  textColor: textColorInput,
  children,
  ...props
}) => {
  const { style, hoverStyle } = useMemo(() => {
    const isLightBgColor = colorGenerator.isLight(bgColorInput);

    const style: React.CSSProperties = {
      backgroundColor: bgColorInput,
      borderColor: colorGenerator.darken(bgColorInput, 0.5),
      color: textColorInput ?? (isLightBgColor ? '#000' : '#fff'),
    };
    const hoverStyle: React.CSSProperties = {
      backgroundColor: colorGenerator.darken(bgColorInput, 0.3),
      borderColor: colorGenerator.darken(bgColorInput, 0.8),
      color: textColorInput ?? (isLightBgColor ? '#000' : '#fff'),
    };
    return {
      style,
      hoverStyle,
    };
  }, [bgColorInput, textColorInput]);

  return (
    <AppButtonUnstyled
      {...props}
      className={clsx(
        'group ${textSize} items-start px-1 py-2 text-center',
        fontWeightClassName ?? 'font-medium',
        props.icon ? 'justify-start' : 'justify-around',
        props.disabled && 'text-opacity-60 hover:text-opacity-60',
        roundedClassName,
        className
      )}
      iconStyleClass={iconStyleClass}
      style={style}
      hoverStyle={hoverStyle}
    >
      {children}
    </AppButtonUnstyled>
  );
};
