const dateRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)$/;
const dateRegexWithTimezone = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)\+(\d{2}):(\d{2})$/;
const dateRegexUTC = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)Z$/;

export const jsonParser = {
  parseJSONWithDates,
};

function parseJSONWithDates<T>(body: any): T {
  // appLogger.debug('[JsonDateParser] BODY:', body);
  const json =
    body !== ''
      ? JSON.parse(body, (key: any, value: any) => reviveUtcDate(key, value))
      : null;
  // appLogger.debug('[JsonDateParser] JSON:', json);
  return json;
}

function reviveUtcDate(key: any, value: any): any {
  if (typeof value !== 'string') {
    return value;
  }
  if (value === '0001-01-01T00:00:00') {
    return null;
  }
  if (dateRegex.exec(value)) {
    // LocalDateTime
    // appLogger.debug('[JsonDateParser] BASIC dateRegex:', new Date(value));

    return new Date(value);
  } else if (dateRegexWithTimezone.exec(value)) {
    // appLogger.debug('[JsonDateParser] TIMEZONE dateRegex:', new Date(value));

    return new Date(value);
  } else if (dateRegexUTC.exec(value)) {
    // OffsetDateTime
    // appLogger.debug('[JsonDateParser] UTC dateRegex:', new Date(value));
    return new Date(value);
  }
  return value;
}
