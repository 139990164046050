import { EcommerceBookingPaymentResume } from 'lib-common-model';
import React from 'react';
import { useIntl } from 'react-intl';
import { dateTransformer } from '_01_CORE/_components-core';

export const AccroparkBookingResumeTablePaymentRow = ({
  lastPaymentResume,
  className,
}: {
  lastPaymentResume: EcommerceBookingPaymentResume;
  className?: string;
}) => {
  const { formatMessage: t } = useIntl();

  const date = dateTransformer.formatUTC(
    lastPaymentResume?.paymentAcceptedDate,
    t({ id: 'common.date.format' })
  );
  const time = dateTransformer.formatUTC(
    lastPaymentResume?.paymentAcceptedDate,
    'HH:mm'
  );
  const dateTimeText = t({ id: 'component.journey.theAt' }, { date, time });

  return (
    <div className={className}>
      <>
        {lastPaymentResume?.type === 'bambora' && (
          <div>
            <b>{t({ id: 'component.resumeTable.paidOnline' })}</b>{' '}
            {dateTimeText}
          </div>
        )}
        {lastPaymentResume?.type === 'external' && (
          <div>
            <b>{t({ id: 'component.resumeTable.paidOnSpot' })}</b>{' '}
            {dateTimeText}
          </div>
        )}
        {lastPaymentResume?.type === 'free' && (
          <div>
            <b>{t({ id: 'component.resumeTable.freeEdit' })}</b> {dateTimeText}
          </div>
        )}
        {lastPaymentResume?.paymentReference && (
          <div className="text-gray-400">
            {t({ id: 'booking.transaction.reference' })}:{' '}
            <span className="font-bold">
              {lastPaymentResume?.paymentReference}
            </span>
          </div>
        )}
      </>
    </div>
  );
};
