import { ActivityTranslation } from 'lib-common-model';
import React from 'react';
import { useIntl } from 'react-intl';
import { useAppTheme } from '_01_CORE/_common-layout';
import {
  AppRichTextH1,
  AppRichTextH2,
  AppTranslationContentPanel,
} from '_01_CORE/_components-core';
import { ActivityTitleCardLocation } from '_02_APP_COMMON/ACT-activity/ACT-16-journey-activities/JourneyDailyActivity/components/ActivityTitle';

export const ActivityTranslationPreview = ({
  translation,
  companyReference,
  className,
}: {
  translation: ActivityTranslation;
  companyReference: string;
  className?: string;
}) => {
  const { formatMessage: t } = useIntl();

  const theme = useAppTheme({ companyReference });

  return (
    translation && (
      <div className={`w-full h-full overflow-y-auto ${className}`}>
        <AppRichTextH1 theme={theme}>{translation.name}</AppRichTextH1>
        {translation.subTitle && (
          <AppRichTextH2 theme={theme}>{translation.subTitle}</AppRichTextH2>
        )}
        <ActivityTitleCardLocation
          className="mt-1 text-sm mm:text-base text-left font-bold"
          translation={translation}
        />
        {translation.description &&
          translation.description.map((item, i) => (
            <AppTranslationContentPanel
              companyReference={companyReference}
              key={i}
              item={item}
            />
          ))}
      </div>
    )
  );
};
