import { useIntl } from 'react-intl';
import { JourneyDailyActivityCardParticipantsList } from '../JourneyDailyActivityCardParticipantsList';
import {
  CustomerActivityParticipantsDailyActivitParticipantState,
  CustomerActivityParticipantsDailyActivity,
  CustomerActivityUpdateParticipantStateFn,
} from '../model';
export const ActivitySessionDetailsDialogWaitingListArea = ({
  dailyActivity,
  participants,
  companyReference,
  onClickParticipant,
}: {
  dailyActivity: CustomerActivityParticipantsDailyActivity;
  participants: CustomerActivityParticipantsDailyActivitParticipantState[];
  companyReference: string;
  onClickParticipant: CustomerActivityUpdateParticipantStateFn;
}) => {
  const { formatMessage: t } = useIntl();

  return (
    <div className="my-2 mx-2 sm:mx-6 p-2 border-2 border-app-warning">
      <div className="font-bold text-app-warning text-justify">
        {t({
          id:
            'page16.activity-session-details-dialog.seance-complete-souhaitez-vous-liste-attente',
        })}
      </div>
      <JourneyDailyActivityCardParticipantsList
        className="mt-2"
        activitySession={dailyActivity.selectedSession.activitySession}
        companyReference={companyReference}
        participants={participants}
        onClickParticipant={({ participantState, activitySession, action }) => {
          return onClickParticipant({
            dailyActivity,
            participantState,
            action,
          });
        }}
      />
      <div className="text-justify">
        {t({
          id:
            'page16.activity-session-details-dialog.seance-complete-si-place-se-libere',
        })}
      </div>
    </div>
  );
};
