import { Transition } from '@headlessui/react';
import { IonAlert, IonIcon } from '@ionic/react';
import { useAppTheme } from '_01_CORE/_common-layout/AppTheme';
import {
  AppButtonUniverse,
  AppIcons,
  AppSelectLanguage,
  useAppModalContext,
} from '_01_CORE/_components-core';
import { useAppRouter, useAppToasts } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import {
  useAppCacheContext,
  useCompany,
} from '_01_CORE/app-contexts/app-cache-context';
import {
  AppLocalizationContextLocales,
  useAppLocalizationContext,
} from '_01_CORE/app-contexts/app-localization-context';
import {
  companyApiClient,
  customerAccountApiClient,
} from '_01_CORE/app-core/app-api';
import { appWebConfig, useAppFeatures } from '_01_CORE/app-core/app-config';
import { close } from 'ionicons/icons';
import { CMFetchedData, LANGUAGES_ARRAY, LanguageCode } from 'lib-common-model';
import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { AppMenu } from '../_model';
import { useAppMenu } from '../useAppMenu.hook';
import { AppSideMenuItems } from './AppSideMenuItems';

export const AppSideMenu: React.FC<{
  isMobileOpen: boolean;
  setIsMobileOpen: (isOpen: boolean) => void;
  hideLogout?: boolean;
}> = ({ isMobileOpen, setIsMobileOpen, hideLogout }) => {
  const appId = appWebConfig().appId;
  const appCacheContext = useAppCacheContext();
  const { auth, companyValidReferenceCode } = appCacheContext;
  const { formatMessage: t } = useIntl();
  const appRouter = useAppRouter();
  const [displayLogoutAlert, setDisplayLogoutAlert] = useState(false);
  const [isChangeLangOpen, setIsChangeLangOpen] = useState(false);
  const { isFeatureAuthEnabled } = useAppFeatures();
  hideLogout = !!hideLogout || !isFeatureAuthEnabled;

  const { company } = useCompany({
    companyReference: companyValidReferenceCode,
  }); // if !companyValidReferenceCode, use journey.company

  const availableLanguages = useMemo(
    () =>
      company?.availableLanguagesCodes
        ? LANGUAGES_ARRAY.filter((c) =>
            company?.availableLanguagesCodes.includes(c.id)
          )
        : [],
    [company?.availableLanguagesCodes]
  );

  const theme = useAppTheme({ companyReference: companyValidReferenceCode });

  const handleLogoutClick = useCallback(() => {
    if (auth?.isAuthenticated) {
      setDisplayLogoutAlert(true);
    } else {
      appRouter.navigate(appRoutesBuilder.getLoginHomeUrl(), {
        cause: 'menu-logout-done',
      });
    }
  }, [appRouter, auth?.isAuthenticated]);

  const { openModal, closeModal } = useAppModalContext();
  const { pushError, pushSuccess } = useAppToasts();

  const onClickDeleteAccount = useCallback(() => {
    openModal({
      title: t({ id: 'menu.delete-account.confirm' }),
      content: null,
      actions: (
        <div className="w-full grid gap-4">
          <AppButtonUniverse
            className="w-full"
            style={'primary'}
            isFullWidth={true}
            label={t({ id: 'common.action.ok' })}
            icon={AppIcons.check}
            onClick={async () => {
              // register to waiting-list
              try {
                await customerAccountApiClient.deleteMyAccount();
              } catch (err) {
                console.error(err);
              } finally {
                closeModal();
                pushSuccess(t({ id: 'menu.delete-account.success' }), 10000);
              }
            }}
          />
          <AppButtonUniverse
            className="w-full"
            style={'cancel'}
            isFullWidth={true}
            label={t({ id: 'common.action.cancel' })}
            icon={AppIcons.actionClose}
            onClick={closeModal}
          />
        </div>
      ),
    });
  }, [closeModal, openModal, pushSuccess, t]);

  const { appLanguage, locales, switchLanguage } = useAppLocalizationContext();

  const handleLogout = useCallback(() => {
    appRouter.navigate(appRoutesBuilder.getLogoutUrl(), {
      cause: 'menu-logout',
    });
  }, [appRouter]);

  const handleDismissLogoutAlert = useCallback(
    () => setDisplayLogoutAlert(false),
    []
  );

  const appMenu: AppMenu = useAppMenu({
    type: 'side-menu',
    hideLogin: hideLogout,
    hideLogout,
    onClickLogout: () => handleLogoutClick(),
    onClickDeleteAccount,
  });

  const onChangeLanguage = useCallback(
    async (languageCode: LanguageCode) => {
      if (languageCode) {
        const {
          platformLanguageCode,
          appLanguageCode,
        }: AppLocalizationContextLocales = switchLanguage(languageCode);
        setIsMobileOpen(false);
        const companyReference = company?.reference;
        const groupReference = company?.companyGroupReference;
        if (companyReference) {
          // fetch i18n booklet
          const fetchedData: CMFetchedData = await companyApiClient.fetchCustomerData(
            {
              authenticate: appCacheContext.auth?.isAuthenticated,
              groupReference,
              companyReference,
              customerJourneyId: undefined,
              groupUpdateDate: undefined,
              bookingUpdateDate: undefined,
              bookingId: undefined,
              companyUpdateDate: undefined,
              customerJourneyUpdateDate: undefined,
              notificationsUpdateDate: undefined,
              platformLanguageCode,
              appLanguageCode,
              fetchCompanyWelcomeBooklet: true,
              fetchCompanyMenu: true,
              companyWelcomeBookletLanguageCode: undefined,
              companyWelcomeBookletUpdateDate: undefined,
            }
          );
          appCacheContext.dispatchCacheContextAction({
            type: 'set-fetched-data',
            fetchedData,
            companyReference,
            context: 'load-guard-fetch-customer-data',
          });
        }
      }
    },
    [
      appCacheContext,
      company?.companyGroupReference,
      company?.reference,
      setIsMobileOpen,
      switchLanguage,
    ]
  );

  return (
    <>
      <Transition
        className={''}
        show={isMobileOpen}
        enter="transition-opacity ease-linear duration-300"
        enterFrom="opacity-100"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-100"
      >
        {() => (
          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className="fixed inset-0"
              aria-hidden="true"
            >
              {/* Off-canvas menu overlay, show/hide based on off-canvas menu state. */}
              {() => (
                <div
                  className="absolute inset-0 bg-gray-600 opacity-75"
                  onClick={() => setIsMobileOpen(!isMobileOpen)}
                ></div>
              )}
            </Transition.Child>

            <Transition.Child
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
              className="relative max-w-xs w-full bg-gray-50 text-gray-600 pt-5 pb-4 flex-1 flex flex-col"
            >
              {/* Off-canvas menu, show/hide based on off-canvas menu state. */}
              {() => (
                <>
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      onClick={() => setIsMobileOpen(!isMobileOpen)}
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    >
                      <IonIcon icon={close} className="w-6 h-6" />
                    </button>
                  </div>
                  <div className="mt-5 flex-1 h-0 overflow-y-auto">
                    <nav className="px-2 space-y-2 md:space-y-5">
                      {availableLanguages?.length > 1 && (
                        <AppSelectLanguage
                          // className="my-2"
                          disableClearButton={true}
                          placeholder="Langue"
                          // placeholder={t({ id: 'form.language' })}
                          languages={availableLanguages}
                          value={locales?.appLanguageCode}
                          onChange={onChangeLanguage}
                        />
                      )}
                      {appMenu.items.map((item) => (
                        <AppSideMenuItems
                          key={item.id}
                          item={item}
                          theme={theme}
                        />
                      ))}
                    </nav>
                  </div>
                </>
              )}
            </Transition.Child>
            <div className="flex-shrink-0 w-14"></div>
          </div>
        )}
      </Transition>

      <IonAlert
        isOpen={displayLogoutAlert}
        onDidDismiss={handleDismissLogoutAlert}
        header={t({ id: 'page04.logoutAlertHeader' })}
        message={t({ id: 'page04.logoutAlertMessage' })}
        buttons={[
          t({ id: 'common.action.cancel' }),
          { handler: handleLogout, text: 'OK' },
        ]}
      />
    </>
  );
};
