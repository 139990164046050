import { Language } from './Language.model';
import { LANGUAGES_ARRAY } from './LANGUAGES_ARRAY.const';

export const LANGUAGES_MAP: { [id: string]: Language } = LANGUAGES_ARRAY.reduce(
  (acc, language) => {
    acc[language.id] = language;
    return acc;
  },
  {} as { [id: string]: Language }
);
