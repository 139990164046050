import { AppMessageLight } from '_01_CORE/_common-layout';
import {
  AppButtonV2,
  AppLoaderType,
  useAppModalContext,
} from '_01_CORE/_components-core';
import { useAppRouter, useOperationPending } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import {
  useAppCacheContext,
  useAppLocalizationContext,
} from '_01_CORE/app-contexts';
import { ecommerceShoppingCartApiClient } from '_01_CORE/app-core/app-api';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import {
  APP_CURRENCIES_MAP,
  CMShoppingCartResume,
  CMShoppingCartResumeCartItem,
  CompanyPlace,
  ParticipantPaymentMode,
} from 'lib-common-model';
import { useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { CMShoppingCartResumeCartItemByPaymentModeMap } from '../_common';
import { useShoppingCartItemsByPaymentMode } from '../_common/AnimLiveShoppingCartTable/useShoppingCartItemsByPaymentMode.hook';
import { AnimLiveShoppingCartTableFormModel } from './AnimLiveJourneyShoppingCartPanel';

export function useAnimLiveJourneyShoppingCartPanelState({
  shoppingCartResume,
  updateShoppingCartResume,
  goBack,
}: {
  shoppingCartResume: CMShoppingCartResume;
  updateShoppingCartResume: (resume: CMShoppingCartResume) => any;
  goBack: () => any;
}) {
  const appRouter = useAppRouter();
  const { formatMessage: t } = useIntl();
  const appId = appWebConfig().appId;

  const { customerJourneyId } = useParams<{ customerJourneyId: string }>();

  const { openModal, closeModal } = useAppModalContext();
  const appCacheContext = useAppCacheContext();

  const currencySymbol =
    APP_CURRENCIES_MAP[shoppingCartResume?.shoppingCart?.purchase.currency]
      ?.symbol;

  const isEmptyShoppingCart =
    shoppingCartResume?.shoppingCartItems?.length === 0;
  const isPaymentPending =
    !isEmptyShoppingCart &&
    shoppingCartResume?.shoppingCart?.paymentStatus === 'payment-pending';

  const {
    locales: { platformLanguageCode, appLanguageCode },
  } = useAppLocalizationContext();

  const [operationInProgress, setOperationInProgress] = useState<{
    type: AppLoaderType;
    // message: string; on pourrait préciser un message
  }>();

  const shoppingCartItemsByPaymentMode: CMShoppingCartResumeCartItemByPaymentModeMap = useShoppingCartItemsByPaymentMode(
    shoppingCartResume.shoppingCartItems,
    shoppingCartResume.shoppingCart
  );

  const form = useForm<AnimLiveShoppingCartTableFormModel>({
    defaultValues: {
      paymentMode:
        shoppingCartItemsByPaymentMode.online?.length +
          shoppingCartItemsByPaymentMode.toChoose?.length >
        0
          ? 'online'
          : 'on-site',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const {
    register,
    handleSubmit,
    watch,
    formState,
    control,
    setValue,
    reset,
  } = form;

  const [paymentMode] = useWatch({
    control,
    name: ['paymentMode'],
  });

  const shoppingCartItemsOnSiteAndOnSiteChoice = useMemo(() => {
    const shoppingCartItems: CMShoppingCartResumeCartItem[] =
      paymentMode === 'on-site'
        ? shoppingCartItemsByPaymentMode.onSite.concat(
            shoppingCartItemsByPaymentMode.toChoose
          )
        : shoppingCartItemsByPaymentMode.onSite;
    return shoppingCartItems;
  }, [
    paymentMode,
    shoppingCartItemsByPaymentMode.onSite,
    shoppingCartItemsByPaymentMode.toChoose,
  ]);

  const onSitePaymentPlaces = useMemo(() => {
    return shoppingCartItemsOnSiteAndOnSiteChoice
      .map((x) => x.onSitePaymentPlace)
      .filter((x) => !!x)
      .reduce((accumulator: CompanyPlace[], currentItem: CompanyPlace) => {
        // Check if an item with the same name already exists in the accumulator
        const isDuplicate = accumulator.some(
          (item) => item._id === currentItem._id
        );

        // If it's a duplicate, skip adding it to the accumulator
        if (isDuplicate) {
          return accumulator;
        }

        // If it's not a duplicate, add it to the accumulator
        return [...accumulator, currentItem];
      }, []);
  }, [shoppingCartItemsOnSiteAndOnSiteChoice]);

  const deleteBookingConfirm = useOperationPending(async () => {
    if (isPaymentPending) {
      openModal({
        title: 'Annuler ma réservation',
        content: (
          <div>Êtes-vous sûr de vouloir annuler votre réservation ?</div>
        ),
        actions: (
          <div className="flex justify-between gap-4">
            <AppButtonV2
              className="w-full"
              buttonStyle="cancel"
              onClick={closeModal}
            >
              {t({ id: 'modal.no' })}
            </AppButtonV2>
            <AppButtonV2
              className="w-full"
              buttonStyle="danger"
              onClick={async () => {
                try {
                  setOperationInProgress({
                    type: 'loading',
                  });
                  closeModal();
                  const {
                    customerJourney,
                  } = await ecommerceShoppingCartApiClient.cancelAndDeleteJourneyShoppingCart(
                    {
                      customerJourneyId,
                      appLanguageCode,
                      platformLanguageCode,
                    }
                  );
                  appCacheContext.dispatchCacheContextAction({
                    type: 'set-journey',
                    customerJourney,
                    context: 'cancelAndDeleteJourneyShoppingCart',
                  });
                  // redirect to shopping
                  appRouter.navigate(
                    appRoutesBuilder.getJourneyActivitiesUrl({
                      customerJourneyId,
                      category: undefined,
                    }),
                    {
                      cause: 'dekete-booking',
                    }
                  );
                } finally {
                  setOperationInProgress(undefined);
                }
              }}
            >
              {t({ id: 'modal.yes' })}
            </AppButtonV2>
          </div>
        ),
      });
    } else {
      goBack();
    }
  }, [
    appCacheContext,
    appLanguageCode,
    appRouter,
    closeModal,
    customerJourneyId,
    goBack,
    isPaymentPending,
    openModal,
    platformLanguageCode,
    t,
  ]);

  const confirmBooking = useOperationPending(
    async ({
      paymentMode,
      redirectToOnlinePaymentPage,
    }: {
      paymentMode: ParticipantPaymentMode;
      redirectToOnlinePaymentPage: boolean;
    }) => {
      if (isPaymentPending) {
        if (redirectToOnlinePaymentPage) {
          try {
            setOperationInProgress({
              type: 'loading',
            });

            await ecommerceShoppingCartApiClient.confirmJourneyShoppingCart({
              customerJourneyId,
              appLanguageCode,
              platformLanguageCode,
              paymentMode,
            });
            // redirect to payment page
            appRouter.navigate(
              appRoutesBuilder.getJourneyShoppingCartPaymentUrl({
                customerJourneyId,
                shoppingCartId: shoppingCartResume.shoppingCart._id,
              }),
              {
                cause: 'confirm-booking',
              }
            );
          } finally {
            setOperationInProgress(undefined);
          }
        } else {
          openModal({
            title: 'Confirmer ma réservation',
            content: (
              <div>
                {/* <div>Êtes-vous sûr de vouloir confirmer votre réservation ?</div> */}
                <AppMessageLight
                  className="my-4 text-base"
                  type="warn"
                  hideIcon={true}
                >
                  En confirmant votre réservation, vous vous engagez à payer la
                  totalité du montant dû, soit{' '}
                  <b>
                    {shoppingCartResume?.shoppingCart.purchase.totalToPayNow.toFixed(
                      2
                    )}{' '}
                    {currencySymbol}
                  </b>
                  .
                </AppMessageLight>
              </div>
            ),
            actions: (
              <div className="flex justify-between gap-4">
                <AppButtonV2
                  className="w-full"
                  buttonStyle="cancel"
                  onClick={closeModal}
                >
                  {t({ id: 'modal.no' })}
                </AppButtonV2>
                <AppButtonV2
                  className="w-full"
                  buttonStyle="primary"
                  onClick={async () => {
                    try {
                      setOperationInProgress({
                        type: 'persist',
                      });
                      closeModal();
                      const shoppingCartResume = await ecommerceShoppingCartApiClient.confirmJourneyShoppingCart(
                        {
                          customerJourneyId,
                          appLanguageCode,
                          platformLanguageCode,
                          paymentMode,
                        }
                      );
                      updateShoppingCartResume(shoppingCartResume);
                    } finally {
                      setOperationInProgress(undefined);
                    }
                  }}
                >
                  {t({ id: 'modal.yes' })}
                </AppButtonV2>
              </div>
            ),
          });
        }
      } else {
        goBack();
      }
    },
    [
      appLanguageCode,
      appRouter,
      closeModal,
      currencySymbol,
      customerJourneyId,
      goBack,
      isPaymentPending,
      openModal,
      platformLanguageCode,
      shoppingCartResume.shoppingCart._id,
      shoppingCartResume.shoppingCart.purchase.totalToPayNow,
      t,
      updateShoppingCartResume,
    ]
  );

  const unconfirmBooking = useOperationPending(async () => {
    if (isPaymentPending) {
      openModal({
        title: 'Modifier ma réservation',
        content: (
          <div>
            {/* <div>Êtes-vous sûr de vouloir confirmer votre réservation ?</div> */}
            <AppMessageLight
              className="my-4 text-base"
              type="warn"
              hideIcon={true}
            >
              Êtes-vous sûr de vouloir modifier votre réservation ?
            </AppMessageLight>
          </div>
        ),
        actions: (
          <div className="flex justify-between gap-4">
            <AppButtonV2
              className="w-full"
              buttonStyle="cancel"
              onClick={closeModal}
            >
              {t({ id: 'modal.no' })}
            </AppButtonV2>
            <AppButtonV2
              className="w-full"
              buttonStyle="primary"
              onClick={async () => {
                try {
                  setOperationInProgress({
                    type: 'loading',
                  });
                  closeModal();
                  const shoppingCartResume = await ecommerceShoppingCartApiClient.unconfirmJourneyShoppingCart(
                    {
                      customerJourneyId,
                      appLanguageCode,
                      platformLanguageCode,
                    }
                  );
                  updateShoppingCartResume(shoppingCartResume);
                } finally {
                  setOperationInProgress(undefined);
                }
              }}
            >
              {t({ id: 'modal.yes' })}
            </AppButtonV2>
          </div>
        ),
      });
    } else {
      goBack();
    }
  }, [
    appLanguageCode,
    closeModal,
    customerJourneyId,
    goBack,
    isPaymentPending,
    openModal,
    platformLanguageCode,
    t,
    updateShoppingCartResume,
  ]);

  return {
    isPaymentPending,
    form,
    isEmptyShoppingCart,
    operationInProgress,
    shoppingCartItemsOnSiteAndOnSiteChoice,
    confirmBooking,
    deleteBookingConfirm,
    onSitePaymentPlaces,
    unconfirmBooking,
  };
}

export type AnimLiveJourneyShoppingCartPanelState = ReturnType<
  typeof useAnimLiveJourneyShoppingCartPanelState
>;
