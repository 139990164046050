import { ExtJourneyToActivateCard } from '_01_CORE/_components-core/JourneyCard/ExtJourneyToActivateCard';
import { useAppRouter } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import { useAppCacheContext } from '_01_CORE/app-contexts';
import { customerJourneyApiClient } from '_01_CORE/app-core/app-api';
import { CMExtJourneyToActivate } from 'lib-common-model';
import { useCallback, useMemo } from 'react';

export const JourneysListNewJourneysToActivatePanel = ({
  extJourneysToActivate: extJourneysToActivateInput,
  emailAddress,
}: {
  extJourneysToActivate: CMExtJourneyToActivate[];
  emailAddress?: string; // utile si on n'est pas connecté
}) => {
  const appRouter = useAppRouter();
  const appCacheContext = useAppCacheContext();
  const { auth } = appCacheContext;
  const customerAccount = auth?.customerProfile?.account;
  const customerAccountMembers = auth?.customerProfile?.members;

  const extJourneysToActivate: CMExtJourneyToActivate[] = useMemo(() => {
    if (!extJourneysToActivateInput) {
      return [];
    }
    return extJourneysToActivateInput.sort((a, b) =>
      a.extCustomerJourneyBookingGroup.beginDateUTC >
      b.extCustomerJourneyBookingGroup.beginDateUTC
        ? -1
        : a.extCustomerJourneyBookingGroup.beginDateUTC <
          b.extCustomerJourneyBookingGroup.beginDateUTC
        ? 1
        : 0
    );
  }, [extJourneysToActivateInput]);

  const startExtJourneyActivation = useCallback(
    async (extJourneyToActivate: CMExtJourneyToActivate) => {
      const {
        company,
        extCustomerJourney,
        extCustomerJourneyBookingGroup,
        extCustomerAccount,
      } = extJourneyToActivate;

      const companyReference = company.reference;
      const activationToken = extCustomerJourneyBookingGroup.activationToken;

      if (!activationToken) {
        // on renvoit un mail et on demande le code d'activation
        const extCustomerJourneyBookingGroupId =
          extCustomerJourneyBookingGroup._id;
        const extCustomerAccountActivationEmail =
          extCustomerAccount?.email1 ?? extCustomerAccount?.email2;
        await customerJourneyApiClient.reSendExternalJourneyActivationMail({
          extCustomerJourneyBookingGroupId,
          emailAddress,
        });
        appRouter.navigate(
          appRoutesBuilder.getJourneyActivateByKeyUrl({
            companyReference,
            extCustomerAccountActivationEmail,
            extCustomerJourneyBookingGroupId,
          }),
          { cause: 'create-journey-from-external' }
        );
      } else {
        // activation du séjour
        const fetchData = await customerJourneyApiClient.fetchExternalJourneyDetailsFromActivationToken(
          {
            activationToken,
            companyReference,
          }
        );

        appCacheContext.dispatchCacheContextAction({
          type: 'set-journey-to-edit-from-external-data',
          payload: {
            companyReference,
            externalData: fetchData,
            activationToken,
            customerAccount,
            customerAccountMembers,
          },
        });
        appRouter.navigate(
          appRoutesBuilder.getJourneyCreateParticipantsListUrl({
            companyReference,
          }),
          { cause: 'create-journey-from-external' }
        );
      }
    },
    [
      appCacheContext,
      appRouter,
      customerAccount,
      customerAccountMembers,
      emailAddress,
    ]
  );

  return extJourneysToActivate.length > 0 ? (
    <div className="flex flex-col gap-y-8">
      <h3 className="my-2 py-1 text-center font-bold text-sm sm:text-base bg-app-warning uppercase text-white">
        Nouveaux séjours détectés
      </h3>
      <p className=" text-gray-600 text-left font-bold">
        {/* <AppIcons.info className="inline-flex w-6 h-6 mr-2" /> */}
        {`Cliquez sur un séjour pour l'activer :`}
      </p>
      <div className="grid divide-y divide-gray-100">
        {extJourneysToActivate.reverse().map((extJourneyToActivate, i) => (
          <ExtJourneyToActivateCard
            key={i}
            extJourneyToActivate={extJourneyToActivate}
            onClick={() => {
              startExtJourneyActivation(extJourneyToActivate);
            }}
          />
        ))}
      </div>
    </div>
  ) : null;
};
