import {
  AppPage,
  BannerList,
  HeaderToolbar,
  PageContainer,
  PageGroupTitle,
} from '_01_CORE/_common-layout';
import { useAppRouter } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import { useGroup } from '_01_CORE/app-contexts';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import { useAppImageUrl } from '_01_CORE/app-core/app-security/useAppImageUrl.hook';
import { AccountChooseCompanyBannerCard } from '_02_APP_COMMON/JOU-journey/JOU-11-journey-choose-company/AccountChooseCompanyBannerCard';
import { appLogger } from 'lib-web-logger';
import React, { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';

export const CompanyGroupHomePage = React.memo(function CompanyGroupHomePage() {
  const appId = appWebConfig().appId;
  const { groupReference } = useParams<{ groupReference: string }>();

  const { formatMessage: t } = useIntl();

  const appRouter = useAppRouter();

  const group = useGroup({ groupReference });

  const logo = useAppImageUrl(group?.logo, {
    defaultImage: 'app',
  });

  useEffect(() => {
    if (!group) {
      appLogger.warn(
        `[CompanyGroupHomePage] No group for reference "${groupReference}": redirect to default route`
      );
      appRouter.navigateAfterDelay(appRoutesBuilder.getRedirectToDefaultUrl(), {
        cause: 'company-group-no-group',
      });
    }
  }, [appRouter, group, groupReference]);

  /**
   * Navigate to a selected company
   */
  const onClickNavigation = useCallback(
    (companyReference: string) => {
      const companyUrl = appRoutesBuilder.getCompanyBookletUrl({
        companyReference,
      });
      appRouter.navigate(companyUrl, { cause: 'company-group-page-click' });
    },
    [appRouter]
  );

  return !group?.companies ? null : (
    <AppPage tabId="destinations">
      <HeaderToolbar />
      <PageContainer className="text-center bg-gray-50" spacing="normal">
        <PageGroupTitle
          logo={logo}
          title={t({ id: `page07.home.title.${appId}` })}
          backLink={appRoutesBuilder.getLoginHomeUrl()}
        />
        <BannerList>
          {group?.companies.map((company) => (
            <AccountChooseCompanyBannerCard
              key={company._id}
              company={company}
              onClick={() => onClickNavigation(company.reference)}
            />
          ))}
        </BannerList>
      </PageContainer>
    </AppPage>
  );
});
