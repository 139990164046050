import { IonLabel, IonRow } from '@ionic/react';
import { format } from 'date-fns';
import { CMCompany } from 'lib-common-model';
import React from 'react';
import { useIntl } from 'react-intl';

type OpenDatesProps = {
  company: CMCompany;
};

export const CompanyOpeningDates: React.FC<OpenDatesProps> = ({ company }) => {
  const { formatMessage: t } = useIntl();

  return (
    <div>
      {company?.validMergedPeriods?.length > 0 ? (
        <>
          <IonRow className="ion-justify-content-center text-md">
            {t({ id: 'page10.openFrom' }) + ' '}:
          </IonRow>
          {company?.validMergedPeriods.map((openDate) => (
            <IonRow
              className="ion-justify-content-center text-md"
              key={openDate.beginDate.getTime()}
            >
              - {format(openDate?.beginDate, "dd/MM/yyyy'")}{' '}
              {t({ id: 'page10.openTo' }) + ' '}
              {format(openDate?.endDateInclusive, "dd/MM/yyyy'")}
            </IonRow>
          ))}
        </>
      ) : (
        <IonLabel className="ion-justify-content-center text-md font-bold text-danger">
          {t({ id: 'page10.noOpenSlot' })}
        </IonLabel>
      )}
    </div>
  );
};
