import { IonIcon } from '@ionic/react';
import React, { useState } from 'react';

type AppInputProps = {
  placeholder?: string;
  value?: unknown;
  className?: string;
  required?: boolean;
  icon?: string;
  children: React.ReactElement;
  onClickIcon?: React.MouseEventHandler<HTMLIonIconElement>;
};

export const AppInputIonic: React.FC<AppInputProps> = ({
  placeholder,
  value,
  className,
  required,
  children,
  icon,
  onClickIcon,
}) => {
  const align = value && placeholder ? 'translate-y-1' : 'translate-y-0';
  const placeholderText = required ? placeholder + '*' : placeholder;
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const onFocus = () => setIsFocused(true);
  const onBlur = () => setIsFocused(false);

  return (
    <div
      onFocus={onFocus}
      onBlur={onBlur}
      className={`flex relative ${
        isFocused ? '' : ''
      } bg-white rounded-full border-2 border-input w-full text-left h-12 px-3 ${
        className ?? ''
      }`}
    >
      {value && placeholder ? (
        <div className="absolute top-0.5 left-5 text-xs text-secondary">
          {placeholder}
        </div>
      ) : null}
      <div className={`flex-1 w-full text-lg mt-1 -mb-1`}>
        {React.cloneElement(children, { placeholder: placeholderText, value })}
      </div>
      {icon && (
        <IonIcon
          className={`text-secondary self-center w-8 h-8 ${
            onClickIcon ? 'cursor-pointer' : ''
          }`}
          icon={icon}
          onClick={onClickIcon}
        />
      )}
    </div>
  );
};
