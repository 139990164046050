import { IonGrid, IonIcon, IonInput, IonLabel, IonRow } from '@ionic/react';
import {
  AccountCreationTitle,
  FooterActions,
  PageContainer,
  useAppTheme,
} from '_01_CORE/_common-layout';
import {
  AppButtonCompany,
  AppIcons,
  FormError,
  dateTransformer,
} from '_01_CORE/_components-core';
import { AppInputIonic } from '_01_CORE/_components-core/AppInput';
import { dateUtils } from '_01_CORE/_components-core/CommonUtils';
import { useAppToasts } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import {
  useAppCacheContext,
  useJourneyEnabledActions,
} from '_01_CORE/app-contexts';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import { useAppSettings } from '_01_CORE/app-core/app-security';
import { CompanyOpeningDates } from '_02_APP_COMMON/JOU-journey/_common/CompanyOpeningDates';
import { build, locate } from 'ionicons/icons';
import { CMCompany } from 'lib-common-model';
import { appLogger } from 'lib-web-logger';
import { useCallback, useMemo } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { AppInputDatePickerIonics } from 'stories/components/04-form';
import { JourneyChooseDatesAnimLiveFormData } from './JourneyChooseDatesAnimLiveFormData.type';

export const JourneyChooseDatesAnimLiveForm = ({
  mode,
  defaultFormValue,
  company,
  minBeginDate,
  maxBeginDate,
  minEndDate,
  maxEndDate,
  isEditableCampingPlaceNumber,
  onSubmit,
  onCancel,
  onBack,
  handleChooseDestination,
}: {
  mode: 'create' | 'edit';
  defaultFormValue: JourneyChooseDatesAnimLiveFormData;
  company: CMCompany;
  minBeginDate: Date;
  maxBeginDate: Date;
  minEndDate: Date;
  maxEndDate: Date;
  isEditableCampingPlaceNumber: boolean;
  onSubmit: (data: JourneyChooseDatesAnimLiveFormData) => void;
  onCancel: () => void;
  onBack?: () => void;
  handleChooseDestination?: () => void;
}) => {
  const appId = appWebConfig().appId;

  const { formatMessage: t } = useIntl();

  const companyReference = company?.reference;

  const appCacheContext = useAppCacheContext();
  const { auth } = appCacheContext;
  const { pushError } = useAppToasts();

  const userProfile = useMemo(() => auth?.customerProfile, [
    auth?.customerProfile,
  ]);

  const {
    handleSubmit,
    formState,
    setValue,
    control,
  } = useForm<JourneyChooseDatesAnimLiveFormData>({
    defaultValues: defaultFormValue,
    mode: 'onChange',
  });
  const errors = formState.errors;
  const beginDate = useWatch({
    control,
    name: 'beginDateUTC',
    defaultValue: undefined,
  });
  const endDate = useWatch({
    control,
    name: 'endDateInclusiveUTC',
    defaultValue: undefined,
  });

  const hasJourneyStarted = useMemo(() => {
    return !beginDate || new Date(beginDate).getTime() > new Date().getTime();
  }, [beginDate]);

  const isOutOfOpeningPeriod = useCallback(
    (date: Date) => {
      return (
        date &&
        !dateUtils.isDateWithinOneRange(date, company?.validMergedPeriods)
      );
    },
    [company?.validMergedPeriods]
  );

  const journeyLength = useMemo(() => {
    if (beginDate && endDate) {
      return (
        Math.round(
          new Date(endDate).getTime() - new Date(beginDate).getTime()
        ) /
        1000 /
        3600 /
        24
      );
    }
  }, [beginDate, endDate]);

  const hasSimilarJourney = useMemo(() => {
    if (mode === 'edit') {
      // disable check on edit mode
      return false;
    }

    //  Check if one of the journey exists now or in the future for this company
    if (!beginDate || !endDate) return false;
    const matching = userProfile?.journeys.find((j) => {
      if (companyReference === j?.company?.reference) {
        // check if dates matches
        return dateUtils.haveCommonDays(
          new Date(beginDate),
          new Date(endDate),
          j?.beginDateUTC,
          j?.endDateInclusiveUTC
        );
      }
      // not the same camping
      return false;
    });
    return !!matching;
  }, [beginDate, companyReference, endDate, mode, userProfile?.journeys]);

  /**
   * Submit button callback
   */
  const checkSubmit = (data: JourneyChooseDatesAnimLiveFormData) => {
    if (
      dateUtils.isAfterIgnoreTime(data.beginDateUTC, data.endDateInclusiveUTC)
    ) {
      appLogger.warn('Invalid dates: beginDate > endDateInclusive');
    } else if (hasSimilarJourney) {
      appLogger.warn('Similar journey exists');
    } else {
      //Save in redux cache
      onSubmit(data);
    }
  };

  function autoFillForm() {
    if (company.validMergedPeriods.length) {
      const period1 = company.validMergedPeriods[0];
      setValue('beginDateUTC', period1.beginDate);
      setValue('endDateInclusiveUTC', period1.endDateInclusive);
    } else {
      setValue('beginDateUTC', new Date());
      setValue('endDateInclusiveUTC', dateTransformer.addDays(new Date(), 14));
    }
  }

  const onInvalid = () => {
    pushError(t({ id: 'common.message.error.invalid-form' }));
  };

  const appSettings = useAppSettings();
  const whiteLabel = appSettings?.universe?.whiteLabel;
  const identity = appSettings?.universe?.identity;

  const theme = useAppTheme({ companyReference });

  const {
    isCreateJourneyEnabled,
    isCreateJourneyByActivationKeyEnabled,
  } = useJourneyEnabledActions({});

  return (
    <>
      <PageContainer className="text-center" spacing="none">
        {mode === 'create' && handleChooseDestination && (
          <AccountCreationTitle
            company={company}
            displayAddress={true}
            displayButtonChooseDestination={
              !whiteLabel.appCompanyReference &&
              (appId === 'anim-live' ? auth?.isAuthenticated : true)
            }
            buttonText={t({ id: `page10.choose.${appId}` })}
            onClick={handleChooseDestination}
          />
        )}
        {mode === 'create' &&
          userProfile?.account?._id &&
          isCreateJourneyByActivationKeyEnabled && (
            <div className={`my-4 text-right`}>
              <AppButtonCompany
                style={'primary'}
                theme={theme}
                isFullWidth={true}
                label={t({ id: 'page04.button.activation-key.anim-live' })}
                icon={AppIcons.code}
                href={
                  auth?.isAuthenticated
                    ? appRoutesBuilder.getJourneyCreateByActivationKeyUrl()
                    : appRoutesBuilder.getLoginMultiUrl({
                        companyReference,
                        action: 'activate',
                      })
                }
              />
            </div>
          )}
        {appWebConfig().envId === 'dev' && (
          <div className="absolute right-8 text-2xl">
            <IonIcon
              className="mr-2"
              color="primary"
              icon={build}
              onClick={() => autoFillForm()}
            />
          </div>
        )}
        <CompanyOpeningDates company={company} />
        <IonGrid className="max-w-xl">
          <IonRow className="align-top">
            <div className="flex mt-8 mb-2">
              <div className="text-primary text-left flex-grow text-xl mm:text-2xl ml:text-3xl sm:text-4xl">
                {t({ id: 'page10.journey' })}
              </div>
            </div>
          </IonRow>
        </IonGrid>

        <form
          onSubmit={(e) => {
            // quand on clique sur les flèches de navigation des mois des calendrier, le "onSubmit est appelé!", donc en attendant de revoir ça, on stop la propagation ici
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {/*Dates Start*/}

          <div className="my-2 max-w-xs m-auto">
            <Controller
              control={control}
              name="beginDateUTC"
              defaultValue={'' as any}
              rules={{
                required: {
                  value: true,
                  message: t({ id: 'validation.required' }),
                },
              }}
              render={({ field: { value, onChange } }) => (
                <AppInputDatePickerIonics
                  value={value}
                  minDate={minBeginDate}
                  maxDate={maxBeginDate}
                  onChange={(value) => {
                    if (!endDate) {
                      setValue(
                        'endDateInclusiveUTC',
                        dateTransformer.addDays(value, 14)
                      );
                    }
                    onChange(value);
                  }}
                  placeholder={t({ id: 'page10.journey.begin' })}
                />
              )}
            />
            <FormError error={errors?.beginDateUTC?.message} />
          </div>

          {/* WARN: date de début de séjour en dehors des périodes d’ouverture */}
          <div
            className="text-sm text-danger font-bold"
            hidden={!isOutOfOpeningPeriod(beginDate)}
          >
            {t({ id: 'page10.journey.alertBegin' })}
          </div>

          <div className="my-2 max-w-xs m-auto">
            <Controller
              render={({ field: { value, onChange } }) => (
                <AppInputDatePickerIonics
                  value={value}
                  minDate={minEndDate}
                  maxDate={maxEndDate}
                  onChange={onChange}
                  placeholder={t({ id: 'page10.journey.end' })}
                />
              )}
              control={control}
              name="endDateInclusiveUTC"
              defaultValue={'' as any}
              rules={{
                required: {
                  value: true,
                  message: t({ id: 'validation.required' }),
                },
              }}
            />
            <FormError error={errors?.endDateInclusiveUTC?.message} />
          </div>

          <div className={'mt-4'} hidden={!hasSimilarJourney}>
            <IonLabel className="ion-justify-content-center text-md font-bold text-danger">
              {t({ id: 'page10.journeyExist' })}
            </IonLabel>
          </div>

          {/* WARN: date de fin de séjour en dehors des périodes d’ouverture */}
          <div
            color={'danger'}
            className="mt-4 text-sm text-danger font-bold"
            hidden={!isOutOfOpeningPeriod(endDate)}
          >
            {t({ id: 'page10.journey.alertEnd' })}
          </div>

          {/*Length of stay*/}
          <div
            className={
              'mt-4 ' +
              (journeyLength > 42 || journeyLength < 0
                ? 'text-danger font-bold'
                : '')
            }
            hidden={!journeyLength}
          >
            {t({ id: 'page10.journey.length' }, { length: journeyLength })}
          </div>

          {/*Location*/}
          {isEditableCampingPlaceNumber && (
            <div className="max-w-xs m-auto mt-8" hidden={hasJourneyStarted}>
              <Controller
                render={({ field: { value, onChange } }) => (
                  <AppInputIonic
                    placeholder={t({ id: 'page10.journey.location' })}
                    value={value}
                    icon={locate}
                  >
                    <IonInput onIonChange={onChange} />
                  </AppInputIonic>
                )}
                control={control}
                name="campingPlaceNumber"
                defaultValue=""
              />
            </div>
          )}
        </form>
      </PageContainer>
      <FooterActions
        onCancel={onCancel}
        onBack={onBack}
        onSubmit={handleSubmit(checkSubmit, onInvalid)}
      />
    </>
  );
};
