import { IonIcon } from '@ionic/react';
import {
  AppIcons,
  AppLoaderSpinner,
  ParticipantAvatar,
} from '_01_CORE/_components-core';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import { checkmark } from 'ionicons/icons';
import {
  APP_CURRENCIES_MAP,
  CMJourneyActivitySession,
  CustomerActivityUpdateParticipantStateAction,
} from 'lib-common-model';
import { useMemo } from 'react';
import { ParticipantActivityPackageLabel } from './components';
import { CustomerActivityParticipantsDailyActivitParticipantState } from './model/CustomerActivityParticipantsDailyActivitParticipantState.type';

export const JourneyDailyActivityParticipant = ({
  companyReference,
  activitySession,
  participantState,
  onClick,
}: {
  companyReference: string;
  activitySession: CMJourneyActivitySession;
  participantState: CustomerActivityParticipantsDailyActivitParticipantState;
  onClick?: ({
    action,
    participantState,
  }: {
    action: CustomerActivityUpdateParticipantStateAction;
    participantState: CustomerActivityParticipantsDailyActivitParticipantState;
  }) => void;
}) => {
  const appId = appWebConfig().appId;

  const unitType = activitySession?.registration?.unitType;
  const unitLabel = activitySession?.registration?.unitLabel;

  const isRegistered = !participantState.isNotRegistered;

  const p = participantState.activitySessionParticipant;

  // const active = participantState.state === 'active' || participantState.state === 'active-customer' || participantState.state === 'active-anim';

  const isAbsent =
    participantState.state === 'absent-anim' ||
    participantState.state === 'absent-customer';

  const firstName = useMemo(() => {
    const firstName = participantState.journeyParticipant.member.firstName?.trim();
    if (firstName.length > 0) {
      return uppercaseFirstLetters(firstName);
    }
    return firstName;
  }, [participantState.journeyParticipant.member.firstName]);

  const isPaymentRequired =
    appId === 'anim-live' &&
    activitySession.registrationFeeEnabled &&
    p.paymentStatus !== 'payment-accepted' &&
    (p.preRegistrationStatus === 'yes' ||
      p.attendanceStatus === 'present' ||
      p.attendanceStatus === 'left');

  const isWaitingListAcceptedPending =
    p.waitingRegistration?.status === 'accepted-pending';

  const displayActivityPackage = useMemo(() => {
    if (p.paymentStatus === 'payment-accepted' && !!p.packId) {
      return true;
    }
    if (p.preRegistrationStatus === 'no' && !!p.packId) {
      return true;
    }
    return false;
  }, [p.packId, p.paymentStatus, p.preRegistrationStatus]);

  // const [operationPending, setOperationPending] = useState(false);

  const operationPending = participantState?.pendingOperations?.length > 0;

  const action: CustomerActivityUpdateParticipantStateAction = useMemo(() => {
    // ajouté le 06/07/2023 (basé sur useActivityDialogParticipantsGroupsToDisplay et useGroupParticipantsByStatus)

    if (p?.waitingRegistration?.status === 'waiting') {
      return 'registration-waiting-list-cancel'; // quitter la liste d'attente
    } else if (p.waitingRegistration?.status === 'accepted-pending') {
      return 'registration-waiting-list-accept'; // accepter la place disponible depuis la liste d'attente
    } else if (isRegistered) {
      return 'registration-cancel'; // se désinscrire
    } else {
      // s'inscrire (si c'est full, on rejoint la liste d'attente)
      return 'registration-confirm';
    }
  }, [isRegistered, p.waitingRegistration?.status]);

  return (
    <>
      <div
        className={`w-20 flex flex-col font-bold ${
          onClick ? 'cursor-pointer' : ''
        }`}
        onClick={async (e) => {
          if (onClick) {
            e.stopPropagation();
            if (operationPending) {
              return;
            }
            try {
              await onClick({
                action,
                participantState,
              });
            } catch (err) {
              console.log('err', err);
            }
          }
        }}
      >
        <div className={`relative self-center`}>
          {operationPending && (
            <AppLoaderSpinner className="absolute -top-2 -right-2 w-6 h-6 bg-gray-300" />
          )}
          {!operationPending && isRegistered && (
            <div
              className={`absolute -top-2 -right-2 w-6 h-6 text-white ${
                isWaitingListAcceptedPending
                  ? 'bg-app-warning'
                  : isPaymentRequired
                  ? 'bg-red-500'
                  : 'bg-registration-customer'
              } rounded-full`}
            >
              <IonIcon
                className="w-6 h-4 py-1"
                icon={checkmark}
                style={{ '--ionicon-stroke-width': 100 }}
              />
            </div>
          )}
          {p.waitingRegistration?.status === 'waiting' && (
            <AppIcons.registrationWaiting className="absolute -top-2 -right-2 bg-app-warning text-white w-6 h-6 p-0.5 rounded-full" />
          )}
          {isAbsent && (
            <span className="absolute top-6 -left-3 transform -rotate-12 text-sm px-1 bg-gray-900 text-white">
              ABSENT
            </span>
          )}
          <ParticipantAvatar
            className={'mt-0.5'}
            participant={participantState.journeyParticipant}
            withBorder={true}
            selected={isRegistered}
            disabled={participantState.state === 'closed'}
            waitingList={p.waitingRegistration?.status === 'waiting'}
          />
        </div>
        <div
          className={`truncate text-center text-gray-600 ${
            firstName.length > 7 ? 'text-xs leading-5' : 'text-sm'
          }`}
        >
          {unitType === 'participant' && firstName}
        </div>
        {!participantState.priceRange ? null : (
          <>
            <>
              <div
                className={`relative rounded-md text-center px-2 py-0.5 sm:py-1 ${
                  isRegistered
                    ? `${
                        isPaymentRequired
                          ? 'bg-red-500 text-white'
                          : 'bg-registration-customer text-white'
                      } text-white`
                    : participantState.activitySessionParticipant
                        ?.waitingRegistration?.status === 'waiting'
                    ? 'border border-app-warning text-app-warning'
                    : 'border border-gray-400 text-gray-400'
                }`}
              >
                {/* {activitySession.registrationFeeEnabled &&
              (p.preRegistrationStatus === 'yes' ||
                p.attendanceStatus === 'present' ||
                p.attendanceStatus === 'left') && (
                <AppIcons.payment
                  className={`absolute text-white rounded-full  ${
                    p.paymentStatus !== 'payment-accepted'
                      ? 'bg-registration-anim'
                      : 'bg-red-600'
                  } text-white w-6 h-6 -bottom-2 -left-2`}
                />
                // <CurrencyDollarIcon className={`absolute text-white rounded-full bg-red-600 text-white w-4 h-4 -bottom-1 -right-1`} />
              )} */}
                {participantState.priceRange.amount}{' '}
                {
                  APP_CURRENCIES_MAP[participantState.priceRange.currency]
                    ?.symbol
                }
              </div>
              {displayActivityPackage && (
                <ParticipantActivityPackageLabel
                  className="mt-1"
                  participantResume={p}
                />
              )}
            </>
          </>
        )}
      </div>
    </>
  );
};
function uppercaseFirstLetters(firstName: string): string {
  return uppercaseFirstLettersBySeparator(
    uppercaseFirstLettersBySeparator(firstName, '-'),
    ' '
  );
}

function uppercaseFirstLettersBySeparator(
  firstName: string,
  separator: string
): string {
  return firstName
    .split(separator)
    .map((word) => uppercaseFirstLetter(word))
    .join(separator);
}

function uppercaseFirstLetter(firstName: string): string {
  return `${firstName.substring(0, 1).toUpperCase()}${firstName.substring(1)}`;
}
