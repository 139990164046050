import { AppPage, HeaderToolbar, PageContainer } from '_01_CORE/_common-layout';
import { useAppRouter } from '_01_CORE/_hooks';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { AnimLiveJourneyShoppingCartsListPanel } from './AnimLiveJourneyShoppingCartsListPanel';
import {
  AnimLiveJourneyShoppingCartPageStateState,
  useAnimLiveJourneyShoppingCartPageState,
} from './useAnimLiveJourneyShoppingCartPageState.hook';

export const AnimLiveJourneyShoppingCartHistoryPage = () => {
  const appRouter = useAppRouter();
  const { formatMessage: t } = useIntl();
  const appId = appWebConfig().appId;

  const { customerJourneyId } = useParams<{ customerJourneyId: string }>();

  const localState: AnimLiveJourneyShoppingCartPageStateState = useAnimLiveJourneyShoppingCartPageState(
    {
      context: 'history',
      customerJourneyId,
    }
  );
  return (
    <AppPage displayFooter={true}>
      <HeaderToolbar hideLogout={false} />
      <PageContainer
        className="h-full bg-gray-50 flex flex-col items-stretch text-center"
        spacing="normal"
      >
        <AnimLiveJourneyShoppingCartsListPanel localState={localState} />
      </PageContainer>
    </AppPage>
  );
};
