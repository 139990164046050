import { Listbox, Transition } from '@headlessui/react';
import { CMCompany } from 'lib-common-model';
import { Fragment, useMemo } from 'react';
import { useAppImageUrl } from '_01_CORE/app-core/app-security/useAppImageUrl.hook';
import { AppIcons } from '_01_CORE/_components-core';
import { AppCompanySelectOption } from './AppCompanySelectOption';

export const AppCompanySelect = <
  COMPANY extends Pick<CMCompany, '_id' | 'name' | 'direction' | 'logo'>
>({
  selectedId,
  companies,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = () => {},
}: {
  selectedId?: string;
  companies: COMPANY[];
  onChange?: (company?: COMPANY) => void;
}) => {
  const selected = useMemo(() => companies.find((c) => c._id === selectedId), [
    companies,
    selectedId,
  ]);

  const imageSrc = useAppImageUrl(selected?.logo, {
    defaultImage: 'app',
  });

  return (
    <Listbox value={selected} onChange={onChange}>
      {({ open }) => (
        <>
          <div className="relative mt-1">
            <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
              {!!selected && (
                <div className="flex flex-1 place-items-center justify-left pt-1 pb-2">
                  <img
                    className="max-w-6xs h-auto object-contain mr-3"
                    src={imageSrc}
                  />
                  <div>
                    <div className="block truncate">{selected?.name}</div>
                    <div className="text-gray-500 text-app-xxs1">
                      {selected?.direction?.line1}
                    </div>
                  </div>
                </div>
              )}
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <AppIcons.select
                  className="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                static
                className="z-50 absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              >
                {companies.map((company, companyIdx) => (
                  <AppCompanySelectOption key={companyIdx} company={company} />
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};
