// @index('./*', f => `export * from '${f.path}'`)
export * from './_external-integration';
export * from './activity';
export * from './app-operation';
export * from './app-settings';
export * from './auth';
export * from './booklet';
export * from './company';
export * from './company-animator';
export * from './company-issue';
export * from './company-opening-period';
export * from './customer';
export * from './customer-journey';
export * from './ecommerce';
export * from './message';
export * from './payment';
export * from './resto';
