import clsx from 'clsx';
import { CMRestoMenuSection } from 'lib-common-model/entities/app-customer-mobile-web/CMResto';

export const CompanyRestoMenuSectionCardHeader = ({
  section,
  className,
}: {
  section: CMRestoMenuSection;
  className?: string;
}) => {
  return (
    <div
      className={clsx(
        'bg-gray-50 relative flex flex-col justify-center overflow-hidden rounded-lg border border-gray-200',
        className
      )}
    >
      {section.image?.original && (
        <div className="aspect-h-1 aspect-w-1 sm:aspect-none h-20 ml:h-28 sm:h-40">
          <img
            src={section.image?.original}
            // alt={product.imageAlt}
            className="opacity-50 h-full w-full object-cover object-center sm:h-full sm:w-full"
          />
        </div>
      )}
      <div className="bg-gray-500 flex flex-1 flex-col space-y-2 p-2">
        <h3 className="text-2xl font-bold uppercase text-gray-50">
          <span aria-hidden="true" className="absolute inset-0" />
          {section.translation.name}
        </h3>
        {section.translation.comment?.trim()?.length > 0 && (
          <p className="text-left text-sm text-gray-100">
            {section.translation.comment}
          </p>
        )}
      </div>
    </div>
  );
};
