import { AppEnvId, AppId } from 'lib-common-model';
import { AppWebConfigURLs } from './model';
import { AppRemoteEnvId } from './model/AppRemoteEnvId.type';

export const configUrlBuilder = {
  buildUrls,
  getEnvIdFromUrl,
};

function buildUrls({
  values,
  remoteEnvId,
}: {
  values: Partial<AppWebConfigURLs>;
  remoteEnvId: AppRemoteEnvId;
}): AppWebConfigURLs {
  const cdn = values.cdn?.trim()
    ? values.cdn?.trim()
    : 'https://anim-live-public.ams3.cdn.digitaloceanspaces.com';
  const urls: AppWebConfigURLs = {
    customerWebMobile: values.customerWebMobile?.trim()
      ? values.customerWebMobile?.trim()
      : getCustomersWebMobileUrl({ remoteEnvId }),
    superAdminWeb: values.superAdminWeb?.trim()
      ? values.superAdminWeb?.trim()
      : getSuperAdminWebUrl({ remoteEnvId }),
    proWeb: values.proWeb?.trim()
      ? values.proWeb?.trim()
      : getProWebUrl({ remoteEnvId }),
    api: values.api?.trim() ? values.api?.trim() : getApiUrl({ remoteEnvId }),
    cdn,
    cdnUpload: `${cdn}/upload`,
    animLiveWeb: values.animLiveWeb?.trim()
      ? values.animLiveWeb?.trim()
      : 'https://www.anim-live.com',
  };
  return urls;
}

function getProWebUrl({ remoteEnvId }: { remoteEnvId: AppRemoteEnvId }) {
  switch (remoteEnvId) {
    case 'local':
      return 'http://localhost:4500';
    case 'local-ip':
      return 'http://192.168.1.101:4500';
    case 'prod':
      return 'https://pro.anim-live.fr';
    case 'accro-prod':
      return 'https://pro.accropark.org';
    case 'accro-beta':
      return 'https://pro.beta.accropark.org';
    case 'mb-natureo':
      return 'https://pro.natureo.anim-live.fr';
    case 'mb-bergerie':
      return 'https://pro.domainelabergerie.anim-live.fr';
    case 'mb-sireneholidays':
      return 'https://pro.sireneholidays.anim-live.fr';
    default:
      // alpha, beta
      return `https://pro.${remoteEnvId}.anim-live.fr`;
  }
}
function getSuperAdminWebUrl({ remoteEnvId }: { remoteEnvId: AppRemoteEnvId }) {
  switch (remoteEnvId) {
    case 'local':
      return 'http://localhost:4300';
    case 'local-ip':
      return 'http://192.168.1.101:4300';
    case 'prod':
      return 'https://anim-live.fr';
    case 'accro-prod':
      return 'https://accropark.org';
    case 'accro-beta':
      return 'https://beta.accropark.org';
    case 'mb-natureo':
      return 'https://natureo.anim-live.fr';
    case 'mb-bergerie':
      return 'https://domainelabergerie.anim-live.fr';
    case 'mb-sireneholidays':
      return 'https://sireneholidays.anim-live.fr';
    default:
      // alpha, beta
      return `https://${remoteEnvId}.anim-live.fr`;
  }
}
function getCustomersWebMobileUrl({
  remoteEnvId,
}: {
  remoteEnvId: AppRemoteEnvId;
}) {
  switch (remoteEnvId) {
    case 'local':
      return 'http://localhost:5500';
    case 'local-ip':
      return 'http://192.168.1.101:5500';
    case 'prod':
      return 'https://anim-live.fr';
    case 'accro-prod':
      return 'https://accropark.org';
    case 'accro-beta':
      return 'https://beta.accropark.org';
    case 'mb-natureo':
      return 'https://natureo.anim-live.fr';
    case 'mb-bergerie':
      return 'https://domainelabergerie.anim-live.fr';
    case 'mb-sireneholidays':
      return 'https://sireneholidays.anim-live.fr';
    default:
      // alpha, beta
      return `https://${remoteEnvId}.anim-live.fr`;
  }
}

function getApiUrl({ remoteEnvId }: { remoteEnvId: AppRemoteEnvId }) {
  switch (remoteEnvId) {
    case 'local':
      return 'http://localhost:7000/api';
    case 'local-ip':
      return 'http://192.168.1.101:7000/api';
    case 'prod':
      return 'https://api.anim-live.fr/api';
    case 'accro-prod':
      return 'https://api.accropark.org/api';
    case 'accro-beta':
      return 'https://api.beta.accropark.org/api';
    case 'mb-natureo':
      return 'https://api.natureo.anim-live.fr/api';
    case 'mb-bergerie':
      return 'https://api.domainelabergerie.anim-live.fr/api';
    case 'mb-sireneholidays':
      return 'https://api.sireneholidays.anim-live.fr/api';
    default:
      // alpha, beta
      return `https://api.${remoteEnvId}.anim-live.fr/api`;
  }
}

function getEnvIdFromUrl(): {
  envId: AppEnvId;
  appId: AppId;
  remoteEnvId: AppRemoteEnvId;
} {
  switch (window?.location?.hostname) {
    case 'alpha.anim-live.fr':
    case 'admin.alpha.anim-live.fr':
    case 'pro.alpha.anim-live.fr':
      return {
        envId: 'alpha',
        appId: 'anim-live',
        remoteEnvId: 'alpha',
      };
    case 'alpha.accropark.org':
    case 'admin.alpha.accropark.org':
    case 'pro.alpha.accropark.org':
      return {
        envId: 'alpha',
        appId: 'accropark',
        remoteEnvId: 'accro-alpha',
      };
    case 'beta.anim-live.fr':
    case 'admin.beta.anim-live.fr':
    case 'pro.beta.anim-live.fr':
      return {
        envId: 'beta',
        appId: 'anim-live',
        remoteEnvId: 'beta',
      };
    case 'beta.accropark.org':
    case 'admin.beta.accropark.org':
    case 'pro.beta.accropark.org':
      return {
        envId: 'beta',
        appId: 'accropark',
        remoteEnvId: 'accro-beta',
      };
    case 'anim-live.fr':
    case 'admin.anim-live.fr':
    case 'pro.anim-live.fr':
      return {
        envId: 'prod',
        appId: 'anim-live',
        remoteEnvId: 'prod',
      };
    case 'accropark.org':
    case 'admin.accropark.org':
    case 'pro.accropark.org':
      return {
        envId: 'prod',
        appId: 'accropark',
        remoteEnvId: 'accro-prod',
      };
    case 'natureo.anim-live.fr':
    case 'admin.natureo.anim-live.fr':
    case 'pro.natureo.anim-live.fr':
      return {
        envId: 'mb-natureo',
        appId: 'anim-live',
        remoteEnvId: 'mb-natureo',
      };
    case 'domainelabergerie.anim-live.fr':
    case 'admin.domainelabergerie.anim-live.fr':
    case 'pro.domainelabergerie.anim-live.fr':
      return {
        envId: 'mb-bergerie',
        appId: 'anim-live',
        remoteEnvId: 'mb-bergerie',
      };
    case 'sireneholidays.anim-live.fr':
    case 'admin.sireneholidays.anim-live.fr':
    case 'pro.sireneholidays.anim-live.fr':
      return {
        envId: 'mb-sireneholidays',
        appId: 'anim-live',
        remoteEnvId: 'mb-sireneholidays',
      };
    default:
      return {
        envId: 'prod',
        appId: 'anim-live',
        remoteEnvId: 'prod',
      };
  }
}
