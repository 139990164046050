import { CMJourneyEditCustomerAccount } from 'lib-common-model';
import { useIntl } from 'react-intl';
import { useAppCacheContext } from '_01_CORE/app-contexts';
import { customerAccountApiClient } from '_01_CORE/app-core/app-api';
import { useNetworkStatus } from '_01_CORE/app-core/app-platform';
import {
  useAppRouter,
  useAppToasts,
  useOperationPending,
} from '_01_CORE/_hooks';
import { journeyHelper } from '_01_CORE/_services';
import { CustomerAccountEditFormData } from '_02_APP_COMMON/_model';

export function useOnSubmitCustomerAccountAccroparkEditForm({
  onSuccess,
}: {
  onSuccess: () => void;
}) {
  const appRouter = useAppRouter();
  const { formatMessage: t } = useIntl();
  const { auth } = useAppCacheContext();
  const { pushError } = useAppToasts();
  const { networkStatus } = useNetworkStatus();

  return useOperationPending(
    async (data: CustomerAccountEditFormData) => {
      const customerAccount: CMJourneyEditCustomerAccount = journeyHelper.buildCustomerAccount(
        {
          existingCustomerAccount: auth?.customerProfile?.account,
          data,
        }
      );
      customerAccountApiClient
        .updateCustomerAccount(customerAccount)
        .then(({ result }) => {
          switch (result) {
            case 'email-already-exists':
              pushError(t({ id: 'error.account.exist' }));
              break;
            case 'unexpected-error':
              if (!networkStatus?.connected) {
                pushError(t({ id: 'error.network.down' }));
              } else {
                pushError(t({ id: 'error.unexpected' }));
              }
              break;
            case 'success':
              onSuccess();
              break;
          }
        });
    },
    [
      auth?.customerProfile?.account,
      networkStatus?.connected,
      onSuccess,
      pushError,
      t,
    ]
  );
}
