import {
  CMShoppingCartResumeCartItem,
  EcommerceShoppingCart,
} from 'lib-common-model';
import { useMemo } from 'react';
import { CMShoppingCartResumeCartItemByPaymentModeMap } from './AnimLiveShoppingCartTable';

export function useShoppingCartItemsByPaymentMode(
  shoppingCartItems: CMShoppingCartResumeCartItem[],
  shoppingCart: EcommerceShoppingCart
): CMShoppingCartResumeCartItemByPaymentModeMap {
  return useMemo(() => {
    return shoppingCartItems.reduce(
      (acc, item) => {
        const modes = item.availablePaymentModes;
        if (shoppingCart.paymentMode === 'online') {
          acc.online.push(item);
        } else if (shoppingCart.paymentMode === 'on-site') {
          acc.onSite.push(item);
        } else if (modes.length === 2) {
          acc.toChoose.push(item);
        } else if (modes.includes('online')) {
          acc.online.push(item);
        } else {
          acc.onSite.push(item);
        }

        return acc;
      },
      {
        online: [],
        onSite: [],
        toChoose: [],
      } as CMShoppingCartResumeCartItemByPaymentModeMap
    );
  }, [shoppingCart.paymentMode, shoppingCartItems]);
}
