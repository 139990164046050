import { useAppRouter, useAppToasts } from '_01_CORE/_hooks';
import { useNetworkStatus } from '_01_CORE/app-core/app-platform';
import clsx from 'clsx';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { JourneyDailyActivityCardParticipantsList } from '../JourneyDailyActivityCardParticipantsList';
import {
  CustomerActivityParticipantsDailyActivitParticipantState,
  CustomerActivityParticipantsDailyActivity,
  CustomerActivityUpdateParticipantStateFn,
} from '../model';

export const ActivitySessionDetailsDialogRegistrationFreeArea = ({
  isOpen,
  dailyActivity,
  participants,
  companyReference,
  onClickParticipant,
}: {
  isOpen: boolean;
  dailyActivity: CustomerActivityParticipantsDailyActivity;
  participants: CustomerActivityParticipantsDailyActivitParticipantState[];
  companyReference: string;
  onClickParticipant: CustomerActivityUpdateParticipantStateFn;
}) => {
  const appRouter = useAppRouter();
  const { formatMessage: t } = useIntl();
  const { networkStatus } = useNetworkStatus();
  const { pushError } = useAppToasts();

  const session = dailyActivity?.selectedSession;

  const hasUnregisteredParticipant = useMemo(
    () => participants.find((x) => x.isNotRegistered) !== undefined,
    [participants]
  );
  const hasRegisteredParticipant = useMemo(
    () => participants.find((x) => !x.isNotRegistered) !== undefined,
    [participants]
  );

  const registeredParticipants = useMemo(
    () => participants.filter((x) => !x.isNotRegistered),
    [participants]
  );

  const unitType = session.activitySession?.registration?.unitType;
  const unitLabel = session.activitySession?.registration?.unitLabel;

  return (
    <div
      className={`my-2 mx-2 sm:mx-6 p-2 border-2 ${
        session.registrationStatus === 'open' || hasRegisteredParticipant
          ? 'border-green-600'
          : 'border-red-600'
      }`}
    >
      <div
        className={
          session.registrationStatus === 'open' || hasRegisteredParticipant
            ? `text-green-600`
            : 'text-red-600'
        }
      >
        <p className={clsx('mb-3 font-bold text-justify')}>
          {!isOpen
            ? t({
                id: 'page16.legend.text.inscriptions-fermees',
              })
            : hasUnregisteredParticipant || unitType === 'product'
            ? t(
                {
                  id: `page16.legend.text.selectionner-participants-unit-type-${unitType}`,
                },
                { unitLabel: unitLabel?.toUpperCase() }
              )
            : t({ id: 'page16.legend.text.participants-inscrits' })}
        </p>
        <JourneyDailyActivityCardParticipantsList
          className="mt-1"
          activitySession={session?.activitySession}
          companyReference={companyReference}
          participants={participants}
          onClickParticipant={({
            participantState,
            activitySession,
            action,
          }) => {
            return onClickParticipant({
              dailyActivity,
              participantState,
              action,
            });
          }}
        />
        {unitType === 'product' && registeredParticipants.length > 0 && (
          <div className="mt-4 mb-2 font-bold text-gray-800">
            {t(
              {
                id: `page16.legend.text.selectionner-participants-unit-type-${unitType}-resume`,
              },
              {
                unitLabel: unitLabel?.toUpperCase(),
                unitCount: registeredParticipants.length,
              }
            )}
          </div>
        )}
      </div>
    </div>
  );
};
