import { useAppTheme } from '_01_CORE/_common-layout';
import { AppButtonV2, AppIcons, IssuesIcons } from '_01_CORE/_components-core';
import { AppButtonCompany } from '_01_CORE/_components-core/AppButton/AppButtonCompany';
import { useAppRouter } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import {
  useAppCacheContext,
  useJourneyActivityCategory,
  useJourneyEnabledActions,
} from '_01_CORE/app-contexts';
import { useAppSettings } from '_01_CORE/app-core/app-security';
import {
  journeyCheckInTester,
  useNavigateToNewJourneyPage,
} from '_02_APP_COMMON/JOU-journey/_common';
import clsx from 'clsx';
import { compareDesc } from 'date-fns';
import { AppSettings, CMJourney } from 'lib-common-model';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
export const AnimLiveJourneyHomeButtons = ({
  journey,
  companyReference,
  showHomeButton,
  className,
}: {
  journey?: CMJourney; // pas forcément renseigné
  companyReference: string;
  showHomeButton?: boolean;
  className?: string;
}) => {
  const appRouter = useAppRouter();
  const { formatMessage: t } = useIntl();

  const isCurrentOfFutureJourney = useMemo(
    () => journey && compareDesc(new Date(), journey?.endDateExclusiveUTC) >= 0,
    [journey]
  );
  const { auth, dispatchCacheContextAction } = useAppCacheContext();

  const appSettings: AppSettings = useAppSettings();
  const customersSettings = appSettings?.features?.customers;

  const enableActivityStandalone = useJourneyActivityCategory(
    journey,
    'standalone'
  );
  const {
    isCreateJourneyEnabled,
    isCreateJourneyByActivationKeyEnabled,
  } = useJourneyEnabledActions({});

  const isCheckInEnabled = useMemo(() => {
    return journeyCheckInTester.isCheckinEnabled(journey);
  }, [journey]);
  const isIssueReportEnabled = useMemo(() => {
    return journeyCheckInTester.isIssueReportEnabled(journey);
  }, [journey]);
  const theme = useAppTheme({ companyReference: journey?.company?.reference });

  const createNewJourneyWhenAuthenticated = useNavigateToNewJourneyPage({
    context: 'home-buttons',
  });

  const addJourney = useCallback(() => {
    const hasAnyPmsJourneyToActivate =
      auth?.customerProfile?.extJourneysToActivate?.length > 0;

    const hasAnyCurrentOfFutureJourneyInThisCompany =
      auth?.customerProfile?.journeys?.find(
        (x) =>
          x.endDateExclusiveUTC > new Date() &&
          x.company?.reference === companyReference
      ) !== undefined;

    if (
      hasAnyPmsJourneyToActivate ||
      hasAnyCurrentOfFutureJourneyInThisCompany
    ) {
      // redirect to journeys list
      appRouter.navigate(appRoutesBuilder.getJourneysUrl(), {
        cause: 'company-presentation-register',
      });
    } else {
      // immediately create journey

      if (auth?.customerProfile) {
        createNewJourneyWhenAuthenticated();
      } else {
        dispatchCacheContextAction({
          type: 'clear-update-journey-cache',
          context: 'create-journey',
        });

        appRouter.navigate(
          appRoutesBuilder.getLoginHomeUrl({
            companyReference,
          }),
          { cause: 'company-presentation-register' }
        );
      }
    }
  }, [
    appRouter,
    auth?.customerProfile,
    companyReference,
    createNewJourneyWhenAuthenticated,
    dispatchCacheContextAction,
  ]);
  return (
    <div className={clsx('', className)}>
      {isCheckInEnabled && (
        <p className="my-2 text-sm text-justify font-bold text-app-danger">
          {`Vous êtes arrivés?`}
          <br />
          {`Commencez par renseigner l'état des lieux!`}
        </p>
      )}
      <div className="mt-2 mm:mt-4 sm:mt-6 max-w-md mx-auto flex flex-col gap-4">
        {isCheckInEnabled && (
          <AppButtonV2
            className={`max-w-md mx-auto w-full rounded-2xl uppercase font-bold`}
            buttonStyle="danger"
            icon={AppIcons.check}
            onClick={() => {
              appRouter.navigate(
                appRoutesBuilder.getJourneyCheckinUrl({
                  customerJourneyId: journey._id,
                }),
                {
                  cause: 'start-check-in',
                }
              );
            }}
          >
            ÉTAT DES LIEUX
          </AppButtonV2>
        )}
        {isCurrentOfFutureJourney ? (
          <div className="flex flex-col ml:flex-row justify-between gap-2">
            <AppButtonCompany
              style={'primary'}
              theme={theme}
              isFullWidth={true}
              className={`uppercase font-bold`}
              label={t({ id: 'page04.button.animations' })}
              icon={AppIcons.activityPackage}
              onClick={() =>
                appRouter.navigate(
                  appRoutesBuilder.getJourneyActivitiesUrl({
                    customerJourneyId: journey._id,
                    category: 'animator',
                  }),
                  { cause: 'journey-home-animations' }
                )
              }
            />
            {enableActivityStandalone && (
              <AppButtonCompany
                style={'primary'}
                theme={theme}
                isFullWidth={true}
                className={`uppercase font-bold`}
                label={t({ id: 'page04.button.activities-leisure' })}
                icon={AppIcons.activityPackageLeisure}
                onClick={() =>
                  appRouter.navigate(
                    appRoutesBuilder.getJourneyActivitiesUrl({
                      customerJourneyId: journey._id,
                      category: 'standalone',
                    }),
                    { cause: 'journey-home-activities-leisure' }
                  )
                }
              />
            )}
          </div>
        ) : (
          // pas de séjour en cours ou futur
          <>
            {isCreateJourneyByActivationKeyEnabled && (
              <>
                <AppButtonCompany
                  style={'primary'}
                  theme={theme}
                  isFullWidth={true}
                  className={`uppercase font-bold`}
                  label={t({ id: 'page04.button.activation-key.anim-live' })}
                  icon={AppIcons.code}
                  href={
                    auth?.isAuthenticated
                      ? appRoutesBuilder.getJourneyCreateByActivationKeyUrl()
                      : appRoutesBuilder.getLoginMultiUrl({
                          origin: 'company-home',
                          action: 'activate',
                          companyReference,
                        })
                  }
                />
              </>
            )}
            {isCreateJourneyEnabled && (
              <>
                {auth?.isAuthenticated ? (
                  <AppButtonCompany
                    style={'primary'}
                    theme={theme}
                    isFullWidth={true}
                    className={`uppercase font-bold`}
                    label={t({
                      id: `page04.button.add-journey.anim-live`,
                    })}
                    icon={AppIcons.actionAdd}
                    onClick={addJourney}
                  />
                ) : (
                  <AppButtonCompany
                    style={'primary'}
                    theme={theme}
                    isFullWidth={true}
                    className={`uppercase font-bold`}
                    label={t({
                      id: `page03.register.anim-live`,
                    })}
                    icon={AppIcons.actionAdd}
                    href={appRoutesBuilder.getLoginMultiUrl({
                      companyReference,
                      origin: 'company-home',
                      action: 'register',
                    })}
                  />
                )}
              </>
            )}
          </>
        )}
        {showHomeButton && (
          <AppButtonCompany
            style={'primary'}
            theme={theme}
            isFullWidth={true}
            className={`uppercase font-bold`}
            label={t({ id: 'page02.infosServicesButton.anim-live' })}
            icon={AppIcons.infoSolid}
            onClick={() =>
              appRouter.navigate(
                appRoutesBuilder.getCompanyBookletUrl({
                  companyReference: journey.company?.reference,
                }),
                { cause: 'journey-home-click' }
              )
            }
          />
        )}
        {isIssueReportEnabled && !isCheckInEnabled && (
          <AppButtonCompany
            style={'cancel'}
            theme={theme}
            isFullWidth={true}
            className={`uppercase font-bold`}
            label={`Signaler un problème`}
            icon={IssuesIcons.general}
            onClick={() => {
              appRouter.navigate(
                appRoutesBuilder.getJourneyReportIssueUrl({
                  customerJourneyId: journey._id,
                }),
                {
                  cause: 'start-check-in',
                }
              );
            }}
          />
        )}
        {!auth?.isAuthenticated && (
          <>
            <AppButtonCompany
              isFullWidth={true}
              className={`uppercase font-bold`}
              onClick={() =>
                appRouter.navigate(
                  appRoutesBuilder.getLoginMultiUrl({
                    companyReference,
                    origin: 'company-home',
                    action: 'login',
                  }),
                  {
                    cause: 'company-presentation-login',
                  }
                )
              }
              style="primary"
              theme={theme}
              label={t({ id: `menu.login` })}
              icon={AppIcons.actionLogin}
            />
          </>
        )}
      </div>
    </div>
  );
};
