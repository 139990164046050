import { useAppTheme } from '_01_CORE/_common-layout';
import { AppIcons } from '_01_CORE/_components-core';
import { AppButtonCompany } from '_01_CORE/_components-core/AppButton/AppButtonCompany';
import { useCompany } from '_01_CORE/app-contexts';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { JourneyDailyActivityCardParticipantsList } from './JourneyDailyActivityCardParticipantsList';
import { SessionRelativeTimeState } from './components/ActivityTitle';
import { CustomerActivityUpdateParticipantStateFn } from './model';
import { CustomerActivityParticipantsDailyActivity } from './model/CustomerActivityParticipantsDailyActivity.type';

export const JourneyDailyActivityCardParticipantsRegistrationCard = ({
  companyReference,
  relativeTimeState,
  dailyActivity,
  onClickParticipant,
  onClickActivityDetails,
  className,
}: {
  companyReference: string;
  relativeTimeState: SessionRelativeTimeState;
  dailyActivity: CustomerActivityParticipantsDailyActivity;
  onClickActivityDetails?: ({
    activityId,
    activitySessionId,
  }: {
    activityId: string;
    activitySessionId: string;
  }) => void;
  onClickParticipant?: CustomerActivityUpdateParticipantStateFn;
  className?: string;
}) => {
  const appId = appWebConfig().appId;
  const { formatMessage: t } = useIntl();
  const theme = useAppTheme({ companyReference });
  const { company } = useCompany({ companyReference });
  const companyOptions = company?.options;
  const waitingListEnabled = companyOptions?.waitingList?.enabled;

  const activitySession = dailyActivity.selectedSession.activitySession;

  const participants = useMemo(() => {
    if (appId === 'accropark') {
      return dailyActivity.selectedSession.participants;
    } else {
      return dailyActivity.selectedSession.participants.filter(
        (x) =>
          !x.isNotRegistered ||
          x.activitySessionParticipant?.waitingRegistration?.status ===
            'waiting'
      );
    }
  }, [appId, dailyActivity.selectedSession.participants]);

  return (
    <div
      className={`${className} ${
        relativeTimeState === 'past' ? 'opacity-50' : ''
      }`}
    >
      <div className="flex-grow flex flex-row-reverse justify-between flex-wrap-reverse gap-x-2 gap-y-2 ">
        {appId === 'anim-live' &&
          activitySession.openingStatus === 'open' &&
          relativeTimeState !== 'past' && (
            <>
              {waitingListEnabled &&
              activitySession.availableRegistrationsStatus === 'full' ? (
                <div style={{ width: '12rem' }}>
                  <AppButtonCompany
                    isFullWidth={true}
                    className="w-full max-w-xs uppercase"
                    style="primary"
                    theme={theme}
                    label={t({
                      id: `page16.slots.waiting-list`,
                    })}
                    icon={AppIcons.registrationWaiting}
                  />
                </div>
              ) : (
                // <AppButtonFreeColor
                //   color={'#F97316'}
                //   textColor={'#ffffff'}
                //   // buttonStyle="primary"
                //   icon={() => (
                //     <AppIcons.registrationWaiting className="ml-3 -mr-7 h-5 w-5" />
                //   )}
                //   iconStyleClass={``}
                //   className={`block w-full max-w-xs uppercase`}
                // >
                //   <div>{t({ id: 'page16.slots.waiting-list' })}</div>
                // </AppButtonFreeColor>
                <div>
                  <AppButtonCompany
                    isFullWidth={true}
                    className="w-full max-w-xs uppercase"
                    onClick={() =>
                      onClickActivityDetails &&
                      onClickActivityDetails({
                        activityId: activitySession?.activity?._id,
                        activitySessionId: activitySession?.activitySessionId,
                      })
                    }
                    style="primary"
                    theme={theme}
                    label={t({
                      id:
                        dailyActivity.displayMode === 'multiple' &&
                        dailyActivity.openSessionsFuture.length !== 0
                          ? `common.action.choose-session`
                          : `common.action.register`,
                    })}
                    icon={AppIcons.check}
                  />
                </div>
              )}
            </>
          )}
        <div className={`flex-grow flex flex-col gap-y-1 pb-1`}>
          <JourneyDailyActivityCardParticipantsList
            activitySession={dailyActivity.selectedSession.activitySession}
            companyReference={companyReference}
            participants={participants}
            onClickParticipant={({
              participantState,
              activitySession,
              action,
            }) => {
              return onClickParticipant({
                dailyActivity,
                participantState,
                action,
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};
