import { CMJourneyCheckinInventoryPayload } from 'lib-common-model';
import { appLogger } from 'lib-web-logger';
import { useCallback, useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { useAppCacheContext, useJourney } from '_01_CORE/app-contexts';
import { customerJourneyApiClient } from '_01_CORE/app-core/app-api';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import {
  AppPage,
  CompanyImage,
  HeaderToolbar,
  PageContainer,
  useAppTheme,
} from '_01_CORE/_common-layout';
import {
  AppButtonCompany,
  AppButtonV2,
  AppFormControlRHF,
  AppIcons,
  AppInput,
  AppLoader,
  IssuesIcons,
} from '_01_CORE/_components-core';
import { JourneyCard } from '_01_CORE/_components-core/JourneyCard';
import { useAppRouter, useOperationPending } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import { journeyCheckInTester } from '_02_APP_COMMON/JOU-journey/_common';
import {
  CompanyIssueCard,
  CompanyIssueReportForm,
  CompanyIssueReportFormModel,
} from '../JOU-50-report-issue';
import { AnimLiveJourneyCheckInFormModel } from './model';

const appId = appWebConfig().appId;

export type CheckInPageMode =
  | 'create-inventory'
  | 'persist-in-progress'
  | 'create-issue'
  | 'persist-success'
  | 'persist-error';

export const AnimLiveJourneyCheckInPage = () => {
  const appRouter = useAppRouter();
  const { formatMessage: t } = useIntl();

  const { customerJourneyId } = useParams<{ customerJourneyId: string }>();

  const { auth } = useAppCacheContext();
  const customerAccount = auth?.customerProfile?.account;
  const { journey, journeyStatus, participants } = useJourney({
    customerJourneyId,
  });
  const theme = useAppTheme({ companyReference: journey?.company?.reference });

  const campingPlaceNumber = journey?.campingPlaceNumber;

  useEffect(() => {
    if (!journey) {
      appLogger.warn(
        '[AnimLiveJourneyHomePage] No journey: redirect to /journeys'
      );
      appRouter.navigateAfterDelay(appRoutesBuilder.getJourneysUrl(), {
        cause: 'company-home-no-journey',
      });
    }
  }, [appRouter, journey]);

  useEffect(() => {
    if (!journeyCheckInTester.isCheckinEnabled(journey)) {
      appRouter.navigate(
        appRoutesBuilder.getJourneyHomeUrl({ customerJourneyId: journey?._id }),
        {
          cause: 'journey-checkin-not-enabled',
        }
      );
    }
    // on ne mets pas la dépendance sur tout l'objet journey, car sinon ça nous redirige à la fin
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appRouter, journey?._id]);

  const form = useForm<AnimLiveJourneyCheckInFormModel>({
    defaultValues: {
      campingPlaceNumber: campingPlaceNumber ?? '',
    },
    mode: 'onChange',
  });
  const { control, register, formState, handleSubmit, setValue } = form;

  const [customerJourneySiteReference] = useWatch({
    control,
    name: ['campingPlaceNumber'],
  });

  const [issues, setIssues] = useState<CompanyIssueReportFormModel[]>([]);

  // const formIssues = useFieldArray({
  //   control,
  //   name: 'issues',
  // });

  const [pageState, setPageState] = useState<{
    mode: CheckInPageMode;
  }>({
    // mode: 'create-issue',
    mode: 'create-inventory',
  });
  const { mode } = pageState;

  const persistChanges = useOperationPending(
    async (formValue: AnimLiveJourneyCheckInFormModel) => {
      const payload: CMJourneyCheckinInventoryPayload = {
        ...formValue,
        issueReports: issues,
      };
      const { result } = await customerJourneyApiClient.submitCheckInInventory({
        customerJourneyId,
        payload,
      });
      if (result === 'success') {
        setPageState({
          mode: 'persist-success',
        });
      } else {
        setPageState({
          mode: 'persist-error',
        });
      }
    },
    [customerJourneyId, issues]
  );

  const submitForm = useCallback(async () => {
    handleSubmit(async (formValue: AnimLiveJourneyCheckInFormModel) => {
      // valid form
      setPageState({ mode: 'persist-in-progress' });
      try {
        await persistChanges(formValue);
        setPageState({ mode: 'persist-success' });
      } catch (e) {
        setPageState({ mode: 'persist-error' });
      }
    })();
  }, [handleSubmit, persistChanges]);

  const addIssue = useCallback(() => {
    setPageState({ mode: 'create-issue' });
  }, []);

  return !journey ? null : (
    <AppPage displayFooter={true}>
      <HeaderToolbar />
      <PageContainer className="text-left bg-gray-50" spacing="normal">
        <div>
          {mode !== 'create-issue' && (
            <>
              <CompanyImage image={journey?.company?.image1} />
              <h2 className="my-3 text-center font-bold text-lg sm:text-xl text-primary uppercase">
                VOTRE SÉJOUR
              </h2>
              <JourneyCard
                showActionEditJourney={false}
                showActionDeleteJourney={false}
                journey={journey}
                redirectOnDelete={false}
                showDetails={false}
              />
            </>
          )}
          {(mode === 'persist-in-progress' ||
            mode === 'persist-success' ||
            mode === 'persist-error') && (
            <>
              <div className="my-5">
                <h2 className="my-3 text-center font-bold text-lg sm:text-xl text-primary uppercase">
                  ÉTAT DES LIEUX
                </h2>
              </div>
              {issues.length === 0 ? (
                <h3 className="my-2 text-centlefter font-bold text-sm sm:text-base text-app-primary uppercase">
                  Aucun problème à signaler
                </h3>
              ) : (
                <div className="my-4">
                  <h3 className="my-2 text-centlefter font-bold text-sm sm:text-base text-app-danger uppercase">
                    Problèmes à signaler:
                  </h3>
                  <div className="flex flex-col gap-2">
                    {issues.map((issue, i) => (
                      <CompanyIssueCard key={i} issue={issue} />
                    ))}
                  </div>
                </div>
              )}
            </>
          )}

          {mode === 'persist-in-progress' && (
            <AppLoader className="my-4 p-2 px-4" type="persist" />
          )}

          {mode === 'persist-success' && (
            <>
              {issues.length === 0 ? (
                <div className="my-5">
                  <p className="my-2 text-sm text-justify font-bold text-app-primary">
                    Parfait, nous vous souhaitons un agréable séjour!
                  </p>
                </div>
              ) : (
                <div className="my-5">
                  <p className="my-2 text-sm text-justify font-bold text-app-success">
                    Votre demande a été transmise et sera traitée le plus
                    rapidement possible.
                  </p>
                  <p className="my-2 text-sm text-justify font-bold text-app-primary">
                    Nous avons à cœur de vous offrir le meilleur service
                    possible.
                  </p>
                </div>
              )}
              <p className="my-2 text-sm text-justify">
                {`N'oubliez pas que vous pouvez signaler le moindre incident via l'application en cliquant sur le bouton`}
                <IssuesIcons.general className="inline ml-1 w-4 h-4" />
              </p>
            </>
          )}

          {mode === 'persist-error' && (
            <div className="my-5">
              <div className="my-2">
                <p className="my-2 text-sm text-justify font-bold text-app-danger">
                  {`Une erreur innatendue s'est produite pendant l'enregistrement de
              votre état des lieux. Veuillez vérifier votre connexion Internet
              et ré-essayer.`}
                </p>
              </div>
              <AppButtonV2
                className={`mt-5 w-full font-bold rounded-full`}
                // size="small"
                buttonStyle="success"
                icon={AppIcons.check}
                onClick={() => submitForm()}
              >
                Ré-essayer
              </AppButtonV2>
            </div>
          )}
          {mode === 'create-inventory' && (
            <>
              <AppFormControlRHF
                className={'mt-4 mb-2'}
                label="N° location / emplacement"
                control={control}
                name={'campingPlaceNumber'}
                required={true}
                renderComponent={(props) => (
                  <AppInput
                    {...props}
                    className="native-input my-3 w-full text-xl font-bold"
                    icon={AppIcons.location}
                  />
                )}
              />
              <h2 className="my-3 text-center font-bold text-lg sm:text-xl text-primary uppercase">
                ÉTAT DES LIEUX
              </h2>
              {issues.length === 0 ? (
                <>
                  <p className="my-2 text-sm text-justify">
                    Vous venez de rejoindre votre location / emplacement, et
                    nous espérons que vous y passerez un agréable séjour.
                  </p>
                  <p className="mt-4 mb-2 text-sm font-bold">
                    Souhaitez-vous nous signaler un problème technique ou de
                    propreté?
                  </p>
                  <div className="my-8 mb-4 flex flex-col sm:flex-row gap-4 justify-between">
                    <AppButtonV2
                      className={`w-full sm:w-1/2 font-bold rounded-full`}
                      // size="small"
                      buttonStyle="success"
                      icon={AppIcons.check}
                      onClick={() => submitForm()}
                    >
                      Rien à signaler
                    </AppButtonV2>
                    <AppButtonV2
                      className={`w-full sm:w-1/2 font-bold rounded-full`}
                      // size="small"
                      buttonStyle="danger"
                      icon={AppIcons.actionCancel}
                      onClick={() => addIssue()}
                    >
                      Signaler un problème
                    </AppButtonV2>
                  </div>
                </>
              ) : (
                <>
                  <div className="my-4">
                    <h3 className="my-2 text-centlefter font-bold text-sm sm:text-base text-app-danger uppercase">
                      Problèmes à signaler:
                    </h3>
                    <div className="flex flex-col gap-2">
                      {issues.map((issue, i) => (
                        <CompanyIssueCard key={i} issue={issue} />
                      ))}
                    </div>
                  </div>
                  <div className="mt-8 mb-4 flex flex-col sm:flex-row gap-4 justify-between">
                    <AppButtonV2
                      className={`w-full sm:w-1/2 font-bold rounded-full`}
                      // size="small"
                      buttonStyle="danger"
                      icon={AppIcons.actionCancel}
                      onClick={() => addIssue()}
                    >
                      Signaler un autre problème
                    </AppButtonV2>
                    <AppButtonV2
                      className={`w-full sm:w-1/2 font-bold rounded-full`}
                      // size="small"
                      buttonStyle="success"
                      icon={AppIcons.check}
                      onClick={() => submitForm()}
                    >
                      Terminer
                    </AppButtonV2>
                  </div>
                </>
              )}
            </>
          )}
          {mode === 'create-issue' && (
            <CompanyIssueReportForm
              className="my-4"
              journey={journey}
              contactPhoneNumber={
                customerAccount?.phone1 ?? customerAccount?.phone2
              }
              customerJourneySiteReference={customerJourneySiteReference}
              onCancel={() => {
                setPageState({ mode: 'create-inventory' });
              }}
              onConfirm={(formValue) => {
                setIssues([...issues, formValue]);
                setPageState({ mode: 'create-inventory' });
              }}
            />
          )}
          {mode === 'persist-success' && (
            <AppButtonCompany
              style={'primary'}
              theme={theme}
              isFullWidth={true}
              className={`my-4 w-full uppercase font-bold`}
              label={t({ id: 'page02.infosServicesButton.anim-live' })}
              icon={AppIcons.infoSolid}
              onClick={() =>
                appRouter.navigate(
                  appRoutesBuilder.getCompanyBookletUrl({
                    companyReference: journey?.company?.reference,
                  }),
                  { cause: 'journey-home-click' }
                )
              }
            />
          )}
        </div>
      </PageContainer>
    </AppPage>
  );
};
