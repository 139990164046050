import { useAppToasts } from '_01_CORE/_hooks';
import { customerJourneyApiClient } from '_01_CORE/app-core/app-api';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { AuthMultiLoginAnimLivePageViewState } from '../_model';
import { authMultiLoginAnimLivePageViewStateBuilder } from '../_services';

export function useAuthPageMultiLoginCheckToken({
  viewState,
  setViewState,
}: {
  viewState: AuthMultiLoginAnimLivePageViewState;
  setViewState: Dispatch<SetStateAction<AuthMultiLoginAnimLivePageViewState>>;
}) {
  const { formatMessage: t } = useIntl();
  const { pushError, pushSuccess } = useAppToasts();

  return useCallback(async () => {
    const checkToken = viewState?.checkToken;
    setViewState({
      operationInProgress: {
        type: 'loading',
        message: 'Chargement du séjour',
      },
      ...viewState,
      checkToken: {
        ...checkToken,
        state: 'check-in-progress',
      },
    });
    try {
      const fetchedJourney = await customerJourneyApiClient.fetchExternalJourneyDetailsFromActivationToken(
        {
          activationToken: checkToken.token,
          companyReference: checkToken.companyReference,
        }
      );
      // success
      const updatedViewState: AuthMultiLoginAnimLivePageViewState = authMultiLoginAnimLivePageViewStateBuilder.buildUpdatedViewStateFromCheckTokenSuccess(
        {
          viewState,
          fetchedJourney,
        }
      );
      setViewState(updatedViewState);

      // TODO gérer la suite, idem que activationKey,
      // sauf que pas besoin de du mot de passe pour se connecter à un compte existant détecté
      if (fetchedJourney?.existingJourneyCustomerAccounts?.length === 1) {
        // setEmailAddress(fetchedJourney?.existingJourneyCustomerAccounts[0].email);
      }
    } catch (err) {
      console.error(err);
      pushError(
        t({ id: 'page30-journey-activation-token.message.fetching-error' })
      );
      setViewState({
        operationInProgress: undefined,
        ...viewState,
        checkToken: {
          ...checkToken,
          state: 'error',
        },
      });
    }
  }, [pushError, setViewState, t, viewState]);
}
