import { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import React from 'react';

export const GenderFemale = (props: IonicReactProps) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1275 1280"
      preserveAspectRatio="xMidYMid meet"
      {...props}
      x="0px"
      y="0px"
    >
      <g
        transform="translate(0,1280) scale(0.1,-0.1)"
        fill="currentColor"
        stroke="none"
      >
        <path
          d="M5815.6,11897.9c-785.6-58.5-1404.3-455.2-1838.9-1178c-333-554.2-560.2-1300.2-694.4-2284.6
		c-29.3-212.5-37-283.1-86.9-724.5c-46.5-417.3-61.1-498.2-112.7-629c-139.4-351.1-475.9-592-984.4-703
		c-187.6-40.4-456.9-69.7-705.6-75.7l-77.4-1.7l68.8-39.6c252.1-145.4,508.6-248.7,744.3-297.7c89.5-18.9,130.8-22.4,249.5-23.2
		c143.7,0,187.6,5.2,305.5,39.6c84.3,24.1,219.4,92.1,303.8,152.3c38.7,28.4,70.6,49.9,70.6,48.2c0-5.2-104.1-84.3-160.9-123.1
		c-126.5-86-330.4-166.1-497.4-196.2c-110.1-19.8-345.1-25.8-479.3-12c-61.1,6-112.7,9.5-114.4,6.9c-1.7-1.7,7.7-45.6,20.7-98.1
		c79.2-298.6,206.5-508.6,381.2-629c79.2-54.2,105-68,191.9-97.2c109.3-37.9,207.4-52.5,394.1-57.7c117.9-3.4,169.5-7.7,169.5-14.6
		c0-18.9-35.3-136-48.2-163.5c-24.1-47.3-90.4-107.6-138.5-125.6c-68-25.8-189.3-37-314.9-31c-70.6,3.4-110.1,2.6-107.6-2.6
		c6.9-21.5,117-151.4,191.9-226.3c157.5-160.9,289.1-249.5,592-399.3c220.3-109.3,211.7-97.2,92.1-132.5
		c-35.3-10.3-137.7-41.3-228-68.8c-89.5-27.5-318.4-97.2-507.7-154.9c-361.4-110.1-403.6-126.5-542.1-219.4
		c-300.3-199.6-525.8-491.3-700.4-905.2c-66.3-157.5-119.6-310.6-234.1-682.4c-52.5-167.8-102.4-321-111.9-340.8
		c-32.7-68-31.8-69.7,133.4-228.9c213.4-205.7,303.8-275.4,449.2-345.1l76.6-36.1h4792.9h4792.9l67.1,25
		c116.2,42.2,222,113.6,419.1,280.5c59.4,49.9,133.4,111.9,166.1,136.8c31.8,25,57.7,52.5,57.7,60.2c0,14.6-248.7,615.3-348.5,839.8
		c-296.9,672.9-586,1073-923.3,1276.1c-136,81.7-84.3,65.4-1253.7,388.9c-240.9,66.3-445.7,123.9-453.5,127.4
		c-12,5.2-4.3,17.2,45.6,74.9c376.9,428.5,605.8,994.7,695.3,1716.7c31,246.1,36.1,339.9,41.3,652.3
		c7.7,510.3-28.4,991.3-122.2,1616.9c-132.5,877.7-289.1,1440.5-551.6,1980c-253.8,522.3-556.7,912.1-942.2,1211.6
		c-474.1,368.3-1020.5,566.2-1698.6,614.4C6210.6,11908.2,5953.3,11908.2,5815.6,11897.9z"
        />
      </g>
    </svg>
  );
};
