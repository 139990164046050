import { useMemo } from 'react';
import { useCompanyBookletTheme } from './useCompanyBookletTheme.hook';

export function useCompanyBookletThemeSheet({
  companyReference,
  bookletThemeReference,
  bookletSheetReference,
}: {
  companyReference: string;
  bookletThemeReference: string;
  bookletSheetReference: string;
}) {
  const { booklet, theme } = useCompanyBookletTheme({
    companyReference,
    bookletThemeReference,
  });

  const sheet = useMemo(
    () =>
      (theme?.sheets ?? []).find(
        (sheet) => sheet.reference === bookletSheetReference
      ),
    [theme?.sheets, bookletSheetReference]
  );

  return { booklet, theme, sheet };
}
