import { appLogger } from 'lib-web-logger';
import {
  appWebConfig,
  MobileCustomerConfigFeature,
} from '_01_CORE/app-core/app-config';
import {
  AppRouteGuard,
  AppRouteGuardContext,
} from '_01_CORE/app-core/app-router';
import { redirectToDefaultRoute } from './redirectToDefaultRoute.guard';
export function requireFeatures(
  ...features: MobileCustomerConfigFeature[]
): AppRouteGuard {
  return async (context: AppRouteGuardContext) => {
    if (
      features.find((feature) => !appWebConfig().features.includes(feature)) ===
      undefined
    ) {
      return context; // ok
    }

    appLogger.warn(`[requireFeatures] invalid features: redirect to home`, {
      required: features,
      features: appWebConfig().features,
    });
    return redirectToDefaultRoute('requireFeatures.guard')(context);
  };
}
