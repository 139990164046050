import { useCamera } from '@capacitor-community/camera-react';
import {
  CameraResultType,
  CameraSource,
  Photo as CapacitorPhoto,
} from '@capacitor/camera';
import { useCallback, useState } from 'react';
import { imageConverter } from './imageConverter.service';

export interface Photo {
  filepath: string;
  webviewPath?: string;
  base64: string;
}

export function usePhotoFromDevice() {
  const { isAvailable, photo, getPhoto } = useCamera();
  const [photos, setPhotos] = useState<Photo[]>([]);

  const takePhoto = useCallback(
    async ({ cameraSource }: { cameraSource: CameraSource }) => {
      const cameraPhoto: CapacitorPhoto = await getPhoto({
        // https://capacitorjs.com/docs/apis/camera#imageoptions
        // resultType: CameraResultType.Base64,
        resultType: CameraResultType.Uri,
        source: cameraSource,
        quality: 90,
        // width/height (ne fonctionne pas en Web, donc ça doit dépendre de l'implémentation native)
        // width: MAX_WIDTH, // The desired maximum width of the saved image. The aspect ratio is respected.
        // height: MAX_HEIGHT, // The desired maximum height of the saved image. The aspect ratio is respected.
      }).catch(() => {
        // ignore errors
        return undefined;
      });

      return cameraPhoto;
    },
    [getPhoto]
  );

  const resizeImage = useCallback(
    async ({
      cameraPhoto,
      dimensions,
    }: {
      cameraPhoto: CapacitorPhoto;
      dimensions: {
        maxWidth: number;
        maxHeight: number;
      };
    }) => {
      if (cameraPhoto) {
        const { maxWidth, maxHeight } = dimensions;
        try {
          const res = await imageConverter.resizeImage({
            webPath: cameraPhoto.webPath,
            format: cameraPhoto.format,
            maxWidth,
            maxHeight,
          });

          const { finalWeight, originalWeight } = res;
          if (originalWeight !== finalWeight) {
            console.log(
              `Image resized by ${Math.round(
                (100 * finalWeight) / originalWeight
              )}% (${finalWeight}KB < ${originalWeight}KB)`
            );
          }

          const fileName = new Date().getTime() + cameraPhoto.format ?? 'jpeg'; // 'jpeg'| ('png'|'gif' => web only)
          const newPhoto = {
            ...res,
            filepath: fileName,
          };

          const newPhotos = [newPhoto, ...photos];
          setPhotos(newPhotos);
          return newPhoto;
        } finally {
          //
        }
      }
    },
    [photos]
  );

  const removeAllPhotos = () => setPhotos([]);

  return {
    isAvailable,
    photos,
    takePhoto,
    removeAllPhotos,
    resizeImage,
  };
}
