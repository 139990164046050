import { IonRouterLink } from '@ionic/react';
import { BookletSheet, BookletTheme } from 'lib-common-model';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { useCompanyBookletTheme } from '_01_CORE/app-contexts';
import { imageUrlBuilder } from '_01_CORE/app-core/app-api';
import {
  AppPage,
  HeaderToolbar,
  PageContainer,
  PageTitle,
} from '_01_CORE/_common-layout';
import { useAppRouter } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import './CompanyBookletThemePage.css';

export const CompanyBookletThemePage = React.memo(
  function CompanyBookletThemePage() {
    const appRouter = useAppRouter();
    const { bookletThemeReference, companyReference } = useParams<{
      companyReference: string;
      bookletThemeReference: string;
    }>();
    const { theme } = useCompanyBookletTheme({
      companyReference,
      bookletThemeReference,
    });

    const companyBookletUrl = useMemo(
      () => appRoutesBuilder.getCompanyBookletUrl({ companyReference }),
      [companyReference]
    );

    useEffect(() => {
      if (!theme) {
        appRouter.navigateAfterDelay(companyBookletUrl, {
          cause: 'company-booklet-theme-missing',
        });
      } else {
        if (theme?.sheets.length === 1) {
          const sheet = theme?.sheets[0];
          appRouter.navigate(
            appRoutesBuilder.getCompanyBookletThemeSheetUrl({
              companyReference,
              bookletThemeReference,
              bookletSheetReference: sheet?.reference,
            }),
            { cause: 'company-booklet-theme-unique-sheet' }
          );
        }
      }
    }, [
      appRouter,
      companyBookletUrl,
      companyReference,
      theme,
      bookletThemeReference,
    ]);

    return !theme ? null : (
      <AppPage tabId="home">
        <HeaderToolbar />
        <PageContainer className="text-center bg-gray-50">
          <PageTitle
            spacing="small"
            title={theme?.title}
            backLink={companyBookletUrl}
          />
          <div className="CompanyBookletThemePage-content">
            <div className="infos-area">
              {theme?.sheets
                .filter((x) => x.visible)
                .map((sheet) => (
                  <BookletSheetsListItem
                    key={sheet?.reference}
                    companyReference={companyReference}
                    sheet={sheet}
                    theme={theme}
                  />
                ))}
            </div>
          </div>
        </PageContainer>
      </AppPage>
    );
  }
);

const BookletSheetsListItem = React.memo(
  function BookletSheetsListItemComponent({
    companyReference,
    theme,
    sheet,
  }: {
    companyReference: string;
    theme: BookletTheme;
    sheet: BookletSheet;
  }) {
    const appRouter = useAppRouter();
    const companyBookletSheetUrl = useMemo(
      () =>
        appRoutesBuilder.getCompanyBookletThemeSheetUrl({
          companyReference,
          bookletThemeReference: theme?.reference,
          bookletSheetReference: sheet?.reference,
        }),
      [companyReference, sheet?.reference, theme?.reference]
    );
    const imageUrl = useMemo(
      () =>
        imageUrlBuilder.getImageFullUrl({
          image: sheet?.coverImage?.xl,
          defaultUrl: '/assets/images/empty.png',
        }),
      [sheet?.coverImage?.xl]
    );
    return (
      <IonRouterLink
        key={sheet?.reference}
        onClick={() =>
          appRouter.navigate(companyBookletSheetUrl, {
            cause: 'company-booklet-theme-select-sheet',
          })
        }
      >
        <div className="info-sheet">
          <div className="infos-sheet-name w-1/2 self-center pl-2 leading-5 font-bold text-gray-800 text-base text-left">
            <div>{sheet?.coverTitle?.toUpperCase()}</div>
            <div className="text-gray-600 text-sm">
              {sheet?.coverSubTitle?.toUpperCase()}
            </div>
          </div>
          <div className="infos-sheet-image-container">
            <img className="infos-sheet-image object-cover" src={imageUrl} />
          </div>
        </div>
      </IonRouterLink>
    );
  }
);
