import { AppCurrency } from './AppCurrency.type';

export const APP_CURRENCIES: AppCurrency[] = [
  {
    isoCode: 'EUR', // https://en.wikipedia.org/wiki/Euro
    symbol: '€',
    label: 'Euro',
    cents: 2,
  },
  {
    isoCode: 'SEK', // https://en.wikipedia.org/wiki/Swedish_krona
    symbol: 'kr',
    label: 'Swedish krona',
    cents: 0,
  },
];
