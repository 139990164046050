import { CMCompanyResume } from 'lib-common-model';
import { useMemo } from 'react';
import { useAppCacheContext } from '_01_CORE/app-contexts';

export function useGroupCompaniesResumes({
  groupReference,
}: { groupReference?: string } = {}): CMCompanyResume[] {
  const { groups } = useAppCacheContext();
  return useMemo(() => {
    const filteredGroups = groupReference
      ? Object.values(groups).filter((g) => g.reference === groupReference)
      : Object.values(groups);
    const companyList = filteredGroups.reduce(
      (acc, group) =>
        acc.concat(
          group.companies.filter(
            (company) => company.options?.customerList?.enabled
          )
        ),
      [] as CMCompanyResume[]
    );
    companyList?.sort((a, b) =>
      a.name < b.name ? -1 : a.name > b.name ? 1 : 0
    );
    return companyList;
  }, [groupReference, groups]);
}
