import { CMJourney } from 'lib-common-model';
import { useCallback } from 'react';
import { useParams } from 'react-router';
import { useAppCacheContext, useJourney } from '_01_CORE/app-contexts';
import { useAppSettings } from '_01_CORE/app-core/app-security';
import { useAppRouter } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';

export function useNavigateToNewJourneyPage({ context }: { context: string }) {
  const appRouter = useAppRouter();

  const appSettings = useAppSettings();
  const whiteLabel = appSettings?.universe?.whiteLabel;
  const { customerJourneyId } = useParams<{ customerJourneyId: string }>();

  const { journey } = useJourney({ customerJourneyId });

  const {
    auth,
    companyValidReferenceCode,
    dispatchCacheContextAction,
  } = useAppCacheContext();

  return useCallback(() => {
    dispatchCacheContextAction({ type: 'clear-update-journey-cache', context });
    //Company ref in cache
    let nextRoute: string;
    if (companyValidReferenceCode) {
      nextRoute = appRoutesBuilder.getJourneyDatesEditUrl({
        companyReference: companyValidReferenceCode,
      });
    } else if (journey?.company?.reference) {
      nextRoute = appRoutesBuilder.getJourneyDatesEditUrl({
        companyReference: journey?.company?.reference,
      });
    } else if (auth?.customerProfile?.journeys?.length > 0) {
      //Take the first journey's company if there are any
      nextRoute = appRoutesBuilder.getJourneyDatesEditUrl({
        companyReference: (auth?.customerProfile?.journeys[
          auth?.customerProfile?.journeys.length - 1
        ] as CMJourney)?.company?.reference,
      });
    } else {
      nextRoute = appRoutesBuilder.getJourneyCompanyChooseUrl({ whiteLabel });
    }
    return appRouter.navigate(nextRoute, { cause: context });
  }, [
    appRouter,
    auth?.customerProfile?.journeys,
    companyValidReferenceCode,
    context,
    whiteLabel,
    dispatchCacheContextAction,
    journey?.company?.reference,
  ]);
}
