import { AppEnvConfigPublic, AppSettings } from 'lib-common-model';
import { apiClient } from 'lib-web-api-client';

export const appSettingsApiClient = {
  fetchAppSettings,
};

function fetchAppSettings(): Promise<{
  appSettings: AppSettings;
  appEnvConfigPublic: AppEnvConfigPublic;
}> {
  return apiClient.get<any>(`/customer-web/app-settings`, {
    options: {
      authenticate: false,
      alwaysThrowError: true, // retourne une exception quelle que soit l'erreur
    },
  });
}
