import { dateTransformer } from '_01_CORE/_components-core';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { AuthMultiLoginAnimLivePageLocalState } from './useAuthMultiLoginAnimLivePageLocalState.hook';

export const AuthMultiLoginAnimLivePageInfoPanel = ({
  localState,
}: {
  localState: AuthMultiLoginAnimLivePageLocalState;
}) => {
  const { formatMessage: t } = useIntl();

  const { viewState, setViewState, actions } = localState;
  const { journeyInfo, operationInProgress, mode } = viewState;

  const showAccountExistsMessage =
    viewState?.promptEmail?.defaultEmailAccountExists &&
    viewState?.promptEmail?.defaultEmail;

  const showJourneyBookingMessage = journeyInfo?.fetchedJourney;

  return showAccountExistsMessage || showJourneyBookingMessage ? (
    <div className="w-full flex flex-col gap-2">
      {showJourneyBookingMessage && (
        <div
          className={clsx('text-left text-lg text-app-theme-anim-live-primary')}
        >
          {t(
            {
              id: 'page30-journey-activation-token.message.vous-avez-reserve',
            },
            {
              campingName: journeyInfo.fetchedJourney.companyName,
              beginDate: dateTransformer.formatUTC(
                journeyInfo.fetchedJourney.fakeCustomerJourney.beginDateUTC,
                t({ id: 'common.date.format' })
              ),
              endDate: dateTransformer.formatUTC(
                journeyInfo.fetchedJourney.fakeCustomerJourney
                  .endDateInclusiveUTC,
                t({ id: 'common.date.format' })
              ),
            }
          )}
        </div>
      )}
      {showAccountExistsMessage && (
        <h2 className="text-left text-lg text-app-theme-accropark-primary">
          {t(
            {
              id:
                'page30-journey-activation-token.message.nous-avons-detecte-compte-existant-email',
            },
            {
              email: viewState?.promptEmail?.defaultEmail,
            }
          )}
        </h2>
      )}
    </div>
  ) : null;
};
