import { IonButton, IonIcon } from '@ionic/react';
import { arrowBackOutline, trash } from 'ionicons/icons';
import React from 'react';
import { useIntl } from 'react-intl';

export const PaymentPageCancelModal = ({
  onClick,
}: {
  onClick: (options: { confirmed: boolean }) => void;
}) => {
  const { formatMessage: t } = useIntl();

  return (
    <div className="w-full">
      <IonButton
        color="secondary"
        onClick={() => onClick({ confirmed: false })}
        expand="block"
      >
        <IonIcon icon={arrowBackOutline} className="mr-3" />
        {t({ id: 'modal.no' })}
      </IonButton>
      <IonButton
        color="danger"
        expand="block"
        onClick={() => onClick({ confirmed: true })}
      >
        <IonIcon icon={trash} className="mr-3" />
        {t({ id: 'modal.yes' })}
      </IonButton>
    </div>
  );
};
