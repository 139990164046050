import { AppId } from 'lib-common-model';
import React, { useMemo } from 'react';

export type NativeButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export type AppButtonProps = {
  color: 'bg-gray';
  primaryColorAppId?: AppId;
} & NativeButtonProps;

export const AppButton: React.FC<AppButtonProps> = ({
  children,
  primaryColorAppId,
  color,
  className,
  ...nativeButtonProps
}) => {
  const classes = useMemo(() => {
    if (color === 'bg-gray') {
      return 'bg-gray-600 text-white hover:bg-gray-700';
    }

    return '';
  }, [color]);

  return (
    <button
      type="button"
      {...nativeButtonProps}
      className={`inline-flex py-2 px-8 rounded-2xl items-center border border-transparent font-bold font-medium rounded shadow-sm text-md focus:outline-none ${classes} ${
        className ?? ''
      }`}
    >
      {children}
    </button>
  );
};
