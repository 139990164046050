import { IonIcon } from '@ionic/react';
import { dateTester, dateTransformer } from '_01_CORE/_components-core';
import { format as dateFormat } from 'date-fns';
import { arrowBack, arrowForward } from 'ionicons/icons';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

export const AppTitleDateNavigator = ({
  date = new Date(),
  onChange,
  minDate,
  maxDate: maxDateInclusive,
  className,
}: {
  date?: Date;
  onChange?: (date: Date) => void;
  minDate: Date;
  maxDate: Date;
  className?: string;
}) => {
  const { formatMessage: t } = useIntl();

  const maxDateExclusive = useMemo(
    () => dateTransformer.addDays(maxDateInclusive, 1),
    [maxDateInclusive]
  );

  const buildSelectedDay = useCallback(
    (selectedDayDate: Date) => {
      selectedDayDate = new Date(selectedDayDate);
      if (
        dateTester.isDateWithinRange(selectedDayDate, {
          minDate,
          maxDateExclusive,
        })
      ) {
        const selectedDay = {
          date: selectedDayDate,
          hasPrevious:
            selectedDayDate.getTime() !== new Date(minDate).getTime(),
          hasNext:
            selectedDayDate.getTime() !== new Date(maxDateInclusive).getTime(),
        };
        return selectedDay;
      }
    },
    [maxDateExclusive, maxDateInclusive, minDate]
  );

  const selectedDay = useMemo(() => buildSelectedDay(date), [
    buildSelectedDay,
    date,
  ]);

  const journeWeekDayText = useMemo<string>(() => {
    const weekDay = dateTransformer.formatWeekDay(selectedDay?.date, {
      formatMessage: t,
      format: 'long',
    });
    return weekDay;
  }, [selectedDay?.date, t]);

  const journeyDateText = useMemo<string>(() => {
    const date = dateFormat(selectedDay?.date, t({ id: 'common.date.format' }));
    return date;
  }, [selectedDay?.date, t]);

  return (
    <div className={`flex place-items-center ${className ?? ''}`}>
      <IonIcon
        className={`${
          selectedDay?.hasPrevious ? 'bg-primary' : 'bg-secondary'
        } cursor-pointer w-10 h-10 rounded-full mt-2 text-white`}
        icon={arrowBack}
        onClick={() => {
          if (selectedDay?.hasPrevious) {
            const newDay = buildSelectedDay(
              dateTransformer.addDays(selectedDay?.date, -1)
            );
            onChange(newDay?.date);
          }
        }}
      />
      <div className="ml-2 text-primary text-center flex-grow">
        <div className="text-xl mm:text-2xl">{journeWeekDayText}</div>
        <div className="text-md">{journeyDateText}</div>
      </div>
      <IonIcon
        className={`${
          selectedDay?.hasNext ? 'bg-primary' : 'bg-secondary'
        } cursor-pointer w-10 h-10 rounded-full mt-2 text-white`}
        icon={arrowForward}
        onClick={() => {
          if (selectedDay?.hasNext) {
            const newDay = buildSelectedDay(
              dateTransformer.addDays(selectedDay?.date, 1)
            );
            onChange(newDay?.date);
          }
        }}
      />
    </div>
  );
};
