export class AppRouteGuardError extends Error {
  redirectToUrl?: string;

  constructor({ redirectToUrl }: { redirectToUrl?: string }) {
    super(`AppRouteGuardError redirection: "${redirectToUrl}"`);
    this.redirectToUrl = redirectToUrl;

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, AppRouteGuardError.prototype);
  }
}
