import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import {
  useAppCacheContext,
  useCompany,
  useJourneyEnabledActions,
} from '_01_CORE/app-contexts/app-cache-context';
import { useAppSettings } from '_01_CORE/app-core/app-security';
import { AppPage, HeaderToolbar } from '_01_CORE/_common-layout';
import { useAppRouter, useJourneyCancellation } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import { JourneyChooseDatesAnimLiveForm } from './JourneyChooseDatesAnimLiveForm';
import { JourneyChooseDatesAnimLiveFormData } from './JourneyChooseDatesAnimLiveFormData.type';
export const JourneyCreateDatesAnimLivePage = () => {
  const appRouter = useAppRouter();

  const { companyReference } = useParams<{ companyReference: string }>();
  const { dispatchCacheContextAction, journeyEditData } = useAppCacheContext();

  const { company } = useCompany({ companyReference });
  const { isCreateJourneyEnabled } = useJourneyEnabledActions({});

  const minDate = useMemo<Date>(() => {
    const min = new Date();
    min.setFullYear(new Date().getFullYear() - 2);
    return min;
  }, []);
  const maxDate = useMemo<Date>(() => {
    const min = new Date();
    min.setFullYear(new Date().getFullYear() + 2);
    return min;
  }, []);

  useEffect(() => {
    if (!company) {
      appRouter.navigateAfterDelay(appRoutesBuilder.getRedirectToDefaultUrl(), {
        cause: 'journey-date-no-company',
      });
    }
    if (!isCreateJourneyEnabled) {
      appRouter.navigateAfterDelay(appRoutesBuilder.getRedirectToDefaultUrl(), {
        cause: 'journey-creation-not-allowed',
      });
    }
  }, [appRouter, company, isCreateJourneyEnabled]);

  const defaultFormValue = useMemo(() => {
    const value: JourneyChooseDatesAnimLiveFormData = {
      campingPlaceNumber: journeyEditData?.journey?.campingPlaceNumber,
      beginDateUTC: journeyEditData?.journey?.beginDateUTC,
      endDateInclusiveUTC: journeyEditData?.journey?.endDateInclusiveUTC,
    };
    return value;
  }, [
    journeyEditData?.journey?.beginDateUTC,
    journeyEditData?.journey?.campingPlaceNumber,
    journeyEditData?.journey?.endDateInclusiveUTC,
  ]);

  /**
   * Submit button callback
   */
  const onSubmit = useCallback(
    (data: JourneyChooseDatesAnimLiveFormData) => {
      //Save in redux cache
      dispatchCacheContextAction({
        type: 'update-journey-company-and-dates',
        payload: {
          ...data,
          companyReference: company?.reference,
        },
      });
      appRouter.navigate(
        appRoutesBuilder.getJourneyCreateParticipantsListUrl({
          companyReference,
        }),
        { cause: 'journey-date-submit' }
      );
    },
    [
      appRouter,
      company?.reference,
      companyReference,
      dispatchCacheContextAction,
    ]
  );

  const onBack = useCallback(() => {
    appRouter.navigate(
      appRoutesBuilder.getCompanyBookletUrl({
        companyReference,
      }),
      { cause: 'journey-date-back' }
    );
  }, [appRouter, companyReference]);
  const { openModalCancelJourneyDraft: onCancel } = useJourneyCancellation({
    companyReference,
  });

  const appSettings = useAppSettings();
  const whiteLabel = appSettings?.universe?.whiteLabel;
  /**
   * Choose another destination button callback
   */
  const handleChooseDestination = useCallback(() => {
    appRouter.navigate(
      appRoutesBuilder.getJourneyCompanyChooseUrl({
        companyReference,
        whiteLabel,
      }),
      { cause: 'journey-date-select-company' }
    );
  }, [appRouter, companyReference, whiteLabel]);

  return (
    <AppPage displayFooter={false}>
      <HeaderToolbar hideLogout={true} />
      <JourneyChooseDatesAnimLiveForm
        mode="create"
        defaultFormValue={defaultFormValue}
        minBeginDate={minDate}
        maxBeginDate={maxDate}
        minEndDate={minDate}
        maxEndDate={maxDate}
        onSubmit={onSubmit}
        onCancel={onCancel}
        onBack={onBack}
        company={company}
        handleChooseDestination={handleChooseDestination}
        isEditableCampingPlaceNumber={true}
      />
    </AppPage>
  );
};
