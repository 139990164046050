import React, { useMemo } from 'react';

export type AppButtonLinkSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export type AppButtonLinkRounded = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full';

type AppButtonLinkProps = {
  size?: AppButtonLinkSize;
  color?: string;
  hoverColor?: string;
  textColor?: string;
  textHoverColor?: string;
  rounded?: AppButtonLinkRounded;
  width?: string;
};

export type NativeAnchorProps = React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>;

const BUTTON_SIZES = {
  xs: 'px-2.5 py-1.5 text-xs',
  sm: 'px-3 py-2 text-sm',
  md: 'px-4 py-2 text-sm',
  lg: 'px-4 py-2 text-base',
  xl: 'px-6 py-3 text-base',
};

function hover_match(color: string) {
  if (color === 'primary') {
    return 'gray-800';
  }
  const colorParts = color.split('-');
  const num = colorParts.pop();
  if (isFinite(+num)) {
    if (+num >= 500) {
      colorParts.push((+num + 200).toString());
    } else {
      colorParts.push((+num - 200).toString());
    }
    return colorParts.join('-');
  }
  return 'secondary';
}

function text_match(color: string) {
  if (color === 'primary') {
    return 'white';
  }
  if (color === 'secondary') {
    return 'primary';
  }
  const colorParts = color.split('-');
  const num = colorParts.pop();
  if (isFinite(+num)) {
    if (+num >= 500) {
      colorParts.push((+num - 300).toString());
    } else {
      colorParts.push((+num + 300).toString());
    }
    return colorParts.join('-');
  }
  return 'white';
}

export const AppButtonLink: React.FC<AppButtonLinkProps & NativeAnchorProps> = (
  props
) => {
  const {
    size = 'md',
    color = 'primary',
    rounded = 'md',
    width,
    textColor,
    hoverColor,
    textHoverColor,
    children,
    ...NativeAnchorProps
  } = props;
  const rWidth = width ? `w-${width} ` : '';
  const hoverMatch = useMemo<string>(() => hoverColor ?? hover_match(color), [
    hoverColor,
    color,
  ]);
  const textMatch = useMemo<string>(() => textColor ?? text_match(color), [
    textColor,
    color,
  ]);
  const tHoverColor = textHoverColor ?? textMatch;

  return (
    <a
      {...NativeAnchorProps}
      className={`${
        BUTTON_SIZES[size]
      } ${rWidth}inline-flex items-center border border-transparent font-medium rounded-${rounded} shadow-sm text-${textMatch} bg-${color} hover:bg-${hoverMatch} hover:text-${tHoverColor} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${color} ${
        NativeAnchorProps.className ?? ''
      }`}
    >
      {children}
    </a>
  );
};
