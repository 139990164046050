import { useAppRouter, useAppToasts } from '_01_CORE/_hooks';
import { useAppCacheContext } from '_01_CORE/app-contexts';
import { useIsNetworkConnected } from '_01_CORE/app-core/app-platform';
import { companyOrGroupReferenceTester } from '_02_APP_COMMON/AUT-auth';
import { appLogger } from 'lib-web-logger';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { AuthMultiLoginAnimLivePageBaseLocalState } from '../useAuthMultiLoginAnimLivePageLocalState.hook';
import { AuthMultiLoginAction_RedirectToJourneyCreateDates } from './useAuthMultiLoginAction_RedirectToJourneyCreateDates.hook';

export function useAuthMultiLoginAction_CheckCompanyCode(
  baseLocalState: AuthMultiLoginAnimLivePageBaseLocalState,
  actions: {
    redirectToJourneyCreateDates: AuthMultiLoginAction_RedirectToJourneyCreateDates;
  }
) {
  const appCacheContext = useAppCacheContext();
  const { pushError, pushSuccess } = useAppToasts();
  const { dispatchCacheContextAction } = useAppCacheContext();
  const appRouter = useAppRouter();

  const { formatMessage: t } = useIntl();

  const isNetworkConnected = useIsNetworkConnected();

  const { viewState, setViewState } = baseLocalState;

  const checkCompanyCode = useCallback(
    async ({
      companyOrGroupReference,
      emailAddress,
    }: {
      companyOrGroupReference: string;
      emailAddress: string;
    }) => {
      if (!isNetworkConnected()) {
        return;
      }
      setViewState({
        operationInProgress: {
          type: 'loading',
          message: 'Connexion en cours',
        },
        ...viewState,
      });
      try {
        const {
          redirectTo,
        } = await companyOrGroupReferenceTester.testCompanyOrGroupCode(
          companyOrGroupReference,
          {
            appCacheContext,
            alwaysThrowError: true,
          }
        );

        if (redirectTo) {
          if (viewState.contextAction === 'see-company-by-code') {
            // ouverture du livret d'accueil
            appRouter.navigate(redirectTo, {
              cause: 'see-company-by-code',
            });
          } else {
            // création d'un séjour non PMS
            actions.redirectToJourneyCreateDates({
              companyReference: companyOrGroupReference,
              emailAddress,
            });
          }
        } else {
          pushError(t({ id: 'page01.companyUnknown' }));
        }
      } catch (err) {
        appLogger.warn('Authentication err', { err });
        pushError(t({ id: 'page01.connectionNotOK' }));
      } finally {
        setViewState({
          operationInProgress: undefined,
          ...viewState,
        });
      }
    },
    [
      actions,
      appCacheContext,
      appRouter,
      isNetworkConnected,
      pushError,
      setViewState,
      t,
      viewState,
    ]
  );

  return checkCompanyCode;
}

export type AuthMultiLoginAction_checkCompanyCode = ReturnType<
  typeof useAuthMultiLoginAction_CheckCompanyCode
>;
