import { search } from '_01_CORE/_components-core/data/search';
import {
  AppSettings,
  CMFetchActivitiesData,
  CMJourney,
  CMJourneyActivities,
  CMJourneyActivitySession,
  CMJourneyParticipant,
} from 'lib-common-model';
import {
  ActivityPendingOperationDef,
  CustomerJourneyActivitiesAggregatedData,
  CustomerJourneyActivitiesAggregatedDataActivity,
  CustomerJourneyActivitiesLocalFilters,
} from '../model';
import { CustomerJourneyActivitiesPageUrlParams } from './customerJourneyActivitiesPageUrlManager.service';
import { customerJourneyAnimationsDailyActivitiesBuilder } from './customerJourneyAnimationsDailyActivitiesBuilder.service';
import {
  CMJourneyAnimationsDailyActivitiesFilters,
  CMJourneyAnimationsFilteredData,
  customerJourneyAnimationsDailyActivitiesFilter,
} from './customerJourneyAnimationsDailyActivitiesFilter.service';

export const customerJourneyActivitiesDataAggregator = {
  aggregateData,
};

function aggregateData({
  fetchedData,
  customerJourney,
  params,
  localFilters,
  pendingOperations,
  appSettings,
}: {
  fetchedData: CMFetchActivitiesData;
  customerJourney: CMJourney;
  params: CustomerJourneyActivitiesPageUrlParams;
  localFilters: CustomerJourneyActivitiesLocalFilters;
  pendingOperations: ActivityPendingOperationDef[];
  appSettings: AppSettings;
}): CustomerJourneyActivitiesAggregatedData {
  if (!fetchedData) {
    return undefined;
  }

  const isSearchTextActive = localFilters.searchText?.trim()?.length;

  const { category, selectedDate } = params;

  const {
    customerJourneyActivities,
    activitySessionsToDisplay,
    activeJourneyActivityPackages,
  } = fetchedData;

  const activities: CustomerJourneyActivitiesAggregatedDataActivity[] = [];
  const participants: CMJourneyParticipant[] = [];

  if (!customerJourneyActivities) {
    return {
      dailyActivities: undefined,
      participantsWithoutActivities: undefined,
      activities,
      participants,
    };
  }

  if (customerJourneyActivities) {
    customerJourneyActivities.clubsParticipants.forEach(
      ({ journeyParticipants }) => {
        journeyParticipants.forEach((p) => {
          if (
            !participants.find(
              (p2) => p2.journeyParticipantId === p.journeyParticipantId
            )
          ) {
            participants.push(p);
          }
        });
      }
    );
  }

  if (customerJourneyActivities) {
    customerJourneyActivities.activitySessions.forEach(({ activity }) => {
      if (!activities.find((a) => a._id === activity._id)) {
        activities.push(activity);
      }
    });
  }

  // const selectedSession = localFilters.lastSelectedSessionId
  //   ? dailyActivity.sessions.find(
  //       (s) =>
  //         s.activitySession.activitySessionId ===
  //         localFilters.lastSelectedSessionId
  //     )
  //   : undefined;

  const { filteredFetchedData, filteredData } = filterData({
    fetchedData,
    localFilters,
    params,
    activities,
    customerJourney,
  });

  const {
    dailyActivities,
    participantsWithoutActivities,
  } = customerJourneyAnimationsDailyActivitiesBuilder.buildDailyActivities({
    filteredData,
    lastSelectedSessionId: localFilters.lastSelectedSessionId,
    companyOptions: customerJourney?.company.options,
    pendingOperations,
    journeyActivityPackages: filteredFetchedData.activeJourneyActivityPackages,
    appSettings,
  });

  const aggregatedData: CustomerJourneyActivitiesAggregatedData = {
    dailyActivities,
    participantsWithoutActivities,
    activities,
    participants,
  };
  return aggregatedData;
}

function filterData({
  fetchedData,
  customerJourney,
  params,
  localFilters,
  activities,
}: {
  fetchedData: CMFetchActivitiesData;
  customerJourney: CMJourney;
  params: CustomerJourneyActivitiesPageUrlParams;
  localFilters: CustomerJourneyActivitiesLocalFilters;
  activities: CustomerJourneyActivitiesAggregatedDataActivity[];
}): {
  filteredFetchedData: CMFetchActivitiesData;
  filteredData: CMJourneyAnimationsFilteredData;
} {
  if (!fetchedData) {
    return {
      filteredFetchedData: undefined,
      filteredData: undefined,
    };
  }
  const customerJourneyActivities = filterCustomerJourneyActivities({
    customerJourneyActivities: fetchedData?.customerJourneyActivities,
    params,
    localFilters,
  });

  const isSearchTextActive = localFilters.searchText?.trim()?.length;
  const { category, selectedDate } = params;

  const selectedActivityIdIfActivityExists =
    localFilters.selectedActivityId &&
    activities.find((a) => a._id === localFilters.selectedActivityId)
      ? localFilters.selectedActivityId
      : undefined;

  const filters: CMJourneyAnimationsDailyActivitiesFilters = {
    registeredOrPresentOnly:
      !isSearchTextActive && localFilters.registeredOrPresentOnly,
    childrenIds:
      !isSearchTextActive && localFilters?.selectedChildId
        ? [localFilters?.selectedChildId]
        : [],
    activitiesIds: selectedActivityIdIfActivityExists
      ? [selectedActivityIdIfActivityExists]
      : [],
  };
  const filteredData: CMJourneyAnimationsFilteredData = customerJourneyAnimationsDailyActivitiesFilter.filterDailyActivities(
    {
      customerJourneyActivities,
      selectedDate: selectedDate,
      filters,
      journeyParticipants: customerJourney?.participants,
    }
  );

  const filteredFetchedData: CMFetchActivitiesData = {
    ...fetchedData,
    customerJourneyActivities,
  };

  return {
    filteredFetchedData,
    filteredData,
  };
}

function filterCustomerJourneyActivities({
  customerJourneyActivities: x,
  params,
  localFilters,
}: {
  customerJourneyActivities?: CMJourneyActivities;
  params: CustomerJourneyActivitiesPageUrlParams;
  localFilters: CustomerJourneyActivitiesLocalFilters;
}): CMJourneyActivities {
  const isSearchTextActive = localFilters.searchText?.trim()?.length;
  const { category, selectedDate } = params;

  if (!category || !x) {
    return x;
  }

  let activitySessions: CMJourneyActivitySession[] = x.activitySessions;

  if (isSearchTextActive) {
    activitySessions = search.filter(activitySessions, {
      getAttributes: (item) => [item.activity?.translation?.name],
      searchText: localFilters.searchText,
      sortResultsByBestMatch: true,
    });
  } else {
    activitySessions = activitySessions.filter((x) => {
      return (x.activity?.categories ?? []).includes(category);
    });
  }
  const customerJourneyActivities: CMJourneyActivities = {
    ...x,
    activitySessions,
  };
  return customerJourneyActivities;
}
