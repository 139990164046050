import { IonIcon, IonInput, IonRouterLink } from '@ionic/react';
import { AppPage, HeaderToolbar, PageContainer } from '_01_CORE/_common-layout';
import {
  AppButtonUniverse,
  AppIcons,
  FormError,
} from '_01_CORE/_components-core';
import { AppInputIonic } from '_01_CORE/_components-core/AppInput';
import { useAppRouter, useAppToasts } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import { useAppCacheContext } from '_01_CORE/app-contexts';
import { authApiClient } from '_01_CORE/app-core/app-api';
import { useNetworkStatus } from '_01_CORE/app-core/app-platform';
import { chevronBackOutline, lockClosedOutline } from 'ionicons/icons';
import { BUProfile, CMProfile } from 'lib-common-model';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

export type ResetPasswordConfirmFormData = {
  password: string;
  passwordConfirmation: string;
};

export const ResetPasswordConfirmPage = () => {
  const appRouter = useAppRouter();
  const { networkStatus } = useNetworkStatus();
  const { formatMessage: t } = useIntl();
  const { pushError, pushSuccess } = useAppToasts();

  const [error, setError] = useState<string>();
  const [profile, setProfile] = useState<CMProfile | BUProfile>();
  const {
    handleSubmit,
    formState,
    getValues,
    control,
  } = useForm<ResetPasswordConfirmFormData>({ mode: 'onChange' });

  const { auth } = useAppCacheContext();

  useEffect(() => {
    if (!auth) {
      appRouter.navigate(appRoutesBuilder.getRedirectToDefaultUrl(), {
        cause: 'reset-password-not-authenticated',
      });
    }
  });

  const errors = formState.errors;

  const onSubmit = useCallback(
    (data: ResetPasswordConfirmFormData) => {
      authApiClient
        .resetPasswordConfirm({ password: data.password })
        .then(({ profile }) => {
          setProfile(profile);
        })
        .catch((e) => {
          if (!networkStatus?.connected) {
            pushError(t({ id: 'error.network.down' }));
          } else {
            if (e.status === 404) {
              return setError(t({ id: 'page21.link.bad' }));
            }
            pushError(t({ id: 'error.unexpected' }));
          }
        });
    },
    [networkStatus?.connected, pushError, t]
  );

  const onInvalid = () => {
    pushError(t({ id: 'common.message.error.invalid-form' }));
  };

  const goBack = () => {
    appRouter.navigate(appRoutesBuilder.getLoginHomeUrl(), {
      cause: 'reset-password-page-back',
    });
  };

  const goHome = () => {
    pushSuccess(t({ id: 'page01.connectionOK' }), 500);
    appRouter.navigate(appRoutesBuilder.getRedirectToDefaultUrl(), {
      cause: 'reset-password-go-home',
    });
  };

  return (
    <AppPage displayFooter={false}>
      <HeaderToolbar />
      <PageContainer
        className={`h-full bg-white text-center flex flex-col gap-4`}
      >
        <div className="flex mb-2 place-items-center">
          <IonRouterLink className="cursor-pointer" onClick={() => goBack()}>
            <IonIcon className="text-primary" icon={chevronBackOutline} />
          </IonRouterLink>
          <div className="ml-8 text-primary text-left flex-grow text-xl place-self-start ml:text-2xl sm:text-3xl">
            {t({ id: 'page21.title' })}
          </div>
        </div>

        <div className="w-full bg-gray-50 rounded-xl border border-gray-200 pt-4 pb-8 px-8">
          {!error && !profile ? (
            <form
              className="max-w-xl mt-8 m-auto"
              onSubmit={handleSubmit(onSubmit, onInvalid)}
            >
              <div className="my-2">
                <Controller
                  render={({ field: { value, onChange } }) => (
                    <AppInputIonic
                      required={true}
                      value={value}
                      placeholder={t({ id: 'page21.password.new' })}
                      icon={lockClosedOutline}
                    >
                      <IonInput type="password" onIonChange={onChange} />
                    </AppInputIonic>
                  )}
                  control={control}
                  name={`password`}
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: t({ id: 'validation.required' }),
                    },
                  }}
                />
                <FormError error={errors.password?.message} />
              </div>

              <div className="my-2">
                <Controller
                  render={({ field: { value, onChange } }) => (
                    <AppInputIonic
                      required={true}
                      value={value}
                      placeholder={t({ id: 'page21.password.new.confirm' })}
                      icon={lockClosedOutline}
                    >
                      <IonInput type="password" onIonChange={onChange} />
                    </AppInputIonic>
                  )}
                  control={control}
                  name={`passwordConfirmation`}
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: t({ id: 'validation.required' }),
                    },
                    validate: (value: any) =>
                      value === getValues('password') ||
                      t({ id: 'validation.password.match' }),
                  }}
                />
                <FormError error={errors.passwordConfirmation?.message} />
              </div>

              <AppButtonUniverse
                className="my-4"
                style="primary"
                icon={AppIcons.check}
                type="submit"
              >
                {t({ id: 'page20.submit' })}
              </AppButtonUniverse>
            </form>
          ) : (
            <div className="max-w-xl mt-8 m-auto">
              {!!error && <div className="text-danger">{error}</div>}
              {profile && (
                <div>
                  <div className="text-xl place-self-start ml:text-2xl mb-4">
                    {t({ id: 'page21.password.reset' })}
                  </div>
                  <AppButtonUniverse
                    className="my-4"
                    style="cancel"
                    icon={AppIcons.home}
                    onClick={goHome}
                  >
                    {t({ id: 'page21.back' })}
                  </AppButtonUniverse>
                </div>
              )}
            </div>
          )}
        </div>
      </PageContainer>
    </AppPage>
  );
};
