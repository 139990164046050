import { IonButton, IonGrid, IonIcon, IonInput, IonRow } from '@ionic/react';
import {
  AppCompanySelect,
  AppPage,
  FooterActions,
  HeaderToolbar,
  PageContainer,
} from '_01_CORE/_common-layout';
import {
  AppInputIonic,
  AppStaticCalendar,
  useAppModalContext,
} from '_01_CORE/_components-core';
import { dateUtils } from '_01_CORE/_components-core/CommonUtils';
import {
  useAppRouter,
  useAppToasts,
  useJourneyCancellation,
} from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import {
  useAppCacheContext,
  useCompany,
  useGroup,
  useGroupCompaniesResumes,
} from '_01_CORE/app-contexts';
import { customerActivitiesApiClient } from '_01_CORE/app-core/app-api';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import { useAppImageUrl } from '_01_CORE/app-core/app-security/useAppImageUrl.hook';
import { useJourneyEditDataDraftOrRedirect } from '_02_APP_COMMON/JOU-journey/JOU-10-journey-choose-dates';
import { CompanyOpeningDates } from '_02_APP_COMMON/JOU-journey/_common/CompanyOpeningDates';
import { format as formatDate } from 'date-fns';
import { arrowBackOutline, build } from 'ionicons/icons';
import { CMCompany } from 'lib-common-model';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { AccroparkCompanyActivitiesAvailabilities } from './AccroparkCompanyActivitiesAvailabilities';
import { DailySessionsAvailabilitiesMap } from './DailySessionsAvailabilitiesMap.type';
import { JourneyChooseDatesPageAccroparkCalendarDayComponent } from './JourneyChooseDatesPageAccroparkCalendarDayComponent';
import {
  buildSessionCounts,
  buildStatusFromSessionCounts,
} from './useSessionCounts.hook';

const MIN_PARTICIPANTS_COUNT = 1;

export const AccroparkJourneyChooseDatesPage = () => {
  const appRouter = useAppRouter();

  const { companyReference } = useParams<{ companyReference: string }>();
  const { journeyEditData, dispatchCacheContextAction } = useAppCacheContext();
  const { pushError } = useAppToasts();

  useJourneyEditDataDraftOrRedirect();

  const { company } = useCompany({ companyReference });
  const companies = useGroupCompaniesResumes({
    groupReference: company?.companyGroupReference,
  });
  const group = useGroup({ groupReference: company?.companyGroupReference });

  const otherCompany: Pick<
    CMCompany,
    'name' | 'reference' | 'logo' | 'direction'
  > = useMemo(() => {
    const c = companies.find((c) => c._id !== company?._id);
    if (c) {
      return c;
    }
    return (
      group?.companies ?? [].find((c) => c?.reference !== company?.reference)
    );
  }, [companies, company?._id, company?.reference, group?.companies]);

  const otherCompanyLogoUrl = useAppImageUrl(otherCompany?.logo, {
    defaultImage: 'app',
  });

  const { openModalCancelJourneyDraft } = useJourneyCancellation({
    companyReference: company?.reference,
  });

  const { formatMessage: t } = useIntl();

  const [participantsCount, setParticipantsCount] = useState(2);
  const [
    dailySessionsAvailabilitiesMap,
    setDailySessionsAvailabilitiesMap,
  ] = useState<DailySessionsAvailabilitiesMap>(undefined);
  const [dateMin, setDateMin] = useState<Date>(new Date());
  const [dateMax, setDateMax] = useState<Date>(undefined);
  const [journeyDate, setJourneyDate] = useState<Date>(new Date());

  const dateFormat = t({ id: 'common.date.format' });

  const dateFormatted = useMemo<string>(
    () => formatDate(new Date(journeyDate), dateFormat),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [journeyDate]
  );

  const confirmDate = useCallback(
    ({ companyReference, date }: { companyReference: string; date: Date }) => {
      if (!date) {
        pushError(t({ id: 'common.message.error.invalid-form' }), 500);
      } else {
        //Save in redux cache
        dispatchCacheContextAction({
          type: 'update-journey-company-and-dates',
          payload: {
            companyReference,
            beginDateUTC: new Date(date),
            endDateInclusiveUTC: new Date(date),
          },
        });
        appRouter.navigate(
          appRoutesBuilder.getJourneyCreateParticipantsListUrl({
            companyReference,
          }),
          { cause: 'journey-date-submit' }
        );
      }
    },
    [appRouter, dispatchCacheContextAction, pushError, t]
  );
  const { openModal, closeModal } = useAppModalContext();

  function autoFillForm() {
    if (company?.validMergedPeriods.length) {
      const period1 = company?.validMergedPeriods[0];
      setJourneyDate(period1.endDateInclusive);
    }
  }
  const onChangeCompany = useCallback(
    (company: Pick<CMCompany, 'reference'>) => {
      setDailySessionsAvailabilitiesMap(undefined);
      // setSelectedCompany(company);
      appRouter.navigate(
        appRoutesBuilder.getJourneyDatesEditUrl({
          companyReference: company?.reference,
        }),
        { cause: 'select-company' }
      );
    },
    [appRouter]
  );

  const onChangeParticipantsCount = useCallback((countString: string) => {
    const count = parseInt(countString, 10);
    if (!isNaN(count) && count >= MIN_PARTICIPANTS_COUNT) {
      setDailySessionsAvailabilitiesMap(undefined);
      setParticipantsCount(count);
    }
  }, []);

  const fetchData = useCallback(
    async ({
      selectedCompanyId,
      participantsCount,
    }: {
      selectedCompanyId: string;
      participantsCount: number;
    }) => {
      const agendaSessionsAvailabilities = await customerActivitiesApiClient.fetchAgendaSessionsAvailabilities(
        { participantsCount }
      );
      setDailySessionsAvailabilitiesMap(
        agendaSessionsAvailabilities.reduce((acc, a) => {
          const dayDateString = a.dayDate.toUTCString();
          if (!acc[dayDateString]) {
            acc[dayDateString] = {};
          }
          if (a.companyId === selectedCompanyId) {
            acc[dayDateString].currentCompany = a;
          } else {
            acc[dayDateString].otherCompany = a;
          }
          return acc;
        }, {} as DailySessionsAvailabilitiesMap)
      );
    },
    []
  );

  useEffect(() => {
    fetchData({ selectedCompanyId: company?._id, participantsCount });
  }, [fetchData, participantsCount, company?._id]);
  /**
   * Submit button callback
   */
  const handleSubmit = () => {
    confirmDate({ date: journeyDate, companyReference: company?.reference });
  };

  const backToHome = () => {
    appRouter.navigate(
      appRoutesBuilder.getCompanyBookletUrl({
        companyReference,
      }),
      { cause: 'journey-date-back' }
    );
  };

  /**
   * Initialise the form
   */
  useEffect(() => {
    const min = new Date();
    const max = new Date();
    // min.setFullYear(new Date().getFullYear() - 2);
    min.setDate(min.getDate() - 1);
    max.setFullYear(new Date().getFullYear() + 2);
    setDateMin(min);
    setDateMax(max);

    if (journeyEditData?.journey?.beginDateUTC) {
      setJourneyDate(journeyEditData.journey.beginDateUTC);
    }

    if (companies && !company) {
      appRouter.navigate(appRoutesBuilder.getRedirectToDefaultUrl(), {
        cause: 'journey-date-no-company',
      });
    }

    //Load saved values if user comes back after he left the process
    // setJourneyDate(accountCreationJourney?.beginDate?.toString());
  }, [journeyEditData, appRouter, company, companies]);

  function onDateSelected(date: Date) {
    setJourneyDate(date);
    const {
      currentCompanyAvailableSessionsCount,
      otherCompanyAvailableSessionsCount,
      currentCompanySessionsCount,
    } = buildSessionCounts({
      date,
      dailySessionsAvailabilitiesMap,
    });
    const status = buildStatusFromSessionCounts({
      currentCompanyAvailableSessionsCount,
      otherCompanyAvailableSessionsCount,
      currentCompanySessionsCount,
    });
    switch (status) {
      case 'available':
        confirmDate({ date, companyReference: company?.reference });
        break;
      case 'busy-1':
      case 'busy-2':
      case 'full':
        if (
          currentCompanyAvailableSessionsCount > 0 &&
          otherCompanyAvailableSessionsCount == 0
        ) {
          confirmDate({ date, companyReference: company?.reference });
        } else {
          const {
            currentCompanyAvailableSessionsCount,
            otherCompanyAvailableSessionsCount,
          } = buildSessionCounts({
            date,
            dailySessionsAvailabilitiesMap,
          });

          const dayDateString =
            date && date.toUTCString ? date.toUTCString() : undefined;
          const availabilities =
            dailySessionsAvailabilitiesMap &&
            dailySessionsAvailabilitiesMap[dayDateString];

          openModal({
            title: t({ id: 'page07.home.title.accropark' }),
            icon: 'none',
            content: (
              <div className="mb-10">
                <AccroparkCompanyActivitiesAvailabilities
                  company={company}
                  onConfirm={() => {
                    closeModal();
                    confirmDate({ date, companyReference: company?.reference });
                  }}
                  availableSessionsCount={currentCompanyAvailableSessionsCount}
                  availabilities={availabilities?.currentCompany}
                />
                {availabilities?.otherCompany?.activities?.length !== 0 && (
                  <AccroparkCompanyActivitiesAvailabilities
                    company={otherCompany}
                    onConfirm={() => {
                      closeModal();
                      openModal({
                        title: '',
                        icon: 'none',
                        content: (
                          <div className="mb-10">
                            <div className="font-bold">
                              {t({ id: 'page10.home.confirm.message1' })}:
                            </div>
                            <div
                              className={`mt-4 flex flex-1 justify-left text-left p-2 text-gray-600 font-bold text-gray-800`}
                            >
                              <img
                                className="max-w-6xs h-10 object-contain mr-2"
                                src={otherCompanyLogoUrl}
                              />
                              <div className={`ml-2 text-md`}>
                                <div>{otherCompany?.name}</div>
                              </div>
                            </div>
                            <div className="mt-4 font-bold">
                              {t({ id: 'page10.home.confirm.message2' })}
                            </div>
                            <div className="mt-2 text-gray-900 text-sm">
                              <div>{otherCompany?.direction?.line1}</div>
                            </div>
                            <div className="mt-6 text-gray-700 text-sm">
                              <hr className="mb-2" />
                              <div>{otherCompany?.direction?.zipCode}</div>
                              <div>{otherCompany?.direction?.city}</div>
                            </div>
                          </div>
                        ),
                        actions: (
                          <>
                            <IonButton
                              className="w-full"
                              color="primary"
                              onClick={async () => {
                                closeModal();
                                confirmDate({
                                  date,
                                  companyReference: otherCompany?.reference,
                                });
                              }}
                            >
                              {t({ id: 'common.action.confirm' })}
                            </IonButton>
                            <IonButton
                              color="secondary"
                              onClick={closeModal}
                              expand="block"
                            >
                              <IonIcon
                                icon={arrowBackOutline}
                                className="mr-3"
                              />
                              {t({ id: 'common.action.cancel' })}
                            </IonButton>
                          </>
                        ),
                      });
                      // confirmDate({ date, companyReference: otherCompany?.reference });
                    }}
                    availableSessionsCount={otherCompanyAvailableSessionsCount}
                    availabilities={availabilities?.otherCompany}
                  />
                )}
              </div>
            ),
            actions: (
              <>
                <IonButton
                  color="secondary"
                  onClick={closeModal}
                  expand="block"
                >
                  <IonIcon icon={arrowBackOutline} className="mr-3" />
                  {t({ id: 'common.action.cancel' })}
                </IonButton>
              </>
            ),
          });
        }
        break;
      case 'closed':
        break;
    }
  }
  return !(companies && group) ? null : (
    <>
      <AppPage displayFooter={false}>
        <HeaderToolbar hideLogout={true} />
        <PageContainer className="text-center bg-gray-50" spacing="normal">
          <AppCompanySelect
            companies={companies}
            selectedId={company?._id}
            onChange={(company) => onChangeCompany(company)}
          />
          <IonGrid className="max-w-xl">
            {appWebConfig().envId === 'dev' && (
              <div className="absolute right-8 text-2xl">
                <IonIcon
                  className="mr-2"
                  color="primary"
                  icon={build}
                  onClick={() => autoFillForm()}
                />
              </div>
            )}
            {/* Date */}
            <div className="mt-5 flex justify-between">
              <div className="mr-2">
                <div className="text-primary text-center flex-grow text-xl mm:text-2xl ml:text-3xl sm:text-4xl">
                  {t({ id: 'page10.input-label.participants' })}
                </div>
                <AppInputIonic value={participantsCount}>
                  <IonInput
                    onIonChange={(e) =>
                      onChangeParticipantsCount(e.detail.value)
                    }
                  />
                </AppInputIonic>
              </div>
              <div className="ml-2">
                <div className="text-primary text-center flex-grow text-xl mm:text-2xl ml:text-3xl sm:text-4xl">
                  {t({ id: 'page10.input-label.date' })}
                </div>
                <AppInputIonic required={true} value={dateFormatted}>
                  <IonInput readonly={true} />
                </AppInputIonic>
              </div>
            </div>
            <IonRow className="my-4">
              <AppStaticCalendar
                minDate={dateMin}
                maxDate={dateMax}
                value={journeyDate}
                onChange={(d) => onDateSelected(d)}
                dayComponent={(props) => (
                  <JourneyChooseDatesPageAccroparkCalendarDayComponent
                    dailySessionsAvailabilitiesMap={
                      dailySessionsAvailabilitiesMap
                    }
                    {...props}
                  />
                )}
                className={
                  !dailySessionsAvailabilitiesMap ? 'invisible' : 'visible'
                }
              />
            </IonRow>
          </IonGrid>

          {/*Alert begin*/}
          <div
            className="text-sm text-danger font-bold"
            hidden={
              !journeyDate ||
              dateUtils.isDateWithinOneRange(
                new Date(journeyDate),
                company?.validMergedPeriods
              )
            }
          >
            {t({ id: 'page10.journey.alertBegin' })}
          </div>
          <CompanyOpeningDates company={company} />
        </PageContainer>
        <FooterActions
          onCancel={openModalCancelJourneyDraft}
          onBack={backToHome}
          onSubmit={handleSubmit}
        />
      </AppPage>{' '}
    </>
  );
};
