import { appLogger } from 'lib-web-logger';
import { useEffect, useMemo, useState } from 'react';
import { BehaviorSubject, NEVER, Observable, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { rxjsUtilWeb } from '../util/rxjsUtilWeb.service';
import { Loadable } from './Loadable.model';

let lastStartDate: Date;

const DEBUG_NAME_TO_LOG = '***';

export function useLoadable<T>(
  options: {
    load: () => Observable<T>;
    debugName: string;
  } & (
    | {
        initialValue?: T;
      }
    | {
        initialValueFromLoadSnapshot?: boolean;
      }
  )
): T & Loadable {
  const { stop, stopOrContinue$ } = useMemo(() => {
    const stop$ = new BehaviorSubject(false);

    const stopOrContinue$ = stop$.pipe(
      distinctUntilChanged(),
      switchMap((stop) => (stop ? NEVER : of(true)))
    );

    function stop() {
      stop$.next(true);
    }

    return { stop, stopOrContinue$ };
  }, []);

  const { load, debugName } = options;

  const initialValue = useMemo(() => {
    if ((options as any).initialValueFromLoadSnapshot) {
      return rxjsUtilWeb.getSnapshot(
        load().pipe(
          catchError((err) => {
            appLogger.warn(
              `[useLoadable] ${
                debugName ? `[${debugName}]` : ''
              } error loading snapshot data`,
              err
            );
            return NEVER;
          })
        )
      );
    }
    return (options as any).initialValue;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // appLogger.debug(`[useLoadable] ${debugName ? `[${debugName}]` : ''} initialValue: ${initialValue}`);

  const [value, setValue] = useState<T & Loadable>(
    buildLoadableState<T>(initialValue, stop)
  );

  useEffect(() => {
    const subscription = stopOrContinue$
      .pipe(
        map(() => new Date()),
        tap((startDate) => {
          lastStartDate = startDate;
        }),
        tap((startDate) => {
          if (debugName === DEBUG_NAME_TO_LOG) {
            appLogger.warn(
              `[useLoadable] ${debugName ? `[${debugName}]` : ''} startDate:`,
              startDate
            );
          }
        }),
        switchMap(() => load()),
        tap((value) => {
          const duration = new Date().getTime() - lastStartDate.getTime();
          if (duration > 50 && duration < 100) {
            appLogger.warn(
              `[useLoadable] ${
                debugName ? `[${debugName}]` : ''
              } loading duration: ${duration}ms`
            );
          }
          if (debugName === DEBUG_NAME_TO_LOG) {
            appLogger.warn(
              `[useLoadable] ${debugName ? `[${debugName}]` : ''} value:`,
              value
            );
          }
          // re-init start date for next emission
          lastStartDate = new Date();
        }),
        distinctUntilChanged(),
        startWith(initialValue),
        map((x) => buildLoadableState<T>(x, stop))
      )
      .subscribe(
        (newValue) => {
          if (debugName === DEBUG_NAME_TO_LOG) {
            appLogger.warn(`[useLoadable] [${debugName}] new value:`, newValue);
          }
          setValue(newValue);
        },
        (err) => {
          appLogger.warn(
            `[useLoadable] ${
              debugName ? `[${debugName}]` : ''
            } error loading data`,
            err
          );
          setValue({
            _error: true,
            _loaded: false,
          } as T & Loadable);
        }
      );

    return () => {
      if (debugName === DEBUG_NAME_TO_LOG) {
        appLogger.warn(`[useLoadable] [${debugName}] UNSUSCRIBE`, subscription);
      }
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return value;
}

function buildValue<T>(value?: T): T {
  if (value !== undefined && value !== null) {
    return value;
  }
  // FIXME si c'est un array, il faut renvoyer un array non?
  return {} as T;
}

function buildLoadableState<T>(value: T, stop: () => void): T & Loadable {
  // appLogger.debug('buildLoadableState:', value)
  const loaded = value !== undefined && value !== null;
  return {
    ...buildValue(value),
    _error: false,
    _loaded: loaded,
    stop,
  };
}
