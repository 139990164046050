import { AppButtonV2, dateTransformer } from '_01_CORE/_components-core';
import { useAppRouter } from '_01_CORE/_hooks';
import { useAppImageUrl } from '_01_CORE/app-core/app-security/useAppImageUrl.hook';
import { format } from 'date-fns';
import { AppClientNotification } from 'lib-common-model';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

export const NotificationItem = ({
  notification: n,
  showOpenActivityButton = true,
  showTitle = true,
  onClick,
}: {
  notification: AppClientNotification;
  showOpenActivityButton?: boolean;
  showTitle?: boolean;
  onClick?: (notification: AppClientNotification) => void;
}) => {
  const appRouter = useAppRouter();
  const { formatMessage: t } = useIntl();

  const now = useMemo(() => new Date().getTime(), []);

  const activity = n?.activity;
  const activitySession = n?.activitySession;
  const customerJourneyId = n?.customerJourneyId;

  const style: 'old' | 'recent' | 'unread' = useMemo(() => {
    if (n) {
      if (now - n.sendDate.getTime() > 24 * 3600 * 1000) {
        return 'old'; // sent > 24h
      }
      if (!n.readDate || now - n.readDate.getTime() < 60 * 1000) {
        return 'unread'; // read < 1mn
      }
      return 'recent'; // sent < 24h
    }
  }, [n, now]);

  const iconUrl = useAppImageUrl(n?.content?.image, {
    defaultImage: 'app', // logo de l'appli par défaut
  });

  const activityLogoUrl = useAppImageUrl(activity?.logo, {
    defaultImage: activity ? 'activity' : null,
  });

  return !n ? null : (
    <div
      className={`text-left border-b-2 border-gray-400 mb-2 ${
        onClick ? 'cursor-pointer' : ''
      }`}
      onClick={() => onClick && onClick(n)}
    >
      <div className="flex items-stretch gap-2">
        <div className="flex-shrink-0 mt-0.5 w-14 h-auto object-contain">
          <img className="" src={activityLogoUrl ?? iconUrl} />
          {onClick && showOpenActivityButton && (
            <AppButtonV2
              className="my-1 w-full  font-bold"
              buttonStyle="outline-primary"
              size="small"
            >
              ouvrir
            </AppButtonV2>
          )}
        </div>
        <div className="flex-grow flex flex-col ">
          {showTitle && (
            <div
              className={`font-bold text-sm
      ${
        style === 'unread'
          ? 'text-blue-400'
          : style === 'recent'
          ? 'text-gray-600'
          : 'text-gray-400'
      }`}
            >
              {n.content?.title ?? n.title}
            </div>
          )}
          {activity && activitySession && (
            <div className="font-bold text-xs">
              {activity.name} -{' '}
              {dateTransformer.formatUTC(
                activitySession.beginDateTime,
                "dd/MM/yyyy'" + t({ id: 'page06.at' }) + "'HH:mm"
              )}
            </div>
          )}
          <div
            className={`flex-grow text-xs break-words whitespace-pre-wrap inline
            ${n.readDate ? '' : ''}`}
          >
            {n.content?.body ?? n.body}
          </div>
          <div
            className={`text-xs text-right 
      ${
        style === 'unread'
          ? 'font-bold'
          : style === 'recent'
          ? 'font-bold'
          : 'text-gray-400'
      }
      `}
          >
            {format(
              n.sendDate,
              "dd/MM/yyyy'" + t({ id: 'page06.at' }) + "'HH:mm"
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
