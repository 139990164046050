import {
  AppRouteGuard,
  AppRouteGuardContext,
} from '_01_CORE/app-core/app-router';
import {
  authenticationClient,
  authenticationStore,
} from '_01_CORE/app-core/app-security';
import { redirectToDefaultRoute } from './redirectToDefaultRoute.guard';

export function disconnect(): AppRouteGuard {
  return async (context: AppRouteGuardContext) => {
    const { appCacheContext } = context;

    appCacheContext.dispatchCacheContextAction({
      type: 'clear-all-user-cache',
      context: 'disconnect',
    });
    await authenticationClient.logout();
    authenticationStore.logoutRequired.set(true);

    return redirectToDefaultRoute('disconnect.guard')({
      ...context,
      appCacheContext: {
        ...appCacheContext,
        auth: null,
      },
    });
  };
}
