import ky from 'ky';
import { from } from 'rxjs';

import { HttpClientFetchOptions } from './HttpClientRequestOptions.model';

// https://github.com/sindresorhus/ky
// https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch

export const httpClient = {
  fetch: fetch,
};

function fetch(resource: string, options: HttpClientFetchOptions = {}) {
  return from(
    ky(resource, {
      ...options,
      retry: 0,
    })
  );
}
