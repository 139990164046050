import {
  AppAuthenticatedTokenInfo,
  AppSecurityUser,
  BUProfile,
  CMProfile,
  JwtTokenPayLoad,
} from 'lib-common-model';
import { appLogger } from 'lib-web-logger';
import { appRolesBuilder } from './appRolesBuilder.service';

export const tokenParser = {
  parse,
};

// NOTE: en cas d'authentification temporaire, 'profile' peut être "null"
function parse(
  token: string,
  profile?: CMProfile | BUProfile
): AppSecurityUser {
  const claims = getTokenClaims(token);
  appLogger.info('[tokenParser.parse] claims: ', claims);

  if (!claims?.authenticatedUser) {
    return;
  }

  const { company, ...authenticatedUser } = claims.authenticatedUser;

  const authUserWithoutRoles: Omit<AppSecurityUser, 'roles'> = {
    ...authenticatedUser,
    appId: claims.appId,
    tokenInfo: buildTokenInfo(claims),
  };
  const companyAuthorizations = (profile as BUProfile)?.companyAuthorizations;
  if (profile?.type === 'company' && company && companyAuthorizations) {
    // les authorisations et rôles ne sont pas inclues dans le token,
    // sinon ça fait exploser la taille du header ensuite (surtout si multiple impersonate)
    authUserWithoutRoles.company = {
      ...company,
      authorizations: companyAuthorizations,
    };
  }

  const authUser: AppSecurityUser = {
    ...authUserWithoutRoles,
    // TODO supprimer authUser.roles
    roles: appRolesBuilder.getAppRoles(authenticatedUser),
  };

  return authUser;
}

function buildTokenInfo(
  claims: Pick<JwtTokenPayLoad, '_tokenVersion' | 'iat' | 'iiat' | 'exp'>
): AppAuthenticatedTokenInfo {
  return {
    version: claims._tokenVersion,
    issueDate: new Date(1000 * claims.iat),
    initialIssueDate: new Date(claims.iiat ? 1000 * claims.iiat : undefined),
    expirationDate: new Date(1000 * claims.exp),
  };
}

function getTokenClaims(token: string): JwtTokenPayLoad {
  if (!token || !token.split) {
    appLogger.warn('[tokenParser.getTokenClaims] Invalid token: ', { token });
    throw new Error('Invalid token');
  }

  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  const claims: any = JSON.parse(window.atob(base64));
  return claims;
}

// function parse(token: string): JwtTokenPayLoadFull {
//   const claims = getTokenClaims(token);
//   appLogger.info('[tokenParser.parse] claims: ', claims);

//   const authUser: JwtTokenPayLoadFull = {
//     _id: claims._id,
//     // initialIssueDate: new Date(claims.iiat ? 1000 * claims.iiat : undefined),
//     expirationDate: new Date(1000 * claims.exp),
//     issueDate: new Date(1000 * claims.iat),
//     isImpersonate: claims.isImpersonate,
//     realUser: claims.realUser,
//     roles: claims.roles,
//     appId: claims.appId,
//   };

//   return authUser;
// }

// function getTokenClaims(
//   token: string
// ): JwtTokenPayLoad & { exp: number; iat: number } {
//   if (!token || !token.split) {
//     appLogger.warn('[tokenParser.getTokenClaims] Invalid empty token: ', {
//       token,
//     });
//     throw new Error('Invalid token: empty or not string');
//   }
//   const base64Url = token.split('.')[1];
//   const base64 = base64Url.replace('-', '+').replace('_', '/');
//   const claims: any = JSON.parse(window.atob(base64));
//   return claims;
// }
