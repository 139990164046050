import { CMCompany } from 'lib-common-model';
import { useMemo } from 'react';
import { useAppCacheContext } from '../../hooks';

export function useCompany({
  companyReference,
}: {
  companyReference: string;
}): { company: CMCompany } {
  const { companies, companyValidReferenceCode } = useAppCacheContext();

  if (!companyReference) {
    companyReference = companyValidReferenceCode;
  }

  const company: CMCompany = useMemo(() => {
    if (companies[companyReference]) {
      return companies[companyReference];
    }
  }, [companies, companyReference]);

  return { company };
}
