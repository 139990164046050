import { Dialog, Transition } from '@headlessui/react';
import { FC, Fragment } from 'react';

const DEFAULT_TEXT_COLOR_TO_AVOID_BUG_ON_IOS_DARK_MODE = 'text-gray-800';

export const AppDialogV2: FC<{
  isOpen: boolean;
  onClose: () => void;
  className?: string;
}> = ({ children, isOpen, onClose, className = '' }) => {
  // const fullWidth = true;
  // const sizeClasses = fullWidth ? 'h-screen w-full' : `sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6`;
  return (
    // https://tailwindui.com/components/application-ui/overlays/modals
    // https://headlessui.dev/react/dialog
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        // style={{ height: 'calc(100vh - env(safe-area-inset-bottom))' }}
        open={isOpen}
        onClose={() => {
          // désactivé, sinon ça se ferme tout seul si on clique sur un popup de confirmation dans le dialog
          // onClose()
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="fixed inset-0 bg-black opacity-30"
            aria-hidden="true"
          />
        </Transition.Child>

        {/* This element is to trick the browser into centering the modal contents. */}
        {/* <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span> */}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          {/* Full-screen scrollable container */}
          <div className="fixed inset-0 p-4">
            {/* Container to center the panel */}
            <div
              className={`h-full flex items-center justify-center ${DEFAULT_TEXT_COLOR_TO_AVOID_BUG_ON_IOS_DARK_MODE}`}
            >
              <Dialog.Panel
                className={`h-full flex flex-col mx-auto rounded bg-white ${className}`}
              >
                {children}
              </Dialog.Panel>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
};
