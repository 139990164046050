import { appLogger } from 'lib-web-logger';
import {
  Loadable,
  useInputModelAsObservable,
  useLoadable,
} from 'lib-web-redux';
import { useMemo } from 'react';
import { useParams } from 'react-router';
import { combineLatest, from, Observable, of, throwError } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
  mapTo,
  switchMap,
} from 'rxjs/operators';
import { useAppCacheContext } from '_01_CORE/app-contexts';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import { useAppPlatform } from '_01_CORE/app-core/app-platform';
import {
  authenticationStore,
  useAppSettings,
} from '_01_CORE/app-core/app-security';
import { AppRouteArgsAuth } from './AppRouteArgs.model';
import { AppRouteState } from './AppRouteState.model';
import {
  AppRouteGuard,
  AppRouteGuardContext,
  AppRouteGuardError,
} from './guard';

const SIMULATE_MOBILE_APP_TEST_OLD_VERSION = false;

export function useAppRoute({
  auth,
  routeGuards,
}: {
  auth: AppRouteArgsAuth;
  routeGuards: AppRouteGuard[];
}): AppRouteState & Loadable {
  const appId = appWebConfig().appId;
  const routeParams = useParams<{ [attr: string]: string }>();
  const routeAuth$ = useInputModelAsObservable(auth, '[useAppRoute] auth');

  const routeParamsMemoised = useMemo(() => {
    return routeParams;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...Object.keys(routeParams), ...Object.values(routeParams)]);

  const routeGuards$ = useInputModelAsObservable(
    routeGuards,
    '[useAppRoute] routeGuards'
  );
  const routeParams$ = useInputModelAsObservable(
    routeParamsMemoised,
    '[useAppRoute] routeParamsMemoised'
  );
  const appCacheContext = useAppCacheContext();
  const appCacheContext$ = useInputModelAsObservable(
    appCacheContext,
    '[useAppRoute] appCacheContext'
  ).pipe(filter((x) => x.loaded));

  const appSettings = useAppSettings();

  const { platform } = useAppPlatform();
  return useLoadable({
    debugName: 'useAppRoute',
    initialValueFromLoadSnapshot: false, // si on met true, les guards sont exécutés 2 fois
    load: () => {
      const appAuth$ = authenticationStore.auth.get();

      const routeGuardResult$: Observable<AppRouteGuardError> = combineLatest([
        routeGuards$.pipe(
          first()
          // tap((x) => console.log('x1:', x))
        ),
        routeParams$,
        // .pipe(tap((x) => console.log('x2:', x))),
      ]).pipe(
        // tap((x) => console.log('x3:', x)),
        switchMap(([routeGuards, routeParams]) =>
          appCacheContext$.pipe(
            map((appCacheContext) => ({
              routeGuards,
              appCacheContext,
              routeParams,
            })),
            first()
          )
        ),
        // tap((x) => console.log('x4:', x)),
        switchMap(({ routeGuards, appCacheContext, routeParams }) => {
          if (routeGuards) {
            const guardContext: AppRouteGuardContext = {
              appSettings,
              appCacheContext,
              routeParams,
            };

            return from(processGuards(routeGuards, guardContext)).pipe(
              mapTo({} as AppRouteGuardError),
              catchError((err: AppRouteGuardError) => {
                return of(err);
              })
            );
          }
          return of({} as AppRouteGuardError);
        }),
        distinctUntilChanged()
      );

      const routeAuthRedirectUrl$ = combineLatest([
        routeAuth$.pipe(first()),
        appAuth$,
      ]).pipe(
        switchMap(([routeAuth, appAuth]) => {
          if (routeAuth) {
            if (
              routeAuth.authenticationRequired ||
              routeAuth.superAdminRequired
            ) {
              if (!appAuth.isAuthenticated) {
                if (appId == 'anim-live') {
                  appLogger.info(
                    `[useAppRoute] navigate to "/" (cause: authenticationRequired | superAdminRequired)`,
                    appAuth
                  );
                  return of('/login');
                } else {
                  return of('/');
                }
              }
              if (routeAuth.superAdminRequired && !appAuth.isSuperAdmin) {
                appLogger.info(
                  `[useAppRoute] navigate to "/" (cause: superAdminRequired)`,
                  appAuth
                );
                return of('/');
              }
            }
            if (routeAuth.redirectToIfAuthenticated) {
              if (appAuth.isAuthenticated) {
                appLogger.info(
                  `[useAppRoute] navigate: ${routeAuth.redirectToIfAuthenticated} (cause: redirectToIfAuthenticated)`
                );
                return of(routeAuth.redirectToIfAuthenticated);
              }
            }
          }
          return of(undefined);
        }),
        distinctUntilChanged()
      );

      return combineLatest([
        authenticationStore.redirectToOldVersionPage.get(),
        routeGuardResult$,
        routeAuthRedirectUrl$,
      ]).pipe(
        debounceTime(50),
        map(([oldVersion, routeGuardResult, routeAuthRedirectUrl]) => {
          if (oldVersion) {
            if (platform === 'web' && !SIMULATE_MOBILE_APP_TEST_OLD_VERSION) {
              appLogger.warn('[App] too old version: refresh page');
              window.location.reload();
            } else {
              appLogger.warn(
                '[App] too old version: redirect to old-version page'
              );
              return '/old-version';
            }
          }
          if (routeGuardResult?.redirectToUrl) {
            return routeGuardResult.redirectToUrl;
          }
          return routeAuthRedirectUrl;
        }),
        map((redirectUrl) => {
          const appRouteState: AppRouteState = {
            redirectUrl,
          };
          return appRouteState;
        }),
        catchError((err) => {
          appLogger.error('[useAppRoute] Error during routing: redirect to /', {
            err,
          });
          return throwError(err);
        })
      );
    },
  });
}

async function processGuards(
  routeGuards: AppRouteGuard[],
  guardContext: AppRouteGuardContext
) {
  if (!routeGuards) {
    return Promise.resolve(guardContext);
  }
  let previousContext = guardContext;
  for (const routeGuard of routeGuards) {
    previousContext = await routeGuard(previousContext);
  }
  return previousContext;
}
