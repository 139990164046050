import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useJourney } from '_01_CORE/app-contexts';
import { customerJourneyApiClient } from '_01_CORE/app-core/app-api';
import { useNetworkStatus } from '_01_CORE/app-core/app-platform';
import { useAppModalContext } from '_01_CORE/_components-core';
import {
  useAppRouter,
  useAppToasts,
  useOperationPending,
} from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import { PaymentPageCancelModal } from '_02_APP_COMMON/JOU-journey/_common';

export function useDeleteJourneyBookingAccroparkConfirm({
  customerJourneyId,
  onConfirm,
}: {
  customerJourneyId: string;
  onConfirm?: () => void;
}) {
  const appRouter = useAppRouter();
  const { formatMessage: t } = useIntl();
  const { pushError } = useAppToasts();
  const { networkStatus } = useNetworkStatus();
  const { openModal, closeModal } = useAppModalContext();

  const { journey } = useJourney({ customerJourneyId });

  const removeJourney = useOperationPending(async () => {
    appRouter.navigate(appRoutesBuilder.getJourneysUrl(), {
      cause: 'remove-journey',
    });
    try {
      await customerJourneyApiClient.deleteJourney({
        customerJourneyId: journey._id,
      });
    } catch (e) {
      if (!networkStatus?.connected) {
        pushError(t({ id: 'error.network.down' }));
      } else {
        pushError(t({ id: 'error.unexpected' }));
      }
    }
  }, [appRouter, journey, networkStatus?.connected, pushError, t]);

  const deleteJourneyBookingConfirm = useCallback(
    () =>
      openModal({
        title: t({ id: 'page25.payment.cancelling' }),
        icon: 'danger',
        clickOutsideToDismiss: false,
        actions: (
          <PaymentPageCancelModal
            onClick={async ({ confirmed }) => {
              closeModal();
              if (confirmed) {
                await removeJourney();
              }
              if (onConfirm) {
                onConfirm();
              }
            }}
          />
        ),
      }),
    [closeModal, onConfirm, openModal, removeJourney, t]
  );
  return deleteJourneyBookingConfirm;
}
