import {
  LoadableAttributeStore,
  loadableAttributeStoreFactory,
  SimpleStore,
} from 'lib-web-redux';

export type ApiClientStore = {
  authenticationToken: LoadableAttributeStore<string>;
  baseUrl: LoadableAttributeStore<string>;
  appClientId: LoadableAttributeStore<string>;
  appVersion: LoadableAttributeStore<string>;
};

export const apiClientStoreProvider = {
  get,
  init,
};

let store: ApiClientStore;

function get(): ApiClientStore {
  if (!store) {
    throw new Error('Call init method first to initialize ApiClientStore.');
  }
  return store;
}

function init(baseStore: SimpleStore<any>): ApiClientStore {
  store = {
    appClientId: loadableAttributeStoreFactory.create<string>(
      baseStore,
      ' api_appClientId'
    ),
    appVersion: loadableAttributeStoreFactory.create<string>(
      baseStore,
      ' api_appVersion'
    ),
    authenticationToken: loadableAttributeStoreFactory.create<string>(
      baseStore,
      ' api_authenticationToken'
    ),
    baseUrl: loadableAttributeStoreFactory.create<string>(
      baseStore,
      ' api_baseUrl'
    ),
  };
  return store;
}
