import { AppSelectOption, dataSorter } from '_01_CORE/_components-core';
import { JourneyActivitiesLocalState } from '_02_APP_COMMON/ACT-activity';
import { ACTIVITY_CATEGORIES, ActivityCategory } from 'lib-common-model';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

export function useAnimLiveJourneysActivitiesPageCriteriaBarOptions({
  localState,
}: {
  localState: JourneyActivitiesLocalState;
}) {
  const { formatMessage: t } = useIntl();

  const participantsOptions: AppSelectOption<string>[] = useMemo(() => {
    const options = (localState?.aggregatedData?.participants ?? []).map(
      (p) => {
        let label = `${p?.member?.lastName} ${p?.member?.firstName}`;

        if (p.attributes?.journeyAge > 0) {
          label += ` (${p.attributes.journeyAge} ${t({
            id: 'page02.age',
          })})`;
        }

        return {
          id: p?.member.customerChildId,
          label,
        };
      }
    );
    const allLabel = t({
      id: 'page16.form.select-participant.all-participants',
    }).toUpperCase();
    return [
      {
        id: null, // ('' as unknown) as ActivityCategory,
        label: `-- ${allLabel} -- `,
      },
    ].concat(options);
  }, [localState?.aggregatedData?.participants, t]);

  const activitiesOptions: AppSelectOption<string>[] = useMemo(() => {
    const options = dataSorter
      .sortMultiple(localState?.aggregatedData?.activities ?? [], {
        getSortAttributes: (a) => [
          {
            value: a?.translation?.name,
            type: 'full-text',
          },
          {
            value: a?.translation?.subTitle,
            type: 'full-text',
          },
        ],
      })
      .map((a) => {
        let label = `${a?.translation?.name}`;

        if (a?.translation?.subTitle?.trim()?.length) {
          label += ` - ${a?.translation?.subTitle}`;
        }

        return {
          id: a._id,
          label,
        };
      });
    const allLabel = t({
      id: 'page16.form.select-participant.all-activities',
    }).toUpperCase();
    return [
      {
        id: null, // ('' as unknown) as ActivityCategory,
        label: `-- ${allLabel} -- `,
      },
    ].concat(options);
  }, [localState?.aggregatedData?.activities, t]);

  const activityCategoriesOptions: AppSelectOption<ActivityCategory>[] = useMemo(() => {
    const options = ACTIVITY_CATEGORIES.map((categoryId) => {
      const label = t({
        id: `page16.title.anim-live.category-${categoryId}`,
      });

      return {
        id: categoryId,
        label,
      };
    });
    return options;
  }, [t]);

  return {
    participantsOptions,
    activitiesOptions,
    activityCategoriesOptions,
  };
}
