import { IonButton, IonInput } from '@ionic/react';
import {
  AppPage,
  BannerList,
  HeaderToolbar,
  PageContainer,
  PageGroupTitle,
} from '_01_CORE/_common-layout';
import { AppInputIonic } from '_01_CORE/_components-core/AppInput';
import { useAppRouter, useAppToasts } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import { useGroupCompaniesResumes } from '_01_CORE/app-contexts/app-cache-context';
import { companyApiClient } from '_01_CORE/app-core/app-api';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import { appLogger } from 'lib-web-logger';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useJourneyEditDataDraftOrRedirect } from '../JOU-10-journey-choose-dates';
import { AccountChooseCompanyBannerCard } from './AccountChooseCompanyBannerCard';

export const AccountChooseCompanyPage = React.memo(
  function CompanyGroupHomePage() {
    const appRouter = useAppRouter();
    const { pushError } = useAppToasts();

    const { formatMessage: t } = useIntl();

    const { appId } = appWebConfig();

    useJourneyEditDataDraftOrRedirect();

    const companyList = useGroupCompaniesResumes();

    const [
      companyDestinationCodeInput,
      setCompanyDestinationCodeInput,
    ] = useState<string>(
      appWebConfig().authentication.defaultAuthLogin?.companyCode || undefined
    );

    const navigateToStep1 = useCallback(
      (companyReference) => {
        //If we reach this code, the company code is found

        const accountCreateStep1 = appRoutesBuilder.getJourneyDatesEditUrl({
          companyReference,
        });
        appRouter.navigate(accountCreateStep1, {
          cause: 'choose-company-step1',
        });
        return true;
      },
      [appRouter]
    );

    /**
     * Navigate select company and navigate to step 1 choose dates
     */
    const onClickCompany = useCallback(
      (companyReference: string) => {
        const companyReferenceUpperCase = companyReference?.toUpperCase();
        const company = companyList.find(
          (c) => c.reference?.toUpperCase() === companyReferenceUpperCase
        );
        if (company) {
          navigateToStep1(companyReferenceUpperCase);
        } else {
          companyApiClient
            .testCompanyCode({
              code: companyReferenceUpperCase,
            })
            .then(
              () => {
                navigateToStep1(companyReferenceUpperCase);
              },
              (err) => {
                appLogger.warn('companyReference:', { companyReference });
                appLogger.error('Company code choose error', { err });
                pushError(t({ id: 'page11.error' }));
              }
            )
            .catch((reason) => {
              appLogger.warn('companyReference:', { companyReference });
              appLogger.error('Company code choose error', reason);
              pushError(t({ id: 'page11.error' }), 2000);
            });
        }
      },
      [companyList, navigateToStep1, pushError, t]
    );
    return !companyList ? null : (
      <AppPage tabId="bookings">
        <HeaderToolbar hideLogout={true} />
        <PageContainer className="text-center bg-gray-50" spacing="normal">
          <PageGroupTitle
            title={t({ id: `page11.title.${appId}` })}
            backLink={appRoutesBuilder.getLoginHomeUrl()}
          />
          <BannerList>
            {companyList.map((company) => (
              <AccountChooseCompanyBannerCard
                key={company._id}
                company={company}
                onClick={() => onClickCompany(company.reference)}
              />
            ))}
          </BannerList>

          {appId === 'anim-live' && (
            <>
              {/*Enter destination company code manually*/}
              <div className="mt-8">
                {t({ id: 'page11.destination.label' })}
              </div>
              <div className="mb-2 max-w-xs m-auto">
                <AppInputIonic
                  placeholder={t({ id: 'page11.destination.placeholder' })}
                  value={companyDestinationCodeInput}
                >
                  <IonInput
                    onIonChange={(e) =>
                      setCompanyDestinationCodeInput(e.detail.value)
                    }
                  />
                </AppInputIonic>
              </div>

              {/* Action submit buttons */}
              <IonButton
                className=""
                onClick={() => onClickCompany(companyDestinationCodeInput)}
                shape="round"
                color="primary"
              >
                {t({ id: 'common.action.submit' })}
              </IonButton>
            </>
          )}
        </PageContainer>
      </AppPage>
    );
  }
);
