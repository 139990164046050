import { AppIcons, IssuesIcons } from '_01_CORE/_components-core';
import { IssueBaseCategory } from './IssueBaseCategory.type';
import { IssueCategory } from './IssueCategory.type';

export const ISSUE_TECHNICAL_CATEGORY2_ITEMS: IssueBaseCategory[] = [
  {
    id: 'electricity',
    label: 'Électricité',
    label2: `Lumière, électricité...`,
    icon: IssuesIcons.electricity,
  },
  {
    id: 'water',
    label: 'Eau',
    label2: "Plus d'eau, fuite...",
    icon: IssuesIcons.water,
  },
  {
    id: 'gas',
    label: 'Gaz',
    label2: 'Bouteille vide...',
    icon: IssuesIcons.gas,
  },
  {
    id: 'other',
    label: 'Autre problème',
    label2: undefined,
    icon: AppIcons.other,
    isOther: true,
  },
];

export const ISSUE_CATEGORIES: IssueCategory[] = [
  {
    id: 'technical',
    label: 'Problème technique',
    label2: `Électricité, eau, gaz...`,
    icon: IssuesIcons.technical,
    children: ISSUE_TECHNICAL_CATEGORY2_ITEMS,
  },
  {
    id: 'cleanliness',
    label: 'Propreté',
    label2: undefined,
    icon: IssuesIcons.cleanliness,
    children: [],
  },
  {
    id: 'missing-equipment',
    label: 'Équipement manquant',
    label2: 'Kit bébé, draps, serviettes...',
    icon: IssuesIcons.missingEquipment,
    children: [],
  },
  {
    id: 'other',
    label: 'Autre demande',
    label2: undefined,
    icon: AppIcons.other,
    children: [],
    isOther: true,
  },
];
