import {
  AppPage,
  HeaderToolbar,
  PageContainer,
  PageTitle,
  useAppTheme,
} from '_01_CORE/_common-layout';
import { AppTranslationContentButton } from '_01_CORE/_components-core';
import { useAppRouter } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import { useCompany } from '_01_CORE/app-contexts';
import { useAppPlatform } from '_01_CORE/app-core/app-platform';
import { useAppImageUrl } from '_01_CORE/app-core/app-security/useAppImageUrl.hook';
import { appLogger } from 'lib-web-logger';
import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';

export const CompanyContactPage = () => {
  const appRouter = useAppRouter();

  const { companyReference } = useParams<{ companyReference: string }>();

  const { company } = useCompany({ companyReference });
  const { formatMessage: t } = useIntl();

  const { platform } = useAppPlatform();

  const logoUrl = useAppImageUrl(company?.logo, {
    defaultImage: 'app',
  });
  const locationSearchString = useMemo<string>(
    () =>
      `${company?.direction?.line1}, ${company?.direction?.zipCode} ${company?.direction?.city}, ${company?.direction?.country}`,
    [company]
  );

  useEffect(() => {
    if (!company) {
      appLogger.info(
        '[CompanyContactPage] No company in context: redirect to /journey'
      );
      appRouter.navigateAfterDelay(appRoutesBuilder.getRedirectToDefaultUrl(), {
        cause: 'company-contact-company-missing',
      });
    }
  }, [appRouter, company]);

  const theme = useAppTheme({ companyReference });

  return company ? (
    <AppPage>
      <HeaderToolbar />
      <PageContainer className="text-center bg-gray-50" spacing="normal">
        <PageTitle
          title={t({ id: 'page02.contactButton' })}
          backLink="/journeys"
        />
        <div className="flex flex-col text-left items-center mx-auto w-full sm:w-3/4 md:w-1/2">
          <div className="mb-2">
            {company?.logo?.xs && (
              <img className="max-h-24 w-auto" src={logoUrl} />
            )}
          </div>
          <div className="w-full max-w-xs">
            {company?.name && <p className="font-bold">{company?.name}</p>}
            <div className="italic text-sm">
              {company?.direction?.line1 && <p>{company?.direction.line1}</p>}
              {company?.direction?.zipCode && (
                <p>
                  {company?.direction.zipCode} {company?.direction?.city}
                </p>
              )}
              {company?.direction?.country && (
                <p>{company?.direction?.country}</p>
              )}
            </div>
            <div className="text-sm font-bold mt-2 mb-5">
              {company?.contactInformation?.email && (
                <p>{company?.contactInformation?.email}</p>
              )}
              {company?.contactInformation?.phone && (
                <p>{company?.contactInformation?.phone}</p>
              )}
            </div>

            {company?.contactInformation?.phone && (
              <AppTranslationContentButton
                theme={theme}
                button={{
                  type: 'phone',
                  label: t({ id: 'page05.contact' }).toUpperCase(),
                  value: company?.contactInformation?.phone,
                }}
                className="my-1 font-bold w-full rounded-full"
              />
            )}
            {company?.contactInformation?.email && (
              <AppTranslationContentButton
                theme={theme}
                button={{
                  type: 'email',
                  label: t({ id: 'page05.email' }).toUpperCase(),
                  value: company?.contactInformation?.email,
                }}
                className="my-1 font-bold w-full rounded-full"
              />
            )}
            <AppTranslationContentButton
              theme={theme}
              button={{
                type: 'direction',
                label: t({ id: 'page05.plan' }).toUpperCase(),
                value: locationSearchString,
              }}
              className="my-1 font-bold w-full rounded-full"
            />
          </div>
        </div>
      </PageContainer>
    </AppPage>
  ) : null;
};
