import { CustomerJourney } from '../../entities';

export const defaultJourneySelector = {
  findJourneyToDisplay,
};

function findJourneyToDisplay<
  J extends Pick<
    CustomerJourney,
    '_id' | 'beginDateUTC' | 'endDateExclusiveUTC'
  >
>(journeys: J[]): J {
  if (!journeys) {
    return undefined;
  }
  const now = new Date();
  const sortedJourneys = sortJourneysByDate(journeys);
  let journeyToDisplay: J;

  for (const j of sortedJourneys) {
    journeyToDisplay = j;
    if (
      isWithinInterval(now, {
        end: j.endDateExclusiveUTC,
        start: j.beginDateUTC,
      })
    ) {
      // select first matching current journey
      break;
    } else if (isFuture(j.beginDateUTC, now)) {
      // else select next journey
      break;
    } else if (isPast(j.endDateExclusiveUTC, now)) {
      // else select last journey
    }
  }
  return journeyToDisplay;
}

function isWithinInterval(
  date: Date,
  {
    start,
    end,
  }: {
    start: Date;
    end: Date;
  }
) {
  return isFuture(start, date) && isPast(date, end);
}

function isFuture(date: Date, now: Date = new Date()) {
  return new Date(date).getTime() > new Date(now).getTime();
}

function isPast(date: Date, now: Date = new Date()) {
  return new Date(date).getTime() < new Date(now).getTime();
}

function sortJourneysByDate<
  J extends Pick<
    CustomerJourney,
    '_id' | 'beginDateUTC' | 'endDateExclusiveUTC'
  >
>(journeys: J[]): J[] {
  if (!journeys) {
    return undefined;
  }
  return journeys.sort((a, b) => {
    return a.beginDateUTC < b.beginDateUTC
      ? -1
      : a.beginDateUTC > b.beginDateUTC
      ? 1
      : a.endDateExclusiveUTC < b.endDateExclusiveUTC
      ? -1
      : a.endDateExclusiveUTC > b.endDateExclusiveUTC
      ? 1
      : a._id < b._id
      ? 1
      : a._id > b._id
      ? -1
      : 0;
  });
}
