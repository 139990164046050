import { IonInput } from '@ionic/react';
import { PositioningStrategy } from '@popperjs/core';
import { format as formatDate } from 'date-fns';
import React, { useContext, useRef } from 'react';
import { Manager, Popper, Reference } from 'react-popper';
import {
  AppDatePickerCtx,
  AppDatePickerCtxProps,
  useDatePickerCtx,
} from './AppDatePickerContext';
import { AppDateSelection } from './AppDateSelection';
import { AppMonthSelection } from './AppMonthSelection';
import { AppYearSelection } from './AppYearSelection';

type DatePickerProps = AppDatePickerCtxProps;

export const AppDatePicker: React.FC<DatePickerProps> = (props) => (
  <RawDatePicker {...props}></RawDatePicker>
);

export const RawDatePicker: React.FC<DatePickerProps> = (props) => {
  const popupNode = useRef<HTMLElement>();
  const ctxValue = useDatePickerCtx({ ...props, ref: popupNode });
  const value =
    !ctxValue.date || isNaN(ctxValue.date.getTime())
      ? ''
      : formatDate(ctxValue.date, ctxValue.dateFormat);
  const strategy: PositioningStrategy = 'fixed';
  return (
    <AppDatePickerCtx.Provider value={ctxValue}>
      <Manager>
        <Reference>
          {({ ref }) => (
            <IonInput
              ref={ref}
              onFocus={() => ctxValue.showCalendar()}
              type="text"
              value={value}
              placeholder={ctxValue.placeholder}
              readonly={true}
            />
            // <input ref={ref} onFocus={() => ctxValue.showCalendar()} type="text" value={value} placeholder={ctxValue.placeholder} readOnly={true} />
          )}
        </Reference>
        <Popper
          strategy={strategy}
          placement="bottom-start"
          innerRef={(node) => (popupNode.current = node)}
        >
          {({ ref, style, placement }) =>
            ctxValue.isVisible ? (
              <Calendar
                placement={placement}
                style={{
                  ...style,
                  zIndex: 100,
                }}
                ref={ref as React.Ref<HTMLDivElement>}
              />
            ) : null
          }
        </Popper>
      </Manager>
    </AppDatePickerCtx.Provider>
  );
};

interface CalendarProps {
  style: React.CSSProperties;
  placement: string;
  ref: React.Ref<HTMLDivElement>;
}

const Calendar: React.FC<CalendarProps> = React.forwardRef<
  HTMLDivElement,
  CalendarProps
>((props, ref) => {
  const { view } = useContext(AppDatePickerCtx);

  let selectionComponent = null;
  switch (view) {
    case 'date':
      selectionComponent = <AppDateSelection />;
      break;
    case 'month':
      selectionComponent = <AppMonthSelection />;
      break;
    case 'year':
      selectionComponent = <AppYearSelection />;
      break;
  }

  return (
    <div
      className={`select-none bg-white relative shadow-lg p-2 rounded-lg z-10 max-w-xs w-80`}
      ref={ref}
      data-placement={props.placement}
      style={props.style}
    >
      {selectionComponent}
    </div>
  );
});
Calendar.displayName = 'Calendar';

export const buttonClassName =
  'hover:bg-gray-200 rounded p-1 text-sm flex align-center justify-center focus:outline-none';
