import { Language, LanguageCode, LANGUAGES_ARRAY } from 'lib-common-model';
import { appLogger } from 'lib-web-logger';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import localeEN from 'translations/en.json';
import localeFR from 'translations/fr.json';
import localeSV from 'translations/se.json';
import { appLanguageCodeReader } from '_01_CORE/app-core/app-platform';
import { CUSTOMERS_APP_LANGUAGES } from './CUSTOMERS_APP_LANGUAGES.const';

export const localizations = {
  en: localeEN,
  fr: localeFR,
  sv: localeSV,
};

export type CustomerAppLanguage = keyof typeof localizations;

export const languageName = {
  en: 'English',
  fr: 'Français',
  sv: 'Svenska',
};

export type AppLocalizationParams = {
  defaultLang: CustomerAppLanguage;
};

export type AppLocalizationContextLocales = {
  locale: CustomerAppLanguage;
  appLanguageCode: LanguageCode;
  platformLanguageCode: LanguageCode;
};

export type AppLocalizationContext = {
  appLanguage: Language;
  locales: AppLocalizationContextLocales;
  switchLanguage: (l: LanguageCode) => AppLocalizationContextLocales;
  messages: Record<string, string>;
};

const defaultLocales = buildLocalesFromPlatformLanguage(navigator?.language);

const AppLocalizationContext = createContext<AppLocalizationContext>({
  appLanguage: CUSTOMERS_APP_LANGUAGES.find(
    (x) => x.ietfBcp45 === defaultLocales?.locale
  ),
  locales: defaultLocales,
  switchLanguage: () => defaultLocales,
  messages: {},
});

function useAppLocalization(): AppLocalizationContext {
  const defaultLocales = useMemo(
    () => buildLocalesFromPlatformLanguage(navigator?.language),
    []
  );
  const [locales, setLocales] = useState<AppLocalizationContextLocales>(
    defaultLocales
  );
  const messages = useMemo(() => localizations[locales.locale] || localeEN, [
    locales.locale,
  ]);

  const appLanguage = useMemo(
    () => CUSTOMERS_APP_LANGUAGES.find((x) => x.ietfBcp45 === locales?.locale),
    [locales?.locale]
  );

  useEffect(() => {
    appLanguageCodeReader
      .getPlatformLanguageCode()
      .then((initialLocale) => {
        const updatedLocales: AppLocalizationContextLocales = buildLocalesFromPlatformLanguage(
          initialLocale
        );
        setLocales(updatedLocales);
      })
      .catch((err) => {
        appLogger.error('Error initializing platform language', err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const switchLanguage = useCallback(
    (languageCode: LanguageCode): AppLocalizationContextLocales => {
      const appLanguage: CustomerAppLanguage = convertLanguageToAppLocale(
        languageCode
      );

      const locales: AppLocalizationContextLocales = {
        locale: appLanguage,
        appLanguageCode: languageCode,
        platformLanguageCode: languageCode,
      };
      setLocales(locales);
      return locales;
    },
    []
  );

  return {
    appLanguage,
    locales,
    switchLanguage,
    messages,
  };
}

export const AppLocalizationContextProvider: React.FC = ({ children }) => {
  return (
    <AppLocalizationContext.Provider value={useAppLocalization()}>
      {children}
    </AppLocalizationContext.Provider>
  );
};

function buildLocalesFromPlatformLanguage(initialLocale: string) {
  const platformLocale = (initialLocale ?? 'en').toLowerCase();
  const language = convertPlatformLocaleToLanguageCode(platformLocale);
  const locale = convertPlatformLocaleToAppLocale(platformLocale);
  const updatedLocales: AppLocalizationContextLocales = {
    locale,
    appLanguageCode: language,
    platformLanguageCode: language,
  };
  return updatedLocales;
}

export function useAppLocalizationContext() {
  return useContext(AppLocalizationContext);
}

function convertPlatformLocaleToLanguageCode(locale: string): LanguageCode {
  locale = locale?.toLowerCase().split('-')[0];

  const language = LANGUAGES_ARRAY.find((x) => x.iso6391 === locale);

  return language?.id;
}

function convertPlatformLocaleToAppLocale(locale: string): CustomerAppLanguage {
  switch (locale.substr(0, 2)) {
    case 'fr':
      return 'fr';
    case 'sv':
      return 'sv';
    default:
      return 'en';
  }
}

function convertLanguageToAppLocale(
  languageCode: LanguageCode
): CustomerAppLanguage {
  switch (languageCode) {
    case 'fre/fra':
      return 'fr';
    case 'swe':
      return 'sv';
    default:
      return 'en';
  }
}
