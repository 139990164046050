import { CMJourneyEditData, CMJourneyEditParticipant } from 'lib-common-model';
import { useMemo } from 'react';
import { useAppCacheContext } from '_01_CORE/app-contexts';
import { journeyHelper } from '_01_CORE/_services';
import { CustomerAccountEditFormData } from '_02_APP_COMMON/_model';

export const useAnimLiveCustomerAccountEditDefaultValues = ({
  journeyEditData,
}: {
  journeyEditData: CMJourneyEditData;
}) => {
  const { auth } = useAppCacheContext();

  return useMemo<Partial<CustomerAccountEditFormData>>(() => {
    const firstParticipant: CMJourneyEditParticipant = journeyEditData
      ?.participants.length
      ? journeyHelper.sortEditParticipants(journeyEditData.participants)[0]
      : undefined;
    const ca =
      auth?.customerProfile?.account ??
      (journeyEditData.customerAccount &&
      Object.values(journeyEditData.customerAccount).filter((x) => !!x).length >
        0
        ? journeyEditData.customerAccount
        : undefined); // si pas connecté, il peut y avoir des infos de pré-renseignées dans le cache si import externe
    if (!ca && firstParticipant) {
      const formData: Partial<CustomerAccountEditFormData> = {
        lastName: firstParticipant.member.lastName,
        firstName: undefined,
        country: firstParticipant?.member?.country,
        language: firstParticipant?.member?.language1,
      };
      return formData;
    } else if (ca) {
      const formData: Partial<CustomerAccountEditFormData> = {
        lastName: ca?.lastName,
        firstName: ca?.firstName,
        country: ca?.country,
        language: ca?.language,
        email: ca?.email,
        gender: ca?.gender,
        phone1: ca?.phone1,
        phone2: ca?.phone2,
        locationArea: ca?.locationArea,
        consent: !!ca?.lastName,
      };
      return formData;
    }
  }, [
    auth?.customerProfile?.account,
    journeyEditData.customerAccount,
    journeyEditData.participants,
  ]);
};
