import { BookletTheme } from 'lib-common-model';
import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router';
import { useCompany, useCompanyBooklet } from '_01_CORE/app-contexts';
import { imageUrlBuilder } from '_01_CORE/app-core/app-api';
import {
  AppPage,
  BannerCard,
  BannerList,
  CompanyPresentation,
  HeaderToolbar,
  PageCompanyTitle,
  PageContainer,
} from '_01_CORE/_common-layout';
import { useAppRouter } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';

export const CompanyBookletPage = React.memo(function CompanyBookletPage() {
  const { companyReference } = useParams<{ companyReference: string }>();

  const booklet = useCompanyBooklet({ companyReference });

  const { company } = useCompany({ companyReference });

  const appRouter = useAppRouter();

  /**
   * Navigation to a select company's activity,
   * useCallback caches the code until param deps appRouter or companyReference changes and refreshes the route
   */
  const onSelectTheme = useCallback(
    (theme: BookletTheme) => {
      if (theme.sheets.length === 1) {
        const companyBookletThemeUrl = appRoutesBuilder.getCompanyBookletThemeSheetUrl(
          {
            companyReference,
            bookletThemeReference: theme.reference,
            bookletSheetReference: theme.sheets[0].reference,
          }
        );

        appRouter.navigate(companyBookletThemeUrl, {
          cause: 'company-boklet-select-theme-unique-sheet',
        });
      } else {
        const companyBookletThemeUrl = appRoutesBuilder.getCompanyBookletThemeUrl(
          {
            companyReference,
            bookletThemeReference: theme.reference,
          }
        );

        appRouter.navigate(companyBookletThemeUrl, {
          cause: 'company-boklet-select-theme',
        });
      }
    },
    [companyReference, appRouter]
  );

  const companyHomeUrl = useMemo(
    () =>
      appRoutesBuilder.getCompanyBookletUrl({
        companyReference,
      }),
    [companyReference]
  );

  return (
    <AppPage tabId="home">
      <HeaderToolbar />
      <PageContainer
        className="text-center bg-gray-50"
        spacing="normal-no-border"
      >
        <PageCompanyTitle
          company={company}
          displayAddress={false}
          backLink={companyHomeUrl}
        />
        {booklet && (
          <>
            <CompanyPresentation
              companyReference={company.reference}
              booklet={booklet}
            />
            <BannerList>
              {booklet.themes
                .filter((x) => x.visible)
                .map((theme) => {
                  const imageUrl = imageUrlBuilder.getImageFullUrl({
                    image: theme.image?.xl,
                    defaultUrl: '/assets/images/empty.png',
                  });
                  return (
                    <BannerCard
                      reference={theme.reference}
                      key={theme.reference}
                      image={imageUrl}
                      title={theme.title}
                      onClick={() => onSelectTheme(theme)}
                    />
                  );
                })}
            </BannerList>
          </>
        )}
      </PageContainer>
    </AppPage>
  );
});
