import { useEffect } from 'react';
import { useAppCacheContext } from '_01_CORE/app-contexts';
import { useAppRouter } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';

// TODO remplacer par un guard
export function useJourneyEditDataDraftOrRedirect() {
  const appRouter = useAppRouter();
  const {
    auth,
    dispatchCacheContextAction,
    journeyEditData,
  } = useAppCacheContext();

  useEffect(() => {
    const customerJourneyId = journeyEditData?.customerJourneyId;
    if (customerJourneyId) {
      const journey = auth?.customerProfile?.journeys.find(
        (j) => j._id === customerJourneyId
      );
      if (journey && journey.status !== 'draft') {
        dispatchCacheContextAction({
          type: 'clear-update-journey-cache',
          context: 'useJourneyEditDataDraftOrRedirect',
        });
        appRouter.navigateAfterDelay(
          appRoutesBuilder.getJourneyHomeUrl({ customerJourneyId }),
          { cause: `Invalid journey statuts ${journey.status}` }
        );
      }
    }
  }, [
    appRouter,
    auth?.customerProfile?.journeys,
    dispatchCacheContextAction,
    journeyEditData?.customerJourneyId,
  ]);
}
