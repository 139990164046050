import { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import React from 'react';

export const GenderMale = (props: IonicReactProps) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1280 1280"
      preserveAspectRatio="xMidYMid meet"
      {...props}
      x="0px"
      y="0px"
    >
      <g
        transform="translate(0,1280) scale(0.1,-0.1)"
        fill="currentColor"
        stroke="none"
      >
        <path
          d="M6235 10749 c-330 -22 -742 -100 -1025 -195 -127 -42 -305 -126 -391
-185 -81 -54 -170 -145 -194 -198 -10 -23 -25 -41 -34 -41 -35 0 -255 -82
-352 -130 -384 -193 -625 -473 -724 -842 -46 -171 -58 -335 -52 -693 6 -302
15 -440 52 -770 36 -311 106 -760 185 -1180 21 -115 49 -271 61 -345 106 -665
428 -1361 873 -1885 l66 -77 0 -280 c0 -234 -2 -279 -14 -275 -13 5 -51 -37
-210 -225 -82 -98 -351 -295 -616 -453 -164 -97 -468 -250 -653 -329 -178 -75
-448 -176 -868 -327 -562 -201 -826 -308 -1114 -449 -300 -147 -491 -276 -656
-440 -149 -149 -218 -266 -298 -506 -72 -217 -85 -300 -85 -539 1 -167 5 -228
20 -295 l19 -85 6147 -3 6147 -2 11 41 c19 69 40 249 40 344 0 220 -57 478
-159 715 -91 211 -257 389 -526 566 -322 211 -638 349 -1570 688 -686 248
-821 304 -1163 475 -351 176 -725 397 -822 486 -60 54 -219 185 -314 255 -53
40 -59 43 -71 27 -7 -9 -15 -17 -19 -17 -3 0 -6 126 -6 281 l0 280 86 97 c447
503 783 1197 904 1862 11 63 27 127 35 141 25 45 111 317 153 484 179 703 196
1368 51 1955 -102 411 -283 776 -552 1110 -107 133 -333 354 -458 448 -517
386 -1165 561 -1904 511z m2744 -3814 c0 -82 -4 -157 -9 -165 -6 -10 -9 58 -9
200 1 143 4 198 9 165 5 -27 9 -117 9 -200z"
        />
      </g>
    </svg>
  );
};
