import {
  useAppCacheContext,
  useAppLocalizationContext,
} from '_01_CORE/app-contexts';
import { customerNotificationsApiClient } from '_01_CORE/app-core/app-api/customerNotificationsApiClient.service';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import { authenticationStore } from '_01_CORE/app-core/app-security';
import { appStore } from '_01_CORE/app-core/app-store';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNotificationState } from './useNotificationState.hook';

// NOTE: le routeur n'est pas encore initialisé, donc ne pas utiliser le routeur, mais passer par "appContext.redirectTo"

export const NotificationsProvider: React.FC = ({ children }) => {
  const { formatMessage: t } = useIntl();

  const enableNotificationsDebug = useMemo(() => {
    const envId = appWebConfig().envId;
    return envId === 'beta' || envId === 'alpha' || envId === 'dev';
  }, []);

  const { notificationsState, initNotifications } = useNotificationState({
    enableNotificationsDebug,
  });

  //Get current context
  const appCacheContext = useAppCacheContext();
  const {
    dispatchCacheContextAction,
    notificationToDisplay,
  } = appCacheContext ?? {
    dispatchCacheContextAction: () => {
      //
    },
  };
  useEffect(() => {
    if (notificationsState) {
      if (enableNotificationsDebug) {
        console.log('[NotificationsProvider] update notifications state');
        console.log(
          '[NotificationsProvider] update notifications state: ',
          notificationsState
        );
      }
      appStore.notificationsState.set(
        notificationsState,
        'notificationsState updated'
      );
    }
  }, [enableNotificationsDebug, notificationsState]); // NOTE: ajouter ici des dépendances vers AppToast bloque l'application déployée

  useEffect(() => {
    if (
      notificationsState?.registrationState?.success &&
      notificationsState.registrationState?.token?.value
    ) {
      if (enableNotificationsDebug) {
        console.log('[NotificationsProvider] update token');
      }
      const token = notificationsState.registrationState?.token?.value?.replace(
        /"/g,
        ''
      );
      if (enableNotificationsDebug) {
        console.log('[NotificationsProvider] update clean token: ', token);
      }
      appStore.deviceToken.set(token, 'registration success');
    }
  }, [
    enableNotificationsDebug,
    notificationsState.registrationState?.success,
    notificationsState.registrationState?.token?.value,
  ]);

  // const { pushSuccess } = useAppToasts();

  const [
    alreadyDisplayedNotificationsIds,
    setAlreadyDisplayedNotificationsIds,
  ] = useState<string[]>([]);

  // const [
  //   notificationToDisplay,
  //   setNotificationToDisplay,
  // ] = useState<AppClientNotification>(null);

  // const notificationToDisplay = useMemo(() => {
  //   const lastNotification = notificationsState?.lastNotification;
  //   return (
  //     lastNotification &&
  //     !alreadyDisplayedNotificationsIds.includes(lastNotification.notification)
  //   );
  // }, [notificationsState?.lastNotification]);

  const {
    locales: { platformLanguageCode, appLanguageCode },
  } = useAppLocalizationContext();

  useEffect(() => {
    if (notificationsState?.lastActionPerformed?.notification) {
      // background notification received
      // test if current url is /notifications
      const path = window.location.pathname;
      const notificationsPageUrl = '/notifications';
      if (path !== notificationsPageUrl) {
        // redirect to notifications page
        dispatchCacheContextAction({
          type: 'redirect-to',
          path: notificationsPageUrl,
          context: 'notification-background-received',
        });
      }
    }
  }, [
    dispatchCacheContextAction,
    notificationsState?.lastActionPerformed?.notification,
  ]);

  useEffect(() => {
    if (
      notificationsState?.lastNotification?.id &&
      authenticationStore.auth.getSnapshot()?.isAuthenticated &&
      authenticationStore.auth.getSnapshot()?.securityUser?.profile ===
        'customer'
    ) {
      // update notifications list
      (async () => {
        if (!notificationToDisplay) {
          let notifications = await customerNotificationsApiClient.getLastNotifications(
            { platformLanguageCode, appLanguageCode }
          );
          if (enableNotificationsDebug) {
            console.log(
              '[NotificationsProvider] update notifications: ',
              notifications.length
            );
          }
          const unreadNotification = notifications.find((x) => !x.readDate);
          if (
            !alreadyDisplayedNotificationsIds.includes(
              notificationsState?.lastNotification?.data?.messageId
            )
          ) {
            const selectedNotification = notifications.find(
              (x) =>
                x.messageId ===
                notificationsState?.lastNotification?.data?.messageId
            );
            if (selectedNotification) {
              // setNotificationToDisplay(selectedNotification);
              dispatchCacheContextAction({
                type: 'set-notification-to-display',
                notificationToDisplay: selectedNotification,
                context: 'notification-received',
              });
              setAlreadyDisplayedNotificationsIds([
                ...alreadyDisplayedNotificationsIds,
                notificationsState?.lastNotification?.data?.messageId,
              ]);
              const notification = await customerNotificationsApiClient.readNotification(
                {
                  messageId:
                    notificationsState?.lastNotification?.data?.messageId,
                  platformLanguageCode,
                  appLanguageCode,
                }
              );
              notifications = notifications.filter((x) =>
                x.messageId === selectedNotification.messageId
                  ? notification
                  : x
              );
            }
          }
          dispatchCacheContextAction({
            type: 'set-notifications',
            notifications,
            context: 'fetch-notif-page',
            forceUnreadNotification: !!unreadNotification,
          });
        }
      })();
    }
  }, [
    alreadyDisplayedNotificationsIds,
    appLanguageCode,
    dispatchCacheContextAction,
    enableNotificationsDebug,
    notificationToDisplay,
    notificationsState?.lastNotification?.data?.messageId,
    notificationsState?.lastNotification?.id,
    platformLanguageCode,
  ]); // NOTE: ajouter ici des dépendances vers AppToast bloque l'application déployée

  // useEffect(() => {
  //   if (notificationToDisplay) {
  //     openModal({
  //       title: notificationToDisplay.title,
  //       content: (
  //         <div>
  //           {/* <NotificationItem
  //             showTitle={false}
  //             showOpenActivityButton={false}
  //             notification={notificationToDisplay}
  //             onClick={onClickNotification}
  //           />  */}
  //           <NotificationMessageDetailsDialog
  //             notification={notificationToDisplay}
  //             onClose={() => {
  //               onClickNotification(notificationToDisplay);
  //             }}
  //           />
  //         </div>
  //       ),
  //       actions: (
  //         <div className="w-full">
  //           <AppButtonUniverse
  //             className="w-full"
  //             style="primary"
  //             icon={AppIcons.check}
  //             onClick={async () => {
  //               handleDismiss();
  //               closeModal();
  //             }}
  //           >
  //             {t({ id: 'common.action.ok' })}
  //           </AppButtonUniverse>
  //         </div>
  //       ),
  //     });
  //   }
  // }, [
  //   closeModal,
  //   handleDismiss,
  //   notificationToDisplay,
  //   onClickNotification,
  //   openModal,
  //   t,
  // ]);

  useEffect(() => {
    if (notificationsState?.listenersRegistered) {
      initNotifications();
    }
  }, [initNotifications, notificationsState?.listenersRegistered]);

  return (
    <>
      {children}

      {/* <IonAlert
        buttons={['OK']}
        header={
          notificationToDisplay?.title ||
          t({ id: 'provider.notifications.alertHeader' })
        }
        isOpen={!!notificationToDisplay}
        message={notificationToDisplay?.body}
        onDidDismiss={handleDismiss}
      /> */}
    </>
  );
};
