import { AppIcons, dateTransformer } from '_01_CORE/_components-core';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import {
  APP_CURRENCIES_MAP,
  ActivityTranslation,
  CMJourneyActivitySessionActivity,
  CompanyOptions,
} from 'lib-common-model';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { JourneyDailyActivityAvailability } from '../../JourneyDailyActivityAvailability';
import { JourneyDailyActivityCardContext } from '../../JourneyDailyActivityCardContext.type';
import {
  CustomerActivityParticipantsDailyActivity,
  CustomerActivityParticipantsDailyActivitySession,
} from '../../model';
import { ActivityTitleCardLocation } from './ActivityTitleCardLocation';
import { ActivityTitleCardSession } from './ActivityTitleCardSession.type';
import { SessionRelativeTimeState } from './SessionRelativeTimeState.type';
import { sessionRelativeTimeStateBuilder } from './sessionRelativeTimeStateBuilder.service';

export const ActivityTitleCard = ({
  activity,
  selectedSession,
  activityTranslation,
  context,
  companyOptions,
  dailyActivity,
  sessionResume,
  className,
}: {
  activity: CMJourneyActivitySessionActivity;
  selectedSession: CustomerActivityParticipantsDailyActivitySession;
  activityTranslation: ActivityTranslation;
  context: JourneyDailyActivityCardContext;
  companyOptions: CompanyOptions;
  dailyActivity: CustomerActivityParticipantsDailyActivity;
  sessionResume: ActivityTitleCardSession;
  className?: string;
}) => {
  const appId = appWebConfig().appId;
  const { formatMessage: t } = useIntl();

  const relativeTimeState: SessionRelativeTimeState = useMemo(
    () => sessionRelativeTimeStateBuilder.build(sessionResume),
    [sessionResume]
  );
  const { minPrice, maxPrice } = useMemo(() => {
    const prices = sessionResume?.registrationFeePriceRanges
      .filter((x) => x.amount >= 0)
      .map((x) => x.amount);
    if (prices.length) {
      const minPrice = Math.min(...prices);
      const maxPrice = Math.max(...prices);
      return { minPrice, maxPrice };
    }
    return { minPrice: undefined, maxPrice: undefined };
  }, [sessionResume?.registrationFeePriceRanges]);

  const currency = companyOptions?.payments?.currency;

  return (
    <div className={className}>
      <div
        className={`${
          sessionResume.openingStatus === 'cancelled'
            ? 'text-red-600 italic'
            : sessionResume.openingStatus === 'close'
            ? 'text-gray-400 italic'
            : ''
        } ${
          relativeTimeState === 'past' &&
          (sessionResume.openingStatus === 'cancelled' ||
            sessionResume.openingStatus === 'close')
            ? 'opacity-50'
            : ''
        }`}
      >
        <div
          className={`max-w-full uppercase font-bold text-xs sm:text-sm md:text-base leading-snug sm:leading-snug md:leading-snug`}
        >
          {activityTranslation.name}
          {sessionResume.duration ? (
            <span className="ml-1 font-normal">
              (
              {dateTransformer.formatDuration(sessionResume.duration, {
                hoursLabel: t({ id: 'page16.session.duration.hour' }),
              })}
              )
            </span>
          ) : null}
        </div>
        <div
          className={`max-w-full uppercase font-bold text-app-xxs1 md:text-sm flex flex-wrap gap-x-2 items-center leading-snug sm:leading-snug md:leading-snug `}
        >
          {activityTranslation.subTitle?.trim()?.length > 0 && (
            <div>
              <span>{activityTranslation.subTitle}</span>
              {dailyActivity.activitySchedule.name?.trim()?.length > 0 && (
                <span>: </span>
              )}
            </div>
          )}
          {dailyActivity.activitySchedule.name && (
            <div>{dailyActivity.activitySchedule.name} </div>
          )}
        </div>
        <div
          className={`max-w-full uppercase font-bold text-app-xxs1 md:text-sm flex flex-wrap gap-x-1 items-center leading-snug sm:leading-snug md:leading-snug `}
        >
          {appId === 'anim-live' && context == 'list' && maxPrice > 0 && (
            <div className="flex gap-1 items-center">
              <div
                className={`relative justify-center items-center rounded font-bold bg-gray-500 text-white py-0.5 px-1.5`}
              >
                {minPrice !== maxPrice && `${minPrice}-`}
                {maxPrice} {APP_CURRENCIES_MAP[currency]?.symbol}
              </div>
              {dailyActivity.activitySchedule.payment
                ?.appOnlinePaymentEnabled ? (
                <AppIcons.paymentOnline className="w-5 h-5 rounded text-blue-400" />
              ) : (
                <AppIcons.paymentOnSite className="w-5 h-5 rounded text-green-400" />
              )}
            </div>
          )}
          {(sessionResume.companyClubMinAge !== 0 ||
            sessionResume.companyClubMaxAge !== 99) && (
            <span>
              {sessionResume.companyClubMaxAge === 0 ? (
                <>{sessionResume.companyClubMaxAge} ans-</>
              ) : sessionResume.companyClubMaxAge === 99 ? (
                <>{sessionResume.companyClubMinAge} ans+</>
              ) : (
                <>
                  {sessionResume.companyClubMinAge}-
                  {sessionResume.companyClubMaxAge} ans
                </>
              )}
            </span>
          )}
          {!selectedSession?.isSessionFinished && (
            <JourneyDailyActivityAvailability
              className="text-left"
              dailyActivity={dailyActivity}
            />
          )}
        </div>
      </div>

      {context === 'details' && (
        <div className="flex flex-col items-start flex-wrap gap-y-1 font-normal text-xs leading-snug sm:text-sm sm:leading-snug">
          {sessionResume.multipleSessions ? null : (
            // <div className="font-bold">
            //   {t(
            //     { id: 'page16.session.multiple-seances-from-time-to-time' },
            //     {
            //       count: sessionResume.multipleSessions.totalCount,
            //       beginTime: dateTransformer.formatUTC(
            //         sessionResume.multipleSessions.beginDateTime,
            //         'HH:mm'
            //       ),
            //       endTime: dateTransformer.formatUTC(
            //         sessionResume.multipleSessions.endDateTime,
            //         'HH:mm'
            //       ),
            //     }
            //   )}
            // </div>
            <div className="font-bold">
              {dateTransformer.formatUTC(sessionResume.beginDateTime, 'HH:mm')}{' '}
              - {dateTransformer.formatUTC(sessionResume.endDateTime, 'HH:mm')}
            </div>
          )}

          <ActivityTitleCardLocation
            className="text-xs"
            translation={activityTranslation}
          />
        </div>
      )}
    </div>
  );
};
