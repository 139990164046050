import { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import React from 'react';

export const GenderGirl = (props: IonicReactProps) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1280 1280"
      preserveAspectRatio="xMidYMid meet"
      {...props}
      x="0px"
      y="0px"
    >
      <g
        transform="translate(0,1006) scale(0.1,-0.1)"
        fill="currentColor"
        stroke="none"
      >
        <path
          d="M1667.5,8111.9c-147.1-16-325.3-93.1-422.8-184.3c-243.7-225.1-434.3-723.2-499.9-1307.3c-17.7-149.8-15.1-574.3,3.5-735.6
		c52.3-447.6,163.1-891.6,358.1-1435.8c41.7-117,176.4-460.9,218-557.5c12.4-29.2,11.5-33.7-17.7-101.9
		c-131.2-301.3-251.7-654.1-312-909.3c-180.8-772-76.2-1398.6,310.2-1865.6l54.1-66.5l-39.9-39.9
		C1216.4,802.7,1175.6,616.6,1211,403c40.8-250.8,198.5-679.8,319.1-868.6c93.9-148,269.4-273.9,413-296
		c135.6-22.2,178.1,26.6,265,300.5c34.6,109,64.7,180.8,117.9,283.6C2536.9,230.2,2564.4,294,2596.3,465
		c17.7,95.7,18.6,396.2,2.7,598.2c-5.3,70.9-16.8,210-24.8,310.2c-11.5,142.7-14.2,315.5-15.1,806.5
		c-0.9,789.7,2.7,825.1,121.4,1263.8c119.6,438.7,131.2,546.8,81.5,739.2c-19.5,73.6-30.1,98.4-147.1,332.4
		c-98.4,195.9-109,222.5-136.5,336.8c-87.7,359.8-93.1,581.4-16,711.7c54.1,93.1,132.1,154.2,244.6,192.3l66.5,22.2l52.3-16.8
		c122.3-39,196.8-49.6,357.2-49.6c92.2,0,174.6,5.3,203,11.5c26.6,6.2,49.6,9.7,51.4,7.1c1.8-1.8-4.4-37.2-15.1-79.8
		c-61.2-255.2-80.7-515.8-48.7-670c24.8-121.4,125-285.4,234.9-384.6c20.4-18.6,35.5-34.6,33.7-36.3c-0.9-0.9-33.7-15.1-71.8-30.1
		c-127.6-50.5-180.8-101.9-246.4-236.6c-90.4-187-93.1-385.5-7.1-630.1c83.3-237.5,183.5-342.1,364.3-381.1
		c66.5-14.2,69.1-16,73.6-41.7c23-148,56.7-317.3,85.1-429c263.2-1039.6,935-1873.6,1780.5-2209.5l84.2-33.7l1.8-671.8
		c1.8-369.6,4.4-676.2,7.1-682.4c2.7-9.7,158.6-11.5,786.1-9.7l782.6,2.7l4.4,689.5l4.4,689.5l89.5,38.1
		c277.4,117.9,559.2,307.5,794.1,535.3c516.7,499.9,863.2,1185,999.7,1972.9c9.7,58.5,19.5,107.2,20.4,109c1.8,0.9,36.3,8,77.1,15.1
		c211.8,37.2,328.8,175.5,410.3,483.9c23.9,90.4,26.6,117,27.5,230.4c0.9,111.7-1.8,136.5-19.5,190.6
		c-48.7,147.1-125,260.6-208.3,308.4c-20.4,11.5-65.6,32.8-101,46.1c-34.6,13.3-62,29.2-60.3,34.6s5.3,20.4,8,32.8
		c3.5,16,22.2,34.6,61.2,59.4c132.9,86,185.2,195,153.3,319.1c-19.5,78.9-59.4,149.8-209.2,374c-72.7,109.9-132.1,202.1-132.9,204.7
		c0,2.7,56.7,8,126.7,10.6c214.5,8.9,378.4,61.2,561,179l72.7,47l64.7-36.3c130.3-70.9,234.9-203,281.8-356.3
		c20.4-65.6,22.2-84.2,22.2-252.6c0-151.6-4.4-207.4-23.9-341.2c-31-205.6-44.3-255.2-131.2-493.7
		c-89.5-244.6-118.8-347.4-137.4-472.4c-25.7-174.6-8-297.8,80.7-556.6c31.9-91.3,83.3-240.2,115.2-332.4
		c129.4-376.7,135.6-525.6,48.7-1185c-41.7-314.6-44.3-347.4-50.5-496.3c-8-225.1,11.5-429.8,81.5-859.7
		c37.2-231.3,50.5-347.4,62-545.1c12.4-218,47.9-318.2,141.8-405.9c179-167.5,388.2-242.8,546.8-196.8
		c154.2,45.2,399.7,512.3,495.4,943.9c23,102.8,32.8,343.9,17.7,448.5c-13.3,90.4-47.9,225.1-77.1,293.4l-21.3,51.4l35.5,52.3
		c97.5,142.7,163.1,350.1,220.7,696.6c93.1,556.6,111.7,974.9,58.5,1285.1c-33.7,195-96.6,411.2-168.4,576.1
		c-34.6,78.9-113.4,232.2-147.1,285.4c-13.3,23-17.7,10.6,71.8,228.7c373.1,913.8,540.6,1697.2,520.2,2428.4
		c-11.5,389.1-56.7,572.5-218.9,887.2c-92.2,179.9-136.5,252.6-203,335c-67.4,83.3-112.6,117-260.6,195
		c-217.1,114.3-317.3,142.7-498.1,140.9c-203.8-0.9-346.5-54.1-554.8-205.6c-249.9-182.6-473.3-417.4-561-590.3
		c-46.1-90.4-59.4-153.3-56.7-257l1.8-87.7l-31-8c-168.4-41.7-539.7-177.3-829.6-304l-152.4-66.5l-19.5,16.8
		c-33.7,31.9-213.6,63.8-362.5,63.8c-36.3,0-86,2.7-110.8,5.3l-44.3,5.3v77.1c0,65.6-3.5,83.3-24.8,125.9
		c-48.7,99.3-217.1,292.5-328.8,377.6c-117,87.7-251.7,132.1-383.8,124.1l-71.8-4.4l-6.2,31c-14.2,77.1-128.5,142.7-343.9,197.6
		c-231.3,58.5-343,74.4-529.1,74.4c-154.2,0-169.3-1.8-242.8-24.8c-263.2-80.7-513.2-299.6-634.6-555.7
		c-14.2-31-26.6-50.5-26.6-43.4c0,28.4-43.4,103.7-80.7,140.9c-88.6,89.5-293.4,203-441.4,245.5c-73.6,21.3-93.1,23-180.8,18.6
		c-124.1-5.3-221.6-32.8-359.8-101c-114.3-55.8-187-118.8-223.3-193.2c-18.6-39-21.3-56.7-21.3-143.6c0-83.3-2.7-100.1-13.3-100.1
		c-28.4,0-108.1-45.2-149.8-84.2l-44.3-41.7l-25.7,18.6c-62,43.4-202.1,80.7-308.4,80.7c-53.2,0-57.6,1.8-85.1,31.9
		c-54.1,59.4-180.8,106.4-417.4,154.2c-74.4,15.1-142.7,29.2-153.3,31c-11.5,2.7-35.5,31.9-65.6,81.5
		c-66.5,109-176.4,266.8-256.1,365.1C2407.5,7975.4,2062.8,8155.3,1667.5,8111.9z"
        />
      </g>
    </svg>
  );
};
