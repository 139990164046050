import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { SimpleStore } from '../core/SimpleStore.model';
import { cloner } from '../util/cloner.service';
import { AttributeStore } from './AttributeStore.model';
import { attributeStoreFactory } from './attributeStoreFactory.service';
import { LoadableAttribute } from './LoadableAttribute.model';
import { LoadableAttributeStore } from './LoadableAttributeStore.model';

function get<T>(
  loadableAttribute: AttributeStore<LoadableAttribute<T>>
): Observable<T> {
  return loadableAttribute.get().pipe(
    filter((x) => x && x.isLoaded),
    map((x) => x.value)
  );
}

function isLoaded<T>(
  loadableAttribute: AttributeStore<LoadableAttribute<T>>
): boolean {
  const attr = loadableAttribute.getSnapshot();
  return attr && attr.isLoaded;
}

function getAttributeSnapshot<T>(
  loadableAttribute: AttributeStore<LoadableAttribute<T>>
): T {
  const attr = loadableAttribute.getSnapshot();
  return attr && attr.isLoaded ? (attr.value as T) : undefined;
}

function set<T>(
  loadableAttribute: AttributeStore<LoadableAttribute<T>>,
  value: T,
  actionId?: string
): void {
  return loadableAttribute.set(
    {
      isLoaded: true,
      value: cloner.clone(value),
    },
    actionId
  );
}

function reduce<T>(
  loadableAttribute: AttributeStore<LoadableAttribute<T>>,
  reduceFn: (state: T) => T,
  actionId?: string
): T {
  let updated: LoadableAttribute<T>;

  loadableAttribute.reduce((state: LoadableAttribute<T>) => {
    const original = state.value;

    updated = {
      isLoaded: true,
      value: reduceFn(original),
    };
    return updated;
  }, actionId);

  return updated.value;
}

function unload<T>(
  loadableAttribute: AttributeStore<LoadableAttribute<T>>,
  actionId?: string
): void {
  return loadableAttribute.set(
    {
      isLoaded: false,
      value: undefined,
    },
    actionId
  );
}

function remove<T>(
  loadableAttribute: AttributeStore<LoadableAttribute<T>>,
  actionId?: string
): void {
  return loadableAttribute.set(
    {
      isLoaded: true,
      value: undefined,
    },
    actionId
  );
}

function create<T>(
  store: SimpleStore<any>,
  name: string,
  initialValue?: LoadableAttribute<T>
): LoadableAttributeStore<T> {
  if (!initialValue) {
    initialValue = {
      isLoaded: false,
      value: undefined,
    };
  } else {
    if (initialValue.isLoaded === undefined) {
      if (initialValue.value === undefined) {
        initialValue.isLoaded = false;
      } else {
        initialValue.isLoaded = true;
      }
    }
  }

  const loadableAttribute = attributeStoreFactory.create<LoadableAttribute<T>>(
    store,
    name,
    initialValue
  );

  return {
    get: () => get(loadableAttribute),
    getSnapshot: () => getAttributeSnapshot(loadableAttribute),
    isLoaded: () => isLoaded(loadableAttribute),
    reduce: (reduceFn: (state: T) => T, actionId?: string) =>
      reduce(loadableAttribute, reduceFn, actionId ? actionId : 'reduce'),
    remove: (actionId?: string) =>
      remove(loadableAttribute, actionId ? actionId : 'remove'),
    set: (value: T, actionId?: string) =>
      set(loadableAttribute, value, actionId ? actionId : 'set'),
    unload: (actionId?: string) =>
      unload(loadableAttribute, actionId ? actionId : 'unload'),
  };
}

export const loadableAttributeStoreFactory = {
  create,
};
