import {
  ActivityTranslation,
  CMJourneyActivitySessionActivity,
} from 'lib-common-model';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import { useAppImageUrl } from '_01_CORE/app-core/app-security/useAppImageUrl.hook';
import { dateTransformer } from '_01_CORE/_components-core';
import { ActivityTitleCardSession } from './ActivityTitleCardSession.type';
import { ActivityTitleCardStatus } from './ActivityTitleCardStatus';
import { SessionRelativeTimeState } from './SessionRelativeTimeState.type';
import { sessionRelativeTimeStateBuilder } from './sessionRelativeTimeStateBuilder.service';

export const ActivityTitleCardLeftSide = ({
  activity,
  activityTranslation,
  sessionResume,
  className,
}: {
  activity: CMJourneyActivitySessionActivity;
  activityTranslation: ActivityTranslation;
  sessionResume: ActivityTitleCardSession;
  className?: string;
}) => {
  const appId = appWebConfig().appId;
  const { formatMessage: t } = useIntl();

  const relativeTimeState: SessionRelativeTimeState = useMemo(
    () => sessionRelativeTimeStateBuilder.build(sessionResume),
    [sessionResume]
  );

  const activityLogoUrl = useAppImageUrl(activity.logo, {
    defaultImage: 'activity',
  });

  const imgOpacity = relativeTimeState === 'past' ? 0.3 : 1;
  return (
    <div className={`text-lg text-center font-bold self-center ${className}`}>
      <div className={`flex flex-col justify-between`}>
        <div className={``}>
          {dateTransformer.formatUTC(sessionResume.beginDateTime, 'HH:mm')}
        </div>
        {activityLogoUrl && (
          <div className="mt-1.5">
            <img
              style={{ opacity: imgOpacity }}
              title={activityTranslation.name}
              src={activityLogoUrl}
              className={`w-16 h-auto ${
                relativeTimeState === 'past' ? 'grayscale-[50%]' : ''
              }`}
            />
          </div>
        )}
      </div>
      <ActivityTitleCardStatus
        openingStatus={sessionResume.openingStatus}
        relativeTimeState={relativeTimeState}
      />
    </div>
  );
};
