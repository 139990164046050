import { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import React from 'react';

export const ArrowRight = (props: IonicReactProps) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 700 700"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <g
        transform="translate(0.000000,604.000000) scale(0.100000,-0.100000)"
        fill="currentColor"
        stroke="none"
      >
        <path
          d="M1000 5963 c-18 -21 -81 -90 -140 -153 -58 -63 -207 -225 -330 -360
        -123 -135 -227 -247 -230 -250 -9 -8 -214 -234 -233 -256 -16 -20 -16 -21 10
        -35 184 -94 848 -471 1303 -742 1532 -909 2474 -1619 3286 -2477 172 -182 162
        -170 139 -183 -34 -20 -100 -44 -177 -63 -66 -17 -107 -19 -437 -16 -210 2
        -393 -1 -435 -7 -39 -6 -101 -13 -138 -17 -38 -3 -68 -9 -68 -13 0 -20 719
        -389 1030 -529 25 -11 90 -40 145 -65 55 -24 145 -64 200 -87 55 -23 114 -48
        130 -55 250 -111 890 -344 1270 -462 238 -74 567 -164 580 -159 7 2 19 74 29
        177 15 142 18 223 13 444 -6 255 -16 367 -51 580 -17 102 -51 263 -65 315 -6
        19 -25 87 -42 150 -47 172 -156 496 -217 645 -84 208 -191 463 -222 530 -20
        44 -47 105 -61 135 -36 83 -316 642 -332 664 -13 17 -15 9 -21 -80 -4 -54 -12
        -259 -16 -454 -7 -266 -13 -369 -24 -410 -23 -83 -67 -180 -81 -180 -6 0 -65
        57 -130 128 -65 70 -227 235 -359 368 -208 207 -306 301 -566 539 -116 106
        -429 370 -627 527 -514 410 -971 728 -1567 1092 -87 53 -160 96 -162 96 -2 0
        -22 11 -43 24 -42 27 -323 184 -461 258 -102 55 -475 242 -580 291 -41 19
        -113 53 -160 74 -127 60 -123 59 -160 16z"
        />
      </g>
    </svg>
  );
};
