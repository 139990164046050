import { CMCompanyResume } from 'lib-common-model';
import { imageUrlBuilder } from '_01_CORE/app-core/app-api';
import { useAppImageUrl } from '_01_CORE/app-core/app-security/useAppImageUrl.hook';
import { BannerCard } from '_01_CORE/_common-layout';

export const AccountChooseCompanyBannerCard = ({
  company,
  onClick,
}: {
  company: CMCompanyResume;
  onClick: () => void;
}) => {
  const companyLogoUrl = useAppImageUrl(company?.logo, {
    defaultImage: 'app',
  });
  return (
    <BannerCard
      reference={company?.reference}
      key={company?.reference}
      logo={companyLogoUrl}
      image={imageUrlBuilder.getImageCompanyFullUrl({
        image: company?.image1,
      })}
      title={company?.name}
      onClick={onClick}
    />
  );
};
