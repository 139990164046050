import { Booklet } from 'lib-common-model';
import React from 'react';
import { useIntl } from 'react-intl';
import {
  useAppCacheContext,
  useJourney,
  useJourneyEnabledActions,
} from '_01_CORE/app-contexts/app-cache-context';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import {
  AppIcons,
  AppTranslationContentPanel,
} from '_01_CORE/_components-core';
import { AppButtonCompany } from '_01_CORE/_components-core/AppButton/AppButtonCompany';
import { useAppRouter } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import { AnimLiveJourneyHomeButtons } from '_04_ANIM_LIVE/JOU-journey/JOU-02-journey-home/AnimLiveJourneyHomeButtons';
import { useAppTheme } from '../AppTheme';

export const CompanyPresentation: React.FC<{
  companyReference: string;
  booklet: Booklet;
}> = ({ companyReference, booklet }) => {
  const appRouter = useAppRouter();
  // const languageCode = appStore.bookletLanguage.getSnapshot();
  // const booklet = useCompanyBooklet({ companyReference, languageCode });
  const appId = appWebConfig().appId;
  const { auth, dispatchCacheContextAction } = useAppCacheContext();
  const { formatMessage: t } = useIntl();

  const { journey } = useJourney({ companyReference });
  const theme = useAppTheme({ companyReference });

  const { isCreateJourneyEnabled } = useJourneyEnabledActions({});

  return (
    <div className="text-black mb-2">
      {booklet.presentation.contentItems.map((item, i) => (
        <AppTranslationContentPanel
          companyReference={companyReference}
          key={i}
          item={item}
        />
      ))}
      <div className="my-4 flex flex-col gap-4">
        {appId === 'anim-live' && (
          <>
            <AnimLiveJourneyHomeButtons
              companyReference={companyReference}
              journey={journey}
            />
          </>
        )}
        {appId === 'accropark' && isCreateJourneyEnabled && (
          <>
            <AppButtonCompany
              isFullWidth={true}
              className="w-full uppercase mx-auto"
              onClick={() => {
                dispatchCacheContextAction({
                  type: 'clear-update-journey-cache',
                  context: 'create-journey',
                });

                appRouter.navigate(
                  appRoutesBuilder.getJourneyDatesEditUrl({ companyReference }),
                  { cause: 'company-presentation-register' }
                );
              }}
              style="primary"
              theme={theme}
              label={t({
                id: auth?.isAuthenticated
                  ? `page04.button.add-journey.accropark`
                  : `page03.register.accropark`,
              })}
              icon={AppIcons.check}
            />
          </>
        )}
      </div>
    </div>
  );
};
