import { AppIcons, ValueLabel } from '_01_CORE/_components-core';
import {
  PARTICIPANT_PAYMENT_MODES,
  ParticipantPaymentMode,
} from 'lib-common-model';
import { useMemo } from 'react';

export function useParticipantPaymentModeOptions({
  appOnlinePaymentEnabled,
  onSitePaymentEnabled,
  format,
}: {
  appOnlinePaymentEnabled: boolean;
  onSitePaymentEnabled: boolean;
  format: 'short' | 'full';
}): ValueLabel<ParticipantPaymentMode>[] {
  return useMemo(
    () =>
      PARTICIPANT_PAYMENT_MODES.filter(
        (x) =>
          (x === 'on-site' && onSitePaymentEnabled) ||
          (x === 'online' && appOnlinePaymentEnabled)
      ).map((paymentMode) => {
        const option: ValueLabel<ParticipantPaymentMode> = {
          value: paymentMode,
          label:
            paymentMode === 'on-site'
              ? format === 'short'
                ? 'Sur place'
                : 'Payer sur place'
              : format === 'short'
              ? 'En ligne'
              : 'Payer en ligne',
          icon:
            paymentMode === 'on-site'
              ? AppIcons.paymentOnSite
              : AppIcons.paymentOnline,
        };
        return option;
      }),
    [appOnlinePaymentEnabled, format, onSitePaymentEnabled]
  );
}
