import { useIntl } from 'react-intl';
import { AppButtonV2 } from '../AppButton';
import { AppIcons } from '../icons';

export const ModalConfirmActions = ({
  onConfirm,
  onCancel,
  cancelMessage,
  confirmMessage,
}: {
  onConfirm: () => void;
  onCancel: () => void;
  cancelMessage?: string;
  confirmMessage?: string;
}) => {
  const { formatMessage: t } = useIntl();

  return (
    <div className="w-full flex gap-4 justify-between z-1000">
      <AppButtonV2
        icon={AppIcons.actionCancel}
        className="w-full"
        buttonStyle="cancel"
        onClick={onCancel}
      >
        {cancelMessage ?? t({ id: 'modal.no' })}
      </AppButtonV2>{' '}
      <AppButtonV2
        icon={AppIcons.check}
        className="w-full"
        buttonStyle="primary"
        onClick={onConfirm}
      >
        {confirmMessage ?? t({ id: 'modal.yes' })}
      </AppButtonV2>
    </div>
  );
};
