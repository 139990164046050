import { User } from '@sentry/react';
import { CaptureContext } from '@sentry/types';
import {
  authenticationClient,
  authenticationStore,
} from '_01_CORE/app-core/app-security';
import { refreshTokenManager } from '_01_CORE/app-core/app-security/refresh-token/refreshTokenManager.service';
import { baseStore } from '_01_CORE/app-core/app-store/baseStore';
import { uuidGenerator } from 'lib-common-data';
import { AppEnvConfigPublic, AppSettings } from 'lib-common-model';
import { AppSettingsFeaturesCustomersApi } from 'lib-common-model/entities/postgres/app-settings/AppSettingsFeaturesCustomersApi.type';
import { apiClient } from 'lib-web-api-client';
import { AppRemoteEnvId } from 'lib-web-common';
import { appLogger, appLoggerConfigurer } from 'lib-web-logger';
import { from, of } from 'rxjs';
import { distinctUntilChanged, mapTo, switchMap, tap } from 'rxjs/operators';
import { appSettingsApiClient } from '../app-api';
import { appWebConfig, resetAppWebConfig } from '../app-config';
import { appStore } from '../app-store';
import { bootstrapAuthentication } from './bootstrapAuthentication.service';

export const appBootstrap = {
  init,
  switchEnv,
};

const appVersion = appWebConfig().appVersion;
const appClientId = uuidGenerator.random();
function init() {
  configureLogger({});

  configureApi({ appClientId, appVersion, clearAuth: false });

  manageLogoutRequired().subscribe();

  refreshTokenManager.manageRefreshToken().subscribe();

  return bootstrapAuthentication().pipe(
    switchMap(() => {
      return from(
        appSettingsApiClient.fetchAppSettings().catch((err) => {
          // NOTE: temporaire: le temps de la mise en prod 5.50.0 (21/02/2023)
          console.error('Error fetching app settings');
          const appSettings: Partial<AppSettings> = {
            universe: {
              theme: {
                textColor: '#787879',
                title1Color: '#366292',
                buttonCancelBgColor: '#eee',
                buttonDangerBgColor: '#B51700',
                buttonPrimaryBgColor: '#366292',
                buttonCancelTextColor: '#555',
                buttonDangerTextColor: '#ffffff',
                buttonPrimaryTextColor: '#ffffff',
                buttonSecondaryBgColor: '#0E623B',
                buttonBottomMenuBgColor: '#ffffff',
                buttonSecondaryTextColor: '#ffffff',
                buttonBottomMenuTextColor: '#366292',
              },
              identity: {
                appLogo: {
                  md:
                    '/api/images/beta/app-settings/logo/1676290133557-loisirs18-w188-h173.gif',
                  sm:
                    '/api/images/beta/app-settings/logo/1676290133557-loisirs18-w188-h173.gif',
                  xl:
                    '/api/images/beta/app-settings/logo/1676290133557-loisirs18-w188-h173.gif',
                  xs:
                    '/api/images/beta/app-settings/logo/1676290133557-loisirs18-w188-h173.gif',
                  original:
                    '/api/images/beta/app-settings/logo/1676290133557-loisirs18.gif',
                },
                appLabel: 'ANIM Live',
                appMarketId: 'com.magicanim.miniclubboard',
                defaultClubLogo: {
                  md:
                    '/api/images/beta/app-settings/logo/1676290114132-cirque-logo-w273-h299.png',
                  sm:
                    '/api/images/beta/app-settings/logo/1676290114132-cirque-logo-w273-h299.png',
                  xl:
                    '/api/images/beta/app-settings/logo/1676290114132-cirque-logo-w273-h299.png',
                  xs:
                    '/api/images/beta/app-settings/logo/1676290114132-cirque-logo-w200-h219.png',
                  original:
                    '/api/images/beta/app-settings/logo/1676290114132-cirque-logo.png',
                },
                defaultActivityLogo: {
                  md:
                    '/api/images/beta/app-settings/logo/1676290123340-capture-d-ecran-2018-12-29-a-21.53.26-w238-h272.png',
                  sm:
                    '/api/images/beta/app-settings/logo/1676290123340-capture-d-ecran-2018-12-29-a-21.53.26-w238-h272.png',
                  xl:
                    '/api/images/beta/app-settings/logo/1676290123340-capture-d-ecran-2018-12-29-a-21.53.26-w238-h272.png',
                  xs:
                    '/api/images/beta/app-settings/logo/1676290123340-capture-d-ecran-2018-12-29-a-21.53.26-w200-h229.png',
                  original:
                    '/api/images/beta/app-settings/logo/1676290123340-capture-d-ecran-2018-12-29-a-21.53.26.png',
                },
              },
              whiteLabel: {},
            },
          };
          return ({
            appSettings,
            appEnvConfigPublic: {},
          } as unknown) as {
            appSettings: AppSettings;
            appEnvConfigPublic: AppEnvConfigPublic;
          };
        })
      ).pipe(
        tap(({ appSettings, appEnvConfigPublic }) => {
          configureLogger({
            appSettings,
            appEnvConfigPublic,
          });
          appStore.appEnvConfigPublic.set(
            appEnvConfigPublic,
            'fetch-app-settings'
          );

          if (!appSettings.features?.customers?.api) {
            // si l'api n'est pas à jour, le champ n'existe pas
            const DEFAULT_API_CONFIG: AppSettingsFeaturesCustomersApi = {
              singleActivity: {
                refreshStrategy: 'all',
              },
              dailyActivities: {
                pageLoadingFetchTTL: 0,
                activityLoadingFetchTTL: 0,
                activityActionFetchTTL: 0,
              },
            };
            appSettings.features.customers.api = DEFAULT_API_CONFIG;
          }
          appStore.appSettings.set(appSettings, 'fetch-app-settings');
        })
      );
    })
  );
}

function switchEnv(env?: { remoteEnvId: AppRemoteEnvId }) {
  resetAppWebConfig(env);
  configureApi({ appClientId, appVersion, clearAuth: true });
}

function configureLogger({
  appSettings,
  appEnvConfigPublic,
}: {
  appSettings?: AppSettings;
  appEnvConfigPublic?: AppEnvConfigPublic;
}) {
  appLoggerConfigurer.configure({
    buildCaptureContext: () => {
      const auth = authenticationStore.auth.getSnapshot();
      const user: User = auth
        ? {
            // [key: string]: any;
            id: auth.customerProfile?.user?._id,
            email: auth.customerProfile?.account?.email,
            username: auth.customerProfile?.account?.firstName
              ? `${auth.customerProfile?.account?.firstName} ${auth.customerProfile?.account?.lastName}`
              : undefined,
          }
        : undefined;

      const context: CaptureContext = {
        user,
        tags: {
          appMarketId: appSettings?.universe?.identity?.appMarketId,
          appCompanyReference:
            appSettings?.universe?.whiteLabel?.appCompanyReference,
          appGroupReference:
            appSettings?.universe?.whiteLabel?.appGroupReference,
          envId: appWebConfig().envId,
          appId: appWebConfig().appId,
          remoteEnvId: appWebConfig().remoteEnvId,
          appVersion: appWebConfig().appVersion,
          appEnvConfigPublicEnvId: appEnvConfigPublic?.env?.id,
          appEnvConfigPublicEnvRunningMode:
            appEnvConfigPublic?.env?.runningMode,
        },
      };
      return context;
    },
  });
}

function configureApi({
  appClientId,
  appVersion,
  clearAuth,
}: {
  appClientId: string;
  appVersion: string;
  clearAuth: boolean;
}) {
  try {
    apiClient.init({
      appClientId,
      appVersion,
      baseStore: baseStore,
      clearAuth,
      baseUrl: appWebConfig().urls.api,
      errorCallback: ({
        httpStatus,
        json,
        err,
      }: {
        httpStatus: number;
        json: any;
        err: Error;
      }) => {
        // default callback
        if (httpStatus === 401 && json.message === 'version-expired') {
          authenticationStore.redirectToOldVersionPage.set(true);
        }
        if (httpStatus === 401 || httpStatus === 403) {
          appLogger.warn('Authentication error', { err });
          authenticationStore.logoutRequired.set(true);
        }
      },
    });
  } catch (err) {
    appLogger.error(
      '[appBootstrap] Error initializing configureApiAndGraphqlClients',
      { err: err as any }
    );
  }
}

function manageLogoutRequired() {
  return authenticationStore.logoutRequired.get().pipe(
    switchMap((authenticationRequired) => {
      if (authenticationRequired) {
        authenticationStore.logoutRequired.set(false);
        return from(authenticationClient.logout());
      }
      return of(undefined);
    }),
    mapTo(true),
    distinctUntilChanged()
  );
}
