import {
  AppButtonUniverse,
  AppInput,
  AppLoader,
  AppLoaderSpinner,
} from '_01_CORE/_components-core';
import { useAppToasts } from '_01_CORE/_hooks';
import { useAppCacheContext } from '_01_CORE/app-contexts';
import { customerJourneyApiClient } from '_01_CORE/app-core/app-api';
import { useAppFeatures } from '_01_CORE/app-core/app-config';
import { useIsNetworkConnected } from '_01_CORE/app-core/app-platform';
import { useAppSettings } from '_01_CORE/app-core/app-security';
import clsx from 'clsx';
import { CMExtJourneyFinderResult } from 'lib-common-model';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

export const AuthPageMultiLoginCheckKeyPanel = ({
  context,
  onJourneyFinderResultUpdated,
  extCustomerAccountActivationEmail,
  extCustomerJourneyBookingGroupId,
  initialActivationKey,
}: {
  context: 'auth-login-activation' | 'journey-add-activation';
  onJourneyFinderResultUpdated: (
    journeyFinderResult: CMExtJourneyFinderResult
  ) => void;
  extCustomerAccountActivationEmail?: string;
  extCustomerJourneyBookingGroupId?: string;
  initialActivationKey?: string;
}) => {
  const appSettings = useAppSettings();
  const { formatMessage: t } = useIntl();
  const { pushError, pushSuccess } = useAppToasts();
  const appCacheContext = useAppCacheContext();
  const { auth } = appCacheContext;

  const [activationKey, setActivationKey] = useState<string>(
    initialActivationKey ?? ''
  );
  const [
    journeyFinderResult,
    setJourneyFinderResult,
  ] = useState<CMExtJourneyFinderResult>();

  const [
    codeVerificationInProgress,
    setCodeVerificationInProgress,
  ] = useState<boolean>(false);

  const [lastCodeValidation, setLastCodeValidation] = useState<string>();
  const [lastCodeValidationResult, setLastCodeValidationResult] = useState<
    'success' | 'expired' | 'error' | 'conflict'
  >();

  const { isFeatureAuthEnabled } = useAppFeatures();

  const isNetworkConnected = useIsNetworkConnected();

  const validateCode = useCallback(async () => {
    if (!isNetworkConnected()) {
      return;
    }

    setCodeVerificationInProgress(true);
    setLastCodeValidationResult(undefined);
    setJourneyFinderResult(undefined);

    if (activationKey) {
      try {
        const fetchData = await customerJourneyApiClient.fetchExternalJourneyDetailsFromActivationKey(
          {
            activationKey,
          }
        );
        setJourneyFinderResult(fetchData);
        onJourneyFinderResultUpdated(fetchData);
        setLastCodeValidationResult('success');
      } catch (err) {
        console.error(err);
        if ((err as any)?.response?.status === 409) {
          setLastCodeValidationResult('conflict');
        } else if ((err as any)?.response?.status === 410) {
          setLastCodeValidationResult('expired');
        } else {
          setLastCodeValidationResult('error');
        }
        // pushError(
        //   t({ id: 'page30-journey-activation-token.message.fetching-error' })
        // );
      } finally {
        setCodeVerificationInProgress(false);
      }
    }
  }, [activationKey, isNetworkConnected, onJourneyFinderResultUpdated]);

  useEffect(() => {
    if (!codeVerificationInProgress) {
      const code = activationKey?.trim()?.toUpperCase();
      if (code?.length === 8 && code !== lastCodeValidation) {
        setLastCodeValidation(code);
        validateCode();
      }
    }
  }, [
    codeVerificationInProgress,
    activationKey,
    lastCodeValidation,
    validateCode,
  ]);

  const [
    reSendExternalJourneyActivationMailStatus,
    setReSendExternalJourneyActivationMailStatus,
  ] = useState<'not-started' | 'in-progress' | 'success'>('not-started');

  const title1Color = appSettings?.universe?.theme?.title1Color;

  return (
    <div className="w-full bg-gray-50 rounded-xl border border-gray-200 pt-4 pb-8 px-8">
      {isFeatureAuthEnabled && (
        <>
          <>
            <div className="flex flex-col gap-2">
              <div>
                <h2
                  className={`text-left text-base`}
                  style={{ color: title1Color }}
                >
                  {context === 'auth-login-activation' ? (
                    `Indiquez la clé d'activation que vous avez reçue par mail`
                  ) : (
                    <span>
                      {`Nous venons de vous envoyer votre clé d'activation par mail à`}{' '}
                      <span className="font-bold">
                        {extCustomerAccountActivationEmail}
                      </span>
                    </span>
                  )}
                </h2>
                <div className="w-full text-center">
                  {/* <AppFormControl
                          label={`Clé d'activation`}
                          renderComponent={() => ( */}
                  <AppInput
                    type="text"
                    className="my-3  w-full text-xl font-bold"
                    iconClasses={`absolute top-1.5 left-1 w-7 h-7 z-10 ${
                      journeyFinderResult
                        ? 'text-app-theme-accropark-success'
                        : 'text-gray-600'
                    }`}
                    required={true}
                    placeholder={`Clé d'activation`}
                    value={activationKey}
                    onIonChange={(e) => {
                      // setActivationKey(e?.target?.value as string);
                      // setJourneyFinderResult(undefined);
                      // setValidationResponse(undefined);
                    }}
                    onChange={(e) => {
                      setActivationKey((e as any).target.value as string);
                      setJourneyFinderResult(undefined);
                      setLastCodeValidationResult(undefined);
                    }}
                    onKeyDown={(e) => {
                      if (e.code?.toLowerCase() === 'enter') {
                        validateCode();
                      }
                    }}
                  />
                </div>
              </div>

              <div className="h-16 font-bold">
                {codeVerificationInProgress ? (
                  <div
                    className={`px-4 font-bold text-sm text-app-info flex justify-center items-center gap-4`}
                  >
                    <span>
                      <AppLoaderSpinner color="info" className="w-4 h-4" />
                    </span>
                    <span className="text-center">
                      {`Vérification de la clé d'activation`}
                    </span>
                    <span></span>
                  </div>
                ) : lastCodeValidationResult ? (
                  <div
                    className={clsx(
                      'text-left',
                      lastCodeValidationResult === 'success'
                        ? 'text-app-theme-accropark-success'
                        : 'text-app-danger'
                    )}
                  >
                    {lastCodeValidationResult === 'success'
                      ? `Clé d'activation valide`
                      : lastCodeValidationResult === 'expired'
                      ? `Clé d'activation expirée`
                      : lastCodeValidationResult === 'error'
                      ? `Clé d'activation invalide`
                      : lastCodeValidationResult === 'conflict'
                      ? `Un séjour existe déjà avec les mêmes dates`
                      : `Erreur innatendue`}
                  </div>
                ) : (
                  <div className="ion-justify-content-center">
                    <AppButtonUniverse
                      style={'primary'}
                      className={`mt-4 uppercase`}
                      onClick={() => validateCode()}
                    >
                      Continuer
                    </AppButtonUniverse>
                  </div>
                )}
              </div>
              {context === 'journey-add-activation' && (
                <>
                  <div
                    className={`text-left text-base`}
                    style={{ color: title1Color }}
                  >
                    {`Vous n'avez pas reçu de mail? Vérifiez dans le dossier SPAM `}
                    {reSendExternalJourneyActivationMailStatus !==
                      'success' && (
                      <a
                        className="text-app-link underline cursor-pointer"
                        onClick={async () => {
                          if (
                            reSendExternalJourneyActivationMailStatus ===
                            'not-started'
                          ) {
                            try {
                              setReSendExternalJourneyActivationMailStatus(
                                'in-progress'
                              );
                              await customerJourneyApiClient.reSendExternalJourneyActivationMail(
                                {
                                  extCustomerJourneyBookingGroupId,
                                  emailAddress: extCustomerAccountActivationEmail,
                                }
                              );
                              setTimeout(() => {
                                pushSuccess(`Email renvoyé`);
                                setReSendExternalJourneyActivationMailStatus(
                                  'success'
                                );
                              }, 2000);
                            } catch (err) {
                              pushError(`Erreur lors de l'envoi de l'email`);
                              setReSendExternalJourneyActivationMailStatus(
                                'not-started'
                              );
                            }
                          }
                        }}
                      >{`ou cliquez ici pour renvoyer l'email`}</a>
                    )}
                  </div>
                  {reSendExternalJourneyActivationMailStatus ===
                    'in-progress' && (
                    <AppLoader
                      className="my-4 p-2 px-4"
                      type="persist"
                      message="Envoi du message en cours"
                    />
                  )}
                </>
              )}

              {/* <div className="mt-8 text-center text-sm underline cursor-pointer">
                <a
                  onClick={() => {
                    //
                  }}
                >
                  {`Recevoir de nouveau ma clé d'activation`}
                </a>
              </div> */}
            </div>
          </>
        </>
      )}
    </div>
  );
};
