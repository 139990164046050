import {
  AppSecurityUser,
  CustomerMobileAuthenticationResult,
} from 'lib-common-model';
import { appLogger } from 'lib-web-logger';
import { from, NEVER, Observable, of, timer } from 'rxjs';
import {
  catchError,
  delay,
  distinctUntilChanged,
  retryWhen,
  switchMap,
  tap,
} from 'rxjs/operators';
import { authenticationClient } from '../authenticationClient.service';
import { AuthenticationClientError } from '../AuthenticationClientError.type';
import { authenticationStore } from '../authenticationStore.service';
import { AppAuth } from '../model';
import { refreshTokenTimeChecker } from './refreshTokenTimeChecker.service';

export const refreshTokenManager = {
  manageRefreshToken,
};

function manageRefreshToken() {
  appLogger.debug('[refreshTokenManager.manageRefreshToken]');

  return authenticationStore.auth.get().pipe(
    switchMap((auth: AppAuth) => {
      if (auth && auth.securityUser) {
        appLogger.info(
          `[refreshTokenManager.manageRefreshToken] auth payload: (${formatSecurityUserForLogs(
            auth.securityUser
          )})`
        );
        return refreshUserTokenPeriodic(auth);
      } else {
        appLogger.warn(
          '[refreshTokenManager.manageRefreshToken] user not authenticated: stop refresh'
        );
        return NEVER;
      }
    }),
    catchError((err) => {
      appLogger.error(
        '[refreshTokenManager.manageRefreshToken] token refresh fatal error',
        { err }
      );
      return NEVER;
    })
  );
}

function refreshUserTokenPeriodic(
  auth: AppAuth
): Observable<CustomerMobileAuthenticationResult> {
  return of(auth.securityUser).pipe(
    distinctUntilChanged(
      (x, y) => x.tokenInfo.expirationDate === y.tokenInfo.expirationDate
    ),
    tap((payload) =>
      appLogger.debug(
        `[refreshTokenManager.manageRefreshToken] triggerNextRefresh (${formatSecurityUserForLogs(
          payload
        )})`
      )
    ),
    switchMap((securityUser) =>
      triggerNextRefresh({ securityUser }).pipe(
        retryWhen((err$: Observable<AuthenticationClientError>) =>
          manageRefreshRetryOnError(err$)
        )
      )
    )
  );
}

function manageRefreshRetryOnError(
  err$: Observable<AuthenticationClientError>
): Observable<any> {
  return err$.pipe(
    switchMap((err: AuthenticationClientError) => {
      if (err && err.status === 401) {
        appLogger.warn(
          '[refreshTokenManager.manageRefreshToken] security error: logout'
        );
        logout();
        return NEVER;
      }
      appLogger.warn('[refreshTokenManager.manageRefreshToken] err', err);
      return of(true).pipe(delay(4000));
    })
  );
}

function triggerNextRefresh({
  securityUser,
}: {
  securityUser: AppSecurityUser;
}) {
  const {
    isValid,
    delayBeforeRefreshMs,
  } = refreshTokenTimeChecker.checkTokenValidityDelay({
    expirationDate: securityUser.tokenInfo.expirationDate,
    issueDate: securityUser.tokenInfo.issueDate,
    minRemainingTimeMs: 1000,
  });

  if (isValid) {
    appLogger.debug(
      `[refreshTokenManager.triggerNextRefresh] delayBeforeRefreshMs: ${delayBeforeRefreshMs}`
    );

    const triggerOnRefreshTime$ =
      delayBeforeRefreshMs > 1000 ? timer(delayBeforeRefreshMs) : of(0);

    return triggerOnRefreshTime$.pipe(
      tap(() =>
        appLogger.warn(
          `[refreshTokenManager.triggerNextRefresh] refresh token now (${formatSecurityUserForLogs(
            securityUser
          )})`
        )
      ),
      switchMap(() => from(authenticationClient.refreshToken()))
    );
  } else {
    appLogger.warn(
      '[refreshTokenManager.triggerNextRefresh] token expired: stop to refresh'
    );
    logout();
    return NEVER;
  }
}

function logout() {
  authenticationStore.logoutRequired.set(true);
}

function formatDateForLogs(date: Date) {
  return `${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
}

function formatSecurityUserForLogs(securityUser: AppSecurityUser) {
  return `issueDate=${formatDateForLogs(
    securityUser.tokenInfo.issueDate
  )}, expirationDate=${formatDateForLogs(
    securityUser.tokenInfo.expirationDate
  )}, now=${formatDateForLogs(new Date())}, authUserId=${
    securityUser.authUserId
  }`;
}
