import { AppRichTextLeaf, CompanyOptionsTheme } from 'lib-common-model';
import React from 'react';

interface RenderLeafProps {
  // from 'slate-react':
  children: any;
  leaf: AppRichTextLeaf;
  text: AppRichTextLeaf;
  attributes: {
    'data-slate-leaf': true;
  };
}
export function AppRichTextSlateLeaf<A>({
  attributes,
  children,
  leaf,
  theme,
}: Omit<RenderLeafProps, 'attributes'> & {
  attributes?: A;
  theme: CompanyOptionsTheme;
}) {
  if (leaf.bold) {
    children = <strong className="text-gray-800">{children}</strong>;
  }

  // if (leaf.code) {
  //   children = <code>{children}</code>;
  // }

  if (leaf.italic) {
    children = <em className="text-gray-800">{children}</em>;
  }

  // if (leaf.underline) {
  //   children = <u>{children}</u>;
  // }

  return <span {...attributes}>{children}</span>;
}
