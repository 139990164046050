export class ApiClientHttpError extends Error {
  status: number;
  response: Response;
  cause?: Error;
  json?: any;

  constructor({
    response,
    cause,
    json,
  }: {
    response: Response;
    cause?: Error;
    json?: any;
  }) {
    super(`ApiClientHttpError status "${response.status}"`);
    this.response = response;
    this.status = response ? response.status : undefined;
    this.cause = cause;
    this.json = json;

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, ApiClientHttpError.prototype);
  }
}
