import { IonIcon, IonToolbar } from '@ionic/react';
import { AppSettingsUniverseTheme } from 'lib-common-model';
import { useAppSettings } from '_01_CORE/app-core/app-security';
import { AppButtonUniverse } from '_01_CORE/_components-core';
import { AppMenu } from '../_model';

export const AppBottomTabsMenu = ({ tabsMenu }: { tabsMenu: AppMenu }) => {
  const appSettings = useAppSettings();
  const theme: AppSettingsUniverseTheme = appSettings?.universe?.theme;

  return tabsMenu.hidden ? null : (
    <>
      <IonToolbar slot="bottom" hidden={false}>
        <div
          className="w-full pb-2 bg-white flex justify-between"
          style={{
            background: theme?.buttonBottomMenuBgColor,
          }}
        >
          {tabsMenu.items
            .filter((item) => item.isVisibleItem)
            .map((item) => (
              <AppButtonUniverse
                key={item.id}
                onClick={() => item.onClick()}
                displayMode="tab"
                style="bottom-menu"
                label={item.label}
                className={
                  !item.iconBadge || !item.iconBadgeVisible
                    ? undefined
                    : '-mb-6'
                }
                icon={
                  item.iconApp
                    ? item.iconApp
                    : item.icon
                    ? () => (
                        <IonIcon
                          className="text-2xl"
                          icon={item.icon}
                          slot="icon-only"
                        />
                      )
                    : undefined
                }
                iconBadge={
                  !item.iconBadge || !item.iconBadgeVisible
                    ? undefined
                    : () => (
                        <div className="relative -top-14 -right-6 border border-red-600 p-px bg-white rounded-full">
                          <item.iconBadge className="h-4 w-4 rounded-full bg-white text-red-600" />
                        </div>
                      )
                }
              />
            ))}
        </div>
      </IonToolbar>
    </>
  );
};
