import { useAppRouter } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { AnimLiveJourneyShoppingCartPanel } from './AnimLiveJourneyShoppingCartPanel';
import { AnimLiveJourneyShoppingCartPageStateState } from './useAnimLiveJourneyShoppingCartPageState.hook';

export const AnimLiveJourneyShoppingCartsListPanel = ({
  localState,
  children,
}: {
  localState: AnimLiveJourneyShoppingCartPageStateState;
  children?: React.ReactNode;
}) => {
  const appRouter = useAppRouter();
  const { formatMessage: t } = useIntl();
  const appId = appWebConfig().appId;

  const { customerJourneyId } = useParams<{ customerJourneyId: string }>();

  const {
    goBackToShopping,
    goBackToActivities,
    openOnlinePaymentPage,
    shoppingCartResumes,
    context,
    updateShoppingCartResume,
  } = localState;

  return (
    <div className="flex-grow flex flex-col gap-8">
      {(shoppingCartResumes ?? []).map((shoppingCartResume) => (
        <AnimLiveJourneyShoppingCartPanel
          mode={context === 'history' ? 'resume' : 'details'}
          key={shoppingCartResume.shoppingCart._id}
          shoppingCartResume={shoppingCartResume}
          goBack={goBackToActivities}
          openOnlinePaymentPage={openOnlinePaymentPage}
          updateShoppingCartResume={updateShoppingCartResume}
          onClick={() => {
            if (context === 'history') {
              appRouter.navigate(
                appRoutesBuilder.getJourneyShoppingCartDetailsUrl({
                  customerJourneyId,
                  shoppingCartId: shoppingCartResume.shoppingCart._id,
                }),
                {
                  cause: 'click-shopping-card-resume-panel',
                }
              );
            }
          }}
        />
      ))}
      {children && children}
    </div>
  );
};
