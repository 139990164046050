import { CMJourneyEditParticipant } from 'lib-common-model';
import { useMemo } from 'react';
import { useAppCacheContext } from '_01_CORE/app-contexts';
import { journeyHelper } from '_01_CORE/_services';
import { JourneyParticipantsListFormData } from '../JourneyParticipantsListFormData.type';

export const useJourneyParticipantsListAccroparkInitialState = () => {
  const { journeyEditData, auth } = useAppCacheContext();

  const editParticipants: CMJourneyEditParticipant[] = useMemo(() => {
    let participants: CMJourneyEditParticipant[] = (
      journeyEditData?.participants ?? []
    ).concat([]); // participants array copy (or empty array)
    if (journeyEditData?.journey) {
      const participantsCustomerChildIds = participants.map(
        (p) => p.customerChildId
      );

      if (auth?.customerProfile?.members) {
        // automatically add missing members
        const enableMembers = participants.length === 0;
        auth.customerProfile.members.forEach((member) => {
          if (!participantsCustomerChildIds.includes(member.customerChildId)) {
            const participant: CMJourneyEditParticipant = {
              enabled: enableMembers,
              customerChildId: member.customerChildId,
              member,
              attributes: {
                journeyAge: journeyHelper.getParticipantJourneyAge({
                  journeyEndDateInclusive:
                    journeyEditData?.journey.endDateInclusiveUTC,
                  memberBirthDate: member.birthDate,
                }),
              },
            };
            participants.push(participant);
          }
        });
      }

      if (auth?.customerProfile?.members) {
        // sort participants (except if first journey creation: let them in the user creation order)
        participants = journeyHelper.sortEditParticipants(participants);
      }
    }
    if (!participants.length) {
      participants.push({
        enabled: true,
        attributes: {} as any,
        member: {} as any,
      });
    }

    return participants;
  }, [auth?.customerProfile?.members, journeyEditData]);

  const defaultValues = useMemo<
    Partial<JourneyParticipantsListFormData>
  >(() => {
    const formData: JourneyParticipantsListFormData = {
      participants: editParticipants,
    };
    return formData;
  }, [editParticipants]);

  return { defaultValues, editParticipants };
};
