import { IonRouterLink } from '@ionic/react';
import React from 'react';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import './BannerCard.css';

export const BannerCard: React.FC<{
  reference: string;
  image?: string;
  title?: string;
  onClick?: (reference: string) => void;
  logo?: string;
}> = ({ reference, logo, image, title, onClick }) => {
  const appId = appWebConfig().appId;

  return (
    <IonRouterLink
      className={`info-theme ${onClick ? 'cursor-pointer' : ''}`}
      onClick={() => onClick(reference)}
    >
      <img
        className="infos-theme-image object-cover object-center w-full max-h-48"
        src={image}
      />
      {appId !== 'accropark' && <img className="infos-theme-logo" src={logo} />}{' '}
      {/* logo déjà visible sur l'image accropark */}
      {
        <div className="app-text--xl infos-theme-name">
          {title?.toUpperCase()}
        </div>
      }
    </IonRouterLink>
  );
};
