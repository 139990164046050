import {
  AppButtonV2,
  AppFormControl,
  AppIcons,
  AppToogleButtonGroupTailwind,
} from '_01_CORE/_components-core';
import { useAppRouter, useAppToasts } from '_01_CORE/_hooks';
import { useJourney } from '_01_CORE/app-contexts';
import { useNetworkStatus } from '_01_CORE/app-core/app-platform';
import clsx from 'clsx';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { JourneyDailyActivityCardParticipantsList } from '../../JourneyDailyActivityCardParticipantsList';
import {
  CustomerActivityParticipantsDailyActivitParticipantState,
  CustomerActivityParticipantsDailyActivity,
  CustomerActivityUpdateParticipantStateFn,
} from '../../model';
import { ActivitySessionDetailsDialogRegistrationPaidAreaSuccessMessage } from '../ActivitySessionDetailsDialogRegistrationPaidAreaSuccessMessage';
import {
  ActivitySessionDetailsDialogRegistrationPaidAreaLocalState,
  useActivitySessionDetailsDialogRegistrationPaidAreaLocalState,
} from './useActivitySessionDetailsDialogRegistrationPaidAreaLocalState.hook';

export const ActivitySessionDetailsDialogRegistrationPaidArea = ({
  isOpen,
  onClose,
  dailyActivity,
  participants,
  companyReference,
  customerJourneyId,
  onClickParticipant,
}: {
  isOpen: boolean;
  onClose: () => any;
  dailyActivity: CustomerActivityParticipantsDailyActivity;
  participants: CustomerActivityParticipantsDailyActivitParticipantState[];
  companyReference: string;
  customerJourneyId: string;
  onClickParticipant: CustomerActivityUpdateParticipantStateFn;
}) => {
  const appRouter = useAppRouter();
  const { formatMessage: t } = useIntl();
  const { networkStatus } = useNetworkStatus();
  const { pushError } = useAppToasts();

  const { journey } = useJourney({
    customerJourneyId: customerJourneyId,
  });

  const localState: ActivitySessionDetailsDialogRegistrationPaidAreaLocalState = useActivitySessionDetailsDialogRegistrationPaidAreaLocalState(
    {
      onClose,
      dailyActivity,
      participants,
      companyReference,
      customerJourneyId,
      onClickParticipant,
    }
  );

  const { data, dialogs } = localState;

  const {
    company,
    session,
    hasUnpayedParticipant,
    isCancelButtonVisible,
    hasAllPayedParticipant,
    hasUnregisteredParticipant,
    hasRegisteredParticipant,
    participantPaymentModesOptions,
    appOnlinePaymentEnabled,
    onSitePaymentEnabled,
  } = data;

  const registeredParticipants = useMemo(
    () => participants.filter((x) => !x.isNotRegistered),
    [participants]
  );

  const unitType = session.activitySession?.registration?.unitType;
  const unitLabel = session.activitySession?.registration?.unitLabel;

  return (
    <div
      className={clsx(
        'my-2 mx-2 sm:mx-6 p-2 border-2 border-red-600',
        session.registrationStatus === 'open' || hasRegisteredParticipant
          ? 'border-green-600'
          : 'border-red-600'
      )}
    >
      <div
        className={
          session.registrationStatus === 'open' || hasRegisteredParticipant
            ? `text-green-600`
            : 'text-red-600'
        }
      >
        <div className="flex gap-2 justify-between">
          <p className={clsx('mb-3 font-bold text-justify')}>
            {t(
              {
                id: !isOpen
                  ? 'page16.legend.text.inscriptions-fermees'
                  : hasUnregisteredParticipant || unitType === 'product'
                  ? `page16.legend.text.selectionner-participants-unit-type-${unitType}`
                  : 'page16.legend.text.participants-inscrits',
              },
              { unitLabel: unitLabel?.toUpperCase() }
            )}
          </p>
          <div className="flex gap-1 items-center">
            {dailyActivity.activitySchedule.payment
              ?.appOnlinePaymentEnabled && (
              <div className="bg-gray-200 p-px rounded-full">
                <AppIcons.paymentOnline className="p-2 rounded-full bg-gray-50 w-12 h-12 text-blue-400" />
              </div>
            )}
            {dailyActivity.activitySchedule.payment?.onSitePaymentEnabled && (
              <div className="bg-gray-200 p-px rounded-full">
                <AppIcons.paymentOnSite className="p-2 rounded-full bg-gray-50 w-12 h-12 text-green-400" />
              </div>
            )}
          </div>
        </div>
        <JourneyDailyActivityCardParticipantsList
          className="mt-1"
          activitySession={dailyActivity.selectedSession.activitySession}
          companyReference={companyReference}
          participants={participants}
          onClickParticipant={({
            participantState,
            activitySession,
            action,
          }) => {
            return onClickParticipant({
              dailyActivity,
              participantState,
              action,
            });
          }}
        />

        {unitType === 'product' && registeredParticipants.length > 0 && (
          <div className="mt-4 mb-2 font-bold text-gray-800">
            {t(
              {
                id: `page16.legend.text.selectionner-participants-unit-type-${unitType}-resume`,
              },
              {
                unitLabel: unitLabel?.toUpperCase(),
                unitCount: registeredParticipants.length,
              }
            )}
          </div>
        )}

        {!hasUnpayedParticipant && hasAllPayedParticipant && (
          <ActivitySessionDetailsDialogRegistrationPaidAreaSuccessMessage className="my-2 text-gray-800 text-xs" />
        )}

        {hasUnpayedParticipant && (
          <div className="my-3">
            {journey.shoppingCartPaymentDeadlineRemainingTimeInMinutes > 0 && (
              <div className="my-4 text-app-danger text-center font-bold p-2">
                ⚠️{' '}
                {t(
                  { id: 'page16.booking.timeRemaining' },
                  {
                    time:
                      journey.shoppingCartPaymentDeadlineRemainingTimeInMinutes,
                  }
                )}
              </div>
            )}
            <AppFormControl
              // className={`my-2`}
              renderComponent={() => (
                <AppToogleButtonGroupTailwind
                  theme={'light'}
                  color="payment-mode"
                  items={participantPaymentModesOptions} // selected={selected}
                  onChanges={(paymentMode) => {
                    if (paymentMode === 'on-site' && !onSitePaymentEnabled) {
                      // pas d'inscription sur l'app avec paiement sur place
                      dialogs.showOnsitePaymentAppRegistrationDisabledDialog();
                    } else {
                      dialogs.addToShoppingCartConfirm();
                    }
                  }}
                />
              )}
            />
          </div>
        )}

        {hasUnpayedParticipant && isCancelButtonVisible && (
          <div className="my-5 flex flex-col-reverse sm:flex gap-2 text-gray-800">
            <AppButtonV2
              size="normal"
              className={`w-full inline-block`}
              buttonStyle="danger"
              icon={AppIcons.actionDelete}
              onClick={() => {
                dialogs.clearShoppingCartConfirm();
              }}
            >
              Annuler
            </AppButtonV2>
          </div>
        )}
      </div>
    </div>
  );
};
