import { IonButton, IonIcon, IonItem, IonItemGroup } from '@ionic/react';
import {
  AppPage,
  CompanyImage,
  HeaderToolbar,
  PageContainer,
  PageTitle,
} from '_01_CORE/_common-layout';
import {
  AppButtonV2,
  AppIcons,
  dateTester,
  useAppModalContext,
} from '_01_CORE/_components-core';
import { JourneyCard } from '_01_CORE/_components-core/JourneyCard';
import { JourneyParticipantCard } from '_01_CORE/_components-core/JourneyParticipantCard';
import { useAppRouter } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import { useAppCacheContext, useJourney } from '_01_CORE/app-contexts';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import { AccroparkBookingResumeTable } from '_03_ACCROPARK/ECO-ecommerce';
import { arrowBackOutline, checkmark, pencil } from 'ionicons/icons';
import { appLogger } from 'lib-web-logger';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';

const appId = appWebConfig().appId;

export const journeyTitleFromStatusMap = {
  current: `page02.titleCurrent.${appId}`,
  last: `page02.titleLast.${appId}`,
  next: `page02.titleNext.${appId}`,
};

const ACCROPARK_BOOKING_REPLACEMENT_DELAY_HOURS = 3;
export const AccroparkJourneyHomePage = () => {
  const appRouter = useAppRouter();
  const { formatMessage: t } = useIntl();

  const { customerJourneyId } = useParams<{ customerJourneyId: string }>();

  const { journey, journeyStatus, participants } = useJourney({
    customerJourneyId,
  });
  const [campingPlaceNumber, setCampingPlaceNumber] = useState(
    journey?.campingPlaceNumber
  );
  const {
    auth,
    companyValidReferenceCode,
    dispatchCacheContextAction,
  } = useAppCacheContext();

  const enableJourneyReplace = useMemo(
    () =>
      (auth?.isSuperAdmin ||
        auth?.securityUser.impersonate?.root?.profile !== 'company') && // only for customers and super-admin
      journey?.booking &&
      journey?.booking.status === 'completed' &&
      journey?.booking.paymentStatus === 'payment-accepted' &&
      dateTester.isFuture(
        journey?.booking?.calculated?.firstActivityDateTimeUTC,
        { delayHours: ACCROPARK_BOOKING_REPLACEMENT_DELAY_HOURS }
      ),
    [
      auth?.isSuperAdmin,
      auth?.securityUser.impersonate?.root?.profile,
      journey?.booking,
    ]
  );

  // const { company } = useCompany({ companyReference: journey?.company?.reference });

  const customerAccount = auth?.customerProfile?.account;

  useEffect(() => {
    if (
      journey?.company?.reference &&
      companyValidReferenceCode !== journey?.company?.reference
    ) {
      // auto-update selected company
      dispatchCacheContextAction({
        type: 'select-company',
        companyReference: journey?.company?.reference,
        context: 'journey-home-auto-select-company',
      });
    }
  }, [
    companyValidReferenceCode,
    dispatchCacheContextAction,
    journey?.company?.reference,
  ]);

  useEffect(() => {
    if (!journey) {
      appLogger.warn(
        '[AccroparkJourneyHomePage] No journey: redirect to /journeys'
      );
      appRouter.navigateAfterDelay(appRoutesBuilder.getJourneysUrl(), {
        cause: 'company-home-no-journey',
      });
    }
  }, [appRouter, journey]);

  const { openModal, closeModal } = useAppModalContext();

  const hasAnyOfferActivity = useMemo(
    () =>
      journey?.booking?.purchase.offers.some(
        (offer) =>
          offer.type === 'activity-session' ||
          offer.type === 'activity-session-product'
      ),
    [journey?.booking?.purchase.offers]
  );

  function replaceJourneyBooking() {
    openModal({
      title: t({
        id: journey?.booking?.purchase?.options?.weatherInsuranceEnabled
          ? 'component.journey.replace.modal.titleWithWeater'
          : 'component.journey.replace.modal.titleWithoutWeater',
      }),
      content: (
        <>
          <div className="italic text-sm">
            {t({
              id: journey?.booking?.purchase?.options?.weatherInsuranceEnabled
                ? 'component.journey.replace.modal.contentWithWeater'
                : 'component.journey.replace.modal.contentWithoutWeater',
            })}
          </div>
          <div className="mt-2 text-lg">
            {t({ id: 'component.journey.replace.modal.edit' })}
          </div>
        </>
      ),
      actions: (
        <>
          <IonButton color="secondary" onClick={closeModal} expand="block">
            <IonIcon icon={arrowBackOutline} className="mr-3" />
            {t({ id: 'modal.no' })}
          </IonButton>
          <IonButton
            color="primary"
            expand="block"
            onClick={() => {
              dispatchCacheContextAction({
                type: 'replace-journey',
                payload: {
                  journey,
                  customerAccount,
                },
                context: 'click-replace-journey',
              });
              closeModal();
              appRouter.navigate(
                appRoutesBuilder.getJourneyDatesEditUrl({
                  companyReference: journey?.company?.reference,
                }),
                {
                  cause: 'journey-home-replace-journey',
                }
              );
            }}
          >
            <IonIcon icon={checkmark} className="mr-3" />
            {t({ id: 'modal.yes' })}
          </IonButton>
        </>
      ),
    });
  }
  /**
   * Get title label which is different for Anim Live & Accropark
   */
  function getTitle() {
    return t({ id: journeyTitleFromStatusMap[journeyStatus] });
  }

  return !journey ? null : (
    <AppPage>
      <HeaderToolbar />
      <PageContainer className="text-center bg-gray-50" spacing="normal">
        <PageTitle
          title={getTitle()}
          // TODO TEMPORAIRE
          // backLink={appRoutesBuilder.getGroupHomeUrl({
          //   groupReference: journey.company.companyGroupReference,
          //   isAuthenticated: false,
          // })}
        />
        <CompanyImage image={journey.company?.image1} />
        <div className="flex flex-col space-y-8 max-w-md mx-auto">
          <IonItemGroup>
            <JourneyCard
              showActionEditJourney={true}
              showActionDeleteJourney={true}
              journey={journey}
              redirectOnDelete={true}
            />
            {participants.length > 0 && (
              <>
                {participants.map((p) => (
                  <IonItem key={`${p.journeyParticipantId}`}>
                    <JourneyParticipantCard
                      customerJourneyId={journey._id}
                      participant={p}
                      className="py-2"
                    />
                  </IonItem>
                ))}
              </>
            )}
          </IonItemGroup>
          {journey.booking && (
            <AccroparkBookingResumeTable
              booking={journey.booking}
              company={journey.company}
            />
          )}
          {journey.booking?.purchase?.giftCardOptions?.pdfRelativePath && (
            <AppButtonV2
              className="my-3"
              buttonStyle="primary-accropark"
              icon={AppIcons.actionDownload}
              href={`${appWebConfig().urls.cdnUpload}/${
                journey.booking?.purchase?.giftCardOptions?.pdfRelativePath
              }`}
              centerText={true}
            >
              <span className="uppercase">
                {t({ id: 'page02.jou-02.action.telecharger-carte-cadeau' })}
              </span>
            </AppButtonV2>
          )}
          {hasAnyOfferActivity && (
            <div className="text-red-500">
              {t({ id: 'component.journey.arrivalWarning' })}
            </div>
          )}

          {enableJourneyReplace && (
            <IonItemGroup>
              <IonButton color="primary" onClick={replaceJourneyBooking}>
                <IonIcon icon={pencil} className="mr-2" />
                {t({ id: 'component.journey.replace.accropark' })}
              </IonButton>
            </IonItemGroup>
          )}
        </div>
      </PageContainer>
    </AppPage>
  );
};
