import { DeviceInfo } from '@capacitor/device';
import { AppEnvConfigPublic, AppSettings } from 'lib-common-model';
import { attributeStoreFactory } from 'lib-web-redux';
import { AppNotificationsState } from '_01_CORE/providers';
import { baseStore } from '../../app-core/app-store/baseStore';

export const appStore = {
  appSettings: attributeStoreFactory.create<AppSettings>(
    baseStore,
    'app_settings'
  ),
  appEnvConfigPublic: attributeStoreFactory.create<AppEnvConfigPublic>(
    baseStore,
    'app_env_config_public'
  ),
  deviceToken: attributeStoreFactory.create<string>(
    baseStore,
    'app_deviceToken'
  ),
  deviceInfo: attributeStoreFactory.create<DeviceInfo>(
    baseStore,
    'app_deviceInfo'
  ),
  notificationsState: attributeStoreFactory.create<AppNotificationsState>(
    baseStore,
    'app_notificationsState',
    {}
  ),
};
