/* eslint-disable @typescript-eslint/no-unused-vars */

import { CSSProperties, ReactNode } from 'react';
import { AppFormLabel } from '../AppFormLabel';

export type AppFormControlV2BaseProps<T> = {
  required?: boolean;
  disabled?: boolean;
  value?: any;
};
export function AppFormControl<T>({
  label,
  required,
  hasError,
  disabled,
  renderComponent,
  helpDescription,
  style,
  className,
}: {
  label?: string;
  required?: boolean;
  hasError?: boolean;
  disabled?: boolean;
  helpDescription?: string;
  renderComponent: () => ReactNode;
  style?: CSSProperties;
  className?: string;
}) {
  return (
    <div
      className={`whitespace-nowrap flex flex-col ${className ?? ''}`}
      style={style}
    >
      <AppFormLabel
        className={'form-label pl-1'}
        label={label}
        required={required}
        hasError={hasError}
        disabled={disabled}
        helpDescription={helpDescription}
      >
        <div className="form-input">{renderComponent()}</div>
      </AppFormLabel>
    </div>
  );
}
