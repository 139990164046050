import { Observable } from 'rxjs';

// NOTE: impossible de mutualiser ça côté serveur, ça créé une erreur dans ts-node (l'observable passé en paramètre n'est pas reconnu)
// à retester quand ts-node supportera les project references

// En attenant:
// web: rxjsUtilWeb
// node: rxjsUtilNode

export const rxjsUtilWeb = {
  getSnapshot,
};

function getSnapshot<T>(observable: Observable<T>): T | undefined {
  let snapshot: T;
  const subscription = observable.subscribe((value) => {
    snapshot = value;
  });
  subscription.unsubscribe();
  return snapshot;
}
