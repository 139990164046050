import {
  IonButton,
  IonIcon,
  IonInput,
  IonItemGroup,
  IonRow,
  IonToggle,
} from '@ionic/react';
import { add, build, checkmark, trash } from 'ionicons/icons';
import {
  CMJourneyEditMember,
  CMJourneyEditParticipant,
} from 'lib-common-model';
import { useCallback, useMemo, useState } from 'react';
import {
  Controller,
  UseFieldArrayReturn,
  UseFormReturn,
  useWatch,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useJourneyEnabledActions } from '_01_CORE/app-contexts';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import {
  dataGenerator,
  fakeDataGenerator,
  FormError,
  JourneyParticipantCard,
  useAppModalContext,
} from '_01_CORE/_components-core';
import { AppInputIonic } from '_01_CORE/_components-core/AppInput';
import { CustomerGenderRadioGroup } from '_02_APP_COMMON/JOU-journey/_common';
import { JourneyParticipantInlineEditFormData } from '../JourneyParticipantInlineEditFormData.type';

export const JourneyCreateParticipantsFormAccropark = ({
  form,
  formParticipants,
  onSubmit,
}: {
  form: UseFormReturn<JourneyParticipantInlineEditFormData>;
  formParticipants: UseFieldArrayReturn<
    JourneyParticipantInlineEditFormData,
    'participants'
  >;
  onSubmit: () => void;
}) => {
  const { formatMessage: t } = useIntl();

  const { handleSubmit, formState, setValue, control, getValues } = form;
  const errors = formState.errors;
  const {
    fields,
    append,
    prepend,
    remove,
    swap,
    move,
    insert,
  } = formParticipants;

  const participantsFormValue = useWatch({
    control,
    name: 'participants',
  });

  const participantsCount = useMemo(() => participantsFormValue.length ?? 0, [
    participantsFormValue.length,
  ]);
  const [openParticipantIndex, setOpenParticipantIndex] = useState<number>(
    participantsFormValue.length > 1
      ? -1
      : participantsFormValue[0].member.firstName
      ? -1
      : 0
  );
  const { isAddJourneyParticipantEnabled } = useJourneyEnabledActions({});

  const { openModal, closeModal } = useAppModalContext();

  const autoFillForm = useCallback(
    (index: number) => {
      setValue(
        `participants[${index}].member.firstName` as any,
        fakeDataGenerator.getFirstName()
      );
      setValue(
        `participants[${index}].member.gender` as any,
        fakeDataGenerator.getGender()
      );
      setValue(
        `participants[${index}].attributes.journeyAge` as any,
        dataGenerator.number({
          min: 5,
          max: 30,
        })
      );
    },
    [setValue]
  );

  const handleParticipantDeleteClick = useCallback(
    (participantIndex: number) => {
      setOpenParticipantIndex(undefined);
      remove(participantIndex);
    },
    [remove]
  );
  const openDeleteParticipantConfirmationModal = useCallback(
    (pIndex: number) => {
      openModal({
        title: t({ id: 'modal.confirmation' }),
        content: <div>{t({ id: 'page12.deletion.confirm' })}</div>,
        actions: (
          <div className="float-right">
            <IonButton color="secondary" onClick={closeModal}>
              {t({ id: 'common.action.cancel' })}
            </IonButton>
            <IonButton
              color="primary"
              onClick={() => {
                handleParticipantDeleteClick(pIndex);
                closeModal();
              }}
            >
              {t({ id: 'common.action.ok' })}
            </IonButton>
          </div>
        ),
      });
    },
    [openModal, t, closeModal, handleParticipantDeleteClick]
  );

  /*
   * Add a participant : only add if all fields are filled in. else, focus the invalid form
   */
  const handleAddParticipant = useCallback(() => {
    const firstInvalidIndex = participantsFormValue.findIndex(
      (p: CMJourneyEditParticipant) =>
        // TODO il faudrait plutôt passer par la validation
        !p.attributes.journeyAge || !p.member.firstName || !p.member.gender
    );
    if (firstInvalidIndex !== -1) {
      setOpenParticipantIndex(firstInvalidIndex);
      return;
    }
    // setParticipantsCount(participantsCount + 1);
    const newParticipant: CMJourneyEditParticipant = {
      enabled: true,
      attributes: {
        journeyAge: undefined,
      },
      member: ({} as unknown) as CMJourneyEditMember,
    };
    append(newParticipant);
    setOpenParticipantIndex(participantsCount);
  }, [append, participantsCount, participantsFormValue]);

  return (
    <>
      {fields.map((p, index) => (
        <div className="w-full mb-2" key={p.id}>
          <div className="flex flex-col bg-white rounded-lg py-2">
            <div className="flex">
              <div
                className="cursor-pointer flex-1 text-left p-2 "
                onClick={() =>
                  setOpenParticipantIndex(
                    openParticipantIndex === index ? undefined : index
                  )
                }
              >
                {participantsFormValue[index] && (
                  <JourneyParticipantCard
                    customerJourneyId={undefined}
                    participant={participantsFormValue[index]}
                  />
                )}
              </div>
              <div className="flex-none text-right p-2">
                {appWebConfig().envId === 'dev' && (
                  <IonIcon
                    className="mr-2"
                    color="primary"
                    icon={build}
                    onClick={() => autoFillForm(index)}
                  />
                )}
                <Controller
                  render={({ field: { value, onChange } }) => (
                    <IonToggle
                      color="primary"
                      className="-mb-4"
                      onIonChange={(e) => onChange(e.detail.checked)}
                      checked={value}
                    />
                  )}
                  control={control}
                  name={`participants[${index}].enabled` as any}
                  defaultValue={true}
                />
              </div>
            </div>
            {openParticipantIndex === index && (
              <div
                className={`transition-all duration-300 ease-in-out truncate`}
              >
                {/* <Controller
                // hidden field to keep customerChildId
                render={({ field: { value, onChange } }) => (
                  <AppInputIonic value={value}>
                    <IonInput type="number" onIonChange={(e) => onChange(e)} />
                  </AppInputIonic>
                )}
                control={control}
                name={`participants[${index}].customerChildId`}
                defaultValue=""
              /> */}

                {/* First name */}
                <Controller
                  render={({ field: { value, onChange } }) => (
                    <AppInputIonic
                      className="mt-2"
                      required={true}
                      placeholder={t({ id: 'page12.firstName' })}
                      value={value}
                    >
                      <IonInput
                        autoCapitalize="on"
                        onIonChange={(e) => onChange(e)}
                      />
                    </AppInputIonic>
                  )}
                  control={control}
                  name={`participants[${index}].member.firstName` as any}
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: t({ id: 'validation.required' }),
                    },
                  }}
                />
                <FormError
                  error={
                    errors?.participants?.[index]?.member?.firstName?.message
                  }
                />

                {/* Gender */}
                <Controller
                  render={({ field: { value, onChange } }) => {
                    const journeyAge = getValues(
                      `participants[${index}].attributes.journeyAge` as any
                    );
                    const isMinor = journeyAge < 18;

                    return (
                      <CustomerGenderRadioGroup
                        value={participantsFormValue[index]?.member?.gender} // 'value' n'est pas toujours correctement mis à jour, donc on prend plutôt dans "participantsFormValue"
                        isMinor={isMinor}
                        onChange={(value) => {
                          onChange(value);
                        }}
                      />
                    );
                  }}
                  control={control}
                  name={`participants[${index}].member.gender` as any}
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: t({ id: 'validation.required' }),
                    },
                  }}
                />
                <FormError
                  error={errors?.participants?.[index]?.member?.gender?.message}
                />

                <div className="grid grid-cols-1 ml:grid-cols-2 gap-2">
                  <div>
                    {/* Age */}
                    <Controller
                      render={({ field: { value, onChange } }) => {
                        return (
                          <AppInputIonic
                            className="mt-2"
                            required={true}
                            placeholder={t({ id: 'page12.age' })}
                            value={value}
                          >
                            <IonInput
                              type="text"
                              autoCapitalize="on"
                              onIonChange={(e) => onChange(e)}
                            />
                          </AppInputIonic>
                        );
                      }}
                      control={control}
                      name={
                        `participants[${index}].attributes.journeyAge` as any
                      }
                      defaultValue=""
                      rules={{
                        required: {
                          value: true,
                          message: t({ id: 'validation.required' }),
                        },
                        min: {
                          value: 1,
                          message: t(
                            { id: 'validation.min.number' },
                            { number: 1 }
                          ),
                        },
                        max: {
                          value: 100,
                          message: t(
                            { id: 'validation.max.number' },
                            { number: 100 }
                          ),
                        },
                      }}
                    />
                    <FormError
                      error={
                        errors?.participants?.[index]?.attributes?.journeyAge
                          ?.message
                      }
                    />
                  </div>
                  <div className="flex items-center space-x-2">
                    {/* <AppFormLabel
                  required={false}
                  className={'my-4 form-label pl-1'}
                  label={`Avatar`}
                  hasError={false}
                  disabled={false}
                >
                  <AppInputPhotoRHFControl
                    control={control}
                    name={`participants[${index}].member.photo`}
                    url={`/children/images`}
                    preview={{
                      size: 'small',
                      onDeleteConfirmMessage: t({
                        id: 'page12.avatar.confirm',
                      }),
                    }}
                  />
                </AppFormLabel> */}
                    {participantsFormValue?.[index]?.customerChildId ? null : (
                      <div className="p-2">
                        <IonButton
                          color="danger"
                          onClick={() =>
                            openDeleteParticipantConfirmationModal(index)
                          }
                        >
                          <IonIcon icon={trash} />
                        </IonButton>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ))}

      <div className="text-center p-4">
        {isAddJourneyParticipantEnabled && (
          <IonItemGroup>
            <IonRow className="ion-justify-content-center">
              <IonButton
                type="submit"
                color="success"
                onClick={handleAddParticipant}
                shape="round"
                className="max-w-xs w-full"
              >
                <IonIcon className="mr-2" icon={add} />
                {t({ id: 'page12.addParticipantButton' })}
              </IonButton>
            </IonRow>
          </IonItemGroup>
        )}
        <IonItemGroup>
          <IonRow className="ion-justify-content-center">
            <IonButton
              color="primary"
              onClick={onSubmit}
              shape="round"
              className="max-w-xs w-full"
            >
              <IonIcon className="mr-2" icon={checkmark} />
              {t({ id: 'common.action.validate' })}
            </IonButton>
          </IonRow>
        </IonItemGroup>
      </div>
    </>
  );
};
