export const dataGenerator = { number, boolean, fromList, date };

function number({ min, max }: { min: number; max: number }): number {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function boolean(options?: { percentageTrue?: number }): boolean {
  if (!options?.percentageTrue) {
    options = {
      percentageTrue: 50,
    };
  }
  return number({ min: 1, max: 100 }) <= options.percentageTrue;
}

function fromList<T>(list: T[]): T {
  const length = list.length;
  const randomIndex = number({
    min: 0,
    max: length - 1,
  });
  return list[randomIndex];
}

type MinMaxOptions = {
  min: number;
  max: number;
};
function date(options: {
  refDate?: Date;
  years?: MinMaxOptions;
  days?: MinMaxOptions;
}): Date {
  const refDate = options.refDate ? options.refDate : new Date();

  const { days, years } = options;

  if (years) {
    const min = (years.min ? years.min : 0) * 365 * 24 * 60;
    const max = years.max * 365 * 24 * 60;
    const minutesDiff = number({ min, max });
    return new Date(refDate.getTime() + minutesDiff * 60 * 1000);
  }

  if (days) {
    const min = (days.min ? days.min : 0) * 24 * 60;
    const max = days.max * 24 * 60;
    const minutesDiff = number({ min, max });
    return new Date(refDate.getTime() + minutesDiff * 60 * 1000);
  }

  return refDate;
}
