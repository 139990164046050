import { useMemo } from 'react';
import { useAppCacheContext } from '../../hooks';

export function useGroup({ groupReference }: { groupReference: string }) {
  const { groups } = useAppCacheContext();

  const group = useMemo(() => {
    if (groups[groupReference]) {
      return groups[groupReference];
    }
  }, [groups, groupReference]);
  return group;
}
