import { ActivityCategory, CMJourney } from 'lib-common-model';
import { useMemo } from 'react';

export function useJourneyActivityCategory(
  journey: CMJourney,
  activityCategory: ActivityCategory
): boolean {
  return useMemo(
    () =>
      journey?.activityCategories !== undefined &&
      journey?.activityCategories.includes(activityCategory),
    [activityCategory, journey?.activityCategories]
  );
}
