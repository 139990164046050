import { IonButton, IonIcon, IonItem, IonItemGroup } from '@ionic/react';
import {
  arrowBackOutline,
  checkmark,
  closeOutline,
  pencil,
} from 'ionicons/icons';
import { appLogger } from 'lib-web-logger';
import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import {
  AppCacheContextModel,
  useAppCacheContext,
  useJourney,
} from '_01_CORE/app-contexts';
import { ecommerceBookingJourneyApiClient } from '_01_CORE/app-core/app-api';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import { useAppPlatform } from '_01_CORE/app-core/app-platform';
import {
  AppPage,
  CompanyImage,
  HeaderToolbar,
  PageContainer,
  PageTitle,
} from '_01_CORE/_common-layout';
import { useAppModalContext } from '_01_CORE/_components-core';
import { JourneyCard } from '_01_CORE/_components-core/JourneyCard';
import { JourneyParticipantCard } from '_01_CORE/_components-core/JourneyParticipantCard';
import { useAppRouter } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import { AccroparkBookingResumeTable } from '_03_ACCROPARK/ECO-ecommerce';
const appId = appWebConfig().appId;

export const AccroparkJourneyProPage = () => {
  const appRouter = useAppRouter();
  const { formatMessage: t } = useIntl();
  const { platform } = useAppPlatform();

  const appCacheContext = useAppCacheContext();

  const { customerJourneyId } = useParams<{ customerJourneyId: string }>();

  const { journey, participants } = useJourney({ customerJourneyId });
  const { booking } = useAppCacheContext();

  const { dispatchCacheContextAction } = useAppCacheContext();
  const { openModal, closeModal } = useAppModalContext();

  const { auth } = useAppCacheContext();
  const customerAccount = auth?.customerProfile?.account;

  useEffect(() => {
    if (!journey) {
      appLogger.warn('[JourneyProPage] No journey: redirect to /journeys');
      appRouter.navigateAfterDelay(appRoutesBuilder.getJourneysUrl(), {
        cause: 'journey-pro-no-journey',
      });
    }
  }, [appRouter, journey]);

  async function adminPayNow() {
    await ecommerceBookingJourneyApiClient
      .updateBookingPaymentStatus({
        customerJourneyId,
        paymentStatus: 'accepted',
      })
      .then(async () => {
        await reloadBooking(customerJourneyId, appCacheContext);
      });
  }
  async function adminPayLater() {
    await ecommerceBookingJourneyApiClient
      .updateBookingPaymentStatus({
        customerJourneyId,
        paymentStatus: 'pending',
      })
      .then(async () => {
        await reloadBooking(customerJourneyId, appCacheContext);
      });
  }

  const enableJourneyReplace = useMemo(
    () =>
      appId === 'accropark' &&
      journey?.booking &&
      journey?.booking.status === 'completed' &&
      journey?.booking.paymentStatus === 'payment-accepted',
    [journey?.booking]
  );

  return !journey ? null : (
    <AppPage>
      <HeaderToolbar />
      <PageContainer className="text-center bg-gray-50" spacing="normal">
        <PageTitle
          title={`${customerAccount.lastName ?? ''} ${
            customerAccount.firstName ?? ''
          } ${
            customerAccount.email || customerAccount.phone1
              ? `(${customerAccount.email}${
                  customerAccount.email && customerAccount.phone1 ? ' ' : ''
                }${customerAccount.phone1})`
              : ''
          }`}
        />
        <div>{customerAccount.locationArea}</div>
        <CompanyImage image={journey.company?.image1} />
        <div className="flex flex-col space-y-8 max-w-md mx-auto">
          <IonItemGroup>
            <JourneyCard
              showActionOpenJourney={true}
              showActionEditJourney={true}
              showActionDeleteJourney={true}
              journey={journey}
              redirectOnDelete={true}
              showDetails={true}
            />
            {participants.length > 0 && (
              <>
                {participants.map((p) => (
                  <IonItem key={`${p.journeyParticipantId}`}>
                    <JourneyParticipantCard
                      customerJourneyId={journey._id}
                      participant={p}
                      className="py-2"
                    />
                  </IonItem>
                ))}
              </>
            )}
          </IonItemGroup>
          <IonItemGroup>
            {journey.booking && (
              <AccroparkBookingResumeTable
                hideGiftCode={true}
                booking={journey.booking}
                company={journey.company}
              />
            )}
            {booking.paymentStatus !== 'payment-accepted' && (
              <>
                <IonButton
                  className="flex-1"
                  color="primary"
                  onClick={adminPayNow}
                  shape="round"
                  size="small"
                >
                  <IonIcon className="mr-2" icon={checkmark} />
                  Payer maintenant
                </IonButton>
              </>
            )}
            {booking.paymentStatus !== 'payment-later' && (
              <>
                <IonButton
                  className="flex-1"
                  color="danger"
                  onClick={adminPayLater}
                  shape="round"
                  size="small"
                >
                  <IonIcon className="mr-2" icon={closeOutline} />
                  Payer plus tard
                </IonButton>
              </>
            )}
            {enableJourneyReplace && (
              <IonItemGroup>
                <IonButton color="primary" onClick={replaceJourneyBooking}>
                  <IonIcon icon={pencil} className="mr-2" />
                  {t({ id: 'component.journey.replace.accropark' })}
                </IonButton>
              </IonItemGroup>
            )}
          </IonItemGroup>
        </div>
      </PageContainer>
    </AppPage>
  );
  function replaceJourneyBooking() {
    openModal({
      title: t({
        id: journey?.booking?.purchase?.options?.weatherInsuranceEnabled
          ? 'component.journey.replace.modal.titleWithWeater'
          : 'component.journey.replace.modal.titleWithoutWeater',
      }),
      content: (
        <>
          <div className="italic text-sm">
            {t({
              id: journey?.booking?.purchase?.options?.weatherInsuranceEnabled
                ? 'component.journey.replace.modal.contentWithWeater'
                : 'component.journey.replace.modal.contentWithoutWeater',
            })}
          </div>
          <div className="mt-2 text-lg">
            {t({ id: 'component.journey.replace.modal.edit' })}
          </div>
        </>
      ),
      actions: (
        <>
          <IonButton color="secondary" onClick={closeModal} expand="block">
            <IonIcon icon={arrowBackOutline} className="mr-3" />
            {t({ id: 'modal.no' })}
          </IonButton>
          <IonButton
            color="primary"
            expand="block"
            onClick={() => {
              dispatchCacheContextAction({
                type: 'replace-journey',
                payload: {
                  journey,
                  customerAccount,
                },
                context: 'click-replace-journey',
              });
              closeModal();
              appRouter.navigate(
                appRoutesBuilder.getJourneyDatesEditUrl({
                  companyReference: journey?.company?.reference,
                }),
                {
                  cause: 'journey-home-replace-journey',
                }
              );
            }}
          >
            <IonIcon icon={checkmark} className="mr-3" />
            {t({ id: 'modal.yes' })}
          </IonButton>
        </>
      ),
    });
  }
};
async function reloadBooking(
  customerJourneyId: string,
  appCacheContext: AppCacheContextModel
) {
  const booking = await ecommerceBookingJourneyApiClient.getJourneyBooking({
    customerJourneyId,
  });
  // add to local cache
  appCacheContext.dispatchCacheContextAction({
    type: 'set-booking',
    booking,
    context: 'pro-page-reload-booking',
  });
}
