import { IonIcon } from '@ionic/react';
import { chevronBack, chevronForward } from 'ionicons/icons';
import { MONTHS } from 'lib-common-model';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { AppDatePickerCtx } from './AppDatePickerContext';

/**
 * Month Selection Component
 * @param props
 */
export const AppMonthSelection: React.FC<Record<string, unknown>> = () => {
  const { viewYears, selectMonth, nextYear, prevYear, visible } = useContext(
    AppDatePickerCtx
  );
  const { formatMessage: t } = useIntl();
  const monthNames = MONTHS.map((m) => t({ id: `month.${m}` }));

  return (
    <div
      className="h-48"
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        gridTemplateRows: '2rem auto',
        alignItems: 'stretch',
      }}
    >
      <div className="flex" style={{ gridColumn: '1/5' }}>
        <button onClick={() => prevYear()}>
          <IonIcon icon={chevronBack} />
        </button>
        <button className="flex-grow" onClick={() => viewYears()}>
          {visible.year}
        </button>
        <button onClick={() => nextYear()}>
          <IonIcon icon={chevronForward} />
        </button>
      </div>

      {monthNames.map((month, index) => (
        <button key={`${month}-${index}`} onClick={() => selectMonth(index)}>
          {month.substring(0, 3)}
        </button>
      ))}
    </div>
  );
};
