import { useAppCacheContext } from '../../hooks';

export function useCompanyBooklet({
  companyReference,
}: {
  companyReference: string;
}) {
  const { companyBooklets } = useAppCacheContext();

  return companyBooklets[companyReference];
}
