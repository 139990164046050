import { useAppRouter } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import { useAppCacheContext } from '_01_CORE/app-contexts';
import { useCallback } from 'react';
import { AuthMultiLoginAnimLivePageBaseLocalState } from '../useAuthMultiLoginAnimLivePageLocalState.hook';

export function useAuthMultiLoginAction_RedirectToJourneyCreateDates(
  baseLocalState: AuthMultiLoginAnimLivePageBaseLocalState
) {
  const appCacheContext = useAppCacheContext();
  const appRouter = useAppRouter();

  const redirectToJourneyCreateDates = useCallback(
    ({
      emailAddress,
      companyReference,
    }: {
      emailAddress: string;
      companyReference: string;
    }) => {
      appCacheContext.dispatchCacheContextAction({
        type: 'initialize-journey-new-account',
        context: 'create-journey-with-new-account',
        payload: {
          companyReference,
          email: emailAddress,
        },
      });
      appRouter.navigate(
        appRoutesBuilder.getJourneyDatesEditUrl({
          companyReference,
        }),
        { cause: 'company-register' }
      );
    },
    [appCacheContext, appRouter]
  );

  return redirectToJourneyCreateDates;
}

export type AuthMultiLoginAction_RedirectToJourneyCreateDates = ReturnType<
  typeof useAuthMultiLoginAction_RedirectToJourneyCreateDates
>;
