import React from 'react';

export const Compose: React.FC<ComposeProps> = ({
  children,
  components = [],
}) => {
  return (
    <>
      {components.reduceRight((acc, Component) => {
        return <Component>{acc}</Component>;
      }, children)}
    </>
  );
};

export type ComposeProps = {
  components: React.ComponentType[];
};
