import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { appWebSentryConfigurer } from 'lib-web-common';
import React from 'react';
import ReactDOM from 'react-dom';
import { AppLocalizationContextProvider } from '_01_CORE/app-contexts/app-localization-context';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import App from './App';
import * as serviceWorker from './serviceWorker';

appWebSentryConfigurer.initSentry(appWebConfig());

ReactDOM.render(
  <AppLocalizationContextProvider>
    <App />
  </AppLocalizationContextProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

defineCustomElements(window);
