import { ActivityCategory } from 'lib-common-model';
import { dateTransformer } from '_01_CORE/_components-core';

export const customerJourneyActivitiesPageUrlManager = {
  getQueryParameters,
  buildSearchParams,
};

export const QUERY_PARAMS = {
  category: 'c',
  selectedDate: 'd',
  focusActivitySessionId: 'fas',
  focusActivityId: 'fac',
  focusActivityOpenDialog: 'fod',
};

export type CustomerJourneyActivitiesPageUrlParams = {
  category?: ActivityCategory;
  selectedDate?: Date;
  focusActivitySessionId?: string;
  focusActivityId?: string;
  focusActivityOpenDialog?: boolean;
};

function getQueryParameters(
  defaultValues?: CustomerJourneyActivitiesPageUrlParams
): CustomerJourneyActivitiesPageUrlParams {
  const params = new URLSearchParams(window.location.search);
  const focusActivitySessionId =
    params.get(QUERY_PARAMS.focusActivitySessionId) ??
    defaultValues?.focusActivitySessionId;

  const focusActivityId =
    params.get(QUERY_PARAMS.focusActivityId) ?? defaultValues?.focusActivityId;
  const category =
    (params.get(QUERY_PARAMS.category) as ActivityCategory) ??
    defaultValues?.category;
  const focusDateString = params.get(QUERY_PARAMS.selectedDate);
  const focusActivityOpenDialog =
    params.get(QUERY_PARAMS.focusActivityOpenDialog) === '1';

  let selectedDate: Date = defaultValues?.selectedDate;
  if (focusDateString) {
    const chunks = focusDateString.split('-');
    if (chunks.length === 3) {
      selectedDate = new Date(
        Date.UTC(
          parseInt(chunks[0], 10),
          parseInt(chunks[1], 10) - 1,
          parseInt(chunks[2], 10)
        )
      );
    }
  }

  return {
    category,
    selectedDate,
    focusActivitySessionId,
    focusActivityId,
    focusActivityOpenDialog,
  };
}

function buildSearchParams(params: CustomerJourneyActivitiesPageUrlParams) {
  const {
    category,
    selectedDate,
    focusActivitySessionId,
    focusActivityId,
    focusActivityOpenDialog,
  } = params;
  const searchParams: string[] = [];
  if (category) {
    searchParams.push(`${QUERY_PARAMS.category}=${category}`);
  }
  if (focusActivitySessionId) {
    searchParams.push(
      `${QUERY_PARAMS.focusActivitySessionId}=${focusActivitySessionId}`
    );
  }
  if (focusActivityId) {
    searchParams.push(`${QUERY_PARAMS.focusActivityId}=${focusActivityId}`);
  }
  if (focusActivityOpenDialog) {
    searchParams.push(`${QUERY_PARAMS.focusActivityOpenDialog}=1`);
  }
  if (selectedDate) {
    searchParams.push(
      `${QUERY_PARAMS.selectedDate}=${dateTransformer.formatUTC(
        selectedDate,
        'yyyy-MM-dd'
      )}`
    );
  }
  return searchParams;
}
