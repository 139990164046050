import { journeyHelper } from '_01_CORE/_services';
import { useAppCacheContext } from '_01_CORE/app-contexts';
import { journeyEditStateBuilder } from '_01_CORE/app-contexts/app-cache-context/reducer/journeyEditStateBuilder.service';
import {
  CMJourneyEditParticipant,
  CMJourneyParticipant,
} from 'lib-common-model';
import { useMemo } from 'react';
import { JourneyParticipantEditFormData } from './JourneyParticipantEditFormData.type';

export const useJourneyParticipantEditInitialState = ({
  participantIndex,
  customerChildId,
  customerJourneyId,
}: {
  participantIndex: number;
  customerChildId?: string;
  customerJourneyId?: string;
}): {
  participantIndex?: number;
  mode: 'create' | 'edit';
  existingParticipant: CMJourneyEditParticipant;
  journeyParticipant?: CMJourneyParticipant;
  defaultValues: Partial<JourneyParticipantEditFormData>;
} => {
  // NOTE: on utilise journeyEditData seulement si en cours de création de séjour

  const { auth, journeyEditData } = useAppCacheContext();

  const participants: CMJourneyEditParticipant[] = useMemo(
    () =>
      journeyHelper.sortEditParticipants(journeyEditData?.participants ?? []),
    [journeyEditData?.participants]
  );
  // sort participants or index will be wrong

  const {
    mode,
    existingParticipant,
    journeyParticipant,
  }: {
    mode: 'edit' | 'create';
    existingParticipant: CMJourneyEditParticipant;
    journeyParticipant?: CMJourneyParticipant;
  } = useMemo(() => {
    if (customerChildId) {
      const journey = (auth?.customerProfile?.journeys ?? []).find(
        (j) => j._id === customerJourneyId
      );
      if (journey) {
        const journeyParticipant = journey.participants.find(
          (p) => p.member.customerChildId === customerChildId
        );
        if (journeyParticipant) {
          return {
            mode: 'edit',
            existingParticipant: journeyEditStateBuilder.buildEditParticipant(
              journeyParticipant
            ),
            journeyParticipant,
          };
        }
      }
    }
    if (
      !isNaN(participantIndex) &&
      participantIndex >= 0 &&
      participantIndex <= participants.length
    ) {
      const existingParticipant: CMJourneyEditParticipant =
        participants[participantIndex];
      if (existingParticipant) {
        return {
          mode: 'edit',
          existingParticipant,
        };
      }
    }
    if (journeyEditData.remainingExternalParticipantsToCreate?.length) {
      return {
        mode: 'create',
        existingParticipant:
          journeyEditData.remainingExternalParticipantsToCreate[0],
      };
    }
    return {
      mode: 'create',
      existingParticipant: undefined as CMJourneyEditParticipant,
    };
  }, [
    auth?.customerProfile?.journeys,
    customerChildId,
    customerJourneyId,
    journeyEditData.remainingExternalParticipantsToCreate,
    participantIndex,
    participants,
  ]);

  const defaultValues = useMemo<Partial<JourneyParticipantEditFormData>>(() => {
    if (mode === 'edit') {
      return buildDefaultValueFromParticipant(existingParticipant);
    } else {
      // create
      if (existingParticipant) {
        // from external app
        return buildDefaultValueFromParticipant(existingParticipant);
      }
      const firstParticipant = participants.length
        ? participants[0]
        : undefined;
      if (firstParticipant) {
        // init some attributes from first participant
        return {
          country: firstParticipant.member.country,
          language: firstParticipant.member.language1,
          lastName: firstParticipant.member.lastName,
        };
      }
      // first participant: default value
      return {};
    }
  }, [mode, existingParticipant, participants]);

  return {
    participantIndex,
    mode,
    existingParticipant,
    journeyParticipant,
    defaultValues,
  };
};
function buildDefaultValueFromParticipant(
  existingParticipant: CMJourneyEditParticipant
) {
  const d = existingParticipant.member.birthDate;
  const birthDate = d ? new Date(d) : undefined;
  const formData: JourneyParticipantEditFormData = {
    lastName: existingParticipant.member.lastName,
    firstName: existingParticipant.member.firstName,
    birthDate,
    gender: existingParticipant.member.gender,
    country: existingParticipant.member.country,
    language: existingParticipant.member.language1,
    photo: existingParticipant.member.photo,
    memberComment: existingParticipant.member.memberComment,
    imageRights: existingParticipant.attributes.imageRights,
    swim: existingParticipant.attributes.swim,
    exitPermit: existingParticipant.attributes.exitPermit,
    journeyComment: existingParticipant.attributes.comment,
  };

  return formData;
}
