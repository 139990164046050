import { ActivityTranslation } from 'lib-common-model';
import React from 'react';
import { useIntl } from 'react-intl';
import { AppIcons } from '_01_CORE/_components-core';

export const ActivityTitleCardLocation = ({
  translation,
  className,
}: {
  translation: ActivityTranslation;
  className?: string;
}) => {
  const { formatMessage: t } = useIntl();

  return !translation?.location ? null : (
    <div className={`${className}`}>
      <AppIcons.location className="inline-block w-4 h-4 mr-1" />
      {/* <span className="text-gray-400 font-bold">LIEU:</span>{' '} */}
      {translation.location}
    </div>
  );
};
