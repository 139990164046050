import { IonAvatar, IonButton, IonIcon, IonItem, IonLabel } from '@ionic/react';
import { arrowBackOutline, pencil, pencilSharp, trash } from 'ionicons/icons';
import { CMJourney } from 'lib-common-model';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import {
  useAppCacheContext,
  useJourneyEnabledActions,
} from '_01_CORE/app-contexts';
import { customerJourneyApiClient } from '_01_CORE/app-core/app-api';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import { useAppImageUrl } from '_01_CORE/app-core/app-security/useAppImageUrl.hook';
import {
  useAppRouter,
  useAppToasts,
  useOperationPending,
} from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import { useCampingPlaceNumberVisibility } from '_02_APP_COMMON/JOU-journey/_common';
import { useAppModalContext } from '../AppModal';
import { dateTransformer } from '../date';

export const JourneyCard: React.FC<{
  journey: CMJourney;
  redirectOnDelete: boolean;
  onDelete?: () => void;
  showActionOpenJourney?: boolean;
  showActionEditJourney?: boolean;
  showActionEditDates?: boolean;
  showActionDeleteJourney?: boolean;
  showDetails?: boolean;
}> = ({
  showDetails,
  journey,
  redirectOnDelete,
  showActionOpenJourney,
  showActionEditDates,
  showActionEditJourney,
  showActionDeleteJourney,
}) => {
  const appRouter = useAppRouter();
  const { formatMessage: t } = useIntl();
  const { pushError } = useAppToasts();
  const { auth, dispatchCacheContextAction } = useAppCacheContext();
  const { openModal, closeModal } = useAppModalContext();
  const appId = appWebConfig().appId;
  const customerAccount = auth?.customerProfile?.account;
  const { booking, company, beginDateUTC, endDateInclusiveUTC } = journey;

  const logo = useAppImageUrl(company?.logo, {
    defaultImage: 'app',
  });

  const {
    isJourneyEditEnabled,
    isJourneyDeleteEnabled,
  } = useJourneyEnabledActions({ journey });

  const {
    isVisibleCampingPlaceNumber,
    isEditableCampingPlaceNumber,
  } = useCampingPlaceNumberVisibility(journey);

  const onClickOpenJourney = useCallback(() => {
    if (showActionOpenJourney) {
      appRouter.navigate(
        appRoutesBuilder.getJourneyHomeUrl({ customerJourneyId: journey._id }),
        { cause: 'open-journey' }
      );
    }
  }, [showActionOpenJourney, appRouter, journey._id]);

  const bookingTime =
    booking?.purchase?.offers?.[0]?.meta?.activitySession?.beginDateTime;

  const actionsEnableEdit = showActionEditJourney || showActionEditDates;
  const onClickEditJourney = useCallback(() => {
    if (journey?.draftBooking?.type === 'gift-card') {
      return appRouter.navigate(
        appRoutesBuilder.getGiftCardEditPage({
          bookingId: journey?.draftBooking?._id,
          companyReference: journey?.company?.reference,
        }),
        {
          cause: 'edit-journey',
        }
      );
    }
    if (showActionEditJourney) {
      dispatchCacheContextAction({
        type: 'set-journey-to-edit',
        payload: {
          journey,
          customerAccount,
        },
      });
      appRouter.navigate(appRoutesBuilder.getJourneyEditUrl({ journey }), {
        cause: 'edit-journey',
      });
    } else {
      if (showActionEditDates) {
        appRouter.navigate(
          appRoutesBuilder.getJourneyEditDatesUrl({
            customerJourneyId: journey._id,
          }),
          { cause: 'edit-journey-dates' }
        );
      }
    }
  }, [
    showActionEditDates,
    showActionEditJourney,
    appRouter,
    customerAccount,
    dispatchCacheContextAction,
    journey,
  ]);

  const deleteJourney = useOperationPending(() => {
    dispatchCacheContextAction({
      type: 'delete-journey',
      customerJourneyId: journey._id,
      context: 'journey-card-delete',
    });
    return customerJourneyApiClient.deleteJourney({
      customerJourneyId: journey._id,
    });
  }, [dispatchCacheContextAction, journey._id]);

  const openshowActionDeleteJourneyModal = useCallback(() => {
    openModal({
      title: t({ id: 'component.journey.delete.title' }),
      actions: (
        <>
          <IonButton color="secondary" onClick={closeModal} expand="block">
            <IonIcon icon={arrowBackOutline} className="mr-3" />
            {t({ id: 'modal.no' })}
          </IonButton>
          <IonButton
            color="danger"
            expand="block"
            onClick={async () => {
              try {
                closeModal();
                if (redirectOnDelete) {
                  appRouter.navigate(appRoutesBuilder.getJourneysUrl(), {
                    cause: 'delete-journey',
                  });
                }
                await deleteJourney();
              } catch (e) {
                pushError((e as any).message);
              }
            }}
          >
            <IonIcon icon={trash} className="mr-3" />
            {t({ id: 'modal.yes' })}
          </IonButton>
        </>
      ),
    });
  }, [
    openModal,
    t,
    closeModal,
    redirectOnDelete,
    deleteJourney,
    appRouter,
    pushError,
  ]);

  return (
    <>
      <IonItem lines="full">
        <IonAvatar
          className={
            showActionOpenJourney ? 'cursor-pointer' : 'cursor-default'
          }
          onClick={onClickOpenJourney}
          slot="start"
        >
          {logo && <img src={logo} />}
        </IonAvatar>
        <IonLabel>
          <div className="flex flex-row">
            <div
              className={`flex-1 ${
                showActionOpenJourney ? 'cursor-pointer' : 'cursor-default'
              }`}
              onClick={onClickOpenJourney}
            >
              <div className="flex-1 flex">
                <h2 className="flex-grow whitespace-normal">{company.name}</h2>
                {showDetails &&
                  isVisibleCampingPlaceNumber &&
                  journey?.campingPlaceNumber && (
                    <div className={`text-xs font-bold text-gray-400`}>
                      {journey.campingPlaceNumber}
                    </div>
                  )}
              </div>
              <div className="flex flex-wrap text-tertiary text-sm gap-x-4">
                {appId === 'anim-live' ? (
                  <>
                    <span>
                      {t({ id: 'component.journey.startDate' })}:{' '}
                      {dateTransformer.formatUTC(
                        beginDateUTC,
                        t({ id: 'common.date.format' })
                      )}
                    </span>
                    <span>
                      {t({ id: 'component.journey.endDate' })}:{' '}
                      {dateTransformer.formatUTC(
                        endDateInclusiveUTC,
                        t({ id: 'common.date.format' })
                      )}
                    </span>
                  </>
                ) : booking?.type === 'activities' ? (
                  bookingTime && (
                    <span>
                      {t(
                        {
                          id:
                            journey.status === 'draft'
                              ? 'component.journey.the'
                              : 'component.journey.theAt',
                        },
                        {
                          date: dateTransformer.formatUTC(
                            bookingTime,
                            t({ id: 'common.date.format' })
                          ),
                          time: dateTransformer.formatUTC(bookingTime, 'HH:mm'),
                        }
                      )}
                    </span>
                  )
                ) : booking?.type === 'gift-card' ? (
                  <span>
                    {t({
                      id: 'accropark.ecommerce.booking-card.offer.gift-card',
                    })}
                  </span>
                ) : null}
              </div>
            </div>
            {actionsEnableEdit && isJourneyEditEnabled && (
              <div
                className="flex flex-wrap text-tertiary text-sm gap-x-4 ml-2 cursor-pointer"
                onClick={onClickEditJourney}
              >
                <span className="flex flex-col">
                  <IonIcon
                    className="w-6 h-6 m-auto text-primary"
                    icon={pencilSharp}
                  />
                  <span className="text-primary uppercase text-app-xxs1 sm:text-xs">
                    {t({ id: `component.journey.edit.${appId}` })}
                  </span>
                </span>
              </div>
            )}
            {showActionDeleteJourney && isJourneyDeleteEnabled && (
              <div
                className="flex flex-wrap text-tertiary text-sm gap-x-4 ml-2 cursor-pointer"
                onClick={openshowActionDeleteJourneyModal}
              >
                <span className="flex flex-col">
                  <IonIcon
                    className="w-6 h-6 m-auto"
                    color="danger"
                    icon={trash}
                  />
                  <span className="text-primary uppercase text-app-xxs1 sm:text-xs">
                    {t({ id: 'component.journey.delete' })}
                  </span>
                </span>
              </div>
            )}
          </div>
        </IonLabel>
      </IonItem>
      {journey.status === 'draft' ||
      journey.status === 'booking-in-progress' ? (
        <IonItem>
          <div className="w-full text-center py-2">
            <div className="text-warning font-bold">
              {t({ id: 'page02.booking.inProgress' })}
            </div>
            <div className="grid grid-cols-1 ml:grid-cols-2">
              {actionsEnableEdit && (
                <IonButton
                  color="warning"
                  onClick={onClickEditJourney}
                  className={
                    journey.status !== 'draft' && !isJourneyDeleteEnabled
                      ? 'col-span-2'
                      : ''
                  }
                >
                  <IonIcon icon={pencil} className="mr-2 text-white" />
                  <span className="text-white">
                    <span className="uppercase text-app-xxs1 sm:text-xs">
                      {t({ id: `component.journey.edit.${appId}` })}
                    </span>
                  </span>
                </IonButton>
              )}
              {showActionDeleteJourney &&
              (journey.status === 'draft' || isJourneyDeleteEnabled) ? (
                <IonButton
                  color="danger"
                  onClick={openshowActionDeleteJourneyModal}
                >
                  <IonIcon icon={trash} className="mr-2" />
                  <span className="uppercase text-app-xxs1 sm:text-xs">
                    {t({ id: 'component.journey.delete' })}
                  </span>
                </IonButton>
              ) : null}
            </div>
          </div>
        </IonItem>
      ) : null}
    </>
  );
};
