import {
  AppButtonUniverse,
  AppIcons,
  AppLoader,
} from '_01_CORE/_components-core';
import { AppInput } from '_01_CORE/_components-core/AppInput';
import { useAppRouter, useAppToasts } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import { useCompany } from '_01_CORE/app-contexts';
import { appWebConfig, useAppFeatures } from '_01_CORE/app-core/app-config';
import { useNetworkStatus } from '_01_CORE/app-core/app-platform';
import { useAppSettings } from '_01_CORE/app-core/app-security';
import { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { AuthMultiLoginAnimLivePageLocalState } from '../useAuthMultiLoginAnimLivePageLocalState.hook';

type AuthPageMultiLoginEmailPanelForm = {
  emailAddress: string;
  password: string;
};

export const AuthPageMultiLoginEmailPanel = ({
  localState,
}: {
  localState: AuthMultiLoginAnimLivePageLocalState;
}) => {
  const [persistInProgress, setPersistInProgress] = useState(false);

  const appRouter = useAppRouter();
  const { formatMessage: t } = useIntl();

  const { viewState, actions } = localState;
  const journeyInfo = viewState.journeyInfo;
  const fetchedJourney = journeyInfo?.fetchedJourney;
  const companyReference = fetchedJourney?.companyReference;
  const { company } = useCompany({
    companyReference,
  });
  const { pushError, pushSuccess } = useAppToasts();
  const { networkStatus } = useNetworkStatus();

  const defaultAuthLogin = appWebConfig().authentication.defaultAuthLogin;

  const initialFormValue: AuthPageMultiLoginEmailPanelForm = {
    emailAddress:
      viewState?.promptEmail?.defaultEmail ?? defaultAuthLogin?.login ?? '',
    password: defaultAuthLogin?.password,
  };

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState,
  } = useForm<AuthPageMultiLoginEmailPanelForm>({
    defaultValues: initialFormValue,
    reValidateMode: 'onChange',
  });

  const [emailAddress] = useWatch({
    control,
    name: ['emailAddress'],
  });

  const { isFeatureAuthEnabled } = useAppFeatures();

  const appSettings = useAppSettings();
  const title1Color = appSettings?.universe?.theme?.title1Color;

  const isEmailVisible = true;

  const emailReadOnly = viewState?.promptEmail?.emailReadOnly;

  const isPasswordVisible = viewState?.promptEmail?.showPassword;

  const isRegistrationNotPossibleWithoutBooking =
    viewState?.promptEmail?.isRegistrationNotPossibleWithoutBooking;

  return persistInProgress ? (
    <AppLoader
      className="my-4 p-2 px-4"
      type="persist"
      message="Connexion en cours"
    />
  ) : (
    <form className="w-full bg-gray-50 rounded-xl border border-gray-200 pt-4 pb-8 px-8">
      {isFeatureAuthEnabled && (
        <>
          <div className="w-full flex flex-col gap-2 items-stretch">
            <h2
              className={`text-left text-base md:text-xl font-bold my-4`}
              style={{ color: title1Color }}
            >
              {isPasswordVisible
                ? `Vos identifiants de connexion:`
                : `Votre adresse e-mail:`}
            </h2>
            {isEmailVisible && (
              <div className="w-full">
                <AppInput
                  required={true}
                  autofocus={true}
                  disabled={emailReadOnly}
                  placeholder={t({ id: 'form.label.email' })}
                  {...register('emailAddress', {
                    required: true,
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: t({ id: 'validation.email' }),
                    },
                  })}
                  onChange={(e) => {
                    const emailAddress = (e as any).target.value as string;
                    setValue('emailAddress', emailAddress, {
                      shouldValidate: true,
                    });
                  }}
                  type="email"
                  icon={AppIcons.mail}
                />
              </div>
            )}
            {isPasswordVisible && (
              <div className="w-full">
                <AppInput
                  required={true}
                  autofocus={true}
                  placeholder={t({ id: 'form.label.password' })}
                  {...register('password', {
                    required: true,
                  })}
                  type="password"
                  icon={AppIcons.password}
                  onChange={(e) => {
                    const password = (e as any).target.value as string;
                    setValue('password', password, {
                      shouldValidate: true,
                    });
                  }}
                />
              </div>
            )}
            {isRegistrationNotPossibleWithoutBooking && (
              <div className="my-4 text-left text-gray-600 grid gap-4">
                <div className="font-bold text-app-danger">
                  {`Aucun séjour reconnu avec cet email.`}
                </div>
                <div className="">
                  {`Si vous n'avez pas reçu de clé d'activation, merci de contacter le camping.`}
                </div>
              </div>
            )}

            {!isRegistrationNotPossibleWithoutBooking && (
              <div className="text-left">
                <AppButtonUniverse
                  style={'primary'}
                  className={`mt-4 uppercase`}
                  type="submit"
                  disabled={!formState.isValid}
                  onClick={(e) => {
                    // bouton submit pour soumettre quand on appuie sur ENTER
                    e.stopPropagation();
                    e.preventDefault();
                    handleSubmit(
                      (formValue) => {
                        // valid form
                        setPersistInProgress(true);
                        try {
                          if (isPasswordVisible) {
                            actions.authenticateByPassword({
                              login: formValue.emailAddress,
                              password: formValue.password,
                            });
                          } else {
                            actions.testEmail({
                              emailAddress: formValue.emailAddress,
                            });
                          }
                        } finally {
                          setPersistInProgress(false);
                        }
                      },
                      (errors) => {
                        // invalid form
                        console.error('invalid form', errors);
                        pushError(
                          t({ id: 'common.message.error.invalid-form' })
                        );
                      }
                    )();
                  }}
                >
                  Continuer
                </AppButtonUniverse>
              </div>
            )}
            {isPasswordVisible && (
              <div className="text-center text-sm underline mt-2 cursor-pointer">
                <a
                  onClick={() => {
                    appRouter.navigate(
                      appRoutesBuilder.getAccountResetPasswordQueryUrl({
                        login: emailAddress,
                      }),
                      {
                        cause: 'click-forgot-password',
                      }
                    );
                  }}
                >
                  {t({ id: 'page01.forgotPassword' })}
                </a>
              </div>
            )}
          </div>
        </>
      )}
    </form>
  );
};
