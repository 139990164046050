import { CMJourney } from 'lib-common-model';
import { useMemo } from 'react';
import { useAppSettings } from '_01_CORE/app-core/app-security';
import { useAppCacheContext } from '../../useAppCacheContext.hook';
import { JourneyEnabledActions } from './JourneyEnabledActions.type';
import { journeyEnabledActionsBuilder } from './journeyEnabledActionsBuilder.service';

export function useJourneyEnabledActions({
  journey,
}: {
  journey?: CMJourney;
}): JourneyEnabledActions {
  const { auth } = useAppCacheContext();

  const securityUser = auth?.securityUser;
  const appSettings = useAppSettings();

  return useMemo(
    () =>
      journeyEnabledActionsBuilder.buildJourneyEnabledActions({
        journey,
        securityUser,
        appSettings,
      }),
    [appSettings, journey, securityUser]
  );
}
