import {
  AppButtonCompany,
  AppFormControlRHF,
  AppIcons,
  AppLoader,
  AppToogleButtonGroupRHF,
} from '_01_CORE/_components-core';
import { useCompany } from '_01_CORE/app-contexts';
import { ActivitySessionDetailsDialogRegistrationPaidAreaWarningMessage } from '_02_APP_COMMON/ACT-activity/ACT-16-journey-activities/JourneyDailyActivity/components';
import clsx from 'clsx';
import { CMShoppingCartResume, ParticipantPaymentMode } from 'lib-common-model';
import { useIntl } from 'react-intl';
import { AnimLiveShoppingCartTable } from '../_common';
import { useParticipantPaymentModeOptions } from '../_common/AnimLiveShoppingCartTable/useCompanyPmsModeOptions.hook';
import { AnimLiveCompanyPlaceDescription } from './AnimLiveCompanyPlaceDescription';
import {
  AnimLiveJourneyShoppingCartPanelState,
  useAnimLiveJourneyShoppingCartPanelState,
} from './useAnimLiveJourneyShoppingCartPanelState.hook';

export type AnimLiveShoppingCartTableFormModel = {
  paymentMode: ParticipantPaymentMode;
};
export const AnimLiveJourneyShoppingCartPanel = ({
  updateShoppingCartResume,
  shoppingCartResume,
  mode,
  onClick,
  openOnlinePaymentPage,
  goBack,
}: {
  updateShoppingCartResume: (resume: CMShoppingCartResume) => any;
  shoppingCartResume: CMShoppingCartResume;
  mode: 'resume' | 'details';
  onClick?: () => any;
  openOnlinePaymentPage: (shoppingCartResume: CMShoppingCartResume) => any;
  goBack: () => any;
}) => {
  const { formatMessage: t } = useIntl();

  const localState: AnimLiveJourneyShoppingCartPanelState = useAnimLiveJourneyShoppingCartPanelState(
    {
      shoppingCartResume,
      goBack,
      updateShoppingCartResume,
    }
  );

  const {
    isPaymentPending,
    form,
    isEmptyShoppingCart,
    operationInProgress,
    shoppingCartItemsOnSiteAndOnSiteChoice,
    confirmBooking,
    deleteBookingConfirm,
    onSitePaymentPlaces,
    unconfirmBooking,
  } = localState;

  const {
    register,
    handleSubmit,
    watch,
    formState,
    control,
    setValue,
    reset,
  } = form;

  const {
    companyReference,
    shoppingCart,
    shoppingCartItems,
  } = shoppingCartResume;

  const { company } = useCompany({
    companyReference,
  });

  const appOnlinePaymentEnabled =
    company?.options?.payments?.online?.activityRegistration?.enabled;

  const onSitePaymentEnabled =
    !appOnlinePaymentEnabled ||
    company?.options?.payments?.onSite?.forceEnableAppRegistration;

  const participantPaymentModesOptions = useParticipantPaymentModeOptions({
    appOnlinePaymentEnabled,
    onSitePaymentEnabled,
    format: 'full',
  });

  return !shoppingCartResume ? null : (
    <div
      className={clsx(
        'flex flex-col items-stretch text-center',
        onClick && 'cursor-pointer'
      )}
      onClick={onClick}
    >
      <>
        {!!shoppingCartResume && (
          <>
            {mode === 'details' &&
              isPaymentPending &&
              shoppingCartResume.paymentDeadlineRemainingTimeInMinutes > 0 && (
                <>
                  <div className="my-4 text-app-danger text-center font-bold p-2">
                    ⚠️{' '}
                    {t(
                      { id: 'page16.booking.timeRemaining' },
                      {
                        time:
                          shoppingCartResume.paymentDeadlineRemainingTimeInMinutes,
                      }
                    )}
                  </div>
                </>
              )}
            {!!shoppingCartResume?.shoppingCart && (
              <AnimLiveShoppingCartTable
                shoppingCartResume={shoppingCartResume}
                mode={mode}
                form={form}
              />
            )}
            {isEmptyShoppingCart && (
              <div className="my-4 bg-gray-400 text-center text-white font-semibold p-2">
                Votre panier est vide
              </div>
            )}
            {operationInProgress ? (
              <AppLoader type={operationInProgress.type} className="my-4" />
            ) : (
              <>
                {mode === 'details' && (
                  <>
                    {shoppingCartResume?.shoppingCart?.status === 'draft' && (
                      <div className="flex flex-col gap-4">
                        {isPaymentPending && (
                          <>
                            {
                              <div className="mb-2 text-center">
                                {/* <div className="flex gap-1 font-bold uppercase text-gray-800 py-1 px-2 items-center justify-center text-base">
                                <AppIcons.paymentOnSite className="inline w-5 h-5 text-green-400" />
                                <AppIcons.paymentOnline className="inline w-5 h-5 text-blue-400" />
                                <div className="">Moyen de paiement</div>
                                <div className="bg-gray-600 text-white px-1.5"></div>
                              </div> */}
                                <AppFormControlRHF
                                  className={`w-full`}
                                  // label="Moyen de paiement"
                                  control={control}
                                  name={'paymentMode'}
                                  renderComponent={(props) => (
                                    <AppToogleButtonGroupRHF
                                      theme={'light'}
                                      {...props}
                                      color="payment-mode"
                                      items={participantPaymentModesOptions} // selected={selected}
                                      onChanges={(paymentMode) => {
                                        confirmBooking({
                                          paymentMode,
                                          redirectToOnlinePaymentPage:
                                            paymentMode === 'online',
                                        });
                                      }}
                                    />
                                  )}
                                />
                              </div>
                            }
                          </>
                          // <>
                          //     {shoppingCartItemsOnSiteAndOnSiteChoice.length >
                          //     0 ? (
                          //       <AppButtonCompany
                          //         className="w-full font-bold"
                          //         isFullWidth={true}
                          //         icon={AppIcons.paymentOnline}
                          //         onClick={(e) => {
                          //           e.stopPropagation();
                          //           confirmBooking({
                          //             redirectToOnlinePaymentPage: false,
                          //           });
                          //         }}
                          //         style={'primary'}
                          //       >
                          //         Confirmer ma réservation
                          //       </AppButtonCompany>
                          //     ) : (
                          //       <AppButtonCompany
                          //         className="w-full font-bold"
                          //         isFullWidth={true}
                          //         icon={AppIcons.paymentOnline}
                          //         onClick={(e) => {
                          //           e.stopPropagation();
                          //           confirmBooking({
                          //             redirectToOnlinePaymentPage: true,
                          //           });
                          //         }}
                          //         style={'primary'}
                          //       >
                          //         Payer maintenant
                          //       </AppButtonCompany>
                          //     )}
                          //   </>
                        )}
                        <AppButtonCompany
                          className="w-full font-bold"
                          isFullWidth={true}
                          icon={AppIcons.actionDelete}
                          onClick={(e) => {
                            e.stopPropagation();
                            deleteBookingConfirm();
                          }}
                          style={'danger'}
                        >
                          Abandonner ma réservation
                        </AppButtonCompany>
                      </div>
                    )}
                    {shoppingCartResume?.shoppingCart?.status ===
                      'payment-pending' &&
                      shoppingCartResume?.shoppingCart?.paymentStatus ===
                        'payment-pending' && (
                        <div className="flex flex-col gap-4">
                          {isPaymentPending && (
                            <>
                              {appOnlinePaymentEnabled &&
                              shoppingCartResume?.shoppingCart?.paymentMode ===
                                'online' ? (
                                <AppButtonCompany
                                  className="w-full font-bold"
                                  isFullWidth={true}
                                  icon={AppIcons.paymentOnline}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    openOnlinePaymentPage(shoppingCartResume);
                                  }}
                                  style={'primary'}
                                >
                                  {t({
                                    id:
                                      'common.action.continue.booking.payment',
                                  })}
                                </AppButtonCompany>
                              ) : (
                                <div>
                                  <p className="my-3 text-justify font-bold text-gray-800">
                                    {t({
                                      id:
                                        'page16.activity-session-details-dialog.vos-places-sont-reservees',
                                    })}
                                  </p>
                                  <ActivitySessionDetailsDialogRegistrationPaidAreaWarningMessage className="my-3 bg-red-600 px-2 py-1 text-white" />
                                  {onSitePaymentPlaces?.length > 0 && (
                                    <div className="text-left grid gap-2">
                                      <b>Lieu de paiement: </b>
                                      {onSitePaymentPlaces.map(
                                        (companyPlace) => (
                                          <AnimLiveCompanyPlaceDescription
                                            key={companyPlace._id}
                                            companyPlace={companyPlace}
                                          />
                                        )
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}
                            </>
                          )}
                          {
                            <AppButtonCompany
                              className="w-full font-bold"
                              isFullWidth={true}
                              icon={AppIcons.actionCancel}
                              onClick={(e) => {
                                e.stopPropagation();
                                unconfirmBooking();
                              }}
                              style={'danger'}
                            >
                              Modifier ma réservation
                            </AppButtonCompany>
                          }
                        </div>
                      )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </>
    </div>
  );
};
