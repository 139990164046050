{
  /*      tailwind dynamically used classes */
}

export const AppTailwindDynamicClasses = () => {
  return (
    <>
      <i className="hidden bg-app-agenda-available bg-app-agenda-busy-1 bg-app-agenda-busy-2 bg-app-agenda-full bg-app-agenda-closed"></i>
      <i className="hidden bg-app-theme-accropark-primary bg-app-theme-accropark-primary-contrast bg-app-theme-accropark-primary-shade bg-app-theme-accropark-primary-tint"></i>
      <i className="hidden bg-app-theme-anim-live-primary bg-app-theme-anim-live-primary-contrast bg-app-theme-anim-live-primary-shade bg-app-theme-anim-live-primary-tint"></i>
      <i className="hidden bg-app-theme-natureo-primary bg-app-theme-natureo-primary-contrast bg-app-theme-natureo-primary-shade bg-app-theme-natureo-primary-tint"></i>
      <i className="hidden bg-app-theme-accropark-success bg-app-theme-accropark-success-contrast bg-app-theme-accropark-success-shade bg-app-theme-accropark-success-tint"></i>
      <i className="hidden bg-app-theme-anim-live-success bg-app-theme-anim-live-success-contrast bg-app-theme-anim-live-success-shade bg-app-theme-anim-live-success-tint"></i>
      <i className="hidden bg-app-theme-natureo-success bg-app-theme-natureo-success-contrast bg-app-theme-natureo-success-shade bg-app-theme-natureo-success-tint"></i>
      <i className="hidden text-app-theme-accropark-primary text-app-theme-accropark-primary-contrast text-app-theme-accropark-primary-shade text-app-theme-accropark-primary-tint"></i>
      <i className="hidden text-app-theme-anim-live-primary text-app-theme-anim-live-primary-contrast text-app-theme-anim-live-primary-shade text-app-theme-anim-live-primary-tint"></i>
      <i className="hidden text-app-theme-natureo-primary text-app-theme-natureo-primary-contrast text-app-theme-natureo-primary-shade text-app-theme-natureo-primary-tint"></i>
      <i className="hidden text-app-theme-accropark-success text-app-theme-accropark-success-contrast text-app-theme-accropark-success-shade text-app-theme-accropark-success-tint"></i>
      <i className="hidden text-app-theme-anim-live-success text-app-theme-anim-live-success-contrast text-app-theme-anim-live-success-shade text-app-theme-anim-live-success-tint"></i>
      <i className="hidden text-app-theme-natureo-success text-app-theme-natureo-success-contrast text-app-theme-natureo-success-shade text-app-theme-natureo-success-tint"></i>
      <i className="hidden hover:bg-app-theme-accropark-primary hover:bg-app-theme-accropark-primary-contrast hover:bg-app-theme-accropark-primary-shade hover:bg-app-theme-accropark-primary-tint"></i>
      <i className="hidden hover:bg-app-theme-anim-live-primary hover:bg-app-theme-anim-live-primary-contrast hover:bg-app-theme-anim-live-primary-shade hover:bg-app-theme-anim-live-primary-tint"></i>
      <i className="hidden hover:bg-app-theme-natureo-primary hover:bg-app-theme-natureo-primary-contrast hover:bg-app-theme-natureo-primary-shade hover:bg-app-theme-natureo-primary-tint"></i>
      <i className="hidden hover:bg-app-theme-accropark-success hover:bg-app-theme-accropark-success-contrast hover:bg-app-theme-accropark-success-shade hover:bg-app-theme-accropark-success-tint"></i>
      <i className="hidden hover:bg-app-theme-anim-live-success hover:bg-app-theme-anim-live-success-contrast hover:bg-app-theme-anim-live-success-shade hover:bg-app-theme-anim-live-success-tint"></i>
      <i className="hidden hover:bg-app-theme-natureo-success hover:bg-app-theme-natureo-success-contrast hover:bg-app-theme-natureo-success-shade hover:bg-app-theme-natureo-success-tint"></i>
      <i className="hidden hover:text-app-theme-accropark-primary hover:text-app-theme-accropark-primary-contrast hover:text-app-theme-accropark-primary-shade hover:text-app-theme-accropark-primary-tint"></i>
      <i className="hidden hover:text-app-theme-anim-live-primary hover:text-app-theme-anim-live-primary-contrast hover:text-app-theme-anim-live-primary-shade hover:text-app-theme-anim-live-primary-tint"></i>
      <i className="hidden hover:text-app-theme-natureo-primary hover:text-app-theme-natureo-primary-contrast hover:text-app-theme-natureo-primary-shade hover:text-app-theme-natureo-primary-tint"></i>
      <i className="hidden hover:text-app-theme-accropark-success hover:text-app-theme-accropark-success-contrast hover:text-app-theme-accropark-success-shade hover:text-app-theme-accropark-success-tint"></i>
      <i className="hidden hover:text-app-theme-anim-live-success hover:text-app-theme-anim-live-success-contrast hover:text-app-theme-anim-live-success-shade hover:text-app-theme-anim-live-success-tint"></i>
      <i className="hidden hover:text-app-theme-natureo-success hover:text-app-theme-natureo-success-contrast hover:text-app-theme-natureo-success-shade hover:text-app-theme-natureo-success-tint"></i>
      <i className="hidden ring-payment-mode-on-site bg-payment-mode-on-site hover:bg-payment-mode-on-site bg-payment-mode-on-site-dark hover:bg-payment-mode-on-site-dark "></i>
      <i className="hidden ring-payment-mode-online bg-payment-mode-online hover:bg-payment-mode-online bg-payment-mode-online-dark hover:bg-payment-mode-online-dark "></i>
    </>
  );
};
