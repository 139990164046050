import { useAppRouter } from '_01_CORE/_hooks';
import { JourneyActivitiesLocalState } from '_02_APP_COMMON/ACT-activity';
import { AppTitleDateNavigator } from '_02_APP_COMMON/ACT-activity/ACT-16-journey-activities/AppTitleDateNavigator';
import { useIntl } from 'react-intl';

export const AnimLiveJourneysActivitiesPageCriteriaDateNavigator = ({
  localState,
  className,
}: {
  localState: JourneyActivitiesLocalState;
  className?: string;
}) => {
  const { formatMessage: t } = useIntl();
  const { scrollToTop } = useAppRouter();

  const { selectedDate, journey, setSelectedDate } = localState;

  return (
    <AppTitleDateNavigator
      className={className}
      date={selectedDate}
      minDate={journey?.beginDateUTC}
      maxDate={journey?.endDateInclusiveUTC}
      onChange={(date) => {
        setSelectedDate(date);
        scrollToTop();
      }}
    />
  );
};
