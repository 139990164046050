import {
  CMCustomerAccount,
  CMJourney,
  CMJourneyEditCustomerAccount,
  CMJourneyEditData,
  CMJourneyEditParticipant,
  CMJourneyParticipant,
} from 'lib-common-model';
import { journeyHelper } from '_01_CORE/_services';

export const journeyEditStateBuilder = {
  buildEditParticipant,
  buildEditCustomerAccount,
  buildEditJourney,
  updateParticipantAgeOnJourneyDateChanges,
  buildInitialJourneyEditData,
};

function buildEditParticipant(
  p: CMJourneyParticipant
): CMJourneyEditParticipant {
  return {
    journeyParticipantId: p.journeyParticipantId,
    enabled: true,
    customerChildId: p.member.customerChildId,
    attributes: p.attributes,
    member: p.member,
  };
}

function buildEditCustomerAccount(
  ca: CMCustomerAccount
): CMJourneyEditCustomerAccount {
  if (ca) {
    return {
      ...ca,
      password: undefined,
      electronicSignature: false,
      consent: false,
    };
  }
}

function buildEditJourney(
  j: CMJourney
): Partial<
  Pick<
    CMJourney,
    | 'beginDateUTC'
    | 'endDateInclusiveUTC'
    | 'campingPlaceNumber'
    | 'allowedPeopleList'
    | 'immatriculation'
    | 'arrivalTime'
    | 'comment'
  >
> {
  return {
    beginDateUTC: j.beginDateUTC,
    endDateInclusiveUTC: j.endDateInclusiveUTC,
    campingPlaceNumber: j.campingPlaceNumber,
    immatriculation: j.immatriculation,
    arrivalTime: j.arrivalTime,
    comment: j.comment,
    allowedPeopleList: j.allowedPeopleList,
  };
}

function updateParticipantAgeOnJourneyDateChanges(
  journeyEditData: CMJourneyEditData
) {
  // update journey age if endDate changes
  journeyEditData.participants =
    journeyEditData.participants ??
    [].map((participant) => ({
      ...participant,
      attributes: {
        ...participant.attributes,
        journeyAge: journeyHelper.getParticipantJourneyAge({
          journeyEndDateInclusive: journeyEditData.journey.endDateInclusiveUTC,
          memberBirthDate: participant.member.birthDate,
        }),
      },
    }));
}

function buildInitialJourneyEditData({
  companyReference,
  customerAccountId,
}: {
  companyReference: string;
  customerAccountId: string;
}): CMJourneyEditData {
  return {
    mode: 'create',
    participants: [],
    allowedPeopleList: [],
    journey: {},
    companyReference,
    customerAccount: {
      _id: customerAccountId,
    } as any,
  };
}
