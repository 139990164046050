import {
  CMCompany,
  CMCompanyGroup,
  CMFetchedData,
  LanguageCode,
} from 'lib-common-model';
import { apiClient } from 'lib-web-api-client';

export const companyApiClient = {
  testCompanyOrCompanyGroupCode,
  testCompanyCode,
  fetchCustomerData,
};

function testCompanyOrCompanyGroupCode({
  code,
  alwaysThrowError,
}: {
  code: string;
  alwaysThrowError?: boolean;
}): Promise<{
  type: 'company' | 'group';
  company?: CMCompany; // si type === 'company'
  group?: CMCompanyGroup; // si type === 'group'
}> {
  return apiClient.get<any>(
    `/customer-web-mobile-company/company-or-group-by-reference/${code}`,
    {
      options: {
        authenticate: false,
        alwaysThrowError,
      },
    }
  );
}

function testCompanyCode({
  code,
}: {
  code: string;
}): Promise<{
  company?: CMCompany; // si type === 'company'
}> {
  return apiClient.get<any>(
    `/customer-web-mobile-company/company-by-reference/${code}`,
    {
      options: {
        authenticate: false,
      },
    }
  );
}

function fetchCustomerData({
  authenticate,
  groupReference,
  companyReference,
  customerJourneyId,
  groupUpdateDate,
  bookingUpdateDate,
  bookingId,
  companyUpdateDate,
  customerJourneyUpdateDate,
  notificationsUpdateDate,
  platformLanguageCode,
  appLanguageCode,
  fetchCompanyWelcomeBooklet,
  fetchCompanyMenu,
  companyWelcomeBookletLanguageCode,
  companyWelcomeBookletUpdateDate,
}: {
  authenticate: boolean;
  groupReference?: string;
  groupUpdateDate?: Date;
  companyReference?: string;
  companyUpdateDate?: Date;
  customerJourneyId?: string;
  customerJourneyUpdateDate?: Date;
  notificationsUpdateDate?: Date;
  bookingUpdateDate?: Date;
  bookingId?: string;
  platformLanguageCode: LanguageCode;
  appLanguageCode: LanguageCode;
  fetchCompanyWelcomeBooklet: boolean;
  fetchCompanyMenu: boolean;
  companyWelcomeBookletLanguageCode?: LanguageCode;
  companyWelcomeBookletUpdateDate?: Date;
}): Promise<CMFetchedData> {
  const searchParams: any = {};
  if (groupReference) {
    searchParams.groupReference = groupReference;
    if (groupUpdateDate) {
      searchParams.groupUpdateDate = groupUpdateDate?.getTime();
    }
  }
  if (companyReference) {
    searchParams.companyReference = companyReference;
    if (companyUpdateDate) {
      searchParams.companyUpdateDate = companyUpdateDate?.getTime();
    }
  }
  if (customerJourneyId) {
    searchParams.customerJourneyId = customerJourneyId;
    if (customerJourneyUpdateDate) {
      searchParams.customerJourneyUpdateDate = customerJourneyUpdateDate?.getTime();
    }
  }
  if (bookingId) {
    searchParams.bookingId = bookingId;
    if (bookingUpdateDate) {
      searchParams.bookingUpdateDate = bookingUpdateDate?.getTime();
    }
  }

  searchParams.notificationsUpdateDate = notificationsUpdateDate?.getTime();

  if (fetchCompanyWelcomeBooklet) {
    searchParams.fetchCompanyWelcomeBooklet = true;
  }
  if (fetchCompanyMenu) {
    searchParams.fetchCompanyMenu = true;
  }
  if (companyWelcomeBookletLanguageCode && companyWelcomeBookletUpdateDate) {
    searchParams.companyWelcomeBookletLanguageCode = companyWelcomeBookletLanguageCode;
    searchParams.companyWelcomeBookletUpdateDate = companyWelcomeBookletUpdateDate?.getTime();
  }
  if (platformLanguageCode) {
    searchParams.platformLanguageCode = platformLanguageCode;
  }
  if (appLanguageCode) {
    searchParams.appLanguageCode = appLanguageCode;
  }

  return apiClient.get<any>(`/customer-web-mobile-company/customer-data`, {
    options: {
      authenticate,
      searchParams,
    },
  });
}
