import { appLogger } from 'lib-web-logger';
import { ecommerceBookingJourneyApiClient } from '_01_CORE/app-core/app-api';
import {
  AppRouteGuard,
  AppRouteGuardContext,
} from '_01_CORE/app-core/app-router';
import { redirectToDefaultRoute } from './redirectToDefaultRoute.guard';
export function loadJourneyBookingByRouteParameter(
  routeParamName: string
): AppRouteGuard {
  return async (context: AppRouteGuardContext) => {
    const { routeParams, appCacheContext } = context;
    const customerJourneyId = routeParams[routeParamName];

    if (!customerJourneyId) {
      appLogger.warn(
        `[loadJourneyBookingByRouteParameter] NO parameter "${routeParamName}" in URL: redirect to home`
      );
      return redirectToDefaultRoute('loadJourneyBookingByRouteParameter.guard')(
        context
      );
    }
    try {
      const booking = await ecommerceBookingJourneyApiClient.getJourneyBooking({
        customerJourneyId,
      });
      // add to local cache
      appCacheContext.dispatchCacheContextAction({
        type: 'set-booking',
        booking,
        context: 'loadJourneyBookingByRouteParameter',
      });
      return context; // ok
    } catch (err) {
      return redirectToDefaultRoute('loadJourneyBookingByRouteParameter.guard')(
        context
      );
    }
  };
}
