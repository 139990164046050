import React from 'react';
import { useIntl } from 'react-intl';

export const ParticipantAgeLabel = ({
  journeyAge,
  memberAge,
}: {
  journeyAge: number;
  memberAge: number;
}) => {
  const { formatMessage: t } = useIntl();
  return memberAge > 0 ? (
    <div className="block text-center">
      <div className="block text-xs font-bold text-gray-400">
        {t({ id: 'page12.age' })}:{' '}
      </div>
      <div className="block text-xs font-bold truncate">
        {t({ id: 'page12.yearsOld' }, { age: memberAge })}
      </div>
      {memberAge !== journeyAge && (
        <div>({t({ id: 'page12.journeyAge' }, { age: journeyAge })})</div>
      )}
    </div>
  ) : null;
};
