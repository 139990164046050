import { useAppTheme } from '_01_CORE/_common-layout';
import {
  AppIcons,
  colorGenerator,
  dateTransformer,
} from '_01_CORE/_components-core';
import { useWindowSize } from '_01_CORE/_hooks/useWindowSize.hook';
import { useCompany } from '_01_CORE/app-contexts';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import clsx from 'clsx';
import {
  Activity,
  ActivityTranslation,
  AppRichTextContent,
  AppRichTextElement,
  AppTranslationContentItem,
  CMJourneyActivitySession,
  CMJourneyActivitySessionActivity,
  LanguageCode,
  TimeHoursMinutes,
} from 'lib-common-model/entities';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { JourneyDailyActivityAvailabilityTimeOption } from './JourneyDailyActivityAvailabilityTimeOption';
import { JourneyDailyActivityCardContext } from './JourneyDailyActivityCardContext.type';
import { JourneyDailyActivityCardParticipantsRegistrationCard } from './JourneyDailyActivityCardParticipantsRegistrationCard';
import {
  ActivitySessionDetailsDialogRegistrationPaidAreaSuccessMessage,
  ActivitySessionDetailsDialogRegistrationPaidAreaWarningMessage,
} from './components';
import {
  ActivityTitleCard,
  ActivityTitleCardSession,
  SessionRelativeTimeState,
} from './components/ActivityTitle';
import { ActivityTitleCardLeftSide } from './components/ActivityTitle/ActivityTitleCardLeftSide';
import { sessionRelativeTimeStateBuilder } from './components/ActivityTitle/sessionRelativeTimeStateBuilder.service';
import { useTimeSessionsToDisplayByDefault } from './hooks';
import { CustomerActivityUpdateParticipantStateFn } from './model';
import { CustomerActivityParticipantsDailyActivity } from './model/CustomerActivityParticipantsDailyActivity.type';
import { CustomerActivityParticipantsDailyActivitySession } from './model/CustomerActivityParticipantsDailyActivitySession.type';

export type JourneyDailyActivityCardViewStep =
  | 'default'
  | 'choose-time-sessions';

export const JourneyDailyActivityCard = ({
  companyReference,
  context,
  dailyActivity,
  onClickParticipant,
  onClickActivityDetails,
  onSelectSessionId,
  onToogleTimeOptionsFullList,
  viewStep,
  className,
}: {
  companyReference: string;
  context: JourneyDailyActivityCardContext;
  dailyActivity: CustomerActivityParticipantsDailyActivity;
  onClickActivityDetails?: ({
    activityId,
    activitySessionId,
  }: {
    activityId: string;
    activitySessionId: string;
  }) => void;
  onClickParticipant?: CustomerActivityUpdateParticipantStateFn;
  onSelectSessionId: (sessionId: string) => void;
  onToogleTimeOptionsFullList?: () => void;
  viewStep: JourneyDailyActivityCardViewStep;
  className?: string;
}) => {
  const appId = appWebConfig().appId;
  const { formatMessage: t } = useIntl();
  const theme = useAppTheme({ companyReference });
  const { company } = useCompany({ companyReference });
  const companyOptions = company?.options;

  const activitySession = dailyActivity.selectedSession.activitySession;
  const selectedSession: CustomerActivityParticipantsDailyActivitySession =
    dailyActivity.selectedSession;

  const { width } = useWindowSize();

  const maxSessionsToDisplay =
    viewStep === 'choose-time-sessions'
      ? 100
      : Math.floor((Math.min(width, 600) - 61) / 87) - 1;

  const timeSessionsToDisplayByDefault: CustomerActivityParticipantsDailyActivitySession[] = useTimeSessionsToDisplayByDefault(
    {
      dailyActivity,
      selectedSession,
      maxSessionsToDisplay,
    }
  );

  const activityTitleCardSessionResume: ActivityTitleCardSession = useMemo(() => {
    const sessionResume: ActivityTitleCardSession = {
      beginDateTime: activitySession.beginDateTime,
      endDateTime: activitySession.endDateTime,
      openingStatus: activitySession.openingStatus,
      registrationFeePriceRanges: activitySession.registrationFeePriceRanges,
      duration: buildActivitySessionDuration(activitySession),
      companyClubMinAge: dailyActivity.club?.minAge,
      companyClubMaxAge: dailyActivity.club?.maxAge,
    };

    if (dailyActivity.sessions.length > 1) {
      sessionResume.multipleSessions = {
        totalCount: dailyActivity.sessions.length,
        beginDateTime: dailyActivity.sessions[0].activitySession.beginDateTime,
        endDateTime:
          dailyActivity.sessions[dailyActivity.sessions?.length - 1]
            .activitySession.endDateTime,
      };
    }
    return sessionResume;
  }, [
    activitySession,
    dailyActivity.club?.maxAge,
    dailyActivity.club?.minAge,
    dailyActivity.sessions,
  ]);

  const relativeTimeState: SessionRelativeTimeState = useMemo(
    () => sessionRelativeTimeStateBuilder.build(activityTitleCardSessionResume),
    [activityTitleCardSessionResume]
  );

  const displayParticipantsCard = useMemo(() => {
    if (viewStep !== 'default') {
      return false;
    }
    if (
      dailyActivity.selectedSession.registrationStatus ===
      'registration-customer-disabled'
    ) {
      // registration disabled
      return false;
    }
    if (appId === 'anim-live') {
      if (context === 'list') {
        if (
          relativeTimeState === 'past' &&
          dailyActivity.selectedSession.participants.find(
            (x) =>
              !x.isNotRegistered ||
              x.activitySessionParticipant?.waitingRegistration?.status ===
                'waiting'
          ) === undefined
        ) {
          // pas activity & no participant
          return false;
        }
        return true;
      }
      if (context === 'details') {
        return false;
      }
    } else if (appId === 'accropark') {
      if (dailyActivity.displayMode === 'multiple') {
        const firstParticipant = dailyActivity.selectedSession.participants.find(
          (x) => !x.isNotRegistered
        );
        return !firstParticipant;
      }
      return true;
    }
  }, [
    appId,
    context,
    dailyActivity.displayMode,
    dailyActivity.selectedSession.participants,
    dailyActivity.selectedSession.registrationStatus,
    relativeTimeState,
    viewStep,
  ]);
  function buildActivitySessionDuration(
    activitySession: CMJourneyActivitySession
  ) {
    if (activitySession.activitySchedule?.duration) {
      return activitySession.activitySchedule?.duration;
    }

    const diff = new Date(
      activitySession.endDateTime.getTime() -
        activitySession.beginDateTime.getTime()
    );

    const d: TimeHoursMinutes = {
      hours: diff.getUTCHours(),
      minutes: diff.getUTCMinutes(),
    };
    return d;
  }

  const borderColorOpacity = relativeTimeState === 'past' ? 0.5 : 1;

  const borderColor = useMemo(
    () => colorGenerator.rgba(dailyActivity.club.color, borderColorOpacity),
    [borderColorOpacity, dailyActivity.club.color]
  );
  const activity: CMJourneyActivitySessionActivity = dailyActivity.activity;
  const activityTranslation = useMemo(() => {
    if (activity.translation) {
      return activity.translation;
    }
    // TODO 03/05/2022 fix temporaire avant la mise en prod de la version 5.35.11 (tant que l'API n'a pas été déployé) - à supprimer sur la prochaine version
    return createActivityTranslation(activity, 'fre/fra');
  }, [activity]);

  const hasUnpayedParticipant = useMemo(
    () =>
      activitySession.registrationFeeEnabled &&
      dailyActivity.selectedSession.participants.find(
        (x) => x.activitySessionParticipant?.paymentStatus === 'payment-later'
      ) !== undefined,
    [
      activitySession.registrationFeeEnabled,
      dailyActivity.selectedSession.participants,
    ]
  );

  const hasAllPayedParticipant = useMemo(
    () =>
      dailyActivity.selectedSession.participants.find(
        (x) =>
          x.activitySessionParticipant?.paymentStatus === 'payment-accepted'
      ) !== undefined,
    [dailyActivity.selectedSession.participants]
  );

  return (
    <div
      className={`${
        onClickActivityDetails ? 'cursor-pointer' : ''
      } relative flex flex-col w-full border border-gray-300 border-l-4 bg-white rounded-r-lg space-x-1 py-1 pr-1 ${className}`}
      style={{ borderLeftColor: borderColor }}
      onClick={() => {
        onClickActivityDetails &&
          onClickActivityDetails({
            activityId: activitySession?.activity?._id,
            activitySessionId: activitySession?.activitySessionId,
          });
      }}
    >
      {onClickActivityDetails && (
        <div className="bg-white px-0.5 absolute top-1 right-0">
          <AppIcons.openArrowRight className="w-5 text-gray-400" />
        </div>
      )}
      <div
        className={`flex gap-2 items-start whitespace-nowrap truncate ${
          relativeTimeState === 'past'
            ? 'text-gray-400 group-hover:text-gray-500'
            : relativeTimeState === 'in-progress'
            ? 'text-gray-700 group-hover:text-gray-800'
            : 'text-gray-700 group-hover:text-gray-800'
        }`}
      >
        <div className={`w-16`}>
          <ActivityTitleCardLeftSide
            activity={activity}
            activityTranslation={activityTranslation}
            sessionResume={activityTitleCardSessionResume}
          />
        </div>
        <div className="flex-grow">
          <ActivityTitleCard
            context={context}
            className="overflow-hidden w-full text-sm text-left"
            dailyActivity={dailyActivity}
            activity={activity}
            activityTranslation={activityTranslation}
            selectedSession={selectedSession}
            sessionResume={activityTitleCardSessionResume}
            companyOptions={companyOptions}
          />
          <div className="mt-2 flex flex-row gap-2 flex-wrap">
            {/* time / available slots */}
            {/* If at least 1 session is available */}
            {context === 'list' &&
              dailyActivity.displayMode === 'multiple' &&
              timeSessionsToDisplayByDefault.length !== 0 && (
                <div
                  className={`text-sm -mt-1 -mb-1 text-left font-bold text-success whitespace-nowrap flex flex-wrap gap-x-2`}
                >
                  {t({ id: 'page16.session.multiple-times-choice' })}
                </div>
              )}{' '}
            {displayParticipantsCard && (
              <JourneyDailyActivityCardParticipantsRegistrationCard
                className="flex-grow"
                companyReference={companyReference}
                dailyActivity={dailyActivity}
                relativeTimeState={relativeTimeState}
                onClickActivityDetails={onClickActivityDetails}
                onClickParticipant={onClickParticipant}
              />
            )}
          </div>
        </div>
      </div>
      {context === 'details' &&
        dailyActivity.displayMode === 'multiple' &&
        timeSessionsToDisplayByDefault.length !== 0 && (
          <div className="mx-auto my-2 flex gap-2 flex-wrap justify-center">
            {timeSessionsToDisplayByDefault.map((session) => (
              <div
                key={session.activitySession.activitySessionId}
                className={clsx(
                  'cursor-pointer w-20 h-12 border border-gray-200 hover:border-gray-400 hover:bg-gray-50 text-center',
                  'flex flex-col justify-around items-center rounded',
                  session.activitySession.activitySessionId ===
                    dailyActivity.selectedSession.activitySession
                      .activitySessionId && 'ring ring-app-primary '
                )}
                onClick={() => {
                  onSelectSessionId(
                    session?.activitySession?.activitySessionId
                  );
                }}
              >
                <div className="leading-3 text-base font-bold text-gray-500 ">
                  {dateTransformer.formatTime(
                    session.activitySession.beginDateTime
                  )}
                </div>
                <JourneyDailyActivityAvailabilityTimeOption
                  className="leading-3 text-xs font-bold "
                  dailyActivity={dailyActivity}
                  selectedSession={session}
                />
                {/* // choisir un métier */}
              </div>
            ))}
            {(dailyActivity.sessions.length !==
              timeSessionsToDisplayByDefault.length ||
              viewStep === 'choose-time-sessions') &&
              onToogleTimeOptionsFullList && (
                <div
                  className="cursor-pointer w-20 h-12 border border-gray-200 hover:border-gray-400 hover:bg-gray-50 text-center flex flex-col justify-around items-center text-gray-800 font-bold"
                  onClick={() => {
                    onToogleTimeOptionsFullList();
                  }}
                >
                  {'...'}
                </div>
              )}
          </div>
        )}
      {hasUnpayedParticipant ? (
        <ActivitySessionDetailsDialogRegistrationPaidAreaWarningMessage className="my-2 text-gray-800 text-xs" />
      ) : (
        hasAllPayedParticipant && (
          <ActivitySessionDetailsDialogRegistrationPaidAreaSuccessMessage className="my-2 text-gray-800 text-xs" />
        )
      )}
    </div>
  );
};

export function createActivityTranslation(
  activity: Pick<Activity, 'name' | 'name2'>,
  languageCode: LanguageCode
): ActivityTranslation {
  const description: AppTranslationContentItem[] = [];
  description.push({
    type: 'rich-text',
    richText: createRichTextContent({
      text: activity.name2 ?? '',
    }),
  });
  description.push({
    type: 'image',
  });
  description.push({
    type: 'rich-text',
    richText: createRichTextContent({
      text: '',
    }),
  });
  return {
    name: activity.name,
    subTitle: activity.name2,
    languageCode,
    description,
  };
}

function createRichTextContent({
  text = '',
  style = 'text',
}: {
  text: string;
  style?: 'text' | 'title+empty-text';
}): AppRichTextContent {
  const elements: AppRichTextElement[] = createRichTextElements({
    text,
    style,
  });
  const richTextContent: AppRichTextContent = {
    elements,
  };
  return richTextContent;
}
function createRichTextElements({
  text = '',
  style = 'text',
}: {
  text?: string;
  style?: 'text' | 'title+empty-text';
}): AppRichTextElement[] {
  let textChunks = text.split(/\n/g);
  if (textChunks.length === 0) {
    textChunks = [''];
  }
  if (style === 'text') {
    const elements: AppRichTextElement[] = textChunks.map((str) => ({
      type: 'p',
      children: [{ text: str }],
    }));
    return elements;
  } else if (style === 'title+empty-text') {
    const elements: AppRichTextElement[] = textChunks.map((str) => ({
      type: 'heading-one',
      children: [{ text: str }],
    }));
    elements.push({
      type: 'p',
      children: [{ text: '' }],
    });
    return elements;
  }
  return [];
}
