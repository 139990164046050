import { IonButton, IonIcon } from '@ionic/react';
import { AppLoader } from '_01_CORE/_components-core';
import {
  useAppCacheContext,
  useAppLocalizationContext,
} from '_01_CORE/app-contexts';
import { checkmark, closeOutline } from 'ionicons/icons';
import { useState } from 'react';
import { useIntl } from 'react-intl';

export function RegistrationConfirmDeleteModal({
  onConfirm,
  onCancel,
}: {
  onConfirm: () => Promise<any>;
  onCancel: () => Promise<void>;
}) {
  const { formatMessage: t } = useIntl();
  const appCacheContext = useAppCacheContext();
  const { auth, dispatchCacheContextAction } = appCacheContext;

  const {
    locales: { platformLanguageCode, appLanguageCode },
  } = useAppLocalizationContext();

  const [operationPending, setOperationPending] = useState(false);

  return (
    <div className="w-full">
      {operationPending ? (
        <AppLoader className="my-4" type={'persist'} />
      ) : (
        <IonButton
          className="w-full"
          color="primary"
          onClick={async () => {
            if (operationPending) {
              return;
            }
            try {
              setOperationPending(true);
              await onConfirm();
            } catch (err) {
              console.error(err);
            } finally {
              setOperationPending(false);
            }
          }}
        >
          <IonIcon icon={checkmark} className="mr-2" />
          {t({ id: 'common.action.ok' })}
        </IonButton>
      )}
      <IonButton className="w-full" color="danger" onClick={onCancel}>
        <IonIcon icon={closeOutline} className="mr-2" />
        {t({ id: 'common.action.cancel' })}
      </IonButton>
    </div>
  );
}
