import { dateTester, dateTransformer } from '_01_CORE/_components-core/date';
import {
  CMJourneyActivities,
  CMJourneyActivitySession,
  CMJourneyActivitySessionParticipant,
  CMJourneyClubParticipant,
  CMJourneyParticipant,
} from 'lib-common-model';

export type CMJourneyAnimationsDailyActivitiesFilters = {
  childrenIds?: string[];
  activitiesIds?: string[];
  registeredOrPresentOnly?: boolean;
};
export type CMJourneyAnimationsDailyActivitiesSelectedDay = {
  date: Date;
  hasPrevious: boolean;
  hasNext: boolean;
};

export const customerJourneyAnimationsDailyActivitiesFilter = {
  filterDailyActivities,
};

export type CMJourneyAnimationsFilteredData = {
  allClubsParticipants: CMJourneyClubParticipant[];
  filteredClubsParticipants: CMJourneyClubParticipant[];
  activitySessions: CMJourneyActivitySession[];
  customerActivitySessionsIds: string[];
  activitySessionsParticipants: CMJourneyActivitySessionParticipant[];
  filteredJourneyParticipants: CMJourneyParticipant[];
  customerJourneyActivities: CMJourneyActivities;
};

function filterDailyActivities({
  selectedDate,
  customerJourneyActivities,
  filters,
  journeyParticipants,
}: {
  selectedDate: Date;
  customerJourneyActivities: CMJourneyActivities;
  filters: CMJourneyAnimationsDailyActivitiesFilters;
  journeyParticipants: CMJourneyParticipant[];
}) {
  const allClubsParticipants = customerJourneyActivities.clubsParticipants;
  const filteredClubsParticipants =
    filters.childrenIds && filters.childrenIds.length
      ? customerJourneyActivities.clubsParticipants
          .map((clubParticipant) => {
            return {
              ...clubParticipant,
              journeyParticipants: clubParticipant.journeyParticipants.filter(
                (journeyParticipant) =>
                  filters.childrenIds.indexOf(
                    journeyParticipant.member.customerChildId
                  ) !== -1
              ),
            };
          })
          .filter(
            (clubParticipant) => clubParticipant.journeyParticipants.length
          )
      : customerJourneyActivities.clubsParticipants;

  const activitySessionsParticipants =
    customerJourneyActivities.activitySessionsParticipants;
  const minDate = dateTransformer.getUTCDateSetTime(selectedDate);
  const maxDateExclusive = dateTransformer.addDays(minDate, 1);

  const filteredJourneyParticipants = journeyParticipants.filter(
    (journeyParticipant) =>
      !filters.childrenIds ||
      (filters.childrenIds &&
        filters.childrenIds.length &&
        filters.childrenIds.indexOf(
          journeyParticipant.member.customerChildId
        ) !== -1)
  );

  const filteredJourneyParticipantsIds = filteredJourneyParticipants.length
    ? filteredJourneyParticipants.map((x) => x.journeyParticipantId)
    : journeyParticipants.map((x) => x.journeyParticipantId);

  const activitySessionsIdsWithFilteredParticipants = activitySessionsParticipants
    .filter((x) =>
      filteredJourneyParticipantsIds.includes(x.journeyParticipantId)
    )
    .map((x) => x.activitySessionId);

  const activitySessions = customerJourneyActivities.activitySessions.filter(
    (activitySession) => {
      if (
        filters.activitiesIds?.length &&
        !filters.activitiesIds.includes(activitySession.activity._id)
      ) {
        // filter activities
        return false;
      }
      if (filters.registeredOrPresentOnly) {
        const hasParticipant = activitySessionsIdsWithFilteredParticipants.includes(
          activitySession.activitySessionId
        );
        if (!hasParticipant) {
          return false;
        }
      }
      return dateTester.isDateWithinRange(activitySession.beginDateTime, {
        minDate,
        maxDateExclusive,
      });
    }
  );

  const customerActivitySessionsIds = activitySessionsParticipants.map(
    (x) => x.activitySessionId
  );

  const filteredData: CMJourneyAnimationsFilteredData = {
    allClubsParticipants,
    filteredClubsParticipants,
    activitySessions,
    customerActivitySessionsIds,
    activitySessionsParticipants,
    filteredJourneyParticipants,
    customerJourneyActivities,
  };

  return filteredData;
}
