import { appLogger } from 'lib-web-logger';
import {
  AppRouteGuard,
  AppRouteGuardContext,
  AppRouteGuardError,
} from '_01_CORE/app-core/app-router';
import { companyOrGroupReferenceTester } from '_02_APP_COMMON/AUT-auth/01-login/companyOrGroupReferenceTester.service';
import { redirectToDefaultRoute } from './redirectToDefaultRoute.guard';

export function testCompanyOrGroupReference(
  routeParamName: string
): AppRouteGuard {
  return async (context: AppRouteGuardContext) => {
    const { routeParams, appCacheContext } = context;
    const companyOrGroupReference = routeParams[routeParamName];

    if (!companyOrGroupReference) {
      appLogger.warn(
        `[testCompanyOrGroupReference] NO parameter "${routeParamName}" in URL: redirect to home`
      );
      return redirectToDefaultRoute('testCompanyOrGroupReference.guard')(
        context
      );
    }

    return await companyOrGroupReferenceTester
      .testCompanyOrGroupCode(companyOrGroupReference, {
        appCacheContext,
      })
      .then(({ redirectTo }) => {
        if (redirectTo) {
          throw new AppRouteGuardError({
            redirectToUrl: redirectTo,
          });
        } else {
          return redirectToDefaultRoute('testCompanyOrGroupReference.guard')(
            context
          );
        }
      });
  };
}
