import {
  AppPage,
  CompanyImage,
  HeaderToolbar,
  PageContainer,
  useAppTheme,
} from '_01_CORE/_common-layout';
import {
  AppButtonCompany,
  AppButtonV2,
  AppIcons,
  AppLoader,
} from '_01_CORE/_components-core';
import { JourneyCard } from '_01_CORE/_components-core/JourneyCard';
import { useAppRouter, useOperationPending } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import { useAppCacheContext, useJourney } from '_01_CORE/app-contexts';
import { customerJourneyApiClient } from '_01_CORE/app-core/app-api';
import { journeyCheckInTester } from '_02_APP_COMMON/JOU-journey/_common';
import { CMJourneyIssuePayload, CompanyIssueReport } from 'lib-common-model';
import { appLogger } from 'lib-web-logger';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import {
  CompanyIssueCard,
  CompanyIssueReportForm,
} from '../JOU-50-report-issue';

export type CheckInPageMode =
  | 'persist-in-progress'
  | 'create-issue'
  | 'persist-success'
  | 'persist-error';

export const AnimLiveReportIssuePage = () => {
  const appRouter = useAppRouter();
  const { formatMessage: t } = useIntl();

  const { customerJourneyId } = useParams<{ customerJourneyId: string }>();

  const { auth } = useAppCacheContext();
  const customerAccount = auth?.customerProfile?.account;
  const { journey } = useJourney({
    customerJourneyId,
  });
  const theme = useAppTheme({ companyReference: journey?.company?.reference });

  const redirectToCompanyBooklet = useCallback(() => {
    appRouter.navigate(
      appRoutesBuilder.getCompanyBookletUrl({
        companyReference: journey?.company?.reference,
      }),
      { cause: 'journey-home-click' }
    );
  }, [appRouter, journey?.company?.reference]);

  useEffect(() => {
    if (!journey) {
      appLogger.warn(
        '[AnimLiveJourneyHomePage] No journey: redirect to /journeys'
      );
      appRouter.navigateAfterDelay(appRoutesBuilder.getJourneysUrl(), {
        cause: 'company-home-no-journey',
      });
    }
  }, [appRouter, journey]);

  useEffect(() => {
    if (!journeyCheckInTester.isIssueReportEnabled(journey)) {
      redirectToCompanyBooklet();
    }
    // on ne mets pas la dépendance sur tout l'objet journey, car sinon ça nous redirige à la fin
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appRouter, journey?._id]);

  const [pageState, setPageState] = useState<{
    mode: CheckInPageMode;
  }>({
    mode: 'create-issue',
  });
  const [issueReport, setIssueReport] = useState<CompanyIssueReport>();
  const { mode } = pageState;

  const persistChanges = useOperationPending(
    async (issueReport: CompanyIssueReport) => {
      if (issueReport) {
        // valid form
        setPageState({ mode: 'persist-in-progress' });
        try {
          const payload: CMJourneyIssuePayload = {
            issueReport: {
              ...issueReport,
              photos: (issueReport.photos ?? []).filter((x) => !!x),
            },
          };
          const { result } = await customerJourneyApiClient.submitCompanyIssue({
            customerJourneyId,
            payload,
          });
          if (result === 'success') {
            setPageState({
              mode: 'persist-success',
            });
          } else {
            setPageState({
              mode: 'persist-error',
            });
          }
          setPageState({ mode: 'persist-success' });
        } catch (e) {
          setPageState({ mode: 'persist-error' });
        }
      }
    },
    [customerJourneyId]
  );

  return !journey ? null : (
    <AppPage>
      <HeaderToolbar />
      <PageContainer className="text-left bg-gray-50" spacing="normal">
        {mode !== 'create-issue' && (
          <>
            <CompanyImage image={journey?.company?.image1} />
            <h2 className="my-3 text-center font-bold text-lg sm:text-xl text-primary uppercase">
              VOTRE SÉJOUR
            </h2>
            <JourneyCard
              showActionEditJourney={false}
              showActionDeleteJourney={false}
              journey={journey}
              redirectOnDelete={false}
              showDetails={false}
            />
          </>
        )}

        {issueReport && (
          <>
            <h2 className="my-3 text-center font-bold text-lg sm:text-xl text-primary uppercase">
              Signaler un problème
            </h2>

            <div className="my-4">
              <h3 className="my-2 text-centlefter font-bold text-sm sm:text-base text-app-danger uppercase">
                Problème à signaler:
              </h3>
              <CompanyIssueCard className="mt-4" issue={issueReport} />
            </div>
          </>
        )}

        {mode === 'persist-in-progress' && (
          <AppLoader className="my-4 p-2 px-4" />
        )}

        {mode === 'persist-success' && (
          <div className="my-5">
            <p className="my-2 text-sm text-justify font-bold text-app-success">
              Votre demande a été transmise et sera traitée le plus rapidement
              possible.
            </p>
            <p className="my-2 text-sm text-justify font-bold text-app-primary">
              Nous avons à cœur de vous offrir le meilleur service possible.
            </p>
          </div>
        )}
        {mode === 'persist-error' && (
          <div className="my-5">
            <div className="my-2">
              <p className="my-2 text-sm text-justify font-bold text-app-danger">
                {`Une erreur innatendue s'est produite pendant l'enregistrement de
              votre signalement. Veuillez vérifier votre connexion Internet
              et ré-essayer.`}
              </p>
            </div>
            <AppButtonV2
              className={`mt-5 w-full font-bold rounded-full`}
              // size="small"
              buttonStyle="success"
              icon={AppIcons.check}
              onClick={() => persistChanges(issueReport)}
            >
              Ré-essayer
            </AppButtonV2>
          </div>
        )}

        {mode === 'create-issue' && (
          <CompanyIssueReportForm
            // className="my-8"
            journey={journey}
            contactPhoneNumber={
              customerAccount?.phone1 ?? customerAccount?.phone2
            }
            customerJourneySiteReference={journey?.campingPlaceNumber}
            onCancel={() => {
              redirectToCompanyBooklet();
            }}
            onConfirm={(issueReport) => {
              setIssueReport(issueReport);
              persistChanges(issueReport);
            }}
          />
        )}
        {mode === 'persist-success' && (
          <AppButtonCompany
            style={'primary'}
            theme={theme}
            isFullWidth={true}
            className={`w-full uppercase font-bold`}
            label={t({ id: 'page02.infosServicesButton.anim-live' })}
            icon={AppIcons.infoSolid}
            onClick={() => {
              redirectToCompanyBooklet();
            }}
          />
        )}
      </PageContainer>
    </AppPage>
  );
};
