import { AppPage, PageContainer } from '_01_CORE/_common-layout';
import { AppButtonUniverse } from '_01_CORE/_components-core';
import { useAppRouter } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import { useAppCacheContext } from '_01_CORE/app-contexts';
import { useAppFeatures } from '_01_CORE/app-core/app-config';
import { useAppSettings } from '_01_CORE/app-core/app-security';
import { AppUniverseHeader } from '_02_APP_COMMON/_global';
import { useIntl } from 'react-intl';

export const AuthPageHomeAnimLive = () => {
  const appSettings = useAppSettings();
  const whiteLabel = appSettings?.universe?.whiteLabel;

  const { isFeatureAuthEnabled } = useAppFeatures();

  const appRouter = useAppRouter();
  const { formatMessage: t } = useIntl();

  const title1Color = appSettings?.universe?.theme?.title1Color;

  const appCacheContext = useAppCacheContext();

  const routeParams = new URLSearchParams(window.location.search);
  const companyReference =
    routeParams.get('companyReference') ??
    appCacheContext?.companyValidReferenceCode;

  const loginByActivationKeyUrl = appRoutesBuilder.getLoginMultiUrl({
    companyReference,
    action: 'activate',
  });
  const loginByPasswordUrl = appRoutesBuilder.getLoginMultiUrl({
    action: 'login',
    companyReference,
  });
  const loginCreateAccountUrl = appRoutesBuilder.getLoginMultiUrl({
    companyReference,
    action: 'register',
  });

  return (
    <AppPage tabId="login">
      <PageContainer
        className={`h-full bg-white text-center flex flex-col gap-8`}
        style={{
          color: title1Color,
        }}
      >
        <AppUniverseHeader showLargeHeader={false} />
        <div className="w-full max-w-sm md:max-w-md mx-auto flex flex-col">
          <div className="w-full bg-gray-50 rounded-xl border border-gray-200 pt-8 pb-8 px-8 flex flex-col gap-8 items-center">
            <AppButtonUniverse
              style={'light'}
              fontWeightClassName="font-bold"
              href={loginByActivationKeyUrl}
            >
              {t({ id: 'page04.button.activation-key.anim-live' })}
            </AppButtonUniverse>
            <div className="w-full flex flex-col gap-4 items-center">
              <AppButtonUniverse style={'primary'} href={loginByPasswordUrl}>
                {`Se connecter`}
              </AppButtonUniverse>

              <AppButtonUniverse style={'light'} href={loginCreateAccountUrl}>
                {`S'inscrire`}
              </AppButtonUniverse>
            </div>
          </div>
        </div>
        {isFeatureAuthEnabled &&
          !(whiteLabel.appCompanyReference || whiteLabel.appGroupReference) && (
            <div className={`underline text-gray-600 font-normal text-right`}>
              <span
                className=" cursor-pointer"
                onClick={() => {
                  const path = appRoutesBuilder.getLoginMultiUrl({
                    action: 'see-company-by-code',
                  });
                  appRouter.navigate(path, {
                    cause: 'login-page-click-auth-later',
                  });
                }}
              >
                {`S'inscrire plus tard`}
              </span>
            </div>
          )}
        <div className="flex-grow"></div>
        {(whiteLabel.appCompanyReference ||
          whiteLabel.appGroupReference ||
          companyReference) && (
          <div className="flex flex-col gap-8 items-center">
            <AppButtonUniverse
              style="light"
              className="w-full uppercase"
              onClick={() => {
                const path =
                  companyReference ?? whiteLabel.appCompanyReference
                    ? appRoutesBuilder.getCompanyBookletUrl({
                        companyReference:
                          companyReference ?? whiteLabel.appCompanyReference,
                      })
                    : appRoutesBuilder.getGroupHomeUrl({
                        groupReference: whiteLabel.appGroupReference,
                        isAuthenticated: false,
                      });
                appRouter.navigate(path, {
                  cause: 'login-page-click-enter',
                });
              }}
            >
              {t({ id: 'common.action.back' })}
            </AppButtonUniverse>
          </div>
        )}
      </PageContainer>
    </AppPage>
  );
};
