import { usePlatform } from '@capacitor-community/react-hooks/platform';
import { IonButton } from '@ionic/react';
import {
  AppPage,
  AppTitle1,
  FooterActions,
  HeaderToolbar,
  PageContainer,
} from '_01_CORE/_common-layout';
import {
  AppFormControlRHF,
  AppIcons,
  useAppModalContext,
} from '_01_CORE/_components-core';
import { AppInput } from '_01_CORE/_components-core/AppInput';
import { useAppRouter, useOperationPending } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import { useAppCacheContext, useCompany } from '_01_CORE/app-contexts';
import { ecommerceBookingJourneyApiClient } from '_01_CORE/app-core/app-api';
import { appBrowser } from '_01_CORE/app-core/app-platform';
import { authenticationClient } from '_01_CORE/app-core/app-security';
import { useDeleteJourneyBookingAccroparkConfirm } from '_02_APP_COMMON/JOU-journey/_common';
import { CMGiftCardEditData, EcommerceBookingJourney } from 'lib-common-model';
import { useEffect, useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';

type AccroparkGiftCardEditFormModel = {
  amount: number;
};

export const AccroparkGiftCardEditPage = () => {
  const appRouter = useAppRouter();
  const { formatMessage: t } = useIntl();

  const { companyReference, bookingId } = useParams<{
    companyReference: string;
    bookingId: string;
  }>();

  const { company } = useCompany({ companyReference });

  const {
    auth,
    booking: cacheBooking,
    dispatchCacheContextAction,
  } = useAppCacheContext();

  const booking: EcommerceBookingJourney = useMemo(() => {
    if (cacheBooking) {
      if (
        cacheBooking?.type === 'gift-card' &&
        cacheBooking.status === 'draft'
      ) {
        return cacheBooking;
      }
      return undefined;
    }
  }, [cacheBooking]);

  useEffect(() => {
    if (cacheBooking && !booking) {
      // remove booking from cache
      dispatchCacheContextAction({
        type: 'set-booking',
        booking: undefined,
        context: 'unload invalid cache booking from gift-card',
      });
    }
  }, [booking, cacheBooking, dispatchCacheContextAction]);

  const deleteBookingConfirm = useDeleteJourneyBookingAccroparkConfirm({
    customerJourneyId: booking?.customerJourneyId,
  });

  const defaultAmount: number = useMemo(() => {
    if (booking) {
      return booking?.purchase.offers.find((o) => o.type === 'gift-card')
        ?.price;
    }
  }, [booking]);

  const form = useForm<AccroparkGiftCardEditFormModel>({
    defaultValues: { amount: defaultAmount },
    mode: 'onChange',
  });
  const { control, register, formState, handleSubmit } = form;

  const [amount] = useWatch({
    control,
    name: ['amount'],
  });

  const formValue = useWatch({ control });

  const goBack = () => {
    appRouter.navigate(
      appRoutesBuilder.getCompanyBookletUrl({
        companyReference,
      }),
      { cause: 'gift-card-back' }
    );
  };

  const { openModal, closeModal } = useAppModalContext();

  const onSubmit = useOperationPending(async () => {
    handleSubmit(
      async (formValue: AccroparkGiftCardEditFormModel) => {
        if (formValue) {
          const payload: CMGiftCardEditData = {
            amount: parseFloat((formValue.amount as unknown) as string),
            companyId: company?._id,
            companyReference,
            customerAccountId: auth?.customerProfile?.account?._id,
            bookingId: booking?._id,
            customerJourneyId: booking?.customerJourneyId,
          };

          const response = await ecommerceBookingJourneyApiClient.createBookingGiftCard(
            payload
          );

          await authenticationClient.authenticateByToken(
            response.token,
            ['customer'],
            { context: 'AccroparkGiftCardEditPage' }
          );

          appRouter.navigate(
            appRoutesBuilder.getBookingResumePageGiftCard({
              bookingId: response.bookingId,
              companyReference,
            }),
            {
              cause: 'gift-card-created',
            }
          );
          // openModal({
          //   title: t({ id: 'page07.home.title.accropark' }),
          //   icon: 'none',
          //   content: (
          //     <div className="mb-10">
          //       <div>TEST</div>
          //     </div>
          //   ),
          //   actions: (
          //     <>
          //       <IonButton color="secondary" onClick={closeModal} expand="block">
          //         <IonIcon icon={arrowBackOutline} className="mr-3" />
          //         {t({ id: 'common.action.cancel' })}
          //       </IonButton>
          //     </>
          //   ),
          // });
        }
      },
      (err) => {
        //
        console.log('xxx onSubmit err', err);
      }
    )();

    // await ecommerceBookingJourneyApiClient.confirmJourneyBookingOptions({
    //   customerJourneyId,
    //   options: booking?.purchase.options,
    // });
    // appRouter.navigate(
    //   appRoutesBuilder.getAccountEditAccroparkJourneyUrl({ customerJourneyId }),
    //   { cause: 'journey-options-submit' }
    // );
  }, [
    appRouter,
    auth?.customerProfile?.account?._id,
    booking?._id,
    booking?.customerJourneyId,
    company?._id,
    companyReference,
    handleSubmit,
  ]);

  const { platform } = usePlatform();

  return (
    <AppPage displayFooter={false}>
      <HeaderToolbar hideLogout={true} />
      <PageContainer className="text-center bg-gray-50" spacing="normal">
        <div className="h-full mx-auto px-8 text-left">
          <AppTitle1 arrow="right">
            {t({ id: 'page26-gift-card.title.gift-card' })}
          </AppTitle1>
          <form
            className="h-full"
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit();
            }}
          >
            <AppFormControlRHF
              className={'my-5 w-full'}
              label={t({ id: 'page26-gift-card.text.how-much-to-offer' })}
              control={control}
              name={'amount'}
              required={true}
              validation={{
                rules: { required: true, min: 1 },
              }}
              renderComponent={(props) => (
                <AppInput
                  {...props}
                  type="number"
                  inputMode="numeric"
                  className="my-3 w-full text-xl font-bold"
                  // required={true}
                  icon={AppIcons.giftCard}
                  // label={t({ id: 'page26-gift-card.text.how-much-to-offer' })}
                  unit="Kr"
                  // autoComplete="off"
                />
              )}
            />

            {/* <div className="my-5 w-full" style={{ maxWidth: 400 }}>
              <label className="text-base text-gray-600">
                {t({ id: 'page26-gift-card.text.how-much-to-offer' })}
              </label>
              <AppInput
                {...register('amount', {
                  required: true,
                  valueAsNumber: true,
                  min: 1,
                })}
                type="number"
                className="my-3 w-full text-xl font-bold"
                // required={true}
                icon={AppIcons.giftCard}
                // label={t({ id: 'page26-gift-card.text.how-much-to-offer' })}
                unit="Kr"
                // autoComplete="off"
              />
            </div> */}
            {formState.isSubmitted && formState.errors?.amount && (
              <div className="font-bold text-xs text-app-danger">
                {t({ id: 'common.message.error.mandatory-field' })}
              </div>
            )}
            <div>
              <IonButton
                type="submit"
                className="w-full mx-auto"
                // shape="round"
                color="primary"
              >
                {t({ id: 'common.action.submit' })}
              </IonButton>
            </div>
          </form>
          <div className="my-5">
            <p className="font-bold text-sm text-gray-600">
              {t({
                id:
                  'page26-gift-card.text.gift-cards-can-be-used-on-all-our-parks',
              })}
            </p>
            <a
              className="block py-2 cursor-pointer font-bold text-sm text-gray-800"
              onClick={() => {
                appBrowser.openUrl({
                  platform,
                  url: 'https://www.accropark.se/tarifs-lida',
                });
              }}
            >
              Park LIDA:{' '}
              <span className="font-normal text-app-link">
                {t({ id: 'page26-gift-card.button.see-prices' })}
              </span>
            </a>
            <a
              className="block py-2 cursor-pointer font-bold text-sm text-gray-800"
              onClick={() => {
                appBrowser.openUrl({
                  platform,
                  url: 'https://www.accropark.se/tarifs-bro',
                });
              }}
            >
              Park BRO:{' '}
              <span className="font-normal text-app-link">
                {t({ id: 'page26-gift-card.button.see-prices' })}
              </span>
            </a>
          </div>
          <div className="my-5">
            <p className="italic text-left text-sm text-gray-600">
              {t({
                id: 'page26-gift-card.text.how-to-use-your-gift-card',
              })}{' '}
              :{' '}
              <a
                className="cursor-pointer not-italic text-app-link"
                onClick={() => {
                  appRouter.navigate(
                    appRoutesBuilder.getGroupHomeUrl({
                      groupReference: company?.companyGroupReference,
                      isAuthenticated: auth?.isAuthenticated,
                    }),
                    { cause: 'click-choose-park-from-gift-card-create' }
                  );
                }}
              >
                {t({
                  id: 'page07.home.title.accropark',
                }).toLowerCase()}
              </a>
            </p>
          </div>
        </div>
      </PageContainer>
      <FooterActions
        onCancel={() => {
          if (booking) {
            deleteBookingConfirm();
          } else {
            goBack();
          }
        }}
        onSubmit={onSubmit}
      />
    </AppPage>
  );
};
