import { CustomerJourneyStatus } from 'lib-common-model';
import { appLogger } from 'lib-web-logger';
import {
  AppRouteGuard,
  AppRouteGuardContext,
} from '_01_CORE/app-core/app-router';
import { redirectToDefaultRoute } from './redirectToDefaultRoute.guard';
export function requireJourneyStatus(
  routeParamName: string,
  authorizedJourneyStatuses:
    | CustomerJourneyStatus
    | CustomerJourneyStatus[] = []
): AppRouteGuard {
  return async (context: AppRouteGuardContext) => {
    authorizedJourneyStatuses = Array.isArray(authorizedJourneyStatuses)
      ? authorizedJourneyStatuses
      : [authorizedJourneyStatuses];
    const { routeParams, appCacheContext } = context;
    const customerJourneyId = routeParams[routeParamName];

    if (!customerJourneyId) {
      appLogger.warn(
        `[requireJourneyStatus] NO parameter "${routeParamName}" in URL: redirect to home`
      );
      return redirectToDefaultRoute('requireJourneyStatus.guard')(context);
    }

    const journey = appCacheContext.auth.customerProfile.journeys.find(
      (j) => j._id === customerJourneyId
    );
    if (journey) {
      if (
        authorizedJourneyStatuses.some((status) => status === journey.status)
      ) {
        return context; // ok
      }
      appLogger.warn(
        `[requireJourneyStatus] cant display that page with status "${
          journey.status
        }". Authorized statuses: ${authorizedJourneyStatuses.join(', ')}`
      );
    } else {
      appLogger.warn(
        `[requireJourneyStatus] journey not found for id ${customerJourneyId}: redirect to home`
      );
    }

    return redirectToDefaultRoute('requireJourneyStatus.guard')(context);
  };
}
