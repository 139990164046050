import { AppLoaderType } from '_01_CORE/_components-core';
import { useAppRouter } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import { ecommerceShoppingCartApiClient } from '_01_CORE/app-core/app-api/ecommerceBookingJourneyApiClient.service.1';
import { appBrowser, useAppPlatform } from '_01_CORE/app-core/app-platform';
import {
  CMShoppingCartPaymentResume,
  EcommercePaymentPlatformLinkInfo,
} from 'lib-common-model';
import { useCallback, useEffect, useState } from 'react';
import {
  ALShoppingCartPaymentPageMode,
  useALShoppingCartPaymentPageMode,
} from './useALShoppingCartPaymentPagePageMode.hook';

export function useALShoppingCartPaymentPageState({
  customerJourneyId,
  shoppingCartId,
}: {
  customerJourneyId: string;
  shoppingCartId: string;
}) {
  const { platform } = useAppPlatform();

  const pageMode: ALShoppingCartPaymentPageMode = useALShoppingCartPaymentPageMode();

  const [operationInProgress, setOperationInProgress] = useState<{
    type: AppLoaderType;
    // message: string; on pourrait préciser un message
  }>({
    type: 'loading',
  });

  const appRouter = useAppRouter();

  const [
    shoppingCartPaymentResume,
    setShoppingCartPaymentResume,
  ] = useState<CMShoppingCartPaymentResume>();

  const [
    paymentLinkInfo,
    setPaymentLinkInfo,
  ] = useState<EcommercePaymentPlatformLinkInfo>();

  const [forceRefreshCount, setForceRefreshCount] = useState(0);
  const [debugMessages, setDebugMessages] = useState<string[]>([]);

  const addDebugMessage = useCallback(
    (msg: string) => {
      if (pageMode.showDebugArea) {
        console.log('[DEBUG] ', msg);
        // note, à cause des eslint-disable-next-line, ça ne marche pas très bien (mais ça suffit pour le besoin car y'a le log qui lui marche bien)
        setDebugMessages([...debugMessages, msg]);
      }
    },
    [debugMessages, pageMode.showDebugArea]
  );

  useEffect(() => {
    (async () => {
      if (forceRefreshCount > 0) {
        try {
          const {
            shoppingCartPaymentResume,
          } = await ecommerceShoppingCartApiClient.fetchJourneyShoppingCartPaymentResume(
            {
              shoppingCartId,
              maxTries: 3,
            }
          );
          addDebugMessage(
            `Refreshed N°${forceRefreshCount} status="${shoppingCartPaymentResume.shoppingCart.status}", paymentStatus="${shoppingCartPaymentResume.shoppingCart?.lastOnlinePaymentMeta?.paymentStatus}"`
          );
          setShoppingCartPaymentResume(shoppingCartPaymentResume);
          if (
            shoppingCartPaymentResume.shoppingCart.status !==
              'payment-pending' ||
            shoppingCartPaymentResume.shoppingCart?.lastOnlinePaymentMeta
              ?.paymentStatus === 'cancelled'
          ) {
            if (pageMode.id === 'app-root') {
              // le paiement est accepté ou annulé, fermeture du slave par le root
              setTimeout(() => {
                // on laisse 1 seconde à la fenêtre stripe pour se fermer
                if (pageMode.disableAutoClose) {
                  addDebugMessage(`Disable close slave browser`);
                } else {
                  addDebugMessage('Close slave browser');
                  appBrowser.closeBrowser();
                }
              }, 1000);
            }
          } else {
            setTimeout(() => {
              setForceRefreshCount(forceRefreshCount + 1);
            }, pageMode.refreshPeriod);
          }
        } catch (err) {
          console.error(err);
          setTimeout(() => {
            // try again in 10s
            setForceRefreshCount(forceRefreshCount + 1);
          }, 10 * pageMode.refreshPeriod);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceRefreshCount]);

  const tryToOpenPaymentInAppBrowser = useCallback(async () => {
    // APP MODE: open this page on web browser
    try {
      setOperationInProgress({
        type: 'loading',
      });
      const {
        shoppingCartPaymentResume,
      } = await ecommerceShoppingCartApiClient.fetchJourneyShoppingCartPaymentResume(
        {
          shoppingCartId,
          removeCancelStatus: true,
          maxTries: 3,
        }
      );
      if (
        shoppingCartPaymentResume.shoppingCart.paymentMode === 'online' &&
        shoppingCartPaymentResume.shoppingCart.status === 'payment-pending'
      ) {
        // if (
        //   shoppingCartPaymentResume.shoppingCart?.lastOnlinePaymentMeta
        //     ?.paymentStatus === 'cancelled'
        // ) {
        //   // ssss
        //   await ecommerceShoppingCartApiClient.confirmJourneyShoppingCart({
        //     customerJourneyId,
        //     appLanguageCode: undefined,
        //     platformLanguageCode: undefined,
        //     paymentMode: 'online',
        //   });
        // }
        // ok: open browser
        const url =
          (await appBrowser.buildAnimLivePaymentPageURL({
            customerJourneyId,
            shoppingCartId,
          })) + '?page-mode=app-slave';

        if (pageMode.showAlertBeforeRedirect) {
          alert('Payment page:' + url);
        }
        if (pageMode.disableAutoRedirect) {
          addDebugMessage(`Skip open payment page on "${platform}: ${url}"`);
          // redirection désactivée, pour tester ce qui est affiché, en dev
          console.log(
            `[DEV ONLY] [TEST_DEV_NO_AUTO_REDIRECT=true] OUVERTURE PAGE DE PAIEMENT DEPUIS L'APPLI`,
            url
          );
        } else {
          addDebugMessage(
            `Open payment page on "${platform}": ${url.substring(0, 30)}...`
          );
          await appBrowser.openAnimLivePaymentPage({ url, platform });
        }

        //
        // start fast refresh
        // NOTE: on en met pas à jour le shopping card, car il s'agit du statut avant ouvreture, et il se peut qu'il soit annulé, ce qui entrainerait la fermeture de la page
        setShoppingCartPaymentResume(shoppingCartPaymentResume);
        setForceRefreshCount(forceRefreshCount + 1);
        // setTimeout(() => {
        //   // on attend 10 secondes avant le premier refresh (pour éviter de rafraichir un paiement encore annulé?)

        // }, 10000);
        setOperationInProgress(undefined);
      } else {
        // shopping cart not ready for payment: back to shopping cart
        if (pageMode.showAlertBeforeRedirect) {
          alert('Shopping cart not ready for payment');
        }
        appRouter.navigate(
          appRoutesBuilder.getJourneyShoppingCartDetailsUrl({
            customerJourneyId,
            shoppingCartId,
          }),
          {
            cause: 'shopping cart not ready for payment ',
          }
        );
      }
    } catch (err) {
      // shopping cart can't be loaded
      if (pageMode.showAlertBeforeRedirect) {
        console.error(err);
        alert(`Shopping cart ${shoppingCartId} not fount`);
      }
      appRouter.navigate(
        appRoutesBuilder.getJourneyHomeUrl({
          customerJourneyId,
        }),
        {
          cause: 'shopping cart not fount',
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    forceRefreshCount, // seulement si cette variable est à jour (sinon on déclenche plein de refresh inutiles)
  ]);

  const tryToStartPaymentAndRedirectToPayment = useCallback(async () => {
    try {
      setOperationInProgress({
        type: 'loading',
      });
      addDebugMessage(
        `Create journey shopping cart payment with pageModeId="${pageMode.id} and shoppingCartId=${shoppingCartId}"}`
      );
      const {
        paymentLinkInfo,
        shoppingCartPaymentResume,
      } = await ecommerceShoppingCartApiClient.createJourneyShoppingCartPayment(
        {
          shoppingCartId,
          pageModeId: pageMode.id,
        }
      );

      if (pageMode.showAlertBeforeRedirect) {
        alert('Payment link:' + paymentLinkInfo.paymentLink);
      }
      if (paymentLinkInfo?.paymentLink) {
        // paiement créé: redirection sur la page de paiement
        let openDelay = 5000;
        addDebugMessage(`PaymentLink: ${paymentLinkInfo?.paymentLink}"}`);
        if (pageMode.disableAutoRedirect) {
          // redirection désactivée, pour tester ce qui est affiché, en dev
          console.log(
            `[DEV ONLY] [TEST_DEV_NO_AUTO_REDIRECT=true] REDIRECTION WEB PAGE DE PAIEMENT`,
            paymentLinkInfo.paymentLink
          );
          openDelay = 1000;
        } else {
          window.location.href = paymentLinkInfo.paymentLink;
        }
        setTimeout(() => {
          // on affiche le bouton d'ouverture de la page de paiement, au cas où la redirection n'a pas fonctionné
          setShoppingCartPaymentResume(shoppingCartPaymentResume);
          setPaymentLinkInfo(paymentLinkInfo);
          setOperationInProgress(undefined);
        }, openDelay);
      } else {
        addDebugMessage(`PaymentLink missing`);
        // échec lors de la création du paiement
        // on rafraichit juste la page (un bouton pour essayer de nouveau de créer le paiement doit être affiché si le statut est compatible)
        setShoppingCartPaymentResume(shoppingCartPaymentResume);
        setForceRefreshCount(forceRefreshCount + 1);
        setOperationInProgress(undefined);
      }
    } catch (err) {
      // shopping creation error
      addDebugMessage(`Error create link: ${(err as any)?.message}"}`);
      if (pageMode.showAlertBeforeRedirect) {
        alert('Shopping cart creation error');
      }
      appRouter.navigate(
        appRoutesBuilder.getJourneyHomeUrl({
          customerJourneyId,
        }),
        {
          cause: 'shopping cart payment creation error',
        }
      );
    }
  }, [
    addDebugMessage,
    pageMode.id,
    pageMode.showAlertBeforeRedirect,
    pageMode.disableAutoRedirect,
    shoppingCartId,
    forceRefreshCount,
    appRouter,
    customerJourneyId,
  ]);

  useEffect(
    () => {
      // initial page loading
      addDebugMessage(
        `Page mode id=${pageMode.id}, loadingMode=${pageMode.loadingMode}, paymentResult=${pageMode.paymentResult}`
      );
      if (pageMode.loadingMode === 'back-from-payment') {
        // TODO: afficher le succès ou l'erreur de paiement
        // retour de la page de paiement
        setOperationInProgress(undefined);
        setForceRefreshCount(forceRefreshCount + 1);
        // si succès: bouton poursuivre
        // si erreur: bouton retour ou payer
        if (pageMode.paymentResult === 'cancel') {
          // on annule le paiement, ce qui va avoir comme effet de prévenir le root, qui va fermer la page
          addDebugMessage(`Cancel payment from slave`);
          ecommerceShoppingCartApiClient.cancelJourneyShoppingCartPayment({
            shoppingCartId,
          });
        }

        if (pageMode.id === 'app-slave') {
          setTimeout(() => {
            // après quelques secondes, fermeture de la fenêtre par l'esclave, si ça n'a pas été fait par le root
            try {
              if (pageMode.disableAutoClose) {
                addDebugMessage(`Disable close self window`);
              } else {
                addDebugMessage(`Close self window`);
                self.close(); // NOTE: ne fonctionne que si la fenêtre a été ouverte par un script, donc inutile sur les appli mobiles (ne marche pas sur android, pour le sûr)
              }
            } catch (err) {
              console.error(err);
            }
          }, 3000);
        }
      } else if (pageMode.loadingMode === 'start-payment') {
        if (pageMode.id === 'app-root') {
          // app mobile: open payment page
          addDebugMessage(`Try to open slave app browser from master`);
          tryToOpenPaymentInAppBrowser();
        } else {
          // web browser: on essaie de créer immédiatement le paiement
          addDebugMessage(`Try to create payemnt and redirect to payment page`);
          tryToStartPaymentAndRedirectToPayment();
        }
      }
    },
    // exécuté une seule fois, au chargement
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const goBackToActivities = useCallback(() => {
    if (pageMode.showAlertBeforeRedirect) {
      alert('Go back to activities');
    }
    appRouter.navigate(
      appRoutesBuilder.getJourneyActivitiesUrl({
        customerJourneyId,
        category: 'animator',
      }),
      { cause: 'shopping-cart-back' }
    );
  }, [appRouter, customerJourneyId, pageMode.showAlertBeforeRedirect]);

  const goBackToShoppingCart = useCallback(
    ({ shoppingCartId }: { shoppingCartId: string }) => {
      if (pageMode.showAlertBeforeRedirect) {
        alert('Go back to shopping cart');
      }
      appRouter.navigate(
        appRoutesBuilder.getJourneyShoppingCartDetailsUrl({
          customerJourneyId,
          shoppingCartId,
        }),

        { cause: 'shopping-cart-back' }
      );
    },
    [appRouter, customerJourneyId, pageMode.showAlertBeforeRedirect]
  );

  // const cancelPayment = useCallback(() => {
  //   setPaymentState({
  //     currentStep: '3-payment-cancel',
  //     paymentLinkInfo: undefined,
  //     shoppingCartResume: undefined,
  //   });
  //   goBackToShopping();
  // }, [goBackToShopping]);

  // const startOnlinePayment = useOperationPending(
  //   async (shoppingCartResume: CMShoppingCartResume) => {
  //     if (
  //       shoppingCartResume?.shoppingCart?.paymentStatus === 'payment-pending'
  //     ) {
  //       try {

  //         setTimeout(
  //           () =>
  //             setPaymentState({
  //               currentStep: '1-payment-not-started',
  //               paymentLinkInfo,
  //               shoppingCartResume,
  //             }),
  //           1000
  //         );
  //         return paymentLinkInfo;
  //       } catch (err) {
  //         goBackToShopping();
  //       }
  //     }
  //   },
  //   [customerJourneyId, goBackToShopping, platform, redirectMode]
  // );

  return {
    paymentLinkInfo,
    goBackToActivities,
    goBackToShoppingCart,
    shoppingCartPaymentResume,
    operationInProgress,
    pageMode,
    debugMessages,
  };
}

export type AnimLiveJourneyShoppingCartPaymentPageState = ReturnType<
  typeof useALShoppingCartPaymentPageState
>;
