import { RadioGroup } from '@headlessui/react';
import clsx from 'clsx';
import { ParticipantPaymentMode } from 'lib-common-model';
import { AppToggleButtonGroupProps } from './AppToggleButtonGroupProps.type';

export function AppToogleButtonGroupTailwind<
  T extends string | number | boolean
>({
  customClassName,
  selected,
  onChanges,
  items,
  className,
  color,
}: AppToggleButtonGroupProps<T>) {
  return (
    <RadioGroup
      value={selected}
      onChange={(item) => {
        onChanges((item as any)?.value);
      }}
      className={`mx-0.5 mt-2 ${className}`}
    >
      <RadioGroup.Label className="sr-only">
        {/* Choose a memory option */}
      </RadioGroup.Label>
      <div
        className={`${
          customClassName ??
          (items.length === 2
            ? 'grid grid-cols-2'
            : 'flex flex-col sm:grid sm:grid-cols-3')
        } gap-3`}
      >
        {items.map((option) => (
          <RadioGroup.Option
            key={option.label}
            value={option}
            className={({ active, checked }) =>
              clsx(
                !option.disabled
                  ? 'cursor-pointer focus:outline-none'
                  : 'opacity-25 cursor-not-allowed',
                active
                  ? `ring-2 ring-offset-2 ${
                      color === 'payment-mode'
                        ? `ring-payment-mode-${
                            (option.value as unknown) as ParticipantPaymentMode
                          }`
                        : color === 'boolean'
                        ? option.value === true
                          ? 'ring-green-500'
                          : 'ring-red-500'
                        : color === 'blue'
                        ? 'ring-blue-500'
                        : 'ring-green-500'
                    }`
                  : '',
                option.value === selected
                  ? color === 'payment-mode'
                    ? `bg-payment-mode-${
                        (option.value as unknown) as ParticipantPaymentMode
                      }  border-transparent text-white hover:bg-payment-mode-${
                        (option.value as unknown) as ParticipantPaymentMode
                      }-dark`
                    : color === 'boolean'
                    ? option.value === true
                      ? 'bg-green-500 border-transparent text-white hover:bg-green-600'
                      : 'bg-red-500 border-transparent text-white hover:bg-red-600'
                    : color === 'blue'
                    ? 'bg-blue-500 border-transparent text-white hover:bg-blue-600'
                    : 'bg-green-500 border-transparent text-white hover:bg-green-600'
                  : 'bg-white border-gray-400 text-gray-700 hover:bg-gray-50',
                'border rounded-md py-3 px-1 sm:px-3 flex items-center justify-center text-xs sm:text-sm font-medium uppercase sm:flex-1'
              )
            }
            disabled={option.disabled}
            style={
              option.color && {
                borderColor: option.color,
              }
            }
          >
            <RadioGroup.Label as="span">
              <span className="inline-flex gap-1 s:gap-2 items-center overflow-hidden">
                {option.icon && (
                  <span
                    className={clsx(
                      ' border flex items-center py-pt px-pt',
                      color === 'payment-mode' && option.value === selected
                        ? 'text-gray-500'
                        : ''
                    )}
                    style={{
                      background: '#fff',
                      color: option.color,
                      borderColor: option.color,
                    }}
                  >
                    <option.icon className="h-4 w-4" />
                  </span>
                )}
                <span
                  className="font-bold truncate uppercase flex items-center"
                  style={{
                    color: option.color,
                  }}
                >
                  {option.label}
                </span>
              </span>
            </RadioGroup.Label>
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}
