import { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import React from 'react';

export const ArrowLeft = (props: IonicReactProps) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 700 700"
      preserveAspectRatio="xMidYMid meet"
      {...props}
      x="0px"
      y="0px"
    >
      <g
        transform="translate(0.000000,604.000000) scale(0.100000,-0.100000)"
        fill="currentColor"
        stroke="none"
      >
        <path
          d="M6005.3,5963c18-21,81-90,140-153c58-63,207-225,330-360s227-247,230-250c9-8,214-234,233-256c16-20,16-21-10-35
          c-184-94-848-471-1303-742c-1532-909-2474-1619-3286-2477c-172-182-162-170-139-183c34-20,100-44,177-63c66-17,107-19,437-16
          c210,2,393-1,435-7c39-6,101-13,138-17c38-3,68-9,68-13c0-20-719-389-1030-529c-25-11-90-40-145-65c-55-24-145-64-200-87
          s-114-48-130-55c-250-111-890-344-1270-462c-238-74-567-164-580-159c-7,2-19,74-29,177c-15,142-18,223-13,444c6,255,16,367,51,580
          c17,102,51,263,65,315c6,19,25,87,42,150c47,172,156,496,217,645c84,208,191,463,222,530c20,44,47,105,61,135
          c36,83,316,642,332,664c13,17,15,9,21-80c4-54,12-259,16-454c7-266,13-369,24-410c23-83,67-180,81-180c6,0,65,57,130,128
          c65,70,227,235,359,368c208,207,306,301,566,539c116,106,429,370,627,527c514,410,971,728,1567,1092c87,53,160,96,162,96
          s22,11,43,24c42,27,323,184,461,258c102,55,475,242,580,291c41,19,113,53,160,74C5972.3,6007,5968.3,6006,6005.3,5963z"
        />
      </g>
    </svg>
  );
};
