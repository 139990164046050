import { useMemo } from 'react';
import { useAppCacheContext } from '_01_CORE/app-contexts';

export const useDisplayPickupKids = () => {
  const { journeyEditData } = useAppCacheContext();

  const displayPickUpKids = useMemo<boolean>(
    () =>
      (journeyEditData?.participants ?? []).some(
        (p) =>
          p.enabled && p.attributes.journeyAge < 18 && !p.attributes.exitPermit
      ),
    [journeyEditData?.participants]
  );

  return displayPickUpKids;
};
