import { IonIcon, IonRouterLink } from '@ionic/react';
import { chevronBackOutline } from 'ionicons/icons';
import React from 'react';
import { useAppRouter } from '_01_CORE/_hooks';

export const PageBackLink: React.FC<{
  spacing?: 'small' | 'normal';
  mode: 'block' | 'absolute';
  backLink?: string;
  className?: string;
}> = ({ spacing, children, backLink, mode, className = '' }) => {
  const appRouter = useAppRouter();
  const spacingClass = spacing === 'small' ? 'm-2' : 'p-5';
  const modeClass = mode === 'absolute' ? 'absolute left-2 top-0' : 'flex ';
  return backLink ? (
    <IonRouterLink
      onClick={() => appRouter.navigate(backLink, { cause: 'page-back' })}
    >
      <div
        className={`text-xl font-bold text-tertiary ${spacingClass} ${modeClass} ${className}`}
      >
        <IonIcon
          className="flex-grow-0"
          icon={chevronBackOutline}
          slot="icon-only"
        />
        {children && <div className="flex-grow">{children}</div>}
      </div>
    </IonRouterLink>
  ) : (
    <>{children && children}</>
  );
};
