import {
  AdjustmentsHorizontalIcon,
  ArrowDownTrayIcon,
  ArrowPathIcon,
  ArrowTopRightOnSquareIcon,
  BanknotesIcon,
  Bars3Icon,
  BellIcon as BellOutlineIcon,
  CakeIcon,
  CheckIcon,
  ClockIcon,
  CurrencyDollarIcon,
  EnvelopeIcon,
  InformationCircleIcon,
  LinkIcon,
  MagnifyingGlassIcon,
  MapPinIcon,
  PencilIcon,
  PhoneIcon,
  PlusIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import {
  ArrowLeftCircleIcon,
  ArrowPathRoundedSquareIcon,
  ArrowRightCircleIcon,
  BellAlertIcon,
  BellIcon as BellSolidIcon,
  BriefcaseIcon,
  CameraIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronUpDownIcon,
  ChevronUpIcon,
  ClipboardDocumentListIcon,
  CreditCardIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  GiftIcon,
  InformationCircleIcon as InformationCircleIconSolid,
  KeyIcon,
  LockOpenIcon,
  PhotoIcon,
  PlusCircleIcon,
  PuzzlePieceIcon,
  ShoppingBagIcon,
  ShoppingCartIcon,
  SparklesIcon,
  StarIcon,
  TrashIcon,
} from '@heroicons/react/24/solid';
import { accropark } from '_01_CORE/_icons/string/accropark';
import { BiPowerOff } from 'react-icons/bi';
import { IoCall, IoHome } from 'react-icons/io5';
import { MdAddAPhoto, MdOutlinePhotoCameraFront } from 'react-icons/md';
import { ExitPermitIcon } from './ExitPermitIcon';
import { SwimIcon } from './SwimIcon';

export const AppIcons = {
  code: KeyIcon,
  password: LockOpenIcon,
  actionReduce: ChevronUpIcon,
  search: MagnifyingGlassIcon,
  actionSettingsDisplay: AdjustmentsHorizontalIcon,
  actionExtend: ChevronDownIcon,
  shoppingCart: ShoppingCartIcon,
  photo: MdOutlinePhotoCameraFront,
  actionAddPhoto: MdAddAPhoto,
  actionOpenPhotoGallery: PhotoIcon,
  other: ArrowPathRoundedSquareIcon,
  actionRefresh: ArrowPathIcon,
  activityPackage: SparklesIcon,
  activityPackageLeisure: PuzzlePieceIcon,
  registrationWaiting: ClockIcon,
  openArrowRight: ChevronRightIcon,
  home: IoHome,
  contact: IoCall,
  accropark: accropark,
  bookingIconAnimLive: BriefcaseIcon,
  bookingIconAccropark: ShoppingBagIcon,
  actionDownload: ArrowDownTrayIcon,
  check: CheckIcon,
  notification: BellOutlineIcon,
  notification2: BellSolidIcon,
  menu: Bars3Icon,
  actionLogin: BiPowerOff,
  actionLogout: BiPowerOff,
  planning: ClipboardDocumentListIcon,
  select: ChevronUpDownIcon,
  star: StarIcon,
  navLeft: ArrowLeftCircleIcon,
  navRight: ArrowRightCircleIcon,
  actionClose: XMarkIcon,
  actionEdit: PencilIcon,
  actionBack: ArrowLeftCircleIcon,
  actionAdd: PlusIcon,
  actionAddCircle: PlusCircleIcon,
  birthDay: CakeIcon,
  payment: CurrencyDollarIcon,
  paymentOnline: CreditCardIcon,
  paymentOnSite: BanknotesIcon,
  warning: ExclamationTriangleIcon,
  warningCircle: ExclamationCircleIcon,
  alert: BellAlertIcon,
  participantImageRights: CameraIcon,
  participantExitPermit: ExitPermitIcon,
  participantSwim: SwimIcon,
  actionCancel: XCircleIcon,
  actionDelete: TrashIcon,
  quota: XCircleIcon,
  info: InformationCircleIcon,
  infoSolid: InformationCircleIconSolid,
  location: MapPinIcon,
  phone: PhoneIcon,
  mail: EnvelopeIcon,
  link: LinkIcon,
  linkExternal: ArrowTopRightOnSquareIcon,
  giftCard: GiftIcon,
};
