import {
  AppSettings,
  CompanyOptions,
  TimeHoursMinutes,
} from 'lib-common-model';
import { dateTransformer } from './dateTransformer.service';

export const activityDateTester = {
  isPreRegistrationOpen,
  getRegistrationBeginDateTimeUTC,
  getRegistrationTime,
};

function isPreRegistrationOpen({
  appSettings,
  companyOptions,
  registrationBeginDate,
  registrationBeginTimeId,
}: {
  appSettings: AppSettings;
  companyOptions: CompanyOptions;
  registrationBeginDate: Date;
  registrationBeginTimeId: string;
}) {
  // ATTENTION: fonction dupliquée côté NOUVEAU PLANNING WEB + ANCIEN PLANNING WEB + API
  const registrationBeginDateTimeUTC = getRegistrationBeginDateTimeUTC({
    appSettings,
    companyOptions,
    registrationBeginDate,
    registrationBeginTimeId,
  });
  const nowUTC = dateTransformer.getUTCFromLocalDate(new Date());
  const preRegistrationOpen =
    nowUTC.getTime() >= registrationBeginDateTimeUTC.getTime();
  return preRegistrationOpen;
}

function getRegistrationBeginDateTimeUTC({
  appSettings,
  companyOptions,
  registrationBeginDate,
  registrationBeginTimeId,
}: {
  appSettings: AppSettings;
  companyOptions: CompanyOptions;
  registrationBeginDate: Date;
  registrationBeginTimeId: string;
}) {
  // ATTENTION: fonction dupliquée côté PUBLIC WEB + PLANNING WEB
  const registrationTime: TimeHoursMinutes = getRegistrationTime({
    companyOptions,
    registrationBeginTimeId,
    appSettings,
  });

  return dateTransformer.getUTCDateSetTime(
    registrationBeginDate,
    registrationTime.hours,
    registrationTime.minutes
  );
}

function getRegistrationTime({
  appSettings,
  companyOptions,
  registrationBeginTimeId,
}: {
  appSettings: AppSettings;
  companyOptions: CompanyOptions;
  registrationBeginTimeId?: string;
}) {
  const registrationTimes =
    companyOptions?.preRegistration?.registrationTimes ?? [];

  const registrationTime: TimeHoursMinutes =
    registrationTimes.find(
      (x) => x._id === registrationBeginTimeId // id spécifié sur la session
    )?.time ??
    registrationTimes.find(
      (x) =>
        x._id ===
        // id par défaut du camping
        companyOptions?.preRegistration?.registrationTimeDefaultId
    )?.time ??
    // id par défaut de l'application
    appSettings?.features?.customers?.registrationDefaultTime;
  return registrationTime;
}
