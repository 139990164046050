import { AppHackableCalendarVM } from './model';
import { AppHackableCalendarProps } from './props';
import { useAppHackableCalendarVM } from './useAppHackableCalendarVM.hook';
import { AppHackableCalendarWeeksPanel } from './weeks-panel';
import { AppHackableCalendarYearsPanel } from './years-panel';

/**
 * AppHackableCalendar description blablabla
 */
export const AppHackableCalendar = (props: AppHackableCalendarProps) => {
  const vm: AppHackableCalendarVM = useAppHackableCalendarVM({
    props,
  });
  return (
    <div className="w-96 max-w-full mt-2 sm:mt-6 md:mt-10 text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 lg:mt-9 xl:col-start-9">
      {vm.view === 'weeks' && <AppHackableCalendarWeeksPanel {...vm} />}
      {vm.view === 'years' && <AppHackableCalendarYearsPanel {...vm} />}
    </div>
  );
};
