import { CMExtJourneyFinderResult } from 'lib-common-model';
import {
  AuthMultiLoginAnimLivePageViewState,
  AuthPageMultiLoginJourneyActivationCheckMethod,
  AuthPageMultiLoginJourneyActivationState,
} from '../_model';

export const authMultiLoginAnimLivePageViewStateBuilder = {
  buildUpdatedViewStateFromCheckKeySuccess,
  buildUpdatedViewStateFromCheckTokenSuccess,
  buildJourneyActivationState,
};

function buildUpdatedViewStateFromCheckKeySuccess({
  viewState,
  fetchedJourney,
}: {
  viewState: AuthMultiLoginAnimLivePageViewState;
  fetchedJourney: CMExtJourneyFinderResult;
}): AuthMultiLoginAnimLivePageViewState {
  const checkKey = viewState?.checkKey;
  const baseViewState: AuthMultiLoginAnimLivePageViewState = buildUpdatedViewStateFromFetchedJourney(
    { fetchedJourney, viewState, checkMethod: 'key' }
  );
  const updatedViewState: AuthMultiLoginAnimLivePageViewState = {
    ...baseViewState,
    checkKey: {
      ...checkKey,
      state: 'success',
    },
  };
  return updatedViewState;
}
function buildUpdatedViewStateFromCheckTokenSuccess({
  viewState,
  fetchedJourney,
}: {
  viewState: AuthMultiLoginAnimLivePageViewState;
  fetchedJourney: CMExtJourneyFinderResult;
}): AuthMultiLoginAnimLivePageViewState {
  const checkToken = viewState?.checkToken;
  const baseViewState: AuthMultiLoginAnimLivePageViewState = buildUpdatedViewStateFromFetchedJourney(
    { fetchedJourney, viewState, checkMethod: 'token' }
  );
  const updatedViewState: AuthMultiLoginAnimLivePageViewState = {
    ...baseViewState,
    checkToken: {
      ...checkToken,
      state: 'success',
    },
  };
  return updatedViewState;
}

function buildUpdatedViewStateFromFetchedJourney({
  fetchedJourney,
  viewState,
  checkMethod,
}: {
  fetchedJourney: CMExtJourneyFinderResult;
  viewState: AuthMultiLoginAnimLivePageViewState;
  checkMethod: AuthPageMultiLoginJourneyActivationCheckMethod;
}): AuthMultiLoginAnimLivePageViewState {
  const activationState: AuthPageMultiLoginJourneyActivationState = buildJourneyActivationState(
    fetchedJourney
  );
  const baseUpdatedViewState: AuthMultiLoginAnimLivePageViewState = {
    ...viewState,
    operationInProgress: undefined,
    journeyInfo: {
      activationState,
      fetchedJourney,
      checkMethod,
    },
  };

  const isSingleBookingNotTO = fetchedJourney?.bookingType === 'single';
  if (activationState === 'already-activated') {
    if (
      fetchedJourney?.existingJourneyCustomerAccounts.length === 1 &&
      isSingleBookingNotTO
    ) {
      // 1 seul compte existant (et séjour simple): on pré-rempli l'email
      const defaultEmail =
        fetchedJourney?.existingJourneyCustomerAccounts[0]?.email;
      const updatedViewState: AuthMultiLoginAnimLivePageViewState = {
        ...baseUpdatedViewState,
        mode: 'prompt-email',
        promptEmail: {
          defaultEmail,
          defaultEmailAccountExists: true,
          isRegistrationNotPossibleWithoutBooking: false,
        },
      };
      if (
        !viewState.extra.journeyEnabledActions.isActivateJourneyMultipleEnabled
      ) {
        // on est forcé d'utiliser ce compte pour ce connecter
        updatedViewState.promptEmail.emailReadOnly = true;
        if (checkMethod === 'key') {
          updatedViewState.promptEmail.showPassword = true;
        } else if (checkMethod === 'token') {
          // pas besoin de mot de passe ici
          updatedViewState.promptEmail.showPassword = false;
        }
      }
      return updatedViewState;
    } else {
      // plusieurs comptes existent (ou séjour groupe/to): il faudra entrer manuellement le login
      const updatedViewState: AuthMultiLoginAnimLivePageViewState = {
        ...baseUpdatedViewState,
        mode: 'prompt-email',
        promptEmail: {
          defaultEmail: '',
          defaultEmailAccountExists: true,
          emailReadOnly: false,
          showPassword: false,
        },
      };
      return updatedViewState;
    }
  } else {
    // le séjour n'est pas activé

    if (activationState === 'similar-account-exists' && isSingleBookingNotTO) {
      // un compte similaire existe (et séjour simple), on suggère de s'y connecter

      const updatedViewState: AuthMultiLoginAnimLivePageViewState = {
        ...baseUpdatedViewState,
        mode: 'prompt-email',
        promptEmail: {
          defaultEmail: fetchedJourney?.existingSimilarCustomerAccount?.email,
          defaultEmailAccountExists: true,
          emailReadOnly: false,
          showPassword: false,
        },
      };

      return updatedViewState;
    } else {
      // aucun compte similaire (ou compte groupe/to), il faut en créer un nouveau (ou indiquer le mail du compte existant à rattacher)
      const defaultEmail = isSingleBookingNotTO
        ? fetchedJourney?.fakeCustomerAccount?.email
        : undefined;
      const updatedViewState: AuthMultiLoginAnimLivePageViewState = {
        ...baseUpdatedViewState,
        mode: 'prompt-email',
        promptEmail: {
          defaultEmail,
        },
      };
      return updatedViewState;
    }
  }
}

function buildJourneyActivationState(
  fetchedJourney: CMExtJourneyFinderResult
): AuthPageMultiLoginJourneyActivationState {
  const {
    existingSimilarCustomerAccount,
    existingJourneyCustomerAccounts,
  } = fetchedJourney;

  const journeyActivationState: AuthPageMultiLoginJourneyActivationState = existingJourneyCustomerAccounts?.length
    ? 'already-activated'
    : existingSimilarCustomerAccount?.email
    ? 'similar-account-exists'
    : 'no-similar-account';

  return journeyActivationState;
}
