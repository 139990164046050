import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import {
  useCompany,
  useJourney,
} from '_01_CORE/app-contexts/app-cache-context';
import { customerJourneyApiClient } from '_01_CORE/app-core/app-api';
import { AppPage, HeaderToolbar } from '_01_CORE/_common-layout';
import { AppLoader } from '_01_CORE/_components-core';
import { useAppRouter, useOperationPending } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import { journeyHelper } from '_01_CORE/_services';
import { useCampingPlaceNumberVisibility } from '_02_APP_COMMON/JOU-journey/_common';
import { JourneyChooseDatesAnimLiveForm } from './JourneyChooseDatesAnimLiveForm';
import { JourneyChooseDatesAnimLiveFormData } from './JourneyChooseDatesAnimLiveFormData.type';
export const AnimLiveJourneyEditDatesPage = () => {
  const appRouter = useAppRouter();

  const [persistInProgress, setPersistInProgress] = useState(false);

  const { customerJourneyId } = useParams<{ customerJourneyId: string }>();

  const { journey, participants } = useJourney({ customerJourneyId });

  const hasJourneyAnyAttendanceOrPreRegistration = useMemo(
    () =>
      journeyHelper.hasJourneyAnyAttendanceOrPreRegistration({ participants }),
    [participants]
  );

  const { company } = useCompany({
    companyReference: journey?.company?.reference,
  });

  const { minBeginDate, maxBeginDate, minEndDate, maxEndDate } = useMemo(() => {
    const min = new Date();
    min.setFullYear(new Date().getFullYear() - 2);
    const max = new Date();
    max.setFullYear(new Date().getFullYear() + 2);
    if (hasJourneyAnyAttendanceOrPreRegistration) {
      // on limite le séjour à minima aux dates de séjour
      return {
        minBeginDate: min,
        maxBeginDate: journey?.beginDateUTC,
        minEndDate: journey?.endDateInclusiveUTC,
        maxEndDate: max,
      };
    }
    return {
      minBeginDate: min,
      maxBeginDate: max,
      minEndDate: min,
      maxEndDate: max,
    };
  }, [
    hasJourneyAnyAttendanceOrPreRegistration,
    journey?.beginDateUTC,
    journey?.endDateInclusiveUTC,
  ]);

  useEffect(() => {
    if (!journey) {
      appRouter.navigateAfterDelay(appRoutesBuilder.getRedirectToDefaultUrl(), {
        cause: 'journey-edit-date-no-journey',
      });
    }
  }, [appRouter, journey]);

  const defaultFormValue = useMemo(() => {
    const value: JourneyChooseDatesAnimLiveFormData = {
      campingPlaceNumber: journey?.campingPlaceNumber,
      beginDateUTC: journey?.beginDateUTC,
      endDateInclusiveUTC: journey?.endDateInclusiveUTC,
    };
    return value;
  }, [
    journey?.beginDateUTC,
    journey?.campingPlaceNumber,
    journey?.endDateInclusiveUTC,
  ]);

  /**
   * Submit button callback
   */
  const onSubmit = useOperationPending(
    async (data: JourneyChooseDatesAnimLiveFormData) => {
      setPersistInProgress(true);
      try {
        const {
          result,
        } = await customerJourneyApiClient.updateJourneyDatesAndPlaceNumber({
          customerJourneyId,
          data,
        });
        if (result === 'success') {
          appRouter.navigate(
            appRoutesBuilder.getJourneyEditExistingHomeUrl({
              customerJourneyId,
            }),
            {
              cause: 'journey-edit-participant-create-submit',
            }
          );
        } else {
          // TODO show message
        }
      } finally {
        setPersistInProgress(false);
      }
    },
    [appRouter, customerJourneyId]
  );

  const onBack = useCallback(() => {
    appRouter.navigate(
      appRoutesBuilder.getJourneyEditUrl({
        journey,
      }),
      { cause: 'journey-date-back' }
    );
  }, [appRouter, journey]);

  const {
    isVisibleCampingPlaceNumber,
    isEditableCampingPlaceNumber,
  } = useCampingPlaceNumberVisibility(journey);

  return (
    <AppPage displayFooter={false}>
      <HeaderToolbar hideLogout={true} />
      {persistInProgress ? (
        <AppLoader className="my-4 p-2 px-4" type="persist" />
      ) : (
        <JourneyChooseDatesAnimLiveForm
          mode="edit"
          defaultFormValue={defaultFormValue}
          onSubmit={onSubmit}
          onCancel={onBack}
          company={company}
          minBeginDate={minBeginDate}
          maxBeginDate={maxBeginDate}
          minEndDate={minEndDate}
          maxEndDate={maxEndDate}
          isEditableCampingPlaceNumber={isEditableCampingPlaceNumber}
        />
      )}
    </AppPage>
  );
};
