export const AppLoaderSpinner = ({
  className = '',
  color = 'info',
}: {
  className?: string;
  color?: 'info' | 'warn' | 'white';
}) => {
  return (
    <div
      style={{ borderTopColor: 'transparent' }}
      className={`border-4 ${
        color === 'info'
          ? 'border-app-info'
          : color === 'white'
          ? 'border-white'
          : 'border-app-warning'
      } border-solid rounded-full animate-spin ${className}`}
    ></div>
  );
};
