import { AppLoaderType } from '_01_CORE/_components-core';
import { useAppRouter } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import {
  useAppCacheContext,
  useAppLocalizationContext,
} from '_01_CORE/app-contexts';
import { ecommerceShoppingCartApiClient } from '_01_CORE/app-core/app-api/ecommerceBookingJourneyApiClient.service.1';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import {
  CMShoppingCartResume,
  CMShoppingCartResumesFetchCriteria,
} from 'lib-common-model';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

export function useAnimLiveJourneyShoppingCartPageState({
  context,
  customerJourneyId,
  shoppingCartId,
}: {
  context: 'live' | 'history' | 'details';
  customerJourneyId: string;
  shoppingCartId?: string; // détails onlhy
}) {
  const appRouter = useAppRouter();
  const { formatMessage: t } = useIntl();
  const appId = appWebConfig().appId;

  const appCacheContext = useAppCacheContext();
  const { auth } = appCacheContext;

  const customerAccountId = auth?.customerProfile?.account?._id;

  const [shoppingCartResumes, setShoppingCartResumes] = useState<
    CMShoppingCartResume[]
  >();

  const [forceRefreshCount, setForceRefreshCount] = useState(1);
  const [isAutoRefreshPeriod, setIsAutoRefreshPeriod] = useState(5000);

  const [operationInProgress, setOperationInProgress] = useState<{
    type: AppLoaderType;
    // message: string; on pourrait préciser un message
  }>({
    type: 'loading',
  });

  const {
    locales: { platformLanguageCode, appLanguageCode },
  } = useAppLocalizationContext();

  useEffect(() => {
    if (forceRefreshCount) {
      const criteria: CMShoppingCartResumesFetchCriteria = {
        customerAccountId,
        customerJourneyId,
        statuses: context === 'live' ? ['draft', 'payment-pending'] : undefined,
        shoppingCartId: shoppingCartId,
      };
      ecommerceShoppingCartApiClient
        .fetchJourneyShoppingCarts({
          criteria,
          platformLanguageCode,
          appLanguageCode,
        })
        .then(({ shoppingCartResumes, customerJourney }) => {
          appCacheContext.dispatchCacheContextAction({
            type: 'set-journey',
            customerJourney,
            context: 'loadJourneyBookingByRouteParameter',
          });

          setShoppingCartResumes(shoppingCartResumes);

          if (
            shoppingCartResumes.length === 0 ||
            shoppingCartResumes.find(
              (x) => x.shoppingCart.status === 'draft'
            ) === undefined
          ) {
            // pas de panier en cours, on ralenti le refresh
            setIsAutoRefreshPeriod(30000); // 30s
          } else {
            setIsAutoRefreshPeriod(5000); // 5s
          }
          if (forceRefreshCount === 1) {
            setOperationInProgress(undefined);
          }
        })
        .catch((err) => {
          // TODO il ne faudrait rediriger que si ce n'est pas une erreur réseau
          // no shopping cart available
          appRouter.navigate(
            appRoutesBuilder.getJourneyHomeUrl({
              customerJourneyId,
            }),
            {
              cause: 'no shopping cart available',
            }
          );
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceRefreshCount]);

  useEffect(() => {
    if (
      isAutoRefreshPeriod > 0
      // sur ios, on ouvre une autre fenêtre, donc il faut rafraichir la fenêtre courante régulièrement
    ) {
      console.log('AUTO REFRESH ON');
      // auto-refresh
      const handle = setInterval(async () => {
        if (isAutoRefreshPeriod > 0) {
          console.log('AUTO REFRESH NOW');
          setForceRefreshCount(forceRefreshCount + 1);
        }
      }, isAutoRefreshPeriod);
      return () => {
        clearInterval(handle);
      };
    }
  }, [customerJourneyId, forceRefreshCount, isAutoRefreshPeriod]);

  const goBackToActivities = useCallback(() => {
    appRouter.navigate(
      appRoutesBuilder.getJourneyActivitiesUrl({
        customerJourneyId,
        category: 'animator',
      }),
      { cause: 'shopping-cart-back' }
    );
  }, [appRouter, customerJourneyId]);

  const goBackToShopping = useCallback(() => {
    appRouter.navigate(
      appRoutesBuilder.getJourneyShoppingCartLiveUrl({ customerJourneyId }),

      { cause: 'shopping-cart-back' }
    );
  }, [appRouter, customerJourneyId]);

  const openOnlinePaymentPage = useCallback(
    (shoppingCartResume: CMShoppingCartResume) => {
      appRouter.navigate(
        appRoutesBuilder.getJourneyShoppingCartPaymentUrl({
          customerJourneyId: shoppingCartResume.shoppingCart.customerJourneyId,
          shoppingCartId: shoppingCartResume.shoppingCart._id,
        }),

        { cause: 'shopping-cart-go-to-payment' }
      );
    },
    [appRouter]
  );

  const updateShoppingCartResume = useCallback(
    (resume: CMShoppingCartResume) => {
      setShoppingCartResumes([
        ...shoppingCartResumes.filter(
          (x) => x.shoppingCart._id !== resume.shoppingCart._id
        ),
        resume,
      ]);
    },
    [shoppingCartResumes]
  );

  return {
    goBackToActivities,
    goBackToShopping,
    openOnlinePaymentPage,
    shoppingCartResumes,
    context,
    operationInProgress,
    updateShoppingCartResume,
  };
}

export type AnimLiveJourneyShoppingCartPageStateState = ReturnType<
  typeof useAnimLiveJourneyShoppingCartPageState
>;
