import { useMemo } from 'react';
import { useAppCacheContext } from '_01_CORE/app-contexts';
import { CustomerAccountEditFormData } from '_02_APP_COMMON/_model';

export const useAccroparkCustomerAccountEditDefaultValues = ({
  defaultName,
}: {
  defaultName?: {
    lastName: string;
    firstName: string;
  };
}) => {
  const { auth } = useAppCacheContext();

  return useMemo<Partial<CustomerAccountEditFormData>>(() => {
    const ca = auth?.customerProfile?.account;
    if (ca?.status === 'draft' && !ca.lastName) {
      const formData: Partial<CustomerAccountEditFormData> = {
        lastName: defaultName?.lastName,
        firstName: defaultName?.firstName,
        country: 'SWE',
        language: 'swe',
      };
      return formData;
    } else {
      const formData: Partial<CustomerAccountEditFormData> = {
        lastName: ca.lastName,
        firstName: ca.firstName,
        country: ca.country ?? 'SWE',
        language: ca.language ?? 'swe',
        email: ca.email,
        gender: ca.gender,
        phone1: ca.phone1,
        phone2: ca.phone2,
        locationArea: ca.locationArea,
      };
      return formData;
    }
  }, [
    auth?.customerProfile?.account,
    defaultName?.firstName,
    defaultName?.lastName,
  ]);
};
