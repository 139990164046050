import { FallbackRender } from '@sentry/react/dist/errorboundary';
import React from 'react';
export const AppErrorFallback: FallbackRender = ({
  error,
  componentStack,
  eventId,
  resetError,
}) => {
  // NOTE: pas possible d'utiliser des hooks ici ni d'accéder à Capacitor

  return (
    <div className="mt-4 max-w-screen-md mx-auto">
      <div className="mx-4 mb-2 p-4 border border-blue-400">
        <div className="text-lg font-bold text-center my-2">{`Une erreur innatendue s'est produite.`}</div>
        <div className="mt-10">
          <p className="my-4">Veuillez vérifier votre connexion Internet.</p>
          <p className="my-4 text-sm">
            Si le problème persiste, rafraichissez la page ou mettez à jour
            votre application via le Store de votre mobile.
          </p>
        </div>
        <div className="text-center p-4 flex justify-around">
          <button
            className="ring-2 py-1 px-4 hover:outline-none text-blue-500 ring-blue-300 cursor-pointer"
            onClick={() => {
              window.location.reload();
            }}
          >
            Rafraîchir la page
          </button>
          <a
            className="ring-2 py-1 px-4 hover:outline-none text-blue-500 ring-blue-300 cursor-pointer"
            href="/logout"
          >
            Se déconnecter
          </a>
        </div>
      </div>
      <div className="mx-4 mb-2 p-4 border border-blue-400">
        <div className="text-lg font-bold text-center my-2">
          An unexpected error has occurred.
        </div>
        <div className="mt-10">
          <p className="my-4">Please check your internet connection.</p>
          <p className="my-4 text-sm">
            If the problem persists, refresh the page or update your application
            via your mobile store.
          </p>
        </div>
        <div className="text-center p-4 flex justify-around">
          <button
            className="ring-2 py-1 px-4 hover:outline-none text-blue-500 ring-blue-300 cursor-pointer"
            onClick={() => {
              window.location.reload();
            }}
          >
            Reload page
          </button>
          <a
            className="ring-2 py-1 px-4 hover:outline-none text-blue-500 ring-blue-300 cursor-pointer"
            href="/logout"
          >
            Logout
          </a>
        </div>
      </div>
      {!!error?.message?.toString && (
        <div className="mt-20 p-4 text-gray-200">
          {error?.message.toString()}
        </div>
      )}
    </div>
  );
};
