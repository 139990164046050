import { AppIcons } from '_01_CORE/_components-core';
import clsx from 'clsx';
import { CompanyIssueReport, ISSUE_CATEGORIES } from 'lib-common-model';
import { useMemo } from 'react';

export function CompanyIssueCard({
  issue,
  className,
}: {
  issue: CompanyIssueReport;
  className?: string;
}) {
  const category1 = useMemo(
    () => ISSUE_CATEGORIES.find((x) => x.id === issue.category1),
    [issue.category1]
  );
  const category2 = useMemo(
    () => (category1?.children ?? []).find((x) => x.id === issue.category2),
    [category1?.children, issue.category2]
  );

  return (
    <div
      className={clsx(
        className,
        'rounded bg-white p-2 sm:p-4 shadow sm:px-6 sm:pt-6 flex gap-4'
      )}
    >
      <div className="w-8 flex flex-col gap-2 text-gray-400">
        {category1?.icon && <category1.icon className="h-6 W-6" />}
        {category2?.icon && !category2?.isOther && (
          <category2.icon className="h-6 W-6" />
        )}
      </div>
      <div className="flex-grow">
        <div className="text-sm text-left font-bold whitespace-nowrap truncate uppercase">
          {category1?.label && category1?.label}
          {category2?.label && <span> / {category2?.label}</span>}
        </div>
        <div className="text-sm text-left max-h-24 line-clamp-3 lg:line-clamp-none">
          {issue.description}
        </div>
        <div className="mt-4 mb-2 text-xs font-bold flex gap-x-4 gap-y-2 flex-wrap justify-between text-gray-400">
          {issue.issueLocation && (
            <div className="uppercase">{issue.issueLocation}</div>
          )}
          {issue.customerJourneySiteReference && (
            <div>{issue.customerJourneySiteReference}</div>
          )}
          {issue.contactPhoneNumber && <div>{issue.contactPhoneNumber}</div>}
        </div>
      </div>
      <div className="flex flex-col flex-wrap items-center gap-1 text-xs font-bold whitespace-nowrap text-gray-400">
        <AppIcons.photo className="w-8 h-8" />
        {issue.photos.length} photos
      </div>
    </div>
  );
}
