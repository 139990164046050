import { CMJourneyEditParticipant } from 'lib-common-model';
import { useMemo } from 'react';
import { useAppCacheContext } from '_01_CORE/app-contexts';
import { journeyHelper } from '_01_CORE/_services';
import { JourneyParticipantsListFormData } from '../JourneyParticipantsListFormData.type';

export const useJourneyCreateParticipantsListAnimLiveInitialState = () => {
  const { journeyEditData, auth } = useAppCacheContext();

  const editParticipants: CMJourneyEditParticipant[] = useMemo(() => {
    return journeyHelper.buildInitialParticipants({ journeyEditData, auth });
  }, [auth, journeyEditData]);

  const defaultValues = useMemo<
    Partial<JourneyParticipantsListFormData>
  >(() => {
    const participants = (editParticipants ?? []).map(({ enabled }) => ({
      enabled,
    }));
    const formData: JourneyParticipantsListFormData = {
      participants,
    };
    return formData;
  }, [editParticipants]);

  return {
    defaultValues,
    editParticipants,
    remainingExternalParticipantsToCreate:
      journeyEditData.remainingExternalParticipantsToCreate ?? [],
  };
};
