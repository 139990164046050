import {
  AppRichTextElement,
  AppRichTextElementType,
  AppRichTextLeaf,
  CompanyOptionsTheme,
} from 'lib-common-model';
import React, { useMemo } from 'react';
import { AppRichTextSlateLeaf } from './AppRichTextSlateLeaf';

export const AppRichTextAreaLeafs = ({
  element,
  theme,
}: {
  element: AppRichTextElement;
  theme: CompanyOptionsTheme;
}) => {
  const leafs: AppRichTextLeaf[] = element.children;
  const isEmptyText = useMemo(() => {
    if (element.type === ('p' as AppRichTextElementType)) {
      if (leafs?.length === 1) {
        if (leafs[0].text?.trim().length === 0) {
          return true;
        }
      }
    }
    return false;
  }, [leafs, element.type]);

  return !leafs ? null : isEmptyText ? (
    <br />
  ) : (
    <>
      {leafs.map((leaf, leafIndex) => {
        return (
          <AppRichTextSlateLeaf
            theme={theme}
            key={leafIndex}
            leaf={leaf}
            text={{
              text: leaf.text,
            }}
          >
            <>{leaf.text}</>
          </AppRichTextSlateLeaf>
        );
      })}
    </>
  );
};
