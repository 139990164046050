import { useAppModalContext } from '_01_CORE/_components-core';
import { useOperationPending } from '_01_CORE/_hooks';
import {
  useAppCacheContext,
  useAppLocalizationContext,
} from '_01_CORE/app-contexts';
import { ActivitySessionParticipantStatusUpdateReturnValue } from 'lib-common-model';
import { ApiClientHttpError } from 'lib-web-api-client';
import { useIntl } from 'react-intl';
import { JourneyActivitiesLocalState } from '../model';

export type JourneyActivitiesConfirmWaitingRegistrationsParams = {
  confirmation: boolean;
  activityId: string;
  activitySessionId: string;
  journeyParticipantsIds: string[];
  customerJourneyId: string;
  customerAccountId: string;
  date: Date;
  companyReference: string;
};

export function useJourneyActivitiesPageOnConfirmWaitingRegistrations(
  localState: JourneyActivitiesLocalState
) {
  const { formatMessage: t } = useIntl();
  const { openModal, closeModal } = useAppModalContext();
  const { auth, dispatchCacheContextAction } = useAppCacheContext();

  const {
    locales: { platformLanguageCode, appLanguageCode },
  } = useAppLocalizationContext();

  const {
    networkStatus,
    pushError,
    journeyActivitiesRepo,
    openWarningModalFromCause,
    updateActivitySessionsToDisplay,
  } = localState;

  return useOperationPending(
    async ({
      confirmation,
      activitySessionId,
      journeyParticipantsIds,
      customerJourneyId,
      companyReference,
      customerAccountId,
      date,
      activityId,
    }: JourneyActivitiesConfirmWaitingRegistrationsParams): Promise<ActivitySessionParticipantStatusUpdateReturnValue> => {
      try {
        const {
          result,
          activitySessionsToDisplay,
        } = await journeyActivitiesRepo.actions.confirmWaitingRegistrations({
          confirmation,
          activitySessionId,
          journeyParticipantsIds,
          customerJourneyId,
          date,
          platformLanguageCode,
          appLanguageCode,
          customerAccountId,
          activityId,
        });

        updateActivitySessionsToDisplay({
          activitySessionsToDisplay,
          companyReference,
        });

        return result;
      } catch (err: unknown) {
        const httpStatus = (err as ApiClientHttpError)?.response?.status;

        if (httpStatus === 423) {
          const cause = (err as ApiClientHttpError).json?.message;
          openWarningModalFromCause(cause);
        } else {
          if (!networkStatus?.connected) {
            pushError(t({ id: 'error.network.down' }));
          } else {
            pushError(t({ id: 'error.unexpected' }));
          }
        }
      }
    },
    [
      journeyActivitiesRepo.actions,
      platformLanguageCode,
      appLanguageCode,
      updateActivitySessionsToDisplay,
      openWarningModalFromCause,
      networkStatus?.connected,
      pushError,
      t,
    ]
  );
}
