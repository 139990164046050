import { Dialog } from '@headlessui/react';
import { useAppTheme } from '_01_CORE/_common-layout';
import {
  AppButtonV2,
  AppIcons,
  dateTransformer,
} from '_01_CORE/_components-core';
import { AppDialogV2 } from '_01_CORE/_components-core/dialog/AppDialogV2';
import { useAppRouter, useAppToasts } from '_01_CORE/_hooks';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import { useNetworkStatus } from '_01_CORE/app-core/app-platform';
import { ActivityTranslationPreview } from '_02_APP_COMMON/ACT-activity/components';
import { CompanyOptions } from 'lib-common-model';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  ActivityDialogParticipantsGroupsToDisplay,
  CustomerActivityParticipantsDailyActivity,
  CustomerActivityUpdateParticipantStateFn,
  JourneyDailyActivityCard,
  JourneyDailyActivityCardViewStep,
  useActivityDialogParticipantsGroupsToDisplay,
} from '../JourneyDailyActivity';
import {
  ActivitySessionDetailsDialogRegistrationAcceptedPendingArea,
  ActivitySessionDetailsDialogRegistrationFreeArea,
  ActivitySessionDetailsDialogRegistrationPaidArea,
  ActivitySessionDetailsDialogWaitingListArea,
} from '../JourneyDailyActivity/components';

export const ActivitySessionDetailsDialog = ({
  companyOptions,
  isOpen,
  onClose,
  dailyActivity,
  onSelectSessionId,
  onClickParticipant,
  onConfirmWaitingRegistrations,
  companyReference,
  customerJourneyId,
  customerAccountId,
}: {
  companyOptions?: CompanyOptions;
  onClickParticipant: CustomerActivityUpdateParticipantStateFn;
  onConfirmWaitingRegistrations: ({
    confirmation,
    activitySessionId,
    journeyParticipantsIds,
    customerJourneyId,
    date,
  }: {
    confirmation: boolean;
    activitySessionId: string;
    journeyParticipantsIds: string[];
    customerJourneyId: string;
    date: Date;
  }) => Promise<any>;
  isOpen: boolean;
  onClose: () => void;
  dailyActivity: CustomerActivityParticipantsDailyActivity;
  onSelectSessionId: (sessionId: string) => void;
  companyReference: string;
  customerJourneyId: string;
  customerAccountId: string;
}) => {
  const appRouter = useAppRouter();
  const { formatMessage: t } = useIntl();
  const { networkStatus } = useNetworkStatus();
  const { pushError } = useAppToasts();

  const activity = dailyActivity?.activity;
  const session = dailyActivity?.selectedSession;

  const activityTranslation = activity.translation;

  const theme = useAppTheme({ companyReference });

  const appId = appWebConfig().appId;

  const isRegistrationOpen =
    dailyActivity.selectedSession?.registrationStatus === 'open';

  const hasUnpayedParticipant = useMemo(
    () =>
      dailyActivity.selectedSession.activitySession.registrationFeeEnabled &&
      dailyActivity.selectedSession.participants.find(
        (x) => x.activitySessionParticipant?.paymentStatus === 'payment-later'
      ) !== undefined,
    [
      dailyActivity.selectedSession.activitySession.registrationFeeEnabled,
      dailyActivity.selectedSession.participants,
    ]
  );

  const groupsToDisplay: ActivityDialogParticipantsGroupsToDisplay = useActivityDialogParticipantsGroupsToDisplay(
    {
      companyOptions,
      dailyActivity,
    }
  );

  const activitySession = dailyActivity.selectedSession.activitySession;

  const [viewStep, setViewStep] = useState<JourneyDailyActivityCardViewStep>(
    'default'
  );

  return (
    isOpen && (
      <AppDialogV2
        className="w-full sm:max-w-screen-sm"
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
      >
        {activity && session && (
          <>
            <Dialog.Title
              as="h3"
              className="py-1 text-primary text-center font-bold text-xl mm:text-2xl"
            >
              {dateTransformer
                .formatUTC(activitySession.beginDateTime, 'eeee dd/MM/yyyy')
                .toUpperCase()}
            </Dialog.Title>
            <div className="w-full h-full overflow-auto border-t border-b border-gray-100 ">
              <div className="w-full px-2 sm:px-6">
                <JourneyDailyActivityCard
                  key={`${dailyActivity.selectedSession.activitySession.activitySessionId}-${dailyActivity.displayMode}`}
                  className="mt-4"
                  companyReference={companyReference}
                  context="details"
                  dailyActivity={dailyActivity}
                  onClickParticipant={onClickParticipant}
                  onSelectSessionId={(sessionId) => {
                    onSelectSessionId(sessionId);
                    setViewStep('default');
                  }}
                  onToogleTimeOptionsFullList={() => {
                    setViewStep(
                      viewStep !== 'default'
                        ? 'default'
                        : 'choose-time-sessions'
                    );
                  }}
                  viewStep={viewStep}
                />
              </div>
              {viewStep === 'default' && (
                <>
                  {groupsToDisplay.participantsRegistrationAcceptedPending
                    .length > 0 && (
                    <ActivitySessionDetailsDialogRegistrationAcceptedPendingArea
                      companyReference={companyReference}
                      customerJourneyId={customerJourneyId}
                      dailyActivity={dailyActivity}
                      participants={
                        groupsToDisplay.participantsRegistrationAcceptedPending
                      }
                      onConfirmWaitingRegistrations={
                        onConfirmWaitingRegistrations
                      }
                      customerAccountId={customerAccountId}
                    />
                  )}
                  <>
                    {groupsToDisplay.participantsRegistration.length > 0 &&
                      groupsToDisplay.isPaid && (
                        <ActivitySessionDetailsDialogRegistrationPaidArea
                          companyReference={companyReference}
                          customerJourneyId={customerJourneyId}
                          dailyActivity={dailyActivity}
                          participants={
                            groupsToDisplay.participantsRegistration
                          }
                          onClickParticipant={onClickParticipant}
                          isOpen={groupsToDisplay.isOpen}
                          onClose={onClose}
                        />
                      )}
                    {groupsToDisplay.participantsRegistration.length > 0 &&
                      groupsToDisplay.isPaid === false && (
                        <ActivitySessionDetailsDialogRegistrationFreeArea
                          companyReference={companyReference}
                          dailyActivity={dailyActivity}
                          participants={
                            groupsToDisplay.participantsRegistration
                          }
                          onClickParticipant={onClickParticipant}
                          isOpen={groupsToDisplay.isOpen}
                        />
                      )}
                    {groupsToDisplay.participantsRegistrationWaiting.length >
                      0 && (
                      <ActivitySessionDetailsDialogWaitingListArea
                        companyReference={companyReference}
                        dailyActivity={dailyActivity}
                        participants={
                          groupsToDisplay.participantsRegistrationWaiting
                        }
                        onClickParticipant={onClickParticipant}
                      />
                    )}
                  </>

                  {activityTranslation && (
                    <div className="my-2 mx-2 sm:mx-6 rounded-lg border-2 border-gray-500 p-2 px-4">
                      <ActivityTranslationPreview
                        companyReference={companyReference}
                        translation={activityTranslation}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </>
        )}

        {!hasUnpayedParticipant &&
          groupsToDisplay.participantsRegistrationAcceptedPending.length ===
            0 && (
            <div className="text-center py-2 px-2">
              <AppButtonV2
                className={`w-full max-w-xs`}
                buttonStyle="primary"
                onClick={() => onClose()}
                icon={AppIcons.actionClose}
                centerText={true}
              >
                Fermer
              </AppButtonV2>
            </div>
          )}
      </AppDialogV2>
    )
  );
};
