import { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import React from 'react';

export const GenderBoy = (props: IonicReactProps) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 856 1280"
      preserveAspectRatio="xMidYMid meet"
      {...props}
      x="0px"
      y="0px"
    >
      <g
        transform="translate(0,1280) scale(0.1,-0.1)"
        fill="currentColor"
        stroke="none"
      >
        <path
          d="M3649.7,11908.1c-41.4-22.4-111.3-97.5-159.7-171.8c-21.6-33.7-89.8-150.2-151.9-258.1
		c-196.8-342.7-336.6-538.6-462.7-645.6c-34.5-29.3-118.3-92.4-186.4-139c-177.8-122.6-216.7-152.8-310.7-242.5
		c-103.6-100.1-146.7-122.6-259.8-132.1c-161.4-13.8-249.5-90.6-424.7-372.9c-77.7-125.2-47.5-108.8-227.9-127.7
		c-177.8-19.9-346.1-61.3-479.9-118.3c-72.5-31.9-76.8-34.5-76-59.6c0.9-13.8,21.6-68.2,45.7-120c24.2-51.8,46.6-107.9,49.2-126
		c5.2-31.1,5.2-31.9-47.5-59.6c-67.3-36.3-140.7-113.9-159.7-169.2c-29.3-86.3-13.8-146.7,63.9-241.7
		c57.8-71.6,60.4-88.9,24.2-127.7c-34.5-36.3-113.1-190.8-158-311.6c-39.7-105.3-85.5-285.7-107.9-418.6
		c-17.3-106.2-38.8-309.9-32.8-315.1c3.5-3.5,123.4-38,193.3-56.1l35.4-8.6l5.2-161.4c3.5-89.8,12.1-218.4,19-287.4
		c7.8-69.1,16.4-213.2,19-321.1l5.2-195.1l-38.8-59.6c-195.9-295.2-222.7-665.5-76.8-1030.6c129.5-321.1,382.4-564.5,681-655.1
		c35.4-10.4,64.7-24.2,66.5-29.3c111.3-414.3,386.7-769.1,815.7-1048.7c154.5-101,378.9-212.3,559.3-277.1
		c29.3-11.2,53.5-21.6,53.5-25c0-2.6-18.1-16.4-41.4-31.1c-77.7-49.2-204.6-158-303.8-259.8c-300.4-309.9-523.9-732-666.4-1254.2
		c-24.2-88.9-53.5-195.1-64.7-238.2c-31.9-113.9-36.3-139.8-36.3-215.8c-0.9-110.5,23.3-184.7,80.3-252
		c61.3-71.6,57.8-69.9,687.9-174.4c1007.3-167.5,1236.9-195.1,2053.5-250.3c498-33.7,648.2-38.8,1061.7-39.7
		c481.6,0,675,10.4,902,49.2c192.5,31.9,279.7,57,379.8,105.3c145,71.6,205.4,147.6,233.1,295.2c23.3,120,18.1,195.9-35.4,495.5
		c-39.7,225.3-50.1,267.6-98.4,414.3c-76,233.1-183.9,454-316.8,654.3c-185.6,280.5-442.8,537.7-712.1,713
		c-42.3,27.6-76,50.9-76,52.7c0,2.6,24.2,16.4,54.4,31.1c177.8,89.8,388.4,262.4,539.5,443.7c176.9,211.5,323.7,493.7,390.1,752.7
		c8.6,35.4,10.4,36.3,58.7,46.6c455.7,94.1,805.3,522.2,858,1050.5c21.6,219.2-29.3,473.9-130.3,644.8
		c-63.9,109.6-83.7,190.8-61.3,251.2c10.4,25.9,24.2,126,77.7,564.5c63,516.2,106.2,753.5,187.3,1044.4
		c34.5,124.3,33.7,154.5-4.3,237.4c-29.3,62.1-87.2,126-185.6,207.2c-58.7,47.5-77.7,68.2-88.9,99.3
		c-82.9,220.1-249.5,397.9-550.7,587.8c-63.9,39.7-113.9,81.1-194.2,162.3c-59.6,59.6-161.4,159.7-226.1,221l-117.4,112.2
		l-10.4,64.7c-25,145-107.9,343.5-198.5,472.1c-226.1,319.4-616.3,565.4-1019.4,642.2c-54.4,10.4-155.4,33.7-224.4,51.8
		c-69.1,19-144.1,33.7-166.6,33.7c-58.7,0-107-22.4-136.4-63c-28.5-38.8-31.1-64.7-12.1-119.1c7.8-20.7,12.1-41.4,9.5-47.5
		c-5.2-12.9-181.3-60.4-266.7-71.6c-37.1-5.2-190.8-12.1-340.9-15.5l-274.5-5.2l-19.9,154.5c-34.5,272.8-60.4,446.3-78.5,517
		C3797.3,11896.8,3736.9,11953.8,3649.7,11908.1z"
        />
      </g>
    </svg>
  );
};
