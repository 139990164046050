import { useMemo } from 'react';
import { appWebConfig } from '_01_CORE/app-core/app-config';

export function useAppFeatures(): {
  isFeatureAuthEnabled: boolean;
  isFeatureWelcomeBookletEnabled: boolean;
} {
  return useMemo(
    () => ({
      isFeatureAuthEnabled: appWebConfig().features.includes('authentication'),
      isFeatureWelcomeBookletEnabled: appWebConfig().features.includes(
        'welcome-booklet'
      ),
    }),
    []
  );
}
