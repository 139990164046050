import { Gender } from 'lib-common-model';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

export const useCustomerGenderText = ({
  isMinor,
  gender,
}: {
  isMinor: boolean;
  gender: Gender;
}) => {
  const { formatMessage: t } = useIntl();

  const genderText = useMemo(() => {
    if (gender === 'male') {
      return isMinor
        ? t({ id: 'page12.gender.boy' })
        : t({ id: 'page12.gender.male' });
    } else if (gender === 'female') {
      return isMinor
        ? t({ id: 'page12.gender.girl' })
        : t({ id: 'page12.gender.female' });
    } else if (gender === 'other') {
      return t({ id: 'page12.gender.other' });
    } else {
      return t({ id: 'page12.gender.prefer-not-to-say' });
    }
  }, [gender, isMinor, t]);

  return genderText;
};
