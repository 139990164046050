import { useMemo } from 'react';
import {
  CustomerActivityParticipantsDailyActivitParticipantState,
  CustomerActivityParticipantsDailyActivity,
} from '../model';

export type ParticipantsByStatus = {
  participantsRegistered: CustomerActivityParticipantsDailyActivitParticipantState[];
  participantsNotRegistered: CustomerActivityParticipantsDailyActivitParticipantState[];
  participantsWaiting: CustomerActivityParticipantsDailyActivitParticipantState[];
  participantsAcceptedPending: CustomerActivityParticipantsDailyActivitParticipantState[];
  participantsAll: CustomerActivityParticipantsDailyActivitParticipantState[];
};

export function useGroupParticipantsByStatus({
  dailyActivity,
}: {
  dailyActivity: CustomerActivityParticipantsDailyActivity;
}): ParticipantsByStatus {
  return useMemo(() => {
    return dailyActivity.selectedSession.participants.reduce(
      (acc, x) => {
        if (
          x.activitySessionParticipant?.waitingRegistration?.status ===
          'waiting'
        ) {
          acc.participantsWaiting.push(x);
        } else if (
          x.activitySessionParticipant?.waitingRegistration?.status ===
          'accepted-pending'
        ) {
          acc.participantsAcceptedPending.push(x);
        } else if (x.isNotRegistered) {
          acc.participantsNotRegistered.push(x);
        } else {
          acc.participantsRegistered.push(x);
        }
        acc.participantsAll.push(x);
        return acc;
      },
      {
        participantsRegistered: [],
        participantsNotRegistered: [],
        participantsWaiting: [],
        participantsAcceptedPending: [],
        participantsAll: [],
      } as ParticipantsByStatus
    );
  }, [dailyActivity.selectedSession.participants]);
}
