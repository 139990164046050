import { AppCacheContextModel } from '_01_CORE/app-contexts';
import {
  AuthMultiLoginAnimLivePageViewState,
  AuthMultiLoginAnimLivePageViewStateExtra,
} from '_04_ANIM_LIVE/AUT-auth/02-login/_model';

export const createJourneyActivationCodePageInitialStateBuilder = {
  buildInitialViewState,
};

function buildInitialViewState(
  appCacheContext: AppCacheContextModel,
  extra: AuthMultiLoginAnimLivePageViewStateExtra
): AuthMultiLoginAnimLivePageViewState {
  const initialViewState: AuthMultiLoginAnimLivePageViewState = {
    contextAction: 'authenticated-activate',
    contextOrigin: 'login-home',
    mode: 'prompt-email',
    extra,
  };
  const routeParams = new URLSearchParams(window.location.search);

  const activationKey = routeParams.get('activationKey');
  initialViewState.mode = 'check-key';
  initialViewState.checkKey = {
    state: 'prompt',
    key: activationKey,
  };

  return initialViewState;
}
