import { dataSorter } from '_01_CORE/_components-core';
import { AppAuth } from '_01_CORE/app-core/app-security';
import { CustomerAccountEditFormData } from '_02_APP_COMMON/_model';
import { differenceInYears } from 'date-fns';
import {
  CMCustomerAccount,
  CMJourneyEditCustomerAccount,
  CMJourneyEditData,
  CMJourneyEditParticipant,
  CMJourneyParticipant,
  CMProfileMember,
} from 'lib-common-model';

export const journeyHelper = {
  getParticipantJourneyAge,
  hasJourneyAnyAttendanceOrPreRegistration,
  sortEditParticipants,
  sortParticipants,
  buildCustomerAccount,
  buildEditParticipantFromProfileMember,
  buildInitialParticipants,
};

function buildInitialParticipants({
  journeyEditData,
  auth,
}: {
  journeyEditData: CMJourneyEditData;
  auth: AppAuth;
}) {
  // TODO mutualiser avec useJourneyParticipantsListAccroparkInitialState ???

  const participants: CMJourneyEditParticipant[] = (
    [...journeyEditData?.participants] ?? []
  ).concat([]); // participants array copy (or empty array)
  if (journeyEditData?.journey) {
    const participantsCustomerChildIds = participants.map(
      (p) => p.customerChildId
    );

    const enableMembers = participants.length === 0;
    if (auth?.customerProfile?.members) {
      // automatically add missing members
      auth.customerProfile.members.forEach((member) => {
        if (!participantsCustomerChildIds.includes(member.customerChildId)) {
          const participant: CMJourneyEditParticipant = journeyHelper.buildEditParticipantFromProfileMember(
            {
              enabled: enableMembers,
              member,
              journeyEditData,
            }
          );
          participants.push(participant);
        }
      });
    }
  }

  // sort participants
  return journeyHelper.sortEditParticipants(participants);
}
function buildEditParticipantFromProfileMember({
  enabled,
  member,
  journeyEditData,
}: {
  enabled: boolean;
  member: CMProfileMember;
  journeyEditData: CMJourneyEditData;
}): CMJourneyEditParticipant {
  return {
    enabled,
    customerChildId: member.customerChildId,
    member,
    attributes: {
      journeyAge: getParticipantJourneyAge({
        journeyEndDateInclusive: journeyEditData?.journey.endDateInclusiveUTC,
        memberBirthDate: member.birthDate,
      }),
    },
  };
}

function getParticipantJourneyAge({
  journeyEndDateInclusive,
  memberBirthDate,
}: {
  journeyEndDateInclusive: Date | string;
  memberBirthDate: Date | string;
}) {
  if (!journeyEndDateInclusive || !memberBirthDate) {
    return undefined;
  }
  return differenceInYears(
    new Date(journeyEndDateInclusive),
    new Date(memberBirthDate)
  );
}

function hasJourneyAnyAttendanceOrPreRegistration(journey: {
  participants: {
    attributes: { totalAttendances: number; totalPreRegistrations: number };
  }[];
}) {
  if (!journey) {
    return false;
  }
  const hasAnyAttendanceOrPreRegistration =
    journey.participants.find(
      (p) =>
        p.attributes.totalAttendances + p.attributes.totalPreRegistrations !== 0
    ) !== undefined;

  return hasAnyAttendanceOrPreRegistration;
}

function sortEditParticipants(
  participants: CMJourneyEditParticipant[]
): CMJourneyEditParticipant[] {
  return dataSorter.sortMultiple(participants, {
    getSortAttributes: (p) => [
      {
        value: p.enabled,
        asc: false,
      },
      {
        value: p.member.birthDate,
      },
      {
        value: p.member.firstName,
      },
      {
        value: p.member.lastName,
      },
    ],
  });
}

function sortParticipants(
  participants: CMJourneyParticipant[]
): CMJourneyParticipant[] {
  return dataSorter.sortMultiple(participants, {
    getSortAttributes: (p) => [
      {
        value: p.member.birthDate,
      },
      {
        value: p.member.firstName,
      },
      {
        value: p.member.lastName,
      },
      {
        value: p.journeyParticipantId,
      },
    ],
  });
}

function buildCustomerAccount({
  existingCustomerAccount,
  data,
}: {
  existingCustomerAccount: Partial<CMCustomerAccount>;
  data: CustomerAccountEditFormData;
}): CMJourneyEditCustomerAccount {
  return {
    ...(existingCustomerAccount ?? {}),
    gender: data.gender,
    language: data.language,
    firstName: data.firstName,
    lastName: data.lastName,
    phone1: data.phone1,
    phone2: data.phone2,
    email: data.email,
    country: data.country,
    consent: data.consent,
    password: data.password,
    electronicSignature: data.electronicSignature,
    locationArea: data.locationArea,
    settings: {
      notifications: {
        language: data.notificationLanguage,
        mobile: {
          enabled: !!data.notificationLanguage,
        },
      },
    },
  };
}
