import { appBootstrap } from '_01_CORE/app-core/app-bootstrap/appBootstrap.service';
import { appWebConfig, buildRemoteEnvId } from '_01_CORE/app-core/app-config';
import { appStore } from '_01_CORE/app-core/app-store';
import { HACK_SPECIAL_ACCOUNTS } from '_02_APP_COMMON/AUT-auth';
import { AppRemoteEnvId } from 'lib-web-common';

const DEV_WEB_TEST_TOKEN = ''; // 'test-token';

export const envAccountSwitcher = {
  switchEnvIfSpecialAccount,
};

function switchEnvIfSpecialAccount({ emailAddress }: { emailAddress: string }) {
  const hackAccount = HACK_SPECIAL_ACCOUNTS.find(
    (x) => x.login === emailAddress
  );

  if (hackAccount) {
    const appId = appWebConfig().appId;
    const envId = appWebConfig().envId;
    const remoteEnvId = appWebConfig().remoteEnvId;

    const newRemoteEnvId: AppRemoteEnvId =
      hackAccount.remote === 'local-ip'
        ? 'local-ip'
        : hackAccount.remote === 'auto'
        ? appId === 'accropark'
          ? 'accro-beta'
          : 'beta'
        : hackAccount.remote === 'prod'
        ? appId === 'accropark'
          ? 'accro-prod'
          : 'prod'
        : undefined;
    console.log('[LoginPage] newRemoteEnvId', newRemoteEnvId);
    if (newRemoteEnvId && remoteEnvId !== newRemoteEnvId) {
      console.info(`Switch to remote env ${newRemoteEnvId}`);
      appBootstrap.switchEnv({
        remoteEnvId: newRemoteEnvId,
      });
    }
  } else {
    const appId = appWebConfig().appId;
    const envId = appWebConfig().envId;
    const remoteEnvId = appWebConfig().remoteEnvId;
    const defaultRemoteEnvId = buildRemoteEnvId({
      appId,
      envId,
    });
    if (remoteEnvId !== defaultRemoteEnvId) {
      console.info(`Switch back to defaul tremote env ${defaultRemoteEnvId}`);
      appBootstrap.switchEnv({
        remoteEnvId: defaultRemoteEnvId,
      });
    }
  }
  if (DEV_WEB_TEST_TOKEN?.length && appWebConfig().envId === 'dev') {
    console.log('[DEV] Set fake deviceToken:', DEV_WEB_TEST_TOKEN);
    appStore.deviceToken.set(DEV_WEB_TEST_TOKEN, 'dev web test token');
  }
}
