import { IonButton, IonIcon, IonInput, IonRouterLink } from '@ionic/react';
import { AppPage, HeaderToolbar, PageContainer } from '_01_CORE/_common-layout';
import { FormError } from '_01_CORE/_components-core';
import { AppInputIonic } from '_01_CORE/_components-core/AppInput';
import { useAppRouter, useAppToasts } from '_01_CORE/_hooks';
import { authApiClient } from '_01_CORE/app-core/app-api';
import { useNetworkStatus } from '_01_CORE/app-core/app-platform';
import { chevronBackOutline, mailOutline } from 'ionicons/icons';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

export type ResetPasswordFormData = {
  email: string;
};

export const ResetPasswordQueryPage = () => {
  const appRouter = useAppRouter();
  const { networkStatus } = useNetworkStatus();
  const { formatMessage: t } = useIntl();

  const routeParams = new URLSearchParams(window.location.search);
  const defaultEmailValue = routeParams.get('login') ?? '';

  const { pushError } = useAppToasts();
  const {
    handleSubmit,
    formState,
    control,
    reset,
  } = useForm<ResetPasswordFormData>({
    mode: 'onChange',
  });

  const [emailResetSent, setEmailResetSent] = useState<string>();

  const goBack = () => {
    appRouter.goBack();
  };

  const onSubmit = async (data: ResetPasswordFormData) => {
    authApiClient
      .resetPasswordQuery({ login: data.email })
      .then(() => {
        setEmailResetSent(data.email);
        reset();
      })
      .catch(() => {
        if (!networkStatus?.connected) {
          pushError(t({ id: 'error.network.down' }));
        } else {
          pushError(t({ id: 'error.unexpected' }));
        }
      });
  };

  const onInvalid = () => {
    pushError(t({ id: 'common.message.error.invalid-form' }));
  };

  return (
    <AppPage displayFooter={false}>
      <HeaderToolbar />
      <PageContainer
        className={`h-full bg-white text-center flex flex-col gap-4`}
      >
        <div className="flex mb-2 place-items-center">
          <IonRouterLink className="cursor-pointer" onClick={() => goBack()}>
            <IonIcon className="text-primary" icon={chevronBackOutline} />
          </IonRouterLink>
          <div className="ml-8 text-primary text-left flex-grow text-xl place-self-start ml:text-2xl sm:text-3xl">
            {t({ id: 'page20.title' })}
          </div>
        </div>
        <div className="w-full bg-gray-50 rounded-xl border border-gray-200 pt-4 pb-8 px-8">
          {!emailResetSent ? (
            <form
              className="max-w-xl mt-8 m-auto"
              onSubmit={handleSubmit(onSubmit, onInvalid)}
            >
              {t({ id: 'page20.email.enter' })}

              <div className="my-2">
                <Controller
                  render={({ field: { value, onChange } }) => (
                    <AppInputIonic
                      required={true}
                      value={value}
                      placeholder={t({ id: 'form.label.email' })}
                      icon={mailOutline}
                    >
                      <IonInput type="email" onIonChange={onChange} />
                    </AppInputIonic>
                  )}
                  control={control}
                  name={`email`}
                  defaultValue={defaultEmailValue}
                  rules={{
                    required: {
                      value: true,
                      message: t({ id: 'validation.required' }),
                    },
                  }}
                />
                <FormError error={formState.errors.email?.message} />
              </div>

              <IonButton className="my-4" type="submit" shape="round">
                {t({ id: 'page20.submit' })}
              </IonButton>
            </form>
          ) : (
            <div className="mt-8 max-w-xl m-auto">
              <div className="text-xl ml:text-2xl sm:text-3xl">
                {t({ id: 'page20.success.title' })}
              </div>
              <div className="text-left my-2">
                {t({ id: 'page20.success.content' })}
              </div>
              <IonButton className="my-8" shape="round" onClick={goBack}>
                {t({ id: 'page20.back' })}
              </IonButton>
              <div className="text-left italic text-sm mt-4 font-light">
                <div>{t({ id: 'page20.nomail' })}</div>
                <ul className="mt-1 list-inside">
                  <li>- {t({ id: 'page20.nomail.step1' })}</li>
                  <li>
                    -{' '}
                    {t(
                      { id: 'page20.nomail.step2' },
                      { email: emailResetSent }
                    )}
                  </li>
                  <li>- {t({ id: 'page20.nomail.step3' })}</li>
                </ul>
              </div>
            </div>
          )}
        </div>
      </PageContainer>
    </AppPage>
  );
};
