import {
  AppPage,
  HeaderToolbar,
  PageContainer,
  PageTitle,
} from '_01_CORE/_common-layout';
import { AppButtonUniverse, AppIcons } from '_01_CORE/_components-core';
import { useAppRouter, useAppToasts } from '_01_CORE/_hooks';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import { appBrowser, useAppPlatform } from '_01_CORE/app-core/app-platform';
import { useIntl } from 'react-intl';

const appId = appWebConfig().appId;

export const AnimLiveProHomePage = () => {
  const appRouter = useAppRouter();
  const { formatMessage: t } = useIntl();

  const { platform } = useAppPlatform();

  const { pushError } = useAppToasts();

  return (
    <AppPage>
      <HeaderToolbar />
      <PageContainer className="text-center bg-gray-50" spacing="normal">
        <PageTitle
          title={'Espace PRO'}
          // TODO TEMPORAIRE
          // backLink={appRoutesBuilder.getGroupHomeUrl({
          //   groupReference: journey.company.companyGroupReference,
          //   isAuthenticated: false,
          // })}
        />
        {/* <CompanyImage image={journey.company?.image1} /> */}
        <div className="app-p-content text-left">
          <p className="my-2">Vous êtes connecté en tant que PRO.</p>
          <p className="my-2">
            {`Désormais, une notification PUSH vous alertera sur votre mobile à chaque fois qu'un nouveau ticket est créé.`}
          </p>
          <div className="mt-20 text-center">
            <AppButtonUniverse
              className="w-full"
              style={'primary'}
              isFullWidth={true}
              label={`Ouvrir l'espace PRO`}
              icon={AppIcons.actionLogin}
              onClick={async () => {
                try {
                  appBrowser.openProHomePage({ platform });
                } catch (err) {
                  console.error(err);
                  pushError(t({ id: 'error.unexpected' }));
                } finally {
                  //
                }
              }}
            />
          </div>
        </div>
      </PageContainer>
    </AppPage>
  );
};
