import {
  AppPage,
  HeaderToolbar,
  PageContainer,
  useAppTheme,
} from '_01_CORE/_common-layout';
import { AppIcons, AppLoader } from '_01_CORE/_components-core';
import { AppButtonCompany } from '_01_CORE/_components-core/AppButton/AppButtonCompany';
import { useAppRouter } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import {
  useAppCacheContext,
  useJourneyActivityCategory,
} from '_01_CORE/app-contexts';
import {
  useJourneyActivitiesLocalState,
  useJourneyActivitiesPageOnClickParticipant,
} from '_02_APP_COMMON/ACT-activity/ACT-16-journey-activities';
import { ActivitySessionDetailsDialog } from '_02_APP_COMMON/ACT-activity/ACT-16-journey-activities/ActivitySessionDetailsDialog';
import {
  JourneyActivitiesLocalState,
  JourneyDailyActivityCard,
  JourneyDailyNoActivityParticipantsCard,
  useJourneyActivitiesPageOnConfirmWaitingRegistrations,
} from '_02_APP_COMMON/ACT-activity/ACT-16-journey-activities/JourneyDailyActivity';
import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { AnimLiveJourneyActivitiesLegend } from './AnimLiveJourneyActivitiesLegend';
import { AnimLiveJourneysActivitiesPageCriteriaBar } from './AnimLiveJourneysActivitiesPageCriteriaBar';
import { AnimLiveJourneysActivitiesPageCriteriaDateNavigator } from './AnimLiveJourneysActivitiesPageCriteriaBar/AnimLiveJourneysActivitiesPageCriteriaDateNavigator';

export const AnimLiveJourneysActivitiesPage = () => {
  const appRouter = useAppRouter();
  const { formatMessage: t } = useIntl();

  const appCacheContext = useAppCacheContext();
  const customerAccountId =
    appCacheContext?.auth?.customerProfile?.account?._id;

  const localState: JourneyActivitiesLocalState = useJourneyActivitiesLocalState();

  const {
    journey,
    journeyDateText,
    hasAnyFilter,
    localFilters,
    appId,
    setLastSelectedSessionId,
    aggregatedData,
    activitySessionDetailsDialogDailyActivity,
    setCategoryAndScroll,
    setActivitySessionDetailsDialog,
    isJourneyReplacementInProgress,
    category,
    journeyActivitiesRepo,
    openActivityDetailsDialog,
    closeActivityDetailsDialog,
  } = localState;

  useEffect(() => {
    if (journeyDateText) {
      journeyActivitiesRepo.actions.refreshPageActivities({
        cause: 'day-initial-load',
      }); // initial load
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [journeyDateText]);

  const onSubmit = () => {
    appRouter.navigate(
      appRoutesBuilder.getJourneyOptionsUrl({
        customerJourneyId: journey?._id,
      }),
      { cause: 'journey-activities-submit' }
    );
  };

  const toggleParticipantRegistration = useJourneyActivitiesPageOnClickParticipant(
    localState
  );

  const onConfirmWaitingRegistrations = useJourneyActivitiesPageOnConfirmWaitingRegistrations(
    localState
  );
  const company = journey?.company;
  const theme = useAppTheme({ companyReference: company?.reference });

  const dailyActivitiesToDisplay = useMemo(
    () =>
      (aggregatedData?.dailyActivities ?? []).filter(
        (dailyActivity) => dailyActivity.selectedSession
      ),
    [aggregatedData?.dailyActivities]
  );

  const enableActivityStandalone = useJourneyActivityCategory(
    journey,
    'standalone'
  );

  return !journey ? null : (
    <AppPage displayFooter={true}>
      <HeaderToolbar hideLogout={false} />
      {activitySessionDetailsDialogDailyActivity && (
        <ActivitySessionDetailsDialog
          key={`${activitySessionDetailsDialogDailyActivity.activity?._id}-${activitySessionDetailsDialogDailyActivity?.selectedSession?.activitySession?.activitySessionId}`}
          companyOptions={journey?.company?.options}
          isOpen={!!activitySessionDetailsDialogDailyActivity}
          dailyActivity={activitySessionDetailsDialogDailyActivity}
          onClose={() => closeActivityDetailsDialog()}
          onClickParticipant={({
            dailyActivity,
            participantState,
            skipConfirmDelete,
          }) => {
            return toggleParticipantRegistration({
              dailyActivity,
              participantState,
              skipConfirmDelete,
            });
          }}
          onConfirmWaitingRegistrations={onConfirmWaitingRegistrations}
          onSelectSessionId={setLastSelectedSessionId}
          companyReference={journey?.company?.reference}
          customerJourneyId={journey?._id}
          customerAccountId={customerAccountId}
        />
      )}
      <PageContainer className="text-center bg-gray-50 " spacing="none">
        <AnimLiveJourneysActivitiesPageCriteriaDateNavigator
          className="mx-2 bg-gray-50 sticky z-10 top-0"
          localState={localState}
        />
        <AnimLiveJourneysActivitiesPageCriteriaBar
          className="max-w-full overflow-hidden x-2 mt-2 app-card-no-padding py-2 px-2"
          localState={localState}
        />
        <div className="mt-2 px-2">
          {aggregatedData?.dailyActivities?.length === 0 &&
          journeyActivitiesRepo.state.isLoaded &&
          !journeyActivitiesRepo.state.isRefreshInProgress ? (
            <div className="text-left font-bold mt-10">
              {localFilters.registeredOrPresentOnly
                ? localFilters.selectedChildId
                  ? t({
                      id: `page16.no-activity-booking-for-participant.${appId}`,
                    })
                  : t({ id: `page16.no-activity-booking.${appId}` })
                : t({ id: `page16.no-activity.${appId}` })}
            </div>
          ) : journeyActivitiesRepo.state.isRefreshInProgress ? (
            <AppLoader className="my-2 p-2 px-4" type="loading" />
          ) : null}
          {dailyActivitiesToDisplay.length > 0 && (
            <div className="flex flex-col gap-4">
              {dailyActivitiesToDisplay.map((dailyActivity, i) => (
                <JourneyDailyActivityCard
                  companyReference={company?.reference}
                  context="list"
                  key={`${dailyActivity.selectedSession.activitySession.activitySessionId}-${dailyActivity.selectedSession.activitySession.activitySessionId}-${dailyActivity.displayMode}`}
                  dailyActivity={dailyActivity}
                  onClickParticipant={async ({
                    dailyActivity,
                    participantState,
                    action,
                  }) => {
                    openActivityDetailsDialog({
                      activityId: dailyActivity.activity._id,
                      activitySessionId:
                        dailyActivity.selectedSession?.activitySession
                          ?.activitySessionId,
                    });
                  }}
                  onSelectSessionId={setLastSelectedSessionId}
                  onClickActivityDetails={({
                    activityId,
                    activitySessionId,
                  }) => {
                    openActivityDetailsDialog({
                      activityId,
                      activitySessionId,
                    });
                    // scrollToTop();
                  }}
                  viewStep={'default'}
                />
              ))}
            </div>
          )}
          {!hasAnyFilter &&
            aggregatedData?.participantsWithoutActivities?.length > 0 && (
              <JourneyDailyNoActivityParticipantsCard
                participantsWithoutActivities={
                  aggregatedData?.participantsWithoutActivities
                }
              />
            )}

          {enableActivityStandalone && (
            <>
              <p className="mt-5 mb-2 text-gray-400 font-bold">
                {t({ id: `page04.label.animations.voir-aussi` })}:
              </p>
              {category === 'standalone' ? (
                <AppButtonCompany
                  isFullWidth={true}
                  className="w-full sm:max-w-xs uppercase mx-auto"
                  onClick={() => {
                    setCategoryAndScroll('animator');
                  }}
                  style="primary"
                  theme={theme}
                  label={t({ id: `page04.button.animations` })}
                  icon={AppIcons.openArrowRight}
                />
              ) : (
                <AppButtonCompany
                  isFullWidth={true}
                  className="w-full sm:max-w-xs uppercase mx-auto"
                  onClick={() => {
                    setCategoryAndScroll('standalone');
                  }}
                  style="primary"
                  theme={theme}
                  label={t({ id: `page04.button.activities-leisure` })}
                  icon={AppIcons.openArrowRight}
                />
              )}
            </>
          )}
          <AnimLiveJourneyActivitiesLegend className="my-4" />
        </div>
      </PageContainer>
    </AppPage>
  );
};
