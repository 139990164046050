import { ActivitySession } from 'lib-common-model';
import { dateTester, dateTransformer } from '_01_CORE/_components-core';
import { SessionRelativeTimeState } from './SessionRelativeTimeState.type';

export const sessionRelativeTimeStateBuilder = {
  build,
};

function build(
  session: Pick<ActivitySession, 'beginDateTime' | 'endDateTime'>
): SessionRelativeTimeState {
  const nowUTC = dateTransformer.getUTCFromLocalDate(new Date());

  const isSessionStarted = dateTester.isBefore(session.beginDateTime, nowUTC);
  const isSessionFinished = dateTester.isBefore(session.endDateTime, nowUTC);
  return isSessionStarted
    ? isSessionFinished
      ? 'past'
      : 'in-progress'
    : 'future';
}
