import { useJourneyActivityCategory } from '_01_CORE/app-contexts';
import { JourneyActivitiesLocalState } from '_02_APP_COMMON/ACT-activity';
import clsx from 'clsx';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useAnimLiveJourneysActivitiesPageCriteriaBarOptions } from './useAnimLiveJourneysActivitiesPageCriteriaBarOptions.hook';

export const AnimLiveJourneysListPageCriteriaBarFiltersResume = ({
  localState,
  className,
}: {
  localState: JourneyActivitiesLocalState;
  className?: string;
}) => {
  const { formatMessage: t } = useIntl();

  const { localFilters, journey, category, isSearchTextActive } = localState;

  const options = useAnimLiveJourneysActivitiesPageCriteriaBarOptions({
    localState,
  });
  const enableActivityStandalone = useJourneyActivityCategory(
    journey,
    'standalone'
  );
  const criteria = useMemo(() => {
    return [
      {
        name: `Type d'activité`,
        value: isSearchTextActive
          ? null
          : enableActivityStandalone
          ? category
          : undefined,
        formattedValue: options.activityCategoriesOptions.find(
          (x) => x.id === category
        )?.label,
      },
      {
        name: 'Activité',
        value: localFilters.selectedActivityId,
        formattedValue: options.activitiesOptions.find(
          (x) => x.id === localFilters.selectedActivityId
        )?.label,
      },
      {
        name: 'Participant',
        value: isSearchTextActive ? null : localFilters.selectedChildId,
        formattedValue: options.participantsOptions.find(
          (x) => x.id === localFilters.selectedChildId
        )?.label,
      },
    ].filter((x) => !!x.value);
  }, [
    category,
    enableActivityStandalone,
    isSearchTextActive,
    localFilters.selectedActivityId,
    localFilters.selectedChildId,
    options.activitiesOptions,
    options.activityCategoriesOptions,
    options.participantsOptions,
  ]);

  const bookingGroupType = 0;

  return criteria.length !== 0 ? (
    <div
      className={clsx(
        'max-w-full flex justify-start flex-wrap gap-x-4 gap-y-1 text-xs text-gray-400 uppercase truncate',
        className
      )}
    >
      {criteria.map(({ name, formattedValue }, i) => (
        <div key={i} className={clsx('flex gap-1 truncate')}>
          <div className="font-bold ">{name}:</div>
          <div className=" ">{formattedValue}</div>
        </div>
      ))}
    </div>
  ) : null;
};
