import {
  IonButton,
  IonIcon,
  IonItem,
  IonItemGroup,
  IonRow,
} from '@ionic/react';
import { add } from 'ionicons/icons';
import { appLogger } from 'lib-web-logger';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import {
  useAppCacheContext,
  useJourney,
  useJourneyEnabledActions,
} from '_01_CORE/app-contexts';
import {
  AppPage,
  CompanyImage,
  HeaderToolbar,
  PageContainer,
  PageTitle,
} from '_01_CORE/_common-layout';
import { JourneyCard, JourneyParticipantCard } from '_01_CORE/_components-core';
import { useAppRouter } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';

// ANIM LIVE seulement
export const AnimLiveJourneyEditExistingHomePage = () => {
  const appRouter = useAppRouter();
  const { formatMessage: t } = useIntl();

  const { customerJourneyId } = useParams<{ customerJourneyId: string }>();

  const { journey, participants } = useJourney({ customerJourneyId });
  const { auth } = useAppCacheContext();
  const { isSuperAdmin } = auth;

  const {
    isJourneyEditEnabled,
    isAddJourneyParticipantEnabled,
  } = useJourneyEnabledActions({
    journey,
  });

  useEffect(() => {
    if (!journey || !isJourneyEditEnabled) {
      appLogger.warn(
        '[AnimLiveJourneyEditExistingHomePage] No journey: redirect to /journeys'
      );
      appRouter.navigateAfterDelay(appRoutesBuilder.getJourneysUrl(), {
        cause: 'journey-edit-existing-home-no-journey',
      });
    }
  }, [appRouter, isJourneyEditEnabled, isSuperAdmin, journey]);

  return !journey ? null : (
    <AppPage>
      <HeaderToolbar />
      <PageContainer className="text-center bg-gray-50" spacing="normal">
        <PageTitle
          title={t({ id: 'component.journey.edit.anim-live' })}
          // TODO TEMPORAIRE
          backLink={appRoutesBuilder.getJourneyHomeUrl({
            customerJourneyId,
          })}
        />
        <CompanyImage image={journey.company?.image1} />
        <div className="flex flex-col space-y-8 max-w-md mx-auto">
          <IonItemGroup>
            <JourneyCard
              showActionEditDates={true}
              journey={journey}
              redirectOnDelete={true}
              showDetails={true}
            />
            {participants.length > 0 && (
              <>
                {participants.map((p) => (
                  <IonItem key={`${p.journeyParticipantId}`}>
                    <JourneyParticipantCard
                      customerJourneyId={journey._id}
                      participant={p}
                      className="py-2"
                      isEditEnabled={true}
                    />
                  </IonItem>
                ))}
              </>
            )}
          </IonItemGroup>
        </div>
        {isAddJourneyParticipantEnabled && (
          <div className="text-center p-4 bg-gray-100">
            <IonItemGroup>
              <IonRow className="ion-justify-content-center">
                <IonButton
                  color="success"
                  onClick={() => {
                    appRouter.navigate(
                      appRoutesBuilder.getJourneyEditParticipantCreateUrl({
                        customerJourneyId,
                      }),
                      {
                        cause: 'participant-edit-submit',
                      }
                    );
                  }}
                  shape="round"
                  className="w-72"
                >
                  <IonIcon className="mr-2" icon={add} />
                  {t({ id: 'page12.addParticipantButton' })}
                </IonButton>
              </IonRow>
            </IonItemGroup>
          </div>
        )}
      </PageContainer>
    </AppPage>
  );
};
