import { ResponsiveImage } from 'lib-common-model';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { imageUrlBuilder } from '_01_CORE/app-core/app-api';
import { AppButtonUniverse, AppButtonV2 } from '../AppButton';
import { useAppModalContext } from '../AppModal';
import { AppIcons } from '../icons';

export type AppInputPhotoPreviewProps = {
  photo?: ResponsiveImage;
  showEmptyImage?: boolean;
  onDelete?: () => any;
  deleteLabel?: string;
  onDeleteConfirmMessage?: string;
  size: 'small' | 'large';
};

export const AppInputPhotoPreview = ({
  photo,
  showEmptyImage,
  deleteLabel = 'Supprimer',
  onDelete,
  onDeleteConfirmMessage,
  size,
}: AppInputPhotoPreviewProps) => {
  const { formatMessage: t } = useIntl();

  const { openModal, closeModal } = useAppModalContext();

  const onClickDelete = useCallback(() => {
    if (onDeleteConfirmMessage) {
      openModal({
        title: t({ id: 'modal.confirmation' }),
        content: <div>{onDeleteConfirmMessage}</div>,
        actions: (
          <div className="my-2 w-full grid gap-x-2 gap-y-4 sm:grid-cols-2">
            <AppButtonUniverse
              className="w-full text-base uppercase font-bold"
              isFullWidth={true}
              style="danger"
              label={t({ id: 'modal.yes' })}
              onClick={() => {
                onDelete();
                closeModal();
              }}
            />
            <AppButtonUniverse
              className="w-full text-sm uppercase font-bold"
              isFullWidth={true}
              style="cancel"
              label={t({ id: 'modal.no' })}
              onClick={closeModal}
            />
          </div>
        ),
      });
    } else {
      onDelete();
    }
  }, [closeModal, onDelete, onDeleteConfirmMessage, openModal, t]);

  const imageSrc = useMemo(() => {
    const imageSrc = photo
      ? imageUrlBuilder.getImageFullUrl({ image: photo.md })
      : showEmptyImage
      ? '/assets/images/empty.png'
      : undefined;
    return imageSrc;
  }, [showEmptyImage, photo]);

  return (
    <div
      className={
        size === 'large'
          ? 'relative overflow-hidden'
          : 'relative overflow-hidden flex flex-wrap gap-2 items-end'
      }
    >
      {imageSrc &&
        (size === 'large' ? (
          <img
            className="relative w-full object-cover object-center"
            style={{ maxHeight: '200px', width: 'auto' }}
            src={imageSrc}
          />
        ) : (
          <img
            className="relative w-full object-cover object-center border-2 border-gray-600"
            style={{ maxHeight: '50px', width: 'auto' }}
            src={imageSrc}
          />
        ))}

      {onDelete && (
        <div
          className={
            size === 'large'
              ? 'absolute bg-white p-0.5 bottom-1 left-2'
              : 'bg-white p-0.5'
          }
        >
          <AppButtonV2
            className={`inline-block`}
            // size="xs"
            buttonStyle="danger"
            icon={AppIcons.actionDelete}
            onClick={() => {
              onClickDelete();
            }}
          >
            {deleteLabel}
          </AppButtonV2>
        </div>
      )}
    </div>
  );
};
