import { CaptureContext } from '@sentry/types';

const config = {
  buildCaptureContext: (): CaptureContext => {
    return {};
  },
};

export const appLoggerConfigurer = {
  getConfig: () => config,
  configure: ({
    buildCaptureContext,
  }: {
    buildCaptureContext: () => CaptureContext;
  }) => {
    if (buildCaptureContext) {
      config.buildCaptureContext = buildCaptureContext;
    }
  },
};
