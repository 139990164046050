import { authenticationClient } from '_01_CORE/app-core/app-security';
import {
  CMAuthUserLoginTestResponse,
  CustomerMobileAuthenticationResult,
} from 'lib-common-model';
import { ApiClientRequestOverrideConfig, apiClient } from 'lib-web-api-client';

export const authApiClient = {
  getTmpToken,
  resetPasswordQuery,
  resetPasswordConfirm,
  refreshToken,
  authenticateByLoginPassword,
  authenticateByActivationToken,
  testEmail,
};

function getTmpToken() {
  return apiClient.put<CustomerMobileAuthenticationResult>(
    '/customer-web/auth/refresh',
    {
      options: {
        authenticate: true,
        json: {
          type: 'temporary-token',
        },
        alwaysThrowError: true,
      },
    }
  );
}
function resetPasswordQuery({ login }: { login: string }) {
  return apiClient.post<CustomerMobileAuthenticationResult>(
    '/customer-web/auth/reset/query',
    {
      options: {
        authenticate: false,
        json: { login },
      },
    }
  );
}
async function resetPasswordConfirm({ password }: { password: string }) {
  const response = await apiClient.post<CustomerMobileAuthenticationResult>(
    '/customer-web/auth/reset/confirm',
    {
      options: {
        authenticate: true,
        json: { password },
      },
    }
  );

  await authenticationClient.updateTokenAndProfile(response);
  return response;
}
function refreshToken({
  overrideConfig,
}: { overrideConfig?: ApiClientRequestOverrideConfig } = {}) {
  return apiClient.put<CustomerMobileAuthenticationResult>(
    '/customer-web/auth/refresh',
    {
      options: {
        authenticate: true,
        alwaysThrowError: true,
      },
      overrideConfig,
    }
  );
}
function testEmail({ email }: { email: string }) {
  return apiClient.post<CMAuthUserLoginTestResponse>(
    '/customer-web/auth/test-email',
    {
      options: {
        json: {
          email,
        },
        alwaysThrowError: true,
      },
    }
  );
}
function authenticateByLoginPassword({
  login,
  password,
}: {
  login: string;
  password: string;
}) {
  return apiClient.post<CustomerMobileAuthenticationResult>(
    '/customer-web/auth/login',
    {
      options: {
        json: {
          login,
          password,
        },
        alwaysThrowError: true,
      },
    }
  );
}
function authenticateByActivationToken({
  email,
  activationToken,
}: {
  email: string;
  activationToken: string;
}) {
  return apiClient.post<CustomerMobileAuthenticationResult>(
    '/customer-web/auth/login-by-activation-token',
    {
      options: {
        json: {
          email,
          activationToken,
        },
        alwaysThrowError: true,
      },
    }
  );
}
