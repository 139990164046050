import { CustomerJourney } from 'lib-common-model';
import { useMemo } from 'react';

export function useCampingPlaceNumberVisibility(
  journey: Pick<
    CustomerJourney,
    'beginDateUTC' | 'campingPlaceNumber' | 'extCustomerJourneyBookingGroupId'
  >
) {
  const isJourneyStarted: boolean = useMemo(
    () => journey?.beginDateUTC.getTime() <= new Date().getTime(),
    [journey?.beginDateUTC]
  );

  const isVisibleCampingPlaceNumber: boolean = useMemo(() => {
    if (journey?.extCustomerJourneyBookingGroupId) {
      return isJourneyStarted && !!journey?.campingPlaceNumber;
    } else {
      return !!journey?.campingPlaceNumber;
    }
  }, [
    isJourneyStarted,
    journey?.campingPlaceNumber,
    journey?.extCustomerJourneyBookingGroupId,
  ]);

  const isEditableCampingPlaceNumber: boolean = useMemo(() => {
    return (
      journey?.campingPlaceNumber === null ||
      journey?.campingPlaceNumber === undefined
    );
    // return (
    //   !journey?.extCustomerJourneyBookingGroupId &&
    //   (!!journey?.campingPlaceNumber || isJourneyStarted)
    // );
  }, [journey?.campingPlaceNumber]);
  return {
    isVisibleCampingPlaceNumber,
    isEditableCampingPlaceNumber,
  };
}
