import React, { useMemo } from 'react';
import { DayComponentProps } from '_01_CORE/_components-core/DatePicker/DayComponentProps.type';
import { DailySessionsAvailabilitiesMap } from './DailySessionsAvailabilitiesMap.type';
import {
  buildStatusFromSessionCounts,
  useSessionCounts,
} from './useSessionCounts.hook';

export const JourneyChooseDatesPageAccroparkCalendarDayComponent = (
  props: DayComponentProps & {
    dailySessionsAvailabilitiesMap: DailySessionsAvailabilitiesMap;
  }
) => {
  const { dailySessionsAvailabilitiesMap, date, day, onClick } = props;

  const {
    currentCompanySessionsCount,
    currentCompanyAvailableSessionsCount,
    otherCompanySessionsCount,
    otherCompanyAvailableSessionsCount,
  } = useSessionCounts({ date, dailySessionsAvailabilitiesMap });

  const {
    classes,
    isDisabled,
  } = useJourneyChooseDatesPageAccroparkCalendarDayComponentClasses({
    ...props,
    currentCompanySessionsCount,
    currentCompanyAvailableSessionsCount,
    otherCompanySessionsCount,
    otherCompanyAvailableSessionsCount,
  });
  return (
    <div
      key={`day${day}`}
      className={classes}
      onClick={isDisabled ? undefined : onClick}
    >
      {day}
    </div>
  );
};

function useJourneyChooseDatesPageAccroparkCalendarDayComponentClasses({
  isSelected,
  isPast,
  isToday,
  currentCompanySessionsCount,
  currentCompanyAvailableSessionsCount,
  otherCompanyAvailableSessionsCount,
}: DayComponentProps & {
  dailySessionsAvailabilitiesMap: DailySessionsAvailabilitiesMap;
  currentCompanySessionsCount: number;
  currentCompanyAvailableSessionsCount: number;
  otherCompanySessionsCount: number;
  otherCompanyAvailableSessionsCount: number;
}) {
  return useMemo(() => {
    let classes =
      'flex justify-self-center place-self-center text-center rounded-full p-1 text-sm w-8 h-auto justify-center m-1';
    if (isSelected) {
      classes += ' border-2 border-red-500';
    }
    if (isToday) {
      classes += ' font-bold';
    }
    const status = buildStatusFromSessionCounts({
      currentCompanyAvailableSessionsCount,
      otherCompanyAvailableSessionsCount,
      currentCompanySessionsCount,
    });
    if (!isPast) {
      switch (status) {
        case 'available':
          classes += ' bg-app-agenda-available text-gray-700 font-bold';
          break;
        case 'busy-1':
          classes += ' bg-app-agenda-busy-1 text-gray-700 font-bold';
          break;
        case 'busy-2':
          classes += ' bg-app-agenda-busy-1 text-gray-700 font-bold';
          // classes += ' bg-app-agenda-busy-2 text-white font-bold';
          break;
        case 'full':
          classes += ' bg-app-agenda-busy-2 text-white font-bold';
          // classes += ' bg-app-agenda-full text-gray-200 font-bold';
          break;
        case 'closed':
          classes += ' bg-app-agenda-closed text-gray-400 font-bold';
          break;
      }
    } else {
      classes += ' text-gray-400';
    }
    const isDisabled = isPast || status === 'closed';
    if (isDisabled) {
      classes += ' text-gray-300 cursor-not-allowed';
    } else {
      classes += ' cursor-pointer';
    }
    return { classes, isDisabled };
  }, [
    currentCompanyAvailableSessionsCount,
    currentCompanySessionsCount,
    isPast,
    isSelected,
    isToday,
    otherCompanyAvailableSessionsCount,
  ]);
}
