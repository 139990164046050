import { useMemo } from 'react';
import { useAppModalContext } from './useAppModalContext.hook';

export const AppModal = () => {
  const {
    isVisible,
    clickOutsideToDismiss,
    closeModal,
    title,
    actions,
    content,
    icon,
  } = useAppModalContext();
  const colors = useMemo(() => {
    if (icon === 'danger') {
      return {
        bg: 'bg-red-100',
        text: 'text-red-600',
        svgIcon: (
          <>
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </>
        ),
      };
    }
    if (icon === 'info') {
      return {
        bg: 'bg-blue-100',
        text: 'text-blue-600',
        svgIcon: (
          <>
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </>
        ),
      };
    }
    if (icon === 'success') {
      return {
        bg: 'bg-green-100',
        text: 'text-green-600',
        svgIcon: (
          <>
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 13l4 4L19 7"
            />
          </>
        ),
      };
    }
  }, [icon]);

  return !isVisible ? null : (
    <div className="fixed z-50 inset-0 overflow-y-auto text-black">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div
            className="absolute inset-0 bg-gray-500 opacity-75"
            onClick={() => clickOutsideToDismiss && closeModal()}
          ></div>
        </div>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
            {/* <button
              type="button"
              className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              //   onClick={() => setDismissed(true)}
              >
              <span className="sr-only">Close</span>
              <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button> */}
          </div>
          <div className="mt-0 sm:ml-4 text-left">
            <div className="flex gap-2 sm:gap-4">
              {!colors ? null : (
                <div
                  className={`flex flex-shrink-0 items-center justify-center h-6 w-6 sm:h-10 sm:w-10 rounded-full ${colors.bg}`}
                >
                  <svg
                    className={`h-4 w-4 sm:h-6 sm:w-6 ${colors.text}`}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    {colors.svgIcon}
                  </svg>
                </div>
              )}
              <h3
                className="text-lg leading-6 font-medium text-gray-900"
                id="modal-headline"
              >
                {title}
              </h3>
            </div>
            <div className="mt-2">{content}</div>
          </div>
          <div className="mt-5 flex flex-col gap-y-2 gap-x-4 sm:mt-4 sm:flex-row-reverse z-1000">
            {actions}
          </div>
        </div>
      </div>
    </div>
  );
};
