import { CMJourneyParticipant } from 'lib-common-model';
import { useIntl } from 'react-intl';
import { ParticipantAvatar } from '_01_CORE/_components-core';

export const JourneyDailyNoActivityParticipantsCard = ({
  participantsWithoutActivities,
}: {
  participantsWithoutActivities: CMJourneyParticipant[];
}) => {
  const { formatMessage: t } = useIntl();

  return (
    <div
      className="flex flex-col w-full rounded-lg border-2 mt-4 space-x-1 ml:flex-row"
      style={{ borderColor: '#222222' }}
    >
      <div className="flex flex-col flex-1">
        {/* name, name2, session name */}
        <div className="text-lg font-bold text-center">
          {t({ id: 'page16.activity.none' })}
        </div>

        {!participantsWithoutActivities?.length ? null : (
          <div className="mx-2 sm:flex flex-1 space-x-2 py-1">
            {/* Participants */}
            <div className="flex-grow sm:mt-0 flex flex-wrap gap-4 items-center">
              {participantsWithoutActivities.map((journeyParticipant, i) => (
                <div className="flex flex-col gap-1" key={`${i}`}>
                  <ParticipantAvatar
                    participant={journeyParticipant}
                    withBorder={true}
                    selected={false}
                  />
                  <div
                    className={`truncate text-center text-gray-600 ${
                      journeyParticipant?.member.firstName.length > 7
                        ? 'text-xs'
                        : 'text-sm'
                    }`}
                  >
                    {journeyParticipant?.member.firstName}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
