import React from 'react';
import { PageBackLink } from './PageBackLink';

export const PageTitle: React.FC<{
  title: string;
  spacing?: 'small' | 'normal';
  backLink?: string;
}> = ({ spacing, title, backLink }) => {
  return (
    <PageBackLink mode="block" spacing={spacing} backLink={backLink}>
      {title}
    </PageBackLink>
  );
};
