import { Listbox } from '@headlessui/react';
import { CMCompany } from 'lib-common-model';
import { useAppImageUrl } from '_01_CORE/app-core/app-security/useAppImageUrl.hook';

export const AppCompanySelectOption = <
  COMPANY extends Pick<CMCompany, '_id' | 'name' | 'direction' | 'logo'>
>({
  company,
}: {
  company: COMPANY;
}) => {
  const companyLogoUrl = useAppImageUrl(company?.logo, {
    defaultImage: 'app',
  });

  return (
    <Listbox.Option
      className={({ active }) =>
        `${active ? 'text-amber-900 bg-amber-100' : 'text-gray-900'}
                      cursor-default select-none relative`
      }
      value={company}
    >
      {({ selected, active }) => (
        <>
          <div
            className={`flex flex-1 justify-left text-left p-2 text-gray-600 ${
              selected && 'font-bold text-gray-800'
            } ${active && 'text-gray-800 bg-gray-100'}`}
          >
            <div className="mr-2">
              <img
                className="max-w-6xs h-10 object-contain mr-2"
                src={companyLogoUrl}
              />
            </div>
            <div className={`text-xs`}>
              <div>{company?.name}</div>
              <div className="text-gray-500 text-app-xxs1">
                {company?.direction?.line1}
              </div>
              {/* dans la bdd accropark, line1 = adresse complete */}
              {/* dans la bdd zipCode = téléphone ou email */}
              {/* dans la bdd direction.city = téléphone ou email */}
              {/* {company?.direction.zipCode} {company?.direction.city} */}
              {/* </div> */}
            </div>
          </div>
        </>
      )}
    </Listbox.Option>
  );
};
