import { IonInput } from '@ionic/react';
import { AppPage, PageContainer } from '_01_CORE/_common-layout';
import { AppButtonUniverse, AppLoader } from '_01_CORE/_components-core';
import { AppButton } from '_01_CORE/_components-core/AppButton/AppButton';
import { AppInputIonic } from '_01_CORE/_components-core/AppInput';
import { useAppRouter, useAppToasts } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import { useAppCacheContext } from '_01_CORE/app-contexts';
import { appBootstrap } from '_01_CORE/app-core/app-bootstrap/appBootstrap.service';
import {
  appWebConfig,
  buildRemoteEnvId,
  useAppFeatures,
} from '_01_CORE/app-core/app-config';
import { useNetworkStatus } from '_01_CORE/app-core/app-platform';
import {
  authenticationClient,
  useAppLogoUrl,
  useAppSettings,
} from '_01_CORE/app-core/app-security';
import { appStore } from '_01_CORE/app-core/app-store';
import {
  HACK_SPECIAL_ACCOUNTS,
  companyOrGroupReferenceTester,
} from '_02_APP_COMMON/AUT-auth';
import { AppUniverseHeader } from '_02_APP_COMMON/_global';
import { appProRoutesBuilder } from '_04_ANIM_LIVE/PRO-company-pro/appProRoutesBuilder.service';
import { key, lockClosed, mail } from 'ionicons/icons';
import { UserProfile } from 'lib-common-model';
import { AppRemoteEnvId } from 'lib-web-common';
import { appLogger } from 'lib-web-logger';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

const HACK_DEBUG_PAGE_LOGINS = ['_mic_', 'test55'];

const DEV_WEB_TEST_TOKEN = ''; // 'test-token';

export const AuthPageLoginAccropark = () => {
  const [persistInProgress, setPersistInProgress] = useState(false);

  const appId = appWebConfig().appId;

  const appSettings = useAppSettings();
  const whiteLabel = appSettings?.universe?.whiteLabel;
  const identity = appSettings?.universe?.identity;

  const appLabel = identity.appLabel;
  const appLogoUrl = useAppLogoUrl('md');

  const appRouter = useAppRouter();
  const { formatMessage: t } = useIntl();
  const { pushError, pushSuccess } = useAppToasts();
  const { networkStatus } = useNetworkStatus();
  const [emailAddress, setEmailAddress] = useState<string>(
    appWebConfig().authentication.defaultAuthLogin?.login || undefined
  );
  const [password, setPassword] = useState<string>(
    appWebConfig().authentication.defaultAuthLogin?.password || undefined
  );
  const [
    companyOrGroupReference,
    setCompanyOrGroupReference,
  ] = useState<string>(
    appWebConfig().authentication.defaultAuthLogin?.companyCode || undefined
  );

  const { isFeatureAuthEnabled } = useAppFeatures();

  //Save selected company in case we use company code
  const appCacheContext = useAppCacheContext();

  async function login() {
    if (!isNetworkConnected() || !isValidLoginForm) {
      return;
    }

    const hackAccount = HACK_SPECIAL_ACCOUNTS.find(
      (x) => x.login === emailAddress
    );

    if (HACK_DEBUG_PAGE_LOGINS.includes(emailAddress)) {
      appRouter.navigate(appRoutesBuilder.getDebugUrl(), {
        cause: 'login-hack',
      });
      return;
    } else if (hackAccount) {
      const appId = appWebConfig().appId;
      const envId = appWebConfig().envId;
      const remoteEnvId = appWebConfig().remoteEnvId;

      const newRemoteEnvId: AppRemoteEnvId =
        hackAccount.remote === 'local-ip'
          ? 'local-ip'
          : hackAccount.remote === 'auto'
          ? appId === 'accropark'
            ? 'accro-beta'
            : 'beta'
          : hackAccount.remote === 'prod'
          ? appId === 'accropark'
            ? 'accro-prod'
            : 'prod'
          : undefined;
      console.log('[LoginPage] newRemoteEnvId', newRemoteEnvId);
      if (newRemoteEnvId && remoteEnvId !== newRemoteEnvId) {
        console.info(`Switch to remote env ${newRemoteEnvId}`);
        appBootstrap.switchEnv({
          remoteEnvId: newRemoteEnvId,
        });
      }
    } else {
      const appId = appWebConfig().appId;
      const envId = appWebConfig().envId;
      const remoteEnvId = appWebConfig().remoteEnvId;
      const defaultRemoteEnvId = buildRemoteEnvId({
        appId,
        envId,
      });
      if (remoteEnvId !== defaultRemoteEnvId) {
        console.info(`Switch back to defaul tremote env ${defaultRemoteEnvId}`);
        appBootstrap.switchEnv({
          remoteEnvId: defaultRemoteEnvId,
        });
      }
    }
    setPersistInProgress(true);
    if (DEV_WEB_TEST_TOKEN?.length && appWebConfig().envId === 'dev') {
      console.log('[DEV] Set fake deviceToken:', DEV_WEB_TEST_TOKEN);
      appStore.deviceToken.set(DEV_WEB_TEST_TOKEN, 'dev web test token');
    }
    await authenticationClient
      .authenticateByLoginPassword({
        login: emailAddress,
        password: password,
      })
      .then(
        ({ profile }) => {
          pushSuccess(t({ id: 'page01.connectionOK' }), 500);
          if (
            (profile as UserProfile<'multi-users-v2'>).type === 'super-admin'
          ) {
            appLogger.debug('[Redirect] to /ddebugg');
            appRouter.navigate(appRoutesBuilder.getDebugUrl(), {
              cause: 'login-success',
            });
          } else if (profile.type === 'customer') {
            const hasJourneys = profile?.journeys?.length > 0;
            if (hasJourneys) {
              appLogger.debug('[Redirect] to /journey');
              appRouter.navigate(
                appRoutesBuilder.getJourneyDefaultUrl({
                  journeys: profile.journeys,
                }),
                { cause: 'login-success' }
              );
            } else {
              appLogger.debug('[Redirect] to /journeys');
              appRouter.navigate(appRoutesBuilder.getJourneysUrl(), {
                cause: 'login-success',
              });
            }
          } else if (profile.type === 'company') {
            appLogger.debug('[Redirect] to /pro');
            appRouter.navigate(appProRoutesBuilder.getHomeUrl(), {
              cause: 'login-success',
            });
          }
        },
        (err) => {
          appLogger.warn('Authentication err', { err });
          pushError(t({ id: 'page01.connectionNotOK' }));
        }
      )
      .finally(() => {
        setPersistInProgress(false);
      });
  }

  /**
   * See the company by given code
   */
  function companySee() {
    if (!isNetworkConnected()) {
      return;
    }
    setPersistInProgress(true);
    companyOrGroupReferenceTester
      .testCompanyOrGroupCode(companyOrGroupReference, {
        appCacheContext,
        alwaysThrowError: true,
      })
      .then(({ redirectTo }) => {
        if (redirectTo) {
          appRouter.navigate(redirectTo, {
            cause: 'test-company-group-code-success',
          });
        } else {
          pushError(t({ id: 'page01.companyUnknown' }));
        }
      })
      .finally(() => {
        setPersistInProgress(false);
      });
  }

  function isNetworkConnected() {
    if (networkStatus && !networkStatus?.connected) {
      pushError(t({ id: 'page01.connectionOK' }));
      return false;
    } else {
      return true;
    }
  }

  const isValidLoginForm: boolean = useMemo(
    () =>
      !!emailAddress?.trim() &&
      (!!password?.trim() || HACK_DEBUG_PAGE_LOGINS.includes(emailAddress)),
    [emailAddress, password]
  );

  const handleEmailChange = (e: CustomEvent<{ value?: string }>) =>
    setEmailAddress(e.detail.value);
  const handlePasswordChange = (e: CustomEvent<{ value?: string }>) =>
    setPassword(e.detail.value);
  const handleCompanyCodeChange = (e: CustomEvent<{ value?: string }>) =>
    setCompanyOrGroupReference(e.detail.value);
  const handleForgotPasswordTouch = () => {
    appRouter.navigate(
      appRoutesBuilder.getAccountResetPasswordQueryUrl({
        login: emailAddress,
      }),
      {
        cause: 'click-forgot-password',
      }
    );
  };

  const title1Color = appSettings?.universe?.theme?.title1Color;

  return (
    <AppPage tabId="login">
      <PageContainer
        className={`h-full bg-white text-center`}
        style={{
          color: title1Color,
        }}
      >
        <AppUniverseHeader showLargeHeader={true} />
        {persistInProgress ? (
          <AppLoader
            className="my-4 p-2 px-4"
            type="persist"
            message="Connexion en cours"
          />
        ) : (
          <div className="max-w-xl m-auto">
            {isFeatureAuthEnabled && (
              <>
                {appId === 'anim-live' && (
                  <>
                    {!(
                      whiteLabel.appCompanyReference ||
                      whiteLabel.appGroupReference
                    ) && (
                      <div className="mx-6 mt-10 bg-gray-50 rounded-xl py-2 px-4">
                        <h2 className="text-left text-xl text-gray-600 font-bold my-4">
                          {t({ id: 'page01.title.anim-live.companyCode' })}
                        </h2>
                        <div className="w-full text-center">
                          <AppInputIonic
                            required={true}
                            placeholder={t({ id: 'page01.companyCode' })}
                            value={companyOrGroupReference}
                            icon={key}
                          >
                            <IonInput
                              inputmode="text"
                              onIonChange={handleCompanyCodeChange}
                              required
                              type="text"
                            />
                          </AppInputIonic>
                        </div>
                        <div className="ion-justify-content-center">
                          <AppButton
                            color="bg-gray"
                            className="mt-4 uppercase"
                            onClick={() => companySee()}
                          >
                            {t({ id: `page01.companySee.${appId}` })}
                          </AppButton>
                        </div>
                      </div>
                    )}

                    <div className="mx-6 mt-10 bg-gray-50 rounded-xl py-2 px-4">
                      <h2
                        className={`text-left text-xl font-bold my-4`}
                        style={{ color: title1Color }}
                      >
                        {t({ id: 'page01.title.anim-live.login' })}
                      </h2>
                      <div className="w-full text-center">
                        <AppInputIonic
                          required={true}
                          placeholder={t({ id: 'form.label.email' })}
                          value={emailAddress}
                          icon={mail}
                        >
                          <IonInput
                            autofocus
                            inputmode="email"
                            onIonChange={handleEmailChange}
                            pattern="email"
                            required
                            type="email"
                          />
                        </AppInputIonic>
                      </div>
                      <div className="w-full text-center mt-2">
                        <AppInputIonic
                          required={true}
                          placeholder={t({ id: 'form.label.password' })}
                          value={password}
                          icon={lockClosed}
                        >
                          <IonInput
                            inputmode="text"
                            onIonChange={handlePasswordChange}
                            pattern="password"
                            required
                            type="password"
                          />
                        </AppInputIonic>
                      </div>
                      <div className="ion-justify-content-center">
                        <AppButtonUniverse
                          style={'primary'}
                          className={`mt-4 uppercase`}
                          disabled={!isValidLoginForm}
                          onClick={() => login()}
                        >
                          {t({ id: 'page01.submit' })}
                        </AppButtonUniverse>
                      </div>
                      <div className="text-center text-sm underline mt-2 cursor-pointer">
                        <a onClick={handleForgotPasswordTouch}>
                          {t({ id: 'page01.forgotPassword' })}
                        </a>
                      </div>
                      {(whiteLabel.appCompanyReference ||
                        whiteLabel.appGroupReference) && (
                        <div className="mt-8 ion-justify-content-center">
                          <AppButtonUniverse
                            style="secondary"
                            className="mt-4 uppercase"
                            onClick={() => {
                              const path = whiteLabel.appCompanyReference
                                ? appRoutesBuilder.getCompanyBookletUrl({
                                    companyReference:
                                      whiteLabel.appCompanyReference,
                                  })
                                : appRoutesBuilder.getGroupHomeUrl({
                                    groupReference:
                                      whiteLabel.appGroupReference,
                                    isAuthenticated: false,
                                  });
                              appRouter.navigate(path, {
                                cause: 'login-page-click-enter',
                              });
                            }}
                          >
                            {t({ id: 'common.action.back' })}
                          </AppButtonUniverse>
                        </div>
                      )}
                    </div>
                  </>
                )}
                {appId === 'accropark' && (
                  <>
                    <div className="mx-6 mt-10 bg-gray-50 rounded-xl py-8 px-4">
                      <div className="w-full text-center">
                        <AppInputIonic
                          required={true}
                          placeholder={t({ id: 'form.label.email' })}
                          value={emailAddress}
                          icon={mail}
                        >
                          <IonInput
                            autofocus
                            inputmode="email"
                            onIonChange={handleEmailChange}
                            pattern="email"
                            required
                            type="email"
                          />
                        </AppInputIonic>
                      </div>
                      <div className="w-full text-center mt-2">
                        <AppInputIonic
                          required={true}
                          placeholder={t({ id: 'form.label.password' })}
                          value={password}
                          icon={lockClosed}
                        >
                          <IonInput
                            inputmode="text"
                            onIonChange={handlePasswordChange}
                            pattern="password"
                            required
                            type="password"
                          />
                        </AppInputIonic>
                      </div>
                      <div className="ion-justify-content-center">
                        <AppButtonUniverse
                          style={'primary'}
                          className="mt-4 uppercase"
                          disabled={!isValidLoginForm}
                          onClick={() => login()}
                        >
                          {t({ id: 'page01.submit' })}
                        </AppButtonUniverse>
                      </div>
                      <div className="mt-4 text-center text-sm underline cursor-pointer">
                        <a onClick={handleForgotPasswordTouch}>
                          {t({ id: 'page01.forgotPassword' })}
                        </a>
                      </div>
                      <div className="mt-8 ion-justify-content-center">
                        <AppButton
                          color="bg-gray"
                          className="mt-4 uppercase"
                          onClick={() => appRouter.goBack()}
                        >
                          {t({ id: `page01.companySee.${appId}` })}
                        </AppButton>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        )}
      </PageContainer>
    </AppPage>
  );
};
