import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { useAppCacheContext, useCompany } from '_01_CORE/app-contexts';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import {
  AppPage,
  AppTitle1,
  FooterActions,
  HeaderToolbar,
  PageContainer,
} from '_01_CORE/_common-layout';
import { useAppRouter, useOperationPending } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import { useDeleteJourneyBookingAccroparkConfirm } from '_02_APP_COMMON/JOU-journey/_common';
import { AccroparkBookingResumeTable } from '../_common';

export const AccroparkBookingResumePage = () => {
  const appRouter = useAppRouter();
  const { formatMessage: t } = useIntl();
  const appId = appWebConfig().appId;

  const { companyReference, bookingId } = useParams<{
    companyReference: string;
    bookingId: string;
  }>();

  const { company } = useCompany({ companyReference });
  const { auth, booking } = useAppCacheContext();

  const deleteBookingConfirm = useDeleteJourneyBookingAccroparkConfirm({
    customerJourneyId: booking?.customerJourneyId,
  });

  const goBack = () => {
    appRouter.navigate(
      appRoutesBuilder.getGiftCardEditPage({
        bookingId,
        companyReference,
      }),
      { cause: 'booking-resume-back' }
    );
  };

  useEffect(() => {
    if (!booking || booking?.type !== 'gift-card') {
      appRouter.navigate(appRoutesBuilder.getJourneysUrl(), {
        cause: 'invalid-booking-resume',
      });
    }
  }, [appRouter, booking]);

  const onSubmit = useOperationPending(async () => {
    appRouter.navigate(
      appRoutesBuilder.getAccountEditAccroparkGiftCardUrl({
        bookingId,
        companyReference,
      }),
      { cause: 'journey-options-submit' }
    );
  }, [appRouter, bookingId, companyReference]);

  return (
    <AppPage displayFooter={false}>
      <HeaderToolbar hideLogout={true} />
      <PageContainer
        className="text-center bg-gray-50 flex flex-col items-stretch text-center"
        spacing="normal"
      >
        <AppTitle1 className="mx-auto" arrow="right">
          {t({ id: 'page27-booking-resume.title.my-shooping-cart' })}
        </AppTitle1>

        <div className="flex-grow">
          {!!booking && !!company && (
            <AccroparkBookingResumeTable
              booking={booking}
              company={company}
              hideReplaceInfo={true}
            />
          )}
        </div>

        <div className="my-4 bg-danger text-center text-white font-semibold p-2">
          ⚠️ {t({ id: 'page16.booking.timeRemaining' }, { time: 9 })}
        </div>
      </PageContainer>
      <FooterActions
        onCancel={() => deleteBookingConfirm()}
        onSubmit={onSubmit}
        onBack={() => goBack()}
      />
    </AppPage>
  );
};
