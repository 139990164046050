import React, { ComponentType, useCallback, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AppLoader } from '_01_CORE/_components-core';
import { useAppRouter } from '_01_CORE/_hooks';
import { AppRouteArgsAuth } from './AppRouteArgs.model';
import { AppRouteGuard } from './guard';
import { useAppRoute } from './useAppRoute.hook';

export const AppRoute = React.memo(function AppRoute({
  component,
  render,
  auth,
  routeGuards,
}: {
  exact?: boolean;
  sensitive?: boolean;
  strict?: boolean;
  component?: ComponentType<RouteComponentProps<any>> | ComponentType<any>;
  render?: () => JSX.Element;
  auth?: AppRouteArgsAuth;
  routeGuards?: AppRouteGuard[];
  navigationContext?: any;
}) {
  const { _loaded, _error, redirectUrl } = useAppRoute({
    auth,
    routeGuards,
  });

  const appRouter = useAppRouter();

  useMemo(() => {
    if (redirectUrl) {
      appRouter.navigate(redirectUrl, { cause: 'app-route-redirect' });
    }
  }, [appRouter, redirectUrl]);

  const renderCustom = useCallback(() => {
    return render
      ? render()
      : React.createElement(component, {
          // navigationContext,
          // ...props, // don't pass router props: https://github.com/ionic-team/ionic-framework/issues/21635
        });
  }, [component, render]);

  return _error ? (
    <div>ERROR</div>
  ) : !_loaded ? (
    <AppLoader className="my-10 mx-4 sm:mx-10 p-2 px-4" />
  ) : (
    renderCustom()
  );
});
