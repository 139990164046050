import {
  IonButton,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonList,
  IonRadio,
  IonRadioGroup,
  IonSelect,
  IonSelectOption,
  IonText,
  IonThumbnail,
  IonToggle,
} from '@ionic/react';
import {
  AppPage,
  FooterActions,
  HeaderToolbar,
  PageContainer,
} from '_01_CORE/_common-layout';
import {
  AppFormLabel,
  AppLoader,
  FormError,
  JourneyParticipantCard,
  dataGenerator,
  fakeDataGenerator,
  useAppModalContext,
} from '_01_CORE/_components-core';
import { AppInputIonic } from '_01_CORE/_components-core/AppInput';
import { AppInputPhotoRHFControl } from '_01_CORE/_components-core/AppInputPhoto/AppInputPhotoRHFControl';
import {
  useAppRouter,
  useAppToasts,
  useMergeState,
  useOperationPending,
} from '_01_CORE/_hooks';
import {
  GenderBoy,
  GenderFemale,
  GenderGirl,
  GenderMale,
} from '_01_CORE/_icons';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import { journeyHelper } from '_01_CORE/_services/journeyHelper.service';
import {
  useCompany,
  useJourney,
  useJourneyEnabledActions,
} from '_01_CORE/app-contexts';
import { customerJourneyApiClient } from '_01_CORE/app-core/app-api';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import { usePhotoFromDevice } from '_01_CORE/app-core/app-platform';
import { differenceInYears } from 'date-fns';
import { build, trash } from 'ionicons/icons';
import {
  CMJourneyEditParticipant,
  COUNTRIES_ARRAY,
  COUNTRIES_MAP,
  CompanyExitPermitConfig,
  LANGUAGES_ARRAY,
  LANGUAGES_MAP,
} from 'lib-common-model';
import { appLogger } from 'lib-web-logger';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { AppInputDatePickerIonics } from 'stories/components/04-form';
import { JourneyParticipantEditFormData } from './JourneyParticipantEditFormData.type';
import { ParticipantAgeLabel } from './ParticipantAgeLabel';
import { useJourneyParticipantEditInitialState } from './useJourneyParticipantEditInitialState.hook';

export const JourneyEditParticipantEditAnimLivePage = () => {
  const appRouter = useAppRouter();

  const [persistInProgress, setPersistInProgress] = useState(false);

  const { formatMessage: t } = useIntl();
  const { customerJourneyId, customerChildId } = useParams<{
    customerJourneyId: string;
    customerChildId: string;
  }>();

  const { openModal, closeModal } = useAppModalContext();
  const { journey, participants } = useJourney({ customerJourneyId });

  const {
    companyReference,
    participantIndex: participantIndexString,
  } = useParams<{ companyReference: string; participantIndex: string }>();
  const { pushError } = useAppToasts();

  const { company } = useCompany({ companyReference: companyReference });
  const { takePhoto } = usePhotoFromDevice();

  const params = new URLSearchParams(window.location.search);
  const creationTimestamp = params.get('creationTimestamp');

  const [pageState, setMergedPageState] = useMergeState({});

  const totalParticipantsCount = useMemo(() => participants.length, [
    participants.length,
  ]);

  const participantIndex = useMemo(() => {
    if (creationTimestamp) {
      // HACK to add a creationTimestamp dependecy to hook
    }

    const participantIndex =
      participantIndexString !== undefined
        ? parseInt(participantIndexString, 10)
        : undefined;
    if (participantIndex === undefined) {
      return totalParticipantsCount;
    }
    return participantIndex;
  }, [creationTimestamp, participantIndexString, totalParticipantsCount]);

  const {
    mode,
    existingParticipant,
    journeyParticipant,
    defaultValues,
  } = useJourneyParticipantEditInitialState({
    participantIndex,
    customerChildId,
    customerJourneyId,
  });

  const {
    handleSubmit,
    formState,
    setValue,
    control,
    trigger,
    reset,
    clearErrors,
  } = useForm<JourneyParticipantEditFormData>({
    defaultValues,
    mode: 'onChange',
  });

  const errors = formState.errors;
  useEffect(() => {
    if (participantIndex !== undefined || creationTimestamp !== undefined) {
      // reset form if adding a participant immediatly (page does not change)
      reset(defaultValues);
      setTimeout(() => {
        // TODO Toub: je n'ai pas trouvé comment ne plus afficher les messages d'erreur: ceci ne suffit pas
        // control.updateFormState({
        //   isSubmitted: false,
        //   isDirty: false,
        // });
        clearErrors();
      }, 10);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, participantIndex, creationTimestamp, defaultValues]);

  const [birthDate, exitPermit] = useWatch({
    control,
    name: ['birthDate', 'exitPermit'],
  });
  const journeyAge = useMemo(
    () =>
      journeyHelper.getParticipantJourneyAge({
        journeyEndDateInclusive: journey.beginDateUTC,
        memberBirthDate: birthDate,
      }),
    [birthDate, journey.beginDateUTC]
  );

  const isMinor = useMemo<boolean>(() => !journeyAge || journeyAge < 18, [
    journeyAge,
  ]);

  const exitPermitConfig = useMemo<CompanyExitPermitConfig | undefined>(
    () =>
      company?.exitPermitConfigs?.find(
        (permit) =>
          permit.enabled &&
          journeyAge >= permit.minAge &&
          journeyAge <= permit.maxAge
      ),
    [company, journeyAge]
  );

  useEffect(() => {
    if (
      !exitPermit &&
      (!exitPermitConfig || exitPermitConfig?.type === 'exitPermit-allow')
    ) {
      setValue('exitPermit', true);
    }
  }, [exitPermit, exitPermitConfig, setValue]);
  function autoFillForm() {
    if (!defaultValues.lastName) {
      setValue('lastName', fakeDataGenerator.getLastName());
    }
    if (!defaultValues.firstName) {
      setValue('firstName', fakeDataGenerator.getFirstName());
    }
    if (!defaultValues.birthDate) {
      setValue(
        'birthDate',
        fakeDataGenerator.getBirthDate({ maxYears: 25 }) as any
      );
    }
    if (!defaultValues.gender) {
      setValue('gender', fakeDataGenerator.getGender());
    }
    if (!defaultValues.country) {
      setValue('country', fakeDataGenerator.getCountry().id);
    }
    if (!defaultValues.language) {
      setValue('language', fakeDataGenerator.getLanguage().id);
    }
    if (!defaultValues.exitPermit) {
      setValue('exitPermit', dataGenerator.boolean());
    }
    if (!defaultValues.imageRights) {
      setValue('imageRights', dataGenerator.boolean());
    }
    if (!defaultValues.swim) {
      setValue('swim', dataGenerator.boolean());
    }
  }
  const { isAddJourneyParticipantEnabled } = useJourneyEnabledActions({});
  /*
   * Submit form callback
   */
  const onSubmit = useOperationPending(
    async (data: JourneyParticipantEditFormData) => {
      if (data) {
        setPersistInProgress(true);
        try {
          const participant: CMJourneyEditParticipant = {
            journeyParticipantId: existingParticipant?.journeyParticipantId,
            customerChildId: existingParticipant?.customerChildId,
            member: {
              firstName: data.firstName,
              lastName: data.lastName,
              gender: data.gender,
              birthDate: new Date(data.birthDate),
              isExactBirthDate: true,
              country: data.country,
              language1: data.language,
              photo: data.photo,
              memberComment: data.memberComment,
            },
            attributes: {
              exitPermit: data.exitPermit,
              imageRights: data.imageRights,
              swim: data.swim,
              comment: data.journeyComment,
              journeyAge,
            },
            enabled: true,
          };

          if (existingParticipant?.journeyParticipantId) {
            const { result } = await customerJourneyApiClient.updateParticipant(
              {
                customerJourneyId,
                participant,
              }
            );
            if (result === 'success') {
              appRouter.navigate(
                appRoutesBuilder.getJourneyEditExistingHomeUrl({
                  customerJourneyId,
                }),
                {
                  cause: 'journey-edit-participant-create-submit',
                }
              );
            } else {
              // TODO show message
            }
          } else {
            const { result } = await customerJourneyApiClient.createParticipant(
              {
                customerJourneyId,
                participant,
              }
            );
            if (result === 'success') {
              appRouter.navigate(
                appRoutesBuilder.getJourneyEditExistingHomeUrl({
                  customerJourneyId,
                }),
                {
                  cause: 'journey-edit-participant-create-submit',
                }
              );
            } else {
              // TODO show message
            }
          }
        } finally {
          setPersistInProgress(false);
        }
      }
    },
    [
      appRouter,
      customerJourneyId,
      existingParticipant?.customerChildId,
      existingParticipant?.journeyParticipantId,
      journeyAge,
    ]
  );

  const goBack = useCallback(() => {
    appRouter.navigate(
      appRoutesBuilder.getJourneyEditExistingHomeUrl({ customerJourneyId }),
      {
        cause: 'journey-edit-participant-create-cancel',
      }
    );
  }, [appRouter, customerJourneyId]);

  const isFormValid = async () => {
    const isValid = await trigger();
    if (!isValid) {
      pushError(t({ id: 'common.message.error.invalid-form' }));
    }
    return isValid;
  };

  const onFinishClick = async () => {
    if (!(await isFormValid())) {
      appLogger.debug('Invalid form', control);
      return;
    }
    handleSubmit((data) => onSubmit(data))();
  };

  const deleteParticipant = useOperationPending(async () => {
    await customerJourneyApiClient.deleteParticipant({
      customerJourneyId,
      journeyParticipantId: journeyParticipant?.journeyParticipantId,
    });
    // always redirect
    appRouter.navigate(
      appRoutesBuilder.getJourneyEditExistingHomeUrl({ customerJourneyId }),
      {
        cause: 'journey-edit-participant-create-submit',
      }
    );
  }, [appRouter, customerJourneyId, journeyParticipant?.journeyParticipantId]);

  const openDeleteParticipantConfirmationModal = useCallback(() => {
    openModal({
      title: t({ id: 'modal.confirmation' }),
      content: <div>{t({ id: 'page12.deletion.confirm' })}</div>,
      actions: (
        <div className="float-right">
          <IonButton color="secondary" onClick={closeModal}>
            {t({ id: 'common.action.cancel' })}
          </IonButton>
          <IonButton
            color="primary"
            onClick={async () => {
              await deleteParticipant();
              closeModal();
            }}
          >
            {t({ id: 'common.action.ok' })}
          </IonButton>
        </div>
      ),
    });
  }, [openModal, t, closeModal, deleteParticipant]);

  const openConfirmImageRightsModal = useCallback(() => {
    openModal({
      title: t({ id: 'modal.confirmation' }),
      content: (
        <div>
          <div className="mb-2">{t({ id: 'page12.imageRights.confirm' })}</div>
          <div className="font-light">
            {t({ id: 'page12.imageRights.confirm.details' })}
          </div>
        </div>
      ),
      actions: (
        <div>
          <IonButton color="secondary" onClick={closeModal}>
            {t({ id: 'common.action.cancel' })}
          </IonButton>
          <IonButton
            color="primary"
            onClick={() => {
              setValue('imageRights', true);
              // onChange(true);
              closeModal();
            }}
          >
            {t({ id: 'common.action.ok' })}
          </IonButton>
        </div>
      ),
    });
  }, [closeModal, openModal, setValue, t]);

  const exitPermitClass =
    exitPermitConfig?.type === 'exitPermit-allow'
      ? 'text-success'
      : exitPermitConfig?.type === 'exitPermit-ask'
      ? 'text-warning'
      : 'text-red-700';

  const genderTextMale = useMemo(
    () =>
      isMinor
        ? t({ id: 'page12.gender.boy' })
        : t({ id: 'page12.gender.male' }),
    [isMinor, t]
  );
  const genderTextFemale = useMemo(
    () =>
      isMinor
        ? t({ id: 'page12.gender.girl' })
        : t({ id: 'page12.gender.female' }),
    [isMinor, t]
  );

  const participantTitle = useMemo<string>(() => {
    const tagLine = [];
    if (pageState.firstName) {
      tagLine.push(pageState.firstName);
    }
    if (pageState.lastName) {
      tagLine.push(pageState.lastName);
    }
    if (pageState.age > 0) {
      tagLine.push(`(${t({ id: 'page12.yearsOld' }, { age: pageState.age })})`);
    }
    return tagLine.join(' ');
  }, [pageState.firstName, pageState.lastName, pageState.age, t]);

  const isParticipantEditLimited = useMemo(
    () =>
      journeyParticipant &&
      journeyParticipant.attributes.totalAttendances +
        journeyParticipant.attributes.totalPreRegistrations !==
        0,
    [journeyParticipant]
  );

  return journey && company ? (
    <AppPage displayFooter={false}>
      <HeaderToolbar hideLogout={true} />
      <PageContainer className="text-center bg-gray-50" spacing="large">
        {persistInProgress ? (
          <AppLoader className="my-4 p-2 px-4" type="persist" />
        ) : (
          <>
            {' '}
            {/* Participant N#... */}
            <IonItemDivider>
              {t(
                { id: 'page12.participant' },
                { number: participantIndex + 1 }
              )}{' '}
              {participantTitle ? ' - ' : ''} {participantTitle}
              <div className="absolute right-8 text-2xl">
                {appWebConfig().envId === 'dev' && (
                  <IonIcon
                    className="mr-2"
                    color="primary"
                    icon={build}
                    onClick={() => autoFillForm()}
                  />
                )}
              </div>
            </IonItemDivider>
            <form
              onSubmit={(e) => {
                // quand on clique sur les flèches de navigation des mois des calendrier, le "onSubmit est appelé!", donc en attendant de revoir ça, on stop la propagation ici
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <IonList className="text-left px-2">
                {isParticipantEditLimited && (
                  <JourneyParticipantCard
                    customerJourneyId={journey._id}
                    participant={journeyParticipant}
                    className="py-2"
                  />
                )}
                <div hidden={isParticipantEditLimited}>
                  <IonItemGroup>
                    {/* Last name */}
                    <Controller
                      render={({ field: { value, onChange } }) => (
                        <AppInputIonic
                          required={true}
                          placeholder={t({ id: 'page12.lastName' })}
                          value={value}
                        >
                          <IonInput
                            autoCapitalize="on"
                            onIonChange={(e) => (
                              onChange(e),
                              setMergedPageState({ lastName: e.detail.value })
                            )}
                          />
                        </AppInputIonic>
                      )}
                      control={control}
                      name="lastName"
                      defaultValue=""
                      rules={{
                        required: {
                          value: true,
                          message: t({ id: 'validation.required' }),
                        },
                      }}
                    />
                    <FormError error={errors.lastName?.message} />

                    {/* First name */}
                    <Controller
                      render={({ field: { value, onChange } }) => (
                        <AppInputIonic
                          className="mt-2"
                          required={true}
                          placeholder={t({ id: 'page12.firstName' })}
                          value={value}
                        >
                          <IonInput
                            autoCapitalize="on"
                            onIonChange={(e) => (
                              onChange(e),
                              setMergedPageState({ firstName: e.detail.value })
                            )}
                          />
                        </AppInputIonic>
                      )}
                      control={control}
                      name="firstName"
                      defaultValue=""
                      rules={{
                        required: {
                          value: true,
                          message: t({ id: 'validation.required' }),
                        },
                      }}
                    />
                    <FormError error={errors.firstName?.message} />
                  </IonItemGroup>

                  {/* Birth Date (2 columns) */}
                  <div className="flex items-center gap-2 mt-2">
                    <Controller
                      control={control}
                      name="birthDate"
                      // defaultValue=""
                      rules={{
                        required: {
                          value: true,
                          message: t({ id: 'validation.required' }),
                        },
                      }}
                      render={({ field: { value, onChange } }) => (
                        <AppInputDatePickerIonics
                          placeholder={t({ id: 'page12.birthDate' })}
                          initialView={value ? 'weeks' : 'years'}
                          value={value}
                          minDate={new Date(1900, 1, 1)}
                          maxDate={new Date()}
                          onChange={(e) => {
                            onChange(e);
                            if (e) {
                              setMergedPageState({
                                age: differenceInYears(
                                  new Date(journey?.beginDateUTC),
                                  new Date(e)
                                ),
                                journeyAge: differenceInYears(
                                  new Date(journey?.endDateInclusiveUTC),
                                  new Date(e)
                                ),
                              });
                            }
                          }}
                        />
                      )}
                    />

                    <ParticipantAgeLabel
                      journeyAge={journeyAge}
                      memberAge={pageState.age}
                    />
                  </div>
                  <FormError error={errors.birthDate?.message} />
                </div>
                <div className={pageState.age < 0 ? 'hidden' : ''}>
                  {!isParticipantEditLimited && (
                    <>
                      {/* Gender */}
                      <Controller
                        render={({ field: { value, onChange } }) => {
                          const classMale = `w-9 h-9 ${
                            value === 'male' ? 'text-blue-500' : ''
                          }`;
                          const classFemale = `w-9 h-9 ${
                            value === 'female' ? 'text-pink-500' : ''
                          }`;
                          return (
                            <IonRadioGroup onIonChange={onChange} value={value}>
                              <div className="grid grid-cols-1 mm:grid-cols-2 items-center gap-2 mt-2">
                                <IonItem className="bg-white rounded-full border-2 border-input">
                                  {isMinor ? (
                                    <GenderBoy className={classMale} />
                                  ) : (
                                    <GenderMale className={classMale} />
                                  )}
                                  <IonLabel className="pl-4">
                                    {genderTextMale}
                                  </IonLabel>
                                  <IonRadio
                                    slot="start"
                                    value="male"
                                    className="mr-3"
                                  />
                                </IonItem>
                                <IonItem className="bg-white rounded-full border-2 border-input">
                                  {isMinor ? (
                                    <GenderGirl className={classFemale} />
                                  ) : (
                                    <GenderFemale className={classFemale} />
                                  )}
                                  <IonLabel className="pl-4">
                                    {genderTextFemale}
                                  </IonLabel>
                                  <IonRadio
                                    slot="start"
                                    value="female"
                                    className="mr-3"
                                  />
                                </IonItem>
                              </div>
                            </IonRadioGroup>
                          );
                        }}
                        control={control}
                        name="gender"
                        defaultValue={'' as any}
                        rules={{
                          required: {
                            value: true,
                            message: t({ id: 'validation.required' }),
                          },
                        }}
                      />
                      <FormError error={errors.gender?.message} />{' '}
                    </>
                  )}
                  {/* Country */}
                  <div className="mt-2">
                    <Controller
                      render={({ field: { value, onChange } }) => (
                        <AppInputIonic
                          required={true}
                          placeholder={t({ id: 'page12.country' })}
                          value={value}
                        >
                          <div className="flex w-full items-center">
                            {COUNTRIES_MAP[value] && (
                              <IonThumbnail
                                slot="start"
                                className="w-4 h-4 ml-2"
                              >
                                <IonImg
                                  className="rounded-full"
                                  src={COUNTRIES_MAP[value].imgUrl}
                                />
                              </IonThumbnail>
                            )}
                            <IonSelect
                              interface="action-sheet"
                              okText={t({ id: 'common.action.ok' })}
                              cancelText={t({ id: 'common.action.cancel' })}
                              placeholder={t({ id: 'page12.country' }) + ' *'}
                              onIonChange={(e) => {
                                onChange(e);
                                // appLogger.debug(COUNTRIES_MAP[e.detail.value]);
                                if (
                                  COUNTRIES_MAP[e.detail.value]?.defaultLanguage
                                ) {
                                  setValue(
                                    'language',
                                    COUNTRIES_MAP[e.detail.value]
                                      ?.defaultLanguage
                                  );
                                }
                              }}
                              value={value}
                            >
                              {COUNTRIES_ARRAY.map((country) => (
                                <IonSelectOption
                                  key={`country-${country.id}`}
                                  value={country.id}
                                >
                                  {t({ id: `country.${country.id}` })}
                                </IonSelectOption>
                              ))}
                            </IonSelect>
                          </div>
                        </AppInputIonic>
                      )}
                      control={control}
                      name="country"
                      defaultValue={'' as any}
                      rules={{
                        required: {
                          value: true,
                          message: t({ id: 'validation.required' }),
                        },
                      }}
                    />
                  </div>
                  <FormError error={errors.country?.message} />
                  {/* Language */}
                  <div className="mt-2">
                    <Controller
                      render={({ field: { value, onChange } }) => (
                        <AppInputIonic
                          required={true}
                          placeholder={t({ id: 'page12.language' })}
                          value={value}
                        >
                          <div className="flex w-full items-center">
                            {LANGUAGES_MAP[value] && (
                              <IonThumbnail slot="start" className="w-8 h-6">
                                <IonImg
                                  className="rounded-full ml-2"
                                  src={LANGUAGES_MAP[value].imgUrl}
                                />
                              </IonThumbnail>
                            )}
                            <IonSelect
                              interface="action-sheet"
                              okText={t({ id: 'common.action.ok' })}
                              cancelText={t({ id: 'common.action.cancel' })}
                              placeholder={t({ id: 'page12.language' }) + ' *'}
                              onIonChange={onChange}
                              value={value}
                            >
                              {LANGUAGES_ARRAY.map((language) => (
                                <IonSelectOption
                                  key={`country-${language.id}`}
                                  value={language.id}
                                >
                                  {language.labelOriginal}
                                </IonSelectOption>
                              ))}
                            </IonSelect>
                          </div>
                        </AppInputIonic>
                      )}
                      control={control}
                      name="language"
                      defaultValue={'' as any}
                      rules={{
                        required: {
                          value: true,
                          message: t({ id: 'validation.required' }),
                        },
                      }}
                    />
                  </div>
                  <FormError error={errors.language?.message} />
                  <AppFormLabel
                    required={false}
                    className={'my-4 form-label pl-1'}
                    label={`Avatar`}
                    hasError={false}
                    disabled={false}
                  >
                    <AppInputPhotoRHFControl
                      control={control}
                      // label={t({ id: 'common.form.photo' })}
                      name={`photo`}
                      url={`/children/images`}
                      preview={{
                        size: 'small',
                        onDeleteConfirmMessage: t({
                          id: 'page12.avatar.confirm',
                        }),
                      }}
                    />
                  </AppFormLabel>
                  {/* Image right */}
                  <IonItem>
                    <Controller
                      render={({ field: { value, onChange } }) => (
                        <IonToggle
                          color="primary"
                          onIonChange={(e) => {
                            if (e.detail.checked !== value) {
                              if (e.detail.checked) {
                                openConfirmImageRightsModal();
                              } else {
                                control;
                                onChange(false);
                              }
                            }
                          }}
                          checked={value}
                        />
                      )}
                      control={control}
                      name="imageRights"
                      defaultValue={false}
                    />
                    <IonLabel>{t({ id: 'page12.imageRights' })}</IonLabel>
                  </IonItem>
                  <div className={!isMinor ? 'hidden' : ''}>
                    {/* Can swim */}
                    <IonItem>
                      <Controller
                        render={({ field: { value, onChange } }) => (
                          <IonToggle
                            color="primary"
                            onIonChange={(e) => onChange(e.detail.checked)}
                            checked={value}
                          />
                        )}
                        control={control}
                        name="swim"
                        defaultValue={false}
                      />
                      <IonLabel>{t({ id: 'page12.canSwim' })}</IonLabel>
                    </IonItem>

                    {/* Exit permission */}
                    <div className={!exitPermitConfig ? 'hidden' : ''}>
                      <p className={`${exitPermitClass} p-4`}>
                        {exitPermitConfig?.text}
                      </p>
                      <IonItem>
                        <Controller
                          render={({ field: { value, onChange } }) => (
                            <IonToggle
                              color="primary"
                              onIonChange={(e) => onChange(e.detail.checked)}
                              checked={value}
                              disabled={
                                exitPermitConfig?.type === 'exitPermit-deny' ||
                                exitPermitConfig?.type === 'exitPermit-allow'
                              }
                            />
                          )}
                          control={control}
                          name="exitPermit"
                          defaultValue={false}
                        />
                        <IonLabel>
                          {t({ id: 'page12.exitPermitAuthorize' })}
                        </IonLabel>
                      </IonItem>
                    </div>

                    {/* Observations, allergies... */}
                    <IonItem>
                      <Controller
                        render={({ field: { value, onChange } }) => (
                          <IonInput
                            value={value}
                            placeholder={t({ id: 'page12.memberComment' })}
                            onIonChange={onChange}
                          />
                        )}
                        control={control}
                        name="memberComment"
                        defaultValue=""
                      />
                    </IonItem>

                    {/* Journey specific memberComment */}
                    <IonItem>
                      <Controller
                        render={({ field: { value, onChange } }) => (
                          <IonInput
                            value={value}
                            placeholder={t({ id: 'page12.journeyComment' })}
                            onIonChange={onChange}
                          />
                        )}
                        control={control}
                        name="journeyComment"
                        defaultValue=""
                      />
                    </IonItem>
                  </div>
                  {mode === 'create' && (
                    <div className="text-center p-4 bg-gray-100">
                      {' '}
                      {isAddJourneyParticipantEnabled && (
                        <IonText
                          className="block font-bold mt-2"
                          color="tertiary"
                        >
                          ⚠️ {t({ id: 'page12.warning.addAllParticipants' })}
                        </IonText>
                      )}
                      <IonText className="italic" color="tertiary">
                        {t({ id: 'page12.warning.details' })}
                      </IonText>
                    </div>
                  )}
                </div>
                {mode === 'edit' && !isParticipantEditLimited && (
                  <div className="mt-12 mb-4 text-center">
                    <IonButton
                      color="danger"
                      onClick={() => openDeleteParticipantConfirmationModal()}
                    >
                      <IonIcon className="mr-1" icon={trash} />{' '}
                      {t({ id: 'common.action.delete.participant' })}
                    </IonButton>
                  </div>
                )}
                {/* ajout de 100 pixels sinon le date picker ne s'affiche pas bien */}
                <div style={{ height: 100 }}></div>
              </IonList>
            </form>
          </>
        )}
      </PageContainer>
      {!persistInProgress && (
        <FooterActions
          onCancel={goBack}
          onBack={undefined}
          onSubmit={onFinishClick}
        />
      )}
    </AppPage>
  ) : null;
};
