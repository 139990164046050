import { ActivitySessionOpeningStatus } from 'lib-common-model';
import React from 'react';
import { useIntl } from 'react-intl';
import { SessionRelativeTimeState } from './SessionRelativeTimeState.type';

export const ActivityTitleCardStatus = ({
  openingStatus,
  relativeTimeState,
  className,
}: {
  openingStatus: ActivitySessionOpeningStatus;
  relativeTimeState: SessionRelativeTimeState;
  className?: string;
}) => {
  const { formatMessage: t } = useIntl();

  return openingStatus === 'cancelled' || openingStatus === 'close' ? (
    <div
      className={`text-center text-app-xxs1 uppercase whitespace-nowrap ${
        openingStatus === 'cancelled'
          ? 'text-red-600'
          : openingStatus === 'close'
          ? 'text-gray-600'
          : 'text-gray-800'
      } ${
        relativeTimeState === 'past' &&
        (openingStatus === 'cancelled' || openingStatus === 'close')
          ? 'opacity-50'
          : ''
      } ${className}`}
    >
      {t({
        id: `common.activity-session.opening-status.by-id.${openingStatus}`,
      }).toUpperCase()}
    </div>
  ) : null;
};
