import { IonItem, IonLabel, IonRadio } from '@ionic/react';
import { Gender } from 'lib-common-model';
import React from 'react';
import { useIntl } from 'react-intl';
import { CustomerGenderIcon } from '_01_CORE/_components-core/ParticipantAvatar';
import { useCustomerGenderText } from './useCustomerGenderText.hook';

export const CustomerGenderRadioGroupOption = ({
  gender,
  isMinor,
  isSelected,
  className,
}: {
  gender?: Gender;
  isMinor: boolean;
  isSelected: boolean;
  className?: string;
}) => {
  const { formatMessage: t } = useIntl();

  const genderText = useCustomerGenderText({
    gender,
    isMinor,
  });

  return (
    <IonItem
      className={`bg-white rounded-full border-2 border-input ${className}`}
    >
      {gender === 'other' || gender === 'prefer-not-to-say' ? null : (
        <CustomerGenderIcon
          isMinor={isMinor}
          gender={gender}
          isSelected={isSelected}
          className={''}
        />
      )}
      <IonLabel className="pl-2 text-xs flex-wrap">{genderText}</IonLabel>
      <IonRadio slot="start" value={gender} className="mr-1" />
    </IonItem>
  );
};
