import { AppPriceLabel } from '_01_CORE/_common-layout';
import { AppIcons, dateTransformer } from '_01_CORE/_components-core';
import { useAppImageUrl } from '_01_CORE/app-core/app-security/useAppImageUrl.hook';
import {
  APP_CURRENCIES_MAP,
  EcommercePurchaseOffer,
  EcommercePurchaseOfferActivitySessionParticipantMeta,
} from 'lib-common-model';
import { useIntl } from 'react-intl';

export const AccroparkBookingResumeTableOfferRow = ({
  offer,
}: {
  offer: EcommercePurchaseOffer;
}) => {
  const { formatMessage: t } = useIntl();

  const appCurrency = APP_CURRENCIES_MAP[offer?.currency];
  const currencySymbol = appCurrency?.symbol;
  const meta: EcommercePurchaseOfferActivitySessionParticipantMeta = offer.meta;

  const offerLogoUrl = useAppImageUrl(meta.activity.logo, {
    defaultImage: 'activity',
  });

  const peopleQuantityPriceLabelKey =
    offer.type === 'activity-session-product'
      ? 'accropark.ecommerce.booking-card.quantity-price-unit-type-product'
      : 'accropark.ecommerce.booking-card.quantity-price-unit-type-participant';

  return (
    offer && (
      <div className="flex space-x-2 py-1 px-2 items-center border-b">
        {(offer.type === 'activity-session' ||
          offer.type === 'activity-session-product') && (
          <>
            <img src={offerLogoUrl} className="w-12 h-12" />
            <div className="flex-1">
              <div>
                {meta.activity.name}
                {meta?.priceRange?.minAge != null &&
                  meta?.priceRange?.maxAge != null && (
                    <>
                      (
                      {t(
                        { id: 'page12.yearsOld' },
                        {
                          age: `${meta?.priceRange?.minAge}-${meta?.priceRange?.maxAge}`,
                        }
                      )}
                      )
                    </>
                  )}{' '}
                -{' '}
                {dateTransformer.formatTime(meta.activitySession.beginDateTime)}
              </div>
              <div>
                {t(
                  { id: peopleQuantityPriceLabelKey },
                  {
                    qty: offer.quantity,
                    price: `${offer.price} ${currencySymbol}`,
                  }
                )}
                {offer.discountPercentage > 0 && (
                  <span className="inline-block ml-2 text-xs text-app-success font-bold">
                    {' '}
                    (-{offer.discountPercentage}%)
                  </span>
                )}
              </div>
            </div>
          </>
        )}{' '}
        {offer.type === 'weather-insurance' && (
          <>
            <img src="/assets/images/sun.png" className="w-12 h-12" />
            <div className="flex-1">
              <div>
                {t({
                  id:
                    'accropark.ecommerce.booking-card.offer.weather-insurance',
                })}
              </div>
              <div>
                {t(
                  { id: peopleQuantityPriceLabelKey },
                  {
                    qty: offer.quantity,
                    price: `${offer.price} ${currencySymbol}`,
                  }
                )}
              </div>
            </div>
          </>
        )}
        {offer.type === 'gift-card' && (
          <>
            <AppIcons.giftCard className="w-12 h-12" />
            <div className="flex-1">
              <div>
                {t({
                  id: 'accropark.ecommerce.booking-card.offer.gift-card',
                })}
              </div>
            </div>
          </>
        )}
        {offer.type === 'booking-update-fee' && (
          <>
            <div className="ml-14 flex-1">
              <div>
                {t({ id: 'accropark.ecommerce.booking-card.edit-journey-fee' })}
              </div>
              <div>
                {t(
                  { id: peopleQuantityPriceLabelKey },
                  {
                    qty: offer.quantity,
                    price: `${offer.price} ${currencySymbol}`,
                  }
                )}
              </div>
            </div>
          </>
        )}
        <div className="self-end">
          <AppPriceLabel
            amount={offer.totalPrice}
            appCurrency={appCurrency}
            addSpacesToLargeNumbers={true}
          />
        </div>
      </div>
    )
  );
};
