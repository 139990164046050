import { base64FromPath } from '@capacitor-community/filesystem-react';
import Pica from 'pica';

export const imageConverter = {
  resizeImage,
};
const pica = new Pica();

async function resizeImage({
  webPath,
  format = 'jpeg',
  maxWidth: MAX_WIDTH,
  maxHeight: MAX_HEIGHT,
}: {
  webPath: string;
  format: string; // 'jpeg' | 'png' | 'gif';
  maxWidth: number;
  maxHeight: number;
}) {
  if (webPath) {
    const originalWeight = await getFileSize(webPath);
    let finalWeight = originalWeight;

    const image: HTMLImageElement = new Image();

    image.src = webPath; // webPath;

    const dimensions: { width: number; height: number } = await new Promise(
      function (resolved, rejected) {
        try {
          image.addEventListener('load', () => {
            const { width, height, sizes } = image;
            resolved({ width, height });
          });
          image.addEventListener('error', (err) => {
            console.log('Error loading image', err);
            rejected(err);
          });

          image.src = webPath;
        } catch (err) {
          console.log('Error loading image:', err);
          rejected(err);
        }
      }
    );

    let { width, height } = dimensions;

    let base64Data: string;
    let webviewPath: string;
    let blob: Blob;

    if (width > MAX_WIDTH || height > MAX_HEIGHT) {
      const image2 = document.createElement('canvas', {});

      const maxWidth = Math.min(MAX_WIDTH, (width / height) * MAX_HEIGHT);
      const maxHeight = Math.min(MAX_HEIGHT, (height / width) * MAX_WIDTH);
      if (width > maxWidth) {
        height = Math.round((height / width) * maxWidth);
        width = maxWidth;
      }
      if (height > maxHeight) {
        width = Math.round((width / height) * maxHeight);
        height = maxHeight;
      }
      image2.width = width;
      image2.height = height;

      const image3 = await pica.resize(image, image2, {
        unsharpAmount: 160,
        unsharpRadius: 0.6,
        unsharpThreshold: 1,
      });

      base64Data = image3.toDataURL(format, 1);
      blob = await fetch(base64Data).then((r) => r.blob());
      finalWeight = Math.round(blob.size / 1024);
    } else {
      // pas besoin de redimensionner
      // TODO: on pourrait faire ça juste avec un canvas?
      base64Data = await base64FromPath(webPath);
      blob = await fetch(webPath).then((r) => r.blob());
      const sizeInKB = Math.round(blob.size / 1024);
      console.log(`The final blob size is ${sizeInKB} KB`);
      webviewPath = webPath;
    }

    // NOTE (vérifier car sharp v0.30 que l'on utilise côté api supporte désormais le gif): le gif n'est pas supporté par sharp sauf en installant une librairie native, donc il faudrait le bloquer côté client: https://github.com/lovell/sharp/issues/2437
    const fileName = new Date().getTime() + format; // 'jpeg'| ('png'|'gif' => web only)
    const res = {
      filepath: fileName,
      webviewPath,
      base64: base64Data,
      blob,
      originalWeight,
      finalWeight,
    };

    return res;
  }
}

async function getFileSize(webPath: string): Promise<number> {
  try {
    const response = await fetch(webPath);
    const blob = await response.blob();
    const sizeInKB = Math.round(blob.size / 1024);
    return sizeInKB;
  } catch (err) {
    console.error('Error getting file size', err);
  }
}
