import { AppSecurityUser, AuthUserRole } from 'lib-common-model';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { authenticationStore } from './authenticationStore.service';

export const authenticationChecker = {
  hasRequiredRole,
  hasRequiredRoles,
  isAuthorized,
};

function isAuthorized({
  requiredRoles,
}: {
  requiredRoles: AuthUserRole[];
}): Observable<boolean> {
  if (requiredRoles) {
    return authenticationStore.auth.get().pipe(
      map((auth) => {
        if (!hasRequiredRoles(auth.securityUser, requiredRoles)) {
          return false;
        }
        return true;
      })
    );
  }
  return of(true);
}

function hasRequiredRoles(
  payload: Pick<AppSecurityUser, 'roles'>,
  requiredRoles: AuthUserRole[]
): boolean {
  return (
    !!payload &&
    !!payload.roles &&
    requiredRoles.find(
      (requiredRole) => !hasRequiredRole(payload, requiredRole)
    ) === undefined
  );
}

function hasRequiredRole(
  payload: Pick<AppSecurityUser, 'roles'>,
  requiredRole: AuthUserRole
): boolean {
  return (
    !!payload && !!payload.roles && payload.roles.indexOf(requiredRole) !== -1
  );
}
