import { useEffect, useState } from 'react';
import { AuthMultiLoginAnimLivePageViewState } from './_model';
import { useAuthPageMultiLoginCheckToken } from './check-token';
import {
  AuthMultiLoginAnimLivePageLocalStateActions,
  useAuthMultiLoginAnimLivePageLocalStateActions,
} from './useAuthMultiLoginAnimLivePageLocalStateActions.hook';

export function useAuthMultiLoginAnimLivePageLocalState(
  initialViewState: AuthMultiLoginAnimLivePageViewState
) {
  const baseLocalState = useBaseLocalState(initialViewState);

  const actions: AuthMultiLoginAnimLivePageLocalStateActions = useAuthMultiLoginAnimLivePageLocalStateActions(
    baseLocalState
  );

  const localState: AuthMultiLoginAnimLivePageLocalState = {
    ...baseLocalState,
    actions,
  };

  return localState;
}

function useBaseLocalState(
  initialViewState: AuthMultiLoginAnimLivePageViewState
) {
  const [
    viewState,
    setViewState,
  ] = useState<AuthMultiLoginAnimLivePageViewState>(initialViewState);

  const checkToken = useAuthPageMultiLoginCheckToken({
    viewState,
    setViewState,
  });

  useEffect(() => {
    if (viewState?.checkToken?.state === 'prompt') {
      checkToken();
    }
  }, [checkToken, viewState?.checkToken?.state]);

  const baseLocalState = {
    viewState,
    setViewState,
  };
  return baseLocalState;
}

export type AuthMultiLoginAnimLivePageBaseLocalState = ReturnType<
  typeof useBaseLocalState
>;

export type AuthMultiLoginAnimLivePageLocalState = AuthMultiLoginAnimLivePageBaseLocalState & {
  actions: AuthMultiLoginAnimLivePageLocalStateActions;
};
