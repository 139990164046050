import { ResponsiveImage } from 'lib-common-model';
import { appWebConfig } from '_01_CORE/app-core/app-config';

export const imageUrlBuilder = {
  getImageFullUrl,
  getImageCompanyFullUrl,
};

/**
 * Get company image
 * @param image
 */
function getImageCompanyFullUrl({ image }: { image: ResponsiveImage }) {
  return getImageFullUrl({
    image: image?.md,
    defaultUrl: '/assets/company/company-journey.jpg',
  });
}

function getImageFullUrl({
  image,
  defaultUrl,
}: {
  image: string;
  defaultUrl?: string;
}) {
  if (!image) {
    return defaultUrl;
  }
  return appWebConfig().urls.cdnUpload + image;
}
