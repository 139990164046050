import { AppEnvId, AppId } from 'lib-common-model';
import {
  AppRemoteEnvId,
  AppWebConfigURLs,
  appWebConfigCommonBuilder,
  configUrlBuilder,
} from 'lib-web-common';
import { appLogger } from 'lib-web-logger';
import { MobileCustomerConfigFeature } from './model';
import { MobileCustomerConfig } from './model/MobileCustomerConfig.type';

const container = {
  appWebConfig: undefined as MobileCustomerConfig,
};

export function resetAppWebConfig(overrideDefault?: {
  remoteEnvId: AppRemoteEnvId;
}) {
  let appId: AppId = (process.env.REACT_APP_ID as unknown) as AppId;

  let envId: AppEnvId = (process.env.REACT_APP_ENV_ID as unknown) as AppEnvId;

  console.log('[resetAppWebConfig] overrideDefault', overrideDefault);

  let remoteEnvId: AppRemoteEnvId = buildRemoteEnvId({
    overrideDefault,
    envId,
    appId,
  });
  console.log('[resetAppWebConfig] remoteEnvId', remoteEnvId);

  if (!envId || !appId) {
    const urlEnvs = configUrlBuilder.getEnvIdFromUrl();
    envId = urlEnvs.envId;
    appId = urlEnvs.appId;
    remoteEnvId = urlEnvs.remoteEnvId;
  }

  let appVersion = process.env.REACT_APP_ANIM_LIVE_VERSION;
  if (!appVersion) {
    appVersion = '5.61.20';
  }

  const sentryDsn =
    envId === 'dev'
      ? 'https://9fb30c73c822448e9f5fc3597f0b2241@o226381.ingest.sentry.io/5875356' // miniclub.board@gmail.com / dev-customer-mobile
      : appId === 'anim-live'
      ? 'https://d8fcef274c054ec0ab34e5e85b47c893@o226381.ingest.sentry.io/5875103' // miniclub.board@gmail.com / anim-live-customer-mobile
      : 'https://ed045f82fe634e7c9338ace67ad8f7da@o226381.ingest.sentry.io/1417305'; // miniclub.board@gmail.com / accropark-customer-mobile

  appLogger.warn(`[appWebConfig] REACT_APP_ID: ${process.env.REACT_APP_ID}`);
  appLogger.warn(`[appWebConfig] APP ENV: ${envId}/${remoteEnvId}`);

  const defaultAuthLogin = getDefaultAuthLogin(appId);

  const urls: AppWebConfigURLs = configUrlBuilder.buildUrls({
    remoteEnvId,
    values: {
      superAdminWeb: process.env.REACT_APP_CUSTOMERS_WEB_URL, // utilisé juste en dev pour surcharger l'url
      proWeb: process.env.REACT_APP_PRO_WEB_URL, // utilisé juste en dev pour surcharger l'url
      api: process.env.REACT_APP_API_URL, // utilisé juste en dev pour surcharger l'url
    },
  });

  appLogger.warn(`[appWebConfig] customers Web URL "${urls.superAdminWeb}"`);
  appLogger.warn(`[appWebConfig] API URL "${urls.api}"`);

  container.appWebConfig = {
    appId,
    authentication: {
      defaultAuthLogin,
    },
    ...appWebConfigCommonBuilder.buildCommonConfig({
      urls,
      sentryDsn,
      appVersion,
      envId,
      remoteEnvId,
    }),
    features: buildAppFeatures({ envId }),
  };
}

export const appWebConfig = () => {
  if (!container.appWebConfig) {
    resetAppWebConfig();
  }
  return container.appWebConfig;
};

export function buildRemoteEnvId({
  overrideDefault,
  envId,
  appId,
}: {
  overrideDefault?: { remoteEnvId: AppRemoteEnvId };
  envId: AppEnvId;
  appId: AppId;
}): AppRemoteEnvId {
  let remoteEnvId: AppRemoteEnvId =
    overrideDefault?.remoteEnvId ??
    ((process.env.REACT_APP_REMOTE_ENV_ID as unknown) as AppRemoteEnvId);
  if (!remoteEnvId) {
    remoteEnvId =
      envId === 'dev' ? (appId === 'anim-live' ? 'beta' : 'accro-beta') : envId;
  }
  return remoteEnvId;
}

function buildAppFeatures({ envId }: { envId: AppEnvId }) {
  const features: MobileCustomerConfigFeature[] = [];
  if (
    process.env.REACT_APP_FEATURES_AUTH !== 'false' /*&& envId !== 'alpha'*/
  ) {
    // (authentication was disabled on alpha: why?)
    features.push('authentication');
  }
  if (process.env.REACT_APP_FEATURES_WELCOME_BOOKLET !== 'false') {
    // welcome leaflet disabled on accropark prod (for now)
    features.push('welcome-booklet');
  }
  return features;
}

function getDefaultAuthLogin(appId: AppId) {
  if (appId === 'anim-live') {
    return {
      login: process.env.REACT_APP_DEFAULT_LOGIN_ANIM_LIVE,
      password: process.env.REACT_APP_DEFAULT_PASSWORD_ANIM_LIVE,
      companyCode: process.env.REACT_APP_DEFAULT_COMPANY_CODE_ANIM_LIVE,
    };
  } else if (appId === 'accropark') {
    return {
      login: process.env.REACT_APP_DEFAULT_LOGIN_ACCROPARK,
      password: process.env.REACT_APP_DEFAULT_PASSWORD_ACCROPARK,
      companyCode: process.env.REACT_APP_DEFAULT_COMPANY_CODE_ACCROPARK,
    };
  }
}
