import { useAppTheme } from '_01_CORE/_common-layout';
import clsx from 'clsx';
import { CMResto } from 'lib-common-model/entities/app-customer-mobile-web/CMResto';

export const CompanyRestoCardHeader = ({
  resto,
  companyReference,
  className,
}: {
  resto: CMResto;
  companyReference: string;
  className?: string;
}) => {
  const theme = useAppTheme({
    companyReference,
  });

  return (
    <div
      className={clsx(
        'bg-gray-50 relative flex flex-col justify-center overflow-hidden rounded-lg border border-gray-200',
        className
      )}
    >
      <div
        className="flex flex-1 flex-col space-y-2 p-2"
        style={{
          backgroundColor: theme.buttonPrimaryBgColor,
          color: theme.buttonPrimaryTextColor,
        }}
      >
        <h3 className="text-2xl font-bold uppercase text-gray-50">
          <span aria-hidden="true" className="absolute inset-0" />
          {resto.translation.name}
        </h3>
        <div className="text-left text-gray-100 flex flex-row-reverse gap-8 justify-between flex-wrap text-sm">
          <div className="flex gap-4 font-medium">
            {resto?.contactInformation?.phone && (
              <p className="">{resto?.contactInformation?.phone}</p>
            )}
            {/* {resto?.contactInformation?.email && (
              <p>{resto?.contactInformation?.email}</p>
            )} */}
          </div>
          {resto.translation.comment?.trim()?.length > 0 && (
            <p className="">{resto.translation.comment}</p>
          )}
        </div>
      </div>
      {resto.image?.original && (
        <div className="aspect-h-1 aspect-w-1 sm:aspect-none h-20 ml:h-28 sm:h-40">
          <img
            src={resto.image?.original}
            // alt={product.imageAlt}
            className="opacity-50 h-full w-full object-cover object-center sm:h-full sm:w-full"
          />
        </div>
      )}
    </div>
  );
};
