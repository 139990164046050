import { persistentCache } from '_01_CORE/app-core/app-platform';
import { authenticationClient } from '_01_CORE/app-core/app-security';
import {
  AuthUserProfile,
  CustomerMobileAuthenticationResult,
} from 'lib-common-model';
import { appLogger } from 'lib-web-logger';
import { Observable, from, of } from 'rxjs';
import { catchError, finalize, first, map, switchMap } from 'rxjs/operators';
import { JWT_TOKEN_BROWSER_STORAGE_ID } from './JWT_TOKEN_BROWSER_STORAGE_ID.const';

const ALLOWED_USER_PROFILES: AuthUserProfile[] = [
  'customer',
  'company',
  'super-admin',
  'temporary-token',
];

export const bootstrapAuthentication = () => {
  const forceLogout = testIfForceLogoutEnabled();

  const auth$: Observable<CustomerMobileAuthenticationResult> = forceLogout
    ? of(undefined)
    : tryToAuthenticateFromUrlParam().pipe(
        switchMap((auth) => {
          if (auth) {
            return of(auth);
          }
          return tryToAuthenticateFromStorage();
        })
      );

  return auth$.pipe(
    map((authResult: CustomerMobileAuthenticationResult) => {
      if (!authResult) {
        appLogger.debug('[bootstrapAuthentication] authResult not defined');

        authenticationClient.clearAppAuth();
      }
      return authResult;
    }),
    first(),
    catchError((err) => {
      console.error('xxx err:', err);
      return of(undefined);
    }),
    map((result) => (result ? result : false))
  );
};

function testIfForceLogoutEnabled() {
  const params = new URLSearchParams(window.location.search);
  const forceLogout = params.get('forceLogout');

  return forceLogout;
}

function tryToAuthenticateFromStorage() {
  return from(
    persistentCache.get(JWT_TOKEN_BROWSER_STORAGE_ID, { ignoreError: true })
  ).pipe(
    switchMap((token) => {
      if (token) {
        return from(
          authenticationClient.authenticateByToken(
            token,
            ALLOWED_USER_PROFILES,
            {
              context: 'tryToAuthenticateFromStorage',
            }
          )
        ).pipe(
          catchError((err) => {
            console.error(err);
            // invalid token: ignore it
            appLogger.debug(
              '[bootstrapAuthentication.tryToAuthenticateFromStorage] error invalid token:',
              {
                token,
              }
            );
            return of(undefined);
          })
        );
      }
      return of(undefined);
    })
  );
}

function tryToAuthenticateFromUrlParam() {
  return of('authenticationToken').pipe(
    switchMap((paramName) => {
      const params = new URLSearchParams(window.location.search);
      const token = params.get(paramName);

      if (token) {
        return from(
          authenticationClient.authenticateByToken(
            token,
            ALLOWED_USER_PROFILES,
            {
              context: 'tryToAuthenticateFromUrlParam',
            }
          )
        ).pipe(
          catchError(() => {
            // invalid token: ignore it
            appLogger.debug(
              '[bootstrapAuthentication.tryToAuthenticateFromUrlParam] error invalid token:',
              {
                token,
              }
            );
            return of(undefined);
          }),
          finalize(() => {
            window.location.replace(
              removeURLParameter(window.location.href, paramName)
            );
          })
        );
      }
      return of(undefined);
    })
  );
}
function removeURLParameter(url: string, parameter: string) {
  //prefer to use l.search if you have a location/link object
  const urlparts = url.split('?');
  if (urlparts.length >= 2) {
    const prefix = encodeURIComponent(parameter) + '=';
    const pars = urlparts[1].split(/[&;]/g);

    //reverse iteration as may be destructive
    for (let i = pars.length; i-- > 0; ) {
      //idiom for string.startsWith
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }

    return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
  }
  return url;
}
