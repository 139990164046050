import { useIntl } from 'react-intl';

export const ActivitySessionDetailsDialogRegistrationPaidAreaWarningMessage = ({
  className = '',
}: {
  className?: string;
}) => {
  const { formatMessage: t } = useIntl();

  return (
    <p className={`text-justify  ${className}`}>
      ⚠️{' '}
      {t({
        id: 'page16.activity-session-details-dialog.merci-effectuer-debut',
      })}{' '}
      <span className="font-bold">
        {t({
          id: 'page16.activity-session-details-dialog.merci-effectuer-gras',
        })}
      </span>
      {t({
        id: 'page16.activity-session-details-dialog.merci-effectuer-fin',
      })}
    </p>
  );
};
