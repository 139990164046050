import { AppPage, PageContainer } from '_01_CORE/_common-layout';
import { AppButtonUniverse, AppLoader } from '_01_CORE/_components-core';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import {
  useAppCacheContext,
  useJourneyEnabledActions,
} from '_01_CORE/app-contexts';
import { useAppSettings } from '_01_CORE/app-core/app-security';
import { AppUniverseHeader } from '_02_APP_COMMON/_global';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { AuthMultiLoginAnimLivePageInfoPanel } from './AuthMultiLoginAnimLivePageInfoPanel';
import { AuthMultiLoginAnimLivePageViewState } from './_model';
import { authMultiLoginAnimLivePageInitialStateBuilder } from './_services';
import {
  AuthPageJourneysToActivateByKeyList,
  AuthPageMultiLoginCheckKeyPanel,
} from './check-key';
import {
  AuthPageMultiLoginCompanyCodePanel,
  AuthPageMultiLoginEmailPanel,
} from './email';
import {
  AuthMultiLoginAnimLivePageLocalState,
  useAuthMultiLoginAnimLivePageLocalState,
} from './useAuthMultiLoginAnimLivePageLocalState.hook';

export const AuthMultiLoginAnimLivePage = () => {
  const appCacheContext = useAppCacheContext();

  const { formatMessage: t } = useIntl();
  const params = new URLSearchParams(window.location.search);
  const companyReference =
    params.get('companyReference') ??
    appCacheContext?.companyValidReferenceCode;

  const appSettings = useAppSettings();
  const title1Color = appSettings?.universe?.theme?.title1Color;

  const routeParams = new URLSearchParams(window.location.search);
  const companyValidReferenceCode =
    routeParams.get('companyReference') ??
    appCacheContext?.companyValidReferenceCode;
  const journeyEnabledActions = useJourneyEnabledActions({});
  const initialViewState: AuthMultiLoginAnimLivePageViewState = useMemo(
    () =>
      authMultiLoginAnimLivePageInitialStateBuilder.buildInitialViewState(
        appCacheContext,
        {
          journeyEnabledActions,
          companyValidReferenceCode,
        }
      ),
    [appCacheContext, companyValidReferenceCode, journeyEnabledActions]
  );

  const localState: AuthMultiLoginAnimLivePageLocalState = useAuthMultiLoginAnimLivePageLocalState(
    initialViewState
  );
  const { viewState, setViewState, actions } = localState;
  const { journeyInfo, operationInProgress, mode } = viewState;
  return (
    <AppPage tabId="login">
      <PageContainer
        className={`h-full bg-white text-center flex flex-col gap-4`}
        style={{
          color: title1Color,
        }}
      >
        <AppUniverseHeader showLargeHeader={false} />

        <div className="h-full w-full max-w-sm md:max-w-md mx-auto flex flex-col gap-8">
          {operationInProgress ? (
            <AppLoader
              className="my-4 p-2 px-4"
              type={operationInProgress?.type}
              message={operationInProgress?.message}
            />
          ) : (
            <>
              <AuthMultiLoginAnimLivePageInfoPanel localState={localState} />
              {mode === 'check-key' && (
                <>
                  <AuthPageMultiLoginCheckKeyPanel
                    context="auth-login-activation"
                    initialActivationKey={viewState?.checkKey?.key}
                    onJourneyFinderResultUpdated={(fetchedJourney) =>
                      actions.processCheckKeySuccess(fetchedJourney)
                    }
                  />
                </>
              )}
              {mode === 'prompt-email' && (
                <>
                  <AuthPageMultiLoginEmailPanel localState={localState} />
                </>
              )}
              {mode === 'new-account-journeys-to-activate' && (
                <>
                  <AuthPageJourneysToActivateByKeyList
                    localState={localState}
                  />
                </>
              )}
              {mode === 'prompt-company-code' && (
                <>
                  <AuthPageMultiLoginCompanyCodePanel localState={localState} />
                </>
              )}
            </>
          )}
          <div className="flex-grow"></div>
          <div className="mt-8 ion-justify-content-center">
            <AppButtonUniverse
              style="light"
              className="w-full mt-4 uppercase"
              isFullWidth={true}
              href={
                actions.backUrl
              }
            >
              {t({ id: 'common.action.back' })}
            </AppButtonUniverse>
          </div>
        </div>
      </PageContainer>
    </AppPage>
  );
};
