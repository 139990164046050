import {
  AppIcons,
  AppInput,
  AppLoaderSpinner,
} from '_01_CORE/_components-core';
import { useAppRouter } from '_01_CORE/_hooks';
import { useAppCacheContext } from '_01_CORE/app-contexts';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import clsx from 'clsx';
import { CMJourney } from 'lib-common-model';
import { useCallback, useEffect, useState } from 'react';
import { UseFormReturn, useController } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { AccroparkBookingOptionsFormModel } from './AccroparkBookingOptionsFormModel.type';
import {
  PromoCodeCheckResponseParserResult,
  promoCodeCheckerClient,
} from './promoCodeCheckerClient.service';

export const AccroparkBookingOptionsGiftPromoCodeCard = ({
  form,
  journey,
  currencySymbol,

  className,
}: {
  form: UseFormReturn<AccroparkBookingOptionsFormModel>;
  journey: CMJourney;
  currencySymbol: string;
  className: string;
}) => {
  const appRouter = useAppRouter();
  const { formatMessage: t } = useIntl();
  const appId = appWebConfig().appId;

  const companyGroupId = journey?.company?.companyGroupId;

  const { auth } = useAppCacheContext();

  const [
    codeCheckResult,
    setCodeCheckResult,
  ] = useState<PromoCodeCheckResponseParserResult>();
  const [
    codeVerificationInProgress,
    setCodeVerificationInProgress,
  ] = useState<boolean>(false);

  const { control, register, formState, handleSubmit } = form;

  const {
    field: { onChange: onChangeGiftPromoDiscount, onBlur, name, value, ref },
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name: 'giftPromoDiscount',
    control,
    rules: { required: false },
    // defaultValue: false,
  });

  const [inputCode, setInputCode] = useState<string>(
    value?.giftPromoCode ?? '' // appWebConfig().envId === 'dev' ? 'CODE0250' : ''
  );

  const [lastCodeValidation, setLastCodeValidation] = useState<string>();

  const validateCode = useCallback(async () => {
    setCodeVerificationInProgress(true);
    setCodeCheckResult(undefined);

    const codeCheckResult: PromoCodeCheckResponseParserResult = await promoCodeCheckerClient.checkCode(
      inputCode,
      {
        formatMessage: t,
        journey,
        currencySymbol,
      }
    );
    setCodeVerificationInProgress(false);
    setCodeCheckResult(codeCheckResult);

    if (codeCheckResult.state === 'code-accepted') {
      setInputCode(codeCheckResult.code);
      if (codeCheckResult?.response?.meta) {
        onChangeGiftPromoDiscount(codeCheckResult?.response?.meta);
      }
    }
  }, [inputCode, t, journey, currencySymbol, onChangeGiftPromoDiscount]);

  useEffect(() => {
    if (!codeVerificationInProgress) {
      const code = inputCode?.trim()?.toUpperCase();
      if (code?.length === 8 && code !== lastCodeValidation) {
        setLastCodeValidation(code);
        validateCode();
      }
    }
  }, [codeVerificationInProgress, inputCode, lastCodeValidation, validateCode]);

  return (
    <div className={`w-full  ${className}`}>
      <div className={`w-full relative`}>
        <AppInput
          value={inputCode}
          onIonChange={(e) => {
            setInputCode(e?.target?.value as string);
            setCodeCheckResult(undefined);
            // setValidationResponse(undefined);
          }}
          type="text"
          className="my-3  w-full text-xl font-bold"
          // paddingClasses="pl-14 pr-14 py-3"
          iconClasses={`absolute top-1.5 left-1 w-7 h-7 z-10 ${
            value?.giftPromoCode
              ? 'text-app-theme-accropark-success'
              : 'text-gray-600'
          }`}
          required={true}
          placeholder={t({ id: 'page17.options.text.gift-code-promo' })}
          icon={AppIcons.giftCard}
          // autoComplete="off"
          uppercase={true}
          disabled={!!value?.giftPromoCode}
          onKeyDown={(e) => {
            if (e.code?.toLowerCase() === 'enter') {
              validateCode();
            }
          }}
        />
        {value?.giftPromoCode && (
          <AppIcons.check className="absolute z-10 top-2 right-3 w-7 h-7 rounded-full p-1 bg-app-theme-accropark-success text-white" />
        )}
        {inputCode?.length !== 0 && codeCheckResult?.state !== 'code-accepted' && (
          <button
            className="absolute z-10 top-1 right-1"
            onClick={() => validateCode()}
          >
            <div className="flex flex-col items-center">
              <AppIcons.check className="flex w-5 h-5 rounded-full p-0.5 bg-app-theme-accropark-primary text-white" />
              <div className="text-xs text-app-theme-accropark-primary">
                {t({ id: 'common.action.validate' })}
              </div>
            </div>
          </button>
        )}
      </div>
      <div className="h-16 font-bold">
        {codeVerificationInProgress ? (
          <div
            className={`px-4 font-bold text-sm text-app-info flex justify-center items-center gap-4`}
          >
            <span>
              <AppLoaderSpinner color="info" className="w-4 h-4" />
            </span>
            <span className="text-center">
              {t({ id: 'page17.options.text.code-verification-in-progress' })}
            </span>
            <span></span>
          </div>
        ) : codeCheckResult ? (
          <div
            className={clsx(
              'text-left',
              codeCheckResult.state === 'code-rejected'
                ? 'text-app-danger'
                : 'text-app-theme-accropark-success'
            )}
          >
            <div>{codeCheckResult.message}</div>
            {codeCheckResult.messageDetails && (
              <div className="text-xs">{codeCheckResult.messageDetails}</div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};
