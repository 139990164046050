import { AppAuth } from '_01_CORE/app-core/app-security';
import { AppCacheContextState } from '../../model';

export function appCacheContextSetAuth(
  state: AppCacheContextState,
  auth: AppAuth
): AppCacheContextState {
  if (!auth.isAuthenticated) {
    // not authenticated (or log-out)
    return {
      ...state,
      loaded: true,
      auth: undefined, // clear authentication
      notifications: [], // clear notifications
      // keep cache (companies, groups...)
    };
  } else if (auth.customerProfile) {
    const companies = {
      ...state.companies,
    };
    const groups = {
      ...state.groups,
    };

    const isNewLogin = !state.loaded || !state.auth?.isAuthenticated;
    // set initial notifications on login (but don't update on refresh token) => pourquoi?  pour éviter les conflits avec d'autres traitements, sans doutes?
    const notifications = isNewLogin
      ? auth.customerProfile.lastNotifications
      : state.notifications;
    const activitySessionsToDisplay = isNewLogin
      ? auth.customerProfile.activitySessionsToDisplay
      : state.activitySessionsToDisplay;

    const notificationsUpdateDate = notifications?.length
      ? new Date(notifications[0].sendDate)
      : state.notificationsUpdateDate;
    const unreadNotification = !!notifications.find((n) => !n.readDate);

    return {
      ...state,
      auth,
      companies,
      groups,
      loaded: true,
      notifications,
      notificationsUpdateDate,
      unreadNotification,
      activitySessionsToDisplay,
    };
  } else {
    return {
      ...state,
      auth: {
        ...auth,
        customerProfile: state.auth?.customerProfile, // keep profile in case of token update
      },
      loaded: true,
    };
  }
}
