import TTLCache from '@isaacs/ttlcache';
import { AppCacheStore } from '../AppCacheStore.type';
import { AppCacheTTLOptions } from './AppCacheTTLOptions.type';

export function AppCacheTTLStore<T>(
  options: AppCacheTTLOptions
): AppCacheStore<T> {
  // https://www.npmjs.com/package/@isaacs/ttlcache
  const lruOptions: TTLCache.Options<any, any> = {
    max: options.maxItems,
    // how long to live in ms
    ttl: options.ttl, // 1000 * 60 * 5,
    checkAgeOnGet: false, // si false, des objets périmés peuvent être retournés
  };
  const lruCache = new TTLCache(lruOptions);

  const cache: AppCacheStore<T> = {
    get: async (key: string): Promise<T> => {
      return lruCache.get(key);
    },
    set: async (key: string, value: T): Promise<void> => {
      lruCache.set(key, value);
    },

    remove: async (key: string): Promise<void> => {
      lruCache.delete(key);
    },

    clear: async (): Promise<void> => {
      lruCache.clear();
    },
  };

  return cache;
}
