import { AppButtonUniverse, AppLoader } from '_01_CORE/_components-core';
import { useAppRouter, useAppToasts } from '_01_CORE/_hooks';
import { useNetworkStatus } from '_01_CORE/app-core/app-platform';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { JourneyDailyActivityCardParticipantsList } from '../JourneyDailyActivityCardParticipantsList';
import { JourneyActivitiesConfirmWaitingRegistrationsParams } from '../hooks';
import {
  CustomerActivityParticipantsDailyActivitParticipantState,
  CustomerActivityParticipantsDailyActivity,
} from '../model';

export const ActivitySessionDetailsDialogRegistrationAcceptedPendingArea = ({
  dailyActivity,
  participants,
  companyReference,
  customerJourneyId,
  customerAccountId,
  onConfirmWaitingRegistrations,
}: {
  dailyActivity: CustomerActivityParticipantsDailyActivity;
  participants: CustomerActivityParticipantsDailyActivitParticipantState[];
  companyReference: string;
  customerJourneyId: string;
  customerAccountId: string;
  onConfirmWaitingRegistrations: (
    params: JourneyActivitiesConfirmWaitingRegistrationsParams
  ) => Promise<any>;
}) => {
  const appRouter = useAppRouter();
  const { formatMessage: t } = useIntl();
  const { networkStatus } = useNetworkStatus();
  const { pushError } = useAppToasts();

  const session = dailyActivity?.selectedSession;

  const [loadingInProgress, setLoadingInProgress] = useState(false);

  const confirmWaitingRegistrations = useCallback(
    async ({ confirmation }: { confirmation: boolean }) => {
      setLoadingInProgress(true);
      try {
        await onConfirmWaitingRegistrations({
          confirmation,
          activityId: session.activitySession.activityId,
          activitySessionId: session.activitySession.activitySessionId,
          journeyParticipantsIds: participants.map(
            (x) => x.journeyParticipant.journeyParticipantId
          ),
          customerJourneyId,
          date: session.activitySession.beginDateTime,
          companyReference,
          customerAccountId,
        });
      } finally {
        setLoadingInProgress(false);
      }
    },
    [
      companyReference,
      customerAccountId,
      customerJourneyId,
      onConfirmWaitingRegistrations,
      participants,
      session.activitySession.activityId,
      session.activitySession.activitySessionId,
      session.activitySession.beginDateTime,
    ]
  );

  return (
    <div className="my-2 mx-2 sm:mx-6 p-2 border-2 border-app-primary text-gray-600 flex flex-col gap-2">
      <p className="text-app-primary text-justify font-bold">
        {t({ id: 'page16.legend.text.des-places-se-sont-liberees' })}
      </p>
      <JourneyDailyActivityCardParticipantsList
        className="justify-center mt-4 mb-2"
        activitySession={dailyActivity.selectedSession.activitySession}
        companyReference={companyReference}
        participants={participants}
      />
      {loadingInProgress ? (
        <AppLoader className="p-2 px-4" />
      ) : (
        <div className="my-4 flex gap-4 justify-around">
          <AppButtonUniverse
            style={'primary'}
            // isFullWidth={true}
            className="w-full uppercase"
            onClick={async () => {
              await confirmWaitingRegistrations({
                confirmation: true,
              });
            }}
          >
            <div className="w-full text-center">{t({ id: 'modal.yes' })}</div>
          </AppButtonUniverse>
          <AppButtonUniverse
            style={'cancel'}
            // isFullWidth={true}
            className="w-full uppercase"
            onClick={async () => {
              await confirmWaitingRegistrations({
                confirmation: false,
              });
            }}
          >
            <div className="w-full text-center">{t({ id: 'modal.no' })}</div>
          </AppButtonUniverse>
        </div>
      )}
      <p className="mt-2 text-justify text-sm text-gray-600 ">
        {`⚠️ `}
        {t({ id: 'page16.legend.text.sans-reponse-de-votre-part' })}
      </p>
    </div>
  );
};
