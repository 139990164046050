import { AppCacheContextModel } from '_01_CORE/app-contexts';
import {
  AuthMultiLoginAnimLivePageContextAction,
  AuthMultiLoginAnimLivePageContextOrigin,
  AuthMultiLoginAnimLivePageViewState,
  AuthMultiLoginAnimLivePageViewStateExtra,
} from '../_model';
export const authMultiLoginAnimLivePageInitialStateBuilder = {
  buildInitialViewState,
};

function buildInitialViewState(
  appCacheContext: AppCacheContextModel,
  extra: AuthMultiLoginAnimLivePageViewStateExtra
): AuthMultiLoginAnimLivePageViewState {
  const routeParams = new URLSearchParams(window.location.search);
  const contextAction =
    (routeParams.get('action') as AuthMultiLoginAnimLivePageContextAction) ??
    'login';
  const contextOrigin =
    (routeParams.get('origin') as AuthMultiLoginAnimLivePageContextOrigin) ??
    'login-home';

  const initialViewState: AuthMultiLoginAnimLivePageViewState = {
    contextAction,
    contextOrigin,
    mode: 'prompt-email',
    extra,
  };
  const companyReference = extra?.companyValidReferenceCode;

  const activationToken = routeParams.get('activationToken');

  if (contextAction === 'activate') {
    const activationKey = routeParams.get('activationKey');
    initialViewState.mode = 'check-key';
    initialViewState.checkKey = {
      state: 'prompt',
      key: activationKey,
    };
  } else if (activationToken && companyReference) {
    initialViewState.contextAction = 'activate';
    initialViewState.mode = 'check-token';
    initialViewState.checkToken = {
      state: 'prompt',
      token: activationToken,
      companyReference,
    };
  } else if (contextAction === 'register') {
    initialViewState.mode = 'prompt-email';
    initialViewState.promptEmail = {
      defaultEmail: '',
      defaultEmailAccountExists: false,
      emailReadOnly: false,
      showPassword: false,
    };
  } else if (contextAction === 'login') {
    initialViewState.mode = 'prompt-email';
    initialViewState.promptEmail = {
      defaultEmail: '',
      defaultEmailAccountExists: false,
      emailReadOnly: false,
      showPassword: true,
    };
  } else if (contextAction === 'see-company-by-code') {
    initialViewState.mode = 'prompt-company-code';
  }
  return initialViewState;
}
