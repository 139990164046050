import { useNavigateToNewJourneyPage } from '_02_APP_COMMON/JOU-journey/_common';
import { JourneysListNewJourneysToActivatePanel } from '_04_ANIM_LIVE/JOU-journey/JOU-04-journeys-list/JourneysListNewJourneysToActivatePanel';
import { CMExtJourneyToActivate } from 'lib-common-model';
import { useIntl } from 'react-intl';
import { AuthMultiLoginAnimLivePageLocalState } from '../useAuthMultiLoginAnimLivePageLocalState.hook';

export const AuthPageJourneysToActivateByKeyList = ({
  localState,
}: {
  localState: AuthMultiLoginAnimLivePageLocalState;
}) => {
  const extJourneysToActivate: CMExtJourneyToActivate[] =
    localState?.viewState?.promptEmail?.testResult?.extJourneysToActivate ?? [];

  const emailAddress = localState?.viewState?.promptEmail?.testResult?.login;

  const { formatMessage: t } = useIntl();
  const navigateToNewJourneyPage = useNavigateToNewJourneyPage({
    context: 'JourneysListAnimLivePage',
  });

  return (
    <div className="w-full bg-gray-50 border border-gray-200 pt-4 pb-8 px-4">
      <JourneysListNewJourneysToActivatePanel
        extJourneysToActivate={extJourneysToActivate}
        emailAddress={emailAddress}
      />
      <div className={`mt-16 underline text-gray-600 font-normal text-right`}>
        <span className=" cursor-pointer" onClick={navigateToNewJourneyPage}>
          {t({ id: 'page04.button.add-other-journey.anim-live' })}
        </span>
      </div>
    </div>
  );
};
