import { AppButtonUniverse, AppIcons } from '_01_CORE/_components-core';
import { AppInput } from '_01_CORE/_components-core/AppInput';
import { useAppRouter, useAppToasts } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import {
  useAppCacheContext,
  useJourneyEnabledActions,
} from '_01_CORE/app-contexts';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import { useIsNetworkConnected } from '_01_CORE/app-core/app-platform';
import { authenticationClient } from '_01_CORE/app-core/app-security';
import {
  CMCustomerAccount,
  CMExtJourneyFinderResult,
  CMProfileMember,
  CustomerMobileAuthenticationResult,
  CustomerMobileAuthenticationStrategy,
  UserProfile,
} from 'lib-common-model';
import { appLogger } from 'lib-web-logger';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

export const AnimLiveJourneyActivateByTokenPanel = ({
  fetchData,
  setPersistInProgress,
}: {
  fetchData: CMExtJourneyFinderResult;
  setPersistInProgress: (value: boolean) => void;
}) => {
  const appRouter = useAppRouter();
  const { formatMessage: t } = useIntl();
  const { pushError, pushSuccess } = useAppToasts();

  const { isActivateJourneyMultipleEnabled } = useJourneyEnabledActions({});

  const [emailAddress, setEmailAddress] = useState<string>(
    appWebConfig().authentication.defaultAuthLogin?.login || undefined
  );
  const [password, setPassword] = useState<string>(
    appWebConfig().authentication.defaultAuthLogin?.password || undefined
  );
  const { auth, companyValidReferenceCode } = useAppCacheContext();

  const {
    companyReference,
    activationToken,
    existingSimilarCustomerAccount,
    existingJourneyCustomerAccounts,
  } = fetchData;

  const displayMode = existingJourneyCustomerAccounts?.length
    ? 'account-already-created'
    : existingSimilarCustomerAccount?.email
    ? 'similar-account-exists'
    : 'new-account';

  const { dispatchCacheContextAction } = useAppCacheContext();

  const isNetworkConnected = useIsNetworkConnected();
  const startExtJourneyActivation = useCallback(
    ({
      customerAccount,
      customerAccountMembers,
    }: {
      customerAccount: CMCustomerAccount;
      customerAccountMembers: CMProfileMember[];
    }) => {
      dispatchCacheContextAction({
        type: 'set-journey-to-edit-from-external-data',
        payload: {
          companyReference,
          externalData: fetchData,
          activationToken,
          customerAccount,
          customerAccountMembers,
        },
      });
      appRouter.navigate(
        appRoutesBuilder.getJourneyCreateParticipantsListUrl({
          companyReference,
        }),
        { cause: 'create-journey-from-external' }
      );
    },
    [
      activationToken,
      appRouter,
      companyReference,
      dispatchCacheContextAction,
      fetchData,
    ]
  );
  const login = useCallback(
    async ({
      strategy,
    }: {
      strategy: CustomerMobileAuthenticationStrategy;
    }) => {
      if (!isNetworkConnected()) {
        return;
      }

      setPersistInProgress(true);

      try {
        let authResult: CustomerMobileAuthenticationResult;

        if (strategy === 'password') {
          authResult = await authenticationClient.authenticateByLoginPassword({
            login: emailAddress,
            password: password,
          });
        } else {
          authResult = await authenticationClient.authenticateByActivationToken(
            {
              email: existingSimilarCustomerAccount?.email,
              activationToken,
            }
          );
        }

        const { profile } = authResult;

        pushSuccess(t({ id: 'page01.connectionOK' }), 500);
        if ((profile as UserProfile<'multi-users-v2'>).type === 'super-admin') {
          appLogger.debug('[Redirect] to /ddebugg');
          appRouter.navigate(appRoutesBuilder.getDebugUrl(), {
            cause: 'login-success',
          });
        } else if (profile.type === 'customer') {
          const existingJourney = profile.journeys.find(
            (j) =>
              j.extCustomerJourneyBookingGroupId ===
              fetchData?.extCustomerJourneyBookingGroupId
          );
          if (existingJourney) {
            appRouter.navigate(
              appRoutesBuilder.getJourneyHomeUrl({
                customerJourneyId: existingJourney._id,
              }),
              { cause: 'activate-journey-already-exists' }
            );
          } else {
            startExtJourneyActivation({
              customerAccount: profile?.account,
              customerAccountMembers: profile?.members,
            });
          }
        } else if (profile.type === 'company') {
          appLogger.debug('[Redirect] to /pro');
          // appRouter.navigate(appRoutesBuilder.getCompanyUrl(), {
          //   cause: 'login-success',
          // });
        }
      } catch (err) {
        appLogger.warn('Authentication err', { err });
        pushError(t({ id: 'page01.connectionNotOK' }));
      } finally {
        setPersistInProgress(false);
      }
    },
    [
      activationToken,
      appRouter,
      startExtJourneyActivation,
      emailAddress,
      existingSimilarCustomerAccount?.email,
      fetchData?.extCustomerJourneyBookingGroupId,
      isNetworkConnected,
      password,
      pushError,
      pushSuccess,
      setPersistInProgress,
      t,
    ]
  );

  const handleForgotPasswordTouch = () => {
    appRouter.navigate(
      appRoutesBuilder.getAccountResetPasswordQueryUrl({
        login: emailAddress,
      }),
      {
        cause: 'click-forgot-password',
      }
    );
  };

  return (
    <div className="w-full flex flex-col gap-8">
      {displayMode === 'new-account' && (
        <div className="w-full bg-gray-50 rounded-xl border border-gray-200 pt-4 pb-8 px-8">
          {/* <h2 className="mt-4 mb-2 text-left font-bold text-base text-app-theme-anim-live-primary">
            {t({
              id:
                'page30-journey-activation-token.message.je-souhaite-decouvrir',
            })}
          </h2> */}

          <div className="mb-4 ion-justify-content-center">
            <AppButtonUniverse
              style={'primary'}
              className="mt-4 uppercase"
              onClick={() => {
                startExtJourneyActivation({
                  customerAccount: auth?.customerProfile?.account,
                  customerAccountMembers: auth?.customerProfile?.members,
                });
              }}
            >
              {t({ id: `page03.register.anim-live` })}
            </AppButtonUniverse>
          </div>
        </div>
      )}
      {displayMode === 'similar-account-exists' && (
        <div className="w-full bg-gray-50 rounded-xl border border-gray-200 pt-4 pb-8 px-8">
          <h2 className="mt-4 mb-2 text-left font-bold text-base text-app-theme-accropark-primary">
            {t(
              {
                id:
                  'page30-journey-activation-token.message.nous-avons-detecte-compte-existant-email',
              },
              {
                email: existingSimilarCustomerAccount?.email,
              }
            )}
          </h2>

          <div className="mb-4 ion-justify-content-center">
            <AppButtonUniverse
              style={'secondary'}
              className="mt-4 uppercase"
              onClick={() => {
                login({
                  strategy: 'external-data-journey-token',
                });
              }}
            >
              {t({ id: `common.action.continue` })}
            </AppButtonUniverse>
          </div>
        </div>
      )}
      {displayMode === 'similar-account-exists' && (
        <div className="w-full bg-gray-50 rounded-xl border border-gray-200 pt-4 pb-8 px-8">
          <h2 className="mt-4 mb-2 text-left font-bold text-base text-app-theme-anim-live-primary">
            {t({
              id:
                'page30-journey-activation-token.message.je-prefere-creer-nouveau-compte',
            })}
          </h2>

          <div className="mb-4 ion-justify-content-center">
            <AppButtonUniverse
              style={'primary'}
              className="mt-4 uppercase"
              onClick={() => {
                startExtJourneyActivation({
                  customerAccount: auth?.customerProfile?.account,
                  customerAccountMembers: auth?.customerProfile?.members,
                });
              }}
            >
              {t({ id: `page03.register.anim-live` })}
            </AppButtonUniverse>
          </div>
        </div>
      )}

      <div className="w-full bg-gray-50 rounded-xl border border-gray-200 pt-4 pb-8 px-8">
        {displayMode === 'new-account' && (
          <>
            <h2 className="mt-4 text-left font-bold text-base text-app-theme-anim-live-primary">
              {t({
                id:
                  'page30-journey-activation-token.message.j-ai-deja-un-compte',
              })}
            </h2>
            <p className="text-left mb-3">
              {t({
                id:
                  'page30-journey-activation-token.message.je-souhaite-ajouter-sur-compte',
              })}
            </p>
          </>
        )}
        {displayMode === 'account-already-created' && (
          <>
            <h2 className="mt-4 text-left font-bold text-base text-app-theme-anim-live-primary">
              {t({
                id: 'page30-journey-activation-token.message.je-me-connecte',
              })}
            </h2>
          </>
        )}
        {displayMode === 'similar-account-exists' && (
          <h2 className="mt-4 mb-2 text-left font-bold text-base text-app-theme-anim-live-primary">
            {t({
              id:
                'page30-journey-activation-token.message.je-possede-autre-compte',
            })}
          </h2>
        )}

        <div className="w-full text-left">
          <AppInput
            required={true}
            autofocus={true}
            placeholder={t({ id: 'form.label.email' })}
            value={emailAddress}
            onChange={(e) => {
              setEmailAddress((e as any).target.value);
            }}
            type="email"
            icon={AppIcons.mail}
            iconClasses="absolute top-4 left-1 text-gray-200 w-6 h-6 z-10"
          />
        </div>
        <div className="w-full text-left mt-2">
          <AppInput
            required={true}
            autofocus={true}
            placeholder={t({ id: 'form.label.password' })}
            value={emailAddress}
            onChange={(e) => {
              setPassword((e as any).target.value);
            }}
            type="password"
            icon={AppIcons.password}
            iconClasses="absolute top-4 left-1 text-gray-200 w-6 h-6 z-10"
          />
        </div>
        <div className="ion-justify-content-center">
          <AppButtonUniverse
            style={'primary'}
            className="mt-4 uppercase"
            onClick={() =>
              login({
                strategy: 'password',
              })
            }
          >
            {t({ id: 'page01.submit' })}
          </AppButtonUniverse>
        </div>
        <div className="text-center text-sm underline mt-2 cursor-pointer">
          <a onClick={handleForgotPasswordTouch}>
            {t({ id: 'page01.forgotPassword' })}
          </a>
        </div>
      </div>
      {displayMode === 'account-already-created' &&
        isActivateJourneyMultipleEnabled && (
          <div className="w-full bg-gray-50 rounded-xl border border-gray-200 pt-4 pb-8 px-8">
            <h2 className="mt-4 mb-2 text-left font-bold text-base text-app-theme-anim-live-primary">
              {t({
                id:
                  'page30-journey-activation-token.message.je-prefere-creer-nouveau-compte',
              })}
            </h2>

            <div className="mb-4 ion-justify-content-center">
              <AppButtonUniverse
                style={'primary'}
                className="mt-4 uppercase"
                onClick={() => {
                  startExtJourneyActivation({
                    customerAccount: auth?.customerProfile?.account,
                    customerAccountMembers: auth?.customerProfile?.members,
                  });
                }}
              >
                {t({ id: `page03.register.anim-live` })}
              </AppButtonUniverse>
            </div>
          </div>
        )}
    </div>
  );
};
