import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import { CMExtJourneyFinderResult } from 'lib-common-model';
import { useCallback, useMemo } from 'react';
import {
  useAuthMultiLoginAction_AuthenticateByActivationToken,
  useAuthMultiLoginAction_AuthenticateByPassword,
  useAuthMultiLoginAction_CheckCompanyCode,
  useAuthMultiLoginAction_ProcessAuthenticationSuccess,
  useAuthMultiLoginAction_RedirectToJourneyCreateDates,
  useAuthMultiLoginAction_StartExtJourneyActivation,
  useAuthMultiLoginAction_TestEmail,
} from './_hooks';
import { AuthMultiLoginAnimLivePageViewState } from './_model';
import { authMultiLoginAnimLivePageViewStateBuilder } from './_services';
import { useAuthPageMultiLoginCheckToken } from './check-token';
import { AuthMultiLoginAnimLivePageBaseLocalState } from './useAuthMultiLoginAnimLivePageLocalState.hook';

export function useAuthMultiLoginAnimLivePageLocalStateActions(
  baseLocalState: AuthMultiLoginAnimLivePageBaseLocalState
) {
  const { viewState, setViewState } = baseLocalState;

  const checkToken = useAuthPageMultiLoginCheckToken({
    viewState,
    setViewState,
  });

  const processCheckKeySuccess = useCallback(
    (fetchedJourney: CMExtJourneyFinderResult) => {
      const updatedViewState: AuthMultiLoginAnimLivePageViewState = authMultiLoginAnimLivePageViewStateBuilder.buildUpdatedViewStateFromCheckKeySuccess(
        {
          viewState,
          fetchedJourney,
        }
      );
      setViewState(updatedViewState);
    },
    [setViewState, viewState]
  );

  const redirectToJourneyCreateDates = useAuthMultiLoginAction_RedirectToJourneyCreateDates(
    baseLocalState
  );

  const checkCompanyCode = useAuthMultiLoginAction_CheckCompanyCode(
    baseLocalState,
    {
      redirectToJourneyCreateDates,
    }
  );

  const startExtJourneyActivation = useAuthMultiLoginAction_StartExtJourneyActivation(
    baseLocalState
  );

  const processAuthenticationSuccess = useAuthMultiLoginAction_ProcessAuthenticationSuccess(
    baseLocalState,
    {
      startExtJourneyActivation,
      redirectToJourneyCreateDates,
    }
  );

  const authenticateByPassword = useAuthMultiLoginAction_AuthenticateByPassword(
    baseLocalState,
    {
      processAuthenticationSuccess,
    }
  );
  const authenticateByActivationToken = useAuthMultiLoginAction_AuthenticateByActivationToken(
    baseLocalState,
    {
      processAuthenticationSuccess,
    }
  );

  const testEmail = useAuthMultiLoginAction_TestEmail(baseLocalState, {
    redirectToJourneyCreateDates,
    startExtJourneyActivation,
    authenticateByActivationToken,
  });

  const backUrl = useMemo(() => {
    const contextOrigin = viewState.contextOrigin;
    const companyValidReferenceCode = viewState.extra.companyValidReferenceCode;
    if (contextOrigin === 'company-home' && companyValidReferenceCode) {
      return appRoutesBuilder.getCompanyBookletUrl({
        companyReference: companyValidReferenceCode,
      });
    } else {
      return appRoutesBuilder.getLoginHomeUrl();
    }
  }, [viewState.extra.companyValidReferenceCode, viewState.contextOrigin]);

  return {
    processCheckKeySuccess,
    checkToken,
    startExtJourneyActivation,
    authenticateByPassword,
    authenticateByActivationToken,
    testEmail,
    checkCompanyCode,
    backUrl,
  };
}

export type AuthMultiLoginAnimLivePageLocalStateActions = ReturnType<
  typeof useAuthMultiLoginAnimLivePageLocalStateActions
>;
