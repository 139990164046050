import { dateTester } from '_01_CORE/_components-core';
import { CMJourney } from 'lib-common-model';

export const journeyCheckInTester = { isCheckinEnabled, isIssueReportEnabled };

function isCheckinEnabled(journey: CMJourney) {
  if (
    journey?.company?.options?.customerServices?.issues?.enabled &&
    journey?.company?.options?.customerServices?.issues?.customer
      ?.createCheckinInventory
  ) {
    if (!journey?.metaPublic?.checkIn?.checkInventoryDate) {
      // état des lieux pas encore fait
      // séjour en cours ?
      return dateTester.isDateWithinRange(new Date(), {
        minDate: journey?.beginDateUTC,
        maxDateExclusive: journey?.endDateExclusiveUTC,
      });
    }
  }
  return false;
}

function isIssueReportEnabled(journey: CMJourney) {
  if (
    journey?.company?.options?.customerServices?.issues?.enabled &&
    journey?.company?.options?.customerServices?.issues?.customer?.createIssue
  ) {
    // séjour en cours ?
    return dateTester.isDateWithinRange(new Date(), {
      minDate: journey?.beginDateUTC,
      maxDateExclusive: journey?.endDateExclusiveUTC,
    });
  }
  return false;
}
