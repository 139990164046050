import React, { useMemo } from 'react';
import { AppToggleButtonGroupColor } from './AppToggleButtonGroupColor.type';

export const AppToogleButton = ({
  selected,
  onClick,
  children,
  color,
  theme,
  className,
}: {
  selected?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
  color: AppToggleButtonGroupColor;
  theme: 'light' | 'strong';
  className?: string;
}) => {
  //   let icon = React.cloneElement(children, { className: 'text-red-400' });
  const selectedClassName = useMemo(() => {
    if (theme === 'light') {
      if (selected) {
        return `bg-white font-bold text-gray-500 ring-1 ${
          color === 'blue'
            ? 'border-blue-500 ring-blue-500'
            : 'border-green-500 ring-green-500'
        }`;
      } else {
        return `bg-white text-gray-400 hover:text-gray-600 hover:bg-gray-50 ${
          color === 'blue' ? 'border-blue-300' : 'border-green-300'
        }`;
      }
    } else if (theme === 'strong') {
      if (selected) {
        return `font-bold text-white ring-1 ${
          color === 'blue'
            ? 'bg-blue-500 border-blue-500 ring-blue-500'
            : 'bg-green-500 border-green-500 ring-green-500'
        }`;
      } else {
        return `bg-white text-gray-500 hover:text-gray-700 hover:bg-gray-50 ${
          color === 'blue' ? 'border-blue-300' : 'border-green-300'
        }`;
      }
    }
  }, [color, selected, theme]);
  return (
    <div
      className={`flex justify-around items-center border rounded p-1 text-xs cursor-pointer
    ${selectedClassName}
    ${className ?? ''}`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
