import { CameraSource, Photo } from '@capacitor/camera';
import { ResponsiveImage } from 'lib-common-model';
import React, { FocusEventHandler, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { customerJourneyApiClient } from '_01_CORE/app-core/app-api';
import { usePhotoFromDevice } from '_01_CORE/app-core/app-platform';
import { useOperationPending } from '_01_CORE/_hooks';
import { AppButtonV2 } from '../AppButton';
import { AppLoader } from '../AppLoader';
import { AppIcons } from '../icons';

export type AppInputPhotoProps = {
  label?: string;
  placeholder?: string;
  className?: string;
  name?: string;
  id?: string;
  value?: ResponsiveImage;
  url: string;
  onChange?: (image?: ResponsiveImage) => void;
  onBlur?: FocusEventHandler<any>;
};

export const AppInputPhoto = React.forwardRef<
  HTMLInputElement,
  AppInputPhotoProps
>(
  (
    {
      label,
      value,
      url,
      onChange,
      onBlur,
      placeholder,
      className = '',
      name,
      ...nativeInputProps
    },
    ref
  ) => {
    const { formatMessage: t } = useIntl();

    const {
      id = nativeInputProps.id ?? `appinput-${Math.random() * 10000}`,
    } = nativeInputProps;

    const [
      imageLoadingSource,
      setImageLoadingSource,
    ] = useState<CameraSource>();
    const [isResizing, setIsResizing] = useState<boolean>();

    const { takePhoto, resizeImage } = usePhotoFromDevice();

    const resizeAndUploadMulti = useCallback(
      async ({
        cameraPhoto,
        dimensions,
      }: {
        cameraPhoto: Photo;
        dimensions: {
          maxWidth: number;
          maxHeight: number;
        }[];
      }) => {
        let photo: ResponsiveImage;
        for (let i = 0; i < dimensions.length && !photo; i++) {
          try {
            setIsResizing(true);
            const image = await resizeImage({
              cameraPhoto,
              dimensions: dimensions[i],
            });
            setIsResizing(false);
            // upload
            // TODO: amélioration: détecter la vitesse, interrompre l'upload et choisir direct la taille adaptée...
            photo = await customerJourneyApiClient.uploadImageBlob(image.blob, {
              url,
            });
          } catch (err) {
            console.error(err);
          }
        }
        setIsResizing(false);
        if (onChange) {
          onChange(photo);
        }

        // }
      },
      [onChange, resizeImage, url]
    );

    const onTakePhotoClick = useOperationPending(
      async ({ cameraSource }: { cameraSource: CameraSource }) => {
        try {
          setImageLoadingSource(cameraSource);
          const cameraPhoto = await takePhoto({
            cameraSource,
          });
          if (cameraPhoto) {
            await resizeAndUploadMulti({
              cameraPhoto,
              dimensions: [
                {
                  maxHeight: 700,
                  maxWidth: 700,
                },
                {
                  maxHeight: 400,
                  maxWidth: 400,
                },
                {
                  maxHeight: 200,
                  maxWidth: 200,
                },
              ],
            });
          }
        } finally {
          setImageLoadingSource(undefined);
        }
      },
      [resizeAndUploadMulti, takePhoto]
    );

    return (
      <div className={`${className}`}>
        {label && (
          <label
            className="text-gray-400 mb-1 font-bold text-xs block text-left"
            htmlFor={id}
          >
            {label}
          </label>
        )}

        <div className="relative overflow-hidden">
          <div
            className={`rounded text-sm font-medium opacity-90 hover:opacity-100 focus-within:opacity-100 cursor-pointer`}
          >
            {imageLoadingSource || isResizing ? (
              <div className="border border-blue-200 px-2 py-0.5">
                {/* <AppLoaderSpinner color="white" className="w-4 h-4" /> */}
                <AppLoader
                  iconSize={6}
                  type="persist"
                  message={
                    isResizing ? 'Redimentionnement en cours...' : undefined
                  }
                />
              </div>
            ) : (
              <div className="flex gap-2">
                <AppButtonV2
                  className={`inline-block`}
                  buttonStyle="primary"
                  onClick={() =>
                    onTakePhotoClick({ cameraSource: CameraSource.Camera })
                  }
                >
                  <label htmlFor={id} className="flex space-x-2 cursor-pointer">
                    <AppIcons.actionAddPhoto className="w-4 h-4" />
                  </label>
                </AppButtonV2>
                <AppButtonV2
                  className={`inline-block`}
                  buttonStyle="primary"
                  onClick={() =>
                    onTakePhotoClick({ cameraSource: CameraSource.Photos })
                  }
                >
                  <label htmlFor={id} className="flex space-x-2 cursor-pointer">
                    <AppIcons.actionOpenPhotoGallery className="w-4 h-4" />
                  </label>
                </AppButtonV2>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
);
AppInputPhoto.displayName = 'AppInputPhoto';
