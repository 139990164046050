import {
  AppRouteGuard,
  AppRouteGuardContext,
} from '_01_CORE/app-core/app-router';
import { redirectToDefaultRoute } from './redirectToDefaultRoute.guard';

export const requireJourneyEditData: AppRouteGuard = async (
  context: AppRouteGuardContext
) => {
  if (!context.appCacheContext.journeyEditData) {
    return redirectToDefaultRoute('requireJourneyEditData.guard')(context);
  }

  return context;
};
