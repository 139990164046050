import { WrenchScrewdriverIcon } from '@heroicons/react/24/solid';
import { ReactComponent as missingEquipment } from './svg/issues/baby-cot.svg';
import { ReactComponent as water } from './svg/issues/faucet.svg';
import { ReactComponent as gas } from './svg/issues/gas-cylinder.svg';
import { ReactComponent as cleanliness } from './svg/issues/mop.svg';
import { ReactComponent as electricity } from './svg/issues/power.svg';

export const IssuesIcons = {
  general: WrenchScrewdriverIcon,
  cleanliness,
  missingEquipment,
  technical: WrenchScrewdriverIcon,
  water,
  gas,
  electricity,
};
