// @index('./*', f => `export * from '${f.path}'`)
export * from './RestoMenu.model';
export * from './RestoMenuDish.model';
export * from './RestoMenuDishOption.model';
export * from './RestoMenuDishTranslation.type';
export * from './RestoMenuProduct.model';
export * from './RestoMenuProductTranslation.type';
export * from './RestoMenuProductType.type';
export * from './RestoMenuSection.model';
export * from './RestoMenuSectionTranslation.type';
export * from './RestoMenuTranslation.type';
