import { useGetInfo } from '@capacitor-community/react-hooks/device';
import { IonImg } from '@ionic/react';
import { AppPage, HeaderToolbar, PageContainer } from '_01_CORE/_common-layout';
import { useAppRouter, useAppToasts } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import {
  useAppPlatform,
  useNetworkStatus,
} from '_01_CORE/app-core/app-platform';
import { useAppLogoUrl, useAppSettings } from '_01_CORE/app-core/app-security';
import { appStore } from '_01_CORE/app-core/app-store';
import { AppRemoteEnvId } from 'lib-web-common';
import { useLoadable } from 'lib-web-redux';
import { map } from 'rxjs/operators';
import { DebugSwitchRemoteEnv } from './DebugSwitchRemoteEnv';
export const HACK_DEBUG_PAGE_PUBLIC_UNTIL_NEXT_DEPLOY = true;

export const DebugPage = () => {
  const appId = appWebConfig().appId;

  const appSettings = useAppSettings();
  const whiteLabel = appSettings?.universe?.whiteLabel;
  const identity = appSettings?.universe?.identity;

  const appLogoUrl = useAppLogoUrl('md');
  const appRouter = useAppRouter();
  const { networkStatus } = useNetworkStatus();

  const { info: deviceMeta } = useGetInfo();

  const { platform } = useAppPlatform();

  const { deviceToken } = useLoadable({
    debugName: 'deviceToken',
    initialValueFromLoadSnapshot: true,
    load: () =>
      appStore.deviceToken.get().pipe(map((deviceToken) => ({ deviceToken }))),
  });
  const { notificationsState } = useLoadable({
    debugName: 'notificationsState',
    initialValueFromLoadSnapshot: true,
    load: () =>
      appStore.notificationsState
        .get()
        .pipe(map((notificationsState) => ({ notificationsState }))),
  });
  const { pushError, pushSuccess } = useAppToasts();

  return (
    <AppPage>
      <HeaderToolbar />
      <PageContainer className="text-center bg-gray-50">
        <IonImg className="m-auto mb-4 w-24 h-auto" src={appLogoUrl} />
        <h1 className="text-4xl font-bold">Admin</h1>
        <DebugSwitchRemoteEnv
          className="my-5"
          onSwitch={(remoteEnvId: AppRemoteEnvId) => {
            appRouter.navigate(appRoutesBuilder.getLogoutUrl(), {
              cause: 'switch env',
            });
          }}
        />

        <div className="max-w-screen-sm mx-auto text-left">
          <h2 className="mt-5 mb-2 text-xl font-bold">Device info</h2>
          <div className="mt-2 text-gray-700">
            <pre>
              {!!deviceMeta && JSON.stringify(deviceMeta, undefined, 2)}
            </pre>
          </div>
          <h2 className="mt-5 mb-2 text-xl font-bold">Notifications</h2>
          <div className="mt-2 text-gray-700">
            <pre>
              {!!notificationsState &&
                JSON.stringify(notificationsState, undefined, 2)}
            </pre>
          </div>
          <div className="mt-2 text-gray-700">
            Token:{' '}
            <pre>
              {!!deviceToken && JSON.stringify(deviceToken, undefined, 2)}
            </pre>
          </div>{' '}
          <h2 className="mt-5 mb-2 text-xl font-bold">Platform</h2>
          <div className="mt-2 text-gray-700">
            <pre>{platform}</pre>
          </div>
          <h2 className="mt-5 mb-2 text-xl font-bold">Network status</h2>
          <div className="mt-2 text-gray-700">
            <pre>
              {!!networkStatus && JSON.stringify(networkStatus, undefined, 2)}
            </pre>
          </div>{' '}
          <h2 className="mt-5 mb-2 text-xl font-bold">Configuration</h2>
          <div className="mt-2 text-gray-700">
            <pre>{JSON.stringify(appWebConfig(), undefined, 2)}</pre>
          </div>
        </div>
      </PageContainer>
    </AppPage>
  );
};
