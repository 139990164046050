import { AppMessageLight } from '_01_CORE/_common-layout';
import {
  AppButtonUniverse,
  AppIcons,
  useAppModalContext,
} from '_01_CORE/_components-core';
import { ModalConfirmActions } from '_01_CORE/_components-core/AppModal/ModalConfirmActions';
import { useAppRouter, useAppToasts } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import { useCompany } from '_01_CORE/app-contexts';
import { useNetworkStatus } from '_01_CORE/app-core/app-platform';
import { AnimLiveCompanyPlaceDescription } from '_04_ANIM_LIVE/ECO-ecommerce/ECO-31-journey-shopping-cart/AnimLiveCompanyPlaceDescription';
import { useParticipantPaymentModeOptions } from '_04_ANIM_LIVE/ECO-ecommerce/_common/AnimLiveShoppingCartTable/useCompanyPmsModeOptions.hook';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
  CustomerActivityParticipantsDailyActivitParticipantState,
  CustomerActivityParticipantsDailyActivity,
  CustomerActivityUpdateParticipantStateFn,
} from '../../model';
import { ActivitySessionDetailsDialogRegistrationPaidAreaWarningMessage } from '../ActivitySessionDetailsDialogRegistrationPaidAreaWarningMessage';

export function useActivitySessionDetailsDialogRegistrationPaidAreaLocalState({
  dailyActivity,
  participants,
  companyReference,
  customerJourneyId,
  onClose,
  onClickParticipant,
}: {
  dailyActivity: CustomerActivityParticipantsDailyActivity;
  participants: CustomerActivityParticipantsDailyActivitParticipantState[];
  companyReference: string;
  customerJourneyId: string;
  onClose: () => any;
  onClickParticipant: CustomerActivityUpdateParticipantStateFn;
}) {
  const appRouter = useAppRouter();
  const { formatMessage: t } = useIntl();
  const { networkStatus } = useNetworkStatus();
  const { pushError } = useAppToasts();

  const { openModal, closeModal } = useAppModalContext();

  const { company } = useCompany({ companyReference });

  const session = dailyActivity?.selectedSession;

  const appOnlinePaymentEnabled =
    company?.options?.payments?.online?.activityRegistration?.enabled &&
    dailyActivity.activitySchedule.payment.appOnlinePaymentEnabled;

  const onSitePaymentEnabled =
    !appOnlinePaymentEnabled ||
    company?.options?.payments?.onSite?.forceEnableAppRegistration;

  const hasUnpayedParticipant = useMemo(
    () =>
      dailyActivity.selectedSession.activitySession.registrationFeeEnabled &&
      dailyActivity.selectedSession.participants.find(
        (x) => x.activitySessionParticipant?.paymentStatus === 'payment-later'
      ) !== undefined,
    [
      dailyActivity.selectedSession.activitySession.registrationFeeEnabled,
      dailyActivity.selectedSession.participants,
    ]
  );
  const isCancelButtonVisible =
    dailyActivity.selectedSession?.registrationStatus === 'open' ||
    dailyActivity.selectedSession?.registrationStatus === 'registration-full';

  const hasAllPayedParticipant = useMemo(
    () =>
      dailyActivity.selectedSession.participants.find(
        (x) =>
          x.activitySessionParticipant?.paymentStatus === 'payment-accepted'
      ) !== undefined,
    [dailyActivity.selectedSession.participants]
  );

  const hasUnregisteredParticipant = useMemo(
    () =>
      participants.find(
        (x) =>
          x.isNotRegistered ||
          x.activitySessionParticipant.paymentStatus !== 'payment-pending'
      ) !== undefined,
    [participants]
  );
  const hasRegisteredParticipant = useMemo(
    () => participants.find((x) => !x.isNotRegistered) !== undefined,
    [participants]
  );

  const participantPaymentModesOptions = useParticipantPaymentModeOptions({
    appOnlinePaymentEnabled,
    // on affiche l'option même si c'est désactivé, afin de pouvoir afficher un popup
    onSitePaymentEnabled:
      dailyActivity.activitySchedule.payment.onSitePaymentEnabled,
    format: 'full',
  });

  const clearUnpayedParticipants = useCallback(async () => {
    const participantsStates = dailyActivity.selectedSession.participants.filter(
      (x) => x.activitySessionParticipant?.paymentStatus === 'payment-later'
    );
    for (const participantState of participantsStates) {
      await onClickParticipant({
        dailyActivity,
        participantState,
        skipConfirmDelete: true,
      });
    }
  }, [dailyActivity, onClickParticipant]);

  const addToShoppingCartConfirm = useCallback(() => {
    return openModal({
      title: 'Votre panier a été mis à jour.',
      content: (
        <>
          {hasUnpayedParticipant &&
            !dailyActivity.activitySchedule.payment
              ?.appOnlinePaymentEnabled && (
              <>
                <p className="my-3 text-justify font-bold text-gray-800">
                  {t({
                    id:
                      'page16.activity-session-details-dialog.vos-places-sont-reservees',
                  })}
                </p>
                <ActivitySessionDetailsDialogRegistrationPaidAreaWarningMessage className="my-3 bg-red-600 px-2 py-1 text-white" />
              </>
            )}
        </>
      ),
      actions: (
        <div className="my-8 w-full grid gap-4">
          <AppButtonUniverse
            className="w-full"
            style={'primary'}
            isFullWidth={true}
            label={'Voir mon panier'}
            icon={AppIcons.shoppingCart}
            onClick={async () => {
              closeModal();
              onClose();
              appRouter.navigate(
                appRoutesBuilder.getJourneyShoppingCartLiveUrl({
                  customerJourneyId,
                }),
                {
                  cause: 'click-shoppingCart-btn',
                }
              );
            }}
          />
          <AppButtonUniverse
            className="w-full"
            style={'cancel'}
            isFullWidth={true}
            label={'Poursuivre mes achats'}
            icon={AppIcons.actionBack}
            onClick={() => {
              closeModal();
              onClose();
            }}
          />
        </div>
      ),
    });
  }, [
    openModal,
    hasUnpayedParticipant,
    dailyActivity.activitySchedule.payment?.appOnlinePaymentEnabled,
    t,
    closeModal,
    onClose,
    appRouter,
    customerJourneyId,
  ]);

  const showOnsitePaymentAppRegistrationDisabledDialog = useCallback(() => {
    return openModal({
      icon: 'info',
      title: (
        <div className="font-medium text-app-primary">
          {`Le mode de paiement "sur place" ne permet pas de réserver sur l'application`}
        </div>
      ),
      content: (
        <div className="my-8">
          <AppMessageLight hideIcon={true}>
            <div className="grid gap-4 text-base text-left">
              <div>
                Vous pouvez réserver
                <span className="sup font-bold text-app-warning">*</span> et
                payer directement à:
              </div>
              <AnimLiveCompanyPlaceDescription
                companyPlace={dailyActivity.activitySchedule.onSitePaymentPlace}
              />
              <div className="text-sm">
                <span className="sup font-bold text-app-warning">*</span> sous
                réserve des places disponibles
              </div>
            </div>
          </AppMessageLight>
        </div>
      ),
      actions: (
        <ModalConfirmActions
          cancelMessage="Annuler"
          confirmMessage="OK"
          onConfirm={async () => {
            closeModal(); // NOTE: d'abord fermer ce modal, pour permettre éventuellement d'en afficher un autre ensuite
            await clearUnpayedParticipants();
          }}
          onCancel={closeModal}
        />
      ),
    });
  }, [
    openModal,
    dailyActivity.activitySchedule.onSitePaymentPlace,
    closeModal,
    clearUnpayedParticipants,
  ]);

  const clearShoppingCartConfirm = useCallback(() => {
    return openModal({
      title: t({ id: 'page16.registration.delete.title' }),
      actions: (
        <div className="w-full grid gap-4">
          <AppButtonUniverse
            className="w-full"
            style={'danger'}
            isFullWidth={true}
            label={'Oui'}
            icon={AppIcons.actionDelete}
            onClick={async () => {
              closeModal(); // NOTE: d'abord fermer ce modal, pour permettre éventuellement d'en afficher un autre ensuite
              await clearUnpayedParticipants();
            }}
          />
          <AppButtonUniverse
            className="w-full"
            style={'cancel'}
            isFullWidth={true}
            label={'Non'}
            icon={AppIcons.actionClose}
            onClick={() => {
              closeModal();
            }}
          />
        </div>
      ),
    });
  }, [openModal, t, closeModal, clearUnpayedParticipants]);

  return {
    data: {
      company,
      session,
      hasUnpayedParticipant,
      isCancelButtonVisible,
      hasAllPayedParticipant,
      hasUnregisteredParticipant,
      hasRegisteredParticipant,
      participantPaymentModesOptions,
      appOnlinePaymentEnabled,
      onSitePaymentEnabled,
    },
    dialogs: {
      addToShoppingCartConfirm,
      clearShoppingCartConfirm,
      showOnsitePaymentAppRegistrationDisabledDialog,
    },
  };
}

export type ActivitySessionDetailsDialogRegistrationPaidAreaLocalState = ReturnType<
  typeof useActivitySessionDetailsDialogRegistrationPaidAreaLocalState
>;
