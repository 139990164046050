import clsx from 'clsx';
import React, { FC, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { AppButtonUnstyledInner } from './AppButtonUnstyledInner';

export type AppButtonUnstyledDisplayMode = 'button' | 'tab';
export type AppButtonUnstyledProps = {
  id?: string;
  className?: string;
  iconStyleClass?: string;
  icon?: (props: React.ComponentProps<'svg'>) => JSX.Element;
  iconBadge?: (props: React.ComponentProps<'svg'>) => JSX.Element;
  displayMode?: AppButtonUnstyledDisplayMode;
  borderClassName?: string;
  href?: string;
  onClick?: (
    e?:
      | React.MouseEvent<HTMLAnchorElement, MouseEvent>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => any;
  target?: string;
  rel?: string;
  disabled?: boolean;
  tabIndex?: number;
  style?: React.CSSProperties;
  hoverStyle?: React.CSSProperties;
  gap?: 1 | 2;
  setReferenceElement?: React.LegacyRef<HTMLAnchorElement | HTMLButtonElement>;
  type?: 'button' | 'submit' | 'reset';
  textSize?: 'text-sm' | 'text-xs';
};

export const AppButtonUnstyled: FC<AppButtonUnstyledProps> = (props) => {
  const {
    id,
    children,
    icon,
    iconBadge,
    displayMode = 'button',
    borderClassName = 'border shadow-sm ',
    className,
    iconStyleClass,
    href,
    target,
    rel,
    onClick,
    disabled,
    tabIndex,
    style,
    hoverStyle,
    gap = 1,
    setReferenceElement,
    type = 'button',
  } = props;

  const [isHover, setIsHover] = useState(false);

  const styleWithHoverSupport = useMemo(() => {
    if (style) {
      return isHover && hoverStyle
        ? {
            ...style,
            ...hoverStyle,
          }
        : style;
    } else {
      return isHover ? hoverStyle : undefined;
    }
  }, [hoverStyle, isHover, style]);

  const buttonClass = useMemo(
    () =>
      clsx(
        'cursor-pointer relative inline-flex',
        gap === 1 ? 'gap-1' : 'gap-2',
        'items-center focus:outline-none',
        disabled && 'opacity-50 pointer-events-none',
        borderClassName,
        className
      ),
    [borderClassName, className, disabled, gap]
  );
  const isLocalLink = useMemo(() => href?.startsWith('/'), [href]);

  return href && !disabled ? (
    isLocalLink ? (
      <Link
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        id={id}
        target={target}
        rel={rel}
        to={href}
        onClick={(e) => {
          if (onClick) {
            onClick(e);
          }
        }}
        className={buttonClass}
        tabIndex={tabIndex}
        style={styleWithHoverSupport}
      >
        <AppButtonUnstyledInner {...props} />
      </Link>
    ) : (
      <a
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        id={id}
        target={target}
        href={href}
        ref={setReferenceElement as React.LegacyRef<HTMLAnchorElement>}
        onClick={(e) => {
          if (onClick) {
            onClick(e);
          }
        }}
        className={buttonClass}
        tabIndex={tabIndex}
        style={styleWithHoverSupport}
      >
        <AppButtonUnstyledInner {...props} />
      </a>
    )
  ) : (
    <button
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      type={type}
      id={id}
      ref={setReferenceElement as React.LegacyRef<HTMLButtonElement>}
      disabled={disabled}
      onClick={(e) => {
        if (!disabled && onClick) {
          onClick(e);
        }
      }}
      className={clsx(
        // 'form-input app--border--important', // tailwind-form reset (FIXME: ne marche pas, il faudrait déjà passer à tailwind3)
        buttonClass
      )}
      tabIndex={tabIndex}
      style={styleWithHoverSupport}
    >
      <AppButtonUnstyledInner {...props} />
    </button>
  );
};
