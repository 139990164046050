import { IonInput, IonItem, IonItemGroup } from '@ionic/react';
import { locate } from 'ionicons/icons';
import { appLogger } from 'lib-web-logger';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { useAppCacheContext, useJourney } from '_01_CORE/app-contexts';
import { customerJourneyApiClient } from '_01_CORE/app-core/app-api';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import {
  AppPage,
  CompanyImage,
  HeaderToolbar,
  PageContainer,
  PageTitle,
} from '_01_CORE/_common-layout';
import {
  AppButtonV2,
  AppIcons,
  AppInputIonic,
} from '_01_CORE/_components-core';
import { JourneyCard } from '_01_CORE/_components-core/JourneyCard';
import { JourneyParticipantCard } from '_01_CORE/_components-core/JourneyParticipantCard';
import { useAppRouter, useOperationPending } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import {
  journeyCheckInTester,
  useCampingPlaceNumberVisibility,
} from '_02_APP_COMMON/JOU-journey/_common';
import { AnimLiveJourneyHomeButtons } from './AnimLiveJourneyHomeButtons';

const appId = appWebConfig().appId;

export const journeyTitleFromStatusMap = {
  current: `page02.titleCurrent.${appId}`,
  last: `page02.titleLast.${appId}`,
  next: `page02.titleNext.${appId}`,
};

export const AnimLiveJourneyHomePage = () => {
  const appRouter = useAppRouter();
  const { formatMessage: t } = useIntl();

  const { customerJourneyId } = useParams<{ customerJourneyId: string }>();

  const { journey, journeyStatus, participants } = useJourney({
    customerJourneyId,
  });
  const [campingPlaceNumber, setCampingPlaceNumber] = useState(
    journey?.campingPlaceNumber
  );
  const {
    auth,
    companyValidReferenceCode,
    dispatchCacheContextAction,
  } = useAppCacheContext();

  useEffect(() => {
    if (
      journey?.company?.reference &&
      companyValidReferenceCode !== journey?.company?.reference
    ) {
      // auto-update selected company
      dispatchCacheContextAction({
        type: 'select-company',
        companyReference: journey?.company?.reference,
        context: 'journey-home-auto-select-company',
      });
    }
  }, [
    companyValidReferenceCode,
    dispatchCacheContextAction,
    journey?.company?.reference,
  ]);

  useEffect(() => {
    if (!journey) {
      appLogger.warn(
        '[AnimLiveJourneyHomePage] No journey: redirect to /journeys'
      );
      appRouter.navigateAfterDelay(appRoutesBuilder.getJourneysUrl(), {
        cause: 'company-home-no-journey',
      });
    }
  }, [appRouter, journey]);

  /**
   * Get title label which is different for Anim Live & Accropark
   */
  function getTitle() {
    return t({ id: journeyTitleFromStatusMap[journeyStatus] });
  }

  const updateJourneyDatesAndPlaceNumber = useOperationPending(async () => {
    journey.campingPlaceNumber = campingPlaceNumber;

    const {
      result,
    } = await customerJourneyApiClient.updateJourneyDatesAndPlaceNumber({
      customerJourneyId,
      data: { campingPlaceNumber },
    });
    if (result === 'success') {
      appRouter.navigate(
        appRoutesBuilder.getJourneyEditExistingHomeUrl({ customerJourneyId }),
        {
          cause: 'journey-edit-participant-create-submit',
        }
      );
    } else {
      // TODO show message
    }
  }, [appRouter, campingPlaceNumber, customerJourneyId, journey]);

  const {
    isVisibleCampingPlaceNumber,
    isEditableCampingPlaceNumber,
  } = useCampingPlaceNumberVisibility(journey);

  const isCheckInEnabled = useMemo(() => {
    return journeyCheckInTester.isCheckinEnabled(journey);
  }, [journey]);

  return !journey ? null : (
    <AppPage>
      <HeaderToolbar />
      <PageContainer className="text-center bg-gray-50" spacing="normal">
        <PageTitle
          title={getTitle()}
          // TODO TEMPORAIRE
          // backLink={appRoutesBuilder.getGroupHomeUrl({
          //   groupReference: journey.company.companyGroupReference,
          //   isAuthenticated: false,
          // })}
        />
        <CompanyImage image={journey.company?.image1} />
        <div className="mt-2 flex flex-col space-y-8 max-w-md mx-auto">
          <IonItemGroup>
            <JourneyCard
              showActionEditJourney={true}
              showActionDeleteJourney={true}
              journey={journey}
              redirectOnDelete={true}
              showDetails={true}
            />
            <AnimLiveJourneyHomeButtons
              className="my-4"
              journey={journey}
              companyReference={journey?.company?.reference}
              showHomeButton={true}
            />

            {!isCheckInEnabled && isEditableCampingPlaceNumber && (
              <div className="w-full mt-8 mb-4">
                <div className={`text-left font-bold text-app-danger`}>
                  {"Renseigner mon numéro d'emplacement"}
                </div>
                <div className="flex gap-2">
                  <AppInputIonic
                    className="flex-grow mt-1"
                    placeholder={t({ id: 'page10.journey.location' })}
                    value={campingPlaceNumber}
                    icon={locate}
                  >
                    <IonInput
                      onIonChange={(e) => {
                        setCampingPlaceNumber(e.detail.value);
                      }}
                    />
                  </AppInputIonic>
                  <AppButtonV2
                    className={`rounded-full ${
                      !campingPlaceNumber ? 'invisible' : ''
                    }`}
                    // size="small"
                    buttonStyle="primary"
                    icon={AppIcons.check}
                    onClick={() => updateJourneyDatesAndPlaceNumber()}
                  >
                    {t({ id: 'common.action.validate' })}
                  </AppButtonV2>
                </div>
              </div>
            )}
            {participants.length > 0 && (
              <>
                {participants.map((p) => (
                  <IonItem key={`${p.journeyParticipantId}`}>
                    <JourneyParticipantCard
                      customerJourneyId={journey._id}
                      participant={p}
                      className="py-2"
                    />
                  </IonItem>
                ))}
              </>
            )}
          </IonItemGroup>
        </div>
      </PageContainer>
    </AppPage>
  );
};
