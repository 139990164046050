import { AppIcons } from '_01_CORE/_components-core';
import { CMJourneyActivitySessionCompanyPlace } from 'lib-common-model';

export const AnimLiveCompanyPlaceDescription = ({
  companyPlace,
}: {
  companyPlace: CMJourneyActivitySessionCompanyPlace;
}) => {
  return !companyPlace ? null : (
    <div className="flex gap-2 text-gray-600">
      <AppIcons.location className="w-6 h-6" />
      <div>
        <div className="font-medium uppercase">{companyPlace.name}</div>
        <div className="text-xs text-gray-500">{companyPlace.subTitle}</div>
        <div className="text-xs text-gray-500">{companyPlace.openingHours}</div>
      </div>
    </div>
  );
};
