export const HACK_SPECIAL_ACCOUNTS: {
  login: string;
  enableDevDebug?: boolean; // affiche la page de débug dans le menu
  remote:
    | 'default' // pas de switch sur un autre env
    | 'auto' // accro-beta ou beta, suivant l'environnement
    | 'prod' // accro-prod ou prod (anim-live), suivant l'environnement
    | 'local-ip'; // ne fonctionne que si on fait ./build-mobile-app-native-apple-store.sh anim-live local-ip full, remote: 'auto' },
}[] = [
  // --- PRO ---
  {
    login: 'demo-beta@demo.fr', // COMPTE PRO (avec notifications activées)
    remote: 'auto',
    enableDevDebug: true,
  },
  // --- CLIENT (testeurs apple & google) ---
  {
    login: 'test@app.ap', // pour les testeurs apple et google (sans notifications)
    remote: 'auto',
    enableDevDebug: false,
  },
  // --- CLIENT BETA (tests internes) ---
  {
    login: 'test-notif@app.ap', // pour les tests internes (avec notifications)
    remote: 'auto',
    enableDevDebug: true,
  },
  {
    login: 'beta1@app.ap', // pour les tests internes (avec notifications)
    remote: 'auto',
    enableDevDebug: true,
  },
  {
    login: 'beta2@app.ap', // pour les tests internes (avec notifications)
    remote: 'auto',
    enableDevDebug: true,
  },
  {
    login: 'ubeta1@yopmail.com', // pour les tests internes (avec notifications)
    remote: 'auto',
    enableDevDebug: true,
  },
  {
    login: 'ubeta2@yopmail.com', // pour les tests internes (avec notifications)
    remote: 'auto',
    enableDevDebug: true,
  },
  {
    login: 'uprod1@yopmail.com', // pour les tests internes (avec notifications)
    remote: 'prod',
    enableDevDebug: true,
  },
  {
    login: 'uprod2@yopmail.com', // pour les tests internes (avec notifications)
    remote: 'prod',
    enableDevDebug: true,
  },
  // --- CLIENT PROD (avec debug) ---
  {
    login: 't@t.fr', // pour les tests internes (avec notifications)
    remote: 'default',
    enableDevDebug: true,
  },
  // --- CLIENT LOCAL (tests dev) ---
  {
    // ne fonctionne que si on fait ./build-mobile-app-native-apple-store.sh anim-live local-ip full, remote: 'auto' },
    login: 'dev1@app.ap', // pour les tests de dev avec l'ipad qui pointe sur le dev local en mode "local-ip"
    remote: 'local-ip',
    enableDevDebug: true,
  },
];
