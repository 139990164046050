import { AppEnvId } from 'lib-common-model';
import { AppRemoteEnvId } from 'lib-web-common';
import { AppWebConfigCommon, AppWebConfigURLs } from './model';

export const appWebConfigCommonBuilder = { buildCommonConfig };

function buildCommonConfig({
  envId,
  remoteEnvId,
  sentryDsn,
  appVersion,
  urls,
}: {
  envId: AppEnvId;
  remoteEnvId: AppRemoteEnvId;
  sentryDsn: string;
  appVersion: string;
  urls: AppWebConfigURLs;
}) {
  const config: AppWebConfigCommon = {
    envId,
    remoteEnvId,
    appVersion,
    urls,
    log: {
      level: envId === 'dev' ? 'info' : 'error',
    },
    debug: {
      autoFill: envId === 'dev',
      debugStore: envId === 'dev',
      showAppVersion: envId !== 'prod',
      showEnvId: envId !== 'prod',
      showErrorStackTrace: envId !== 'prod',
      showMediaSize: envId === 'dev',
      sentryDsn: envId === 'dev' ? undefined : sentryDsn,
    },
  };
  return config;
}
