import { AppPage, HeaderToolbar, PageContainer } from '_01_CORE/_common-layout';
import {
  AppButtonCompany,
  AppIcons,
  AppLoader,
} from '_01_CORE/_components-core';
import { useAppRouter } from '_01_CORE/_hooks';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { AnimLiveJourneyShoppingCartsListPanel } from './AnimLiveJourneyShoppingCartsListPanel';
import {
  AnimLiveJourneyShoppingCartPageStateState,
  useAnimLiveJourneyShoppingCartPageState,
} from './useAnimLiveJourneyShoppingCartPageState.hook';

export const AnimLiveJourneyShoppingCartDetailsPage = () => {
  const appRouter = useAppRouter();
  const { formatMessage: t } = useIntl();
  const appId = appWebConfig().appId;

  const { customerJourneyId, shoppingCartId } = useParams<{
    customerJourneyId: string;
    shoppingCartId: string;
  }>();

  const localState: AnimLiveJourneyShoppingCartPageStateState = useAnimLiveJourneyShoppingCartPageState(
    {
      context: 'details',
      customerJourneyId,
      shoppingCartId,
    }
  );
  const { operationInProgress, goBackToActivities } = localState;

  return (
    <AppPage displayFooter={false}>
      <HeaderToolbar hideLogout={true} />
      <PageContainer
        className="h-full bg-gray-50 flex flex-col items-stretch text-center"
        spacing="normal"
      >
        {operationInProgress && (
          <AppLoader type={operationInProgress.type} className="my-4" />
        )}
        <AnimLiveJourneyShoppingCartsListPanel localState={localState} />
        <div>
          <AppButtonCompany
            className="flex-grow-0 w-full font-bold"
            isFullWidth={true}
            icon={AppIcons.actionBack}
            onClick={(e) => {
              e.stopPropagation();
              goBackToActivities();
            }}
            style={'cancel'}
          >
            Continuer mes achats
          </AppButtonCompany>
        </div>
      </PageContainer>
    </AppPage>
  );
};
