import { FC } from 'react';
import { AppButtonUnstyledProps } from './AppButtonUnstyled';

export const AppButtonUnstyledInner: FC<AppButtonUnstyledProps> = ({
  children,
  icon,
  iconBadge,
  iconStyleClass,
  displayMode,
}) => {
  const AppButtonIcon = icon;
  const AppBadgeIcon = iconBadge;
  return displayMode === 'tab' ? (
    <div className="w-full flex flex-col items-center gap-1">
      {AppButtonIcon && (
        <AppButtonIcon
          className={`cursor-pointer ${iconStyleClass}`}
          aria-hidden="true"
        />
      )}
      <div className="text-xs">{children}</div>
      {AppBadgeIcon && (
        <AppBadgeIcon
          className={`cursor-pointer ${iconStyleClass}`}
          aria-hidden="true"
        />
      )}
    </div>
  ) : (
    <>
      {AppButtonIcon && (
        <AppButtonIcon
          className={`cursor-pointer ${iconStyleClass}`}
          aria-hidden="true"
        />
      )}
      <span className="flex-grow">{children}</span>
      {AppBadgeIcon && (
        <AppBadgeIcon
          className={`cursor-pointer ${iconStyleClass}`}
          aria-hidden="true"
        />
      )}
    </>
  );
};
