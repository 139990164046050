import { CMCompany, DailySessionsAvailabilities } from 'lib-common-model';
import { useIntl } from 'react-intl';
import { useAppImageUrl } from '_01_CORE/app-core/app-security/useAppImageUrl.hook';

export const AccroparkCompanyActivitiesAvailabilities = ({
  company,
  onConfirm,
  availabilities,
}: {
  company: Pick<CMCompany, 'name' | 'reference' | 'logo' | 'direction'>;
  onConfirm: () => void;
  availableSessionsCount: number;
  availabilities: DailySessionsAvailabilities;
}) => {
  const { formatMessage: t } = useIntl();

  const logoUrl = useAppImageUrl(company?.logo, {
    defaultImage: 'app',
  });

  return !availabilities ? null : (
    <div
      className="border p-3 my-5 cursor-pointer"
      onClick={() => {
        onConfirm();
      }}
    >
      <div
        className={`flex flex-1 justify-left text-left p-2 text-gray-600 font-bold text-gray-800`}
      >
        <img className="max-w-6xs h-10 object-contain mr-2" src={logoUrl} />
        <div className={`ml-2 text-xs`}>
          <div>{company?.name}</div>
          <div className="text-gray-500 text-app-xxs1">
            {company?.direction?.line1}
          </div>
        </div>
      </div>
      {/* <div
        className={`p-2 font-bold ${
          availabilities.availableSessionsCount >= 5
            ? 'bg-app-agenda-available text-gray-700'
            : availabilities.availableSessionsCount > 0
            ? 'bg-app-agenda-busy-1 text-gray-700'
            : 'bg-app-agenda-busy-2 text-gray-200'
        }`}
      >
        TOTAL {t({ id: 'page10.label.available-sessions' })}: {availabilities.availableSessionsCount}
      </div> */}
      {(availabilities.activities ?? []).map((act, i) => (
        <div className="mt-4 first:mt-0" key={i}>
          <div className="p-2 font-bold bg-gray-600 text-white">
            {act.activityName}
          </div>
          <div
            className={`p-2 font-bold ${
              act.availableSessionsCount >= 5
                ? 'bg-app-agenda-available text-gray-700'
                : act.availableSessionsCount > 0
                ? 'bg-app-agenda-busy-1 text-gray-700'
                : 'bg-app-agenda-busy-2 text-gray-200'
            }`}
          >
            {t({ id: 'page10.label.available-sessions' })}:{' '}
            {act.availableSessionsCount}
          </div>
        </div>
      ))}
      {availabilities.availableSessionsCount == 0 && (
        <div className="mt-2 font-bold text-xs text-left text-gray-500">
          {t({ id: 'page10.message.sessions-not-full' })}
        </div>
      )}
    </div>
  );
};
