import { loadableAttributeStoreFactory } from 'lib-web-redux';
import { baseStore } from '../../app-core/app-store/baseStore';
import { AppAuth } from './model/AppAuth.model';

export const authenticationStore = {
  auth: loadableAttributeStoreFactory.create<AppAuth>(
    baseStore,
    'authentication_auth'
  ),
  logoutRequired: loadableAttributeStoreFactory.create<boolean>(
    baseStore,
    'authentication_logoutRequired'
  ),
  redirectToOldVersionPage: loadableAttributeStoreFactory.create<boolean>(
    baseStore,
    'authentication_redirectToOldVersionPage',
    {
      isLoaded: true,
      value: false,
    }
  ),
};
