import { AppCacheContextModel } from '_01_CORE/app-contexts/app-cache-context';
import { companyApiClient } from '_01_CORE/app-core/app-api';
import {
  AppRouteGuard,
  AppRouteGuardContext,
} from '_01_CORE/app-core/app-router';
import { CMFetchedData, CMJourney, LanguageCode } from 'lib-common-model';
export function loadDataGuard({
  routeParamsNames,
  fetchCompanyWelcomeBooklet,
  fetchCompanyMenu,
  platformLanguageCode,
  appLanguageCode,
}: {
  routeParamsNames: {
    groupReference: string;
    companyReference: string;
    customerJourneyId: string;
    bookingId: string;
  };
  fetchCompanyWelcomeBooklet: boolean;
  fetchCompanyMenu?: boolean;
  platformLanguageCode: LanguageCode;
  appLanguageCode: LanguageCode;
}): AppRouteGuard {
  return async (context: AppRouteGuardContext) => {
    const { routeParams } = context;

    const groupReference = routeParams[routeParamsNames.groupReference];
    const companyReference = routeParams[routeParamsNames.companyReference];
    const customerJourneyId = routeParams[routeParamsNames.customerJourneyId];
    const bookingId = routeParams[routeParamsNames.bookingId];

    const { appCacheContext } = context;

    const isDataRequired =
      groupReference || companyReference || customerJourneyId || bookingId;
    if (!isDataRequired) {
      return context; // ok
    }

    const isDataInCache1 =
      !groupReference || !!appCacheContext.groups[groupReference];
    const isDataInCache2 =
      !companyReference || !!appCacheContext.companies[companyReference];
    const isDataInCache3 =
      !customerJourneyId ||
      !!findCachedJourney({
        customerJourneyId,
        appCacheContext,
      });

    const isDataInCache4 =
      !fetchCompanyWelcomeBooklet ||
      !!appCacheContext.companyBooklets[companyReference];

    const isDataInCache5 =
      !bookingId || appCacheContext.booking?._id === bookingId;

    const isDataInCache6 =
      !fetchCompanyMenu || !!appCacheContext.companyRestos[companyReference];

    const isDataInCache =
      isDataInCache1 &&
      isDataInCache2 &&
      isDataInCache3 &&
      isDataInCache4 &&
      isDataInCache5 &&
      isDataInCache6;

    if (isDataInCache) {
      // data already in cache: async fetch (no await keyword!)
      fetchData({
        groupReference,
        companyReference,
        customerJourneyId,
        bookingId,
        fetchCompanyWelcomeBooklet,
        fetchCompanyMenu,
        platformLanguageCode,
        appLanguageCode,
        context,
      });
      // immediately return
      return context; // ok
    }

    // data not in cache: synchronous fetch
    return await fetchData({
      groupReference,
      companyReference,
      customerJourneyId,
      bookingId,
      fetchCompanyWelcomeBooklet,
      fetchCompanyMenu,
      platformLanguageCode,
      appLanguageCode,
      context,
    });
  };
}

function findCachedJourney({
  customerJourneyId,
  appCacheContext,
}: {
  customerJourneyId: string;
  appCacheContext: AppCacheContextModel;
}): CMJourney {
  if (customerJourneyId) {
    return appCacheContext.auth?.customerProfile?.journeys?.find(
      (j) => j._id === customerJourneyId
    );
  }
}

async function fetchData({
  groupReference,
  companyReference,
  customerJourneyId,
  bookingId,
  fetchCompanyWelcomeBooklet,
  fetchCompanyMenu,
  platformLanguageCode,
  appLanguageCode,
  context,
}: {
  groupReference?: string;
  companyReference?: string;
  customerJourneyId?: string;
  bookingId?: string;
  fetchCompanyWelcomeBooklet: boolean;
  fetchCompanyMenu: boolean;
  platformLanguageCode: LanguageCode;
  appLanguageCode: LanguageCode;
  context: AppRouteGuardContext;
}) {
  const { appCacheContext } = context;

  const customerJourney =
    customerJourneyId &&
    findCachedJourney({ customerJourneyId, appCacheContext });
  if (customerJourney && !companyReference) {
    // fetch journey company as well
    companyReference = customerJourney?.company?.reference;
  }
  if (customerJourney && !groupReference) {
    // fetch journey company as well
    groupReference = customerJourney?.group?.reference;
  }

  const company =
    companyReference && appCacheContext.companies[companyReference];
  if (company && !groupReference) {
    // fetch company group as well
    groupReference = company?.companyGroupReference;
  }

  const group = groupReference && appCacheContext.groups[groupReference];
  const booking = bookingId && appCacheContext.booking;

  const bookingUpdateDate: Date = booking?._updatedAt;
  const groupUpdateDate: Date = group?.lastDataUpdate;
  const companyUpdateDate: Date = company?.lastDataUpdate;
  const customerJourneyUpdateDate: Date = customerJourney?.lastDataUpdate;
  const notificationsUpdateDate = appCacheContext.notificationsUpdateDate;

  const cachedBooklet = fetchCompanyWelcomeBooklet
    ? appCacheContext.companyBooklets[companyReference]
    : undefined;

  const fetchedData: CMFetchedData = await companyApiClient.fetchCustomerData({
    authenticate: appCacheContext.auth?.isAuthenticated,
    groupReference,
    companyReference,
    customerJourneyId,
    groupUpdateDate,
    bookingUpdateDate,
    bookingId,
    companyUpdateDate,
    customerJourneyUpdateDate,
    notificationsUpdateDate,
    platformLanguageCode,
    appLanguageCode,
    fetchCompanyWelcomeBooklet,
    fetchCompanyMenu,
    companyWelcomeBookletLanguageCode: cachedBooklet?.languageCode,
    companyWelcomeBookletUpdateDate: cachedBooklet?.updatedAt,
  });
  appCacheContext.dispatchCacheContextAction({
    type: 'set-fetched-data',
    fetchedData,
    companyReference,
    context: 'load-guard-fetch-customer-data',
  });

  return context;
}
