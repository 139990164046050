import { useAppRouter } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import { useAppCacheContext } from '_01_CORE/app-contexts';
import {
  CMCustomerAccount,
  CMExtJourneyFinderResult,
  CMProfileMember,
} from 'lib-common-model';
import { useCallback } from 'react';
import { AuthMultiLoginAnimLivePageBaseLocalState } from '../useAuthMultiLoginAnimLivePageLocalState.hook';

export function useAuthMultiLoginAction_StartExtJourneyActivation(
  baseLocalState: AuthMultiLoginAnimLivePageBaseLocalState
) {
  const appCacheContext = useAppCacheContext();
  const { dispatchCacheContextAction } = useAppCacheContext();
  const appRouter = useAppRouter();

  const startExtJourneyActivation = useCallback(
    ({
      customerAccount,
      customerAccountMembers,
      fetchedJourney,
      emailAddress,
    }: {
      customerAccount: CMCustomerAccount;
      customerAccountMembers: CMProfileMember[];
      fetchedJourney: CMExtJourneyFinderResult;
      emailAddress: string;
    }) => {
      const companyReference =
        fetchedJourney?.companyReference ??
        appCacheContext?.companyValidReferenceCode;
      const activationToken = fetchedJourney?.activationToken;

      if (fetchedJourney && emailAddress?.trim()?.length) {
        fetchedJourney = {
          ...fetchedJourney,
          fakeCustomerAccount: {
            ...fetchedJourney?.fakeCustomerAccount,
            email: emailAddress?.trim(), // on remplace l'email par celui qui a été testé
          },
        };
      }

      dispatchCacheContextAction({
        type: 'set-journey-to-edit-from-external-data',
        payload: {
          companyReference,
          externalData: fetchedJourney,
          activationToken,
          customerAccount,
          customerAccountMembers,
        },
      });
      appRouter.navigate(
        appRoutesBuilder.getJourneyCreateParticipantsListUrl({
          companyReference,
        }),
        { cause: 'create-journey-from-external' }
      );
    },
    [
      appCacheContext?.companyValidReferenceCode,
      appRouter,
      dispatchCacheContextAction,
    ]
  );
  return startExtJourneyActivation;
}

export type AuthMultiLoginAction_StartExtJourneyActivation = ReturnType<
  typeof useAuthMultiLoginAction_StartExtJourneyActivation
>;
