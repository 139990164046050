import { AppPriceLabel } from '_01_CORE/_common-layout';
import { AppIcons, dateTransformer } from '_01_CORE/_components-core';
import { AnimLiveShoppingCartTableFormModel } from '_04_ANIM_LIVE/ECO-ecommerce/ECO-31-journey-shopping-cart/AnimLiveJourneyShoppingCartPanel';
import clsx from 'clsx';
import {
  APP_CURRENCIES_MAP,
  CMShoppingCartResume,
  CMShoppingCartResumeCartItem,
} from 'lib-common-model';
import { useMemo } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { AnimLiveShoppingCartTableItemRow } from './AnimLiveShoppingCartTableItemRow';
import { useShoppingCartItemsByPaymentMode } from './useShoppingCartItemsByPaymentMode.hook';

export type CMShoppingCartResumeCartItemByPaymentModeMap = {
  online: CMShoppingCartResumeCartItem[];
  onSite: CMShoppingCartResumeCartItem[];
  toChoose: CMShoppingCartResumeCartItem[];
};

export const AnimLiveShoppingCartTable = ({
  shoppingCartResume,
  form,
  mode,
}: {
  shoppingCartResume: CMShoppingCartResume;
  form: UseFormReturn<AnimLiveShoppingCartTableFormModel, any>;
  mode: 'resume' | 'details';
}) => {
  const appCurrency =
    APP_CURRENCIES_MAP[shoppingCartResume?.shoppingCart?.purchase?.currency];

  const { formatMessage: t } = useIntl();
  const currencySymbol =
    APP_CURRENCIES_MAP[shoppingCartResume?.shoppingCart?.purchase.currency]
      ?.symbol;

  const { shoppingCart, shoppingCartItems } = shoppingCartResume;

  const itemsCount = useMemo(
    () =>
      shoppingCartItems.reduce(
        (acc, item) => acc + parseInt((item.quantity as unknown) as string, 10),
        0
      ),
    [shoppingCartItems]
  );

  const {
    register,
    handleSubmit,
    watch,
    formState,
    control,
    setValue,
    reset,
  } = form;

  const [paymentMode] = useWatch({
    control,
    name: ['paymentMode'],
  });

  const shoppingCartItemsByPaymentMode: CMShoppingCartResumeCartItemByPaymentModeMap = useShoppingCartItemsByPaymentMode(
    shoppingCartItems,
    shoppingCart
  );

  return (
    <div className="w-full flex flex-col shadow-md border rounded-md text-left mb-4 bg-white text-sm">
      <div className="bg-gray-600 text-white py-1 px-2 rounded-md flex gap-2 items-center">
        <AppIcons.shoppingCart className="h-5 w-5" />
        <div className="text-base font-semibold">
          {shoppingCart.status === 'draft'
            ? t({ id: 'page27-booking-resume.title.my-shooping-cart' })
            : t(
                { id: 'page27-booking-resume.title.order' },
                {
                  orderDate: dateTransformer.format(
                    shoppingCartResume?.shoppingCart?.purchase?.date,
                    'dd/MM/yyyy'
                  ),
                  orderTime: dateTransformer.format(
                    shoppingCartResume?.shoppingCart?.purchase?.date,
                    'HH:mm'
                  ),
                }
              )}
        </div>
        {/* {company?.description && (
          <div className="text-sm  font-normal">{company.description}</div>
        )} */}
      </div>
      {/* offers */}
      {mode === 'details' && (
        <>
          {(shoppingCartItemsByPaymentMode.online?.length > 0 ||
            (paymentMode === 'online' &&
              shoppingCartItemsByPaymentMode.toChoose?.length > 0)) && (
            <div>
              <>
                <div className="flex gap-1 font-bold uppercase text-gray-800 py-1 px-2 items-center border-b">
                  <AppIcons.paymentOnline className="inline w-5 h-5 text-blue-400" />
                  {/* <AppIcons.paymentOnline className="inline w-5 h-5 mr-1 text-gray-600" /> */}
                  Paiement en ligne par CB
                </div>
                {shoppingCartItemsByPaymentMode.online.map((item, i) => (
                  <AnimLiveShoppingCartTableItemRow
                    key={item._id}
                    item={item}
                  />
                ))}
                {paymentMode === 'online' &&
                  shoppingCartItemsByPaymentMode.toChoose?.length > 0 && (
                    <>
                      {shoppingCartItemsByPaymentMode.toChoose.map(
                        (item, i) => (
                          <AnimLiveShoppingCartTableItemRow
                            key={item._id}
                            item={item}
                          />
                        )
                      )}
                    </>
                  )}
              </>
            </div>
          )}

          {(shoppingCartItemsByPaymentMode.onSite?.length > 0 ||
            (paymentMode === 'on-site' &&
              shoppingCartItemsByPaymentMode.toChoose?.length > 0)) && (
            <div>
              <>
                <div className="flex gap-1 font-bold uppercase text-gray-800 py-1 px-2 items-center border-b">
                  <AppIcons.paymentOnSite className="inline w-5 h-5 text-green-400" />
                  {/* <AppIcons.location className="inline w-5 h-5 text-gray-600" /> */}
                  <div className="">Paiement sur place</div>
                  <div className="bg-gray-600 text-white px-1.5">
                    {/* {dailyActivity.activitySchedule.payment?.paymentLocation} */}
                  </div>
                </div>
                {shoppingCartItemsByPaymentMode.onSite.map((item, i) => (
                  <AnimLiveShoppingCartTableItemRow
                    key={item._id}
                    item={item}
                  />
                ))}
                {paymentMode === 'on-site' &&
                  shoppingCartItemsByPaymentMode.toChoose?.length > 0 && (
                    <>
                      {shoppingCartItemsByPaymentMode.toChoose.map(
                        (item, i) => (
                          <AnimLiveShoppingCartTableItemRow
                            key={item._id}
                            item={item}
                          />
                        )
                      )}
                    </>
                  )}
              </>
            </div>
          )}
        </>
      )}
      {/* Sub-total */}
      {
        <div className="grid grid-cols-4 gap-x-4 text-right px-2 border-b py-1 sm:grid-cols-6">
          {mode === 'details' ? (
            <>
              <div className="col-span-3 sm:col-span-5">
                {t({ id: 'vat' }, { pct: 20 })}:
              </div>
              <div>
                {Math.round(shoppingCart.purchase.totalTax).toFixed(2)}{' '}
                {currencySymbol}
              </div>
            </>
          ) : (
            <div className="col-span-4 sm:col-span-6">
              {itemsCount} produits
            </div>
          )}
          <div
            className={clsx(
              'col-span-3 sm:col-span-5',
              shoppingCart.purchase.dueBalance === 0 && 'font-bold'
            )}
          >
            {t({ id: 'accropark.ecommerce.booking-card.total' })}:
          </div>
          <div className="font-bold">
            {shoppingCart.purchase.totalPurchase.toFixed(2)} {currencySymbol}
          </div>
          {shoppingCart.purchase.dueBalance !== 0 && (
            <>
              <>
                <div className="col-span-3 sm:col-span-5 font-bold text-green-400">
                  Déjà payé
                </div>
                <div className="font-bold text-green-400">
                  <AppPriceLabel
                    amount={-shoppingCart.purchase.dueBalance}
                    appCurrency={appCurrency}
                    addSpacesToLargeNumbers={true}
                  />
                </div>
              </>
            </>
          )}
          {shoppingCart.purchase.dueBalance !== 0 && (
            <>
              <>
                <div className="col-span-3 sm:col-span-5 font-bold">
                  Reste à payer
                </div>
                <div className="font-bold">
                  <AppPriceLabel
                    amount={shoppingCart.purchase.totalToPayNow}
                    appCurrency={appCurrency}
                    addSpacesToLargeNumbers={true}
                  />
                </div>
              </>
            </>
          )}
        </div>
      }

      {/* Solde */}
      {
        <div className="p-2">
          {shoppingCart.paymentStatus === 'payment-accepted' && (
            <>
              <div className="text-success font-bold">
                {t({ id: 'booking.payment.confirmed' })}
              </div>
            </>
          )}
          {shoppingCart.paymentStatus === 'payment-later' && (
            <>
              <div className="text-danger font-bold">Paiement sur place</div>
            </>
          )}
          {mode === 'resume' &&
            shoppingCart.paymentStatus === 'payment-pending' && (
              <>
                <div className="text-danger font-bold">Paiement en attente</div>
              </>
            )}
        </div>
      }
    </div>
  );
};
