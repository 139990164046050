// import CameraIcon from 'assets/images/icons/camera.svg';
import { CMJourneyActivitySessionParticipant } from 'lib-common-model';
import { AppIcons } from '_01_CORE/_components-core';

export const ParticipantActivityPackageLabel = ({
  participantResume,
  className,
}: {
  participantResume: Pick<
    CMJourneyActivitySessionParticipant,
    'packCredits' | 'packType'
  >;
  className?: string;
}) => {
  const credits = participantResume.packCredits;
  return credits ? (
    <div className={className}>
      <div className="text-app-xxs1 px-1 py-0.5 uppercase font-bold rounded bg-white border border-gray-800 text-gray-800 flex items-center gap-1">
        <AppIcons.activityPackage className={`w-3.5 h-3.5`} />
        {participantResume.packType === 'illimited' && (
          <span className="">illimité</span>
        )}
        {participantResume.packType === 'credits' && (
          <span>
            {credits.used} / {credits.total}
          </span>
        )}
      </div>
    </div>
  ) : null;
};
