import {
  IonButton,
  IonIcon,
  IonItemGroup,
  IonRouterLink,
  IonRow,
  IonText,
  IonToggle,
} from '@ionic/react';
import {
  AccountCreationTitle,
  AppPage,
  FooterActions,
  HeaderToolbar,
  PageContainer,
} from '_01_CORE/_common-layout';
import { JourneyParticipantCard } from '_01_CORE/_components-core';
import {
  useAppRouter,
  useAppToasts,
  useJourneyCancellation,
} from '_01_CORE/_hooks';
import { ArrowRight } from '_01_CORE/_icons';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import {
  useAppCacheContext,
  useCompany,
  useJourneyEnabledActions,
} from '_01_CORE/app-contexts';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import { useAppSettings } from '_01_CORE/app-core/app-security';
import { add, chevronBackOutline } from 'ionicons/icons';
import { CMJourneyEditData } from 'lib-common-model';
import { appLogger } from 'lib-web-logger';
import { useCallback, useEffect } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { JourneyParticipantsListFormData } from '../JourneyParticipantsListFormData.type';
import { useJourneyCreateParticipantsListAnimLiveInitialState } from './useJourneyCreateParticipantsListAnimLiveInitialState.hook';

export const JourneyCreateParticipantsListAnimLivePage = () => {
  const appRouter = useAppRouter();
  const { formatMessage: t } = useIntl();
  const appCacheContext = useAppCacheContext();
  const { auth, journeyEditData, dispatchCacheContextAction } = appCacheContext;
  const appId = appWebConfig().appId;

  const { companyReference } = useParams<{ companyReference: string }>();
  const { company } = useCompany({ companyReference });
  const { pushError } = useAppToasts();
  const { openModalCancelJourneyDraft } = useJourneyCancellation({
    companyReference,
  });

  const {
    defaultValues,
    editParticipants,
    remainingExternalParticipantsToCreate,
  } = useJourneyCreateParticipantsListAnimLiveInitialState();

  useEffect(() => {
    if (remainingExternalParticipantsToCreate?.length) {
      appRouter.navigate(
        appRoutesBuilder.getJourneyCreateParticipantCreateUrl({
          companyReference,
        }),
        {
          cause:
            'remaining participants to create from external import in journey',
        }
      );
    } else if (!defaultValues?.participants?.length) {
      // redirect to first participant creation
      appRouter.navigate(
        appRoutesBuilder.getJourneyCreateParticipantCreateUrl({
          companyReference,
        }),
        { cause: 'no participants in journey' }
      );
    }
  }, [
    appCacheContext,
    appRouter,
    companyReference,
    defaultValues,
    defaultValues?.participants?.length,
    journeyEditData,
    remainingExternalParticipantsToCreate?.length,
  ]);

  const {
    handleSubmit,
    formState,
    control,
    reset,
  } = useForm<JourneyParticipantsListFormData>({
    defaultValues,
    mode: 'onChange',
  });
  const errors = formState.errors;

  useEffect(() => {
    reset(defaultValues); // reset form if defaultValues changes as pages are memoized
  }, [defaultValues, reset]);

  const formParticipants = useWatch({
    control,
    name: 'participants',
  });
  const formParticipantsEnabledCount = formParticipants.filter(
    (x: any) => x.enabled
  ).length; // useMemo(() => formParticipants.filter((x) => x.enabled).length, [formParticipants]);

  const appSettings = useAppSettings();

  const { isAddJourneyParticipantEnabled } = useJourneyEnabledActions({});

  const whiteLabel = appSettings?.universe?.whiteLabel;
  /**
   * Choose another destination button callback
   */
  const handleChooseDestination = useCallback(() => {
    appRouter.navigate(
      appRoutesBuilder.getJourneyCompanyChooseUrl({
        companyReference: companyReference,
        whiteLabel,
      }),
      { cause: 'participant-list-select-company' }
    );
  }, [appRouter, companyReference, whiteLabel]);

  /*
   * Add a participant : redirection to add a new one
   */
  const handleAddParticipant = () => {
    appRouter.navigate(
      appRoutesBuilder.getJourneyCreateParticipantCreateUrl({
        companyReference,
      }),
      { cause: 'participant-list-add-participant' }
    );
  };

  /*
   * Click on a participant : redirect to edit
   */
  const handleEditParticipant = (participantIndex: number) => {
    handleSubmit(
      (data) => {
        saveFormData(data);
        appRouter.navigate(
          appRoutesBuilder.getJourneyParticipantEditUrl({
            companyReference,
            participantIndex,
          }),
          {
            cause: 'participant-list-edit-participant',
          }
        );
      },
      (err) => {
        // TODO: navigate anyway: changes are lost
        console.log('Invalid form', err);
        appRouter.navigate(
          appRoutesBuilder.getJourneyParticipantEditUrl({
            companyReference,
            participantIndex,
          }),
          {
            cause: 'participant-list-edit-participant',
          }
        );
      }
    )();
  };

  /*
   * Submit form callback
   */
  const saveFormData = (data: JourneyParticipantsListFormData) => {
    appLogger.debug('submit data', data);
    const isThereAtLeast1Participant = !!data.participants.filter(
      (p) => p.enabled
    ).length;

    if (!isThereAtLeast1Participant) {
      return pushError(t({ id: `page13.participantMissing.${appId}` }));
    }

    // Save in redux cache
    const payload: Pick<
      CMJourneyEditData,
      'journey' | 'participants' | 'allowedPeopleList'
    > = {
      journey: data.journey,
      participants: editParticipants.map((p, i) => ({
        ...p,
        enabled: data.participants[i].enabled,
      })),
      allowedPeopleList: data.allowedPeople4Array ?? [].filter((x) => !!x.name),
    };
    dispatchCacheContextAction({
      type: 'update-journey-participants-list',
      payload,
    });
  };

  const onSubmitForm = () => {
    handleSubmit(
      (data) => {
        saveFormData(data);
        appRouter.navigate(
          appRoutesBuilder.getJourneyCreateDetailsAnimLiveUrl({
            companyReference,
          }),
          { cause: 'participant-list-submit' }
        );
      },
      (err) => {
        // TODO
        console.log('Invalid form', err);
      }
    )();
  };

  const goBack = () => {
    appRouter.navigate(
      appRoutesBuilder.getJourneyDatesEditUrl({ companyReference }),
      { cause: 'participant-list-back' }
    );
  };

  return !journeyEditData || !defaultValues?.participants?.length ? null : (
    <AppPage displayFooter={false}>
      <HeaderToolbar hideLogout={true} />
      <PageContainer className="text-center bg-gray-50" spacing="normal">
        <AccountCreationTitle
          company={company}
          displayAddress={true}
          displayButtonChooseDestination={
            !whiteLabel.appCompanyReference && auth?.isAuthenticated
          }
          buttonText={t({ id: `page10.choose.${appId}` })}
          onClick={handleChooseDestination}
        />

        <div className="flex mb-2 place-items-center">
          <IonRouterLink className="cursor-pointer" onClick={() => goBack()}>
            <IonIcon className="text-primary" icon={chevronBackOutline} />
          </IonRouterLink>
          <div className="ml-8 text-primary text-left flex-grow place-self-start text-xl mm:text-2xl ml:text-3xl sm:text-4xl">
            {t({ id: 'page13.whoIsParticipating' })}
          </div>
          <div className="flex-1 self-end flex-grow-0">
            <ArrowRight className="w-16 h-16 mr-8 text-primary" />
          </div>
        </div>

        {editParticipants.length
          ? editParticipants.map((participant, index) => (
              <div className="w-full mb-2" key={`participant-${index}`}>
                <div className="flex">
                  <div
                    className="flex-1 text-left p-2 bg-white rounded-lg"
                    onClick={() => handleEditParticipant(index)}
                  >
                    <JourneyParticipantCard
                      customerJourneyId={undefined}
                      participant={participant}
                    />
                  </div>
                  <div className="flex-none text-right p-2 bg-white rounded-lg">
                    <Controller
                      render={({ field: { value, onChange } }) => (
                        <IonToggle
                          color="primary"
                          className="-mb-6"
                          onIonChange={(e) => onChange(e.detail.checked)}
                          checked={value}
                        />
                      )}
                      control={control}
                      name={`participants[${index}].enabled` as any}
                      defaultValue=""
                    />
                  </div>
                </div>
              </div>
            ))
          : null}

        <div className="text-center p-4">
          {isAddJourneyParticipantEnabled && (
            <IonItemGroup>
              <IonRow className="ion-justify-content-center">
                <IonButton
                  type="submit"
                  color="success"
                  onClick={handleAddParticipant}
                  shape="round"
                  className="w-72"
                >
                  <IonIcon className="mr-2" icon={add} />
                  {t({ id: 'page12.addParticipantButton' })}
                </IonButton>
              </IonRow>
            </IonItemGroup>
          )}

          {isAddJourneyParticipantEnabled && (
            <IonText className="block font-bold mt-2" color="tertiary">
              ⚠️ {t({ id: 'page12.warning.addAllParticipants' })}
            </IonText>
          )}
          <IonText className="italic" color="tertiary">
            {t({ id: 'page12.warning.details' })}
          </IonText>
        </div>

        <div className="text-right font-bold text-2xl mr-4">
          {t({ id: 'page13.total' }, { total: formParticipantsEnabledCount })}
        </div>
      </PageContainer>
      <FooterActions
        onCancel={openModalCancelJourneyDraft}
        onBack={
          journeyEditData?.externalData
            ? undefined // pas de bouton de retour aux dates du séjour si c'est un séjour PMS
            : goBack
        }
        onSubmit={onSubmitForm}
      />
    </AppPage>
  );
};
