import { useAppImageUrl } from '_01_CORE/app-core/app-security/useAppImageUrl.hook';
import { CMProfileMember, JourneyParticipant } from 'lib-common-model';
import React from 'react';
import { CustomerGenderIcon } from './CustomerGenderIcon';
import './ParticipantAvatar.css';

export const ParticipantAvatar: React.FC<{
  participant: {
    member: Pick<
      CMProfileMember,
      'gender' | 'firstName' | 'lastName' | 'photo'
    >;
    attributes: Pick<JourneyParticipant, 'journeyAge'>;
  };
  size?: number;
  withBorder?: boolean;
  selected?: boolean;
  disabled?: boolean;
  waitingList?: boolean;
  className?: string;
}> = ({
  participant,
  size,
  withBorder,
  selected,
  disabled,
  waitingList,
  className,
}) => {
  size = size ?? 12;
  withBorder = withBorder === undefined ? true : withBorder;
  selected = selected === undefined ? true : selected;
  disabled = disabled === undefined ? false : disabled;
  waitingList = waitingList === undefined ? false : waitingList;

  const logo = useAppImageUrl(participant.member?.photo, {
    defaultImage: 'none',
  });

  const gender = participant.member?.gender;

  let classRoot = 'rounded-full truncate border-2 border-white';
  if (withBorder) {
    classRoot += ` ring-2`;
    if (selected) {
      classRoot += ` ${
        gender === 'male'
          ? 'ring-blue-500'
          : gender === 'female'
          ? 'ring-pink-500'
          : waitingList
          ? 'ring-app-warning'
          : 'ring-white'
      }`;
    } else {
      classRoot += waitingList ? ' ring-app-warning' : ' ring-gray-300';
    }
  }

  return disabled ? (
    <div
      className={`${classRoot} ${className} disabled-participant-avatar rounded-full truncate cursor-not-allowed border-2 border-gray-400 w-${size} h-${size}`}
    />
  ) : (
    <div className={`  ${classRoot} ${className}`}>
      {logo ? (
        <img
          className={`w-${size} h-${size} object-cover object-center`}
          src={logo}
        />
      ) : (
        <CustomerGenderIcon
          className={``}
          size={size}
          isMinor={participant.attributes?.journeyAge < 18}
          gender={gender}
          isSelected={true}
        />
      )}
    </div>
  );
};
