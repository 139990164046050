import { dateTransformer } from '_01_CORE/_components-core';
import { journeyHelper } from '_01_CORE/_services';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import { persistentCache } from '_01_CORE/app-core/app-platform';
import { CustomerAccountEditFormData } from '_02_APP_COMMON/_model';
import {
  AppClientNotification,
  CMCustomerAccount,
  CMExtJourneyFinderResult,
  CMJourney,
  CMJourneyEditCustomerAccount,
  CMJourneyEditData,
  CMJourneyEditJourney,
  CMJourneyEditParticipant,
  CMProfileMember,
  CustomerAccount,
  CustomerAccountMember,
} from 'lib-common-model';
import { appLogger } from 'lib-web-logger';
import { APP_CURRENT_REGISTRATION_BROWSER_STORAGE_ID } from '../../../app-core/app-bootstrap/APP_CURRENT_REGISTRATION_BROWSER_STORAGE_ID.const';
import { AppCacheContextState } from '../model';
import { AppCacheContextReducerAction } from './AppCacheContextReducerAction.type';
import { appCacheContextSetAuth } from './actions';
import { appCacheContextBuilder } from './appCacheContextBuilder.service';
import { journeyEditStateBuilder } from './journeyEditStateBuilder.service';

export const APP_CONTEXT_INITIAL_STATE: AppCacheContextState = {
  loaded: false,
  companies: {},
  groups: {},
  companyBooklets: {},
  companyRestos: {},
  notifications: [],
  activitySessionsToDisplay: [],
  unreadNotification: false,
};

export function appCacheContextReducer(
  state: AppCacheContextState,
  action: AppCacheContextReducerAction
): AppCacheContextState {
  appLogger.debug(
    `[appCacheContextReducer] reduce '${action.type}' (context: ${action.context})`
  );

  switch (action.type) {
    case 'set-auth': {
      return appCacheContextSetAuth(state, action.auth);
    }
    case 'set-profile': {
      return {
        ...state,
        auth: {
          ...state.auth,
          customerProfile: action.profile,
        },
      };
    }

    case 'set-notifications': {
      const notifications = action.notifications;
      const forceUnreadNotification = action.forceUnreadNotification;
      return appCacheContextBuilder.updateStateNotifications({
        notifications,
        state,
        forceUnreadNotification,
      });
    }
    case 'set-notification-to-display': {
      const notificationToDisplay: AppClientNotification =
        action.notificationToDisplay;
      return {
        ...state,
        notificationToDisplay,
      };
    }
    case 'redirect-to': {
      return {
        ...state,
        redirectTo: action.path
          ? {
              path: action.path,
              cause: action.context,
            }
          : undefined,
      };
    }

    case 'set-booking': {
      return {
        ...state,
        booking: action.booking,
      };
    }
    case 'set-company': {
      const company = action.company;

      const updateCompanyValidReferenceCode =
        action.updateCompanyValidReferenceCode;
      return appCacheContextBuilder.updateStateCompany({
        state,
        company,
        updateCompanyValidReferenceCode,
      });
    }

    case 'set-journey': {
      const customerJourney = action.customerJourney;
      return appCacheContextBuilder.updateStateJourney({
        customerJourney,
        state,
      });
    }
    case 'select-company': {
      const companyReference = action.companyReference;
      appLogger.debug(
        `[appCacheContextReducer] set-valid-company:'${companyReference}'`
      );

      return {
        ...state,
        companyValidReferenceCode: companyReference,
      };
    }
    case 'update-journey-status': {
      const customerJourneyId = action.payload.customerJourneyId;
      const status = action.payload.status;
      if (!state.auth) {
        return state;
      }
      return {
        ...state,
        auth: {
          ...state.auth,
          customerProfile: {
            ...state.auth.customerProfile,
            journeys: state.auth.customerProfile.journeys.map((journey) => {
              if (journey._id === customerJourneyId) {
                return {
                  ...journey,
                  status,
                };
              }
              return journey;
            }),
          },
        },
      };
    }
    case 'set-group': {
      const group = action.group;
      if (!group) {
        return state;
      }
      appLogger.debug(
        `[appCacheContextReducer] set-group: '${group?.reference}'`
      );
      const groups = {
        ...state.groups,
      };
      // update groups cache
      groups[group.reference] = group;
      return {
        ...state,
        groups: groups,
      };
    }
    case 'set-fetched-data': {
      const fetchedData = action.fetchedData;
      const companyReference = action.companyReference;
      let localState = {
        ...state,
      };

      if (fetchedData?.booking) {
        localState.booking = fetchedData?.booking;
      }
      if (fetchedData?.group) {
        const group = fetchedData?.group;
        localState.groups = {
          ...localState.groups,
        };
        // update groups cache
        localState.groups[group.reference] = group;
      }
      if (fetchedData?.company) {
        const company = fetchedData?.company;
        localState = appCacheContextBuilder.updateStateCompany({
          state: localState,
          company,
          updateCompanyValidReferenceCode: true,
        });
      }
      if (fetchedData?.customerJourney) {
        const customerJourney = fetchedData?.customerJourney;
        localState = appCacheContextBuilder.updateStateJourney({
          state: localState,
          customerJourney,
        });
      }

      if (fetchedData?.customerMessages) {
        localState = appCacheContextBuilder.updateStateNotifications({
          state: localState,
          notifications: fetchedData?.customerMessages,
        });
      }
      if (fetchedData?.companyWelcomeBooklet) {
        const booklet = fetchedData?.companyWelcomeBooklet;
        if (companyReference && booklet) {
          // update companyBooklets cache
          const companyBooklets = {
            ...localState.companyBooklets,
          };
          companyBooklets[companyReference] = booklet;

          localState = {
            ...localState,
            companyBooklets,
          };
        }
      }
      if (fetchedData?.companyRestos) {
        const menus = fetchedData?.companyRestos;
        if (companyReference && menus) {
          // update companyRestos cache
          const companyRestos = {
            ...(localState.companyRestos ?? {}),
          };
          companyRestos[companyReference] = menus;

          localState = {
            ...localState,
            companyRestos,
          };
        }
      }
      if (fetchedData?.activitySessionsToDisplay) {
        localState.activitySessionsToDisplay =
          fetchedData?.activitySessionsToDisplay;
      }

      return localState;
    }
    case 'restore-journey-edit-data-from-cache': {
      const journeyEditData = action.journeyEditData;

      return {
        ...state,
        journeyEditData,
      };
    }
    case 'replace-journey': {
      const j = action.payload.journey;
      const ca = action.payload.customerAccount;
      const journey: CMJourneyEditJourney = journeyEditStateBuilder.buildEditJourney(
        {
          ...j,
          // beginDateUTC: undefined,
          // endDateInclusiveUTC: undefined,
          campingPlaceNumber: undefined,
          immatriculation: undefined,
          arrivalTime: undefined,
        }
      );
      const customerAccount: CMJourneyEditCustomerAccount = journeyEditStateBuilder.buildEditCustomerAccount(
        ca
      );
      const participants: CMJourneyEditParticipant[] = j.participants.map((p) =>
        journeyEditStateBuilder.buildEditParticipant(p)
      );
      const journeyEditData: CMJourneyEditData = {
        mode: 'replace',
        replaceFromCustomerJourneyId: j._id,
        companyReference: j.company.reference,
        journey,
        participants,
        allowedPeopleList: journey.allowedPeopleList,
        customerAccount: customerAccount,
      };

      return {
        ...state,
        journeyEditData,
      };
    }
    case 'delete-journey': {
      const customerJourneyId = action.customerJourneyId;
      if (customerJourneyId && state.auth?.customerProfile) {
        return {
          ...state,
          auth: {
            ...state.auth,
            customerProfile: {
              ...state.auth.customerProfile,
              journeys: state.auth.customerProfile.journeys.filter(
                (j) => j._id !== customerJourneyId
              ),
            },
          },
        };
      }
      return state;
    }
    case 'set-journey-to-edit': {
      const j = action.payload.journey;
      const ca = action.payload.customerAccount;
      const journey: CMJourneyEditJourney = journeyEditStateBuilder.buildEditJourney(
        j
      );
      const customerAccount: CMJourneyEditCustomerAccount = journeyEditStateBuilder.buildEditCustomerAccount(
        ca
      );
      const participants: CMJourneyEditParticipant[] = j.participants.map((p) =>
        journeyEditStateBuilder.buildEditParticipant(p)
      );
      const journeyEditData: CMJourneyEditData = {
        mode: 'edit',
        customerJourneyId: j._id,
        companyReference: j.company.reference,
        journey,
        participants,
        allowedPeopleList: journey.allowedPeopleList,
        customerAccount: customerAccount,
      };

      saveJourneyEditDataToBrowserStorage(journeyEditData);
      return {
        ...state,
        journeyEditData,
      };
    }
    case 'set-journey-to-edit-from-external-data': {
      const {
        externalData,
        companyReference,
        customerAccount: ca,
        customerAccountMembers: caMembers,
      } = action.payload;
      const {
        fakeCustomerJourney,
        fakeCustomerJourneyMembers,
        fakeCustomerAccount,
      } = externalData;

      let customerAccount: CMJourneyEditCustomerAccount = journeyEditStateBuilder.buildEditCustomerAccount(
        ca
      );
      if (!customerAccount) {
        customerAccount = {
          _id: undefined,
          consent: false,
          electronicSignature: false,
          mongoAccountUserId: undefined,
          password: undefined,
          gender: fakeCustomerAccount.gender,
          language: fakeCustomerAccount.language,
          firstName: fakeCustomerAccount.firstName,
          lastName: fakeCustomerAccount.lastName,
          phone1: fakeCustomerAccount.phone1,
          phone2: fakeCustomerAccount.phone2,
          email: fakeCustomerAccount.email,
          country: fakeCustomerAccount.country,
          settings: fakeCustomerAccount.settings,
          locationArea: fakeCustomerAccount.locationArea,
        };
      }

      const journey: CMJourneyEditJourney = {
        beginDateUTC: fakeCustomerJourney?.beginDateUTC,
        endDateInclusiveUTC: fakeCustomerJourney?.endDateInclusiveUTC,
        campingPlaceNumber: fakeCustomerJourney?.campingPlaceNumber,
        extCustomerJourneyBookingGroupId:
          externalData.extCustomerJourneyBookingGroupId,
      };

      const remainingExternalParticipantsToCreate: CMJourneyEditParticipant[] = buildRemainingExternalParticipantsToCreate(
        {
          fakeCustomerJourneyMembers,
          caMembers,
          journey,
          fakeCustomerAccount,
          ca,
          customerAccount,
          externalData,
        }
      );
      const participants: CMJourneyEditParticipant[] = [];

      const journeyEditData: CMJourneyEditData = {
        mode: 'create',
        externalData,
        replaceFromCustomerJourneyId: undefined, // 'replace' mode only
        customerJourneyId: undefined, // 'edit' mode only
        companyReference,
        journey,
        participants,
        remainingExternalParticipantsToCreate,
        allowedPeopleList: [],
        customerAccount: customerAccount,
      };

      saveJourneyEditDataToBrowserStorage(journeyEditData);
      return {
        ...state,
        journeyEditData,
      };
    }
    case 'update-journey-company-and-dates': {
      const payload = action.payload;
      // ALWAYS UPDATE DATES + CAMPING TOGETHER (to be sure journey matchs camping opening periods)

      const journeyEditData: CMJourneyEditData = state.journeyEditData
        ? {
            ...state.journeyEditData,
            companyReference: payload.companyReference,
          }
        : journeyEditStateBuilder.buildInitialJourneyEditData({
            companyReference: payload.companyReference,
            customerAccountId: state.auth?.customerProfile?.account?._id,
          });
      journeyEditData.journey = {
        ...journeyEditData.journey,
        beginDateUTC: payload.beginDateUTC,
        endDateInclusiveUTC: payload.endDateInclusiveUTC,
        campingPlaceNumber: payload.campingPlaceNumber,
      };
      if (
        journeyEditData?.participants?.length &&
        new Date(
          state.journeyEditData.journey?.endDateInclusiveUTC
        ).getTime() !== new Date(payload.endDateInclusiveUTC).getTime()
      ) {
        journeyEditStateBuilder.updateParticipantAgeOnJourneyDateChanges(
          journeyEditData
        );
      } else if (
        appWebConfig().appId === 'anim-live' &&
        journeyEditData?.participants?.length === 0
      ) {
        journeyEditData.participants = journeyHelper.buildInitialParticipants({
          journeyEditData,
          auth: state.auth,
        });
      }

      saveJourneyEditDataToBrowserStorage(journeyEditData);
      return {
        ...state,
        journeyEditData,
      };
    }

    case 'delete-journey-participant': {
      const participantIndex = action.payload.participantIndex;
      const participants = state.journeyEditData.participants;
      const journeyEditData: CMJourneyEditData = {
        ...state.journeyEditData,
        participants: participants.filter((x, i) => i !== participantIndex),
      };
      saveJourneyEditDataToBrowserStorage(journeyEditData);
      return {
        ...state,
        journeyEditData,
      };
    }

    case 'update-journey-participant': {
      const payload = action.payload;
      const participants = [...state.journeyEditData.participants];
      const participantIndex =
        payload.participantIndex !== undefined
          ? payload.participantIndex
          : participants.length;
      const participant = participants[participantIndex]
        ? {
            ...participants[participantIndex],
            ...payload.participant,
          }
        : {
            ...payload.participant,
            enabled: true,
          };

      const journeyEditData: CMJourneyEditData = {
        ...state.journeyEditData,
      };
      journeyEditData.participants[participantIndex] = participant;
      if (journeyEditData?.remainingExternalParticipantsToCreate) {
        // remove first remaining participant from pending list
        journeyEditData.remainingExternalParticipantsToCreate = journeyEditData.remainingExternalParticipantsToCreate.slice(
          1
        );
      }
      saveJourneyEditDataToBrowserStorage(journeyEditData);
      return {
        ...state,
        journeyEditData,
      };
    }
    case 'update-journey-participants-list': {
      const payload = action.payload;
      const journeyEditData: CMJourneyEditData = {
        ...state.journeyEditData,
        participants: payload.participants,
        journey: {
          ...state.journeyEditData.journey,
          ...payload.journey,
        },
        allowedPeopleList: payload.allowedPeopleList,
      };
      saveJourneyEditDataToBrowserStorage(journeyEditData);
      return {
        ...state,
        journeyEditData,
      };
    }

    case 'update-journey-details': {
      const payload = action.payload;
      const journeyEditData: CMJourneyEditData = {
        ...state.journeyEditData,
        journey: {
          ...state.journeyEditData.journey,
          ...payload.journey,
        },
        allowedPeopleList: payload.allowedPeopleList,
      };
      saveJourneyEditDataToBrowserStorage(journeyEditData);
      return {
        ...state,
        journeyEditData,
      };
    }

    case 'update-journey-account-profile': {
      const payload: CustomerAccountEditFormData = action.payload;
      const customerAccount: CMJourneyEditCustomerAccount = {
        ...state.journeyEditData.customerAccount,
        gender: payload.gender,
        language: payload.language,
        firstName: payload.firstName,
        lastName: payload.lastName,
        phone1: payload.phone1,
        phone2: payload.phone2,
        email: payload.email,
        country: payload.country,
        consent: payload.consent,
        password: payload.password,
        electronicSignature: payload.electronicSignature,
        settings: {
          notifications: {
            language: payload.notificationLanguage,
            mobile: {
              enabled: !!payload.notificationLanguage,
            },
          },
        },
      };
      const journeyEditData: CMJourneyEditData = {
        ...state.journeyEditData,
        customerAccount,
      };
      saveJourneyEditDataToBrowserStorage(journeyEditData);
      return {
        ...state,
        journeyEditData,
      };
    }
    case 'initialize-journey-new-account': {
      const payload = action.payload;

      const journeyEditData: CMJourneyEditData = journeyEditStateBuilder.buildInitialJourneyEditData(
        {
          companyReference: payload.companyReference,
          customerAccountId: null,
        }
      );
      journeyEditData.customerAccount = ({
        email: payload.email,
      } as unknown) as CMJourneyEditCustomerAccount;
      saveJourneyEditDataToBrowserStorage(journeyEditData);
      return {
        ...state,
        journeyEditData,
      };
    }

    case 'clear-update-journey-cache': {
      persistentCache.remove(APP_CURRENT_REGISTRATION_BROWSER_STORAGE_ID, {
        ignoreError: true,
      });
      return {
        ...state,
        journeyEditData: undefined,
      };
    }

    case 'clear-all-user-cache': {
      persistentCache.remove(APP_CURRENT_REGISTRATION_BROWSER_STORAGE_ID, {
        ignoreError: true,
      });
      return {
        ...APP_CONTEXT_INITIAL_STATE,
        loaded: true,
      };
    }
    default:
      throw new Error('[appCacheContextReducer] Invalid reducer action type');
  }
}

function buildRemainingExternalParticipantsToCreate({
  fakeCustomerJourneyMembers,
  caMembers,
  journey,
  fakeCustomerAccount,
  ca,
  customerAccount,
  externalData,
}: {
  fakeCustomerJourneyMembers: CustomerAccountMember[];
  caMembers: CMProfileMember[];
  journey: Partial<
    Pick<
      CMJourney,
      | 'extCustomerJourneyBookingGroupId'
      | 'beginDateUTC'
      | 'endDateInclusiveUTC'
      | 'campingPlaceNumber'
      | 'allowedPeopleList'
      | 'immatriculation'
      | 'arrivalTime'
      | 'comment'
    >
  >;
  fakeCustomerAccount: CustomerAccount;
  ca: CMCustomerAccount;
  customerAccount: CMJourneyEditCustomerAccount;
  externalData: CMExtJourneyFinderResult;
}) {
  if (externalData.bookingType !== 'single') {
    return [];
  }
  const remainingExternalParticipantsToCreate: CMJourneyEditParticipant[] = fakeCustomerJourneyMembers.map(
    (member) => {
      const cleanAttributes = {
        firstName: member.firstName?.toLowerCase()?.trim(),
        lastName: member.lastName?.toLowerCase()?.trim(),
        birthDate: member.birthDate,
      };
      const existingMember = caMembers?.find((caMember) => {
        const x = {
          firstName: caMember.firstName?.toLowerCase()?.trim(),
          lastName: caMember.lastName?.toLowerCase()?.trim(),
          birthDate: caMember.birthDate,
        };
        return (
          (x.firstName?.length &&
            x.lastName?.length &&
            x.firstName === cleanAttributes.firstName &&
            x.lastName === cleanAttributes.lastName) ||
          (x.firstName?.length &&
            x.birthDate &&
            x.firstName === cleanAttributes.firstName &&
            x.birthDate?.getTime() === cleanAttributes.birthDate?.getTime())
        );
      });
      if (existingMember) {
        const birthDate = existingMember?.birthDate ?? member.birthDate;
        const journeyAge = birthDate
          ? dateTransformer.getAge(birthDate, journey.endDateInclusiveUTC)
          : undefined;
        const p: CMJourneyEditParticipant = {
          attributes: {
            journeyAge,
          },
          enabled: true,
          member: {
            ...existingMember,
            birthDate,
            isExactBirthDate: true,
          },
          customerChildId: existingMember.customerChildId,
          journeyParticipantId: undefined,
        };
        return p;
      } else {
        const journeyAge = member.birthDate
          ? dateTransformer.getAge(
              member.birthDate,
              journey.endDateInclusiveUTC
            )
          : undefined;
        const p: CMJourneyEditParticipant = {
          attributes: {
            journeyAge,
          },
          enabled: true,
          member: {
            lastName: member.lastName,
            firstName: member.firstName,
            birthDate: member.birthDate,
            memberComment: member.comment,
            country: fakeCustomerAccount.country,
            gender: member.gender,
            isExactBirthDate: true,
            language1: fakeCustomerAccount.language,
          },
          customerChildId: undefined,
          journeyParticipantId: undefined,
        };
        return p;
      }
    }
  );

  if (
    !remainingExternalParticipantsToCreate?.length &&
    !ca &&
    customerAccount
  ) {
    // nouveau compte, et pas de participants dans le séjour: on pré-créé un participant avec le titulaire du compte dans eseason
    const journeyAge = fakeCustomerAccount.birthDate
      ? dateTransformer.getAge(
          fakeCustomerAccount.birthDate,
          journey.endDateInclusiveUTC
        )
      : undefined;
    const p: CMJourneyEditParticipant = {
      attributes: {
        journeyAge,
      },
      enabled: true,
      member: {
        lastName: customerAccount.lastName,
        firstName: customerAccount.firstName,
        birthDate: fakeCustomerAccount.birthDate,
        memberComment: undefined,
        country: fakeCustomerAccount.country,
        gender: customerAccount.gender,
        isExactBirthDate: true,
        language1: fakeCustomerAccount.language,
      },
      customerChildId: undefined,
      journeyParticipantId: undefined,
    };
    remainingExternalParticipantsToCreate.push(p);
  }
  return remainingExternalParticipantsToCreate;
}

function saveJourneyEditDataToBrowserStorage(
  journeyEditData: CMJourneyEditData
) {
  //Save in local storage
  persistentCache
    .set(APP_CURRENT_REGISTRATION_BROWSER_STORAGE_ID, journeyEditData, {
      ignoreError: true,
    })

    .then(() => {
      appLogger.info(`[appCacheContextReducer] Saving storage success:`);
    })
    .catch((reason) => {
      appLogger.error(
        `[appCacheContextReducer] Saving storage error: ${reason}`
      );
    });
}
