import { IonImg } from '@ionic/react';
import {
  useAppImageUrl,
  useAppLogoUrl,
  useAppSettings,
} from '_01_CORE/app-core/app-security';
import clsx from 'clsx';
import { Company } from 'lib-common-model';
import { useIntl } from 'react-intl';

export const AppUniverseHeader = ({
  showLargeHeader,
  company,
  label,
}: {
  showLargeHeader: boolean;
  company?: Pick<Company, 'name' | 'logo'>;
  label?: string;
}) => {
  const appSettings = useAppSettings();
  const identity = appSettings?.universe?.identity;

  const appLabel = identity.appLabel;
  const appLogoUrl = useAppLogoUrl('md');

  const companyLogoUrl = useAppImageUrl(company?.logo, {
    defaultImage: undefined,
    size: 'md',
  });

  const { formatMessage: t } = useIntl();

  const title1Color = appSettings?.universe?.theme?.title1Color;

  return (
    <div
      className={clsx(
        'text-center font-bold text-xl mt-4 mb-6',
        showLargeHeader ? '' : 'flex gap-2 items-center relative'
      )}
    >
      <IonImg
        className={clsx(
          showLargeHeader
            ? 'm-auto h-auto w-36'
            : 'h-16 w-16  md:absolute md:left-0'
        )}
        src={companyLogoUrl ?? appLogoUrl}
      />
      <h1
        className={clsx(
          showLargeHeader
            ? 'mt-4 text-xl '
            : 'flex-grow text-center text-base md:text-xl'
        )}
        style={{
          color: title1Color,
        }}
      >
        {label ?? (
          <>
            {t({ id: 'page01.welcomeTo' })}{' '}
            <span className="whitespace-nowrap">
              {company?.name ?? appLabel}
            </span>
          </>
        )}
      </h1>
    </div>
  );
};
