import {
  CMJourney,
  CMJourneyParticipant,
  CMJourneyStatus,
  defaultJourneySelector,
} from 'lib-common-model';
import { useMemo } from 'react';
import { journeyStatusBuilder } from '_02_APP_COMMON/JOU-journey/_common/services';
import { useAppCacheContext } from '../../hooks';

export function useJourney({
  customerJourneyId,
  companyReference,
}: {
  customerJourneyId?: string;
  companyReference?: string;
}): {
  journey: CMJourney;
  journeyStatus: CMJourneyStatus;
  participants: CMJourneyParticipant[];
} {
  const { auth } = useAppCacheContext();

  return useMemo(() => {
    let journey: CMJourney;
    const journeys = auth?.customerProfile?.journeys;
    if (journeys) {
      if (customerJourneyId) {
        journey = auth?.customerProfile?.journeys.find(
          (j) => j._id === customerJourneyId
        );
      } else {
        journey = defaultJourneySelector.findJourneyToDisplay(
          companyReference
            ? journeys.filter((j) => j.company?.reference === companyReference)
            : journeys
        );
      }
    }
    if (!journey) {
      return {
        journey: undefined,
        journeyStatus: undefined,
        participants: [],
      };
    }
    return {
      journey,
      journeyStatus: journeyStatusBuilder.getJourneyStatus(journey),
      participants: journey?.participants ? journey.participants : [],
    };
  }, [auth?.customerProfile?.journeys, companyReference, customerJourneyId]);
}
