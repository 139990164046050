import * as Sentry from '@sentry/react';
import { CaptureContext, Severity } from '@sentry/types';
import { AppLogger } from './AppLogger.type';
import { appLoggerConfigurer } from './appLoggerConfigurer.service';

const debug = (message: string, data?: { [key: string]: any }) => {
  if (data) {
    console.debug(message, data);
  } else {
    console.debug(message);
  }
  // Sentry.addBreadcrumb({
  //   level: Severity.Debug,
  //   message,
  //   data,
  // });
};
const info = (message: string, data?: { [key: string]: any }) => {
  if (data) {
    console.info(message, data);
  } else {
    console.info(message);
  }
  // Sentry.addBreadcrumb({
  //   level: Severity.Info,
  //   message,
  //   data,
  // });
};

const warn = (message: string, data?: { [key: string]: any }) => {
  if (data) {
    console.warn(message, data);
  } else {
    console.warn(message);
  }
  Sentry.addBreadcrumb({
    level: Severity.Warning,
    message,
    data,
  });
};
const error = (
  message: string,
  {
    err,
    data,
    captureContext,
  }: {
    err?: Error;
    data?: {
      [key: string]: any;
    };
    captureContext?: CaptureContext;
  } = {}
) => {
  if (!captureContext) {
    captureContext = appLoggerConfigurer.getConfig().buildCaptureContext();
  }
  if (err) {
    console.error(message, err, data);
    Sentry.addBreadcrumb({
      message,
      data,
    });
    Sentry.captureException(err, captureContext);
  } else {
    if (data) {
      console.error(message, data);
      Sentry.addBreadcrumb({
        message,
        data,
      });
    } else {
      console.error(message);
    }
    Sentry.captureMessage(message, captureContext);
  }
};

export const appLogger: AppLogger = {
  debug,
  info,
  warn,
  error,
};
