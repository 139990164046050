import { AppSettings } from 'lib-common-model';
import { Loadable, useLoadable } from 'lib-web-redux';
import { distinctUntilChanged } from 'rxjs/operators';
import { appStore } from '../app-store';
export function useAppSettings(): AppSettings & Loadable {
  const appSettings = useLoadable({
    debugName: 'useAppSettings',
    initialValueFromLoadSnapshot: true,
    load: () => appStore.appSettings.get().pipe(distinctUntilChanged()),
  });
  return appSettings;
}
