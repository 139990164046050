import {
  CMJourney,
  EcommerceBookingGiftPromoCodeValidationResponse,
} from 'lib-common-model';
import { IntlShape } from 'react-intl';
import { ecommerceBookingJourneyApiClient } from '_01_CORE/app-core/app-api';
import { dateTester, dateTransformer } from '_01_CORE/_components-core';

export type PromoCodeCheckResponseState = 'code-accepted' | 'code-rejected';

export const CODE_ACCEPTED = 'code-accepted';
export const CODE_REJECTED = 'code-rejected';

export type PromoCodeCheckResponseParserResult = {
  message: string;
  messageDetails?: string;
  state: PromoCodeCheckResponseState;
  response?: EcommerceBookingGiftPromoCodeValidationResponse;
  code?: string;
};

export const promoCodeCheckerClient = { checkCode };

async function checkCode(
  inputCode: string,
  {
    journey,
    formatMessage: t,
    currencySymbol,
  }: {
    journey: CMJourney;
    currencySymbol: string;
  } & Pick<IntlShape, 'formatMessage'>
): Promise<PromoCodeCheckResponseParserResult> {
  const companyGroupId = journey.company.companyGroupId;
  const code = inputCode?.trim()?.toUpperCase();
  if (code?.length !== 8) {
    return {
      message: t({ id: 'common.message.error.invalid-code' }),
      state: 'code-rejected',
      code,
    };
  } else {
    return ecommerceBookingJourneyApiClient
      .checkGiftPromoCode({
        giftPromoCode: code,
        companyGroupId,
      })
      .then((response) => {
        const codeCheckResult: PromoCodeCheckResponseParserResult = parseResponse(
          response,
          {
            journey,
            formatMessage: t,
            currencySymbol,
          }
        );

        return { ...codeCheckResult, code, response };
      })
      .catch(() => {
        return {
          message: t({ id: 'common.message.error.invalid-code' }),
          state: 'code-rejected',
        };
      });
  }
}

function parseResponse(
  response: EcommerceBookingGiftPromoCodeValidationResponse,
  {
    journey,
    formatMessage: t,
    currencySymbol,
  }: {
    journey: CMJourney;
    currencySymbol: string;
  } & Pick<IntlShape, 'formatMessage'>
): PromoCodeCheckResponseParserResult {
  if (response.exists) {
    if (response?.status === 'valid') {
      // extra validation
      if (
        response.meta.companyId &&
        journey.company._id !== response.meta.companyId
      ) {
        return {
          message: t({
            id: 'common.message.error.invalid-company-accropark',
          }),
          state: 'code-rejected',
        };
      } else if (
        response.meta.activitiesPeriod?.beginDate &&
        !dateTester.isDateWithinRange(journey.beginDateUTC, {
          minDate: response.meta.activitiesPeriod.beginDate,
          maxDateExclusive: dateTransformer.addDays(
            response.meta.activitiesPeriod.endDateInclusive,
            1
          ),
        })
      ) {
        return {
          message: t({
            id: 'common.message.error.invalid-activities-period',
          }),
          messageDetails: t(
            {
              id: 'common.message.error.invalid-activities-period-details',
            },
            {
              beginDate: dateTransformer.formatUTC(
                response.meta.activitiesPeriod.beginDate,
                'dd/MM/yyyy'
              ),
              endDate: dateTransformer.formatUTC(
                response.meta.activitiesPeriod.endDateInclusive,
                'dd/MM/yyyy'
              ),
            }
          ),

          state: 'code-rejected',
        };
      } else {
        if (response?.meta?.type === 'gift-card') {
          return {
            message: t(
              { id: 'common.message.success.valid-code' },
              {
                giftCardAmount: response.meta?.discountAmount,
                currencySymbol,
              }
            ),
            state: 'code-accepted',
          };
        } else {
          return {
            message: t(
              { id: 'common.message.success.valid-code' },
              {
                giftCardAmount: response.meta?.discountPercentage,
                currencySymbol: '%',
              }
            ),
            state: 'code-accepted',
          };
        }
      }
    } // invalid code
    else if (response?.status === 'used') {
      return {
        message: t({ id: 'common.message.error.code-already-used' }),
        state: 'code-rejected',
      };
    } else if (response?.status === 'expired') {
      return {
        message: t({ id: 'common.message.error.expired-code' }),
        state: 'code-rejected',
      };
    } else {
      return {
        message: t({ id: 'common.message.error.invalid-code' }),
        state: 'code-rejected',
      };
    }
  } else {
    return {
      message: t({ id: 'common.message.error.invalid-code' }),
      state: 'code-rejected',
    };
  }
}
