import { Dialog } from '@headlessui/react';
import {
  AppButtonV2,
  AppIcons,
  dateTransformer,
} from '_01_CORE/_components-core';
import { AppDialogV2 } from '_01_CORE/_components-core/dialog/AppDialogV2';
import { useAppRouter, useAppToasts } from '_01_CORE/_hooks';
import { useNetworkStatus } from '_01_CORE/app-core/app-platform';
import { useAppImageUrl } from '_01_CORE/app-core/app-security';
import clsx from 'clsx';
import { AppClientNotification, MessageContent } from 'lib-common-model';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { notificationTargetUrlBuilder } from './notificationTargetUrlBuilder.service';

export const NotificationMessageDetailsDialog = ({
  onClose,
  notification: n,
  className,
}: {
  onClose: () => void;
  notification: AppClientNotification;
  className?: string;
}) => {
  const appRouter = useAppRouter();
  const { formatMessage: t } = useIntl();
  const { networkStatus } = useNetworkStatus();
  const { pushError } = useAppToasts();

  const messageContent: MessageContent = n?.content;

  const activity = n?.activity;
  const activitySession = n?.activitySession;
  const customerJourneyId = n?.customerJourneyId;

  const now = useMemo(() => new Date().getTime(), []);

  const imageUrl = useAppImageUrl(n?.content?.image, {
    defaultImage: null,
  });

  const logoUrl = useAppImageUrl(activity?.logo, {
    defaultImage: activity ? 'activity' : 'app', // logo de l'appli par défaut
  });

  const activityUrl = useMemo(
    () => notificationTargetUrlBuilder.buildActivityUrl(n),
    [n]
  );

  return !n ? null : (
    <AppDialogV2
      className={clsx('w-full sm:max-w-screen-sm bg-gray-50', className)}
      isOpen={true}
      onClose={() => {
        onClose();
      }}
    >
      <Dialog.Title
        as="h3"
        className="py-1 text-primary text-center font-bold text-base md:text-xl mm:text-2xl"
      >
        <div className="flex justify-between items-stretch gap-4 mx-4">
          {logoUrl && (
            <div className="flex-shrink-0 mt-0.5 w-14 h-auto object-contain">
              <img className="" src={logoUrl} />
            </div>
          )}
          <div className="flex-grow text-center">{messageContent?.title}</div>
          <div
            className="self-start p-2 cursor-pointer rounded-full bg-gray-100 text-gray-600 hover:bg-gray-200 hover:text-gray-800"
            onClick={() => onClose()}
          >
            <AppIcons.actionClose className="h-8 w-8" />
          </div>
        </div>
        <div className="">
          <div className={`text-base text-right px-4 text-gray-500`}>
            {dateTransformer.format(
              n.sendDate,
              "dd/MM/yyyy'" + t({ id: 'page06.at' }) + "'HH:mm"
            )}
          </div>
        </div>
      </Dialog.Title>
      <div className="w-full h-full overflow-auto text-left flex flex-col justify-between gap-4 py-4 px-4">
        {activity && activitySession && (
          <div className="font-bold text-base">
            {activity.name} -{' '}
            {dateTransformer.formatUTC(
              activitySession.beginDateTime,
              "dd/MM/yyyy'" + t({ id: 'page06.at' }) + "'HH:mm"
            )}
          </div>
        )}
        <div className="flex flex-col gap-4 border border-gray-200 border-collapse rounded-t-xl rounded-r-xl bg-white py-8 p-4">
          <div
            className={`flex-grow text-lg break-words whitespace-pre-wrap inline 
            ${n.readDate ? '' : ''}`}
          >
            {n.content?.body ?? n.body}
          </div>
        </div>
        {activityUrl && (
          <div className="text-center py-2 px-2">
            <AppButtonV2
              className={`w-full max-w-xs`}
              buttonStyle="outline-primary"
              onClick={() => {
                onClose();
                appRouter.navigate(activityUrl, {
                  cause: 'clic-notification',
                });
              }}
              icon={AppIcons.actionClose}
              centerText={true}
            >
              {`Voir l'activité`}
            </AppButtonV2>
          </div>
        )}

        <div className="flex-grow"></div>
        {imageUrl && (
          <div className="flex-shrink-0 mt-0.5 object-contain">
            <img
              className="object-contain w-full h-auto"
              style={{
                maxHeight: '45vh',
              }}
              src={imageUrl}
            />
          </div>
        )}
        <div className="text-center py-2 px-2">
          <AppButtonV2
            className={`w-full max-w-xs`}
            buttonStyle="primary"
            onClick={() => onClose()}
            icon={AppIcons.actionClose}
            centerText={true}
          >
            Fermer
          </AppButtonV2>
        </div>
      </div>
    </AppDialogV2>
  );
};
