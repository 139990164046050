import { DependencyList, useCallback, useState } from 'react';

export function useOperationPending<
  A extends any[],
  T extends (...args: A) => any = (...args: A) => any
>(op: T, dependencies: DependencyList): (...args: A) => Promise<any> {
  const [operationPending, setOperationPending] = useState(false);

  const operation = useCallback(
    async (...args: A) => {
      if (!operationPending) {
        setOperationPending(true);
        try {
          return await op(...args);
        } finally {
          setOperationPending(false);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...dependencies, op, operationPending]
  );

  return operation;
}
