import { IonCol, IonIcon, IonRouterLink } from '@ionic/react';
import { chevronBackOutline } from 'ionicons/icons';
import { CMCompany } from 'lib-common-model';
import React from 'react';
import { useAppImageUrl } from '_01_CORE/app-core/app-security/useAppImageUrl.hook';
import { useAppRouter } from '_01_CORE/_hooks';

export const PageCompanyTitle: React.FC<{
  company: CMCompany;
  displayAddress?: boolean;
  backLink?: string;
}> = ({ company, displayAddress, backLink }) => {
  const imageSrc = useAppImageUrl(company?.logo, {
    defaultImage: 'app',
  });
  const appRouter = useAppRouter();

  return (
    <div className="flex text-sm md:text-base text-tertiary font-bold text-left">
      {backLink ? (
        <div className="">
          <IonRouterLink
            onClick={() =>
              appRouter.navigate(backLink, { cause: 'company-page-back' })
            }
            hidden={!backLink}
          >
            <IonCol size="1">
              <IonIcon className="text-primary" icon={chevronBackOutline} />
            </IonCol>
          </IonRouterLink>
        </div>
      ) : null}
      <div className="flex w-full">
        <div className="flex justify-center">
          <img className="w-24 self-start p-2" src={imageSrc} />
        </div>
        <div className="flex self-center">
          <div>
            <div>{company?.name}</div>
            <div hidden={!displayAddress}>
              <div>{company?.direction.line1}</div>
              <div>
                {company?.direction.zipCode} {company?.direction.city}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
