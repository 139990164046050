import { Language } from './Language.model';

// NOTE: e-season défini aussi le language "ct" pour CATALAN

export const LANGUAGES_ARRAY: Language[] = [
  {
    id: 'ger/deu',
    imgUrl: 'assets/images/countries/DEU-20px.png',
    iso6391: 'de',
    ietfBcp45: 'de',
    eseasonLanguageId: 'de',
    labelFr: 'Allemand',
    labelOriginal: 'Deutsch',
  },
  {
    id: 'en',
    imgUrl: 'assets/images/countries/GBR-20px.png',
    iso6391: 'en',
    ietfBcp45: 'en',
    eseasonLanguageId: 'en',
    labelFr: 'Anglais',
    labelOriginal: 'English',
  },
  {
    id: 'dan',
    imgUrl: 'assets/images/countries/DNK-20px.png',
    iso6391: 'da',
    ietfBcp45: 'da',
    eseasonLanguageId: 'dk', // ils utilisent l'ISO code du pays et non pas de la langue
    labelFr: 'Danois',
    labelOriginal: 'Dansk',
  },
  {
    id: 'spa',
    imgUrl: 'assets/images/countries/ESP-20px.png',
    iso6391: 'es',
    ietfBcp45: 'es',
    eseasonLanguageId: 'es',
    labelFr: 'Espagnol',
    labelOriginal: 'Español',
  },
  {
    id: 'fin',
    imgUrl: 'assets/images/countries/FIN-20px.png',
    iso6391: 'fi',
    ietfBcp45: 'fi',
    eseasonLanguageId: undefined,
    labelFr: 'Finnois',
    labelOriginal: 'Suomen kieli',
  },
  {
    id: 'fre/fra',
    imgUrl: 'assets/images/countries/FRA-20px.png',
    iso6391: 'fr',
    ietfBcp45: 'fr',
    eseasonLanguageId: 'fr',
    labelFr: 'Français',
    labelOriginal: 'Français',
  },
  {
    id: 'gle',
    imgUrl: 'assets/images/countries/IRL-20px.png',
    iso6391: 'ga',
    ietfBcp45: 'ga',
    eseasonLanguageId: undefined,
    labelFr: 'Irlandais',
    labelOriginal: 'Gaeilge',
  },
  {
    id: 'ita',
    imgUrl: 'assets/images/countries/ITA-20px.png',
    iso6391: 'it',
    ietfBcp45: 'it',
    eseasonLanguageId: 'it',
    labelFr: 'Italien',
    labelOriginal: 'Italiano',
  },
  {
    id: 'nor',
    imgUrl: 'assets/images/countries/NOR-20px.png',
    iso6391: 'no',
    ietfBcp45: 'no',
    eseasonLanguageId: undefined,
    labelFr: 'Norvégien',
    labelOriginal: 'Norsk',
  },
  {
    id: 'gsw',
    imgUrl: 'assets/images/countries/CHE-20px.png',
    iso6391: '',
    ietfBcp45: 'gsw',
    eseasonLanguageId: undefined,
    labelFr: 'Suisse Alémanique',
    labelOriginal: 'Alemannisch',
  },
  {
    id: 'por',
    imgUrl: 'assets/images/countries/PRT-20px.png',
    iso6391: 'pt',
    ietfBcp45: 'pt',
    eseasonLanguageId: 'pt',
    labelFr: 'Portugais',
    labelOriginal: 'Português',
  },
  {
    id: 'swe',
    imgUrl: 'assets/images/countries/SWE-20px.png',
    iso6391: 'sv',
    ietfBcp45: 'sv',
    eseasonLanguageId: undefined,
    labelFr: 'Suédois',
    labelOriginal: 'Svenska',
  },
  {
    id: 'dut/nld',
    imgUrl: 'assets/images/countries/NDL-20px.png',
    iso6391: 'nl',
    ietfBcp45: 'nl',
    eseasonLanguageId: 'nl',
    labelFr: 'Néerlandais',
    labelOriginal: 'Nederlands', // coquille, en fait c'est NLD
  },
  {
    id: '@@@', // depecated : remplacé par OTHER
    iso6391: '',
    ietfBcp45: '',
    labelFr: 'Autres',
    labelOriginal: 'Others',
  },
  {
    id: 'OTHER',
    iso6391: '',
    ietfBcp45: '',
    labelFr: 'Autres',
    labelOriginal: 'Others',
  },
];
export const LANGUAGES_FRENCH = LANGUAGES_ARRAY.find((l) => l.id === 'fre/fra');
