import React from 'react';
import {
  AppDatePickerCtx,
  AppDatePickerCtxProps,
  useDatePickerCtx,
} from './AppDatePickerContext';
import { AppDateSelection } from './AppDateSelection';
import { AppMonthSelection } from './AppMonthSelection';
import { AppYearSelection } from './AppYearSelection';

type AppStaticCalendarProps = AppDatePickerCtxProps;

export const AppStaticCalendar: React.FC<
  AppStaticCalendarProps & { className?: string }
> = ({ className, ...props }) => {
  const ctxValue = useDatePickerCtx(props);
  const { view } = ctxValue;

  let selectionComponent = null;
  switch (view) {
    case 'date':
      selectionComponent = <AppDateSelection />;
      break;
    case 'month':
      selectionComponent = <AppMonthSelection />;
      break;
    case 'year':
      selectionComponent = <AppYearSelection />;
      break;
  }

  return (
    <AppDatePickerCtx.Provider value={ctxValue}>
      <div
        className={`bg-white shadow-lg p-2 rounded-lg w-full ${
          className ?? ''
        }`}
      >
        {selectionComponent}
      </div>
    </AppDatePickerCtx.Provider>
  );
};
