import { IonButton, IonIcon } from '@ionic/react';
import { arrowBackOutline, trash } from 'ionicons/icons';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useAppCacheContext } from '_01_CORE/app-contexts';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import { useAppModalContext } from '_01_CORE/_components-core';
import { useAppRouter } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
export function useJourneyCancellation({
  companyReference,
}: {
  companyReference: string;
}) {
  const appRouter = useAppRouter();
  const { dispatchCacheContextAction } = useAppCacheContext();
  const { openModal, closeModal } = useAppModalContext();
  const { formatMessage: t } = useIntl();
  const appId = appWebConfig().appId;

  const backToHome = useCallback(() => {
    appRouter.navigate(
      appRoutesBuilder.getCompanyBookletUrl({ companyReference }),
      { cause: 'cancel journey' }
    );
  }, [appRouter, companyReference]);

  const openWarningModal = useCallback(
    (onClick) => {
      openModal({
        title: t({ id: 'modal.cancel.title' }),
        content: <div>{t({ id: `modal.cancel.content.${appId}` })}</div>,
        actions: (
          <>
            <IonButton color="secondary" onClick={closeModal} expand="block">
              <IonIcon icon={arrowBackOutline} className="mr-3" />
              {t({ id: 'modal.no' })}
            </IonButton>
            <IonButton color="danger" expand="block" onClick={onClick}>
              <IonIcon icon={trash} className="mr-3" />
              {t({ id: 'modal.yes' })}
            </IonButton>
          </>
        ),
      });
    },
    [openModal, t, appId, closeModal]
  );

  const openModalCancelJourneyDraft = useCallback(() => {
    openWarningModal(() => {
      backToHome();
      dispatchCacheContextAction({
        type: 'clear-update-journey-cache',
        context: 'useJourneyCancellation:openModalCancelJourneyDraft',
      });
      closeModal();
    });
  }, [openWarningModal, backToHome, dispatchCacheContextAction, closeModal]);

  return {
    openModalCancelJourneyDraft,
  };
}
