import {
  AppPage,
  FooterActions,
  HeaderToolbar,
  PageContainer,
} from '_01_CORE/_common-layout';
import { useAppRouter } from '_01_CORE/_hooks';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { AnimLiveJourneyShoppingCartsListPanel } from './AnimLiveJourneyShoppingCartsListPanel';
import {
  AnimLiveJourneyShoppingCartPageStateState,
  useAnimLiveJourneyShoppingCartPageState,
} from './useAnimLiveJourneyShoppingCartPageState.hook';

export const AnimLiveJourneyShoppingCartLivePage = () => {
  const appRouter = useAppRouter();
  const { formatMessage: t } = useIntl();
  const appId = appWebConfig().appId;

  const { customerJourneyId } = useParams<{ customerJourneyId: string }>();

  const localState: AnimLiveJourneyShoppingCartPageStateState = useAnimLiveJourneyShoppingCartPageState(
    {
      context: 'live',
      customerJourneyId,
    }
  );
  const { goBackToShopping, goBackToActivities } = localState;

  return (
    <AppPage displayFooter={false}>
      <HeaderToolbar hideLogout={true} />
      <PageContainer
        className="h-full bg-gray-50 flex flex-col items-stretch text-center"
        spacing="normal"
      >
        <AnimLiveJourneyShoppingCartsListPanel
          localState={localState}
        ></AnimLiveJourneyShoppingCartsListPanel>
      </PageContainer>
      <FooterActions
        // onCancel={openModalCancelJourneyDraft}
        backText="Continuer mes achats"
        onBack={() => {
          goBackToActivities();
        }}
        // onSubmit={onFinishClick}
      />
    </AppPage>
  );
};
