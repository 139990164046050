import {
  CMJourneyActivitySession,
  CustomerActivityUpdateParticipantStateAction,
} from 'lib-common-model';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { JourneyDailyActivityParticipant } from './JourneyDailyActivityParticipant';
import { CustomerActivityParticipantsDailyActivitParticipantState } from './model/CustomerActivityParticipantsDailyActivitParticipantState.type';

export const JourneyDailyActivityCardParticipantsList = ({
  className = '',
  companyReference,
  activitySession,
  participants,
  onClickParticipant,
}: {
  className?: string;
  companyReference: string;
  activitySession: CMJourneyActivitySession;
  participants: CustomerActivityParticipantsDailyActivitParticipantState[];
  onClickParticipant?: ({
    activitySession,
    participantState,
    action,
  }: {
    activitySession: CMJourneyActivitySession;
    participantState: CustomerActivityParticipantsDailyActivitParticipantState;
    action?: CustomerActivityUpdateParticipantStateAction;
  }) => void;
}) => {
  const { formatMessage: t } = useIntl();

  const unitType = activitySession?.registration?.unitType;
  const unitLabel = activitySession?.registration?.unitLabel;

  const registeredParticipants = useMemo(
    () => participants.filter((x) => !x.isNotRegistered),
    [participants]
  );

  const productCount = useMemo(() => {
    if (unitType === 'product') {
      if (registeredParticipants.length === 0) {
        return 0;
      } else {
        const mainParticipant = registeredParticipants[0];
        return 1;
        // TODO
        // return mainParticipant.productCount ?? 1;
      }
    } else {
      return registeredParticipants.length;
    }
  }, [registeredParticipants, unitType]);

  return participants.length > 0 ? (
    <div
      className={`flex-grow flex flex-row items-start flex-wrap gap-x-1 gap-y-3 ${className}`}
    >
      {
        // unitType === 'product' ? (
        //   <ProductAvatar
        //     className={'my-2'}
        //     productCount={productCount}
        //     onClickAdd={(count) => {
        //       if (registeredParticipants.length) {
        //         const mainParticipant = registeredParticipants[0];
        //         onClickParticipant({
        //           activitySession,
        //           participantState: mainParticipant,
        //           action: {
        //             type: 'update-quantity',
        //             quantity: productCount + count,
        //           },
        //         });
        //       } else if (participants.length) {
        //         onClickParticipant({
        //           activitySession,
        //           participantState: participants[participants.length - 1],
        //         });
        //       }
        //     }}
        //     onClickRemove={(count) => {
        //       if (registeredParticipants.length) {
        //         const mainParticipant = registeredParticipants[0];
        //         onClickParticipant({
        //           activitySession,
        //           participantState: mainParticipant,
        //           action: {
        //             type: 'update-quantity',
        //             quantity: productCount + count,
        //           },
        //         });
        //       }
        //     }}
        //   />
        // ) :
        participants.map((participantState, i) => (
          <JourneyDailyActivityParticipant
            key={`p1-${i}`}
            companyReference={companyReference}
            activitySession={activitySession}
            participantState={participantState}
            onClick={
              onClickParticipant
                ? ({ action, participantState }) => {
                    return onClickParticipant({
                      action,
                      activitySession,
                      participantState,
                    });
                  }
                : undefined
            }
          />
        ))
      }
    </div>
  ) : null;
};
