import React from 'react';
import { ArrowLeft, ArrowRight } from '_01_CORE/_icons';

export const AppTitle1: React.FC<{
  arrow?: 'right' | 'left';
  className?: string;
}> = ({ children, arrow, className = '' }) => {
  return (
    <div className={`my-3 flex gap-2 sm:gap-4 items-start ${className}`}>
      {arrow === 'left' && (
        <div className="inline-block">
          <ArrowLeft className="mt-3 -mb-3 sm:mt-4 sm:-mb-4 inline-block w-10 h-10 sm:w-16 sm:h-16 text-primary" />
        </div>
      )}
      <div className="inline-block text-primary text-xl mm:text-2xl ml:text-3xl sm:text-4xl">
        {children}
      </div>
      {arrow === 'right' && (
        <div className="inline-block">
          <ArrowRight className="mt-3 -mb-3 sm:mt-4 sm:-mb-4 inline-block w-10 h-10 sm:w-16 sm:h-16 text-primary" />
        </div>
      )}
    </div>
  );
};
