import { XMarkIcon } from '@heroicons/react/24/solid';
import { Gender } from 'lib-common-model';
import { useMemo } from 'react';
import {
  GenderBoy,
  GenderFemale,
  GenderGirl,
  GenderMale,
} from '_01_CORE/_icons';

export const CustomerGenderIcon = ({
  gender,
  isMinor,
  isSelected,
  size = 9,
  className = '',
}: {
  gender?: Gender;
  isMinor: boolean;
  isSelected: boolean;
  size?: number;
  className?: string;
}) => {
  const baseIconClassName = `w-${size} h-${size} ${className}`;
  const iconClassName = useMemo(() => {
    if (isSelected) {
      if (gender === 'male') {
        return `text-blue-500 ${baseIconClassName}`;
      } else if (gender === 'female') {
        return `text-pink-500 ${baseIconClassName}`;
      } else {
        return `text-gray-700 ${baseIconClassName}`;
      }
    } else {
      return `${baseIconClassName}`;
    }
  }, [isSelected, gender, baseIconClassName]);

  return (
    <>
      {gender === 'male' ? (
        isMinor ? (
          <GenderBoy className={iconClassName} />
        ) : (
          <GenderMale className={iconClassName} />
        )
      ) : gender === 'female' ? (
        isMinor ? (
          <GenderGirl className={iconClassName} />
        ) : (
          <GenderFemale className={iconClassName} />
        )
      ) : (
        <XMarkIcon className={iconClassName} />
      )}
    </>
  );
};
