import { IonIcon } from '@ionic/react';
import { AppDisclosure, AppIcons } from '_01_CORE/_components-core';
import { ParticipantActivityPackageLabel } from '_02_APP_COMMON/ACT-activity/ACT-16-journey-activities/JourneyDailyActivity/components';
import { checkmark } from 'ionicons/icons';
import { useIntl } from 'react-intl';

export const AnimLiveJourneyActivitiesLegend = ({
  className,
}: {
  className?: string;
}) => {
  const { formatMessage: t } = useIntl();

  return (
    <div
      className={`border border-gray-300 text-xs sm:text-base text-left p-2 ${
        className ?? ''
      }`}
    >
      <h2 className="text-center text-lg font-bold">
        {t({ id: 'page16.legend.title' })}
      </h2>
      <AppDisclosure
        defaultExpanded={false}
        className="w-full px-1 py-2 flex gap-2 text-gray-600"
        title={
          <div className="text-left text-sm text-gray-400 uppercase">
            Afficher les détails
          </div>
        }
      >
        <div className="my-3">
          <b className="text-base sm:text-lg font-bold text-red-400">
            {t({ id: 'page16.legend.text.warning.title' })}
          </b>
          :{' '}
          <span className="text-gray-800 font-bold">
            {t({ id: 'page16.legend.text.warning.absences' })}
          </span>
          <div className="text-gray-800">
            {t({ id: 'page16.legend.text.warning.contact-anim' })}
          </div>
        </div>
        <div className="my-3 flex">
          <div className="w-6 h-6 text-white bg-registration-customer rounded-full">
            <IonIcon
              className="w-6 h-4 py-1"
              icon={checkmark}
              style={{ '--ionicon-stroke-width': 100 }}
            />
          </div>
          <span className="ml-2">
            {t({ id: 'page16.legend.text.inscription-enregistree' })}
          </span>
        </div>
        <div className="my-3 flex">
          <div className="w-6 h-6 text-white bg-red-500 rounded-full">
            <IonIcon
              className="w-6 h-4 py-1"
              icon={checkmark}
              style={{ '--ionicon-stroke-width': 100 }}
            />
          </div>
          <span className="ml-2">
            {t({ id: 'page16.legend.text.inscription-enregistree-non-payee' })}
          </span>
        </div>
        <div className="my-3 flex">
          <AppIcons.registrationWaiting
            className={`w-6 h-6 bg-app-warning text-white rounded-full`}
          />
          <span className="ml-2">
            {t({ id: 'page16.legend.text.inscription-liste-attente' })}
          </span>
        </div>

        {/* <div className="my-3 flex">
        <div className="w-6 h-6 text-white bg-registration-anim rounded-full">
          <IonIcon className="w-6 h-4 py-1" icon={checkmark} style={{ '--ionicon-stroke-width': 100 }} />
        </div>
        <span className="ml-2">{t({ id: 'page16.legend.text.inscription-anim' })}</span>
      </div> */}
        <div className="my-3">
          <AppIcons.warning className="inline w-5 h-5 mr-1 text-red-400" />
          <span>
            {t({ id: 'page16.legend.text.remove-inscription-before-start' })}
          </span>
        </div>
        <div className="">
          {t({ id: 'page16.legend.text.remove-inscription-after-start' })}
        </div>
        <div className="mt-5">
          <span className="font-bold uppercase">
            {t({ id: 'page16.legend.text.pour-activite-payante' })}
          </span>
        </div>
        <div className="my-3 flex">
          <div
            className={`relative rounded-md px-2 py-0.5 sm:py-1 bg-registration-customer text-white`}
          >
            -- &euro;
          </div>
          <span className="ml-2">
            {t({ id: 'page16.legend.text.activite-payee' })}
          </span>
        </div>
        <div className="my-3 flex">
          <div
            className={`relative rounded-md px-2 py-0.5 sm:py-1 bg-red-500 text-white`}
          >
            -- &euro;
          </div>
          <span className="ml-2">
            {t({ id: 'page16.legend.text.activite-non-payee' })}
          </span>
        </div>

        <div className="my-3 flex items-center">
          <ParticipantActivityPackageLabel
            className="w-20"
            participantResume={{
              packType: 'illimited',
              packCredits: {
                used: 5,
                cancelled: 0,
              },
            }}
          />
          <span className="ml-2">
            {t({ id: 'page16.legend.activity-package-illimited' })}
          </span>
        </div>
        <div className="my-3 flex items-center">
          <ParticipantActivityPackageLabel
            className="w-20"
            participantResume={{
              packType: 'credits',
              packCredits: {
                used: 5,
                total: 10,
                cancelled: 0,
                remainingCredits: 5,
              },
            }}
          />
          <span className="ml-2">
            {t({ id: 'page16.legend.activity-package-credits' })}
          </span>
        </div>

        <div className="my-5 ">
          <div>
            <b>{t({ id: 'page16.legend.text.notes.title' })}</b>:
          </div>
          <div>{t({ id: 'page16.legend.text.notes.activites-modifiees' })}</div>
          <div>{t({ id: 'page16.legend.text.notes.activites-quota' })}</div>
          <div className="mt-2 text-right">
            {t({ id: 'page16.legend.text.notes.sign' })}
          </div>
        </div>
        <div className="mt-3">
          <AppIcons.warning className="inline w-5 h-5 mr-1 text-red-400" />
          <span className="font-bold">
            {t({ id: 'page16.legend.text.toute-activite-reservee-est-due' })}
          </span>
        </div>
      </AppDisclosure>
    </div>
  );
};
