import {
  CMRestoMenuDish,
  CMRestoMenuSection,
} from 'lib-common-model/entities/app-customer-mobile-web/CMResto';
import { Element as ScrollableElement } from 'react-scroll';
import { CompanyRestoMenuSectionCardDish } from './CompanyRestoMenuSectionCardDish';
import { CompanyRestoMenuSectionCardHeader } from './CompanyRestoMenuSectionCardHeader';

export const CompanyRestoMenuSectionCard = ({
  section,
  onClickDish,
}: {
  section: CMRestoMenuSection;
  onClickDish?: (dish: CMRestoMenuDish) => void;
}) => {
  return (
    <ScrollableElement name={`section-${section._id}`} className="grid gap-2">
      <CompanyRestoMenuSectionCardHeader section={section} />
      <div className="grid gap-y-2 gap-x-4">
        {true &&
          section.dishes.map((dish) => (
            <CompanyRestoMenuSectionCardDish
              key={dish._id}
              dish={dish}
              onClick={() => {
                onClickDish && onClickDish(dish);
              }}
            />
          ))}
      </div>
    </ScrollableElement>
  );
};
