import { dataSorter, SortableAttribute } from '_01_CORE/_components-core';
import {
  ActivitySession,
  ActivitySessionRegistrationFeePriceRange,
  APP_CURRENCIES,
  CompanyOptions,
  EcommerceBookingJourney,
  EcommercePurchase,
  EcommercePurchaseOffer,
  EcommercePurchaseOfferActivitySessionParticipantMeta,
  EcommercePurchaseOfferGiftPromoDiscountMeta,
  EcommercePurchaseTax,
} from 'lib-common-model';

export const ecommerceHelper = {
  sortOffers,
  getParticipantPriceRange,
  buildWeatherOffer,
  updateAccroparkBookingOptions,
};

function getParticipantPriceRange({
  activitySession,
  journeyAge,
}: {
  activitySession: Pick<
    ActivitySession,
    'registrationFeeEnabled' | 'registrationFeePriceRanges'
  >;
  journeyAge: number;
}): ActivitySessionRegistrationFeePriceRange {
  // ATTENTION: cette fonction existe sur plusieurs applis (api + web customer + web pro)

  let priceRange: ActivitySessionRegistrationFeePriceRange;
  if (
    activitySession.registrationFeeEnabled &&
    activitySession.registrationFeePriceRanges
  ) {
    priceRange = activitySession.registrationFeePriceRanges.find(
      (priceRange) => {
        if (priceRange.minAge !== undefined && priceRange.minAge > journeyAge) {
          return false;
        }
        if (priceRange.maxAge !== undefined && priceRange.maxAge < journeyAge) {
          return false;
        }
        return true;
      }
    );
    if (!priceRange) {
      // no price range: get price without minAge/maxAge
      priceRange = activitySession.registrationFeePriceRanges.find(
        (priceRange) =>
          priceRange.minAge === undefined && priceRange.maxAge === undefined
      );
    }
    if (!priceRange) {
      // no price range: get nearest age price range
      priceRange = activitySession.registrationFeePriceRanges.reduce(
        (acc, priceRange) => {
          if (
            priceRange.minAge !== undefined &&
            priceRange.maxAge !== undefined
          ) {
            if (!acc) {
              return priceRange;
            }
            const accDiff = Math.min(
              Math.abs(acc.minAge - journeyAge),
              Math.abs(acc.maxAge - journeyAge)
            );
            const newDiff = Math.min(
              Math.abs(priceRange.minAge - journeyAge),
              Math.abs(priceRange.maxAge - journeyAge)
            );
            if (newDiff < accDiff) {
              return priceRange;
            }
          }
          return acc;
        },
        undefined as ActivitySessionRegistrationFeePriceRange
      );
    }
  }
  return priceRange;
}

function buildWeatherOffer({
  booking,
  totalActivitySessions,
  companyOptions,
}: {
  booking: EcommerceBookingJourney;
  totalActivitySessions: number;
  companyOptions: CompanyOptions;
}): EcommercePurchaseOffer<any> {
  const defaultVatPercentage = companyOptions.payments.defaultVatPercentage;
  return {
    currency: booking.purchase.currency,
    price: 20,
    quantity: totalActivitySessions,
    reference: 'weather-insurance-offer',
    totalPrice: totalActivitySessions * 20,
    meta: undefined,
    taxPercentage: defaultVatPercentage,
    type: 'weather-insurance',
  };
}

function updateAccroparkBookingOptions({
  booking,
  weatherInsuranceEnabled,
  giftPromoDiscount,
  companyOptions,
}: {
  booking: EcommerceBookingJourney;
  weatherInsuranceEnabled: boolean;
  giftPromoDiscount: EcommercePurchaseOfferGiftPromoDiscountMeta;
  companyOptions: CompanyOptions;
}): EcommerceBookingJourney {
  if (!booking) {
    return booking;
  }

  const newStateOffers: EcommercePurchaseOffer[] = booking.purchase.offers.filter(
    (offer) => offer.type !== 'weather-insurance'
  );
  if (weatherInsuranceEnabled) {
    const totalActivitySessions = booking.purchase.offers
      .filter(
        (o) =>
          o.type === 'activity-session' || o.type === 'activity-session-product'
      )
      .reduce((count, o) => count + o.quantity, 0);
    const weatherOffer = buildWeatherOffer({
      booking,
      totalActivitySessions,
      companyOptions,
    });
    newStateOffers.push(weatherOffer);
  }

  const purchase = updatePurchaseFromOffers({
    purchase: booking.purchase,
    offers: newStateOffers,
    giftPromoDiscount,
  });
  if (purchase?.options) {
    purchase.options.weatherInsuranceEnabled = weatherInsuranceEnabled;
  }

  const newState: EcommerceBookingJourney = {
    ...booking,
    purchase,
    calculated: {
      ...booking.calculated,
      ...buildBookingTotals({
        dueBalance: booking.calculated.dueBalance,
        purchase,
      }),
    },
  };

  return newState;
}

// NOTE: méthode du même nom côté API
function updatePurchaseFromOffers({
  offers,
  purchase,
  giftPromoDiscount,
}: {
  offers: EcommercePurchaseOffer[];
  purchase: EcommercePurchase;
  giftPromoDiscount: EcommercePurchaseOfferGiftPromoDiscountMeta;
}) {
  const cents =
    APP_CURRENCIES.find((x) => x.isoCode === purchase.currency)?.cents ?? 2;
  offers = sortOffers(
    offers.map((offer) => ({
      ...offer,
      totalPrice: offer.quantity * offer.price,
    }))
  );

  const totalPurchase = roundWithCents(
    offers.reduce((acc, x) => acc + x.totalPrice, 0),
    cents
  );

  const giftPromoDiscounts = [];
  if (giftPromoDiscount) {
    giftPromoDiscounts.push(giftPromoDiscount);
  }

  const totalDiscountBeforeTaxes = buildTotalDiscountBeforeTaxes({
    giftPromoDiscounts,
    offers,
    cents,
  });
  const totalDiscountAfterTaxes = buildTotalDiscountAfterTaxes({
    giftPromoDiscounts,
  });

  const taxes = buildTaxes(offers, cents);

  const totalTax = roundWithCents(
    taxes.reduce((acc, x) => acc + x.taxAmount ?? 0, 0),
    cents
  );

  const newPurchase: EcommercePurchase = {
    ...purchase,
    offers,
    taxes,
    totalPurchase,
    totalTax,
    totalDiscountBeforeTaxes,
    totalDiscountAfterTaxes,
    giftPromoDiscounts,
  };

  return newPurchase;
}

// ATTENTION: fonction dupliquée côté API
function buildTotalDiscountAfterTaxes({
  giftPromoDiscounts,
}: {
  giftPromoDiscounts: EcommercePurchaseOfferGiftPromoDiscountMeta[];
}) {
  const giftCards = giftPromoDiscounts.filter((x) => x.type === 'gift-card');
  const totalDiscount = giftCards.reduce((acc, x) => acc + x.discountAmount, 0);
  return totalDiscount;
}
// ATTENTION: fonction dupliquée côté API
function buildTotalDiscountBeforeTaxes({
  giftPromoDiscounts,
  offers,
  cents,
}: {
  giftPromoDiscounts: EcommercePurchaseOfferGiftPromoDiscountMeta[];
  offers: EcommercePurchaseOffer<any>[];
  cents: number;
}) {
  const promoDiscounts = giftPromoDiscounts.filter(
    (x) => x.type === 'promo-code'
  );
  const discountPercentage = promoDiscounts.reduce(
    (acc, x) => acc + parseFloat((x.discountPercentage as unknown) as string),
    0
  );

  const discountOffers = offers.filter(
    (offer) =>
      offer.type === 'activity-session' ||
      offer.type === 'activity-session-product'
  );

  if (discountOffers.length) {
    discountOffers.forEach((x) => {
      x.discountPercentage = discountPercentage; // update discount percentage
    });
  }

  if (discountPercentage > 0) {
    const totalPurchaseElligibleToDiscount = discountOffers.reduce(
      (acc, x) => acc + x.totalPrice,
      0
    );

    if (totalPurchaseElligibleToDiscount > 0) {
      const totalDiscount = roundWithCents(
        (discountPercentage * totalPurchaseElligibleToDiscount) / 100,
        cents
      );
      return totalDiscount;
    }
  }
  return 0;
}

// roundWithCents aussi présent dans numberFormatter sur l'app pro
function roundWithCents(nb: number, cents: number): number {
  if (cents === 0) {
    return Math.round(nb);
  } else {
    const multiplier = Math.pow(10, cents);
    return Math.round(multiplier * nb) / multiplier;
  }
}

function buildTaxes(
  offers: EcommercePurchaseOffer[],
  cents: number
): EcommercePurchaseTax[] {
  const taxes: EcommercePurchaseTax[] = offers
    .filter((x) => x.taxPercentage !== 0 && x.totalPrice !== 0)
    .reduce((acc, x) => {
      // on applique la réduction avant de calculer la taxe!
      const discountMultiplier = (100 - (x.discountPercentage ?? 0)) / 100;
      const totalPrice = x.totalPrice * discountMultiplier;
      let tax: EcommercePurchaseTax = acc.find(
        (t) => t.taxPercentage === x.taxPercentage
      );
      const totalTax = totalPrice - totalPrice / (1 + x.taxPercentage);
      if (tax) {
        tax.taxAmount += totalTax;
      } else {
        tax = {
          taxPercentage: x.taxPercentage,
          taxAmount: totalTax,
          totalPrice: totalPrice,
        };
        return acc.concat([tax]);
      }
      return acc;
    }, [] as EcommercePurchaseTax[])
    .map((x) => ({
      ...x,
      totalTax: roundWithCents(x.taxAmount, cents),
      taxAmount: roundWithCents(x.taxAmount, cents),
    }));

  return dataSorter.sortMultiple(taxes, {
    getSortAttributes: (x) => [
      {
        value: x.taxPercentage,
      },
    ],
  });
}

function buildBookingTotals({
  dueBalance,
  purchase,
}: {
  dueBalance: number;
  purchase: EcommercePurchase;
}) {
  const totalWithDiscount =
    purchase.totalPurchase -
    (purchase.totalDiscountAfterTaxes ?? 0) -
    (purchase.totalDiscountBeforeTaxes ?? 0);
  const totalToPayNow = Math.max(totalWithDiscount + dueBalance, 0);
  const remainingBalance = totalWithDiscount + dueBalance;
  return { remainingBalance, totalToPayNow };
}

function sortOffers(offers: EcommercePurchaseOffer[]) {
  return dataSorter.sortMultiple(offers, {
    getSortAttributes: (offer) => {
      const sortAttributes: SortableAttribute[] = [];

      sortAttributes.push({
        value:
          offer.type === 'activity-session'
            ? 1
            : offer.type === 'activity-session-product'
            ? 2
            : 3,
      });
      if (
        offer.type === 'activity-session' ||
        offer.type === 'activity-session-product'
      ) {
        const meta: EcommercePurchaseOfferActivitySessionParticipantMeta =
          offer.meta;

        sortAttributes.push({
          value: meta.activitySession.beginDateTime,
        });
      } else {
        sortAttributes.push({
          value: undefined,
        });
      }
      sortAttributes.push({
        value: offer.price,
      });
      return sortAttributes;
    },
    asc: true,
  });
}
