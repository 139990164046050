import { IonButton, IonIcon } from '@ionic/react';
import { checkmark, closeOutline } from 'ionicons/icons';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useAppModalContext } from '_01_CORE/_components-core';
export function useTimeLimit(): {
  openTimeLimitWarningModal: () => void;
  openTimeLimitOverModal: () => void;
} {
  const { formatMessage: t } = useIntl();
  const { openModal, closeModal } = useAppModalContext();

  const openTimeLimitWarningModal = useCallback(() => {
    openModal({
      title: t({ id: 'page16.time.limit.title' }),
      content: <div>{t({ id: 'page16.time.limit.cancel' })}</div>,
      actions: (
        <div className="w-full">
          <IonButton className="w-full" color="primary" onClick={closeModal}>
            <IonIcon icon={checkmark} className="mr-2" />
            {t({ id: 'common.action.continue' })}
          </IonButton>
          <IonButton className="w-full" color="danger" onClick={closeModal}>
            <IonIcon icon={closeOutline} className="mr-2" />
            {t({ id: 'common.action.cancel' })}
          </IonButton>
        </div>
      ),
    });
  }, [closeModal, openModal, t]);

  const openTimeLimitOverModal = useCallback(() => {
    openModal({
      title: t({ id: 'page16.time.limit.title' }),
      content: <div>{t({ id: 'page16.time.limit.cancelled' })}</div>,
      actions: (
        <IonButton className="w-full" color="primary" onClick={closeModal}>
          <IonIcon icon={checkmark} className="mr-2" />
          {t({ id: 'navigation.understand' })}
        </IonButton>
      ),
    });
  }, [openModal, closeModal, t]);

  return {
    openTimeLimitWarningModal,
    openTimeLimitOverModal,
  };
}
