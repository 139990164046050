import {
  IonHeader,
  IonIcon,
  IonImg,
  IonItemGroup,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { AppIcons } from '_01_CORE/_components-core';
import { useAppRouter } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import { useAppCacheContext, useJourney } from '_01_CORE/app-contexts';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import {
  AppAuth,
  useAppLogoUrl,
  useAppSettings,
} from '_01_CORE/app-core/app-security';
import { menu } from 'ionicons/icons';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { AppSideMenu } from '../AppMenu';
type HeaderToolbarProps = {
  hideLogout?: boolean;
};

export const HeaderToolbar: React.FC<HeaderToolbarProps> = (
  props: HeaderToolbarProps
) => {
  const appId = appWebConfig().appId;
  const appSettings = useAppSettings();
  const appLogoUrl = useAppLogoUrl('md');

  const { formatMessage: t } = useIntl();
  const { auth } = useAppCacheContext();
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const appRouter = useAppRouter();

  const { customerJourneyId } = useParams<{ customerJourneyId: string }>();

  const { journey } = useJourney({
    customerJourneyId,
  });

  return (
    <IonHeader className="">
      <IonToolbar className="pl-4 pr-0 ">
        {appLogoUrl && (
          <IonImg className="w-10 h-10 m-auto" slot="start" src={appLogoUrl} />
        )}
        <IonTitle size="small">
          <div className="text-xs truncate">
            {t({ id: 'toolbar.header.hello' })} {getUserName(auth)}
          </div>
        </IonTitle>
        <IonItemGroup slot="end">
          <div className="flex gap-2">
            {/* {appWebConfig().envId === 'dev' && (
              <DebugSwitchRemoteEnv
                className="my-3 truncate"
                onSwitch={(remoteEnvId: AppRemoteEnvId) => {
                  appRouter.navigate(appRoutesBuilder.getLogoutUrl(), {
                    cause: 'switch env',
                  });
                }}
              />
            )} */}
            {journey?.shoppingCartProductsItemsCount > 0 && (
              <div className="relative">
                <AppIcons.shoppingCart
                  className="cursor-pointer mt-2 h-6 w-6 text-app-success hover:text-app-success-shade"
                  onClick={() => {
                    appRouter.navigate(
                      appRoutesBuilder.getJourneyShoppingCartLiveUrl({
                        customerJourneyId: journey?._id, // attention, customerJourneyId peut-être null, alors que journey?._id est renseigné
                      }),
                      {
                        cause: 'click-shopping-cart-icon',
                      }
                    );
                  }}
                />
                <div className="absolute top-0.5 -right-0.5 rounded-full text-white bg-red-500 text-xs px-0.5 font-bold">
                  {journey?.shoppingCartProductsItemsCount}
                </div>
              </div>
            )}
            <div className="relative inline-block text-left">
              <span
                className="inline-flex text-gray-400 shadow-sm px-4 py-2 text-sm font-medium cursor-pointer hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
                onClick={() => setIsMobileOpen(!isMobileOpen)}
              >
                <IonIcon icon={menu} className="h-6 w-6" />
              </span>
            </div>
          </div>
        </IonItemGroup>
      </IonToolbar>

      <AppSideMenu
        hideLogout={props.hideLogout}
        isMobileOpen={isMobileOpen}
        setIsMobileOpen={setIsMobileOpen}
      />
    </IonHeader>
  );
};
function getUserName(auth: AppAuth): React.ReactNode {
  if (!auth) {
    return '';
  }
  if (auth.securityUser?.profile === 'company') {
    return <span className="text-app-xxs2 text-gray-400 truncate">PRO</span>;
  }
  if (auth.isSuperAdmin) {
    return (
      <span className="text-app-xxs2 text-gray-400 truncate">SUPER ADMIN</span>
    );
  }
  const baseName = auth?.customerProfile?.account?.firstName;
  if (auth.securityUser?.impersonate?.root?.profile === 'company') {
    return (
      <>
        <span>{baseName}</span>
        <div className="text-app-xxs2 text-gray-400 truncate">PRO</div>
      </>
    );
  }
  if (auth.isSuperAdmin) {
    return (
      <>
        <span>{baseName}</span>
        <div className="text-app-xxs2 text-gray-400 truncate">SUPER ADMIN</div>
      </>
    );
  }
  return baseName;
}
