import { IonRadioGroup } from '@ionic/react';
import { Gender } from 'lib-common-model';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import { CustomerGenderRadioGroupOption } from './CustomerGenderRadioGroupOption';

export const CustomerGenderRadioGroup = ({
  value,
  isMinor,
  onChange,
}: {
  value?: Gender;
  isMinor: boolean;
  onChange: (value: Gender) => void;
}) => {
  const { formatMessage: t } = useIntl();

  const optionsGenders = useMemo(() => {
    const genders: Gender[] = ['male', 'female'];
    if (appWebConfig().appId === 'accropark') {
      genders.push('other');
      genders.push('prefer-not-to-say');
    }
    return genders;
  }, []);

  return (
    <IonRadioGroup
      onIonChange={(e) => {
        e?.detail?.value && onChange(e.detail.value);
      }}
      value={value}
    >
      <div
        className={`grid grid-cols-2 ${
          optionsGenders?.length === 4 ? 'xl:grid-cols-4' : ''
        } items-center gap-2 mt-2`}
      >
        {optionsGenders.map((gender) => (
          <CustomerGenderRadioGroupOption
            // className={
            //   gender === 'other' || gender === 'prefer-not-to-say'
            //     ? 'col-span-2 sm:col-span-1'
            //     : ''
            // }
            key={gender}
            isMinor={isMinor}
            gender={gender}
            isSelected={value === gender}
          />
        ))}
      </div>
    </IonRadioGroup>
  );
};
