import { IonButton, IonIcon } from '@ionic/react';
import {
  AccountCreationTitle,
  AppPage,
  FooterActions,
  HeaderToolbar,
  PageContainer,
} from '_01_CORE/_common-layout';
import { AppLoader } from '_01_CORE/_components-core';
import { useAppRouter } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import { useAppCacheContext } from '_01_CORE/app-contexts';
import { useAppSettings } from '_01_CORE/app-core/app-security';
import {
  useJourneyActivitiesLocalState,
  useJourneyActivitiesPageOnClickParticipant,
} from '_02_APP_COMMON/ACT-activity/ACT-16-journey-activities';
import {
  JourneyActivitiesLocalState,
  JourneyDailyActivityCard,
  JourneyDailyNoActivityParticipantsCard,
} from '_02_APP_COMMON/ACT-activity/ACT-16-journey-activities/JourneyDailyActivity';
import { checkmark } from 'ionicons/icons';
import { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';

export const AccroparkBookingActivitiesPage = () => {
  const appRouter = useAppRouter();
  const { formatMessage: t } = useIntl();

  const { auth, dispatchCacheContextAction } = useAppCacheContext();
  const localState: JourneyActivitiesLocalState = useJourneyActivitiesLocalState();

  const {
    journey,
    deleteJourneyBookingConfirm,
    journeyDateText,
    customerAccount,
    appId,
    setLastSelectedSessionId,
    hasAnyFilter,
    isJourneyReplacementInProgress,
    journeyActivitiesRepo,
    aggregatedData,
  } = localState;

  const canGoBackAccroparkJourneyBooking = useMemo(
    () =>
      !journeyActivitiesRepo.state.data?.customerJourneyActivities
        ?.activitySessionsParticipants.length,
    [
      journeyActivitiesRepo.state.data?.customerJourneyActivities
        ?.activitySessionsParticipants.length,
    ]
  );

  const canSubmitAccroparkJourneyBooking = !canGoBackAccroparkJourneyBooking;

  useEffect(
    () => {
      if (journeyDateText) {
        journeyActivitiesRepo.actions.refreshPageActivities({
          cause: 'day-initial-load',
        }); // initial load
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [journeyDateText]
  );

  const onSubmit = () => {
    appRouter.navigate(
      appRoutesBuilder.getJourneyOptionsUrl({
        customerJourneyId: journey?._id,
      }),
      { cause: 'journey-activities-submit' }
    );
  };

  const goBack = () => {
    dispatchCacheContextAction({
      type: 'set-journey-to-edit',
      payload: {
        journey,
        customerAccount,
      },
    });
    appRouter.navigate(
      appRoutesBuilder.getJourneyCreateParticipantsListUrl({
        companyReference: journey?.company?.reference,
      }),
      {
        cause: 'journey-activities-back',
      }
    );
  };

  const appSettings = useAppSettings();
  const whiteLabel = appSettings?.universe?.whiteLabel;
  /**
   * Choose another destination button callback
   */
  const handleChooseDestination = useCallback(() => {
    appRouter.navigate(
      appRoutesBuilder.getJourneyCompanyChooseUrl({
        companyReference: journey?.company?.reference,
        whiteLabel,
      }),
      {
        cause: 'journey-activities-page-select-company',
      }
    );
  }, [appRouter, journey?.company, whiteLabel]);

  const onClickParticipant = useJourneyActivitiesPageOnClickParticipant(
    localState
  );

  const dailyActivitiesToDisplay = useMemo(
    () =>
      (aggregatedData?.dailyActivities ?? []).filter(
        (dailyActivity) => dailyActivity.selectedSession
      ),
    [aggregatedData?.dailyActivities]
  );

  return !journey ? null : (
    <AppPage displayFooter={false}>
      <HeaderToolbar hideLogout={true} />
      <PageContainer className="text-center bg-gray-50" spacing="normal">
        <AccountCreationTitle
          company={journey?.company}
          displayAddress={true}
          displayButtonChooseDestination={
            !whiteLabel.appCompanyReference && auth?.isAuthenticated
          }
          buttonText={t({ id: `page10.choose.${appId}` })}
          onClick={handleChooseDestination}
        />
        {
          <div className="flex mb-2 place-items-center">
            <div className="ml-2 text-primary text-left flex-grow place-self-start text-xl mm:text-2xl ml:text-3xl sm:text-4xl">
              {t({ id: 'page16.timetablesPrices' })} - {journeyDateText}
            </div>
          </div>
        }
        {aggregatedData?.dailyActivities ? (
          <>
            {aggregatedData?.dailyActivities.length ? (
              <div className="font-medium mt-2 text-sm">
                {t({ id: 'page16.choose' })}
              </div>
            ) : (
              <div className="font-bold mt-10 text-center">
                {t({ id: `page16.no-activity.${appId}` })}
              </div>
            )}
          </>
        ) : (
          <AppLoader className="my-2 p-2 px-4" />
        )}
        {dailyActivitiesToDisplay.length > 0 && (
          <div className="flex flex-col gap-4">
            {dailyActivitiesToDisplay.map((dailyActivity, i) => (
              <JourneyDailyActivityCard
                companyReference={journey?.company.reference}
                context="list"
                key={`${dailyActivity.selectedSession.activitySession.activitySessionId}-${dailyActivity.selectedSession.activitySession.activitySessionId}-${dailyActivity.displayMode}`}
                dailyActivity={dailyActivity}
                onClickParticipant={onClickParticipant}
                onSelectSessionId={setLastSelectedSessionId}
                viewStep={'default'}
              />
            ))}
          </div>
        )}
        {!hasAnyFilter &&
          aggregatedData?.participantsWithoutActivities?.length > 0 && (
            <JourneyDailyNoActivityParticipantsCard
              participantsWithoutActivities={
                aggregatedData?.participantsWithoutActivities
              }
            />
          )}
        {journey?.status !== 'booking-in-progress' ? null : (
          <>
            <div className="mt-4 bg-danger text-center text-white font-semibold p-2">
              ⚠️ {t({ id: 'page16.booking.timeRemaining' }, { time: 10 })}
            </div>
            <div className="mt-4 text-center font-semibold w-full">
              <div>{t({ id: 'page16.attention' })}</div>
              <div>{t({ id: 'page16.slots.not.reserved.yet' })}</div>
            </div>
            {!isJourneyReplacementInProgress &&
              canSubmitAccroparkJourneyBooking && (
                <div className="mt-10 flex flex-col space-y-8 max-w-md mx-auto">
                  <IonButton
                    className="w-full"
                    color="primary"
                    onClick={onSubmit}
                  >
                    <IonIcon icon={checkmark} className="mr-2" />
                    {t({ id: 'common.action.continue.booking.payment' })}
                  </IonButton>
                </div>
              )}
          </>
        )}
      </PageContainer>
      {
        <FooterActions
          onCancel={() => deleteJourneyBookingConfirm()}
          onBack={canGoBackAccroparkJourneyBooking ? goBack : undefined}
          onSubmit={canSubmitAccroparkJourneyBooking ? onSubmit : undefined}
        />
      }
    </AppPage>
  );
};
