import { SelectOption } from '../../../form';

export const RELATIONSHIP_OPTIONS: SelectOption[] = [
  {
    id: 'parent',
    label: 'parent',
  },
  {
    id: 'grandparent',
    label: 'grand-parent',
  },
  {
    id: 'brother/sister',
    label: 'frère/soeur',
  },
  {
    id: 'uncle/aunt',
    label: 'oncle/tante',
  },
  {
    id: 'legal-guardian',
    label: 'tuteur légal',
  },
  {
    id: 'OTHER',
    label: 'autre',
  },
];
