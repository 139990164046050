import { authenticationClient } from '_01_CORE/app-core/app-security';
import {
  AuthenticationResult,
  BUProfile,
  CMJourneyEditCustomerAccount,
  CMProfile,
} from 'lib-common-model';
import { apiClient } from 'lib-web-api-client';
import { appLogger } from 'lib-web-logger';

export const customerAccountApiClient = {
  updateCustomerAccount,
  deleteMyAccount,
};

async function updateCustomerAccount(
  customerAccount: CMJourneyEditCustomerAccount
): Promise<{
  result: 'success' | 'unexpected-error' | 'email-already-exists';
}> {
  // send remote operation
  try {
    const response = await apiClient.put<
      AuthenticationResult<'multi-users-v2', CMProfile | BUProfile>
    >('/customer-web-mobile-account', {
      options: {
        authenticate: true,
        json: {
          customerAccount,
        },
      },
    });
    await authenticationClient.updateTokenAndProfile(response);
    return {
      result: 'success',
    };
  } catch (err) {
    if ((err as any)?.response?.status === 409) {
      return {
        result: 'email-already-exists',
      };
    } else {
      appLogger.error('Unexpected error creating customer account', {
        err: err as any,
      });
      return {
        result: 'unexpected-error',
      };
    }
  }
}

async function deleteMyAccount(): Promise<void> {
  const response = await apiClient.put<
    AuthenticationResult<'multi-users-v2', CMProfile | BUProfile>
  >('/customer-web-mobile-account/delete-my-account', {
    options: {
      authenticate: true,
    },
  });
  await authenticationClient.updateTokenAndProfile(response);
}
