import { Control, Controller } from 'react-hook-form';
import { AppInputPhoto, AppInputPhotoProps } from './AppInputPhoto';
import { AppInputPhotoPreview } from './AppInputPhotoPreview';

export const AppInputPhotoRHFControl = ({
  control,
  name,
  preview = {
    show: true,
    size: 'large',
  },
  ...otherProps
}: {
  control: Control<any>;
  name: string;
  preview?: {
    show?: boolean;
    size?: 'small' | 'large';
    onDeleteConfirmMessage?: string;
  };
} & AppInputPhotoProps) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) =>
        value && preview?.show !== false ? (
          <AppInputPhotoPreview
            deleteLabel={'Supprimer'}
            photo={value}
            onDelete={() => {
              onChange(null);
            }}
            size={preview?.size ?? 'small'}
            onDeleteConfirmMessage={preview?.onDeleteConfirmMessage}
          />
        ) : (
          <AppInputPhoto
            className="mt-2"
            onBlur={onBlur}
            value={value}
            name={name}
            ref={ref}
            onChange={(value) => {
              onChange(value);
            }}
            {...otherProps}
          />
        )
      }
    />
  );
};
