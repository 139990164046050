import { AppPage, PageContainer } from '_01_CORE/_common-layout';
import { AppButtonUniverse, AppLoader } from '_01_CORE/_components-core';
import { useAppRouter } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import {
  useAppCacheContext,
  useJourneyEnabledActions,
} from '_01_CORE/app-contexts';
import {
  AppAuth,
  authenticationStore,
  useAppSettings,
} from '_01_CORE/app-core/app-security';
import { AppUniverseHeader } from '_02_APP_COMMON/_global';
import { AuthMultiLoginAnimLivePageInfoPanel } from '_04_ANIM_LIVE/AUT-auth/02-login/AuthMultiLoginAnimLivePageInfoPanel';
import {
  AuthMultiLoginAnimLivePageViewState,
  AuthPageMultiLoginJourneyActivationState,
} from '_04_ANIM_LIVE/AUT-auth/02-login/_model';
import { authMultiLoginAnimLivePageViewStateBuilder } from '_04_ANIM_LIVE/AUT-auth/02-login/_services';
import { AuthPageMultiLoginCheckKeyPanel } from '_04_ANIM_LIVE/AUT-auth/02-login/check-key';
import {
  AuthMultiLoginAnimLivePageLocalState,
  useAuthMultiLoginAnimLivePageLocalState,
} from '_04_ANIM_LIVE/AUT-auth/02-login/useAuthMultiLoginAnimLivePageLocalState.hook';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { createJourneyActivationCodePageInitialStateBuilder } from './createJourneyActivationCodePageInitialStateBuilder.service';

export const CreateJourneyActivationCodePage = () => {
  const appCacheContext = useAppCacheContext();

  const { formatMessage: t } = useIntl();

  const appSettings = useAppSettings();
  const title1Color = appSettings?.universe?.theme?.title1Color;

  const routeParams = new URLSearchParams(window.location.search);
  const companyValidReferenceCode =
    routeParams.get('companyReference') ??
    appCacheContext?.companyValidReferenceCode;
  const journeyEnabledActions = useJourneyEnabledActions({});
  const initialViewState: AuthMultiLoginAnimLivePageViewState = useMemo(
    () =>
      createJourneyActivationCodePageInitialStateBuilder.buildInitialViewState(
        appCacheContext,
        {
          journeyEnabledActions,
          companyValidReferenceCode,
        }
      ),
    [appCacheContext, companyValidReferenceCode, journeyEnabledActions]
  );

  const localState: AuthMultiLoginAnimLivePageLocalState = useAuthMultiLoginAnimLivePageLocalState(
    initialViewState
  );

  const { viewState, setViewState, actions } = localState;
  const { journeyInfo, operationInProgress, mode } = viewState;
  const appRouter = useAppRouter();

  return (
    <AppPage tabId="login">
      <PageContainer
        className={`h-full bg-white text-center flex flex-col gap-4`}
        style={{
          color: title1Color,
        }}
      >
        <AppUniverseHeader showLargeHeader={false} />

        <div className="h-full w-full max-w-sm md:max-w-md mx-auto flex flex-col gap-8">
          {operationInProgress ? (
            <AppLoader
              className="my-4 p-2 px-4"
              type={operationInProgress?.type}
              message={operationInProgress?.message}
            />
          ) : (
            <>
              <AuthMultiLoginAnimLivePageInfoPanel localState={localState} />
              {mode === 'check-key' && (
                <>
                  <AuthPageMultiLoginCheckKeyPanel
                    context="auth-login-activation"
                    initialActivationKey={viewState?.checkKey?.key}
                    onJourneyFinderResultUpdated={(fetchedJourney) => {
                      // actions.processCheckKeySuccess(fetchedJourney)
                      const activationState: AuthPageMultiLoginJourneyActivationState = authMultiLoginAnimLivePageViewStateBuilder.buildJourneyActivationState(
                        fetchedJourney
                      );
                      const isSingleBookingNotTO =
                        fetchedJourney?.bookingType === 'single';

                      const auth: AppAuth = authenticationStore.auth.getSnapshot();
                      const profile = auth?.customerProfile;

                      const existingJourney = profile.journeys.find(
                        (j) =>
                          j.extCustomerJourneyBookingGroupId ===
                          fetchedJourney?.extCustomerJourneyBookingGroupId
                      );
                      if (existingJourney) {
                        appRouter.navigate(
                          appRoutesBuilder.getJourneyHomeUrl({
                            customerJourneyId: existingJourney._id,
                          }),
                          { cause: 'activate-journey-already-exists' }
                        );
                      } else {
                        if (
                          activationState === 'already-activated' &&
                          isSingleBookingNotTO &&
                          !viewState.extra.journeyEnabledActions
                            .isActivateJourneyMultipleEnabled
                        ) {
                          // déjà activé pour un autre compte
                          appRouter.navigate(
                            appRoutesBuilder.getJourneysUrl(),
                            {
                              cause: 'activate-journey-already-exists',
                            }
                          );
                        } else {
                          // activation du séjour
                          appCacheContext.dispatchCacheContextAction({
                            type: 'set-journey-to-edit-from-external-data',
                            payload: {
                              companyReference: fetchedJourney.companyReference,
                              externalData: fetchedJourney,
                              activationToken: fetchedJourney.activationToken,
                              customerAccount: profile.account,
                              customerAccountMembers: profile.members,
                            },
                          });
                          appRouter.navigate(
                            appRoutesBuilder.getJourneyCreateParticipantsListUrl(
                              {
                                companyReference:
                                  fetchedJourney.companyReference,
                              }
                            ),
                            { cause: 'create-journey-from-external' }
                          );
                        }
                      }
                    }}
                  />
                </>
              )}
            </>
          )}
          <div className="flex-grow"></div>
          <div className="mt-8 ion-justify-content-center">
            <AppButtonUniverse
              style="light"
              className="w-full mt-4 uppercase"
              isFullWidth={true}
              href={appRoutesBuilder.getJourneysUrl()}
            >
              {t({ id: 'common.action.back' })}
            </AppButtonUniverse>
          </div>
        </div>
      </PageContainer>
    </AppPage>
  );
};
