import {
  AppTranslationContentItem,
  CompanyOptionsTheme,
} from 'lib-common-model';
import React, { useMemo } from 'react';
import { useAppTheme } from '_01_CORE/_common-layout';
import { AppRichTextArea } from '../AppRichText/rendering/AppRichTextArea';
import { AppTranslationContentButton } from './AppTranslationContentButton';
import { AppTranslationContentImage } from './AppTranslationContentImage';

export const AppTranslationContentPanel = ({
  item,
  companyReference,
  className,
}: {
  item: AppTranslationContentItem;
  companyReference: string;
  className?: string;
}) => {
  const theme: CompanyOptionsTheme = useAppTheme({ companyReference });

  const style: React.CSSProperties = useMemo(
    () => ({
      color: theme.textColor,
    }),
    [theme.textColor]
  );

  return !item ? null : (
    <>
      {item.type === 'text' && item.text?.length !== 0 && (
        <div className={`my-3 leading-6 ${className}`} style={style}>
          <p
            dangerouslySetInnerHTML={{
              __html: item.text.replace(/\n/g, '<br/>'),
            }}
          />
        </div>
      )}
      {item.type === 'rich-text' && item.richText?.elements.length !== 0 && (
        <AppRichTextArea
          theme={theme}
          className={`my-3 leading-6 ${className}`}
          content={item.richText}
        />
      )}
      {item.type === 'image' && item.image && (
        <AppTranslationContentImage
          className={`my-4  ${className}`}
          image={item.image}
        />
      )}
      {item.type === 'button' && item.button && (
        <div className={`text-center  ${className}`}>
          <AppTranslationContentButton
            theme={theme}
            className="my-2"
            button={item.button}
          />
        </div>
      )}
    </>
  );
};
