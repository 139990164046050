import { useMemo, useState } from 'react';
import { dateServiceCore } from '../../../../stories/services';
import { AppHackableCalendarVM } from './model';
import {
  AppHackableCalendarProps,
  AppHackableCalendarPropsLabels,
  APP_HACKABLE_CALENDAR_PROPS_DEFAULT_LABELS_FR,
} from './props';

export function useAppHackableCalendarVM({
  props,
}: {
  props: AppHackableCalendarProps;
}): AppHackableCalendarVM {
  const selectedDates = useMemo(
    () =>
      props.selectedDates ?? (props.selectedDate ? [props.selectedDate] : []),
    [props.selectedDate, props.selectedDates]
  );

  const minDate = useMemo(() => {
    if (props.minDate) {
      return dateServiceCore.truncateTimeUTC(props.minDate);
    } else if (props.minYear !== undefined && props.minYear !== null) {
      return new Date(Date.UTC(props.minYear, 0, 1));
    }
  }, [props.minDate, props.minYear]);

  const maxDate = useMemo(() => {
    if (props.maxDate) {
      return dateServiceCore.truncateTimeUTC(props.maxDate);
    } else if (props.maxYear !== undefined && props.maxYear !== null) {
      return new Date(Date.UTC(props.maxYear, 11, 31));
    }
  }, [props.maxDate, props.maxYear]);

  const initialViewDate = useMemo(() => {
    const d =
      props.initialViewDate ??
      (selectedDates.length ? selectedDates[0] : new Date());
    // remove time
    const initialViewDateWithoutTime = dateServiceCore.truncateTimeUTC(d);
    return initialViewDateWithoutTime;
  }, [props.initialViewDate, selectedDates]);

  const [viewDate, setViewDate] = useState(initialViewDate);
  const [view, setView] = useState(props.initialView ?? 'weeks');

  return useMemo(() => {
    // set default labels
    const labels: AppHackableCalendarPropsLabels = {
      ...APP_HACKABLE_CALENDAR_PROPS_DEFAULT_LABELS_FR,
      ...(props.labels ?? {}),
    };

    const vm: AppHackableCalendarVM = {
      view,
      setView,
      selectedDates,
      viewDate,
      minDate,
      maxDate,
      setViewDate,
      labels,
      firstIsoDayOfWeek: 1, // Monday
      props,
    };
    return vm;
  }, [maxDate, minDate, props, selectedDates, view, viewDate]);
}
