import { IonIcon } from '@ionic/react';
import capitalize from 'capitalize';
import { pencilSharp } from 'ionicons/icons';
import { CMProfileMember, JourneyParticipant } from 'lib-common-model';
import React from 'react';
import { useIntl } from 'react-intl';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import { useAppRouter } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import { ParticipantAvatar } from '../ParticipantAvatar';

export const JourneyParticipantCard: React.FC<{
  customerJourneyId: string;
  participant: {
    member: Pick<
      CMProfileMember,
      'gender' | 'firstName' | 'lastName' | 'photo'
    > &
      Partial<Pick<CMProfileMember, 'customerChildId'>>;
    attributes: Pick<JourneyParticipant, 'journeyAge'>;
  };
  className?: string;
  isEditEnabled?: boolean;
}> = ({ customerJourneyId, participant, className, isEditEnabled }) => {
  const appId = appWebConfig().appId;

  const appRouter = useAppRouter();

  const { formatMessage: t } = useIntl();
  const lastName = participant.member?.lastName?.toUpperCase();
  const firstName = participant.member?.firstName
    ? capitalize(participant.member?.firstName)
    : '';

  return (
    <div className={'w-full flex ' + className}>
      <div className="mr-2 ml-1">
        <ParticipantAvatar participant={participant} />
      </div>
      <div className="flex-grow flex items-center font-bold">
        {lastName} {firstName}
        {participant.attributes?.journeyAge && (
          <span className="italic font-normal text-sm ml-2">
            {` (${participant.attributes.journeyAge} ${t({
              id: 'page02.age',
            })})`}
          </span>
        )}
      </div>
      {isEditEnabled && (
        <div
          className="flex flex-wrap text-tertiary text-sm gap-x-4 ml-2 cursor-pointer"
          onClick={() => {
            appRouter.navigate(
              appRoutesBuilder.getJourneyEditParticipantEditUrl({
                customerJourneyId: customerJourneyId,
                customerChildId: participant.member.customerChildId,
              }),
              {
                cause: 'edit-participant',
              }
            );
          }}
        >
          <span className="flex flex-col">
            <IonIcon
              className="w-6 h-6 m-auto text-primary"
              icon={pencilSharp}
            />
            <span className="text-primary">
              {t({ id: `component.journey.edit.${appId}` })}
            </span>
          </span>
        </div>
      )}
    </div>
  );
};
