import { AppClientNotification, CMCompany, CMJourney } from 'lib-common-model';
import { appLogger } from 'lib-web-logger';
import { AppCacheContextState } from '../model';

export const appCacheContextBuilder = {
  updateStateCompany,
  updateStateJourney,
  updateStateNotifications,
};

function updateStateCompany({
  state,
  company,
  updateCompanyValidReferenceCode,
}: {
  state: AppCacheContextState;
  company: CMCompany;
  updateCompanyValidReferenceCode?: boolean;
}) {
  if (!company) {
    return state;
  }
  const companyValidReferenceCode = updateCompanyValidReferenceCode
    ? company.reference
    : state.companyValidReferenceCode;
  if (updateCompanyValidReferenceCode) {
    appLogger.debug(
      `[appCacheContextReducer] set-valid-company: '${companyValidReferenceCode}'`
    );
  }
  // update companies cache
  const companies = {
    ...state.companies,
  };
  companies[company.reference] = company;

  return {
    ...state,
    companies,
    companyValidReferenceCode,
  };
}

function updateStateJourney({
  state,
  customerJourney,
}: {
  state: AppCacheContextState;
  customerJourney: CMJourney;
}) {
  if (state.auth?.customerProfile?.journeys) {
    const journeys = state.auth.customerProfile.journeys.map((j) => {
      if (j._id === customerJourney._id) {
        return customerJourney;
      }
      return j;
    });
    return {
      ...state,
      auth: {
        ...state.auth,
        customerProfile: {
          ...state.auth.customerProfile,
          journeys,
        },
      },
    };
  }
  return state;
}

function updateStateNotifications({
  state,
  notifications,
  forceUnreadNotification,
}: {
  state: AppCacheContextState;
  notifications: AppClientNotification[];
  forceUnreadNotification?: boolean;
}) {
  const notificationsUpdateDate = notifications?.length
    ? new Date(notifications[0].sendDate)
    : state.notificationsUpdateDate;
  const unreadNotification =
    forceUnreadNotification || !!notifications.find((n) => !n.readDate);
  return {
    ...state,
    notifications,
    notificationsUpdateDate,
    unreadNotification,
  };
}
