import {
  disconnect,
  loadDataGuard,
  loadJourneyBookingByRouteParameter,
  redirectToDefaultRoute,
  requireFeatures,
  requireJourneyEditData,
  requireJourneyStatus,
  testCompanyOrGroupReference,
  testRedirectFromOldVersion,
} from '_01_CORE/_pages-routes/guards';
import {
  useAppCacheContext,
  useAppLocalizationContext,
} from '_01_CORE/app-contexts';
import { APP_CURRENT_REGISTRATION_BROWSER_STORAGE_ID } from '_01_CORE/app-core/app-bootstrap/APP_CURRENT_REGISTRATION_BROWSER_STORAGE_ID.const';
import { persistentCache } from '_01_CORE/app-core/app-platform';
import { AppRoute, AppRouteGuardContext } from '_01_CORE/app-core/app-router';
import { useAppSettings } from '_01_CORE/app-core/app-security';
import {
  DebugPage,
  HACK_DEBUG_PAGE_PUBLIC_UNTIL_NEXT_DEPLOY,
  OldVersionPage,
  ResetPasswordConfirmPage,
  ResetPasswordQueryPage,
} from '_02_APP_COMMON/AUT-auth';
import { CompanyContactPage } from '_02_APP_COMMON/COM-company/COM-05-company-contact';
import { CompanyGroupHomePage } from '_02_APP_COMMON/COM-company/COM-07-company-group-home';
import {
  CompanyBookletPage,
  CompanyBookletSheetPage,
  CompanyBookletThemePage,
} from '_02_APP_COMMON/COM-company/COM-08-company-booklet';
import { AccountChooseCompanyPage } from '_02_APP_COMMON/JOU-journey/JOU-11-journey-choose-company';
import { JourneyCreateParticipantsListAccroparkPage } from '_02_APP_COMMON/JOU-journey/JOU-13-journey-participants-list';
import { NotificationsListPage } from '_02_APP_COMMON/NOT-notifications/NOT-06-notifications-list';
import { CMJourneyEditData } from 'lib-common-model';
import React, { useCallback, useEffect } from 'react';
import { Route } from 'react-router';
import { Redirect } from 'react-router-dom';
import { AccroparkCustomerAccountGiftCardEditPage } from './ACC-account/ACC-14-customer-account-edit/AccroparkCustomerAccountGiftCardEditPage';
import { AccroparkCustomerAccountJourneyEditPage } from './ACC-account/ACC-14-customer-account-edit/AccroparkCustomerAccountJourneyEditPage';
import { AccroparkBookingActivitiesPage } from './ACT-activities';
import { AuthPageLoginAccropark } from './AUT-auth';
import { AccroparkBookingOptionsPage } from './ECO-ecommerce/ECO-17-booking-options';
import { AccroparkPaymentPage } from './ECO-ecommerce/ECO-25-payment';
import { AccroparkGiftCardEditPage } from './ECO-ecommerce/ECO-26-gift-card';
import { AccroparkBookingResumePage } from './ECO-ecommerce/ECO-27-booking-resume';
import { AccroparkJourneyHomePage } from './JOU-journey/JOU-02-journey-home';
import { JourneysListAccroparkPage } from './JOU-journey/JOU-04-journeys-list';
import { AccroparkJourneyChooseDatesPage } from './JOU-journey/JOU-10-journey-choose-dates';
import { AccroparkJourneyProPage } from './JOU-journey/JOU-22-journey-pro/AccroparkJourneyProPage';

export const AppCustomerRoutesAccropark: React.FC = () => {
  const { loaded, dispatchCacheContextAction } = useAppCacheContext();

  // TODO @toub quand on créé un séjour, il faudrait avoir une route spécifique, pour être sûr que le cache est vidé
  // et peut-être un cache différent entre création et édition
  // TODO @toub il va falloir faire des statuts qui dépendent de l'application sur laquelle on est: peut-être faire des routes complètement différentes pour les 2 applis?
  // TODO en cas d'édition de séjour, ilfaudrait des gards sur le statut du séjour, mais comme le séjour est en cache et pas dans la routeUrl, c'est plus compliqué
  // au lieu de passer des gards, peut-être passer un objet qui défini tous les checks à faire, plus optimisé et clair à l'utilisation?
  // {
  //   auth: 'required',
  //   journeyFromUrl: 'customerJourneyId',
  //   journeyFromCache: true,
  //   journeyAllowedStatus: ['draft']
  // }
  // TODO @toub intégrer useJourneyEditDataDraftOrRedirect dedans

  useEffect(() => {
    persistentCache
      .get<CMJourneyEditData>(APP_CURRENT_REGISTRATION_BROWSER_STORAGE_ID, {
        ignoreError: true,
      })
      .then((journeyEditData) => {
        // restore or clear journey in redux cache
        dispatchCacheContextAction({
          type: 'restore-journey-edit-data-from-cache',
          journeyEditData,
        });
      });
  }, [dispatchCacheContextAction]);

  const testCompanyOrGroupReferenceGuard = useCallback(
    (context: AppRouteGuardContext) =>
      testCompanyOrGroupReference('companyOrGroupReference')(context),
    []
  );

  const requireFeatureAuth = useCallback(
    (context: AppRouteGuardContext) =>
      requireFeatures('authentication')(context),
    []
  );
  const requireJourneyStatusActivitiesDraftOrBookingInProgress = useCallback(
    (context: AppRouteGuardContext) =>
      requireJourneyStatus('customerJourneyId', [
        'draft',
        'booking-in-progress',
      ])(context),
    []
  );
  const requireJourneyStatusBookingInProgress = useCallback(
    (context: AppRouteGuardContext) =>
      requireJourneyStatus('customerJourneyId', ['booking-in-progress'])(
        context
      ),
    []
  );
  const requireJourneyStatusPaymentInProgress = useCallback(
    (context: AppRouteGuardContext) =>
      requireJourneyStatus('customerJourneyId', [
        'booking-in-progress',
        'payment-in-progress',
      ])(context),
    []
  );
  const loadJourneyBookingGuard = useCallback(
    (context: AppRouteGuardContext) =>
      loadJourneyBookingByRouteParameter('customerJourneyId')(context),
    []
  );
  const disconnectGuard = useCallback(
    (context: AppRouteGuardContext) => disconnect()(context),
    []
  );

  const {
    locales: { platformLanguageCode, appLanguageCode },
  } = useAppLocalizationContext();

  const appSettings = useAppSettings();
  const whiteLabel = appSettings?.universe?.whiteLabel;

  const loadDataGuardDefault = useCallback(
    (context: AppRouteGuardContext) =>
      loadDataGuard({
        routeParamsNames: {
          companyReference: 'companyReference',
          groupReference: 'groupReference',
          customerJourneyId: 'customerJourneyId',
          bookingId: 'bookingId',
        },
        fetchCompanyWelcomeBooklet: false,
        platformLanguageCode,
        appLanguageCode,
      })(context),
    [appLanguageCode, platformLanguageCode]
  );

  const loadDataGuardWithBookletsDefault = useCallback(
    (context: AppRouteGuardContext) =>
      loadDataGuard({
        routeParamsNames: {
          companyReference: 'companyReference',
          groupReference: 'groupReference',
          customerJourneyId: 'customerJourneyId',
          bookingId: 'bookingId',
        },
        fetchCompanyWelcomeBooklet: true,
        platformLanguageCode,
        appLanguageCode,
      })(context),
    [appLanguageCode, platformLanguageCode]
  );
  return (
    loaded && (
      <>
        {' '}
        <Route
          path="/camping/:companyOrGroupReference/inscriptions" // ATTENTION: ce lien est un lien direct utilisé (directement ou indirectement) par les vacanciers, ne pas le modifier
          exact={true}
          render={() => (
            <AppRoute
              component={ResetPasswordQueryPage}
              routeGuards={[testCompanyOrGroupReferenceGuard]}
            />
          )}
        />
        <Route
          path="/groupe/:companyOrGroupReference/inscriptions" // ATTENTION: ce lien est un lien direct utilisé (directement ou indirectement) par les vacanciers, ne pas le modifier
          exact={true}
          render={() => (
            <AppRoute
              component={AuthPageLoginAccropark}
              routeGuards={[testCompanyOrGroupReferenceGuard]}
            />
          )}
        />
        <Route
          path="/company/:companyReference/booklet"
          exact={true}
          render={() => (
            <AppRoute
              component={CompanyBookletPage}
              routeGuards={[loadDataGuardWithBookletsDefault]}
            />
          )}
        />
        <Route
          path="/company/:companyReference/gift-card"
          exact={true}
          render={() => (
            <AppRoute
              component={AccroparkGiftCardEditPage}
              routeGuards={[loadDataGuardWithBookletsDefault]}
            />
          )}
        />
        <Route
          path="/company/:companyReference/booking/:bookingId/edit-gift-card"
          exact={true}
          render={() => (
            <AppRoute
              component={AccroparkGiftCardEditPage}
              routeGuards={[loadDataGuardWithBookletsDefault]}
            />
          )}
        />
        <Route
          path="/company/:companyReference/booking/:bookingId/resume"
          exact={true}
          render={() => (
            <AppRoute
              component={AccroparkBookingResumePage}
              routeGuards={[loadDataGuardWithBookletsDefault]}
            />
          )}
        />{' '}
        <Route
          path="/company/:companyReference/booking/:bookingId/account-edit"
          exact={true}
          render={() => (
            <AppRoute
              component={AccroparkCustomerAccountGiftCardEditPage}
              routeGuards={[loadDataGuardWithBookletsDefault]}
            />
          )}
        />
        <Route
          path="/company/:companyReference/booklet/theme/:bookletThemeReference"
          exact={true}
          render={() => (
            <AppRoute
              component={CompanyBookletThemePage}
              routeGuards={[loadDataGuardWithBookletsDefault]}
            />
          )}
        />
        <Route
          path="/company/:companyReference/booklet/theme/:bookletThemeReference/sheet/:bookletSheetReference"
          exact={true}
          render={() => (
            <AppRoute
              component={CompanyBookletSheetPage}
              routeGuards={[loadDataGuardWithBookletsDefault]}
            />
          )}
        />
        {/* </IonRouterOutlet> */}
        {/* NOTE: `render={() => ` permet d'éviter des sauts lors que l'on navigue, car les paramètres de route entrainent un rafraichissement du composant: https://github.com/ionic-team/ionic-framework/issues/21635*/}
        <Route
          path="/login"
          exact={true}
          render={() => <AppRoute component={AuthPageLoginAccropark} />}
        />
        <Route
          path="/logout"
          exact={true}
          render={() => (
            <AppRoute
              routeGuards={[disconnectGuard]}
              component={AuthPageLoginAccropark}
            />
          )}
        />
        <Route
          path="/journey/:customerJourneyId"
          exact={true}
          render={() => (
            <AppRoute
              component={AccroparkJourneyHomePage}
              auth={{ authenticationRequired: true }}
              routeGuards={[requireFeatureAuth, loadDataGuardDefault]}
            />
          )}
        />
        <Route
          path="/journey-create/:companyReference/participants-list"
          exact={true}
          render={() => (
            <AppRoute
              routeGuards={[
                requireFeatureAuth,
                requireJourneyEditData,
                loadDataGuardDefault,
              ]}
              component={JourneyCreateParticipantsListAccroparkPage}
            />
          )}
        />
        <Route
          path="/account/create/:companyReference"
          exact={true}
          render={() => (
            <AppRoute
              component={AccroparkJourneyChooseDatesPage}
              routeGuards={[requireFeatureAuth, loadDataGuardDefault]}
            />
          )}
        />
        <Route
          path="/journey/:customerJourneyId/pro"
          exact={true}
          render={() => (
            <AppRoute
              component={AccroparkJourneyProPage}
              auth={{ authenticationRequired: true }}
              routeGuards={[
                requireFeatureAuth,
                loadDataGuardDefault,
                loadJourneyBookingGuard,
              ]}
            />
          )}
        />
        <Route
          path="/accropark/journey/:customerJourneyId/activities"
          exact={true}
          render={() => (
            <AppRoute
              component={AccroparkBookingActivitiesPage}
              auth={{ authenticationRequired: true }}
              routeGuards={[
                requireFeatureAuth,
                loadDataGuardDefault,
                requireJourneyStatusActivitiesDraftOrBookingInProgress,
              ]}
            />
          )}
        />
        <Route
          path="/journey/:customerJourneyId/options"
          exact={true}
          render={() => (
            <AppRoute
              routeGuards={[
                requireFeatureAuth,
                loadDataGuardDefault,
                loadJourneyBookingGuard,
                requireJourneyStatusBookingInProgress,
              ]}
              component={AccroparkBookingOptionsPage}
              auth={{ authenticationRequired: true }}
            />
          )}
        />
        <Route
          path="/journey/:customerJourneyId/payment"
          exact={true}
          render={() => (
            <AppRoute
              routeGuards={[
                requireFeatureAuth,
                loadDataGuardDefault,
                loadJourneyBookingGuard,
                requireJourneyStatusPaymentInProgress,
              ]}
              component={AccroparkPaymentPage}
              auth={{ authenticationRequired: true }}
            />
          )}
        />
        <Route
          path="/journey/:customerJourneyId/account-edit"
          exact={true}
          render={() => (
            <AppRoute
              routeGuards={[
                requireFeatureAuth,
                loadDataGuardDefault,
                requireJourneyStatusBookingInProgress,
              ]}
              component={AccroparkCustomerAccountJourneyEditPage}
              auth={{ authenticationRequired: true }}
            />
          )}
        />
        <Route
          path="/journeys"
          exact={true}
          render={() => (
            <AppRoute
              component={JourneysListAccroparkPage}
              auth={{ authenticationRequired: true }}
            />
          )}
        />
        <Route
          path="/company/:companyReference/contact"
          exact={true}
          render={() => (
            <AppRoute
              component={CompanyContactPage}
              routeGuards={[loadDataGuardDefault]}
            />
          )}
        />
        <Route
          path="/group/:groupReference"
          exact={true}
          render={() => (
            <AppRoute
              component={CompanyGroupHomePage}
              routeGuards={[loadDataGuardDefault]}
            />
          )}
        />
        <Route
          path="/account/create/group/:groupReference/choose"
          exact={true}
          render={() => (
            <AppRoute
              component={AccountChooseCompanyPage}
              routeGuards={[requireFeatureAuth, loadDataGuardDefault]}
            />
          )}
        />
        <Route
          path="/account/create/company/choose"
          exact={true}
          render={() => (
            <AppRoute
              component={AccountChooseCompanyPage}
              routeGuards={[requireFeatureAuth]}
            />
          )}
        />
        <Route
          path="/account/create/company/choose/:companyReference"
          exact={true}
          render={() => (
            <AppRoute
              component={AccountChooseCompanyPage}
              routeGuards={[requireFeatureAuth, loadDataGuardDefault]}
            />
          )}
        />
        <Route
          path="/account/reset/password/query"
          exact={true}
          render={() => (
            <AppRoute
              component={ResetPasswordQueryPage}
              routeGuards={[requireFeatureAuth]}
            />
          )}
        />
        <Route
          path="/account/reset/password/confirm"
          exact={true}
          render={() => (
            <AppRoute
              component={ResetPasswordConfirmPage}
              routeGuards={[requireFeatureAuth]}
              auth={{ authenticationRequired: true }}
            />
          )}
        />
        <Route
          path="/old-version"
          exact={true}
          render={() => (
            <AppRoute
              component={OldVersionPage}
              routeGuards={[testRedirectFromOldVersion]}
            />
          )}
        />
        <Route
          path="/notifications"
          render={() => (
            <AppRoute
              component={NotificationsListPage}
              auth={{ authenticationRequired: true }}
              routeGuards={[requireFeatureAuth]}
            />
          )}
          exact={true}
        />
        <Route
          path="/"
          exact={true}
          // render={() => <AppRoute component={CompanyGroupHomePage} routeGuards={[redirectToDefaultRoute]} />}
        >
          <Redirect to={`/group/${whiteLabel?.appGroupReference}`} />
        </Route>
        <Route
          path="/redirect"
          exact={true}
          render={() => (
            <AppRoute
              component={AuthPageLoginAccropark}
              routeGuards={[redirectToDefaultRoute('route /redirect')]}
            />
          )}
        />
        <Route
          path="/ddebugg"
          exact={true}
          render={() => (
            <AppRoute
              component={DebugPage}
              auth={
                HACK_DEBUG_PAGE_PUBLIC_UNTIL_NEXT_DEPLOY
                  ? undefined
                  : { superAdminRequired: true }
              }
            />
          )}
        />
      </>
    )
  );
};
