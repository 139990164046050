import { appLogger } from 'lib-web-logger';

export const refreshTokenTimeChecker = {
  checkTokenValidityDelay,
  getDelayBeforeRefreshMs,
  getRemainingTimeMs,
  getTotalValidityDurationMs,
  isExpired,
};

function checkTokenValidityDelay({
  expirationDate,
  issueDate,
  referenceDate,
  minRemainingTimeMs,
}: {
  expirationDate: Date;
  issueDate: Date;
  referenceDate?: Date;
  minRemainingTimeMs: number;
}) {
  const remainingTimeMs = getRemainingTimeMs({ expirationDate, referenceDate });

  const isValid = !isExpired({
    minRemainingTimeMs,
    remainingTimeMs,
  });

  if (isValid) {
    const totalValidityDurationMs = getTotalValidityDurationMs({
      expirationDate,
      issueDate,
    });
    const delayBeforeRefreshMs = getDelayBeforeRefreshMs({
      remainingTimeMs,
      totalValidityDurationMs,
    });
    return {
      delayBeforeRefreshMs,
      isValid,
    };
  } else {
    return {
      delayBeforeRefreshMs: -1,
      isValid,
    };
  }
}

function isExpired({
  remainingTimeMs,
  minRemainingTimeMs,
}: {
  remainingTimeMs: number;
  minRemainingTimeMs: number;
}): boolean {
  if (remainingTimeMs < minRemainingTimeMs) {
    appLogger.warn(
      `[refreshTokenTimeChecker.checkTokenRemainingTime] token expired, (remainingTimeMs=${remainingTimeMs})`
    );
    return true;
  }
  return false;
}

function getDelayBeforeRefreshMs({
  totalValidityDurationMs,
  remainingTimeMs,
}: {
  totalValidityDurationMs: number;
  remainingTimeMs: number;
}) {
  const semiValidityDurationMs = totalValidityDurationMs / 2;
  if (remainingTimeMs > semiValidityDurationMs) {
    return remainingTimeMs - semiValidityDurationMs;
  }
  return 0;
}

function getRemainingTimeMs({
  expirationDate,
  referenceDate,
}: {
  expirationDate: Date;
  referenceDate?: Date;
}) {
  const now = referenceDate ? referenceDate : new Date();
  const remainingTime = expirationDate.getTime() - now.getTime();
  return remainingTime;
}

function getTotalValidityDurationMs({
  expirationDate,
  issueDate,
}: {
  expirationDate: Date;
  issueDate: Date;
}) {
  const totalValidityTime = expirationDate.getTime() - issueDate.getTime();
  return totalValidityTime;
}
