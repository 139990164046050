import { AppCacheContextModel } from '_01_CORE/app-contexts';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import {
  CustomerJourneyActivitiesPageUrlParams,
  customerJourneyActivitiesPageUrlManager,
} from '_02_APP_COMMON/ACT-activity';
import {
  AuthMultiLoginAnimLivePageContextAction,
  AuthMultiLoginAnimLivePageContextOrigin,
} from '_04_ANIM_LIVE/AUT-auth/02-login/_model';
import {
  ActivityCategory,
  AppSettingsUniverseWhiteLabel,
  CMJourney,
  defaultJourneySelector,
} from 'lib-common-model';
import { appLogger } from 'lib-web-logger';

export const appRoutesBuilder = {
  getAccountEditAccroparkGiftCardUrl,
  getAccountEditAccroparkJourneyUrl,
  getAccountEditAnimLiveUrl,
  getAccountResetPasswordQueryUrl,
  getBookingResumePageGiftCard,
  getCompanyBookletThemeSheetUrl,
  getCompanyBookletThemeUrl,
  getCompanyBookletUrl,
  getCompanyContactUrl,
  getDebugUrl,
  getDefaultUrl,
  getGiftCardCreatePage,
  getGiftCardEditPage,
  getGroupHomeUrl,
  getJourneyCreateByActivationKeyUrl,
  getJourneyActivitiesUrl,
  getJourneyActivitiesWithParamsUrl,
  getJourneyCheckinUrl,
  getJourneyCompanyChooseUrl,
  getJourneyCreateDetailsAnimLiveUrl,
  getJourneyCreateParticipantCreateUrl,
  getJourneyCreateParticipantsEditUrl,
  getJourneyCreateParticipantsListUrl,
  getJourneyDatesEditUrl,
  getJourneyDefaultUrl,
  getJourneyEditDatesUrl,
  getJourneyActivateByKeyUrl,
  getJourneyEditExistingHomeUrl,
  getJourneyEditParticipantCreateUrl,
  getJourneyEditParticipantEditUrl,
  getJourneyEditUrl,
  getJourneyHomeUrl,
  getJourneyOptionsUrl,
  getJourneyParticipantEditUrl,
  getJourneyProUrl,
  getJourneyReportIssueUrl,
  getJourneyShoppingCartDetailsUrl,
  getJourneyShoppingCartPaymentUrl,
  getJourneyShoppingCartHistoryUrl,
  getJourneyShoppingCartLiveUrl,
  getJourneysUrl,
  getLoginHomeUrl,
  getLogoutUrl,
  getNotificationsUrl,
  getPaymentUrl,
  getRedirectToDefaultUrl,
  getCompanyRestoMenuUrl,
  getLoginMultiUrl,
  getProHomeUrl,
  getActivationTokenUrl,
};

function getGiftCardCreatePage({
  companyReference,
}: {
  companyReference: string;
}) {
  return `/company/${companyReference}/gift-card`;
}
function getGiftCardEditPage({
  bookingId,
  companyReference,
}: {
  bookingId: string;
  companyReference: string;
}) {
  return `/company/${companyReference}/booking/${bookingId}/edit-gift-card`;
}
function getBookingResumePageGiftCard({
  companyReference,
  bookingId,
}: {
  companyReference: string;
  bookingId: string;
}) {
  return `/company/${companyReference}/booking/${bookingId}/resume`;
}

function getAccountEditAccroparkGiftCardUrl({
  companyReference,
  bookingId,
}: {
  companyReference: string;
  bookingId: string;
}) {
  return `/company/${companyReference}/booking/${bookingId}/account-edit`;
}

function getRedirectToDefaultUrl() {
  return '/redirect';
}

/* NOTE: don't use directly: use getRedirectToDefaultUrl */
function getDefaultUrl({
  appCacheContext,
  whiteLabel,
}: {
  appCacheContext: AppCacheContextModel;
  whiteLabel: AppSettingsUniverseWhiteLabel;
}) {
  const {
    auth,
    companyValidReferenceCode,
    groupValidReferenceCode,
  } = appCacheContext;
  const isAuthenticated = auth?.isAuthenticated;
  let redirection: string;
  if (isAuthenticated) {
    const profile = auth?.securityUser?.profile;
    if (
      profile === 'temporary-token' &&
      auth?.securityUser?.tmpTokenInfo?.role === 'company-reset-password'
    ) {
      return '/account/reset/password/confirm';
    }

    const userType = auth?.securityUser?.profile;
    if (userType === 'customer') {
      const hasJourneys = auth?.customerProfile?.journeys?.length > 0;
      redirection = hasJourneys
        ? getJourneyDefaultUrl({
            journeys: auth?.customerProfile?.journeys,
          })
        : getJourneysUrl();
    } else if (userType === 'company') {
      // TODO go page anim
      redirection = getProHomeUrl();
    } else {
      throw new Error(`[appRoutesBuilder] Unsupported userType '${userType}'`);
    }
  } else {
    const companyReference =
      companyValidReferenceCode ?? whiteLabel.appCompanyReference;
    const groupReference =
      groupValidReferenceCode ?? whiteLabel.appGroupReference;
    if (companyReference) {
      redirection = getCompanyBookletUrl({ companyReference });
    } else if (groupReference) {
      redirection = getGroupHomeUrl({ groupReference, isAuthenticated });
    } else {
      redirection = getLoginHomeUrl();
    }
  }
  appLogger.debug('[appRoutesBuilder] Default route: ', { redirection });
  return redirection;
}

function getNotificationsUrl() {
  return '/notifications';
}

function getDebugUrl() {
  return '/ddebugg';
}

function getLoginHomeUrl({
  companyReference,
}: { companyReference?: string } = {}) {
  return `/login${
    companyReference ? `?companyReference=${companyReference}` : ''
  }`;
}

function getLoginMultiUrl({
  action,
  origin,
  companyReference,
}: {
  action: AuthMultiLoginAnimLivePageContextAction;
  origin?: AuthMultiLoginAnimLivePageContextOrigin;
  companyReference?: string;
}) {
  const urlSearch = new URLSearchParams();
  if (action?.length) {
    urlSearch.append('action', action);
  }
  if (companyReference?.length) {
    urlSearch.append('companyReference', companyReference);
  }
  if (origin?.length) {
    urlSearch.append('origin', origin);
  }
  return `/login/auth?${urlSearch.toString()}`;
}

function getActivationTokenUrl({
  companyReference,
  activationToken,
  origin,
}: {
  companyReference: string;
  activationToken: string;
  origin?: AuthMultiLoginAnimLivePageContextOrigin;
}) {
  // fonction dupliquée côté PRO: getCustomerMobileAppActivationTokenUrl
  if (!activationToken) {
    return;
  }
  const urlSearch = new URLSearchParams('forceLogout=1');

  if (companyReference?.length) {
    urlSearch.append('companyReference', companyReference);
  }
  if (activationToken?.length) {
    urlSearch.append('activationToken', activationToken);
  }
  if (origin?.length) {
    urlSearch.append('origin', origin);
  }

  return `/login/auth?${urlSearch.toString()}`;
}
function getLogoutUrl() {
  return '/logout';
}

function getCompanyContactUrl({
  companyReference,
}: {
  companyReference: string;
}) {
  return `/company/${companyReference}/contact`;
}

//Group URLs
function getGroupHomeUrl({
  groupReference,
  isAuthenticated,
}: {
  groupReference: string;
  isAuthenticated: boolean;
}) {
  // if (isAuthenticated) {
  //   // page inaccessible si authentifié, rediriger alors vers le séjour
  //   return getJourneyDefaultUrl();
  // }
  return `/group/${groupReference}`;
}

function getJourneyDefaultUrl({ journeys }: { journeys: CMJourney[] }) {
  const journey = defaultJourneySelector.findJourneyToDisplay(journeys);
  if (journey) {
    if (journey.company?.options?.welcomeBooklet) {
      return getCompanyBookletUrl({
        companyReference: journey.company.reference,
      });
    }
    return getJourneyHomeUrl({ customerJourneyId: journey._id });
  } else {
    return getJourneysUrl();
  }
}

function getJourneysUrl() {
  return `/journeys`;
}

function getJourneyHomeUrl({
  customerJourneyId,
}: {
  customerJourneyId: string;
}) {
  return `/journey/${customerJourneyId}`;
}
function getJourneyCheckinUrl({
  customerJourneyId,
}: {
  customerJourneyId: string;
}) {
  return `/journey/${customerJourneyId}/checkin`;
}
function getJourneyShoppingCartLiveUrl({
  customerJourneyId,
}: {
  customerJourneyId: string;
}) {
  return `/journey/${customerJourneyId}/shopping-cart-live`;
}
function getJourneyShoppingCartHistoryUrl({
  customerJourneyId,
}: {
  customerJourneyId: string;
}) {
  return `/journey/${customerJourneyId}/shopping-cart-history`;
}

function getJourneyShoppingCartDetailsUrl({
  customerJourneyId,
  shoppingCartId,
}: {
  customerJourneyId: string;
  shoppingCartId: string;
}) {
  return `/journey/${customerJourneyId}/shopping-cart-details/${shoppingCartId}`;
}
function getJourneyShoppingCartPaymentUrl({
  customerJourneyId,
  shoppingCartId,
}: {
  customerJourneyId: string;
  shoppingCartId: string;
}) {
  return `/journey/${customerJourneyId}/shopping-cart-payment/${shoppingCartId}`;
}
function getJourneyReportIssueUrl({
  customerJourneyId,
}: {
  customerJourneyId: string;
}) {
  return `/journey/${customerJourneyId}/report-issue`;
}
function getJourneyEditExistingHomeUrl({
  customerJourneyId,
}: {
  customerJourneyId: string;
}) {
  return `/journey/${customerJourneyId}/edit`;
}
function getJourneyProUrl({
  customerJourneyId,
}: {
  customerJourneyId: string;
}) {
  return `/journey/${customerJourneyId}/pro`;
}
function getProHomeUrl() {
  return `/pro`;
}

function getJourneyCreateParticipantCreateUrl({
  companyReference,
}: {
  companyReference: string;
}) {
  return `/journey-create/${companyReference}/participant-create?creationTimestamp=${new Date().getTime()}`;
}
function getJourneyEditParticipantCreateUrl({
  customerJourneyId,
}: {
  customerJourneyId: string;
}) {
  return `/journey-edit/${customerJourneyId}/participant-create?creationTimestamp=${new Date().getTime()}`;
}
function getJourneyEditParticipantEditUrl({
  customerJourneyId,
  customerChildId,
}: {
  customerJourneyId: string;
  customerChildId: string;
}) {
  return `/journey-edit/${customerJourneyId}/participant-edit/${customerChildId}`;
}
function getJourneyEditDatesUrl({
  customerJourneyId,
}: {
  customerJourneyId: string;
}) {
  return `/journey-edit/${customerJourneyId}/dates-edit`;
}
function getJourneyParticipantEditUrl({
  companyReference,
  participantIndex,
}: {
  participantIndex: number;
  companyReference: string;
}) {
  if (
    isNaN(participantIndex) ||
    !isFinite(participantIndex) ||
    participantIndex < 0
  ) {
    participantIndex = 0;
  }
  return `/journey-create/${companyReference}/participant-edit/${participantIndex}`;
}

function getJourneyCreateParticipantsListUrl({
  companyReference,
}: {
  companyReference: string;
}) {
  return `/journey-create/${companyReference}/participants-list`;
}

function getJourneyCreateParticipantsEditUrl({
  customerJourneyId,
}: {
  customerJourneyId: string;
}) {
  return `/journey-edit/${customerJourneyId}/participants-list`;
}

function getAccountEditAnimLiveUrl({
  companyReference,
}: {
  companyReference: string;
}) {
  return `/journey-create/${companyReference}/account-edit`;
}
function getJourneyCreateByActivationKeyUrl() {
  return `/journey-create/activation-code`;
}
function getJourneyCreateDetailsAnimLiveUrl({
  companyReference,
}: {
  companyReference: string;
}) {
  return `/journey-create/${companyReference}/details`;
}
function getAccountEditAccroparkJourneyUrl({
  customerJourneyId,
}: {
  customerJourneyId: string;
}) {
  return `/journey/${customerJourneyId}/account-edit`;
}

function getJourneyEditUrl({
  journey,
}: {
  journey: Pick<CMJourney, '_id' | 'status' | 'company'>;
}) {
  const appId = appWebConfig().appId;
  if (appId === 'anim-live') {
    if (journey.status === 'draft') {
      return getJourneyDatesEditUrl({
        companyReference: journey.company?.reference,
      });
    } else {
      return getJourneyEditExistingHomeUrl({ customerJourneyId: journey._id });
    }
  } else {
    // accropark
    if (journey.status === 'draft') {
      return getJourneyDatesEditUrl({
        companyReference: journey.company?.reference,
      });
    } else if (journey.status === 'booking-in-progress') {
      return getJourneyActivitiesUrl({
        customerJourneyId: journey._id,
        category: 'animator',
      });
    } else if (journey.status === 'payment-in-progress') {
      return getPaymentUrl({ customerJourneyId: journey._id });
    } else {
      return getAccountEditAccroparkJourneyUrl({
        customerJourneyId: journey._id,
      });
    }
  }
}
function getJourneyActivitiesUrl({
  customerJourneyId,
  category,
}: {
  customerJourneyId: string;
  category: ActivityCategory;
}) {
  const appId = appWebConfig().appId;
  if (appId === 'anim-live') {
    const searchParams = customerJourneyActivitiesPageUrlManager.buildSearchParams(
      {
        category,
      }
    );
    return `/anim-live/journey/${customerJourneyId}/activities${
      searchParams?.length ? `?${searchParams.join('&')}` : ''
    }`;
  } else {
    return `/accropark/journey/${customerJourneyId}/activities`;
  }
}
function getJourneyActivateByKeyUrl({
  companyReference,
  extCustomerJourneyBookingGroupId,
  extCustomerAccountActivationEmail,
}: {
  companyReference: string;
  extCustomerJourneyBookingGroupId: string;
  extCustomerAccountActivationEmail: string;
}) {
  return `/company/${companyReference}/journey-activation-key?extCustomerJourneyBookingGroupId=${extCustomerJourneyBookingGroupId}&extCustomerAccountActivationEmail=${extCustomerAccountActivationEmail}`;
}
function getJourneyActivitiesWithParamsUrl({
  customerJourneyId,
  params,
}: {
  customerJourneyId: string;
  params: CustomerJourneyActivitiesPageUrlParams;
}) {
  const appId = appWebConfig().appId;
  if (appId === 'anim-live') {
    const searchParams: string[] = customerJourneyActivitiesPageUrlManager.buildSearchParams(
      params
    );
    return `/anim-live/journey/${customerJourneyId}/activities${
      searchParams?.length ? `?${searchParams.join('&')}` : ''
    }`;
  } else {
    return `/accropark/journey/${customerJourneyId}/activities`;
  }
}
function getJourneyOptionsUrl({
  customerJourneyId,
}: {
  customerJourneyId: string;
}) {
  return `/journey/${customerJourneyId}/options`;
}
function getPaymentUrl({ customerJourneyId }: { customerJourneyId: string }) {
  return `/journey/${customerJourneyId}/payment`;
}

function getCompanyBookletUrl({
  companyReference,
}: {
  companyReference: string;
}) {
  return `/company/${companyReference}/booklet`;
}

function getCompanyBookletThemeUrl({
  companyReference,
  bookletThemeReference,
}: {
  companyReference: string;
  bookletThemeReference: string;
}) {
  return `/company/${companyReference}/booklet/theme/${bookletThemeReference}`;
}

function getCompanyBookletThemeSheetUrl({
  companyReference,
  bookletThemeReference,
  bookletSheetReference,
}: {
  companyReference: string;
  bookletThemeReference: string;
  bookletSheetReference: string;
}) {
  return `/company/${companyReference}/booklet/theme/${bookletThemeReference}/sheet/${bookletSheetReference}`;
}

function getCompanyRestoMenuUrl({
  companyReference,
  menuId,
  menuSectionId,
  menuDishId,
}: {
  companyReference: string;
  menuId?: string;
  menuSectionId?: string;
  menuDishId?: string;
}) {
  const baseUrl = `/company/${companyReference}/menu`;

  const url = new URL(baseUrl, window.location.href);

  // create a map of optional parameters
  const optionalParams: { [key: string]: string | undefined } = {
    menuId,
    menuSectionId,
    menuDishId,
  };

  // iterate through optional parameters and add them to the URL if provided
  for (const paramName in optionalParams) {
    const paramValue = optionalParams[paramName];
    if (paramValue !== undefined) {
      url.searchParams.set(paramName, paramValue);
    }
  }

  return url.toString();
}

function getJourneyDatesEditUrl({
  companyReference,
}: {
  companyReference: string;
}) {
  return `/account/create/${companyReference}`;
}

function getJourneyCompanyChooseUrl({
  companyReference,
  whiteLabel,
}: {
  companyReference?: string;
  whiteLabel: AppSettingsUniverseWhiteLabel;
}) {
  if (!companyReference && whiteLabel?.appGroupReference) {
    return `/account/create/group/${whiteLabel?.appGroupReference}/choose`;
  }
  return `/account/create/company/choose${
    companyReference ? `/${companyReference}` : ''
  }`;
}

function getAccountResetPasswordQueryUrl({ login = '' }: { login: string }) {
  return '/account/reset/password/query?login=' + encodeURIComponent(login);
}
