import { IonContent } from '@ionic/react';
import React, { CSSProperties } from 'react';

export type Spacing =
  | 'none'
  | 'normal'
  | 'large'
  | 'normal-no-border'
  | 'large-no-border';

export const PageContainer: React.FC<{
  className?: string;
  spacing?: Spacing;
  style?: CSSProperties;
}> = ({ style, children, className, spacing = 'normal-no-border' }) => {
  const spacings: Record<Spacing, string> = {
    none: 'p-0 ',
    normal: 'p-4 px-4 sm:px-6 md:px-8 ',
    large: 'pt-8 pr-4 pb-4 pl-4 px-4 sm:px-6 md:px-8 ',
    'normal-no-border': 'px-4 py-4 sm:px-6 md:px-8 ',
    'large-no-border': 'px-4 pt-8 pb-4 sm:px-6 md:px-8 ',
  };
  const innerClassNames = [spacings[spacing]].filter((x) => x).join(' ');
  return (
    <IonContent className="bg-gray-100 min-h-fit" fullscreen style={style}>
      <div
        id="app-scrollable-container"
        className="bg-gray-100 h-full w-full overflow-scroll"
      >
        <main
          style={{ minHeight: '300px' }}
          className={`min-h-full w-full max-w-screen-sm mx-auto 
          ${innerClassNames} ${className}`}
        >
          {children}
        </main>
      </div>
    </IonContent>
  );
};
