import { IonToast } from '@ionic/react';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

type Toast = { color?: string; duration?: number; message: string };

type ToastsContext = (toast: Toast) => void;
// eslint-disable-next-line @typescript-eslint/no-empty-function
const ToastsContext = createContext<ToastsContext>(() => {});

export const ToastsProvider: React.FC = ({ children }) => {
  const [isOpened, setIsOpened] = useState(false);
  const [toastsQueue, setToastsQueue] = useState<Toast[]>([]);
  const push = useCallback((t: Toast) => setToastsQueue([...toastsQueue, t]), [
    toastsQueue,
  ]);
  const handleToastDismiss = useCallback(() => {
    setIsOpened(false);
    const queue = [...toastsQueue];
    queue.shift();
    setToastsQueue(queue);
  }, [toastsQueue]);
  useEffect(() => {
    if (toastsQueue.length) setIsOpened(true);
  }, [toastsQueue]);
  return (
    <ToastsContext.Provider value={push}>
      <IonToast
        color={(toastsQueue.length && toastsQueue[0]?.color) || 'primary'}
        duration={(toastsQueue.length && toastsQueue[0]?.duration) || 2500}
        isOpen={isOpened}
        message={(toastsQueue.length && toastsQueue[0]?.message) || ''}
        onDidDismiss={handleToastDismiss}
      />
      {children}
    </ToastsContext.Provider>
  );
};

export function useToasts() {
  return useContext(ToastsContext);
}
