import { Language, LanguageCode } from 'lib-common-model';
import { useMemo } from 'react';
import { AppSelect } from './AppSelect';
import { AppSelectProps } from './AppSelectProps.type';

export const AppSelectLanguage = ({
  languages,
  ...appSelectProps
}: { languages: Language[] } & Omit<
  AppSelectProps<LanguageCode>,
  'elements'
>) => {
  // const { formatMessage: t } = useIntl();
  const languagesSelectOptions = useMemo(
    () =>
      languages.map((c) => ({
        id: c.id,
        label: (
          <div className="flex items-center">
            <img
              className="flex-initial w-6 h-4 mr-3"
              src={c.imgUrl.startsWith('/') ? c.imgUrl : `/${c.imgUrl}`}
            />
            <span className="flex-initial">{c.labelOriginal}</span>
          </div>
        ),
      })),
    [languages]
  );

  return <AppSelect {...appSelectProps} elements={languagesSelectOptions} />;
};
