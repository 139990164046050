import { Market } from '@ionic-native/market';
import { IonButton, IonIcon } from '@ionic/react';
import { logoAppleAppstore, logoGooglePlaystore } from 'ionicons/icons';
import React from 'react';
import { useIntl } from 'react-intl';
import { useAppPlatform } from '_01_CORE/app-core/app-platform';
import { useAppSettings } from '_01_CORE/app-core/app-security';
import { AppPage, HeaderToolbar, PageContainer } from '_01_CORE/_common-layout';

export const OldVersionPage: React.FC = () => {
  const { formatMessage: t } = useIntl();
  const { platform } = useAppPlatform();

  const appSettings = useAppSettings();
  const whiteLabel = appSettings?.universe?.whiteLabel;
  const identity = appSettings?.universe?.identity;

  const handleOpenMarket = () => Market.open(identity.appMarketId);
  const Icon = platform === 'android' ? logoGooglePlaystore : logoAppleAppstore;
  const storeName = platform === 'android' ? 'Google Play' : 'App Store';
  return (
    <AppPage>
      <HeaderToolbar />
      <PageContainer className="text-center bg-gray-50" spacing="normal">
        <div className="text-lg font-bold text-center mb-2">
          {t({ id: 'page19.headerTitle' })}
        </div>
        <div className="text-sm">
          <p>{t({ id: 'page19.disclaimer' })}</p>
        </div>
        <IonButton
          className="mt-10"
          color="secondary"
          onClick={handleOpenMarket}
          shape="round"
        >
          <IonIcon className="mr-2" icon={Icon} />
          {t({ id: 'page19.button' })} {storeName}
        </IonButton>
      </PageContainer>
    </AppPage>
  );
};
