import {
  COUNTRIES_ARRAY,
  Country,
  Gender,
  Language,
  LANGUAGES_ARRAY,
} from 'lib-common-model';
import { dataGenerator } from './dataGenerator.service';

export const fakeDataGenerator = {
  getFirstName,
  getLastName,
  getBirthDate,
  getCountry,
  getGender,
  getPhone,
  getLanguage,
  getEmail,
};
function getFirstName(): string {
  return dataGenerator.fromList(['Peter', 'Sarah', 'Joe', 'Mica', 'Sophie']);
}
function getLastName(): string {
  return dataGenerator.fromList(['Smith', 'Thomson']);
}
function getEmail({
  firstName,
  lastName,
}: {
  firstName: string;
  lastName: string;
}): string {
  return `${firstName}.${lastName}.${dataGenerator.number({
    min: 100,
    max: 999,
  })}@${dataGenerator.fromList([
    'gmail',
    'hotmail',
    'yahoo',
  ])}.com`.toLowerCase();
}
function getBirthDate(
  { minYears = 6, maxYears = 60 }: { minYears?: number; maxYears?: number } = {
    minYears: 6,
    maxYears: 60,
  }
): Date {
  return dataGenerator.date({
    years: {
      min: -minYears,
      max: -maxYears,
    },
  });
}
function getGender(): Gender {
  return dataGenerator.fromList(['male', 'female']);
}
function getCountry(): Country {
  return dataGenerator.fromList(COUNTRIES_ARRAY);
}
function getLanguage(): Language {
  return dataGenerator.fromList(LANGUAGES_ARRAY);
}
function getPhone(): string {
  return '06 00 00 00 00';
}
