import { ResponsiveImage } from 'lib-common-model';
import React, { useMemo } from 'react';
import { imageUrlBuilder } from '_01_CORE/app-core/app-api';

export const CompanyImage = ({ image }: { image: ResponsiveImage }) => {
  const imgSrc = useMemo(() => {
    if (image) {
      return imageUrlBuilder.getImageCompanyFullUrl({ image: image });
    }
    // default image
    return '/assets/company/company-journey.jpg';
  }, [image]);
  return <img className="mx-auto" src={imgSrc} />;
};
