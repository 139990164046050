import { IonButton, IonIcon, IonItemGroup, IonRow } from '@ionic/react';
import {
  AppPage,
  HeaderToolbar,
  PageContainer,
  PageTitle,
} from '_01_CORE/_common-layout';
import { AppIcons, JourneyCard } from '_01_CORE/_components-core';
import { useAppRouter } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import {
  useAppCacheContext,
  useJourneyEnabledActions,
} from '_01_CORE/app-contexts';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import { useNavigateToNewJourneyPage } from '_02_APP_COMMON/JOU-journey/_common';
import { add, checkmark } from 'ionicons/icons';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

export const JourneysListAccroparkPage = () => {
  const appRouter = useAppRouter();
  const appId = appWebConfig().appId;
  const { formatMessage: t } = useIntl();
  const { auth, companyValidReferenceCode } = useAppCacheContext();
  const userProfile = useMemo(() => auth?.customerProfile, [
    auth?.customerProfile,
  ]);
  const journeys = useMemo(() => {
    if (!userProfile?.journeys) {
      return [];
    }
    return userProfile.journeys.sort((a, b) =>
      a.beginDateUTC > b.beginDateUTC
        ? -1
        : a.beginDateUTC < b.beginDateUTC
        ? 1
        : 0
    );
  }, [userProfile?.journeys]);
  const { isCreateJourneyEnabled } = useJourneyEnabledActions({});

  const showCreateJourneyButton = useMemo(() => {
    // allow to create journey if no journey is currently in draft
    return (
      isCreateJourneyEnabled &&
      journeys.find((j) => j.status !== 'validated') === undefined
    );
  }, [isCreateJourneyEnabled, journeys]);

  const giftCardCompanyReference = useMemo(() => {
    if (companyValidReferenceCode) {
      return companyValidReferenceCode;
    }
    if (journeys.length) {
      return journeys[0].company?.reference;
    }
    return 'ACC426'; // LIDA par défaut
  }, [companyValidReferenceCode, journeys]);

  const showCreateGiftCardButton = useMemo(() => {
    // allow to create journey if no journey is currently in draft
    return appId === 'accropark' && giftCardCompanyReference;
  }, [appId, giftCardCompanyReference]);

  const navigateToNewJourneyPage = useNavigateToNewJourneyPage({
    context: 'JourneysListAccroparkPage',
  });

  /**
   * Get button icon which is different for Anim Live & Accropark
   */
  function getIcon() {
    return appId === 'anim-live' ? add : checkmark;
  }

  return (
    <AppPage tabId="bookings">
      <HeaderToolbar />
      <PageContainer className="text-center bg-gray-50" spacing="normal">
        <PageTitle
          title={t({ id: `page04.goToWebApp.${appId}` })}
          backLink={appRoutesBuilder.getRedirectToDefaultUrl()}
        />
        <div className="inline-flex flex-col space-y-4 w-full max-w-md">
          {journeys.length > 0 ? (
            <IonItemGroup>
              {journeys.reverse().map((j, i) => (
                <JourneyCard
                  showActionOpenJourney={true}
                  showActionEditJourney={true}
                  showActionDeleteJourney={true}
                  key={i}
                  journey={j}
                  redirectOnDelete={false}
                />
              ))}
            </IonItemGroup>
          ) : (
            <p>{t({ id: `page04.noJourneys.${appId}` })}</p>
          )}
          {showCreateJourneyButton && (
            <IonItemGroup>
              <IonRow className="ion-justify-content-center">
                <IonButton
                  className="my-5 w-full max-w-xs"
                  onClick={navigateToNewJourneyPage}
                  shape="round"
                >
                  <IonIcon className="mr-2" icon={getIcon()} />
                  {t({ id: `page04.button.add-journey.${appId}` })}
                </IonButton>
              </IonRow>
            </IonItemGroup>
          )}
          {showCreateGiftCardButton && (
            <IonItemGroup>
              <IonRow className="ion-justify-content-center">
                <IonButton
                  className="my-5 w-full max-w-xs"
                  onClick={() => {
                    appRouter.navigate(
                      appRoutesBuilder.getGiftCardCreatePage({
                        companyReference: giftCardCompanyReference,
                      }),
                      {
                        cause: 'click-create-gift-card',
                      }
                    );
                  }}
                  shape="round"
                >
                  <AppIcons.giftCard className="w-6 h-6 mr-2" />
                  {t({
                    id: `accropark.ecommerce.booking-card.offer.gift-card`,
                  })}
                </IonButton>
              </IonRow>
            </IonItemGroup>
          )}
        </div>
        {auth?.customerProfile?.account?.deletionRequestedAt && (
          <div className="my-5 font-bold text-red-500">
            {t({ id: 'menu.delete-account.success' })}
          </div>
        )}
      </PageContainer>
    </AppPage>
  );
};
