import { appLogger } from 'lib-web-logger';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { scroller } from 'react-scroll';

export function useAppRouter() {
  const history = useHistory();

  const navigate = useCallback(
    (path: string, { cause }: { cause: string }) => {
      appLogger.info(
        `[useAppRouter] navigate: ${path}${cause ? ` (cause: ${cause})` : ''}`
      );
      history?.push(path);
    },
    [history]
  );

  const navigateAfterDelay = useCallback(
    (path: string, options: { cause: string }) => {
      setTimeout(() => navigate(path, options), 1000);
    },
    [navigate]
  );

  const goBack = useCallback(
    ({ cause }: { cause?: string } = {}) => {
      appLogger.info(
        `[useAppRouter] navigate backward${cause ? ` (cause: ${cause})` : ''}`
      );
      history.goBack();
    },
    [history]
  );

  const scrollableContainerId = 'app-scrollable-container';
  const scrollToTop = useCallback(() => {
    window.document.getElementById(scrollableContainerId)?.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
  }, []);

  const scrollTo = useCallback((id: string, options?: { offset: number }) => {
    scroller.scrollTo(id, {
      duration: 800,
      delay: 20,
      smooth: 'easeInOutQuint',
      containerId: scrollableContainerId,
      ...(options ?? {}),
    });
  }, []);

  return useMemo(
    () => ({
      navigate,
      navigateAfterDelay,
      goBack,
      scrollToTop,
      scrollTo,
    }),
    [goBack, navigate, navigateAfterDelay, scrollTo, scrollToTop]
  );
}
