import { AppPriceLabel } from '_01_CORE/_common-layout';
import {
  AppButtonCompany,
  AppTranslationContentPanel,
} from '_01_CORE/_components-core';
import { AppDialogV2 } from '_01_CORE/_components-core/dialog/AppDialogV2';
import clsx from 'clsx';
import { AppCurrencyIsoCode } from 'lib-common-model';
import { CMRestoMenuDish } from 'lib-common-model/entities/app-customer-mobile-web/CMResto';

export const CompanyRestoMenuDishDetailsDialog = ({
  companyReference,
  dish,
  onClose,
  className,
}: {
  companyReference: string;
  dish: CMRestoMenuDish;
  onClose?: () => void;
  className?: string;
}) => {
  const appCurrencyCode: AppCurrencyIsoCode = 'EUR';
  return (
    <AppDialogV2
      className={clsx('overflow-y-auto  w-full sm:max-w-screen-sm', className)}
      isOpen={true}
      onClose={() => {
        onClose();
      }}
    >
      <div
        className={clsx(
          // 'h-full flex-grow s app-card bg-white ',
          'app-p-content flex flex-col gap-4 items-stretch'
        )}
        // onClick={() => onClose()}
      >
        <div className="">
          <div className="text-left flex flex-1 flex-col space-y-2 p-4">
            <div className="flex gap-4 justify-between">
              <h3 className="text-lg sm:text-xl font-bold text-gray-600">
                <span aria-hidden="true" className="absolute inset-0" />
                {dish.translation.name}
              </h3>
              <AppPriceLabel
                className="text-right text-base font-medium text-gray-500"
                amount={dish.basePrice?.unitPrice}
                appCurrencyCode={appCurrencyCode}
              />
            </div>
            {dish.translation.comment?.trim()?.length > 0 && (
              <p className="text-sm sm:text-base font-bold text-gray-500">
                {dish.translation.comment}
              </p>
            )}
          </div>
          <div className={clsx('border-l border-gray-100 bg-gray-50')}>
            <div className="bg-gray-50 sm:aspect-none">
              {dish.image?.original && (
                <img
                  src={dish.image?.original}
                  // alt={product.imageAlt}
                  className={clsx(
                    'h-full max-h-80 w-full bg-gray-50 object-cover object-center rounded-lg'
                  )}
                />
              )}
            </div>
          </div>
          {dish.translation.description?.length > 0 && (
            <div className="border border-gray-200 bg-gray-50 py-2 px-2 text-xs">
              {dish.translation.description.map((item, i) => (
                <AppTranslationContentPanel
                  companyReference={companyReference}
                  key={i}
                  item={item}
                />
              ))}
            </div>
          )}
        </div>
        <div className="sticky bottom-0 bg-white border-t border-gray-300 py-4 flex gap-8 justify-around">
          <AppButtonCompany
            type="button"
            onClick={() => {
              onClose();
            }}
            style={'cancel'}
          >
            Fermer
          </AppButtonCompany>
          <AppButtonCompany
            type="button"
            onClick={() => {
              onClose();
            }}
            style={'primary'}
          >
            Commander
          </AppButtonCompany>
        </div>
      </div>
    </AppDialogV2>
  );
};
