import { IonBadge } from '@ionic/react';
import React, { Fragment } from 'react';
import { useNetworkStatus } from '_01_CORE/app-core/app-platform';

export const NetworkStatus: React.FC<{
  showLabel: boolean;
}> = ({ showLabel }) => {
  const { networkStatus } = useNetworkStatus();
  return networkStatus && !networkStatus?.connected ? (
    <IonBadge
      className="absolute right-4 top-5 py-1 px-3 z-50"
      color={networkStatus?.connected ? 'success' : 'danger'}
    >
      {showLabel ? <Fragment>Pas de connection Internet</Fragment> : null}
    </IonBadge>
  ) : null;
};
