import { useAppSettings } from '_01_CORE/app-core/app-security';
import clsx from 'clsx';
import { AppSettingsUniverseTheme } from 'lib-common-model';
import { FC, useMemo } from 'react';
import {
  AppButtonFreeColor,
  AppButtonFreeColorProps,
} from './AppButtonFreeColor';

export type AppButtonUniverseStyle =
  | 'light'
  | 'cancel'
  | 'primary'
  | 'secondary'
  | 'danger'
  | 'bottom-menu';

export type AppButtonUniverseProps = Omit<
  AppButtonFreeColorProps,
  'color' | 'children' | 'style'
> & {
  children?: React.ReactNode | React.ReactNode[];
  size?: 'small' | 'normal' | 'large';
  label?: string;
  label2?: string;
  isFullWidth?: boolean;
  style: AppButtonUniverseStyle;
  fontWeightClassName?: string;
  roundedClassName?: string;
  className?: string;
};

export const AppButtonUniverse: FC<AppButtonUniverseProps> = ({
  children,
  label,
  size = 'normal',
  fontWeightClassName,
  roundedClassName,
  label2,
  isFullWidth,
  displayMode,
  style = 'primary',
  className,
  ...props
}) => {
  const appSettings = useAppSettings();
  const theme: AppSettingsUniverseTheme = appSettings?.universe?.theme;

  const { color, textColor, borderClassName } = useMemo(
    () => getColorsFromTheme({ style, theme }),
    [style, theme]
  );

  const buttonStyleClass = useMemo(() => {
    if (displayMode === 'tab') {
      const classes = 'text-xs';
      return classes;
    } else {
      const classes =
        size === 'large'
          ? 'text-base sm:text-lg px-4 sm:px-8 py-4'
          : size === 'normal'
          ? 'text-sm px-4 py-2'
          : 'text-xs px-2 py-1';
      return classes;
    }
  }, [displayMode, size]);

  const iconStyleClass = useMemo(() => {
    if (displayMode === 'tab') {
      const classes = 'h-5 w-5';
      return classes;
    } else {
      return size === 'large'
        ? 'ml-2 h-14 w-14 text-gray-800 rounded bg-white p-2'
        : size === 'normal'
        ? 'ml-2 h-5 w-5'
        : 'mr-1 h-3 w-3';
    }
  }, [displayMode, size]);

  const iconOpositePadding =
    displayMode === 'tab'
      ? ''
      : size === 'large'
      ? 'pr-9'
      : size === 'normal'
      ? 'pr-7'
      : 'pr-2';

  return (
    <AppButtonFreeColor
      displayMode={displayMode}
      color={color}
      textColor={textColor}
      borderClassName={borderClassName}
      fontWeightClassName={fontWeightClassName}
      roundedClassName={
        displayMode === 'tab' ? 'rounded-none' : roundedClassName
      }
      // buttonStyle="primary"
      className={clsx(
        `flex-1 text-center relative`,
        !isFullWidth && 'w-full max-w-xs',
        buttonStyleClass,
        className,
        iconOpositePadding
      )}
      iconStyleClass={iconStyleClass}
      {...props}
    >
      {children && children}
      {label && <div className={label2 ? '-mt-4' : ''}>{label}</div>}
      {label2 && (
        <div className="h-0 -mt-1 text-center text-app-xxs1">{label2}</div>
      )}
    </AppButtonFreeColor>
  );
};
function getColorsFromTheme({
  style,
  theme,
}: {
  style: AppButtonUniverseStyle;
  theme: AppSettingsUniverseTheme;
}): {
  color: string;
  textColor: string;
  borderClassName?: string;
} {
  switch (style) {
    case 'light': {
      return {
        color: '#fff',
        textColor: '#666',
      };
    }
    case 'primary': {
      return {
        color: theme?.buttonPrimaryBgColor,
        textColor: theme?.buttonPrimaryTextColor,
      };
    }
    case 'secondary': {
      return {
        color: theme?.buttonSecondaryBgColor,
        textColor: theme?.buttonSecondaryTextColor,
      };
    }
    case 'cancel': {
      return {
        color: theme?.buttonCancelBgColor,
        textColor: theme?.buttonCancelTextColor,
      };
    }
    case 'danger': {
      return {
        color: theme?.buttonDangerBgColor,
        textColor: theme?.buttonDangerTextColor,
      };
    }
    case 'bottom-menu': {
      return {
        color: theme?.buttonBottomMenuBgColor,
        textColor: theme?.buttonBottomMenuTextColor,
        borderClassName: 'border-0',
      };
    }
  }
}
