import { appLogger } from 'lib-web-logger';
import { useLoadable } from 'lib-web-redux';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import { authenticationStore } from '_01_CORE/app-core/app-security';
import { AppCacheContextModel } from '../model';
import { appCacheContextReducer, APP_CONTEXT_INITIAL_STATE } from '../reducer';
import { AppCacheContextReducerAction } from '../reducer/AppCacheContextReducerAction.type';

const AppCacheContext = createContext<AppCacheContextModel>(
  APP_CONTEXT_INITIAL_STATE
);

export const AppCacheContextProvider: React.FC = ({ children }) => {
  const [appCacheContext, dispatch] = useReducer(
    appCacheContextReducer,
    APP_CONTEXT_INITIAL_STATE
  );

  const appAuth = useLoadable({
    debugName: 'useAppRoute',
    initialValueFromLoadSnapshot: true,
    load: () => {
      const appAuth$ = authenticationStore.auth.get();
      return appAuth$;
    },
  });

  useEffect(() => {
    if (appAuth?._loaded) {
      appLogger.info('[AppCacheContextProvider] update user profile');
      dispatch({ type: 'set-auth', auth: appAuth });
    }
  }, [appAuth, appAuth?._loaded, appAuth.customerProfile]);

  const dispatchCacheContextAction = useCallback(
    (action: AppCacheContextReducerAction) => {
      appLogger.info(
        `[AppCacheContextProvider] dispatch action ${action?.type}`
      );
      dispatch(action);
    },
    []
  );

  const appContextModel: AppCacheContextModel = {
    ...appCacheContext,
    dispatchCacheContextAction,
  };

  return (
    <AppCacheContext.Provider value={appContextModel}>
      {children}
    </AppCacheContext.Provider>
  );
};

export function useAppCacheContext(): AppCacheContextModel {
  const context = useContext(AppCacheContext);
  if (context) {
    return context;
  }
  // sometimes, on development reload, the app crash because context is null (not sure if it appends in production too)
  const initialValue: AppCacheContextModel = {
    ...APP_CONTEXT_INITIAL_STATE,
    dispatchCacheContextAction: () => {
      // nothing to do
    },
  };
  return initialValue;
}
