import { CMJourney, ISSUE_CATEGORIES, ResponsiveImage } from 'lib-common-model';
import { useCallback, useMemo, useState } from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useAppTheme } from '_01_CORE/_common-layout';
import {
  AppButtonCompany,
  AppButtonV2,
  AppFormControlRHF,
  AppFormLabel,
  AppIcons,
  AppInput,
  AppInputBooleanSwitch,
  AppTextarea,
} from '_01_CORE/_components-core';
import { AppInputPhoto } from '_01_CORE/_components-core/AppInputPhoto';
import { AppInputPhotoRHFControl } from '_01_CORE/_components-core/AppInputPhoto/AppInputPhotoRHFControl';
import { CompanyIssueReportFormModel } from './model';

export const CompanyIssueReportForm = ({
  journey,
  contactPhoneNumber,
  customerJourneySiteReference,
  className,
  onCancel,
  onConfirm,
}: {
  journey: CMJourney;
  contactPhoneNumber: string;
  customerJourneySiteReference: string;
  className?: string;
  onCancel: () => any;
  onConfirm: (formValue: CompanyIssueReportFormModel) => any;
}) => {
  const { formatMessage: t } = useIntl();

  const theme = useAppTheme({ companyReference: journey?.company?.reference });

  const form = useForm<CompanyIssueReportFormModel>({
    defaultValues: {
      description: '',
      contactPhoneNumber: contactPhoneNumber ?? '',
      customerJourneySiteReference: customerJourneySiteReference ?? '',
      issueLocation: '',
      photos: [],
    },
    mode: 'onChange',
  });

  const { control, register, formState, handleSubmit, setValue } = form;

  const [step, setStep] = useState<'category1' | 'category2' | 'description'>(
    'category1'
  );
  const [category1, category2] = useWatch({
    control,
    name: ['category1', 'category2'],
  });

  const formPhotos = useFieldArray({
    control,
    name: 'photos',
  });
  const { fields, append, prepend, remove, swap, move, insert } = formPhotos;

  const selectedCategory = useMemo(
    () => ISSUE_CATEGORIES.find((x) => x.id === category1),
    [category1]
  );
  const selectedCategory2 = useMemo(
    () => (selectedCategory?.children ?? []).find((x) => x.id === category2),
    [category2, selectedCategory?.children]
  );
  const submitForm = useCallback(async () => {
    handleSubmit(async (formValue: CompanyIssueReportFormModel) => {
      // valid form
      onConfirm(formValue);
    })();
  }, [handleSubmit, onConfirm]);

  return (
    <div className={className}>
      <div className="relative  h-8">
        <AppIcons.actionBack
          className="absolute left-0 top-0 w-8 h-8 rounded-full bg-gray-400 text-white cursor-pointer"
          onClick={() => {
            switch (step) {
              case 'description': {
                if (selectedCategory.children.length) {
                  setStep('category2');
                } else {
                  setStep('category1');
                }
                break;
              }
              case 'category2': {
                setStep('category1');
                setValue('category2', null);
                break;
              }
              default:
                onCancel();
                break;
            }
          }}
        />
        <h2 className="my-3 text-center font-bold text-lg sm:text-xl text-app-danger uppercase">
          Signaler un problème
        </h2>
      </div>
      {step === 'category1' && (
        <div className="mt-4 lg:mt-8 flex flex-col gap-6">
          {ISSUE_CATEGORIES.map((c) => (
            <AppButtonCompany
              key={c.id}
              style={'primary'}
              theme={theme}
              isFullWidth={true}
              className={`uppercase font-bold`}
              roundedClassName="rounded"
              size="large"
              label={c.label}
              label2={c.label2}
              icon={c.icon}
              onClick={() => {
                setValue('category1', c.id);
                if (c.children.length) {
                  setStep('category2');
                } else {
                  setStep('description');
                }
              }}
            />
          ))}
        </div>
      )}
      {step === 'category2' && (
        <div className="mt-4 lg:mt-8 flex flex-col gap-6">
          {selectedCategory.children.map((c) => (
            <AppButtonCompany
              key={c.id}
              style={'primary'}
              theme={theme}
              isFullWidth={true}
              className={`uppercase font-bold`}
              roundedClassName="rounded"
              size="large"
              label={c.label}
              label2={c.label2}
              icon={c.icon}
              onClick={() => {
                setValue('category2', c.id);
                setStep('description');
              }}
            />
          ))}
        </div>
      )}
      {selectedCategory && !selectedCategory.isOther && (
        <h3 className="my-2 text-center font-bold text-sm sm:text-base text-gray-600 uppercase">
          {selectedCategory.label}
        </h3>
      )}
      {selectedCategory2 && !selectedCategory2.isOther && (
        <h3 className="my-2 text-center font-bold text-sm sm:text-base text-gray-600 uppercase">
          {selectedCategory2.label}
        </h3>
      )}
      {step === 'description' && (
        <div className="mt-4 flex-col gap-4">
          <AppFormControlRHF
            label="N° location / emplacement"
            control={control}
            name={'customerJourneySiteReference'}
            required={true}
            renderComponent={(props) => (
              <AppInput
                {...props}
                className="my-3 w-full text-xl font-bold"
                icon={AppIcons.location}
                iconClasses="absolute top-1 left-1 text-gray-400 w-7 h-7"
                // autoComplete="off"
              />
            )}
          />
          <AppFormControlRHF
            label="Lieu du problème (salon, cuisine...)"
            control={control}
            name={'issueLocation'}
            required={false}
            // disabled={!!campingPlaceNumber}
            // validation={{
            //   rules: {
            //     maxLength: 30,
            //   },
            // }}
            renderComponent={(props) => (
              <AppInput
                {...props}
                className="my-3 w-full text-base font-bold"
                // icon={AppIcons.location}
                iconClasses="absolute top-1 left-1 text-gray-400 w-7 h-7"
                // autoComplete="off"
              />
            )}
          />
          <AppFormControlRHF
            label="Description"
            control={control}
            name={'description'}
            required={true}
            // disabled={!!campingPlaceNumber}
            // validation={{
            //   rules: {
            //     maxLength: 30,
            //   },
            // }}
            renderComponent={(props) => (
              <AppTextarea
                {...props}
                className="my-3 p-1 w-full text-base"
                autoComplete="off"
                rows={5}
              />
            )}
          />
          <AppFormControlRHF
            label="Contact téléphonique"
            control={control}
            name={'contactPhoneNumber'}
            required={true}
            renderComponent={(props) => (
              <AppInput
                {...props}
                className="my-3 w-full text-base font-bold"
                // icon={AppIcons.location}
                iconClasses="absolute top-1 left-1 text-gray-400 w-7 h-7"
                // autoComplete="off"
              />
            )}
          />
          <AppFormControlRHF
            label="Autorisation d'accès en mon abscence"
            control={control}
            name={'allowAccessIfCustomerAway'}
            required={false}
            renderComponent={(props) => (
              <div className="flex gap-1">
                <AppInputBooleanSwitch
                  {...props}
                  label={`J'autorise le personnel à accéder au logement en mon absence`}
                  labelStyle="small"
                />
                {/* <div className="text-justify text-xs text-gray-600">
                  {`J'autorise le personnel à accéder au logement en mon absence
                  pour résoudre ce problème.`}
                </div> */}
              </div>
            )}
          />
          <AppFormLabel
            required={false}
            className={'my-4 form-label pl-1'}
            label={`${t({ id: 'common.form.photos' })}${
              fields.length > 0 ? ` (${fields.length})` : ''
            }`}
            hasError={false}
            disabled={false}
          >
            <AppInputPhoto
              // label={t({ id: 'common.form.photo' })}
              url={`/customer-web/company-issues/images`}
              className="my-2"
              onChange={(value: ResponsiveImage) => {
                if (value) {
                  // add photo to form
                  append(value, {
                    shouldFocus: true,
                  });
                }
              }}
            />
            <div className={`flex flex-wrap gap-x-2`}>
              {fields.map((p, index) => (
                <AppInputPhotoRHFControl
                  key={p.original}
                  control={control}
                  // label={t({ id: 'common.form.photo' })}
                  name={`photos.${index}`}
                  preview={{ size: 'large' }}
                  url={`/customer-web/company-issues/images`}
                  onChange={(value) => {
                    if (!value) {
                      // remove photo from form
                      remove(index);
                    }
                  }}
                />
              ))}
            </div>
          </AppFormLabel>
          <div className="my-4 flex flex-col-reverse ml:flex-row gap-y-6 gap-x-4">
            <AppButtonV2
              className={`w-full font-bold rounded-full`}
              // size="small"
              buttonStyle="cancel"
              icon={AppIcons.actionCancel}
              onClick={() => onCancel()}
            >
              {t({ id: 'common.action.cancel' })}
            </AppButtonV2>
            <AppButtonV2
              className={`w-full font-bold rounded-full`}
              // size="small"
              buttonStyle="success"
              icon={AppIcons.check}
              onClick={() => submitForm()}
            >
              {t({ id: 'common.action.submit' })}
            </AppButtonV2>
          </div>
        </div>
      )}
    </div>
  );
};
