import { CMCompanyResume } from 'lib-common-model';
import React from 'react';
import { useAppImageUrl } from '_01_CORE/app-core/app-security/useAppImageUrl.hook';
import { AppButtonUniverse } from '_01_CORE/_components-core';

export const AccountCreationTitle: React.FC<{
  company: CMCompanyResume;
  displayAddress?: boolean;
  displayButtonChooseDestination?: boolean;
  buttonText?: string;
  onClick?: () => void;
}> = ({
  company,
  displayAddress,
  buttonText,
  onClick,
  displayButtonChooseDestination = false,
}) => {
  const imageSrc = useAppImageUrl(company?.logo, {
    defaultImage: 'app',
  });
  return (
    <>
      <div className="w-full mb-2 pt-2">
        <div className="w-full flex flex-col">
          <div className="flex flex-1 gap-2 justify-start items-start">
            <img className="max-w-4xs h-auto object-contain" src={imageSrc} />
            <div className="font-tertiary font-bold text-xs text-left">
              <div>{company?.name}</div>
              <div className="font-normal" hidden={!displayAddress}>
                {/* dans la bdd accropark, line1 = adresse complete */}
                <div>{company?.direction.line1}</div>
                <div>
                  {/* dans la bdd zipCode = téléphone ou email */}
                  {/* dans la bdd direction.city = téléphone ou email */}
                  {company?.direction.zipCode} {company?.direction.city}
                </div>
              </div>
            </div>
          </div>
          <div
            className="place-self-end flex-1 ml:mb-0"
            hidden={!buttonText || !onClick || !displayButtonChooseDestination}
          >
            <AppButtonUniverse
              className="my-2 text-xs"
              onClick={onClick}
              style="primary"
              label={buttonText}
            />
          </div>
        </div>
      </div>
      <div className="mb-2 border-b border-gray-200"></div>
    </>
  );
};
