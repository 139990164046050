import { IonButton, IonIcon } from '@ionic/react';
import {
  AppPage,
  AppTitle1,
  FooterActions,
  HeaderToolbar,
  PageContainer,
} from '_01_CORE/_common-layout';
import { fakeDataGenerator } from '_01_CORE/_components-core';
import { useAppRouter, useAppToasts } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import { journeyHelper } from '_01_CORE/_services';
import { useAppCacheContext, useJourney } from '_01_CORE/app-contexts';
import { useNetworkStatus } from '_01_CORE/app-core/app-platform';
import { useDeleteJourneyBookingAccroparkConfirm } from '_02_APP_COMMON/JOU-journey/_common';
import { AppAutofillButton } from '_02_APP_COMMON/_global';
import { CustomerAccountEditFormData } from '_02_APP_COMMON/_model';
import { checkmark } from 'ionicons/icons';
import { CMJourneyParticipant } from 'lib-common-model';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { CustomerAccountAccroparkEditForm } from './CustomerAccountAccroparkEditForm';
import { useAccroparkCustomerAccountEditDefaultValues } from './useAccroparkCustomerAccountEditDefaultValues.hook';
import { useOnSubmitCustomerAccountAccroparkEditForm } from './useOnSubmitCustomerAccountAccroparkEditForm.hook';
export const AccroparkCustomerAccountJourneyEditPage = () => {
  const appRouter = useAppRouter();
  const { formatMessage: t } = useIntl();
  const { auth } = useAppCacheContext();
  const { customerJourneyId } = useParams<{ customerJourneyId: string }>();
  const { pushError } = useAppToasts();
  const { networkStatus } = useNetworkStatus();

  const { journey } = useJourney({ customerJourneyId });

  const defaultName = useMemo<{
    lastName: string;
    firstName: string;
  }>(() => {
    const firstParticipant: CMJourneyParticipant = journey?.participants.length
      ? journeyHelper.sortParticipants(journey.participants)[0]
      : undefined;
    const ca = auth?.customerProfile?.account;
    if (firstParticipant && ca?.status === 'draft' && !ca.lastName) {
      return {
        lastName: firstParticipant.member.lastName,
        firstName: firstParticipant.member.firstName,
      };
    }
  }, [auth?.customerProfile?.account, journey?.participants]);

  const defaultValues = useAccroparkCustomerAccountEditDefaultValues({
    defaultName,
  });
  const deleteJourneyBookingConfirm = useDeleteJourneyBookingAccroparkConfirm({
    customerJourneyId,
  });

  const form = useForm<CustomerAccountEditFormData>({
    defaultValues,
    mode: 'onChange',
  });
  const { handleSubmit, formState, control, setValue } = form;

  // NOTE: avant: isAnyAdmin limitait aux pro admin (maintenant, plus de restriction en fonction du profil, peut-être qu'il faudra parser les roles "az")
  const isAnyAdmin =
    auth?.isSuperAdmin || auth?.securityUser?.profile === 'company';

  const isJourneyReplacementInProgress = useMemo(
    () =>
      journey.status !== 'validated' && !!journey.replaceFromCustomerJourneyId,
    [journey.replaceFromCustomerJourneyId, journey.status]
  );

  const goBack = () => {
    appRouter.navigate(
      appRoutesBuilder.getJourneyOptionsUrl({ customerJourneyId: journey._id }),
      { cause: 'customer-account-journey-back' }
    );
  };

  function autoFillForm() {
    const lastName = fakeDataGenerator.getLastName();
    setValue('lastName', lastName);
    if (!isAnyAdmin) {
      const firstName = fakeDataGenerator.getFirstName();
      setValue('firstName', firstName);
      setValue('gender', fakeDataGenerator.getGender());
      setValue('country', fakeDataGenerator.getCountry().id);
      setValue('language', fakeDataGenerator.getLanguage().id);
      setValue('phone1', fakeDataGenerator.getPhone());
      setValue('email', fakeDataGenerator.getEmail({ lastName, firstName }));
      setValue('password', 'a');
      setValue('consent', true);
      setValue('electronicSignature', true);
      setValue('locationArea', '75000 Paris');
    }
  }

  const onSuccess = useCallback(() => {
    if (isAnyAdmin) {
      appRouter.navigate(
        appRoutesBuilder.getJourneyProUrl({ customerJourneyId }),
        { cause: 'customer-account-submit' }
      );
    } else {
      appRouter.navigate(
        appRoutesBuilder.getPaymentUrl({ customerJourneyId }),
        { cause: 'customer-account-submit' }
      );
    }
  }, [appRouter, customerJourneyId, isAnyAdmin]);

  const onSubmit = useOnSubmitCustomerAccountAccroparkEditForm({
    onSuccess,
  });

  const onInvalid = () => {
    pushError(t({ id: 'common.message.error.invalid-form' }));
  };

  return (
    <AppPage displayFooter={false}>
      <HeaderToolbar hideLogout={true} />
      <PageContainer
        className="text-right bg-gray-50 flex flex-col"
        spacing="normal"
      >
        <AppTitle1 className="w-full pl-4 text-left relative" arrow="left">
          {t({ id: 'page14.payment.infos' })}
          <AppAutofillButton onClick={() => autoFillForm()} />
        </AppTitle1>

        <div className="flex-grow">
          <CustomerAccountAccroparkEditForm
            form={form}
            onSubmit={handleSubmit(onSubmit, onInvalid)}
          />
        </div>

        <div className="my-4 bg-danger text-center text-white font-semibold p-2">
          ⚠️ {t({ id: 'page16.booking.timeRemaining' }, { time: 9 })}
        </div>
        {!isJourneyReplacementInProgress && (
          <div className="my-4">
            <IonButton
              className="w-full"
              color="primary"
              onClick={handleSubmit(onSubmit, onInvalid)}
            >
              <IonIcon icon={checkmark} className="mr-2" />
              {t({ id: 'common.action.continue.booking.payment' })}
            </IonButton>
          </div>
        )}
      </PageContainer>
      <FooterActions
        onCancel={() => deleteJourneyBookingConfirm()}
        onBack={goBack}
        onSubmit={handleSubmit(onSubmit, onInvalid)}
        submitText={t({ id: 'page14.pay' })}
      />
    </AppPage>
  );
};
