import { useIntl } from 'react-intl';
import { CustomerActivityParticipantsDailyActivitySession } from './model';
import { CustomerActivityParticipantsDailyActivity } from './model/CustomerActivityParticipantsDailyActivity.type';

// NOTE: voir aussi JourneyDailyActivityAvailabilityTimeOption
export const JourneyDailyActivityAvailability = ({
  selectedSession: selectedSessionInput,
  dailyActivity,
  className = '',
}: {
  selectedSession?: CustomerActivityParticipantsDailyActivitySession;
  dailyActivity: CustomerActivityParticipantsDailyActivity;
  className?: string;
}) => {
  const { formatMessage: t } = useIntl();

  const selectedSession = selectedSessionInput ?? dailyActivity.selectedSession;

  const activitySession = selectedSession.activitySession;

  return (
    <>
      <div
        className={`${className} mt-0.5 ${
          activitySession.availableRegistrationsStatus === 'full'
            ? 'text-danger'
            : activitySession.availableRegistrationsStatus === 'busy'
            ? 'text-warning'
            : 'text-app-success-shade'
        }`}
      >
        {dailyActivity.openSessionsFuture.length !== 0 && (
          <>
            {activitySession.availableRegistrationsStatus === 'full' && (
              <div className="flex-grow uppercase">
                {t({
                  id: 'page16.slots.full', // 'Complet'
                })}
              </div>
            )}
            {selectedSession.registrationStatus === 'open' &&
              activitySession.availableRegistrationsStatus === 'free' && (
                <div className="flex-grow">
                  {t(
                    {
                      id: 'page16.slots.available', // 'Places disponibles: {count = 'illimité'}'
                    },
                    {
                      count: t({
                        id: 'page16.slots.illimited', // 'illimité'
                      }),
                    }
                  )}
                </div>
              )}
            {selectedSession.registrationStatus === 'open' &&
              activitySession.availableRegistrationsCount > 0 && (
                <div className="flex-grow">
                  {t(
                    {
                      id: 'page16.slots.available', // 'Places disponibles: {count}'
                    },
                    { count: activitySession.availableRegistrationsCount }
                  )}
                </div>
              )}
          </>
        )}
        {selectedSession.registrationStatus ===
          'registration-customer-disabled' && (
          <div className="text-danger">
            {dailyActivity.activitySchedule.registrationCustomerDisabledMessage}
          </div>
        )}
      </div>
      {dailyActivity.openSessionsFuture.length === 0 && (
        <div className={`${className} flex-grow text-danger`}>
          {t({ id: 'page16.session.full' })}
        </div>
      )}
    </>
  );
};
