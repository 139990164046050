import React, { useState } from 'react';

type AppTextareaProps = {
  label?: string;
};

type NativeInputProps = React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>;

function calcHeight(value: string) {
  const numberOfLineBreaks = (value.match(/\n/g) || []).length;
  // min-height + lines x line-height + padding //+ border
  const newHeight = 40 + (numberOfLineBreaks + 1) * 24 + 16; //+ 2;
  return newHeight;
}

export const AppTextarea = React.forwardRef<
  HTMLTextAreaElement,
  AppTextareaProps & NativeInputProps
>(({ label, className, ...nativeInputProps }, ref) => {
  const {
    id = nativeInputProps.id ?? `apptextarea-${Math.random() * 10000}`,
  } = nativeInputProps;

  const classNameTextArea =
    'bg-white shadow-sm focus:border-primary block w-full app-theme-anim-live-primary border-gray-300 rounded-md p-1';
  const [height, setHeight] = useState(
    calcHeight(
      ((nativeInputProps.value ?? nativeInputProps.defaultValue) as string) ??
        ''
    )
  );

  const onTextChange = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
    setHeight(calcHeight(ev.target.value));
    nativeInputProps.onChange?.(ev);
  };

  return (
    <div className={`w-full ${className ?? ''}`}>
      {label && (
        <label
          className="mb-1 text-gray-500 font-bold text-xs block text-left"
          htmlFor={id}
        >
          {label}
        </label>
      )}
      <textarea
        className={classNameTextArea}
        ref={ref}
        id={id}
        {...nativeInputProps}
        onChange={onTextChange}
        style={{ height }}
      />
    </div>
  );
});
AppTextarea.displayName = 'AppTextarea';
