export const dateTester = {
  isBefore,
  isDateWithinRange,
  havePeriodsCommonRange,
  isSameDay,
  isFuture,
};

function isFuture(
  date: Date | string,
  {
    delayHours,
  }: {
    delayHours: number;
  }
): boolean {
  return (
    new Date(date).getTime() - new Date().getTime() > delayHours * 3600 * 1000
  );
}

function isSameDay(d1: Date, d2: Date) {
  if (!d1.getTime) {
    d1 = new Date(d1);
  }
  if (!d2.getTime) {
    d2 = new Date(d2);
  }
  return (
    d1.getUTCFullYear() === d2.getUTCFullYear() &&
    d1.getUTCMonth() === d2.getUTCMonth() &&
    d1.getUTCDate() === d2.getUTCDate()
  );
}

function isBefore(d1: Date, d2: Date) {
  if (!d1.getTime) {
    d1 = new Date(d1);
  }
  if (!d2.getTime) {
    d2 = new Date(d2);
  }
  return d1.getTime() < d2.getTime();
}

function isDateWithinRange(
  date: Date,
  {
    minDate,
    maxDateExclusive,
  }: {
    minDate: Date;
    maxDateExclusive: Date;
  }
) {
  if (!date || !minDate || !maxDateExclusive) {
    return false;
  }
  if (!date.getTime) {
    date = new Date(date);
  }
  if (!minDate.getTime) {
    minDate = new Date(minDate);
  }
  if (!maxDateExclusive.getTime) {
    maxDateExclusive = new Date(maxDateExclusive);
  }
  return (
    date.getTime() < maxDateExclusive.getTime() &&
    date.getTime() >= minDate.getTime()
  );
}

function havePeriodsCommonRange({
  period1,
  period2,
}: {
  period1: {
    minDate: Date;
    maxDateExclusive: Date;
  };
  period2: {
    minDate: Date;
    maxDateExclusive: Date;
  };
}) {
  return (
    period1.minDate.getTime() < period2.maxDateExclusive.getTime() &&
    period2.minDate.getTime() < period1.maxDateExclusive.getTime()
  );
}
