import { IonIcon } from '@ionic/react';
import { build } from 'ionicons/icons';
import React from 'react';
import { appWebConfig } from '_01_CORE/app-core/app-config';

export const AppAutofillButton = ({ onClick }: { onClick: () => void }) => {
  return appWebConfig().envId === 'dev' ? (
    <div className="absolute -top-5 -right-2 sm:-right-3 md:-right-5 text-2xl cursor-pointer">
      <IonIcon
        className="text-gray-400"
        icon={build}
        onClick={() => onClick()}
      />
    </div>
  ) : null;
};
