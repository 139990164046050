import { appWebConfig } from '_01_CORE/app-core/app-config';
import { useAppPlatform } from '_01_CORE/app-core/app-platform';
import { useMemo } from 'react';

const TEST_DEV_SIMULATE_APP = appWebConfig().envId === 'dev' && true;
const TEST_DEV_SHOW_POPUP_BEFORE_REDIRECT =
  appWebConfig().envId === 'dev' && false;
const TEST_DEV_SHOW_DEBUG_AREA = appWebConfig().envId === 'dev' && true;
const TEST_DEV_DISABLE_AUTO_REDIRECT = appWebConfig().envId === 'dev' && false;
const TEST_DEV_DISABLE_AUTO_CLOSE = appWebConfig().envId === 'dev' && false;
const REFRESH_PERIOD = appWebConfig().envId === 'dev' ? 5000 : 2000;

export type ALShoppingCartPaymentPageMode = {
  id:
    | 'simple' // SIMPLEWeb, une seule page, fonctionnement par redirections
    | 'app-root' // ROOT Appli mobile ios (ou simulé via TEST_DEV_SIMULATE_APP), page principale
    // | 'inline-iframe' // Appli mobile android (ou simulé via TEST_DEV_SIMULATE_APP), page ouverte dans par 'app-root',
    | 'app-slave'; // ESCLAVE Appli mobile ios (ou simulé via TEST_DEV_SIMULATE_APP), page ouverte dans par 'app-root',
  loadingMode: 'start-payment' | 'back-from-payment';
  paymentResult?: 'success' | 'cancel';
  disableAutoRedirect: boolean;
  showAlertBeforeRedirect: boolean;
  disableAutoClose: boolean;
  showDebugArea: boolean;
  refreshPeriod: number;
};

export function useALShoppingCartPaymentPageMode() {
  const { platform } = useAppPlatform();

  // sous android, on ne peut pas fermer le navigateur, donc obligé d'utiliser une iframe
  // sous ios, on ne peut pas utiliser d'iframe, donc on ouvre un navigateur

  const pageMode: ALShoppingCartPaymentPageMode = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    const paramPaymentResult = params.get('payment-result') as
      | 'success'
      | 'cancel';
    return {
      id:
        params.get('page-mode') === 'app-slave'
          ? 'app-slave' // ESCLAVE Appli mobile (ou simulé via TEST_DEV_SIMULATE_APP), page ouverte dans par 'app-root'
          : platform === 'web' && !TEST_DEV_SIMULATE_APP
          ? 'simple' // SIMPLE Web, une seule page, fonctionnement par redirections
          : // : platform === 'android'
            // ? 'inline-iframe' // Appli mobile android
            'app-root', // ROOT Appli mobile ios (ou simulé via TEST_DEV_SIMULATE_APP), page principale
      loadingMode:
        paramPaymentResult === 'success' || paramPaymentResult === 'cancel'
          ? 'back-from-payment'
          : 'start-payment',
      paymentResult: paramPaymentResult,
      disableAutoRedirect: TEST_DEV_DISABLE_AUTO_REDIRECT,
      disableAutoClose: TEST_DEV_DISABLE_AUTO_CLOSE,
      showAlertBeforeRedirect: TEST_DEV_SHOW_POPUP_BEFORE_REDIRECT,
      showDebugArea: TEST_DEV_SHOW_DEBUG_AREA,
      refreshPeriod: REFRESH_PERIOD,
    };
  }, [platform]);
  return pageMode;
}
