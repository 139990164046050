import { Event, InlineCheckout } from '@bambora/checkout-sdk-web';
import { IonButton, IonIcon } from '@ionic/react';
import { checkmark, time } from 'ionicons/icons';
import { CMCompanyResume, EcommerceBookingJourney } from 'lib-common-model';
import { appLogger } from 'lib-web-logger';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { ecommerceBookingJourneyApiClient } from '_01_CORE/app-core/app-api';
import { useAppPlatform } from '_01_CORE/app-core/app-platform';
import { AccroparkBookingResumeTable } from '../_common';
import { PaymentPageState } from './PaymentPageState.type';

const ENABLED_PAYMENT_MODES = {
  'pay-now': true,
  'pay-later': false,
};

export const AccroparkPaymentForm = ({
  checkoutRef,
  booking,
  reloadBooking,
  company,
  paymentState,
  setPaymentState,
  confirmFreeBooking,
  bamboraMode,
}: {
  checkoutRef: React.MutableRefObject<InlineCheckout>;
  booking: EcommerceBookingJourney;
  reloadBooking: (customerJourneyId: string) => Promise<void>;
  company: CMCompanyResume;
  paymentState: PaymentPageState;
  setPaymentState: (paymentState: PaymentPageState) => void;
  confirmFreeBooking: () => void;
  bamboraMode: 'open-browser' | 'inline-iframe';
}) => {
  const customerJourneyId = booking?.customerJourneyId;

  const { formatMessage: t } = useIntl();
  const { platform } = useAppPlatform();

  const isPaymentInProgress = useMemo(
    () =>
      paymentState === '3-payment-in-progress-inline' ||
      paymentState === '3-payment-in-progress-browser',
    [paymentState]
  );
  const isPaymentResumeVisible = useMemo(() => !isPaymentInProgress, [
    isPaymentInProgress,
  ]);
  const isPaymentNotStarted = useMemo(
    () =>
      paymentState === '1-payment-not-started' ||
      paymentState === '4-payment-cancel' ||
      paymentState === '5-payment-error',
    [paymentState]
  );

  async function startPayment() {
    try {
      // FIXME @toub faire via le state
      setPaymentState('2-starting-payment');
      const sessionToken = await ecommerceBookingJourneyApiClient.createBookingCheckoutSession(
        { customerJourneyId }
      );
      setPaymentState('3-payment-in-progress-inline');
      const checkout = new InlineCheckout(sessionToken, {
        container: document.getElementById('bambora-frame-container'),
      });
      checkoutRef.current = checkout;

      checkout.on(Event.Authorize, async function (payload) {
        setPaymentState('6-payment-success');
        checkout.destroy();
        await ecommerceBookingJourneyApiClient.confirmBamboraBookingPayment({
          customerJourneyId,
          sessionToken,
          data: payload.data,
        });

        reloadBooking(customerJourneyId);
      });
      checkout.on(Event.Cancel, function () {
        setPaymentState('4-payment-cancel');
        checkout.destroy();
      });
    } catch (err) {
      setPaymentState('5-payment-error');
      appLogger.error('Unexpected error during payment', { err: err as any });
    }
  }

  function payLater() {
    // TODO @toub ?
  }

  return !booking ? null : (
    <>
      {bamboraMode === 'open-browser' ? (
        <div hidden={!isPaymentInProgress}>{t({ id: 'page25.wait' })}</div>
      ) : (
        <div
          id="bambora-frame-container"
          className="h-full w-full"
          hidden={!isPaymentInProgress}
        ></div>
      )}

      <div hidden={!isPaymentResumeVisible}>
        <AccroparkBookingResumeTable booking={booking} company={company} />
      </div>
      <div hidden={paymentState !== '2-starting-payment'}>
        {t({ id: 'page25.wait' })}
      </div>
      {/* <div hidden={paymentState !== '4-payment-cancel'}>Paiement annulé</div> */}
      <div hidden={paymentState !== '5-payment-error'} className="text-red-600">
        {t({ id: 'page25.payment.error' })}
      </div>
      {booking.calculated.totalToPayNow > 0 ? (
        <>
          {ENABLED_PAYMENT_MODES['pay-now'] && (
            <>
              <IonButton
                hidden={!isPaymentNotStarted}
                className="my-5 max-w-xs w-full"
                onClick={startPayment}
                shape="round"
              >
                <IonIcon className="mr-2" icon={checkmark} />
                {t({ id: 'page25.pay.now' })}
              </IonButton>
              <img
                hidden={!isPaymentNotStarted}
                className="mt-2 mb-4 max-w-sm mx-auto h-12"
                src="/assets/images/cards.png"
              />
            </>
          )}

          {ENABLED_PAYMENT_MODES['pay-later'] && (
            <IonButton
              hidden={!isPaymentNotStarted}
              className="block max-w-xs w-full mx-auto"
              onClick={payLater}
              shape="round"
            >
              <IonIcon className="mr-2" icon={time} />
              {t({ id: 'page25.pay.later' })}
            </IonButton>
          )}
        </>
      ) : (
        <>
          <IonButton
            hidden={!isPaymentNotStarted}
            className="max-w-xs w-full"
            onClick={() => confirmFreeBooking()}
            shape="round"
          >
            <IonIcon className="mr-2" icon={checkmark} />
            {t({ id: 'page25.free.confirm' })}
          </IonButton>
        </>
      )}
    </>
  );
};
