import { useMemo } from 'react';
import { dateServiceCore } from '../../../../../stories/services';
import { AppHackableCalendarIsoWeekDay, AppHackableCalendarVM } from '../model';
import {
  AppHackableCalendarWeeksPanelVM,
  AppHackableCalendarWeeksPanelVMDay,
} from './model';
import { AppHackableCalendarWeeksPanelVMHeaderDay } from './model/AppHackableCalendarWeeksPanelVMHeaderDay.type';

export function useAppHackableCalendarWeeksPanelVM(
  props: AppHackableCalendarVM
): AppHackableCalendarWeeksPanelVM {
  const selectedDatesIso = useMemo(
    () =>
      (props.selectedDates ?? []).map((d) =>
        dateServiceCore.toISOStringDate(d)
      ),
    [props.selectedDates]
  );

  const days: AppHackableCalendarWeeksPanelVMDay[] = useMemo(() => {
    const viewDate = props.viewDate;
    const viewUTCMonth = viewDate.getUTCMonth();
    const todayIsoString = dateServiceCore.toISOStringDate(new Date());

    let firstDay = new Date(viewDate);

    for (
      let i = 0;
      i < 50 &&
      !(
        dateServiceCore.getUTCWeekDayIso(firstDay) ===
          props.firstIsoDayOfWeek &&
        (firstDay.getUTCDate() === 1 || viewUTCMonth !== firstDay.getUTCMonth())
      );
      i++
    ) {
      firstDay = dateServiceCore.addDays(firstDay, -1);
    }

    const days: AppHackableCalendarWeeksPanelVMDay[] = [];
    for (let i = 0; i < 6 * 7; i++) {
      const date = dateServiceCore.addDays(firstDay, i);
      const dateIsoString = dateServiceCore.toISOStringDate(date);
      const day: AppHackableCalendarWeeksPanelVMDay = {
        date,
        dateIsoString,
        isCurrentViewMonth: date.getUTCMonth() === viewUTCMonth,
        isToday: dateIsoString === todayIsoString,
        isSelected: selectedDatesIso.includes(dateIsoString),
      };
      days.push(day);
    }

    return days;
  }, [props.firstIsoDayOfWeek, props.viewDate, selectedDatesIso]);

  return useMemo(() => {
    const headerDays = days.slice(0, 7).map((x) => {
      const isoWeekDay: AppHackableCalendarIsoWeekDay = dateServiceCore.getUTCWeekDayIso(
        x.date
      );
      const hd: AppHackableCalendarWeeksPanelVMHeaderDay = {
        date: x.date,
        isoWeekDay,
        label: props.labels.weekDays[isoWeekDay - 1],
      };
      return hd;
    });

    const vm: AppHackableCalendarWeeksPanelVM = {
      days,
      headerDays,
    };
    return vm;
  }, [days, props.labels.weekDays]);
}
