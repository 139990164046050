import React, { createContext, useState } from 'react';

type IconType = 'danger' | 'info' | 'success' | 'none';

export type AppModalParams = {
  title?: React.ReactNode;
  content?: React.ReactNode;
  actions?: React.ReactNode;
  clickOutsideToDismiss?: boolean;
  icon?: IconType;
};

export interface AppModalContext {
  openModal: (p: AppModalParams) => void;
  closeModal: () => void;
  isVisible: boolean;
  clickOutsideToDismiss: boolean;
  title: React.ReactNode;
  content: React.ReactNode;
  actions: React.ReactNode;
  icon: React.ReactNode;
}

export const AppModalContext = createContext<AppModalContext>({
  isVisible: false,
  clickOutsideToDismiss: false,
  openModal: () => {
    //
  },
  closeModal: () => {
    //
  },
  title: null,
  content: null,
  actions: null,
  icon: 'danger',
});

function useAppModal(): AppModalContext {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [clickOutsideToDismiss, setClickOutsidetoDismiss] = useState<boolean>(
    false
  );
  const [title, setTitle] = useState<React.ReactNode>(null);
  const [actions, setActions] = useState<React.ReactNode>(null);
  const [content, setContent] = useState<React.ReactNode>(null);
  const [icon, setIcon] = useState<IconType>('danger');

  const openModal = (p: AppModalParams) => {
    if (p) {
      setIsVisible(true);
      setClickOutsidetoDismiss(
        p.clickOutsideToDismiss !== undefined ? p.clickOutsideToDismiss : true
      );
      setTitle(p.title !== undefined ? p.title : null);
      setActions(p.actions !== undefined ? p.actions : null);
      setContent(p.content !== undefined ? p.content : null);
      setIcon(p.icon !== undefined ? p.icon : 'danger');
    }
  };

  const closeModal = () => {
    setIsVisible(false);
  };

  return {
    clickOutsideToDismiss,
    openModal,
    closeModal,
    isVisible,
    title,
    actions,
    content,
    icon,
  };
}

export const AppModalContextProvider: React.FC = ({ children }) => {
  return (
    <AppModalContext.Provider value={useAppModal()}>
      {children}
    </AppModalContext.Provider>
  );
};
