import { AppDefaultImage, ResponsiveImage } from 'lib-common-model';
import { useMemo } from 'react';
import { imageUrlBuilder } from '../app-api';
import { appWebConfig } from '../app-config';
import { useAppSettings } from './useAppSettings.hook';

export function useAppImageUrl(
  image: ResponsiveImage,
  {
    defaultImage,
    size = 'md',
  }: { defaultImage: AppDefaultImage; size?: 'sm' | 'md' }
): string {
  const appSettings = useAppSettings();
  const defaultLogo =
    defaultImage === 'app'
      ? appSettings.universe?.identity?.appLogo
      : defaultImage === 'activity'
      ? appSettings.universe?.identity?.defaultActivityLogo
      : defaultImage === 'club'
      ? appSettings.universe?.identity?.defaultClubLogo
      : undefined;
  const appLogoUrl = useMemo(
    () =>
      imageUrlBuilder.getImageFullUrl({
        image: image?.[size],
        defaultUrl: defaultLogo
          ? appWebConfig().urls.cdnUpload + defaultLogo?.[size]
          : undefined,
      }),
    [defaultLogo, image, size]
  );
  return appLogoUrl;
}
