import { SelectOption } from '../../../form';
import { COUNTRIES_ARRAY } from './COUNTRIES_ARRAY.const';
import { Country } from './Country.model';

export const COUNTRIES_OPTIONS: SelectOption[] = COUNTRIES_ARRAY.map(
  (c: Country) => {
    return {
      id: c.id,
      imageUrl: c.imgUrl,
      // label: c.label,
      // Please use translations to label something
      label: '[DEV: dont-use]',
    };
  }
).filter((x) => x.id !== '@@@');
