import { dataSorter } from '_01_CORE/_components-core';
import {
  activityDateTester,
  dateTester,
  dateTransformer,
} from '_01_CORE/_components-core/date';
import { ecommerceHelper } from '_01_CORE/_services';
import {
  ActivitySessionRegistrationFeePriceRange,
  ActivitySessionRegistrationStatus,
  AppSettings,
  CMJourneyActivities,
  CMJourneyActivitySession,
  CMJourneyActivitySessionParticipant,
  CMJourneyParticipant,
  CompanyOptions,
  JourneyActivityPackage,
} from 'lib-common-model';
import { ActivityPendingOperationDef } from '../model';
import { CustomerActivityParticipantsDailyActivitParticipantState } from '../model/CustomerActivityParticipantsDailyActivitParticipantState.type';
import { CustomerActivityParticipantsDailyActivity } from '../model/CustomerActivityParticipantsDailyActivity.type';
import { CustomerActivityParticipantsDailyActivitySession } from '../model/CustomerActivityParticipantsDailyActivitySession.type';
import { CustomerParticipantStatusAvatarState } from '../model/CustomerParticipantStatusAvatarState.type';
import { CMJourneyAnimationsFilteredData } from './customerJourneyAnimationsDailyActivitiesFilter.service';

export const customerJourneyAnimationsDailyActivitiesBuilder = {
  buildDailyActivities,
};

function buildDailyActivities({
  filteredData,
  lastSelectedSessionId,
  companyOptions,
  journeyActivityPackages,
  pendingOperations,
  appSettings,
}: {
  filteredData: CMJourneyAnimationsFilteredData;
  lastSelectedSessionId: string;
  companyOptions: CompanyOptions;
  journeyActivityPackages: JourneyActivityPackage[];
  pendingOperations: ActivityPendingOperationDef[];
  appSettings: AppSettings;
}) {
  const waitingListEnabled = companyOptions.waitingList?.enabled;

  const {
    filteredJourneyParticipants,
    allClubsParticipants,
    filteredClubsParticipants,
    activitySessions,
    customerActivitySessionsIds,
    activitySessionsParticipants,
    customerJourneyActivities,
  } = filteredData;

  const dailyActivities: CustomerActivityParticipantsDailyActivity[] = activitySessions.reduce(
    (dailyActivities, activitySession) => {
      // filter les activités par participant
      const clubsFilteredParticipant = filteredClubsParticipants.find(
        (clubParticipants) =>
          clubParticipants.club._id === activitySession.companyClubId
      );

      // mais affiche tous les participants du club, même si il ne sont pas dans le filtre
      const clubAllParticipants = allClubsParticipants.find(
        (clubParticipants) =>
          clubParticipants.club._id === activitySession.companyClubId
      );

      if (clubsFilteredParticipant) {
        const club = clubsFilteredParticipant.club;

        const dailySession = buildDailyActivitySession({
          activitySession,
          journeyParticipants: clubAllParticipants.journeyParticipants,
          activitySessionsParticipants,
          customerJourneyActivities,
          customerActivitySessionsIds,
          companyOptions,
          journeyActivityPackages,
          pendingOperations,
          appSettings,
        });

        let dailyActivity: CustomerActivityParticipantsDailyActivity;

        const isMultipleSessions =
          activitySession.activitySchedule.mode === 'multiple-sessions';
        let multipleModeDailyActivity: CustomerActivityParticipantsDailyActivity;

        let createActivity = false;
        if (isMultipleSessions) {
          // look for existing activity in the same schedule
          multipleModeDailyActivity = dailyActivities.find(
            (da) =>
              da.displayMode === 'multiple' &&
              da.activitySchedule._id ===
                activitySession.activitySchedule._id &&
              da.club._id === club._id
          );
          if (multipleModeDailyActivity) {
            // add activity to multiple list
            multipleModeDailyActivity.sessions.push(dailySession);
          } else {
            // create multiple activity
            multipleModeDailyActivity = {
              activity: dailySession.activitySession.activity,
              activitySchedule: dailySession.activitySession.activitySchedule,
              displayMode: 'multiple',
              club,
              sessions: [dailySession],
              openSessions: [dailySession],
              openSessionsFuture: [dailySession],
              selectedSession: dailySession,
            };
            dailyActivities.push(multipleModeDailyActivity);
          }
          if (dailySession.isCustomerRegistered) {
            // also create the activity as "single"
            createActivity = true;
          }
        } else {
          createActivity = true;
        }
        if (createActivity) {
          // build single mode activity
          dailyActivity = {
            activity: dailySession.activitySession.activity,
            activitySchedule: dailySession.activitySession.activitySchedule,
            displayMode: 'single',
            club,
            sessions: [dailySession],
            openSessions: [dailySession],
            openSessionsFuture: [dailySession],
            selectedSession: dailySession,
          };
          dailyActivities.push(dailyActivity);
        }
      }
      return dailyActivities;
    },
    []
  );

  // NOTE: si un participant n'est dans aucun club, alors il n'est pas retourné par le serveur, donc n'apparaitra pas ici
  const participantsWithoutActivities = filteredJourneyParticipants.filter(
    (journeyParticipant) =>
      !dailyActivities.some((dailyActivity) =>
        dailyActivity.sessions.some((session) =>
          session.participants.some(
            (participantActivities) =>
              participantActivities.journeyParticipant.journeyParticipantId ===
              journeyParticipant.journeyParticipantId
          )
        )
      )
  );

  // dailyActivities = dailyActivities.filter(da=>da.)

  dailyActivities.forEach((dailyActivity) => {
    if (dailyActivity.displayMode === 'multiple') {
      const nowUTC = dateTransformer.getUTCFromLocalDate(new Date());
      const openSessions = dailyActivity.sessions.filter(
        (s) =>
          s.activitySession.openingStatus === 'open' &&
          (s.isCustomerRegistered ||
            [
              'open',
              'camping-registration-not-enabled',
              'registration-not-open-yet',
            ].indexOf(s.registrationStatus) !== -1 ||
            (s.registrationStatus === 'registration-full' &&
              waitingListEnabled))
      );
      const openSessionsFuture = openSessions.filter(
        (s) => !dateTester.isBefore(s.activitySession.endDateTime, nowUTC)
      );
      dailyActivity.openSessions = openSessions;
      dailyActivity.openSessionsFuture = openSessionsFuture;

      // by default: select first session without registration, because sessions with registration are also displayed as single
      const firstNonRegisteredSession = dailyActivity.openSessionsFuture.find(
        (s) =>
          !s.isCustomerRegistered &&
          s.registrationStatus !== 'registration-full'
      );
      if (firstNonRegisteredSession) {
        dailyActivity.selectedSession = firstNonRegisteredSession;
      } else {
        // else, select first available session, or first session if none is available
        dailyActivity.selectedSession = dailyActivity.openSessionsFuture.length
          ? dailyActivity.openSessionsFuture[0]
          : dailyActivity.sessions[0];
      }
    }
  });

  const sortedDailyActivities = dataSorter
    .sortMultiple(dailyActivities, {
      getSortAttributes: (
        dailyActivity: CustomerActivityParticipantsDailyActivity
      ) => [
        {
          value: dailyActivity.selectedSession.activitySession.beginDateTime,
        },
        {
          value: dailyActivity.selectedSession.activitySession.endDateTime,
        },
        {
          value:
            dailyActivity.selectedSession.activitySession.activitySessionId,
        },
      ],
      asc: true,
    })
    .map((dailyActivity) => {
      const lastSelectedSessionMatch = !lastSelectedSessionId
        ? undefined
        : dailyActivity.sessions.find(
            (s) => s.activitySession.activitySessionId === lastSelectedSessionId
          );
      if (lastSelectedSessionMatch) {
        // last manually selected session
        dailyActivity.selectedSession = lastSelectedSessionMatch;
      }

      return dailyActivity;
    })
    .filter((da) => {
      if (da.displayMode === 'single') {
        // on cherche l'autre dailyActivity en mode "multiple", pour voir si la session est sélectionnée
        const otherDailyActivityWithSameSessionSelected = dailyActivities.find(
          (daM) =>
            daM.displayMode === 'multiple' &&
            daM?.selectedSession?.activitySession?.activitySessionId ===
              da?.selectedSession?.activitySession?.activitySessionId
        );
        if (otherDailyActivityWithSameSessionSelected) {
          // on masque l'activité simple, car son homologue "multiple" est déjà sélectionné
          return false;
        }
      }
      return true;
    });

  return {
    dailyActivities: sortedDailyActivities,
    participantsWithoutActivities,
  };
}

function buildDailyActivitySession({
  appSettings,
  pendingOperations,
  companyOptions,
  activitySession,
  journeyParticipants,
  activitySessionsParticipants,
  customerJourneyActivities,
  customerActivitySessionsIds,
  journeyActivityPackages,
}: {
  pendingOperations: ActivityPendingOperationDef[];
  appSettings: AppSettings;
  companyOptions: CompanyOptions;
  activitySession: CMJourneyActivitySession;
  journeyParticipants: CMJourneyParticipant[];
  activitySessionsParticipants: CMJourneyActivitySessionParticipant[];
  customerJourneyActivities: CMJourneyActivities;
  customerActivitySessionsIds: string[];
  journeyActivityPackages: JourneyActivityPackage[];
}): CustomerActivityParticipantsDailyActivitySession {
  const isCustomerRegistered =
    customerActivitySessionsIds.indexOf(activitySession.activitySessionId) !==
    -1;

  const nowUTC = dateTransformer.getUTCFromLocalDate(new Date());
  const isSessionStarted = dateTester.isBefore(
    activitySession.beginDateTime,
    nowUTC
  );
  const isSessionFinished = dateTester.isBefore(
    activitySession.endDateTime,
    nowUTC
  );

  const registrationStatus: ActivitySessionRegistrationStatus = buildRegistrationClosedStatus(
    activitySession,
    {
      appSettings,
      companyOptions,
      campingPreregistrationEnabled:
        customerJourneyActivities.campingPreregistrationEnabled,
      isSessionStarted,
      isSessionFinished,
    }
  );

  return {
    isSessionStarted,
    isSessionFinished,
    isCustomerRegistered,
    activitySession,
    registrationStatus,
    registrationCustomerDisabledMessage:
      registrationStatus === 'registration-customer-disabled'
        ? activitySession.activitySchedule.registrationCustomerDisabledMessage
        : undefined,
    participants: journeyParticipants.map((journeyParticipant) => {
      const participantState: CustomerActivityParticipantsDailyActivitParticipantState = buildParticipantState(
        {
          activitySessionsParticipants,
          activitySession,
          journeyParticipant,
          registrationStatus,
          companyOptions,
          journeyActivityPackages,
          pendingOperations,
        }
      );
      return participantState;
    }),
  };
}

function buildParticipantState({
  pendingOperations,
  activitySessionsParticipants,
  activitySession,
  journeyParticipant,
  registrationStatus,
  companyOptions,
  journeyActivityPackages,
}: {
  pendingOperations: ActivityPendingOperationDef[];
  activitySessionsParticipants: CMJourneyActivitySessionParticipant[];
  activitySession: CMJourneyActivitySession;
  journeyParticipant: CMJourneyParticipant;
  registrationStatus: ActivitySessionRegistrationStatus;
  companyOptions: CompanyOptions;
  journeyActivityPackages: JourneyActivityPackage[];
}) {
  let activitySessionParticipant = activitySessionsParticipants.find(
    (activitySessionParticipant) =>
      activitySessionParticipant.activitySessionId ===
        activitySession.activitySessionId &&
      activitySessionParticipant.journeyParticipantId ===
        journeyParticipant.journeyParticipantId
  );
  if (!activitySessionParticipant) {
    activitySessionParticipant = {
      activityId: activitySession.activityId,
      activitySessionId: activitySession.activitySessionId,
      journeyParticipantId: journeyParticipant.journeyParticipantId,
      attendanceStatus: 'absent',
      preRegistrationStatus: 'no',
      preRegistrationLastChange: undefined,
      paymentStatus: undefined,
    };
    // find first matching package
    const pack = journeyActivityPackages.find(
      (x) =>
        x.activitiesIds.includes(activitySession.activityId) &&
        x.journeyParticipantsIds.includes(
          journeyParticipant.journeyParticipantId
        )
    );
    if (pack) {
      activitySessionParticipant.packId = pack._id;
      activitySessionParticipant.packActivitiesIds = pack.activitiesIds;
      activitySessionParticipant.packCredits = pack.credits;
      activitySessionParticipant.packType = pack.type;
    }
  }
  const isNotRegistered =
    activitySessionParticipant.attendanceStatus === 'absent' &&
    activitySessionParticipant.preRegistrationStatus === 'no';

  const state: CustomerParticipantStatusAvatarState = buildAvatarState({
    isNotRegistered,
    registrationStatus,
    activitySessionParticipant,
    companyOptions,
  });

  const priceRange: ActivitySessionRegistrationFeePriceRange = ecommerceHelper.getParticipantPriceRange(
    {
      activitySession,
      journeyAge: journeyParticipant.attributes.journeyAge,
    }
  );

  const participantState: CustomerActivityParticipantsDailyActivitParticipantState = {
    journeyParticipant,
    activitySessionParticipant,
    state,
    isNotRegistered,
    isVisible:
      registrationStatus !== 'registration-customer-disabled' ||
      !isNotRegistered,
    priceRange,
    pendingOperations: pendingOperations.filter(
      (x) =>
        // x.id === 'toogle-attendance' &&
        x.activitySessionId === activitySessionParticipant.activitySessionId &&
        x.journeyParticipantId === journeyParticipant.journeyParticipantId
    ),
  };
  return participantState;
}

function buildAvatarState({
  isNotRegistered,
  registrationStatus,
  activitySessionParticipant,
  companyOptions,
}: {
  isNotRegistered: boolean;
  registrationStatus: ActivitySessionRegistrationStatus;
  activitySessionParticipant: CMJourneyActivitySessionParticipant;
  companyOptions: CompanyOptions;
}): CustomerParticipantStatusAvatarState {
  const waitingListEnabled = companyOptions?.waitingList?.enabled;

  if (isNotRegistered) {
    return registrationStatus === 'open'
      ? 'open'
      : waitingListEnabled && registrationStatus === 'registration-full'
      ? 'waiting-list'
      : 'closed';
  }
  if (activitySessionParticipant.attendanceStatus === 'absent-registered') {
    return activitySessionParticipant.preRegistrationLastChange.userType ===
      'customer'
      ? 'absent-customer'
      : 'absent-anim';
  }
  if (
    activitySessionParticipant.preRegistrationStatus === 'yes' &&
    activitySessionParticipant.preRegistrationLastChange &&
    activitySessionParticipant.preRegistrationLastChange.userType === 'customer'
  ) {
    return 'active-customer';
  } else {
    return 'active-anim';
  }
}

function buildRegistrationClosedStatus(
  activitySession: CMJourneyActivitySession,
  {
    appSettings,
    campingPreregistrationEnabled,
    companyOptions,
    isSessionStarted,
    isSessionFinished,
  }: {
    appSettings: AppSettings;
    campingPreregistrationEnabled: boolean;
    companyOptions: CompanyOptions;
    isSessionStarted: boolean;
    isSessionFinished: boolean;
  }
): ActivitySessionRegistrationStatus {
  // NOTE: côté serveur, l'équivalent est implémenté dans activitySessionParticipantRegistrationTester.ensureRegistrationIsAvailable

  const preRegistrationOpen = activityDateTester.isPreRegistrationOpen({
    appSettings,
    companyOptions,
    registrationBeginDate: activitySession.registrationBeginDate,
    registrationBeginTimeId: activitySession.registrationBeginTimeId,
  });

  let registrationStatus: ActivitySessionRegistrationStatus;
  if (!campingPreregistrationEnabled) {
    registrationStatus = 'camping-registration-not-enabled';
  } else if (!activitySession.activitySchedule.registrationCustomerEnabled) {
    registrationStatus = 'registration-customer-disabled';
  } else if (!preRegistrationOpen) {
    registrationStatus = 'registration-not-open-yet';
  } else if (isSessionFinished) {
    registrationStatus = 'activity-finished';
  } else if (
    !activitySession.activitySchedule
      .registrationCustomerEnabledUntilEndOfSession &&
    isSessionStarted
  ) {
    registrationStatus = 'activity-started';
  } else if (activitySession.availableRegistrationsStatus === 'full') {
    registrationStatus = 'registration-full';
  } else if (
    activitySession.openingStatus &&
    activitySession.openingStatus !== 'open'
  ) {
    registrationStatus = 'registration-not-open';
  } else {
    registrationStatus = 'open';
  }
  return registrationStatus;
}
