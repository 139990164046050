import { authenticationClient } from '_01_CORE/app-core/app-security';
import {
  AuthenticationResult,
  BUProfile,
  CMGiftCardEditData,
  CMGiftCardEditDataResponse,
  CMProfile,
  EcommerceBookingGiftPromoCodeValidationResponse,
  EcommerceBookingJourney,
  EcommercePaymentStatus,
  EcommercePurchaseActivitiesOptions,
} from 'lib-common-model';
import { apiClient } from 'lib-web-api-client';

export const ecommerceBookingJourneyApiClient = {
  getJourneyBooking,
  confirmJourneyBookingOptions,
  createBookingCheckoutSession,
  confirmBamboraBookingPayment,
  confirmFreeBooking,
  updateBookingPaymentStatus,
  createBookingGiftCard,
  checkGiftPromoCode,
};

function createBookingGiftCard(
  payload: CMGiftCardEditData
): Promise<CMGiftCardEditDataResponse> {
  return apiClient.put<CMGiftCardEditDataResponse>(
    '/customer-web-mobile-ecommerce-booking-journey/booking/gift-card',
    {
      options: {
        authenticate: true,
        json: {
          payload,
        },
      },
    }
  );
}
async function getJourneyBooking({
  customerJourneyId,
}: {
  customerJourneyId: string;
}): Promise<EcommerceBookingJourney> {
  return apiClient.get<any>(
    `/customer-web-mobile-ecommerce-booking-journey/booking/${customerJourneyId}`,
    {
      options: {
        authenticate: true,
      },
    }
  );
}
async function confirmJourneyBookingOptions({
  customerJourneyId,
  options,
}: {
  customerJourneyId: string;
  options: EcommercePurchaseActivitiesOptions;
}): Promise<EcommerceBookingJourney> {
  return apiClient.put<any>(
    `/customer-web-mobile-ecommerce-booking-journey/booking/${customerJourneyId}/confirm-options`,
    {
      options: {
        authenticate: true,
        json: {
          options,
        },
      },
    }
  );
}
async function checkGiftPromoCode({
  giftPromoCode,
  companyGroupId,
}: {
  giftPromoCode: string;
  companyGroupId: string;
}): Promise<EcommerceBookingGiftPromoCodeValidationResponse> {
  return apiClient.put<any>(
    `/customer-web-mobile-ecommerce-booking-journey/booking/validate-gift-promo-code`,
    {
      options: {
        authenticate: true,
        json: {
          giftPromoCode,
          companyGroupId,
        },
      },
    }
  );
}
async function createBookingCheckoutSession({
  customerJourneyId,
}: {
  customerJourneyId: string;
}): Promise<string> {
  return apiClient.put<any>(
    `/customer-web-mobile-ecommerce-booking-journey/booking/${customerJourneyId}/create-checkout-session`,
    {
      options: {},
    }
  );
}
async function confirmBamboraBookingPayment({
  customerJourneyId,
  sessionToken,
  data,
}: {
  customerJourneyId: string;
  sessionToken: string;
  data: any;
}): Promise<AuthenticationResult<'multi-users-v2', CMProfile | BUProfile>> {
  const response = await apiClient.put<any>(
    `/customer-web-mobile-ecommerce-booking-journey/booking/${customerJourneyId}/confirm-checkout-session-payment/${sessionToken}`,
    {
      options: {
        json: { data },
      },
    }
  );
  return await authenticationClient.updateTokenAndProfile(response);
}
async function confirmFreeBooking({
  customerJourneyId,
}: {
  customerJourneyId: string;
}): Promise<AuthenticationResult<'multi-users-v2', CMProfile | BUProfile>> {
  const response = await apiClient.put<any>(
    `/customer-web-mobile-ecommerce-booking-journey/booking/${customerJourneyId}/confirm-free-booking`,
    {
      options: {},
    }
  );
  return await authenticationClient.updateTokenAndProfile(response);
}
async function updateBookingPaymentStatus({
  customerJourneyId,
  paymentStatus,
}: {
  customerJourneyId: string;
  paymentStatus: Extract<EcommercePaymentStatus, 'pending' | 'accepted'>;
}): Promise<AuthenticationResult<'multi-users-v2', CMProfile | BUProfile>> {
  const response = await apiClient.put<any>(
    `/customer-web-mobile-ecommerce-booking-journey/booking/${customerJourneyId}/update-payment-status`,
    {
      options: {
        json: { paymentStatus },
      },
    }
  );
  return await authenticationClient.updateTokenAndProfile(response);
}
