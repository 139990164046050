import {
  IonCheckbox,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonThumbnail,
} from '@ionic/react';
import {
  COUNTRIES_ARRAY,
  COUNTRIES_MAP,
  LANGUAGES_ARRAY,
  LANGUAGES_MAP,
} from 'lib-common-model';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useAppCacheContext } from '_01_CORE/app-contexts';
import { appBrowser } from '_01_CORE/app-core/app-platform';
import { FormError } from '_01_CORE/_components-core';
import { AppInputIonic } from '_01_CORE/_components-core/AppInput';
import { CustomerAccountEditFormData } from '_02_APP_COMMON/_model';
export const CustomerAccountAccroparkEditForm = ({
  form,
  onSubmit,
}: {
  form: UseFormReturn<CustomerAccountEditFormData, any>;
  onSubmit: () => void;
}) => {
  const { formatMessage: t } = useIntl();
  const { auth } = useAppCacheContext();

  const { formState, control, setValue } = form;
  const errors = formState.errors;

  const account = auth?.customerProfile?.account;

  // NOTE: avant: isAnyAdmin limitait aux pro admin (maintenant, plus de restriction en fonction du profil, peut-être qu'il faudra parser les roles "az")
  const isAnyAdmin =
    auth?.isSuperAdmin || auth?.securityUser?.profile === 'company';

  const openCguLink = () => {
    appBrowser.openUrl({
      platform: 'web',
      url: 'https://www.accropark.se/cgu-mobile',
    });
  };

  return (
    <form onSubmit={onSubmit}>
      {/* Lastname */}
      <Controller
        render={({ field: { value, onChange } }) => (
          <AppInputIonic
            className="mt-2"
            required={true}
            placeholder={t({ id: 'page12.lastName' })}
            value={value}
          >
            <IonInput onIonChange={onChange} />
          </AppInputIonic>
        )}
        control={control}
        name="lastName"
        defaultValue=""
        rules={{
          required: {
            value: true,
            message: t({ id: 'validation.required' }),
          },
        }}
      />
      <FormError error={errors.lastName?.message} />

      {/* Firstname */}
      <Controller
        render={({ field: { value, onChange } }) => (
          <AppInputIonic
            className="mt-2"
            required={isAnyAdmin ? false : true}
            placeholder={t({ id: 'page12.firstName' })}
            value={value}
          >
            <IonInput onIonChange={onChange} />
          </AppInputIonic>
        )}
        control={control}
        name="firstName"
        defaultValue=""
        rules={{
          required: {
            value: isAnyAdmin ? false : true,
            message: t({ id: 'validation.required' }),
          },
        }}
      />
      <FormError error={errors.firstName?.message} />

      {/* Phone 1 */}
      <Controller
        render={({ field: { value, onChange } }) => (
          <AppInputIonic
            className="mt-2"
            required={isAnyAdmin ? false : true}
            placeholder={t({ id: 'page14.phone' }, { number: 1 })}
            value={value}
          >
            <IonInput onIonChange={onChange} type="tel" inputmode="tel" />
          </AppInputIonic>
        )}
        control={control}
        name="phone1"
        defaultValue=""
        rules={{
          pattern: {
            value: /^([\d. \-+]){6,30}$/,
            message: t({ id: 'validation.phone' }),
          },
          required: {
            value: isAnyAdmin ? false : true,
            message: t({ id: 'validation.required' }),
          },
        }}
      />
      <FormError error={errors.phone1?.message} />

      {/* Postal code / city */}
      <Controller
        render={({ field: { value, onChange } }) => (
          <AppInputIonic
            className="mt-2"
            required={isAnyAdmin ? false : true}
            placeholder={t({ id: 'page14.locationArea' })}
            value={value}
          >
            <IonInput onIonChange={onChange} />
          </AppInputIonic>
        )}
        control={control}
        name="locationArea"
        defaultValue=""
        rules={{
          required: {
            value: isAnyAdmin ? false : true,
            message: t({ id: 'validation.required' }),
          },
        }}
      />
      <FormError error={errors.locationArea?.message} />

      {/* Country */}
      <Controller
        render={({ field: { value, onChange } }) => (
          <AppInputIonic
            className="mt-2"
            required={isAnyAdmin ? false : true}
            placeholder={t({ id: 'page12.country' })}
            value={value}
          >
            <div className="flex w-full items-center">
              {COUNTRIES_MAP[value] && (
                <IonThumbnail slot="start" className="w-8 h-6 ml-2">
                  <IonImg
                    class="rounded-full"
                    src={'/' + COUNTRIES_MAP[value].imgUrl}
                  />
                </IonThumbnail>
              )}
              <IonSelect
                interface="action-sheet"
                okText={t({ id: 'common.action.ok' })}
                cancelText={t({ id: 'common.action.cancel' })}
                placeholder={t({ id: 'page12.country' }) + ' *'}
                onIonChange={(e) => {
                  onChange(e);
                  if (COUNTRIES_MAP[e.detail.value]?.defaultLanguage) {
                    setValue(
                      'language',
                      COUNTRIES_MAP[e.detail.value]?.defaultLanguage
                    );
                  }
                }}
                value={value}
              >
                {COUNTRIES_ARRAY.map((country) => (
                  <IonSelectOption
                    key={`country-${country.id}`}
                    value={country.id}
                  >
                    {t({ id: `country.${country.id}` })}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </div>
          </AppInputIonic>
        )}
        control={control}
        name="country"
        defaultValue="SWE"
        rules={{
          required: {
            value: isAnyAdmin ? false : true,
            message: t({ id: 'validation.required' }),
          },
        }}
      />
      <FormError error={errors.country?.message} />

      {/* Language */}
      <Controller
        render={({ field: { value, onChange } }) => (
          <AppInputIonic
            className="mt-2"
            required={isAnyAdmin ? false : true}
            placeholder={t({ id: 'page12.language' })}
            value={value}
          >
            <div className="flex w-full items-center">
              {LANGUAGES_MAP[value] && (
                <IonThumbnail slot="start" className="w-8 h-6 ml-2">
                  <IonImg
                    className="rounded-full"
                    src={LANGUAGES_MAP[value].imgUrl}
                  />
                </IonThumbnail>
              )}
              <IonSelect
                interface="action-sheet"
                okText={t({ id: 'common.action.ok' })}
                cancelText={t({ id: 'common.action.cancel' })}
                placeholder={t({ id: 'page12.language' }) + ' *'}
                onIonChange={onChange}
                value={value}
              >
                {LANGUAGES_ARRAY.map((language) => (
                  <IonSelectOption
                    key={`country-${language.id}`}
                    value={language.id}
                  >
                    {language.labelOriginal}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </div>
          </AppInputIonic>
        )}
        control={control}
        name="language"
        defaultValue="swe"
        rules={{
          required: {
            value: isAnyAdmin ? false : true,
            message: t({ id: 'validation.required' }),
          },
        }}
      />
      <IonItem className="hidden">
        <Controller
          render={({ field: { value, onChange } }) => (
            <IonInput value={value} />
          )}
          control={control}
          name="notificationLanguage"
          defaultValue={'' as any}
        />
      </IonItem>
      <FormError error={errors.language?.message} />

      {/* Email */}
      <Controller
        render={({ field: { value, onChange } }) => (
          <AppInputIonic
            className="mt-2"
            required={isAnyAdmin ? false : true}
            placeholder={t({ id: 'form.label.email' })}
            value={value}
          >
            <IonInput onIonChange={onChange} type="email" inputmode="email" />
          </AppInputIonic>
        )}
        control={control}
        name="email"
        defaultValue=""
        rules={{
          pattern: {
            value: /^(\s?)+(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))(\s?)+$/,
            message: t({ id: 'validation.email' }),
          },
          required: {
            value: isAnyAdmin ? false : true,
            message: t({ id: 'validation.required' }),
          },
        }}
      />
      <FormError error={errors.email?.message} />

      {/* Password */}
      {account.status === 'draft' && (
        <>
          <Controller
            render={({ field: { value, onChange } }) => (
              <AppInputIonic
                className="mt-2"
                required={isAnyAdmin ? false : true}
                placeholder={t({ id: 'form.label.password.create' })}
                value={value}
              >
                <IonInput onIonChange={onChange} />
              </AppInputIonic>
            )}
            control={control}
            name="password"
            defaultValue=""
            rules={{
              required: {
                value: isAnyAdmin ? false : true,
                message: t({ id: 'validation.required' }),
              },
            }}
          />
          <FormError error={errors.password?.message} />
        </>
      )}

      <IonItem className="mt-2">
        <IonLabel>
          {t({ id: 'page14.accept' })}{' '}
          <a className="underline cursor-pointer" onClick={() => openCguLink()}>
            {t({ id: 'page14.terms' })}
          </a>
          *
        </IonLabel>
        <Controller
          render={({ field: { value, onChange } }) => (
            <IonCheckbox
              slot="start"
              value={value as any}
              checked={value}
              onIonChange={(e) => onChange(e.detail.checked)}
            />
          )}
          control={control}
          name="consent"
          defaultValue={false}
          rules={{
            required: {
              value: isAnyAdmin ? false : true,
              message: t({ id: 'validation.required' }),
            },
          }}
        />
      </IonItem>
      <FormError error={errors.consent?.message} />
    </form>
  );
};
