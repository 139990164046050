import { useAppRouter, useAppToasts } from '_01_CORE/_hooks';
import { useJourneyEnabledActions } from '_01_CORE/app-contexts';
import { authApiClient } from '_01_CORE/app-core/app-api';
import { useIsNetworkConnected } from '_01_CORE/app-core/app-platform';
import { CMAuthUserLoginTestResponse } from 'lib-common-model';
import { useCallback } from 'react';
import { AuthMultiLoginAnimLivePageViewState } from '../_model';
import { envAccountSwitcher } from '../_services';
import { AuthMultiLoginAnimLivePageBaseLocalState } from '../useAuthMultiLoginAnimLivePageLocalState.hook';
import { AuthMultiLoginAction_AuthenticateByActivationToken } from './useAuthMultiLoginAction_AuthenticateByActivationToken.hook';
import { AuthMultiLoginAction_RedirectToJourneyCreateDates } from './useAuthMultiLoginAction_RedirectToJourneyCreateDates.hook';
import { AuthMultiLoginAction_StartExtJourneyActivation } from './useAuthMultiLoginAction_StartExtJourneyActivation.hook';

export function useAuthMultiLoginAction_TestEmail(
  baseLocalState: AuthMultiLoginAnimLivePageBaseLocalState,
  actions: {
    startExtJourneyActivation: AuthMultiLoginAction_StartExtJourneyActivation;
    redirectToJourneyCreateDates: AuthMultiLoginAction_RedirectToJourneyCreateDates;
    authenticateByActivationToken: AuthMultiLoginAction_AuthenticateByActivationToken;
  }
) {
  const { pushError, pushSuccess } = useAppToasts();
  const appRouter = useAppRouter();
  const isNetworkConnected = useIsNetworkConnected();
  const { isCreateJourneyEnabled } = useJourneyEnabledActions({});

  const { viewState, setViewState } = baseLocalState;

  const processResponse = useCallback(
    async ({ testResult }: { testResult: CMAuthUserLoginTestResponse }) => {
      const updatedViewState: AuthMultiLoginAnimLivePageViewState = {
        operationInProgress: undefined,
        ...viewState,
        promptEmail: {
          ...viewState.promptEmail,
          testResult,
          defaultEmail: testResult.login,
          isRegistrationNotPossibleWithoutBooking: false,
        },
      };

      const isAccountLoginSameAsPMSJourneyEmail =
        testResult.login ===
        updatedViewState.journeyInfo?.fetchedJourney?.fakeCustomerAccount
          ?.email;
      if (testResult?.exists) {
        // le compte existe
        if (viewState.contextAction === 'register') {
          updatedViewState.promptEmail.defaultEmailAccountExists = true;
          updatedViewState.promptEmail.defaultEmail = testResult.login;
        }

        if (
          viewState.journeyInfo?.checkMethod === 'token' &&
          isAccountLoginSameAsPMSJourneyEmail
        ) {
          // auto login by token
          return await actions.authenticateByActivationToken({
            email: testResult.login,
            activationToken: viewState.checkToken?.token,
          });
        } else {
          // prompt password
          updatedViewState.promptEmail.emailReadOnly = true;
          updatedViewState.promptEmail.showPassword = true;
        }
      } else if (!testResult?.exists) {
        // nouveau compte
        const fetchedJourney = viewState.journeyInfo?.fetchedJourney;

        if (viewState.contextAction === 'activate' && fetchedJourney) {
          // création de compte avec activation de séjour
          actions.startExtJourneyActivation({
            customerAccount: undefined,
            customerAccountMembers: undefined,
            fetchedJourney,
            emailAddress: testResult?.login,
          });
          return;
        } else if (viewState.contextAction === 'register') {
          const extJourneysToActivate = testResult.extJourneysToActivate;
          if (extJourneysToActivate?.length > 0) {
            // séjour(s) détecté(s): on affiche la liste des séjours à activer
            updatedViewState.mode = 'new-account-journeys-to-activate';
          } else {
            // pas de séjour PMS détecté

            if (isCreateJourneyEnabled) {
              const companyReference =
                viewState.extra.companyValidReferenceCode; 
              if (companyReference) {
                // création d'un nouveau compte+séjour NON PMS
                actions.redirectToJourneyCreateDates({
                  companyReference,
                  emailAddress: testResult.login,
                });
                return;
              } else {
                // on demande le code séjour
                updatedViewState.mode = 'prompt-company-code';
              }
            } else {
              // séjours NON PMS non activés pour le vacancier
              updatedViewState.promptEmail = {
                ...updatedViewState.promptEmail,
                isRegistrationNotPossibleWithoutBooking: true,
              };
            }
          }
        }
      }
      setViewState(updatedViewState);
    },
    [actions, isCreateJourneyEnabled, setViewState, viewState]
  );

  const testEmail = useCallback(
    async ({ emailAddress }: { emailAddress: string }) => {
      if (!isNetworkConnected()) {
        return;
      }
      setViewState({
        operationInProgress: {
          type: 'loading',
          message: 'Chargement en cours',
        },
        ...viewState,
      });

      try {
        envAccountSwitcher.switchEnvIfSpecialAccount({ emailAddress });
        const testResult: CMAuthUserLoginTestResponse = await authApiClient.testEmail(
          {
            email: emailAddress,
          }
        );

        await processResponse({
          testResult,
        });
      } catch (err) {
        setViewState({
          operationInProgress: undefined,
          ...viewState,
        });
      } finally {
        //
      }
    },
    [isNetworkConnected, processResponse, setViewState, viewState]
  );
  return testEmail;
}

export type AuthMultiLoginAction_TestEmail = ReturnType<
  typeof useAuthMultiLoginAction_TestEmail
>;
