import { useEffect } from 'react';

/**
 * Hook that alerts clicks outside of the passed ref
 */
export function useOutsideClick({
  ref,
  callback,
}: {
  ref: any;
  callback?: () => void;
}) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: Event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback?.();
        unbind();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);

    const unbind = () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
    return unbind;
  }, [callback, ref]);
}
