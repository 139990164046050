import { AppRichTextContent, CompanyOptionsTheme } from 'lib-common-model';
import React from 'react';
import { AppRichTextAreaLeafs } from './AppRichTextAreaLeafs';
import { AppRichTextSlateElement } from './AppRichTextSlateElement';
export const AppRichTextArea = ({
  content,
  theme,
  className = '',
}: {
  content: AppRichTextContent;
  theme: CompanyOptionsTheme;
  className?: string;
}) => {
  return !content ? null : (
    <div className={className}>
      {(content?.elements ?? []).map((element, i) => (
        <AppRichTextSlateElement key={i} element={element} theme={theme}>
          <AppRichTextAreaLeafs element={element} theme={theme} />
        </AppRichTextSlateElement>
      ))}
    </div>
  );
};
