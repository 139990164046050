import { SelectOption } from '../../../form';
import { Language } from './Language.model';
import { LANGUAGES_ARRAY } from './LANGUAGES_ARRAY.const';
// @see https://fr.wikipedia.org/wiki/Liste_des_codes_ISO_639-2

export const LANGUAGES_OPTIONS: SelectOption[] = LANGUAGES_ARRAY.map(
  (l: Language) => {
    return {
      id: l.id,
      imageUrl: l.imgUrl,
      label: l.labelOriginal,
    };
  }
).filter((x) => x.id !== '@@@');
