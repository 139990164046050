import { useCallback } from 'react';
import { useToasts } from '../providers';

export function useAppToasts() {
  const push = useToasts();

  const pushToast = useCallback(
    (color: string, message: string, duration = 2500) => {
      push({
        color,
        duration,
        message,
      });
    },
    [push]
  );

  const pushSuccess = useCallback(
    (message: string, duration = 2500) => {
      pushToast('success', message, duration);
    },
    [pushToast]
  );

  const pushError = useCallback(
    (message: string, duration = 2500) => {
      pushToast('danger', message, duration);
    },
    [pushToast]
  );

  return {
    push: pushToast,
    pushError,
    pushSuccess,
  };
}
