import { useMemo, useState } from 'react';
import { dateServiceCore } from '../../../../../stories/services';
import { AppHackableCalendarVM } from '../model';
import {
  AppHackableCalendarYearsPanelVM,
  AppHackableCalendarYearsPanelVMYear,
} from './model';

export function useAppHackableCalendarYearsPanelVM(
  props: AppHackableCalendarVM
): AppHackableCalendarYearsPanelVM {
  const initialLastYear = useMemo(() => {
    let initialLastYear = dateServiceCore.addYear(props.viewDate, 10);
    if (props.maxDate && props.maxDate.getTime() < initialLastYear.getTime()) {
      initialLastYear = new Date(props.maxDate);
    }
    initialLastYear = dateServiceCore.addYear(
      initialLastYear,
      5 - (props.viewDate.getUTCFullYear() % 5)
    );
    return initialLastYear;
  }, [props.maxDate, props.viewDate]);

  const [lastYear, setLastYear] = useState(initialLastYear);

  return useMemo(() => {
    const todayCurrentYear = new Date().getUTCFullYear();

    const years: AppHackableCalendarYearsPanelVMYear[] = [];

    const firstYearDate = dateServiceCore.addYear(lastYear, -30);

    for (let i = 0; i < 30; i++) {
      const yearDate = dateServiceCore.addYear(firstYearDate, i);

      const yearNumber = yearDate.getUTCFullYear();
      const year: AppHackableCalendarYearsPanelVMYear = {
        date: yearDate,
        year: yearNumber,
        isCurrentViewYear:
          yearDate.getUTCFullYear() === props.viewDate.getUTCFullYear(),
        isCurrentTodayYear: yearDate.getUTCFullYear() === todayCurrentYear,
        isSelectableYear: isSelectableYear({
          yearNumber,
          props,
        }),
      };
      years.push(year);
    }
    const vm: AppHackableCalendarYearsPanelVM = {
      years,
      lastYear,
      setLastYear,
    };
    return vm;
  }, [lastYear, props]);
}
function isSelectableYear({
  yearNumber,
  props,
}: {
  yearNumber: number;
  props: AppHackableCalendarVM;
}) {
  if (props.minDate && props.minDate.getFullYear() > yearNumber) {
    return false;
  }
  if (props.maxDate && props.maxDate.getFullYear() < yearNumber) {
    return false;
  }
  return true;
}
