import { CMResto } from 'lib-common-model/entities/app-customer-mobile-web/CMResto';
import { useAppCacheContext } from '../../hooks';

export function useCompanyRestos({
  companyReference,
}: {
  companyReference: string;
}): CMResto[] {
  const { companyRestos } = useAppCacheContext();

  return companyRestos[companyReference];
}
