import { IonItemGroup } from '@ionic/react';
import {
  AppPage,
  HeaderToolbar,
  PageContainer,
  PageTitle,
} from '_01_CORE/_common-layout';
import {
  AppButtonUniverse,
  AppIcons,
  JourneyCard,
  dateTester,
} from '_01_CORE/_components-core';
import { useAppRouter } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import {
  useAppCacheContext,
  useJourneyEnabledActions,
} from '_01_CORE/app-contexts';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import { useNavigateToNewJourneyPage } from '_02_APP_COMMON/JOU-journey/_common';
import { CMJourney } from 'lib-common-model';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { JourneysListNewJourneysToActivatePanel } from './JourneysListNewJourneysToActivatePanel';

export const JourneysListAnimLivePage = () => {
  const appRouter = useAppRouter();
  const appId = appWebConfig().appId;
  const { formatMessage: t } = useIntl();
  const appCacheContext = useAppCacheContext();
  const { auth } = appCacheContext;
  const customerAccount = auth?.customerProfile?.account;
  const customerAccountMembers = auth?.customerProfile?.members;
  const userProfile = auth?.customerProfile;
  const journeys: CMJourney[] = useMemo(() => {
    if (!userProfile?.journeys) {
      return [];
    }
    return userProfile?.journeys.sort((a, b) =>
      a.beginDateUTC > b.beginDateUTC
        ? -1
        : a.beginDateUTC < b.beginDateUTC
        ? 1
        : 0
    );
  }, [userProfile?.journeys]);

  const { currentOrFutureJourneys, pastJourneys } = useMemo(() => {
    return journeys.reduce(
      (acc, j) => {
        if (dateTester.isBefore(j.endDateExclusiveUTC, new Date())) {
          acc.pastJourneys.push(j);
        } else {
          acc.currentOrFutureJourneys.push(j);
        }
        return acc;
      },
      {
        currentOrFutureJourneys: [],
        pastJourneys: [],
      } as {
        currentOrFutureJourneys: CMJourney[];
        pastJourneys: CMJourney[];
      }
    );
  }, [journeys]);

  const {
    isCreateJourneyEnabled,
    isCreateJourneyByActivationKeyEnabled,
  } = useJourneyEnabledActions({});

  const showCreateJourneyButton = useMemo(() => {
    // allow to create journey if no journey is currently in draft
    return (
      (isCreateJourneyEnabled || isCreateJourneyByActivationKeyEnabled) &&
      journeys.find((j) => j.status !== 'validated') === undefined
    );
  }, [isCreateJourneyByActivationKeyEnabled, isCreateJourneyEnabled, journeys]);

  const navigateToNewJourneyPage = useNavigateToNewJourneyPage({
    context: 'JourneysListAnimLivePage',
  });

  const hasJourneysToActivate = userProfile?.extJourneysToActivate?.length > 0;

  return (
    <AppPage tabId="bookings">
      <HeaderToolbar />
      <PageContainer className="text-center bg-gray-50" spacing="normal">
        <PageTitle
          title={t({ id: `page04.label.mes-sejours` })}
          backLink={appRoutesBuilder.getRedirectToDefaultUrl()}
        />
        <div className="inline-flex flex-col gap-y-8 w-full max-w-md">
          {hasJourneysToActivate && (
            <JourneysListNewJourneysToActivatePanel
              extJourneysToActivate={userProfile?.extJourneysToActivate}
            />
          )}
          {journeys.length === 0 && (
            <p>{t({ id: `page04.noJourneys.${appId}` })}</p>
          )}
          {currentOrFutureJourneys.length > 0 && (
            <>
              {hasJourneysToActivate && (
                <h3 className="my-2 text-center font-bold text-sm sm:text-base text-gray-600 uppercase">
                  Séjours existants
                </h3>
              )}
              <IonItemGroup>
                {currentOrFutureJourneys.reverse().map((j, i) => (
                  <JourneyCard
                    showActionOpenJourney={true}
                    showActionEditJourney={true}
                    showActionDeleteJourney={true}
                    key={i}
                    journey={j}
                    redirectOnDelete={false}
                  />
                ))}
              </IonItemGroup>
            </>
          )}
          {showCreateJourneyButton &&
            (!hasJourneysToActivate ? (
              <div className="flex flex-col gap-4">
                {isCreateJourneyByActivationKeyEnabled && (
                  <AppButtonUniverse
                    className={`w-full uppercase`}
                    isFullWidth={true}
                    style={'primary'}
                    fontWeightClassName="font-bold"
                    href={appRoutesBuilder.getJourneyCreateByActivationKeyUrl()}
                    icon={AppIcons.code}
                  >
                    {t({ id: 'page04.button.activation-key.anim-live' })}
                  </AppButtonUniverse>
                )}

                {isCreateJourneyEnabled && (
                  <AppButtonUniverse
                    className={`uppercase font-bold`}
                    style={'primary'}
                    isFullWidth={true}
                    label={t({ id: `page04.button.add-journey.${appId}` })}
                    icon={AppIcons.actionAdd}
                    onClick={navigateToNewJourneyPage}
                  />
                )}
              </div>
            ) : (
              <div className="flex flex-col gap-4">
                {isCreateJourneyByActivationKeyEnabled && (
                  <div
                    className={`underline text-gray-600 font-normal text-right`}
                  >
                    <span
                      className=" cursor-pointer"
                      onClick={() =>
                        appRouter.navigate(
                          appRoutesBuilder.getJourneyCreateByActivationKeyUrl(),
                          {
                            cause: 'click-from-journeys-page',
                          }
                        )
                      }
                    >
                      {t({ id: 'page04.button.activation-key.anim-live' })}
                    </span>
                  </div>
                )}
                {isCreateJourneyEnabled && (
                  <div
                    className={`underline text-gray-600 font-normal text-right`}
                  >
                    <span
                      className=" cursor-pointer"
                      onClick={navigateToNewJourneyPage}
                    >
                      {t({ id: 'page04.button.add-other-journey.anim-live' })}
                    </span>
                  </div>
                )}
              </div>
            ))}
          {pastJourneys.length > 0 && (
            <>
              <h3 className="my-2 text-center font-bold text-sm sm:text-base text-gray-600 uppercase">
                {t({ id: `page04.label.mes-sejours-passes` })}
              </h3>
              <IonItemGroup>
                {pastJourneys.reverse().map((j, i) => (
                  <JourneyCard
                    showActionOpenJourney={true}
                    showActionEditJourney={true}
                    showActionDeleteJourney={true}
                    key={i}
                    journey={j}
                    redirectOnDelete={false}
                  />
                ))}
              </IonItemGroup>
            </>
          )}
        </div>
        {auth?.customerProfile?.account?.deletionRequestedAt && (
          <div className="my-5 font-bold text-red-500">
            {t({ id: 'menu.delete-account.success' })}
          </div>
        )}
      </PageContainer>
    </AppPage>
  );
};
