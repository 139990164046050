import { dateTransformer } from '_01_CORE/_components-core';
import { useAppRouter } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import { useAppImageUrl } from '_01_CORE/app-core/app-security/useAppImageUrl.hook';
import { CustomerJourneyActivitiesPageUrlParams } from '_02_APP_COMMON/ACT-activity';
import {
  APP_CURRENCIES_MAP,
  CMShoppingCartResumeCartItem,
} from 'lib-common-model';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

export const AnimLiveShoppingCartTableItemRow = ({
  item,
}: {
  item: CMShoppingCartResumeCartItem;
}) => {
  const { formatMessage: t } = useIntl();

  const priceRange = item?.activityDetails?.priceRange;

  const currencySymbol = APP_CURRENCIES_MAP[item?.currency]?.symbol;

  const totalPrice = useMemo(() => {
    return item.quantity * item.unitPrice;
  }, [item.quantity, item.unitPrice]);

  const activityLogoSrc = useAppImageUrl(item.activityDetails?.activity.logo, {
    defaultImage: 'activity',
  });

  const activityTranslation = item.activityTranslation;

  const activityDialogUrl = useMemo(() => {
    if (
      item.customerJourneyId &&
      item.activityId &&
      item.activitySessionId &&
      item.activityDetails?.activitySession?.beginDateTime
    ) {
      const params: CustomerJourneyActivitiesPageUrlParams = {
        category: undefined, // la catégorie sera automatiquement switchée en arrivant sur la page, si nécessaire
        selectedDate: item.activityDetails?.activitySession?.beginDateTime,
        focusActivitySessionId: item.activitySessionId,
        focusActivityId: item.activityId,
        focusActivityOpenDialog: true,
      };

      const url = appRoutesBuilder.getJourneyActivitiesWithParamsUrl({
        customerJourneyId: item.customerJourneyId,
        params,
      });
      return url;
    }
  }, [
    item.activityDetails?.activitySession?.beginDateTime,
    item.activityId,
    item.activitySessionId,
    item.customerJourneyId,
  ]);
  const appRouter = useAppRouter();

  const peopleQuantityPriceLabelKey =
    item.activityDetails?.activitySession?.registration?.unitType === 'product'
      ? 'accropark.ecommerce.booking-card.quantity-price-unit-type-product'
      : 'accropark.ecommerce.booking-card.quantity-price-unit-type-participant';

  return (
    item && (
      <div
        className="flex space-x-2 py-1 px-2 items-center border-b"
        onClick={(e) => {
          if (item.itemType === 'activity' && activityDialogUrl) {
            appRouter.navigate(activityDialogUrl, {
              cause: 'click-shopping-card-item',
            });
            e.stopPropagation();
          }
        }}
      >
        {item.itemType === 'activity' && (
          <>
            <img src={activityLogoSrc} className="w-12 h-12" />
            <div className="flex-1">
              <div className="flex flex-wrap gap-x-1 justify-between items-end">
                <div className="flex gap-x-1 items-end">
                  <div className="font-bold">{activityTranslation.name}</div>
                  {priceRange?.minAge != null && priceRange?.maxAge != null && (
                    <div className="text-xs">
                      (
                      {t(
                        { id: 'page12.yearsOld' },
                        {
                          age: `${priceRange?.minAge}-${priceRange?.maxAge}`,
                        }
                      )}
                      )
                    </div>
                  )}
                </div>
                <div className="text-xs text-gray-400">
                  {dateTransformer.format(
                    item.activityDetails?.activitySession.beginDateTime,
                    'dd/MM/yyyy'
                  )}{' '}
                  {dateTransformer.formatTime(
                    item.activityDetails?.activitySession.beginDateTime
                  )}
                </div>
              </div>
              <div className="font-normal text-xs">
                {activityTranslation.subTitle}
                {item.activityDetails?.activitySession?.name}
              </div>
              <div className="flex gap-1 justify-between items-end">
                <div className="flex gap-1 items-end text-xs">
                  <div>
                    {t(
                      { id: peopleQuantityPriceLabelKey },
                      {
                        qty: item.quantity,
                        price: `${parseFloat(
                          (item.unitPrice as unknown) as string
                        ).toFixed(2)} ${currencySymbol}`,
                      }
                    )}
                  </div>
                </div>
                <div className="font-bold self-end">
                  {totalPrice.toFixed(2)} {currencySymbol}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    )
  );
};
