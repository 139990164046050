import { isPlatform } from '@ionic/react';
export const mapUrlBuilder = {
  getMapUrl,
};

function getMapUrl(direction: string) {
  const address = encodeURI(direction);
  if (isPlatform('desktop')) {
    return `https://maps.google.com/?q=${address}`;
  } else if (isPlatform('android')) {
    return `https://maps.google.com/?q=${address}`;
  } else if (isPlatform('ios')) {
    return `http://maps.apple.com/?q=${address}`;
  } else {
    return `geo:0,0?q=${address}`;
  }
}
