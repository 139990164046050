import * as Sentry from '@sentry/react';
import { authenticationClient } from '_01_CORE/app-core/app-security';
import { JourneyChooseDatesAnimLiveFormData } from '_04_ANIM_LIVE/JOU-journey/JOU-10-journey-choose-dates';
import {
  AuthUserProfile,
  CMExtJourneyFinderResult,
  CMJourneyCheckinInventoryPayload,
  CMJourneyEditParticipant,
  CMJourneyIssuePayload,
  CustomerAccountCreateOperation,
  CustomerAccountCreatePayload,
  CustomerMobileAuthenticationResult,
  ResponsiveImage,
} from 'lib-common-model';
import { ApiClientRequestOverrideConfig, apiClient } from 'lib-web-api-client';
import { appLogger } from 'lib-web-logger';

const ALLOWED_USER_PROFILES: AuthUserProfile[] = [
  'customer',
  'company',
  'super-admin',
  'temporary-token',
];

export const customerJourneyApiClient = {
  uploadImage,
  uploadImageBlob,
  createOrUpdateCustomerAccountJourney,
  deleteJourney,
  submitCheckInInventory,
  submitCompanyIssue,
  updateJourneyDatesAndPlaceNumber,
  cancelJourneyBookingLastStep,
  deleteParticipant,
  createParticipant,
  updateParticipant,
  fetchExternalJourneyDetailsFromActivationToken,
  fetchExternalJourneyDetailsFromActivationKey,
  reSendExternalJourneyActivationMail,
};

async function fetchExternalJourneyDetailsFromActivationToken({
  activationToken,
  companyReference,
}: {
  activationToken: string;
  companyReference: string;
}): Promise<CMExtJourneyFinderResult> {
  return await apiClient.put<any>(
    `/customer-web-mobile-journeys/ext-journey-activation-token`,
    {
      options: {
        authenticate: false,
        json: {
          activationToken,
          companyReference,
        },
      },
    }
  );
}
async function fetchExternalJourneyDetailsFromActivationKey({
  activationKey,
}: {
  activationKey: string;
}): Promise<CMExtJourneyFinderResult> {
  return await apiClient.put<any>(
    `/customer-web-mobile-journeys/ext-journey-activation-key`,
    {
      options: {
        authenticate: false,
        json: {
          activationKey,
        },
      },
    }
  );
}
async function reSendExternalJourneyActivationMail({
  extCustomerJourneyBookingGroupId,
  emailAddress,
}: {
  extCustomerJourneyBookingGroupId: string;
  emailAddress?: string;
}): Promise<CMExtJourneyFinderResult> {
  return await apiClient.put<any>(
    `/customer-web-mobile-journeys/send-activation-mail-customer`,
    {
      options: {
        authenticate: false,
        json: {
          extCustomerJourneyBookingGroupId,
          emailAddress,
        },
      },
    }
  );
}
async function uploadImage(
  webPath: string,
  { url }: { url: string }
): Promise<ResponsiveImage> {
  const blobData: Blob = await fetch(webPath).then((r) => r.blob());
  const formData: FormData = new FormData();
  // cameraPhoto
  formData.append('file', blobData);
  return await apiClient
    .post<any>(url, {
      options: {
        authenticate: true,
        body: formData,
      },
    })
    .catch(() => undefined); // ignore errors (git is not supported)
}
async function uploadImageBlob(
  blobData: Blob,
  { url }: { url: string }
): Promise<ResponsiveImage> {
  // const blobData = await fetch(webPath).then((r) => r.blob());
  const formData: FormData = new FormData();
  // cameraPhoto
  formData.append('file', blobData);
  return await apiClient.post<any>(url, {
    options: {
      authenticate: true,
      body: formData,
      alwaysThrowError: true,
    },
  });
}

async function cancelJourneyBookingLastStep({
  customerJourneyId,
}: {
  customerJourneyId: string;
}): Promise<CustomerMobileAuthenticationResult> {
  const response = await apiClient.put<CustomerMobileAuthenticationResult>(
    `/customer-web-mobile-journeys/cancel-journey-booking-last-step`,
    {
      options: {
        authenticate: true,
        json: {
          customerJourneyId,
        },
      },
    }
  );
  await authenticationClient.updateTokenAndProfile(response);
  return response;
}
async function deleteJourney({
  customerJourneyId,
}: {
  customerJourneyId: string;
}): Promise<void> {
  const response = await apiClient.delete_<CustomerMobileAuthenticationResult>(
    `/customer-web-mobile-journeys/${customerJourneyId}`,
    {
      options: {
        authenticate: true,
      },
    }
  );
  await authenticationClient.updateTokenAndProfile(response);
}

async function createOrUpdateCustomerAccountJourney(
  payload: CustomerAccountCreatePayload,
  {
    overrideConfig,
  }: {
    overrideConfig?: ApiClientRequestOverrideConfig;
  } = {}
): Promise<{
  result: 'success' | 'unexpected-error' | 'email-already-exists';
  customerJourneyId?: string;
  err?: Error;
  errStatus?: number;
  errMessage?: string;
}> {
  const operation: CustomerAccountCreateOperation = {
    id: 'customer.account.create',
    payload,
  };

  // send remote operation

  try {
    const response: {
      token: string;
      customerJourneyId: string;
    } = await createOrUpdateCustomerAccountJourneyCore(payload, {
      overrideConfig,
    });

    console.log(`Account creation success`);

    await authenticationClient.authenticateByToken(
      response.token,
      ALLOWED_USER_PROFILES,
      { context: 'createOrUpdateCustomerAccountJourney', overrideConfig }
    );
    return {
      result: 'success',
      customerJourneyId: response.customerJourneyId,
    };
  } catch (err) {
    if ((err as any)?.response?.status === 409) {
      return {
        result: 'email-already-exists',
      };
    } else {
      console.log('Error creating customer account');
      console.log(operation);
      Sentry.addBreadcrumb({
        level: Sentry.Severity.Info,
        message: 'Error creating customer account - operation breadcrumb',
        data: { operation: JSON.stringify(operation), err },
      });
      appLogger.error(`Error creating customer account`);
      return {
        result: 'unexpected-error',
        err: err as any,
        errStatus: (err as any)?.response?.status,
        errMessage: (err as any)?.json?.message ?? (err as any)?.message,
      };
    }
  }
}

async function createOrUpdateCustomerAccountJourneyCore(
  payload: CustomerAccountCreatePayload,
  {
    overrideConfig,
  }: {
    overrideConfig?: ApiClientRequestOverrideConfig;
  } = {}
): Promise<{
  token: string;
  customerJourneyId: string;
}> {
  console.log(`START createOrUpdateCustomerAccountJourneyCore`);

  try {
    const operation: CustomerAccountCreateOperation = {
      id: 'customer.account.create',
      payload,
    };
    // send remote operation

    const response = await apiClient.post<{
      token: string;
      customerJourneyId: string;
    }>('/operations/single/customer-account-create', {
      options: {
        authenticate: true,
        json: {
          operation,
        },
      },
      overrideConfig,
    });

    console.log('Account creation success');

    return response;
  } catch (err) {
    if ((err as any)?.response?.status === 409) {
      throw err;
    } else {
      console.log(`Error creating customer account`);
      Sentry.addBreadcrumb({
        level: Sentry.Severity.Warning,
        message: `Error creating customer account breadcrumb`,
      });
      throw err;
    }
  }
}

async function createParticipant({
  customerJourneyId,
  participant,
}: {
  customerJourneyId: string;
  participant: CMJourneyEditParticipant;
}): Promise<{
  result: 'success' | 'unexpected-error';
}> {
  try {
    const response = await apiClient.post<CustomerMobileAuthenticationResult>(
      `/customer-web-mobile-journeys/${customerJourneyId}/create-participant`,
      {
        options: {
          authenticate: true,
          json: {
            participant,
          },
        },
      }
    );
    await authenticationClient.authenticateByToken(
      response.token,
      ALLOWED_USER_PROFILES,
      { context: 'createParticipant' }
    );

    return {
      result: 'success',
    };
  } catch (err) {
    appLogger.error('Unexpected error creating customer account', {
      err: err as any,
    });
    return {
      result: 'unexpected-error',
    };
  }
}
async function deleteParticipant({
  customerJourneyId,
  journeyParticipantId,
}: {
  customerJourneyId: string;
  journeyParticipantId: string;
}): Promise<{
  result: 'success' | 'unexpected-error';
}> {
  try {
    const response = await apiClient.delete_<CustomerMobileAuthenticationResult>(
      `/customer-web-mobile-journeys/${customerJourneyId}/delete-participant/${journeyParticipantId}`,
      {
        options: {
          authenticate: true,
        },
      }
    );
    await authenticationClient.authenticateByToken(
      response.token,
      ALLOWED_USER_PROFILES,
      { context: 'updateParticipant' }
    );

    return {
      result: 'success',
    };
  } catch (err) {
    appLogger.error('Unexpected error deleting customer account', {
      err: err as any,
    });
    return {
      result: 'unexpected-error',
    };
  }
}

async function updateParticipant({
  customerJourneyId,
  participant,
}: {
  customerJourneyId: string;
  participant: CMJourneyEditParticipant;
}): Promise<{
  result: 'success' | 'unexpected-error';
}> {
  try {
    const response = await apiClient.put<CustomerMobileAuthenticationResult>(
      `/customer-web-mobile-journeys/${customerJourneyId}/update-participant/${participant.journeyParticipantId}`,
      {
        options: {
          authenticate: true,
          json: {
            participant,
          },
        },
      }
    );
    await authenticationClient.authenticateByToken(
      response.token,
      ALLOWED_USER_PROFILES,
      { context: 'updateParticipant' }
    );

    return {
      result: 'success',
    };
  } catch (err) {
    appLogger.error('Unexpected error updating customer account', {
      err: err as any,
    });
    return {
      result: 'unexpected-error',
    };
  }
}

async function updateJourneyDatesAndPlaceNumber({
  customerJourneyId,
  data,
}: {
  customerJourneyId: string;
  data: Partial<JourneyChooseDatesAnimLiveFormData>;
}): Promise<{
  result: 'success' | 'unexpected-error';
}> {
  try {
    const response = await apiClient.put<CustomerMobileAuthenticationResult>(
      `/customer-web-mobile-journeys/${customerJourneyId}/update-dates`,
      {
        options: {
          authenticate: true,
          json: data,
          alwaysThrowError: true,
        },
      }
    );
    await authenticationClient.authenticateByToken(
      response.token,
      ALLOWED_USER_PROFILES,
      { context: 'updateParticipant' }
    );

    return {
      result: 'success',
    };
  } catch (err) {
    appLogger.error('Unexpected error updating customer account', {
      err: err as any,
    });
    return {
      result: 'unexpected-error',
    };
  }
}

async function submitCheckInInventory({
  customerJourneyId,
  payload,
}: {
  customerJourneyId: string;
  payload: CMJourneyCheckinInventoryPayload;
}): Promise<{
  result: 'success' | 'unexpected-error';
}> {
  try {
    const response = await apiClient.post<CustomerMobileAuthenticationResult>(
      `/customer-web/company-issues/${customerJourneyId}/check-in-inventory`,
      {
        options: {
          authenticate: true,
          json: payload,
        },
      }
    );
    await authenticationClient.authenticateByToken(
      response.token,
      ALLOWED_USER_PROFILES,
      { context: 'checkin-inventory' }
    );

    return {
      result: 'success',
    };
  } catch (err) {
    appLogger.error('Unexpected error submiting check-in inventory', {
      err: err as any,
    });
    return {
      result: 'unexpected-error',
    };
  }
}

async function submitCompanyIssue({
  customerJourneyId,
  payload,
}: {
  customerJourneyId: string;
  payload: CMJourneyIssuePayload;
}): Promise<{
  result: 'success' | 'unexpected-error';
}> {
  try {
    const response = await apiClient.post<CustomerMobileAuthenticationResult>(
      `/customer-web/company-issues/${customerJourneyId}/create-journey-issue`,
      {
        options: {
          authenticate: true,
          json: payload,
        },
      }
    );
    await authenticationClient.authenticateByToken(
      response.token,
      ALLOWED_USER_PROFILES,
      { context: 'checkin-inventory' }
    );

    return {
      result: 'success',
    };
  } catch (err) {
    appLogger.error('Unexpected error submiting check-in inventory', {
      err: err as any,
    });
    return {
      result: 'unexpected-error',
    };
  }
}
