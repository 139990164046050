import {
  IonButton,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonList,
  IonSelect,
  IonSelectOption,
  IonText,
  IonThumbnail,
  IonToggle,
} from '@ionic/react';
import {
  AppPage,
  FooterActions,
  HeaderToolbar,
  PageContainer,
} from '_01_CORE/_common-layout';
import {
  AppFormLabel,
  FormError,
  dataGenerator,
  fakeDataGenerator,
  useAppModalContext,
} from '_01_CORE/_components-core';
import { AppInputIonic } from '_01_CORE/_components-core/AppInput';
import { AppInputPhotoRHFControl } from '_01_CORE/_components-core/AppInputPhoto/AppInputPhotoRHFControl';
import {
  useAppRouter,
  useAppToasts,
  useJourneyCancellation,
  useMergeState,
} from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import { journeyHelper } from '_01_CORE/_services/journeyHelper.service';
import {
  useAppCacheContext,
  useCompany,
  useJourneyEnabledActions,
} from '_01_CORE/app-contexts';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import { usePhotoFromDevice } from '_01_CORE/app-core/app-platform';
import { differenceInYears } from 'date-fns';
import { build, trash } from 'ionicons/icons';
import {
  CMJourneyEditParticipant,
  COUNTRIES_ARRAY,
  COUNTRIES_MAP,
  CompanyExitPermitConfig,
  LANGUAGES_ARRAY,
  LANGUAGES_MAP,
} from 'lib-common-model';
import { appLogger } from 'lib-web-logger';
import { useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { AppInputDatePickerIonics } from 'stories/components/04-form';
import { CustomerGenderRadioGroup } from '../_common';
import { JourneyParticipantEditFormData } from './JourneyParticipantEditFormData.type';
import { ParticipantAgeLabel } from './ParticipantAgeLabel';
import { useJourneyParticipantEditInitialState } from './useJourneyParticipantEditInitialState.hook';

export const JourneyCreateParticipantEditAnimLivePage = () => {
  const appRouter = useAppRouter();
  const { formatMessage: t } = useIntl();

  const appCacheContext = useAppCacheContext();
  const { openModal, closeModal } = useAppModalContext();
  const { journeyEditData, dispatchCacheContextAction } = appCacheContext;
  const {
    companyReference,
    participantIndex: participantIndexString,
  } = useParams<{ companyReference: string; participantIndex: string }>();
  const { pushError } = useAppToasts();
  const { openModalCancelJourneyDraft } = useJourneyCancellation({
    companyReference,
  });

  const { company } = useCompany({ companyReference: companyReference });
  const { takePhoto } = usePhotoFromDevice();

  const params = new URLSearchParams(window.location.search);
  const creationTimestamp = params.get('creationTimestamp');

  const [pageState, setMergedPageState] = useMergeState({});

  const totalParticipantsCount = useMemo(
    () => journeyEditData.participants.length,
    [journeyEditData.participants.length]
  );

  const participantIndex = useMemo(() => {
    if (creationTimestamp) {
      // HACK to add a creationTimestamp dependecy to hook
    }

    const participantIndex =
      participantIndexString !== undefined
        ? parseInt(participantIndexString, 10)
        : undefined;
    if (participantIndex === undefined) {
      return totalParticipantsCount;
    }
    return participantIndex;
  }, [creationTimestamp, participantIndexString, totalParticipantsCount]);

  const {
    mode,
    existingParticipant,
    defaultValues,
  } = useJourneyParticipantEditInitialState({ participantIndex });

  const {
    handleSubmit,
    formState,
    setValue,
    control,
    trigger,
    reset,
    clearErrors,
  } = useForm<JourneyParticipantEditFormData>({
    defaultValues,
    mode: 'onChange',
  });
  const { isAddJourneyParticipantEnabled } = useJourneyEnabledActions({});

  const errors = formState.errors;
  useEffect(() => {
    if (participantIndex !== undefined || creationTimestamp !== undefined) {
      // reset form if adding a participant immediatly (page does not change)
      reset(defaultValues);
      setTimeout(() => {
        // TODO Toub: je n'ai pas trouvé comment ne plus afficher les messages d'erreur: ceci ne suffit pas
        // control.updateFormState({
        //   isSubmitted: false,
        //   isDirty: false,
        // });
        clearErrors();
      }, 10);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, participantIndex, creationTimestamp, defaultValues]);

  const [birthDate] = useWatch({
    control,
    name: ['birthDate'],
  });
  const journeyAge = useMemo(
    () =>
      journeyHelper.getParticipantJourneyAge({
        journeyEndDateInclusive: journeyEditData.journey.beginDateUTC,
        memberBirthDate: birthDate,
      }),
    [birthDate, journeyEditData.journey.beginDateUTC]
  );

  const isMinor = useMemo<boolean>(() => !journeyAge || journeyAge < 18, [
    journeyAge,
  ]);

  const exitPermitConfig = useMemo<CompanyExitPermitConfig | undefined>(
    () =>
      company?.exitPermitConfigs?.find(
        (permit) =>
          permit.enabled &&
          journeyAge >= permit.minAge &&
          journeyAge <= permit.maxAge
      ),
    [company, journeyAge]
  );

  useEffect(() => {
    setValue(
      'exitPermit',
      !exitPermitConfig || exitPermitConfig.type === 'exitPermit-allow'
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exitPermitConfig]);

  /*
   * Remove the photo
   */
  const removePhoto = useCallback(() => {
    setValue('photo', '' as any);
  }, [setValue]);

  function autoFillForm() {
    if (!defaultValues.lastName) {
      setValue('lastName', fakeDataGenerator.getLastName());
    }
    if (!defaultValues.firstName) {
      setValue('firstName', fakeDataGenerator.getFirstName());
    }
    if (!defaultValues.birthDate) {
      setValue(
        'birthDate',
        fakeDataGenerator.getBirthDate({ maxYears: 25 }) as any
      );
    }
    if (!defaultValues.gender) {
      setValue('gender', fakeDataGenerator.getGender());
    }
    if (!defaultValues.country) {
      setValue('country', fakeDataGenerator.getCountry().id);
    }
    if (!defaultValues.language) {
      setValue('language', fakeDataGenerator.getLanguage().id);
    }
    if (!defaultValues.exitPermit) {
      setValue('exitPermit', dataGenerator.boolean());
    }
    if (!defaultValues.imageRights) {
      setValue('imageRights', dataGenerator.boolean());
    }
    if (!defaultValues.swim) {
      setValue('swim', dataGenerator.boolean());
    }
  }
  /*
   * Submit form callback
   */
  const onSubmit = (
    data: JourneyParticipantEditFormData,
    { nextPage }: { nextPage: 'participants-list' | 'add-participant' }
  ) => {
    const participant: Omit<CMJourneyEditParticipant, 'enabled'> = {
      customerChildId: existingParticipant?.customerChildId,
      member: {
        firstName: data.firstName,
        lastName: data.lastName,
        gender: data.gender,
        birthDate: new Date(data.birthDate),
        isExactBirthDate: true,
        country: data.country,
        language1: data.language,
        photo: data.photo,
        memberComment: data.memberComment,
      },
      attributes: {
        exitPermit: data.exitPermit,
        imageRights: data.imageRights,
        swim: data.swim,
        comment: data.journeyComment,
        journeyAge,
      },
    };

    //Save in redux cache
    dispatchCacheContextAction({
      type: 'update-journey-participant',
      payload: {
        participant,
        participantIndex,
      },
    });
    if (nextPage === 'participants-list') {
      navigateToParticipantsList();
    } else {
      appRouter.navigate(
        appRoutesBuilder.getJourneyCreateParticipantCreateUrl({
          companyReference,
        }),
        {
          cause: 'participant-edit-submit',
        }
      );
    }
  };

  const navigateToParticipantsList = useCallback(() => {
    appRouter.navigate(
      appRoutesBuilder.getJourneyCreateParticipantsListUrl({
        companyReference: companyReference,
      }),
      {
        cause: 'participant-edit-navigate-list',
      }
    );
  }, [appRouter, companyReference]);

  const deleteParticipant = useCallback(() => {
    navigateToParticipantsList();
    if (mode === 'edit') {
      // pas de timeout, sinon le participant n'est pas encore supprimé quand on arrive sur la liste
      dispatchCacheContextAction({
        type: 'delete-journey-participant',
        payload: { participantIndex },
      });
    }
  }, [
    mode,
    dispatchCacheContextAction,
    navigateToParticipantsList,
    participantIndex,
  ]);

  const isFormValid = async () => {
    const isValid = await trigger();
    if (!isValid) {
      pushError(t({ id: 'common.message.error.invalid-form' }));
    }
    return isValid;
  };

  const onAddParticipantClick = async () => {
    handleSubmit((data) => onSubmit(data, { nextPage: 'add-participant' }))();
  };

  const onFinishClick = async () => {
    if (!(await isFormValid())) {
      appLogger.debug('Invalid form', control);
      return;
    }
    handleSubmit((data) => onSubmit(data, { nextPage: 'participants-list' }))();
  };

  const goBack = () => {
    if (totalParticipantsCount) {
      navigateToParticipantsList();
    } else {
      appRouter.navigate(
        appRoutesBuilder.getJourneyDatesEditUrl({
          companyReference: companyReference,
        }),
        { cause: 'participant-edit-back' }
      );
    }
  };

  const openConfirmImageRightsModal = useCallback(() => {
    openModal({
      title: t({ id: 'modal.confirmation' }),
      content: (
        <div>
          <div className="mb-2">{t({ id: 'page12.imageRights.confirm' })}</div>
          <div className="font-light">
            {t({ id: 'page12.imageRights.confirm.details' })}
          </div>
        </div>
      ),
      actions: (
        <div>
          <IonButton color="secondary" onClick={closeModal}>
            {t({ id: 'common.action.cancel' })}
          </IonButton>
          <IonButton
            color="primary"
            onClick={() => {
              setValue('imageRights', true);
              // onChange(true);
              closeModal();
            }}
          >
            {t({ id: 'common.action.ok' })}
          </IonButton>
        </div>
      ),
    });
  }, [closeModal, openModal, setValue, t]);

  const openConfirmParticipantDeletionModal = useCallback(() => {
    openModal({
      title: t({ id: 'modal.confirmation' }),
      content: <div>{t({ id: 'page12.deletion.confirm' })}</div>,
      actions: (
        <div>
          <IonButton color="secondary" onClick={closeModal}>
            {t({ id: 'common.action.cancel' })}
          </IonButton>
          <IonButton
            color="primary"
            onClick={() => {
              deleteParticipant();
              closeModal();
            }}
          >
            {t({ id: 'common.action.ok' })}
          </IonButton>
        </div>
      ),
    });
  }, [closeModal, deleteParticipant, openModal, t]);

  const exitPermitClass =
    exitPermitConfig?.type === 'exitPermit-allow'
      ? 'text-success'
      : exitPermitConfig?.type === 'exitPermit-ask'
      ? 'text-warning'
      : 'text-red-700';

  const participantTitle = useMemo<string>(() => {
    const tagLine = [];
    if (pageState.firstName) {
      tagLine.push(pageState.firstName);
    }
    if (pageState.lastName) {
      tagLine.push(pageState.lastName);
    }
    if (pageState.age > 0) {
      tagLine.push(`(${t({ id: 'page12.yearsOld' }, { age: pageState.age })})`);
    }
    return tagLine.join(' ');
  }, [pageState.firstName, pageState.lastName, pageState.age, t]);

  return !journeyEditData || !company ? null : (
    <AppPage displayFooter={false}>
      <HeaderToolbar hideLogout={true} />
      <PageContainer className="text-center bg-gray-50" spacing="normal">
        {/* Participant N#... */}
        <>
          <IonItemDivider>
            {t({ id: 'page12.participant' }, { number: participantIndex + 1 })}{' '}
            {participantTitle ? ' - ' : ''} {participantTitle}
            <div className="absolute right-8 text-2xl">
              {appWebConfig().envId === 'dev' && (
                <IonIcon
                  className="mr-2"
                  color="primary"
                  icon={build}
                  onClick={() => autoFillForm()}
                />
              )}
              {existingParticipant?.customerChildId ? null : (
                <IonIcon
                  color="danger"
                  icon={trash}
                  onClick={openConfirmParticipantDeletionModal}
                />
              )}
            </div>
          </IonItemDivider>
          <form
            onSubmit={(e) => {
              // quand on clique sur les flèches de navigation des mois des calendrier, le "onSubmit est appelé!", donc en attendant de revoir ça, on stop la propagation ici
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <IonList className="text-left px-2">
              <IonItemGroup>
                {/* Last name */}
                <Controller
                  render={({ field: { value, onChange } }) => (
                    <AppInputIonic
                      required={true}
                      placeholder={t({ id: 'page12.lastName' })}
                      value={value}
                    >
                      <IonInput
                        autoCapitalize="on"
                        onIonChange={(e) => (
                          onChange(e),
                          setMergedPageState({ lastName: e.detail.value })
                        )}
                      />
                    </AppInputIonic>
                  )}
                  control={control}
                  name="lastName"
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: t({ id: 'validation.required' }),
                    },
                  }}
                />
                <FormError error={errors.lastName?.message} />

                {/* First name */}
                <Controller
                  render={({ field: { value, onChange } }) => (
                    <AppInputIonic
                      className="mt-2"
                      required={true}
                      placeholder={t({ id: 'page12.firstName' })}
                      value={value}
                    >
                      <IonInput
                        autoCapitalize="on"
                        onIonChange={(e) => (
                          onChange(e),
                          setMergedPageState({ firstName: e.detail.value })
                        )}
                      />
                    </AppInputIonic>
                  )}
                  control={control}
                  name="firstName"
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: t({ id: 'validation.required' }),
                    },
                  }}
                />
                <FormError error={errors.firstName?.message} />
              </IonItemGroup>

              {/* Birth Date (2 columns) */}
              <div className="flex items-center gap-2 mt-2">
                <Controller
                  control={control}
                  name="birthDate"
                  rules={{
                    required: {
                      value: true,
                      message: t({ id: 'validation.required' }),
                    },
                  }}
                  render={({ field: { value, onChange } }) => (
                    <AppInputDatePickerIonics
                      placeholder={t({ id: 'page12.birthDate' })}
                      initialView={value ? 'weeks' : 'years'}
                      value={value}
                      minDate={new Date(1900, 1, 1)}
                      maxDate={new Date()}
                      onChange={(e) => {
                        onChange(e);
                        if (e) {
                          setMergedPageState({
                            age: differenceInYears(
                              new Date(journeyEditData?.journey?.beginDateUTC),
                              new Date(e)
                            ),
                            journeyAge: differenceInYears(
                              new Date(
                                journeyEditData?.journey?.endDateInclusiveUTC
                              ),
                              new Date(e)
                            ),
                          });
                        }
                      }}
                    />
                  )}
                />

                <ParticipantAgeLabel
                  journeyAge={journeyAge}
                  memberAge={pageState.age}
                />
              </div>
              <FormError error={errors.birthDate?.message} />

              <div className={pageState.age < 0 ? 'hidden' : ''}>
                {/* Gender */}
                <Controller
                  render={({ field: { value, onChange } }) => (
                    <CustomerGenderRadioGroup
                      value={value}
                      isMinor={isMinor}
                      onChange={onChange}
                    />
                  )}
                  control={control}
                  name="gender"
                  defaultValue={'' as any}
                  rules={{
                    required: {
                      value: true,
                      message: t({ id: 'validation.required' }),
                    },
                  }}
                />
                <FormError error={errors.gender?.message} />

                {/* Country */}
                <div className="mt-2">
                  <Controller
                    render={({ field: { value, onChange } }) => (
                      <AppInputIonic
                        required={true}
                        placeholder={t({ id: 'page12.country' })}
                        value={value}
                      >
                        <div className="flex w-full items-center">
                          {COUNTRIES_MAP[value] && (
                            <IonThumbnail slot="start" className="w-4 h-4 ml-2">
                              <IonImg
                                className="rounded-full"
                                src={COUNTRIES_MAP[value].imgUrl}
                              />
                            </IonThumbnail>
                          )}
                          <IonSelect
                            interface="action-sheet"
                            okText={t({ id: 'common.action.ok' })}
                            cancelText={t({ id: 'common.action.cancel' })}
                            placeholder={t({ id: 'page12.country' }) + ' *'}
                            onIonChange={(e) => {
                              onChange(e);
                              // appLogger.debug(COUNTRIES_MAP[e.detail.value]);
                              if (
                                COUNTRIES_MAP[e.detail.value]?.defaultLanguage
                              ) {
                                setValue(
                                  'language',
                                  COUNTRIES_MAP[e.detail.value]?.defaultLanguage
                                );
                              }
                            }}
                            value={value}
                          >
                            {COUNTRIES_ARRAY.map((country) => (
                              <IonSelectOption
                                key={`country-${country.id}`}
                                value={country.id}
                              >
                                {t({ id: `country.${country.id}` })}
                              </IonSelectOption>
                            ))}
                          </IonSelect>
                        </div>
                      </AppInputIonic>
                    )}
                    control={control}
                    name="country"
                    defaultValue={'' as any}
                    rules={{
                      required: {
                        value: true,
                        message: t({ id: 'validation.required' }),
                      },
                    }}
                  />
                </div>
                <FormError error={errors.country?.message} />

                {/* Language */}
                <div className="mt-2">
                  <Controller
                    render={({ field: { value, onChange } }) => (
                      <AppInputIonic
                        required={true}
                        placeholder={t({ id: 'page12.language' })}
                        value={value}
                      >
                        <div className="flex w-full items-center">
                          {LANGUAGES_MAP[value] && (
                            <IonThumbnail slot="start" className="w-8 h-6">
                              <IonImg
                                className="rounded-full ml-2"
                                src={LANGUAGES_MAP[value].imgUrl}
                              />
                            </IonThumbnail>
                          )}
                          <IonSelect
                            interface="action-sheet"
                            okText={t({ id: 'common.action.ok' })}
                            cancelText={t({ id: 'common.action.cancel' })}
                            placeholder={t({ id: 'page12.language' }) + ' *'}
                            onIonChange={onChange}
                            value={value}
                          >
                            {LANGUAGES_ARRAY.map((language) => (
                              <IonSelectOption
                                key={`country-${language.id}`}
                                value={language.id}
                              >
                                {language.labelOriginal}
                              </IonSelectOption>
                            ))}
                          </IonSelect>
                        </div>
                      </AppInputIonic>
                    )}
                    control={control}
                    name="language"
                    defaultValue={'' as any}
                    rules={{
                      required: {
                        value: true,
                        message: t({ id: 'validation.required' }),
                      },
                    }}
                  />
                </div>
                <FormError error={errors.language?.message} />

                <AppFormLabel
                  required={false}
                  className={'my-4 form-label pl-1'}
                  label={`Avatar`}
                  hasError={false}
                  disabled={false}
                >
                  <AppInputPhotoRHFControl
                    control={control}
                    // label={t({ id: 'common.form.photo' })}
                    name={`photo`}
                    url={`/children/images`}
                    preview={{
                      size: 'small',
                      onDeleteConfirmMessage: t({
                        id: 'page12.avatar.confirm',
                      }),
                    }}
                  />
                </AppFormLabel>

                {/* Image right */}
                <IonItem>
                  <Controller
                    render={({ field: { value, onChange } }) => (
                      <IonToggle
                        color="primary"
                        onIonChange={(e) => {
                          if (e.detail.checked !== value) {
                            if (e.detail.checked) {
                              openConfirmImageRightsModal();
                            } else {
                              control;
                              onChange(false);
                            }
                          }
                        }}
                        checked={value}
                      />
                    )}
                    control={control}
                    name="imageRights"
                    defaultValue={false}
                  />
                  <IonLabel>{t({ id: 'page12.imageRights' })}</IonLabel>
                </IonItem>
                <div className={!isMinor ? 'hidden' : ''}>
                  {/* Can swim */}
                  <IonItem>
                    <Controller
                      render={({ field: { value, onChange } }) => (
                        <IonToggle
                          color="primary"
                          onIonChange={(e) => onChange(e.detail.checked)}
                          checked={value}
                        />
                      )}
                      control={control}
                      name="swim"
                      defaultValue={false}
                    />
                    <IonLabel>{t({ id: 'page12.canSwim' })}</IonLabel>
                  </IonItem>

                  {/* Exit permission */}
                  <div className={!exitPermitConfig ? 'hidden' : ''}>
                    <p className={`${exitPermitClass} p-4`}>
                      {exitPermitConfig?.text}
                    </p>
                    <IonItem>
                      <Controller
                        render={({ field: { value, onChange } }) => (
                          <IonToggle
                            color="primary"
                            onIonChange={(e) => onChange(e.detail.checked)}
                            checked={value}
                            disabled={
                              exitPermitConfig?.type === 'exitPermit-deny' ||
                              exitPermitConfig?.type === 'exitPermit-allow'
                            }
                          />
                        )}
                        control={control}
                        name="exitPermit"
                        defaultValue={false}
                      />
                      <IonLabel>
                        {t({ id: 'page12.exitPermitAuthorize' })}
                      </IonLabel>
                    </IonItem>
                  </div>

                  {/* Observations, allergies... */}
                  <IonItem>
                    <Controller
                      render={({ field: { value, onChange } }) => (
                        <IonInput
                          value={value}
                          placeholder={t({ id: 'page12.memberComment' })}
                          onIonChange={onChange}
                        />
                      )}
                      control={control}
                      name="memberComment"
                      defaultValue=""
                    />
                  </IonItem>

                  {/* Journey specific memberComment */}
                  <IonItem>
                    <Controller
                      render={({ field: { value, onChange } }) => (
                        <IonInput
                          value={value}
                          placeholder={t({ id: 'page12.journeyComment' })}
                          onIonChange={onChange}
                        />
                      )}
                      control={control}
                      name="journeyComment"
                      defaultValue=""
                    />
                  </IonItem>
                </div>

                {mode === 'create' && (
                  <div className="text-center p-4 bg-gray-100">
                    {' '}
                    {isAddJourneyParticipantEnabled && (
                      <IonText
                        className="block font-bold mt-2"
                        color="tertiary"
                      >
                        ⚠️ {t({ id: 'page12.warning.addAllParticipants' })}
                      </IonText>
                    )}
                    <IonText className="italic" color="tertiary">
                      {t({ id: 'page12.warning.details' })}
                    </IonText>
                  </div>
                )}
              </div>
              {/* ajout de 100 pixels sinon le date picker ne s'affiche pas bien */}
              <div style={{ height: 100 }}></div>
            </IonList>
          </form>
        </>
      </PageContainer>
      <FooterActions
        onCancel={openModalCancelJourneyDraft}
        onBack={goBack}
        onSubmit={onFinishClick}
      />
    </AppPage>
  );
};
