import { IonIcon, IonRouterLink } from '@ionic/react';
import { chevronBackOutline } from 'ionicons/icons';
import React from 'react';
import { useAppRouter } from '_01_CORE/_hooks';

export const PageGroupTitle: React.FC<{
  logo?: string;
  title?: string;
  backLink: string;
}> = ({ logo, title, backLink }) => {
  const appRouter = useAppRouter();

  return (
    <IonRouterLink
      onClick={() => appRouter.navigate(backLink, { cause: 'group-page-back' })}
    >
      <div className="flex text-tertiary text-lg font-extrabold max-h-14">
        <div hidden={!backLink} className="flex items-center">
          <IonIcon icon={chevronBackOutline} slot="icon-only" />
        </div>
        <div hidden={!logo} className="flex-1">
          <img className="h-full w-auto mx-auto" src={logo} />
        </div>
        <div className="flex flex-1 justify-center place-self-center">
          {title}
        </div>
      </div>
    </IonRouterLink>
  );
};
