import * as Sentry from '@sentry/react';
import { BrowserOptions } from '@sentry/react';
import { AppWebConfigCommon } from './model';

export const appWebSentryConfigurer = {
  initSentry,
};
enum LogLevel {
  /** No logs will be generated. */
  None = 0,
  /** Only SDK internal errors will be logged. */
  Error = 1,
  /** Information useful for debugging the SDK will be logged. */
  Debug = 2,
  /** All SDK actions will be logged. */
  Verbose = 3,
}
function initSentry(config: AppWebConfigCommon) {
  const sentryDsn = config.debug.sentryDsn;
  console.log(`Sentry DSN ${sentryDsn}`);

  if (sentryDsn) {
    // https://docs.sentry.io/platforms/javascript/guides/react/
    console.log(`Initialize sentry with DSN ${sentryDsn}`);
    const sentryOptions: BrowserOptions = {
      dsn: sentryDsn,
      release: config.appVersion,
      logLevel: LogLevel.Error,
      maxBreadcrumbs: 100,
    };
    Sentry.init(sentryOptions);
  }
}
