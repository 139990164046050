import { appLogger } from 'lib-web-logger';
import { AppCacheContextModel } from '_01_CORE/app-contexts';
import { companyApiClient } from '_01_CORE/app-core/app-api';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';

export const companyOrGroupReferenceTester = {
  testCompanyOrGroupCode,
};

function testCompanyOrGroupCode(
  companyOrGroupReference: string,
  {
    appCacheContext,
    alwaysThrowError,
  }: {
    appCacheContext: AppCacheContextModel;
    alwaysThrowError?: boolean;
  }
): Promise<{
  redirectTo?: string;
}> {
  const { dispatchCacheContextAction } = appCacheContext;

  return companyApiClient
    .testCompanyOrCompanyGroupCode({
      code: companyOrGroupReference,
      alwaysThrowError,
    })
    .then(
      ({ type, company, group }) => {
        //The user entered company reference
        if (type === 'company') {
          dispatchCacheContextAction({
            type: 'set-company',
            company,
            updateCompanyValidReferenceCode: true,
            context: 'login-enter-company-code',
          });
          return {
            redirectTo: appRoutesBuilder.getCompanyBookletUrl({
              companyReference: company.reference,
            }),
          };
        }
        //The user entered group reference
        if (type === 'group') {
          dispatchCacheContextAction({
            type: 'set-group',
            group,
          });
          return {
            redirectTo: appRoutesBuilder.getGroupHomeUrl({
              groupReference: group.reference,
              isAuthenticated: false,
            }),
          };
        }
        return {};
      },
      (err) => {
        appLogger.warn('companyOrGroupReference:', { companyOrGroupReference });
        appLogger.warn('Company code login error', { err });
        return {};
      }
    )
    .catch((reason) => {
      appLogger.warn('companyOrGroupReference:', { companyOrGroupReference });
      appLogger.warn('Company code login error', reason);
      return {};
    });
}
