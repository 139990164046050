import {
  AppButtonUniverse,
  AppIcons,
  AppLoader,
} from '_01_CORE/_components-core';
import { AppInput } from '_01_CORE/_components-core/AppInput';
import { useAppRouter, useAppToasts } from '_01_CORE/_hooks';
import { useCompany } from '_01_CORE/app-contexts';
import { appWebConfig, useAppFeatures } from '_01_CORE/app-core/app-config';
import { useNetworkStatus } from '_01_CORE/app-core/app-platform';
import { useAppSettings } from '_01_CORE/app-core/app-security';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { AuthMultiLoginAnimLivePageLocalState } from '../useAuthMultiLoginAnimLivePageLocalState.hook';

type AuthPageMultiLoginCompanyCodePanelForm = {
  companyOrGroupReference: string;
};

export const AuthPageMultiLoginCompanyCodePanel = ({
  localState,
}: {
  localState: AuthMultiLoginAnimLivePageLocalState;
}) => {
  const [persistInProgress, setPersistInProgress] = useState(false);

  const appRouter = useAppRouter();
  const { formatMessage: t } = useIntl();

  const { viewState, actions } = localState;
  const journeyInfo = viewState.journeyInfo;
  const fetchedJourney = journeyInfo?.fetchedJourney;
  const companyReference = fetchedJourney?.companyReference;

  const { company } = useCompany({
    companyReference,
  });
  const { pushError, pushSuccess } = useAppToasts();
  const { networkStatus } = useNetworkStatus();

  const initialFormValue: AuthPageMultiLoginCompanyCodePanelForm = {
    companyOrGroupReference:
      appWebConfig().authentication.defaultAuthLogin?.companyCode || undefined,
  };

  const {
    control,
    setValue,
    register,
    handleSubmit,
    formState,
  } = useForm<AuthPageMultiLoginCompanyCodePanelForm>({
    defaultValues: initialFormValue,
    reValidateMode: 'onChange',
  });

  const { isFeatureAuthEnabled } = useAppFeatures();

  const appSettings = useAppSettings();
  const title1Color = appSettings?.universe?.theme?.title1Color;

  return persistInProgress ? (
    <AppLoader
      className="my-4 p-2 px-4"
      type="persist"
      message="Connexion en cours"
    />
  ) : (
    <form className="w-full bg-gray-50 rounded-xl border border-gray-200 pt-4 pb-8 px-8">
      {isFeatureAuthEnabled && (
        <>
          <div className="w-full flex flex-col gap-2 items-stretch">
            <h2
              className={`text-left text-base md:text-xl font-bold my-4`}
              style={{ color: title1Color }}
            >
              {`Le code de votre camping:`}
            </h2>
            <div className="w-full">
              <AppInput
                required={true}
                autofocus={true}
                placeholder={t({ id: 'page01.companyCode' })}
                {...register('companyOrGroupReference', {
                  required: true,
                })}
                onChange={(e) => {
                  const companyOrGroupReference = (e as any).target
                    .value as string;
                  setValue('companyOrGroupReference', companyOrGroupReference, {
                    shouldValidate: true,
                  });
                }}
                type="text"
                icon={AppIcons.code}
              />
            </div>

            <div className="text-left">
              <AppButtonUniverse
                style={'primary'}
                className={`mt-4 uppercase`}
                type="submit"
                disabled={!formState.isValid}
                onClick={(e) => {
                  // bouton submit pour soumettre quand on appuie sur ENTER
                  e.stopPropagation();
                  e.preventDefault();
                  handleSubmit(
                    (formValue) => {
                      // valid form
                      setPersistInProgress(true);
                      actions
                        .checkCompanyCode({
                          companyOrGroupReference:
                            formValue.companyOrGroupReference,
                          emailAddress:
                            viewState.promptEmail?.testResult?.login,
                        })
                        .finally(() => {
                          setPersistInProgress(false);
                        });
                    },
                    (errors) => {
                      // invalid form
                      console.error('invalid form', errors);
                      pushError(t({ id: 'common.message.error.invalid-form' }));
                    }
                  )();
                }}
              >
                Continuer
              </AppButtonUniverse>
            </div>
          </div>
        </>
      )}
    </form>
  );
};
