import { useAppRouter } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import {
  disconnect,
  loadDataGuard,
  redirectToDefaultRoute,
  requireFeatures,
  requireJourneyEditData,
  requireJourneyStatus,
  testCompanyOrGroupReference,
  testRedirectFromOldVersion,
} from '_01_CORE/_pages-routes/guards';
import {
  useAppCacheContext,
  useAppLocalizationContext,
} from '_01_CORE/app-contexts';
import { APP_CURRENT_REGISTRATION_BROWSER_STORAGE_ID } from '_01_CORE/app-core/app-bootstrap/APP_CURRENT_REGISTRATION_BROWSER_STORAGE_ID.const';
import { persistentCache } from '_01_CORE/app-core/app-platform';
import {
  AppRoute,
  AppRouteGuardContext,
  AppRouteGuardError,
} from '_01_CORE/app-core/app-router';
import { CustomerJourneyActivitiesPageUrlParams } from '_02_APP_COMMON/ACT-activity';
import {
  DebugPage,
  HACK_DEBUG_PAGE_PUBLIC_UNTIL_NEXT_DEPLOY,
  OldVersionPage,
  ResetPasswordConfirmPage,
  ResetPasswordQueryPage,
} from '_02_APP_COMMON/AUT-auth';
import { CompanyContactPage } from '_02_APP_COMMON/COM-company/COM-05-company-contact';
import { CompanyGroupHomePage } from '_02_APP_COMMON/COM-company/COM-07-company-group-home';
import {
  CompanyBookletPage,
  CompanyBookletSheetPage,
  CompanyBookletThemePage,
} from '_02_APP_COMMON/COM-company/COM-08-company-booklet';
import { CompanyRestoMenuPage } from '_02_APP_COMMON/COM-company/COM-09-company-resto';
import { AccountChooseCompanyPage } from '_02_APP_COMMON/JOU-journey/JOU-11-journey-choose-company';
import {
  JourneyCreateParticipantEditAnimLivePage,
  JourneyEditParticipantEditAnimLivePage,
} from '_02_APP_COMMON/JOU-journey/JOU-12-journey-participant-edit';
import { JourneyCreateParticipantsListAnimLivePage } from '_02_APP_COMMON/JOU-journey/JOU-13-journey-participants-list';
import { JourneyCreateDetailsAnimLivePage } from '_02_APP_COMMON/JOU-journey/JOU-13-journey-participants-list/anim-live-details/JourneyCreateDetailsAnimLivePage';
import { NotificationsListPage } from '_02_APP_COMMON/NOT-notifications/NOT-06-notifications-list';
import {
  CMJourneyEditData,
  CMProfileActivitySessionToDisplay,
} from 'lib-common-model';
import React, { useCallback, useEffect, useState } from 'react';
import { Route } from 'react-router';
import { AnimLiveCustomerAccountEditPage } from './ACC-account/AC-14-customer-account-edit/AnimLiveCustomerAccountEditPage';
import { AnimLiveJourneysActivitiesPage } from './ACT-activities';
import { AuthMultiLoginAnimLivePage, AuthPageHomeAnimLive } from './AUT-auth';
import {
  AnimLiveJourneyShoppingCartDetailsPage,
  AnimLiveJourneyShoppingCartHistoryPage,
  AnimLiveJourneyShoppingCartLivePage,
} from './ECO-ecommerce/ECO-31-journey-shopping-cart';
import { ALShoppingCartPaymentPage } from './ECO-ecommerce/ECO-32-journey-shopping-cart-payment';
import { AnimLiveJourneyHomePage } from './JOU-journey/JOU-02-journey-home';
import { JourneysListAnimLivePage } from './JOU-journey/JOU-04-journeys-list';
import { CreateJourneyActivationCodePage } from './JOU-journey/JOU-08-activation-code';
import {
  AnimLiveJourneyEditDatesPage,
  JourneyCreateDatesAnimLivePage,
} from './JOU-journey/JOU-10-journey-choose-dates';
import { AnimLiveJourneyActivateByTokenPage } from './JOU-journey/JOU-30-journey-activate';
import { AnimLiveJourneyActivateByKeyPage } from './JOU-journey/JOU-30-journey-activate/AnimLiveJourneyActivateByKeyPage';
import { AnimLiveJourneyCheckInPage } from './JOU-journey/JOU-40-check-in';
import { AnimLiveReportIssuePage } from './JOU-journey/JOU-50-report-issue';
import { AnimLiveJourneyEditExistingHomePage } from './JOU-journey/JOU-journey-edit-existing-home';
import { AnimLiveProHomePage } from './PRO-company-pro';

export const AppCustomerRoutesAnimLive: React.FC = () => {
  const appRouter = useAppRouter();

  const {
    loaded,
    dispatchCacheContextAction,
    activitySessionsToDisplay,
  } = useAppCacheContext();

  const [
    nextActivitySessionToDisplay,
    setNextActivitySessionToDisplay,
  ] = useState<CMProfileActivitySessionToDisplay>();

  useEffect(() => {
    if (activitySessionsToDisplay?.length) {
      const firstActivitySessionsToDisplay = activitySessionsToDisplay[0];
      if (
        firstActivitySessionsToDisplay?.activitySessionId !==
        nextActivitySessionToDisplay?.activitySessionId
      ) {
        setNextActivitySessionToDisplay(firstActivitySessionsToDisplay);
      }
    }
  }, [
    activitySessionsToDisplay,
    nextActivitySessionToDisplay?.activitySessionId,
  ]);

  useEffect(() => {
    if (nextActivitySessionToDisplay) {
      const {
        activityId,
        activitySessionId,
        beginDateTime,
        customerJourneyId,
      } = nextActivitySessionToDisplay;
      const params: CustomerJourneyActivitiesPageUrlParams = {
        category: undefined, // la catégorie sera automatiquement switchée en arrivant sur la page, si nécessaire
        selectedDate: beginDateTime,
        focusActivitySessionId: activitySessionId,
        focusActivityId: activityId,
        focusActivityOpenDialog: true,
      };

      const activityDialogUrl = appRoutesBuilder.getJourneyActivitiesWithParamsUrl(
        {
          customerJourneyId,
          params,
        }
      );
      appRouter.navigate('/notifications', {
        cause: 'force-session-focus',
      });
      appRouter.navigateAfterDelay(activityDialogUrl, {
        cause: 'force-session-focus',
      });
    }
  }, [appRouter, nextActivitySessionToDisplay]);

  // TODO @toub quand on créé un séjour, il faudrait avoir une route spécifique, pour être sûr que le cache est vidé
  // et peut-être un cache différent entre création et édition
  // TODO @toub il va falloir faire des statuts qui dépendent de l'application sur laquelle on est: peut-être faire des routes complètement différentes pour les 2 applis?
  // TODO en cas d'édition de séjour, ilfaudrait des gards sur le statut du séjour, mais comme le séjour est en cache et pas dans la routeUrl, c'est plus compliqué
  // au lieu de passer des gards, peut-être passer un objet qui défini tous les checks à faire, plus optimisé et clair à l'utilisation?
  // {
  //   auth: 'required',
  //   journeyFromUrl: 'customerJourneyId',
  //   journeyFromCache: true,
  //   journeyAllowedStatus: ['draft']
  // }
  // TODO @toub intégrer useJourneyEditDataDraftOrRedirect dedans

  useEffect(() => {
    persistentCache
      .get<CMJourneyEditData>(APP_CURRENT_REGISTRATION_BROWSER_STORAGE_ID, {
        ignoreError: true,
      })
      .then((journeyEditData) => {
        // restore or clear journey in redux cache
        dispatchCacheContextAction({
          type: 'restore-journey-edit-data-from-cache',
          journeyEditData,
        });
      });
  }, [dispatchCacheContextAction]);

  const testCompanyOrGroupReferenceGuard = useCallback(
    (context: AppRouteGuardContext) =>
      testCompanyOrGroupReference('companyOrGroupReference')(context),
    []
  );

  const requireFeatureAuth = useCallback(
    (context: AppRouteGuardContext) =>
      requireFeatures('authentication')(context),
    []
  );
  const requireJourneyStatusValidated = useCallback(
    (context: AppRouteGuardContext) =>
      requireJourneyStatus('customerJourneyId', ['validated'])(context),
    []
  );

  const disconnectGuard = useCallback(
    (context: AppRouteGuardContext) => disconnect()(context),
    []
  );

  const {
    locales: { platformLanguageCode, appLanguageCode },
  } = useAppLocalizationContext();

  const loadDataGuardDefault = useCallback(
    (context: AppRouteGuardContext) =>
      loadDataGuard({
        routeParamsNames: {
          companyReference: 'companyReference',
          groupReference: 'groupReference',
          customerJourneyId: 'customerJourneyId',
          bookingId: 'bookingId',
        },
        fetchCompanyWelcomeBooklet: false,
        platformLanguageCode,
        appLanguageCode,
      })(context),
    [appLanguageCode, platformLanguageCode]
  );

  const loadDataGuardWithBookletsDefault = useCallback(
    (context: AppRouteGuardContext) =>
      loadDataGuard({
        routeParamsNames: {
          companyReference: 'companyReference',
          groupReference: 'groupReference',
          customerJourneyId: 'customerJourneyId',
          bookingId: 'bookingId',
        },
        fetchCompanyWelcomeBooklet: true,
        platformLanguageCode,
        appLanguageCode,
      })(context),
    [appLanguageCode, platformLanguageCode]
  );
  const loadDataGuardWithMenuDefault = useCallback(
    (context: AppRouteGuardContext) =>
      loadDataGuard({
        routeParamsNames: {
          companyReference: 'companyReference',
          groupReference: undefined,
          customerJourneyId: undefined,
          bookingId: undefined,
        },
        fetchCompanyWelcomeBooklet: false,
        fetchCompanyMenu: true,
        platformLanguageCode,
        appLanguageCode,
      })(context),
    [appLanguageCode, platformLanguageCode]
  );
  const redirectToNewActivationTokenRouteGuard = useCallback(
    (context: AppRouteGuardContext) => {
      const { routeParams } = context;

      const companyReference = routeParams['companyReference'];
      const params = new URLSearchParams(window.location.search);
      const activationToken = params.get('activationToken');

      const redirectToUrl = appRoutesBuilder.getActivationTokenUrl({
        activationToken,
        companyReference,
        origin: 'login-home',
      });

      throw new AppRouteGuardError({
        redirectToUrl,
      });
    },
    []
  );
  return (
    loaded && (
      <>
        <Route
          path="/camping/:companyOrGroupReference/inscriptions" // ATTENTION: ce lien est un lien direct utilisé (directement ou indirectement) par les vacanciers, ne pas le modifier
          exact={true}
          render={() => (
            <AppRoute
              component={ResetPasswordQueryPage}
              routeGuards={[testCompanyOrGroupReferenceGuard]}
            />
          )}
        />
        <Route
          path="/groupe/:companyOrGroupReference/inscriptions" // ATTENTION: ce lien est un lien direct utilisé (directement ou indirectement) par les vacanciers, ne pas le modifier
          exact={true}
          render={() => (
            <AppRoute
              component={AuthPageHomeAnimLive}
              routeGuards={[testCompanyOrGroupReferenceGuard]}
            />
          )}
        />
        <Route
          path="/company/:companyReference/booklet"
          exact={true}
          render={() => (
            <AppRoute
              component={CompanyBookletPage}
              routeGuards={[loadDataGuardWithBookletsDefault]}
            />
          )}
        />
        <Route
          path="/company/:companyReference/menu"
          exact={true}
          render={() => (
            <AppRoute
              component={CompanyRestoMenuPage}
              routeGuards={[loadDataGuardWithMenuDefault]}
            />
          )}
        />
        <Route
          path="/company/:companyReference/booklet/theme/:bookletThemeReference"
          exact={true}
          render={() => (
            <AppRoute
              component={CompanyBookletThemePage}
              routeGuards={[loadDataGuardWithBookletsDefault]}
            />
          )}
        />
        <Route
          path="/company/:companyReference/booklet/theme/:bookletThemeReference/sheet/:bookletSheetReference"
          exact={true}
          render={() => (
            <AppRoute
              component={CompanyBookletSheetPage}
              routeGuards={[loadDataGuardWithBookletsDefault]}
            />
          )}
        />
        <Route
          // @deprecated (remplacé par journey-sactivate-by-token), conserver pendant 1 an car le lien est présent dans les mails envoyés en 2023
          path="/company/:companyReference/journey-activate-start"
          exact={false}
          render={() => (
            <AppRoute
              // NOTE, redirige vers la nouvelle page
              routeGuards={[redirectToNewActivationTokenRouteGuard]}
              component={AnimLiveJourneyActivateByTokenPage}
            />
          )}
        />
        <Route
          path="/company/:companyReference/journey-activation-key"
          exact={true}
          render={() => (
            <AppRoute
              component={AnimLiveJourneyActivateByKeyPage}
              auth={{ authenticationRequired: false }} // on peut être authentifié ou non
              routeGuards={[requireFeatureAuth]}
            />
          )}
        />
        {/* </IonRouterOutlet> */}
        {/* NOTE: `render={() => ` permet d'éviter des sauts lors que l'on navigue, car les paramètres de route entrainent un rafraichissement du composant: https://github.com/ionic-team/ionic-framework/issues/21635*/}
        <Route
          path="/login"
          exact={true}
          render={() => <AppRoute component={AuthPageHomeAnimLive} />}
        />
        <Route
          path="/login/auth"
          exact={false}
          render={() => (
            <AppRoute
              routeGuards={[loadDataGuardDefault]}
              component={AuthMultiLoginAnimLivePage}
            />
          )}
        />
        <Route
          path="/logout"
          exact={true}
          render={() => (
            <AppRoute
              routeGuards={[disconnectGuard]}
              component={AuthPageHomeAnimLive}
            />
          )}
        />
        <Route
          path="/pro"
          exact={true}
          render={() => (
            <AppRoute
              component={AnimLiveProHomePage}
              auth={{ authenticationRequired: true }} // TODO tester le profil pro
              routeGuards={[requireFeatureAuth]}
            />
          )}
        />
        <Route
          path="/journey/:customerJourneyId"
          exact={true}
          render={() => (
            <AppRoute
              component={AnimLiveJourneyHomePage}
              auth={{ authenticationRequired: true }}
              routeGuards={[requireFeatureAuth, loadDataGuardDefault]}
            />
          )}
        />
        <Route
          path="/journey/:customerJourneyId/shopping-cart-live"
          exact={true}
          render={() => (
            <AppRoute
              component={AnimLiveJourneyShoppingCartLivePage}
              auth={{ authenticationRequired: true }}
              routeGuards={[requireFeatureAuth, loadDataGuardDefault]}
            />
          )}
        />{' '}
        <Route
          path="/journey/:customerJourneyId/shopping-cart-history"
          exact={true}
          render={() => (
            <AppRoute
              component={AnimLiveJourneyShoppingCartHistoryPage}
              auth={{ authenticationRequired: true }}
              routeGuards={[requireFeatureAuth, loadDataGuardDefault]}
            />
          )}
        />
        <Route
          path="/journey/:customerJourneyId/shopping-cart-details/:shoppingCartId"
          exact={true}
          render={() => (
            <AppRoute
              component={AnimLiveJourneyShoppingCartDetailsPage}
              auth={{ authenticationRequired: true }}
              routeGuards={[requireFeatureAuth, loadDataGuardDefault]}
            />
          )}
        />
        <Route
          path="/journey/:customerJourneyId/shopping-cart-payment/:shoppingCartId"
          exact={true}
          render={() => (
            <AppRoute
              component={ALShoppingCartPaymentPage}
              auth={{ authenticationRequired: true }}
              routeGuards={[requireFeatureAuth, loadDataGuardDefault]}
            />
          )}
        />
        <Route
          path="/journey/:customerJourneyId/checkin"
          exact={true}
          render={() => (
            <AppRoute
              component={AnimLiveJourneyCheckInPage}
              auth={{ authenticationRequired: true }}
              routeGuards={[requireFeatureAuth, loadDataGuardDefault]}
            />
          )}
        />
        <Route
          path="/journey/:customerJourneyId/report-issue"
          exact={true}
          render={() => (
            <AppRoute
              component={AnimLiveReportIssuePage}
              auth={{ authenticationRequired: true }}
              routeGuards={[requireFeatureAuth, loadDataGuardDefault]}
            />
          )}
        />
        <Route
          path="/journey-create/:companyReference/participants-list"
          exact={true}
          render={() => (
            <AppRoute
              routeGuards={[
                requireFeatureAuth,
                requireJourneyEditData,
                loadDataGuardDefault,
              ]}
              component={JourneyCreateParticipantsListAnimLivePage}
            />
          )}
        />{' '}
        <Route
          path="/journey-create/activation-code"
          exact={true}
          render={() => (
            <AppRoute
              routeGuards={[requireFeatureAuth]}
              component={CreateJourneyActivationCodePage}
            />
          )}
        />
        <Route
          path="/account/create/:companyReference"
          exact={true}
          render={() => (
            <AppRoute
              component={JourneyCreateDatesAnimLivePage}
              routeGuards={[requireFeatureAuth, loadDataGuardDefault]}
            />
          )}
        />
        <Route
          path="/journey-edit/:customerJourneyId/dates-edit"
          exact={true}
          render={() => (
            <AppRoute
              component={AnimLiveJourneyEditDatesPage}
              routeGuards={[requireFeatureAuth, loadDataGuardDefault]}
            />
          )}
        />
        <Route
          path="/journey-create/:companyReference/details"
          exact={true}
          render={() => (
            <AppRoute
              component={JourneyCreateDetailsAnimLivePage}
              routeGuards={[requireFeatureAuth, loadDataGuardDefault]}
            />
          )}
        />
        <Route
          path="/journey/:customerJourneyId/edit"
          exact={true}
          render={() => (
            <AppRoute
              component={AnimLiveJourneyEditExistingHomePage}
              auth={{ authenticationRequired: true }}
              routeGuards={[requireFeatureAuth, loadDataGuardDefault]}
            />
          )}
        />
        <Route
          path="/journey-create/:companyReference/participant-edit/:participantIndex"
          exact={true}
          render={() => (
            <AppRoute
              routeGuards={[
                requireFeatureAuth,
                requireJourneyEditData,
                loadDataGuardDefault,
              ]}
              component={JourneyCreateParticipantEditAnimLivePage}
            />
          )}
        />
        <Route
          path="/journey-create/:companyReference/participant-create"
          exact={true}
          render={() => (
            <AppRoute
              routeGuards={[
                requireFeatureAuth,
                requireJourneyEditData,
                loadDataGuardDefault,
              ]}
              component={JourneyCreateParticipantEditAnimLivePage}
            />
          )}
        />
        <Route
          path="/journey-edit/:customerJourneyId/participant-create"
          exact={true}
          render={() => (
            <AppRoute
              routeGuards={[
                requireFeatureAuth,
                requireJourneyEditData,
                loadDataGuardDefault,
              ]}
              component={JourneyEditParticipantEditAnimLivePage}
            />
          )}
        />
        <Route
          path="/journey-edit/:customerJourneyId/participant-edit/:customerChildId"
          exact={true}
          render={() => (
            <AppRoute
              routeGuards={[
                requireFeatureAuth,
                requireJourneyEditData,
                loadDataGuardDefault,
              ]}
              component={JourneyEditParticipantEditAnimLivePage}
            />
          )}
        />
        {/* <Route
              path="/journey-edit/:customerJourneyId/participants-list"
              exact={true}
              render={() => (
                <AppRoute routeGuards={[requireFeatureAuth, loadCompanyGuard, loadDataGuardDefault]} component={JourneyEditParticipantsListAnimLivePage} />
              )}
            /> */}
        <Route
          path="/journey-create/:companyReference/account-edit"
          exact={true}
          render={() => (
            <AppRoute
              routeGuards={[
                requireFeatureAuth,
                requireJourneyEditData,
                loadDataGuardDefault,
              ]}
              component={AnimLiveCustomerAccountEditPage}
            />
          )}
        />
        <Route
          path="/anim-live/journey/:customerJourneyId/activities"
          exact={true}
          render={() => (
            <AppRoute
              component={AnimLiveJourneysActivitiesPage}
              auth={{ authenticationRequired: true }}
              routeGuards={[
                requireFeatureAuth,
                loadDataGuardDefault,
                requireJourneyStatusValidated,
              ]}
            />
          )}
        />
        <Route
          path="/journeys"
          exact={true}
          render={() => (
            <AppRoute
              component={JourneysListAnimLivePage}
              auth={{ authenticationRequired: true }}
            />
          )}
        />
        <Route
          path="/company/:companyReference/contact"
          exact={true}
          render={() => (
            <AppRoute
              component={CompanyContactPage}
              routeGuards={[loadDataGuardDefault]}
            />
          )}
        />
        <Route
          path="/group/:groupReference"
          exact={true}
          render={() => (
            <AppRoute
              component={CompanyGroupHomePage}
              routeGuards={[loadDataGuardDefault]}
            />
          )}
        />
        <Route
          path="/account/create/group/:groupReference/choose"
          exact={true}
          render={() => (
            <AppRoute
              component={AccountChooseCompanyPage}
              routeGuards={[requireFeatureAuth, loadDataGuardDefault]}
            />
          )}
        />
        <Route
          path="/account/create/company/choose"
          exact={true}
          render={() => (
            <AppRoute
              component={AccountChooseCompanyPage}
              routeGuards={[requireFeatureAuth]}
            />
          )}
        />
        <Route
          path="/account/create/company/choose/:companyReference"
          exact={true}
          render={() => (
            <AppRoute
              component={AccountChooseCompanyPage}
              routeGuards={[requireFeatureAuth, loadDataGuardDefault]}
            />
          )}
        />
        <Route
          path="/account/reset/password/query"
          exact={true}
          render={() => (
            <AppRoute
              component={ResetPasswordQueryPage}
              routeGuards={[requireFeatureAuth]}
            />
          )}
        />
        <Route
          path="/account/reset/password/confirm"
          exact={true}
          render={() => (
            <AppRoute
              component={ResetPasswordConfirmPage}
              routeGuards={[requireFeatureAuth]}
            />
          )}
        />
        <Route
          path="/old-version"
          exact={true}
          render={() => (
            <AppRoute
              component={OldVersionPage}
              routeGuards={[testRedirectFromOldVersion]}
            />
          )}
        />
        <Route
          path="/notifications"
          render={() => (
            <AppRoute
              component={NotificationsListPage}
              auth={{ authenticationRequired: true }}
              routeGuards={[requireFeatureAuth]}
            />
          )}
          exact={true}
        />
        {/*  Note: si on met exact={false}, alors le guard est TOUJOURS appelé, donc on est toujours redirigé en arrivant sur cette page */}
        <Route
          path="/"
          exact={true}
          render={() => (
            <AppRoute
              component={AuthPageHomeAnimLive}
              routeGuards={[redirectToDefaultRoute('route /')]}
            />
          )}
        />
        <Route
          path="/redirect"
          exact={true}
          render={() => (
            <AppRoute
              component={AuthPageHomeAnimLive}
              routeGuards={[redirectToDefaultRoute('route /redirect')]}
            />
          )}
        />
        <Route
          path="/ddebugg"
          exact={true}
          render={() => (
            <AppRoute
              component={DebugPage}
              auth={
                HACK_DEBUG_PAGE_PUBLIC_UNTIL_NEXT_DEPLOY
                  ? undefined
                  : { superAdminRequired: true }
              }
            />
          )}
        />
      </>
    )
  );
};
