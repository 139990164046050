import { IonButton, IonIcon } from '@ionic/react';
import { checkmark } from 'ionicons/icons';
import { APP_CURRENCIES_MAP, EcommerceBookingJourney } from 'lib-common-model';
import { appLogger } from 'lib-web-logger';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm, UseFormReturn, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { useAppCacheContext, useJourney } from '_01_CORE/app-contexts';
import { ecommerceBookingJourneyApiClient } from '_01_CORE/app-core/app-api';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import { useAppSettings } from '_01_CORE/app-core/app-security';
import {
  AccountCreationTitle,
  AppPage,
  FooterActions,
  HeaderToolbar,
  PageContainer,
} from '_01_CORE/_common-layout';
import { useAppRouter, useOperationPending } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import { ecommerceHelper } from '_01_CORE/_services';
import { useDeleteJourneyBookingAccroparkConfirm } from '_02_APP_COMMON/JOU-journey/_common';
import { AccroparkBookingResumeTable } from '../_common';
import {
  AccroparkBookingOptionsFormModel,
  AccroparkBookingOptionsGiftPromoCodeCard,
  AccroparkBookingOptionsWeatherAssuranceCard,
} from './form';

export const AccroparkBookingOptionsPage = () => {
  const appRouter = useAppRouter();
  const { formatMessage: t } = useIntl();
  const appId = appWebConfig().appId;

  const { customerJourneyId } = useParams<{ customerJourneyId: string }>();
  const { journey } = useJourney({ customerJourneyId });

  const companyOptions = journey?.company?.options;

  const deleteJourneyBookingConfirm = useDeleteJourneyBookingAccroparkConfirm({
    customerJourneyId,
  });
  const { booking: serverBooking } = useAppCacheContext();

  const defaultFormValue: AccroparkBookingOptionsFormModel = useMemo(() => {
    return {
      giftPromoDiscount: undefined,
      weatherInsuranceEnabled:
        serverBooking?.purchase?.options?.weatherInsuranceEnabled ?? false,
    };
  }, [serverBooking?.purchase?.options?.weatherInsuranceEnabled]);

  const form: UseFormReturn<AccroparkBookingOptionsFormModel> = useForm<AccroparkBookingOptionsFormModel>(
    {
      defaultValues: defaultFormValue,
      mode: 'onChange',
    }
  );

  const { control, register, formState, handleSubmit } = form;

  const giftPromoDiscount = useWatch({ control, name: 'giftPromoDiscount' });
  const weatherInsuranceEnabled = useWatch({
    control,
    name: 'weatherInsuranceEnabled',
  });

  const localBooking: EcommerceBookingJourney = useMemo(() => {
    if (!serverBooking) {
      return serverBooking;
    }
    const booking: EcommerceBookingJourney = {
      ...serverBooking,
      purchase: {
        ...serverBooking?.purchase,
        offers: ecommerceHelper.sortOffers(
          serverBooking?.purchase.offers ?? []
        ),
      },
    };
    return ecommerceHelper.updateAccroparkBookingOptions({
      booking,
      weatherInsuranceEnabled,
      giftPromoDiscount,
      companyOptions,
    });
  }, [
    companyOptions,
    giftPromoDiscount,
    serverBooking,
    weatherInsuranceEnabled,
  ]);

  const currencySymbol =
    APP_CURRENCIES_MAP[localBooking?.purchase.currency]?.symbol;

  const { auth } = useAppCacheContext();

  const isJourneyReplacementInProgress = useMemo(
    () =>
      journey?.status !== 'validated' &&
      !!journey?.replaceFromCustomerJourneyId,
    [journey?.replaceFromCustomerJourneyId, journey?.status]
  );

  const goBack = useCallback(() => {
    appRouter.navigate(
      appRoutesBuilder.getJourneyActivitiesUrl({
        customerJourneyId: journey?._id,
        category: 'animator',
      }),
      { cause: 'journey-options-back' }
    );
  }, [appRouter, journey?._id]);

  useEffect(() => {
    if (
      !journey ||
      !serverBooking ||
      !serverBooking?.purchase?.offers?.length
    ) {
      appLogger.warn(
        '[AccroparkBookingOptionsPage] No journey or booking: redirect back'
      );
      appRouter.navigate(appRoutesBuilder.getJourneysUrl(), {
        cause: 'boking-options-back',
      });
    }
  }, [appRouter, journey, serverBooking]);

  const onSubmit = useOperationPending(async () => {
    await ecommerceBookingJourneyApiClient.confirmJourneyBookingOptions({
      customerJourneyId,
      options: {
        ...localBooking?.purchase?.options,
        giftPromoDiscountCode: giftPromoDiscount?.giftPromoCode,
        weatherInsuranceEnabled,
      },
    });
    appRouter.navigate(
      appRoutesBuilder.getAccountEditAccroparkJourneyUrl({ customerJourneyId }),
      { cause: 'journey-options-submit' }
    );
  }, [
    appRouter,
    customerJourneyId,
    giftPromoDiscount?.giftPromoCode,
    localBooking?.purchase?.options,
    weatherInsuranceEnabled,
  ]);

  const appSettings = useAppSettings();
  const whiteLabel = appSettings?.universe?.whiteLabel;
  /**
   * Choose another destination button callback
   */
  const handleChooseDestination = useCallback(() => {
    appRouter.navigate(
      appRoutesBuilder.getJourneyCompanyChooseUrl({
        companyReference: journey?.company?.reference,
        whiteLabel,
      }),
      { cause: 'options-page-select-company' }
    );
  }, [appRouter, journey?.company, whiteLabel]);

  return !(!!journey && !!localBooking) ? null : (
    <AppPage displayFooter={false}>
      <HeaderToolbar hideLogout={true} />
      <PageContainer
        className="text-center bg-gray-50 flex flex-col items-stretch text-center"
        spacing="normal"
      >
        <AccountCreationTitle
          company={journey?.company}
          displayAddress={true}
          displayButtonChooseDestination={auth?.isAuthenticated}
          buttonText={t({ id: `page10.choose.${appId}` })}
          onClick={handleChooseDestination}
        />

        <div className="my-5 flex-grow">
          {!isJourneyReplacementInProgress && (
            <AccroparkBookingOptionsWeatherAssuranceCard
              currencySymbol={currencySymbol}
              form={form}
            />
          )}
          <AccroparkBookingOptionsGiftPromoCodeCard
            className="mt-5 mb-1"
            form={form}
            currencySymbol={currencySymbol}
            journey={journey}
          />

          <AccroparkBookingResumeTable
            booking={localBooking}
            company={journey?.company}
            hideReplaceInfo={true}
          />
        </div>

        <div className="my-4 bg-danger text-center text-white font-semibold p-2">
          ⚠️ {t({ id: 'page16.booking.timeRemaining' }, { time: 9 })}
        </div>
        {!isJourneyReplacementInProgress && (
          <div className="my-4 flex flex-col max-w-md mx-auto">
            <IonButton
              className="w-full"
              color="primary"
              onClick={() => onSubmit()}
            >
              <IonIcon icon={checkmark} className="mr-2" />
              {t({ id: 'common.action.continue.booking.payment' })}
            </IonButton>
          </div>
        )}
      </PageContainer>
      <FooterActions
        onCancel={() => deleteJourneyBookingConfirm()}
        onSubmit={onSubmit}
        onBack={() => goBack()}
      />
    </AppPage>
  );
};
