import { dateTester } from '_01_CORE/_components-core';
import { useAppRouter, useAppToasts } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import {
  CMProfile,
  CustomerMobileAuthenticationResult,
  UserProfile,
} from 'lib-common-model';
import { appLogger } from 'lib-web-logger';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { AuthMultiLoginAnimLivePageBaseLocalState } from '../useAuthMultiLoginAnimLivePageLocalState.hook';
import { AuthMultiLoginAction_RedirectToJourneyCreateDates } from './useAuthMultiLoginAction_RedirectToJourneyCreateDates.hook';
import { AuthMultiLoginAction_StartExtJourneyActivation } from './useAuthMultiLoginAction_StartExtJourneyActivation.hook';

export function useAuthMultiLoginAction_ProcessAuthenticationSuccess(
  baseLocalState: AuthMultiLoginAnimLivePageBaseLocalState,
  actions: {
    startExtJourneyActivation: AuthMultiLoginAction_StartExtJourneyActivation;
    redirectToJourneyCreateDates: AuthMultiLoginAction_RedirectToJourneyCreateDates;
  }
) {
  const { pushError, pushSuccess } = useAppToasts();
  const appRouter = useAppRouter();

  const { formatMessage: t } = useIntl();

  const { viewState, setViewState } = baseLocalState;

  const processAuthenticationSuccess = useCallback(
    (authResult: CustomerMobileAuthenticationResult) => {
      const { profile } = authResult;

      pushSuccess(t({ id: 'page01.connectionOK' }), 500);
      if ((profile as UserProfile<'multi-users-v2'>).type === 'super-admin') {
        appLogger.debug('[Redirect] to /ddebugg');
        appRouter.navigate(appRoutesBuilder.getDebugUrl(), {
          cause: 'login-success',
        });
      } else if (profile.type === 'customer') {
        const customerProfile = profile as CMProfile;

        if (
          viewState.contextAction === 'login' ||
          viewState.contextAction === 'register'
        ) {
          if (customerProfile?.extJourneysToActivate?.length > 0) {
            // séjour détecté: on redirige sur la page du séjour
            appRouter.navigate(appRoutesBuilder.getJourneysUrl(), {
              cause: 'login-success',
            });
          } else {
            // sinon, on sur la page d'accueil
            appRouter.navigate(appRoutesBuilder.getRedirectToDefaultUrl(), {
              cause: 'login-success',
            });
          }
        } else if (viewState.contextAction === 'activate') {
          // activation de séjour

          const fetchedJourney = viewState.journeyInfo?.fetchedJourney;
          const emailTestResult = viewState.promptEmail?.testResult;
          const existingJourney = profile.journeys.find((j) => {
            if (
              j.extCustomerJourneyBookingGroupId ===
              fetchedJourney?.extCustomerJourneyBookingGroupId
            ) {
              // séjour déjà activé pour cet utilisateur
              return true;
            } else if (j.companyId === fetchedJourney?.companyId) {
              const otherJourneyWithConflitDates = dateTester.havePeriodsCommonRange(
                {
                  period1: {
                    minDate: j.beginDateUTC,
                    maxDateExclusive: j.endDateExclusiveUTC,
                  },
                  period2: {
                    minDate: fetchedJourney?.fakeCustomerJourney?.beginDateUTC,
                    maxDateExclusive:
                      fetchedJourney?.fakeCustomerJourney?.endDateExclusiveUTC,
                  },
                }
              );

              return otherJourneyWithConflitDates;
            }

            return false;
          });
          if (existingJourney) {
            // le séjour est déjà rattaché à ce compte (ou bien on a trouvé un séjour avec un conflit sur les dates), on l'ouvre
            appRouter.navigate(
              appRoutesBuilder.getJourneyHomeUrl({
                customerJourneyId: existingJourney._id,
              }),
              { cause: 'activate-journey-already-exists' }
            );
          } else {
            if (fetchedJourney) {
              // démarrage de l'activation du séjour
              actions.startExtJourneyActivation({
                customerAccount: profile?.account,
                customerAccountMembers: profile?.members,
                fetchedJourney,
                emailAddress: emailTestResult?.login,
              });
            } else {
              // ça ne devrait pas arriver
              appLogger.error(
                'Can not activate journey - fetchedJourney missing'
              );
            }
          }
        }
      } else if (profile.type === 'company') {
        appLogger.debug('[Redirect] to /pro');
        appRouter.navigate(appRoutesBuilder.getProHomeUrl(), {
          cause: 'login-success',
        });
      }
    },
    [actions, appRouter, pushSuccess, t, viewState]
  );
  return processAuthenticationSuccess;
}

export type AuthMultiLoginAction_ProcessAuthenticationSuccess = ReturnType<
  typeof useAuthMultiLoginAction_ProcessAuthenticationSuccess
>;
