import { InlineCheckout } from '@bambora/checkout-sdk-web';
import { IonButton, IonIcon } from '@ionic/react';
import {
  AccountCreationTitle,
  AppPage,
  AppTitle1,
  FooterActions,
  HeaderToolbar,
  PageContainer,
} from '_01_CORE/_common-layout';
import { useAppModalContext } from '_01_CORE/_components-core';
import { useAppRouter } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import { useAppCacheContext, useJourney } from '_01_CORE/app-contexts';
import { ecommerceBookingJourneyApiClient } from '_01_CORE/app-core/app-api';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import { appBrowser, useAppPlatform } from '_01_CORE/app-core/app-platform';
import { useDeleteJourneyBookingAccroparkConfirm } from '_02_APP_COMMON/JOU-journey/_common';
import { checkmark } from 'ionicons/icons';
import { EcommerceBookingJourney } from 'lib-common-model';
import { appLogger } from 'lib-web-logger';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { AccroparkPaymentForm } from './AccroparkPaymentForm';
import { PaymentPageState } from './PaymentPageState.type';

const TEST_DEV_OPEN_BROWSER = false;

export const AccroparkPaymentPage = () => {
  const appRouter = useAppRouter();
  const { formatMessage: t } = useIntl();
  const { closeModal } = useAppModalContext();

  const { customerJourneyId } = useParams<{ customerJourneyId: string }>();
  const { journey } = useJourney({ customerJourneyId });
  const appCacheContext = useAppCacheContext();
  const { booking: initialBooking } = appCacheContext;

  const [booking, setBooking] = useState(initialBooking);

  const { platform } = useAppPlatform();

  const params = new URLSearchParams(window.location.search);
  const isIosWebWindowsOpenFromApp = params.get('open-from-app') === 'true';

  const bamboraMode =
    platform === 'ios' ||
    (TEST_DEV_OPEN_BROWSER &&
      appWebConfig().envId === 'dev' &&
      !isIosWebWindowsOpenFromApp)
      ? 'open-browser'
      : 'inline-iframe';

  const [paymentState, setPaymentState] = useState<PaymentPageState>(
    '1-payment-not-started'
  );

  useEffect(() => {
    (async () => {
      const isJourneyDefined = journey?._id;
      if (isJourneyDefined) {
        if (bamboraMode === 'open-browser') {
          setPaymentState('3-payment-in-progress-browser');
          await appBrowser.openBamboraPaymentPage({
            platform,
            customerJourneyId: journey?._id,
          });
        }
      } else {
        appRouter.navigateAfterDelay(
          appRoutesBuilder.getRedirectToDefaultUrl(),
          {
            cause: 'journey missing on payment page',
          }
        );
      }
    })();
  }, [appRouter, bamboraMode, journey, journey?._id, platform]);

  const updateCachesBooking = useCallback(
    (booking: EcommerceBookingJourney) => {
      setBooking(booking); // update local cache
      if (booking?.lastPaymentResume?.status === 'accepted') {
        // remove from local cache
        appCacheContext.dispatchCacheContextAction({
          type: 'set-booking',
          booking: undefined,
          context: 'payment-page-clear-booking',
        });
      } else {
        // add to local cache
        appCacheContext.dispatchCacheContextAction({
          type: 'set-booking',
          context: 'payment-page-update-booking',
          booking,
        });
      }
    },
    [appCacheContext]
  );

  const reloadBooking = useCallback(
    async (customerJourneyId: string) => {
      console.log('REFRESH NOW');
      const booking = await ecommerceBookingJourneyApiClient.getJourneyBooking({
        customerJourneyId,
      });
      updateCachesBooking(booking);

      if (booking?.lastPaymentResume?.status === 'accepted') {
        if (bamboraMode === 'open-browser') {
          try {
            appBrowser.closeBrowser();
          } catch {
            // ignore error (window already closed?)
          }
        }
        setPaymentState('6-payment-success');
      }
    },
    [bamboraMode, updateCachesBooking]
  );

  useEffect(() => {
    if (
      isAutoRefreshActive({
        paymentState,
        bamboraMode,
        isIosWebWindowsOpenFromApp,
      })
      // sur ios, on ouvre une autre fenêtre, donc il faut rafraichir la fenêtre courante régulièrement
    ) {
      console.log('AUTO REFRESH ON');
      // auto-refresh
      const handle = setInterval(async () => {
        if (
          isAutoRefreshActive({
            paymentState,
            bamboraMode,
            isIosWebWindowsOpenFromApp,
          })
        ) {
          return await reloadBooking(customerJourneyId);
        }
      }, 5000);
      return () => {
        clearInterval(handle);
      };
    }
  }, [
    bamboraMode,
    customerJourneyId,
    isIosWebWindowsOpenFromApp,
    paymentState,
    reloadBooking,
  ]);

  const isPaymentNotStarted = useMemo(
    () =>
      paymentState === '1-payment-not-started' ||
      paymentState === '4-payment-cancel' ||
      paymentState === '5-payment-error',
    [paymentState]
  );
  const showBackButton = isPaymentNotStarted;
  const showCancelButton = useMemo(() => paymentState !== '6-payment-success', [
    paymentState,
  ]);

  const goBack = useCallback(() => {
    appRouter.navigate(
      appRoutesBuilder.getAccountEditAccroparkJourneyUrl({ customerJourneyId }),
      { cause: 'payment-back' }
    );
  }, [appRouter, customerJourneyId]);

  async function confirmFreeBooking() {
    journey.status = 'payment-in-progress';
    setPaymentState('2-starting-payment');
    try {
      await ecommerceBookingJourneyApiClient.confirmFreeBooking({
        customerJourneyId,
      });
      reloadBooking(customerJourneyId);
      setPaymentState('6-payment-success');
    } catch (err) {
      setPaymentState('5-payment-error');
      appLogger.debug('Unexpected error during payment', err);
    }
  }

  const onDeleteJourney = useCallback(() => {
    if (paymentState === '3-payment-in-progress-browser') {
      appBrowser.closeBrowser();
    }
  }, [paymentState]);

  const deleteJourneyBookingConfirm = useDeleteJourneyBookingAccroparkConfirm({
    customerJourneyId,
    onConfirm: onDeleteJourney,
  });
  const checkoutRef: React.MutableRefObject<InlineCheckout> = useRef<InlineCheckout>();

  const onClickCancel = useCallback(() => {
    if (
      paymentState === '3-payment-in-progress-inline' ||
      paymentState === '3-payment-in-progress-browser'
    ) {
      setPaymentState('4-payment-cancel');
      if (checkoutRef.current) {
        checkoutRef.current.destroy();
      }
    } else {
      deleteJourneyBookingConfirm();
    }
  }, [deleteJourneyBookingConfirm, paymentState]);
  return !journey ? null : (
    <AppPage displayFooter={false}>
      <HeaderToolbar hideLogout={true} />
      <PageContainer
        className="h-full bg-gray-50 flex flex-col items-stretch text-center"
        spacing="normal"
      >
        <AccountCreationTitle
          company={journey.company}
          displayAddress={true}
          displayButtonChooseDestination={false}
        />
        {paymentState === '1-payment-not-started' && (
          <AppTitle1 className="flex items-center justify-center" arrow="right">
            {t({ id: 'page25.payment' })}
          </AppTitle1>
        )}

        <div className="my-2 flex-grow">
          <AccroparkPaymentForm
            checkoutRef={checkoutRef}
            booking={booking}
            reloadBooking={reloadBooking}
            company={journey.company}
            paymentState={paymentState}
            setPaymentState={setPaymentState}
            confirmFreeBooking={confirmFreeBooking}
            bamboraMode={bamboraMode}
          />
        </div>

        {paymentState === '6-payment-success' && (
          <>
            <div className="text-green-600 text-lg mx-auto">
              <IonIcon icon={checkmark} className="mr-2" />
              {t({ id: 'page25.payment.success' })}
            </div>
            <IonButton
              className="w-full min-h-app-btn-30 max-w-xs mx-auto mt-2"
              color="primary"
              onClick={() => {
                closeModal();
                appRouter.navigate(
                  appRoutesBuilder.getJourneyHomeUrl({ customerJourneyId }),
                  { cause: 'payment-success' }
                );
              }}
            >
              {t({ id: 'page25.button.seeBooking' })}
            </IonButton>
          </>
        )}

        <div
          hidden={!isPaymentNotStarted}
          className="my-4 bg-danger text-center text-white font-semibold p-2"
        >
          ⚠️ {t({ id: 'page16.booking.timeRemaining' }, { time: 8 })}
        </div>
      </PageContainer>
      {(showCancelButton || showBackButton) && (
        <FooterActions
          onCancel={showCancelButton ? onClickCancel : undefined}
          onBack={showBackButton ? goBack : undefined}
        />
      )}
    </AppPage>
  );
};
function isAutoRefreshActive({
  paymentState,
  bamboraMode,
  isIosWebWindowsOpenFromApp,
}: {
  paymentState: string;
  bamboraMode: string;
  isIosWebWindowsOpenFromApp: boolean;
}) {
  return (
    paymentState === '1-payment-not-started' ||
    paymentState === '4-payment-cancel' ||
    paymentState === '5-payment-error' ||
    paymentState === '6-payment-success' ||
    (bamboraMode === 'open-browser' && !isIosWebWindowsOpenFromApp)
  );
}
