import { AppClientNotification, LanguageCode } from 'lib-common-model';
import { apiClient } from 'lib-web-api-client';

export const customerNotificationsApiClient = {
  getLastNotifications,
  readNotifications,
  readNotification,
};

async function getLastNotifications({
  appLanguageCode,
  platformLanguageCode,
}: {
  appLanguageCode: LanguageCode;
  platformLanguageCode: LanguageCode;
}): Promise<AppClientNotification[]> {
  return await apiClient.get<any>(`/customer-web-mobile-notifications`, {
    options: {
      authenticate: true,
      searchParams: {
        platformLanguageCode,
        appLanguageCode,
      },
    },
  });
}

async function readNotifications({
  appLanguageCode,
  platformLanguageCode,
}: {
  appLanguageCode: LanguageCode;
  platformLanguageCode: LanguageCode;
}): Promise<AppClientNotification[]> {
  return await apiClient.put<any>(`/customer-web-mobile-notifications/read`, {
    options: {
      authenticate: true,
      json: {
        platformLanguageCode,
        appLanguageCode,
      },
    },
  });
}
async function readNotification({
  platformLanguageCode,
  appLanguageCode,
  messageId,
}: {
  messageId: string;
  platformLanguageCode: LanguageCode;
  appLanguageCode: LanguageCode;
}): Promise<void> {
  return await apiClient.put<any>(
    `/customer-web-mobile-notifications/read-by-id`,
    {
      options: {
        authenticate: true,
        json: {
          messageId,
          platformLanguageCode,
          appLanguageCode,
        },
      },
    }
  );
}
