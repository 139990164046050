import {
  IonButton,
  IonCheckbox,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonThumbnail,
} from '@ionic/react';
import { FormError, useAppModalContext } from '_01_CORE/_components-core';
import { AppInputIonic } from '_01_CORE/_components-core/AppInput';
import { useAppCacheContext } from '_01_CORE/app-contexts';
import { appBrowser } from '_01_CORE/app-core/app-platform';
import { CustomerAccountEditFormData } from '_02_APP_COMMON/_model';
import { arrowBack, checkmark } from 'ionicons/icons';
import {
  COUNTRIES_ARRAY,
  COUNTRIES_MAP,
  GENDERS,
  LANGUAGES_ARRAY,
  LANGUAGES_MAP,
} from 'lib-common-model';
import { useCallback } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';

export const AnimLiveCustomerAccountForm = ({
  form,
  onSubmit,
  onInvalid,
}: {
  form: UseFormReturn<CustomerAccountEditFormData>;
  onSubmit: (data: CustomerAccountEditFormData) => any;
  onInvalid: () => any;
}) => {
  const { formatMessage: t } = useIntl();
  const { journeyEditData } = useAppCacheContext();
  const { openModal, closeModal, isVisible } = useAppModalContext();

  const { handleSubmit, formState, setValue, control, trigger } = form;

  const errors = formState.errors;

  const openCguLink = () => {
    appBrowser.openUrl({
      platform: 'web',
      url: 'https://www.anim-live.com/cgu',
    });
  };

  const openConfirmESignModal = useCallback(() => {
    openModal({
      title: t({ id: 'modal.confirmation' }),
      content: <div>{t({ id: 'page14.electronicSignature.confirm' })}</div>,
      actions: (
        <div className="flex flex-col w-full">
          <IonButton
            color="primary"
            onClick={() => {
              closeModal();
              setValue('electronicSignature', true);
              trigger('electronicSignature');
            }}
          >
            <IonIcon icon={checkmark} className="mr-2" />
            {t({ id: 'common.action.ok' })}
          </IonButton>
          <IonButton color="secondary" onClick={closeModal}>
            <IonIcon icon={arrowBack} className="mr-2" />
            {t({ id: 'common.action.cancel' })}
          </IonButton>
        </div>
      ),
    });
  }, [closeModal, openModal, setValue, t, trigger]);

  return (
    <form onSubmit={handleSubmit(onSubmit, onInvalid)}>
      {/* Civility */}
      <Controller
        render={({ field: { value, onChange } }) => (
          <AppInputIonic
            className="mt-2"
            required={true}
            placeholder={t({ id: 'page14.gender' })}
            value={value}
          >
            <IonSelect
              interface="popover"
              okText={t({ id: 'common.action.ok' })}
              cancelText={t({ id: 'common.action.cancel' })}
              onIonChange={onChange}
            >
              {GENDERS.map((gender) => (
                <IonSelectOption key={`gender-${gender}`} value={gender}>
                  {t({ id: `gender.${gender}` })}
                </IonSelectOption>
              ))}
            </IonSelect>
          </AppInputIonic>
        )}
        control={control}
        name="gender"
        defaultValue={'' as any}
        rules={{
          required: {
            value: true,
            message: t({ id: 'validation.required' }),
          },
        }}
      />
      <FormError error={errors.gender?.message} />

      {/* Lastname */}
      <Controller
        render={({ field: { value, onChange } }) => (
          <AppInputIonic
            className="mt-2"
            required={true}
            placeholder={t({ id: 'page12.lastName' })}
            value={value}
          >
            <IonInput onIonChange={onChange} />
          </AppInputIonic>
        )}
        control={control}
        name="lastName"
        defaultValue=""
        rules={{
          required: {
            value: true,
            message: t({ id: 'validation.required' }),
          },
        }}
      />
      <FormError error={errors.lastName?.message} />

      {/* Firstname */}
      <Controller
        render={({ field: { value, onChange } }) => (
          <AppInputIonic
            className="mt-2"
            required={true}
            placeholder={t({ id: 'page12.firstName' })}
            value={value}
          >
            <IonInput onIonChange={onChange} />
          </AppInputIonic>
        )}
        control={control}
        name="firstName"
        defaultValue=""
        rules={{
          required: {
            value: true,
            message: t({ id: 'validation.required' }),
          },
        }}
      />
      <FormError error={errors.firstName?.message} />

      {/* Phone 1 */}
      <Controller
        render={({ field: { value, onChange } }) => (
          <AppInputIonic
            className="mt-2"
            required={true}
            placeholder={t({ id: 'page14.phone' }, { number: 1 })}
            value={value}
          >
            <IonInput onIonChange={onChange} type="tel" inputmode="tel" />
          </AppInputIonic>
        )}
        control={control}
        name="phone1"
        defaultValue=""
        rules={{
          pattern: {
            value: /^([\d. \-+]){6,30}$/,
            message: t({ id: 'validation.phone' }),
          },
          required: {
            value: true,
            message: t({ id: 'validation.required' }),
          },
        }}
      />
      <FormError error={errors.phone1?.message} />

      {/* Phone 2 */}
      <Controller
        render={({ field: { value, onChange } }) => (
          <AppInputIonic
            className="mt-2"
            placeholder={t({ id: 'page14.phone' }, { number: 2 })}
            value={value}
          >
            <IonInput onIonChange={onChange} type="tel" inputmode="tel" />
          </AppInputIonic>
        )}
        control={control}
        name="phone2"
        defaultValue=""
      />
      <FormError error={errors.phone2?.message} />

      {/* Country */}
      <Controller
        render={({ field: { value, onChange } }) => (
          <AppInputIonic
            className="mt-2"
            required={true}
            placeholder={t({ id: 'page12.country' })}
            value={value}
          >
            <div className="flex w-full items-center">
              {COUNTRIES_MAP[value] && (
                <IonThumbnail slot="start" className="w-8 h-6 ml-2">
                  <IonImg
                    class="rounded-full"
                    src={COUNTRIES_MAP[value].imgUrl}
                  />
                </IonThumbnail>
              )}
              <IonSelect
                interface="action-sheet"
                okText={t({ id: 'common.action.ok' })}
                cancelText={t({ id: 'common.action.cancel' })}
                placeholder={t({ id: 'page12.country' }) + ' *'}
                onIonChange={(e) => {
                  onChange(e);
                  if (COUNTRIES_MAP[e.detail.value]?.defaultLanguage) {
                    setValue(
                      'language',
                      COUNTRIES_MAP[e.detail.value]?.defaultLanguage
                    );
                  }
                }}
                value={value}
              >
                {COUNTRIES_ARRAY.map((country) => (
                  <IonSelectOption
                    key={`country-${country.id}`}
                    value={country.id}
                  >
                    {t({ id: `country.${country.id}` })}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </div>
          </AppInputIonic>
        )}
        control={control}
        name="country"
        defaultValue={'' as any}
        rules={{
          required: {
            value: true,
            message: t({ id: 'validation.required' }),
          },
        }}
      />
      <FormError error={errors.country?.message} />

      {/* Language */}
      <Controller
        render={({ field: { value, onChange } }) => (
          <AppInputIonic
            className="mt-2"
            required={true}
            placeholder={t({ id: 'page12.language' })}
            value={value}
          >
            <div className="flex w-full items-center">
              {LANGUAGES_MAP[value] && (
                <IonThumbnail slot="start" className="w-8 h-6 ml-2">
                  <IonImg
                    className="rounded-full"
                    src={LANGUAGES_MAP[value].imgUrl}
                  />
                </IonThumbnail>
              )}
              <IonSelect
                interface="action-sheet"
                okText={t({ id: 'common.action.ok' })}
                cancelText={t({ id: 'common.action.cancel' })}
                placeholder={t({ id: 'page12.language' }) + ' *'}
                onIonChange={onChange}
                value={value}
              >
                {LANGUAGES_ARRAY.map((language) => (
                  <IonSelectOption
                    key={`country-${language.id}`}
                    value={language.id}
                  >
                    {language.labelOriginal}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </div>
          </AppInputIonic>
        )}
        control={control}
        name="language"
        defaultValue={'' as any}
        rules={{
          required: {
            value: true,
            message: t({ id: 'validation.required' }),
          },
        }}
      />
      <IonItem className="hidden">
        <Controller
          render={({ field: { value, onChange } }) => (
            <IonInput value={value} />
          )}
          control={control}
          name="notificationLanguage"
          defaultValue={'' as any}
        />
      </IonItem>
      <FormError error={errors.language?.message} />

      {journeyEditData.mode === 'create' && (
        <>
          {/* Email */}
          <Controller
            render={({ field: { value, onChange } }) => (
              <AppInputIonic
                className="mt-2"
                required={true}
                placeholder={t({ id: 'form.label.email' })}
                value={value}
              >
                <IonInput
                  onIonChange={onChange}
                  type="email"
                  inputmode="email"
                  readonly={
                    value && journeyEditData.customerAccount?._id === undefined
                  }
                />
              </AppInputIonic>
            )}
            control={control}
            name="email"
            defaultValue=""
            rules={{
              pattern: {
                value: /^(\s?)+(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))(\s?)+$/,
                message: t({ id: 'validation.email' }),
              },
              required: {
                value: true,
                message: t({ id: 'validation.required' }),
              },
            }}
          />
          <FormError error={errors.email?.message} />

          {journeyEditData.customerAccount?._id === undefined && (
            <>
              {/* Password */}
              <Controller
                render={({ field: { value, onChange } }) => (
                  <AppInputIonic
                    className="mt-2"
                    required={true}
                    placeholder={t({ id: 'form.label.password.create' })}
                    value={value}
                  >
                    <IonInput onIonChange={onChange} />
                  </AppInputIonic>
                )}
                control={control}
                name="password"
                defaultValue=""
                rules={{
                  required: {
                    value: true,
                    message: t({ id: 'validation.required' }),
                  },
                }}
              />
              <FormError error={errors.password?.message} />
            </>
          )}
        </>
      )}

      <IonItem className="mt-2">
        <IonLabel>{t({ id: 'page14.electronicSignature' })}*</IonLabel>
        <Controller
          render={({ field: { value, onChange } }) => (
            <IonCheckbox
              slot="start"
              checked={value}
              onIonChange={(e) => {
                if (e.detail.checked === value || isVisible) return;
                e.detail.checked ? openConfirmESignModal() : onChange(false);
              }}
            />
          )}
          control={control}
          name="electronicSignature"
          defaultValue={false}
          rules={{
            required: {
              value: true,
              message: t({ id: 'validation.required' }),
            },
          }}
        />
      </IonItem>
      <FormError error={errors.electronicSignature?.message} />

      <IonItem className="mt-2">
        <IonLabel>
          {t({ id: 'page14.accept' })}{' '}
          <a className="underline cursor-pointer" onClick={() => openCguLink()}>
            {t({ id: 'page14.terms' })}
          </a>
          *
        </IonLabel>
        <Controller
          render={({ field: { value, onChange } }) => (
            <IonCheckbox
              slot="start"
              value={value as any}
              checked={value}
              onIonChange={(e) => onChange(e.detail.checked)}
            />
          )}
          control={control}
          name="consent"
          defaultValue={false}
          rules={{
            required: {
              value: true,
              message: t({ id: 'validation.required' }),
            },
          }}
        />
      </IonItem>
      <FormError error={errors.consent?.message} />

      <div className="text-danger my-4">
        {t({ id: 'page14.warning.booking' })}
      </div>
    </form>
  );
};
