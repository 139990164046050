import {
  AppAuthenticatedUser,
  AuthUserProfile,
  AuthUserRole,
} from 'lib-common-model';

export const appRolesBuilder = {
  getAppRoles,
};

function getAppRoles(
  authenticatedUser: Omit<AppAuthenticatedUser, 'roles'>
): AuthUserRole[] {
  if (!authenticatedUser) {
    return [];
  }
  const profile: AuthUserProfile = authenticatedUser.profile;
  const rootProfile: AuthUserProfile =
    authenticatedUser.impersonate?.root?.profile ?? authenticatedUser.profile;

  const roles: AuthUserRole[] = [];

  if (profile === 'super-admin') {
    roles.push('super-admin');
  } else if (profile === 'company') {
    if (authenticatedUser.oldMongoRoles.includes('business')) {
      roles.push('company-admin');
    }
    if (rootProfile === 'super-admin') {
      // ajout des droits spécifiques au super-admin
      roles.push('super-admin');
    }

    // roles = roles
    //   .concat(buildRolesFromClubAuthorizations(clubAuthorizations))
    //   .concat(['club', 'account', 'account-profile']);
  } else if (profile === 'customer') {
    roles.push('customer');
    if (rootProfile === 'super-admin') {
      // ajout des droits spécifiques au super-admin
      roles.push('super-admin');
    }
    if (rootProfile === 'company') {
      //  ajout des droits spécifiques au super-admin
      roles.push('company-admin');
    }
  } else if (rootProfile === 'customer') {
    // NOTE: c'est quoi ce scénario?
    // ajout des droits spécifiques au super-admin
    roles.push('customer');
  } else if (rootProfile === 'temporary-token') {
    // ajout des droits spécifiques au "temporary-token"
    // roles.push('customer');
  } else {
    throw new Error(`Invalid user profile "${profile}"`);
  }

  return roles;
}

// function buildRolesFromClubAuthorizations(
//   clubAuthorizations: ClubAuthUserAuthorizations
// ): AuthUserRole[] {
//   if (clubAuthorizations) {
//     return [].concat(
//       buildRolesViewFromClubAuthorizations(clubAuthorizations),

//       buildRolesEditFromClubAuthorizations(clubAuthorizations),
//       buildRolesDownloadFromClubAuthorizations(clubAuthorizations),
//       buildRolesMultiDiveCentersFromClubAuthorizations(clubAuthorizations)
//     );
//   }
//   return [];
// }

// function buildRolesViewFromClubAuthorizations(
//   clubAuthorizations: ClubAuthUserAuthorizations
// ): AuthUserRole[] {
//   const roles: AuthUserRole[] = [];

//   const clubViewPlanning =
//     clubAuthorizations.view?.planning?.participant ||
//     clubAuthorizations.view?.planning?.event;
//   if (clubViewPlanning) {
//     roles.push('club-view-planning');
//     if (clubAuthorizations.view?.planning?.participant) {
//       roles.push('club-view-planning-participant');
//     }
//     if (clubAuthorizations.view?.planning?.event) {
//       roles.push('club-view-planning-event');
//     }
//   }
