import {
  AppRichTextElementTextAlign,
  CompanyOptionsTheme,
} from 'lib-common-model';
import React, { useMemo } from 'react';

interface RenderElementProps {
  // from 'slate-react':
  children: any;
  element: any;
  attributes: {
    'data-slate-node': 'element';
    'data-slate-inline'?: true;
    'data-slate-void'?: true;
    dir?: 'rtl';
    ref: any;
  };
}
export function AppRichTextSlateElement<A>({
  attributes = {} as any,
  children,
  element,
  theme,
}: Omit<RenderElementProps, 'attributes'> & {
  attributes?: A;
  theme: CompanyOptionsTheme;
}) {
  const style: React.CSSProperties = useMemo(() => {
    if (element.type === 'heading-one') {
      return { color: theme.title1Color };
    }
    if (element.type === 'heading-two') {
      return { color: theme.title2Color };
    }
    if (element.type === 'p' || element.type === 'paragraph') {
      return { color: theme.textColor };
    }
  }, [element.type, theme.textColor, theme.title1Color, theme.title2Color]);

  const className = useMemo(() => {
    return [getAlignmentClass(element.textAlign)].filter((x) => !!x).join(' ');
  }, [element.textAlign]);

  switch (element.type) {
    // case 'block-quote':
    //   return (
    //     <blockquote className={`text-gray-600 ${className}`} {...attributes}>
    //       {children}
    //     </blockquote>
    //   );
    // case 'bulleted-list':
    //   return (
    //     <ul className={`text-gray-600 ${className}`} {...attributes}>
    //       {children}
    //     </ul>
    //   );
    case 'heading-one':
      return (
        <h1
          className={`mt-2 font-bold text-lg mm:text-xl ${className}`}
          {...attributes}
          style={style}
        >
          {children}
        </h1>
      );
    case 'heading-two':
      return (
        <h2
          className={`mt-1 font-bold text-base mm:text-lg ${className}`}
          {...attributes}
          style={style}
        >
          {children}
        </h2>
      );
    // case 'list-item':
    //   return (
    //     <li className={`text-gray-600 ${className}`} {...attributes}>
    //       {children}
    //     </li>
    //   );
    // case 'numbered-list':
    //   return (
    //     <ol className={`text-gray-600 ${className}`} {...attributes}>
    //       {children}
    //     </ol>
    //   );
    default:
      return (
        <p className={`mt-0.5 ${className}`} {...attributes} style={style}>
          {children}
        </p>
      );
  }
}

function getAlignmentClass(textAlign: AppRichTextElementTextAlign) {
  switch (textAlign) {
    case 'right':
      return 'text-right';
    case 'center':
      return 'text-center';
    case 'justify':
      return 'text-justify';
    case 'left':
    default:
      return 'text-left';
  }
}
