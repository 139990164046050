import { AppRichTextElementTextAlign } from 'lib-common-model';

export const appRichTextRenderer = {
  getAlignmentClass,
};
function getAlignmentClass(textAlign: AppRichTextElementTextAlign) {
  switch (textAlign) {
    case 'right':
      return 'text-right';
    case 'center':
      return 'text-center';
    case 'justify':
      return 'text-justify';
    case 'left':
    default:
      return 'text-left';
  }
}
