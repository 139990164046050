import { journeyHelper } from '_01_CORE/_services';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import { AppSecurityUser, AppSettings, CMJourney } from 'lib-common-model';
import { dateServiceCore } from 'stories/services';
import { JourneyEnabledActions } from './JourneyEnabledActions.type';

export const journeyEnabledActionsBuilder = { buildJourneyEnabledActions };

function buildJourneyEnabledActions({
  journey,
  securityUser,
  appSettings,
}: {
  journey?: CMJourney; // séjour pas forcément existant (création)
  securityUser?: AppSecurityUser; // pas forcément connecté
  appSettings: AppSettings;
}): JourneyEnabledActions {
  const appId = appWebConfig().appId;
  const today = dateServiceCore.setTimeUTC(new Date());
  const remainingDays = dateServiceCore.getDiffInDaysRelative(
    today,
    journey?.endDateExclusiveUTC
  );

  const isPast = remainingDays < 0; // on autorise les modifs jusqu'au lendemain du séjour
  const hasAnyAttendance = journeyHelper.hasJourneyAnyAttendanceOrPreRegistration(
    journey
  );

  const isSuperAdmin =
    securityUser?.impersonate?.root?.profile === 'super-admin';

  const isAddJourneyParticipantEnabled =
    appSettings?.features?.customers?.enableJourneyParticipantCreation ||
    isSuperAdmin ||
    securityUser?.proInMobileApp?.overrideAppSettingsCustomersRestrictions;

  const isCreateJourneyEnabled =
    appSettings?.features?.customers?.enableJourneyCreation ||
    isSuperAdmin ||
    securityUser?.proInMobileApp?.overrideAppSettingsCustomersRestrictions ||
    false;

  const isCreateJourneyByActivationKeyEnabled = appId === 'anim-live';

  const isActivateJourneyMultipleEnabled =
    appSettings?.features?.customers?.enableJourneyMultiplePMSActivation ||
    isSuperAdmin ||
    securityUser?.proInMobileApp?.overrideAppSettingsCustomersRestrictions;

  if (appId === 'accropark') {
    const isAccroPaymentValidated =
      appId === 'accropark' && journey?.status === 'validated';

    const isJourneyEditEnabled =
      (appSettings?.features?.customers?.enableJourneyEdition ||
        isSuperAdmin ||
        securityUser?.proInMobileApp
          ?.overrideAppSettingsCustomersRestrictions) &&
      !isPast &&
      !isAccroPaymentValidated;

    const isJourneyDeleteEnabled =
      (appSettings?.features?.customers?.enableJourneyEdition ||
        isSuperAdmin ||
        securityUser?.proInMobileApp
          ?.overrideAppSettingsCustomersRestrictions) &&
      !isPast &&
      !hasAnyAttendance &&
      !isAccroPaymentValidated;

    return {
      isActivateJourneyMultipleEnabled,
      isAddJourneyParticipantEnabled,
      isCreateJourneyEnabled,
      isCreateJourneyByActivationKeyEnabled,
      isJourneyEditEnabled,
      isJourneyDeleteEnabled,
    };
  } else {
    // appId === 'anim-live'

    const isJourneyEditEnabled =
      (appSettings?.features?.customers?.enableJourneyEdition ||
        securityUser?.proInMobileApp
          ?.overrideAppSettingsCustomersRestrictions) &&
      (!isPast || isSuperAdmin);

    const isJourneyDeleteEnabled =
      !hasAnyAttendance &&
      (appSettings?.features?.customers?.enableJourneyEdition ||
        securityUser?.proInMobileApp
          ?.overrideAppSettingsCustomersRestrictions) &&
      (!isPast || isSuperAdmin);

    return {
      isActivateJourneyMultipleEnabled,
      isAddJourneyParticipantEnabled,
      isCreateJourneyEnabled,
      isCreateJourneyByActivationKeyEnabled,
      isJourneyEditEnabled: isJourneyEditEnabled,
      isJourneyDeleteEnabled,
    };
  }
}
