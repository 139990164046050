import { useGetInfo } from '@capacitor-community/react-hooks/device';
import { IonApp, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';
import * as Sentry from '@sentry/react';
import {
  AppLoader,
  AppModal,
  AppModalContextProvider,
  Compose,
} from '_01_CORE/_components-core';
import {
  AppErrorFallback,
  AppLoadingErrorPanel,
} from '_01_CORE/_components-core/AppErrorFallback';
import {
  AppCacheContextProvider,
  useAppLocalizationContext,
} from '_01_CORE/app-contexts';
import { appBootstrap } from '_01_CORE/app-core/app-bootstrap/appBootstrap.service';
import { appStore } from '_01_CORE/app-core/app-store';
import { NotificationsProvider, ToastsProvider } from '_01_CORE/providers';
import { appLogger } from 'lib-web-logger';
import { useLoadable } from 'lib-web-redux';
import React, { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { AppRoutes } from './AppRoutes';
import { AppTailwindDynamicClasses } from './AppTailwindDynamicClasses';
import './theme/app-global.css';
import './theme/app-text.css';
import './theme/tailwind.scss';
appLogger.warn(`[App.tsx] loading css...`);
require(`./theme/${process.env.REACT_APP_ID}/variables.css`);
require(`./theme/app.scss`);

appLogger.warn(`[App.tsx] css loaded`);

setupIonicReact({
  // scrollAssist: false,
});

const App: React.FC = () => {
  const { info: deviceInfo } = useGetInfo();

  useEffect(() => {
    if (deviceInfo) {
      appStore.deviceInfo.set(deviceInfo);
    }
  }, [deviceInfo]);

  const { _loaded, _error } = useLoadable({
    debugName: 'App',
    initialValueFromLoadSnapshot: false,
    load: () => appBootstrap.init(),
  });

  const {
    locales: { locale },
    messages,
  } = useAppLocalizationContext();

  return (
    <Sentry.ErrorBoundary fallback={AppErrorFallback}>
      <IntlProvider locale={locale} messages={messages}>
        {_loaded ? (
          <Compose
            components={[
              ToastsProvider,
              AppModalContextProvider,
              AppCacheContextProvider,
              NotificationsProvider,
            ]}
          >
            <IonApp>
              {/*      tailwind dynamically used classes */}
              <AppTailwindDynamicClasses />
              <IonReactRouter>
                {/* Note: on met les routes en dehors de IonRouterOutlet à cause de ce bug (et si on le retire carément, le footer n'apparait plus sous chrome): https://github.com/ionic-team/ionic-framework/issues/20597 */}
                {/* <IonRouterOutlet animated={false}> */}
                <AppRoutes />
              </IonReactRouter>
            </IonApp>
            <AppModal />
          </Compose>
        ) : (
          <div className="bg-gray-50 h-screen w-screen">
            {_error ? (
              <AppLoadingErrorPanel />
            ) : (
              <div className="bg-blue-100 p-4">
                <AppLoader
                  className="flex items-center justify-center"
                  type="loading"
                />
              </div>
            )}
          </div>
        )}
      </IntlProvider>
    </Sentry.ErrorBoundary>
  );
};
export default App;
