import { useMemo } from 'react';
import { imageUrlBuilder } from '../app-api';
import { useAppSettings } from './useAppSettings.hook';

export function useAppLogoUrl(size: 'md'): string {
  const appSettings = useAppSettings();
  const appLogo = appSettings.universe?.identity?.appLogo;
  const appLogoUrl = useMemo(
    () =>
      !appLogo
        ? undefined
        : imageUrlBuilder.getImageFullUrl({
            image: appLogo[size],
          }),
    [appLogo, size]
  );
  return appLogoUrl;
}
