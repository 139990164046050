import { IonIcon, IonRouterLink } from '@ionic/react';
import {
  AccountCreationTitle,
  AppPage,
  FooterActions,
  HeaderToolbar,
  PageContainer,
} from '_01_CORE/_common-layout';
import { AppLoader } from '_01_CORE/_components-core';
import {
  useAppRouter,
  useAppToasts,
  useJourneyCancellation,
  useOperationPending,
} from '_01_CORE/_hooks';
import { ArrowRight } from '_01_CORE/_icons';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import { useAppCacheContext, useCompany } from '_01_CORE/app-contexts';
import { customerJourneyApiClient } from '_01_CORE/app-core/app-api';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import { useNetworkStatus } from '_01_CORE/app-core/app-platform';
import { useAppSettings } from '_01_CORE/app-core/app-security';
import { useJourneyEditDataDraftOrRedirect } from '_02_APP_COMMON/JOU-journey/JOU-10-journey-choose-dates';
import { chevronBackOutline } from 'ionicons/icons';
import {
  CMJourneyEditCustomerAccount,
  CMJourneyEditParticipant,
  CustomerAccountCreatePayload,
} from 'lib-common-model';
import { appLogger } from 'lib-web-logger';
import { useCallback, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { JourneyParticipantInlineEditFormData } from '../JourneyParticipantInlineEditFormData.type';
import { JourneyCreateParticipantsFormAccropark } from './JourneyCreateParticipantsFormAccropark';
import { useJourneyParticipantsListAccroparkInitialState } from './useJourneyParticipantsListAccroparkInitialState.hook';

export const JourneyCreateParticipantsListAccroparkPage = () => {
  const appRouter = useAppRouter();
  const { formatMessage: t } = useIntl();
  const appCacheContext = useAppCacheContext();
  const { auth, journeyEditData, dispatchCacheContextAction } = appCacheContext;
  const appId = appWebConfig().appId;

  const { companyReference } = useParams<{ companyReference: string }>();
  const { company } = useCompany({ companyReference });
  const { pushError } = useAppToasts();
  const { openModalCancelJourneyDraft } = useJourneyCancellation({
    companyReference,
  });

  useJourneyEditDataDraftOrRedirect();

  const {
    defaultValues,
    editParticipants,
  } = useJourneyParticipantsListAccroparkInitialState();

  const form = useForm<JourneyParticipantInlineEditFormData>({
    defaultValues,
    mode: 'all',
  });
  const { handleSubmit, formState, setValue, control, getValues } = form;
  const formParticipants = useFieldArray({
    control,
    name: 'participants',
  });

  const { networkStatus } = useNetworkStatus();
  const isParticipantInvalid = (p: CMJourneyEditParticipant) =>
    !p.attributes.journeyAge || !p.member.firstName || !p.member.gender;

  const formParticipantsEnabledCount = formParticipants.fields?.filter(
    (x: any) => !isParticipantInvalid(x) && x.enabled
  ).length;

  const appSettings = useAppSettings();
  const whiteLabel = appSettings?.universe?.whiteLabel;
  /**
   * Choose another destination button callback
   */
  const handleChooseDestination = useCallback(() => {
    appRouter.navigate(
      appRoutesBuilder.getJourneyCompanyChooseUrl({
        companyReference: companyReference,
        whiteLabel,
      }),
      { cause: 'participant-list-select-company' }
    );
  }, [appRouter, companyReference, whiteLabel]);

  const [persistInProgress, setPersistInProgress] = useState(false);

  const onSubmit = useOperationPending(
    async (data: JourneyParticipantInlineEditFormData) => {
      if (persistInProgress) {
        return;
      }
      setPersistInProgress(true);
      appLogger.debug(
        '[JourneyCreateParticipantsListAccroparkPage] submit data',
        data
      );
      try {
        const isThereAtLeast1Participant = !!data.participants.filter(
          (p) => p.enabled
        ).length;

        const participants: CMJourneyEditParticipant[] = data.participants
          .map((p, i) => {
            const birthDate = new Date();
            birthDate.setFullYear(
              birthDate.getFullYear() - p.attributes.journeyAge
            );
            const participant: CMJourneyEditParticipant = {
              ...p,
              member: {
                ...p.member,
                birthDate,
                isExactBirthDate: false,
              },
              enabled: data.participants[i].enabled,
              customerChildId: editParticipants[i]?.customerChildId,
            };
            return participant;
          })
          .filter((p) => !isParticipantInvalid(p));
        const firstParticipant = participants[0];

        if (!firstParticipant || !isThereAtLeast1Participant) {
          return pushError(t({ id: `page13.participantMissing.${appId}` }));
        }

        const customerAccount: CMJourneyEditCustomerAccount = journeyEditData
          .customerAccount?._id
          ? journeyEditData.customerAccount
          : {
              // create account from first participant
              gender: firstParticipant.member.gender,
              language: firstParticipant.member.language1,
              firstName: firstParticipant.member.firstName,
              lastName: firstParticipant.member.lastName,
              country: firstParticipant.member.country,
              consent: false,
              electronicSignature: false,
              settings: {},
            };

        const payload: CustomerAccountCreatePayload = {
          ...journeyEditData,
          participants,
          customerAccount,
        };

        // accropark: create account now
        const {
          result,
          customerJourneyId,
        } = await customerJourneyApiClient.createOrUpdateCustomerAccountJourney(
          payload
        );

        if (result !== 'success') {
          // Save in redux cache
          dispatchCacheContextAction({
            type: 'update-journey-participants-list',
            payload,
          });
        }
        switch (result) {
          case 'email-already-exists':
            appLogger.error(
              'Erreur: un compte avec cette adresse e-mail existe déjà'
            );
            pushError(t({ id: 'error.account.exist' }));
            break;
          case 'unexpected-error':
            if (!networkStatus?.connected) {
              appLogger.warn(
                'Erreur: veuillez vérifier votre connexion Internet'
              );
              pushError(t({ id: 'error.network.down' }));
            } else {
              appLogger.error('Erreur innatendue, veuillez ré-essayer');
              pushError(t({ id: 'error.unexpected' }));
            }
            break;
          case 'success':
            // redirect to journey time tables
            appRouter.navigate(
              appRoutesBuilder.getJourneyActivitiesUrl({
                customerJourneyId: customerJourneyId,
                category: 'animator',
              }),
              {
                cause: 'participant-list-create-success',
              }
            );
            // user is authenticated: clear cache
            dispatchCacheContextAction({
              type: 'clear-update-journey-cache',
              context:
                'JourneyCreateParticipantsListAccroparkPage:onSubmit:success',
            });
            break;
        }
      } finally {
        setPersistInProgress(false);
      }
    },
    [
      appId,
      appRouter,
      dispatchCacheContextAction,
      editParticipants,
      journeyEditData,
      networkStatus?.connected,
      persistInProgress,
      pushError,
      t,
    ]
  );

  const onInvalid = () => {
    pushError(t({ id: 'common.message.error.invalid-form' }));
  };

  const goBack = () => {
    appRouter.navigate(
      appRoutesBuilder.getJourneyDatesEditUrl({ companyReference }),
      { cause: 'participant-list-back' }
    );
  };

  return !journeyEditData ? null : (
    <AppPage displayFooter={false}>
      <HeaderToolbar hideLogout={true} />
      <PageContainer className="text-center bg-gray-50" spacing="normal">
        <AccountCreationTitle
          company={company}
          displayAddress={true}
          displayButtonChooseDestination={auth?.isAuthenticated}
          buttonText={t({ id: `page10.choose.${appId}` })}
          onClick={handleChooseDestination}
        />

        <div className="flex mb-2 place-items-center">
          <IonRouterLink className="cursor-pointer" onClick={() => goBack()}>
            <IonIcon className="text-primary" icon={chevronBackOutline} />
          </IonRouterLink>
          <div className="ml-4 text-primary text-left flex-grow place-self-start text-xl mm:text-2xl ml:text-3xl sm:text-4xl">
            {t({ id: 'page13.whoIsParticipating' })}
          </div>
          <div className="flex-1 self-end flex-grow-0">
            <ArrowRight className="w-16 h-16 mr-8 text-primary" />
          </div>
        </div>

        {persistInProgress ? (
          <AppLoader className="my-4 p-2 px-4" type="persist" />
        ) : (
          <>
            {' '}
            <JourneyCreateParticipantsFormAccropark
              onSubmit={() => handleSubmit(onSubmit, onInvalid)()}
              form={form}
              formParticipants={formParticipants}
            />
            <div className="text-right font-bold text-2xl mr-4">
              {t(
                { id: 'page13.total' },
                { total: formParticipantsEnabledCount }
              )}
            </div>
          </>
        )}
      </PageContainer>
      <FooterActions
        hidden={persistInProgress}
        onCancel={openModalCancelJourneyDraft}
        onBack={goBack}
        onSubmit={handleSubmit(onSubmit, onInvalid)}
      />
    </AppPage>
  );
};
