/* eslint-disable @typescript-eslint/no-unused-vars */
import { AppSelect } from '_01_CORE/_components-core';
import { useJourneyActivityCategory } from '_01_CORE/app-contexts';
import { JourneyActivitiesLocalState } from '_02_APP_COMMON/ACT-activity';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { useAnimLiveJourneysActivitiesPageCriteriaBarOptions } from './useAnimLiveJourneysActivitiesPageCriteriaBarOptions.hook';

export const AnimLiveJourneysListPageCriteriaBarExtraFilters = ({
  localState,
  className,
}: {
  localState: JourneyActivitiesLocalState;
  className?: string;
}) => {
  const { formatMessage: t } = useIntl();

  const {
    localFilters,
    setLocalFilters,
    journey,
    setCategoryAndScroll,
    category,
    isSearchTextActive,
  } = localState;

  const enableActivityStandalone = useJourneyActivityCategory(
    journey,
    'standalone'
  );

  const options = useAnimLiveJourneysActivitiesPageCriteriaBarOptions({
    localState,
  });

  return (
    <div className="flex flex-col gap-2">
      <div className={clsx('grid gap-2', className)}>
        {!isSearchTextActive && enableActivityStandalone && (
          <AppSelect
            className="w-full"
            elements={options.activityCategoriesOptions}
            label="Type d'activité"
            disableClearButton={true}
            value={category}
            onChange={(category) => {
              setCategoryAndScroll(category);
            }}
          />
        )}
        {!isSearchTextActive && (
          <AppSelect
            className="w-full"
            elements={options.participantsOptions}
            label="Filtrer par participant"
            value={localFilters.selectedChildId}
            onChange={(selectedChildId) => {
              setLocalFilters({
                ...localFilters,
                selectedActivityId: undefined, // reset activity when select participant
                selectedChildId,
              });
            }}
          />
        )}
        <AppSelect
          className="w-full"
          elements={options.activitiesOptions}
          label="Filtrer par activité"
          value={localFilters.selectedActivityId}
          onChange={(selectedActivityId) => {
            setLocalFilters({
              ...localFilters,
              selectedChildId: undefined, // reset participant when select activity
              selectedActivityId,
            });
          }}
        />

        {/* <ParticipantFormSelect
          className={`w-full`}
          participants={participants}
          selectedChildId={localFilters.selectedChildId}
          labelAllOptions={t({
            id: 'page16.form.select-participant.all-participants',
          })}
          onChange={(p) => {
            setLocalFilters({
              ...localFilters,
              selectedActivityId: undefined, // reset activity when select participant
              selectedChildId: p?.member.customerChildId,
            });
          }}
        /> */}
        {/* <ActivityFormSelect
          className={`w-full`}
          alignOptions="right"
          activities={activities}
          selectedId={localFilters.selectedActivityId}
          labelAllOptions={t({
            id: 'page16.form.select-participant.all-activities',
          })}
          onChange={(a) => {
            setLocalFilters({
              ...localFilters,
              selectedChildId: undefined, // reset participant when select activity
              selectedActivityId: a?._id,
            });
          }}
        /> */}

        <div className="flex justify-between gap-4">
          {/* <AppButtonV2
            className="w-full"
            buttonStyle="outline-transparent"
            onClick={() => !isRefreshInProgress && refreshActivities()}
            icon={AppIcons.actionRefresh}
          >
            Fermer
          </AppButtonV2> */}
          {/* <AppButtonV2
            className="w-full app--border--important"
            buttonStyle="outline-transparent"
            onClick={() => !isRefreshInProgress && refreshActivities()}
            icon={AppIcons.actionRefresh}
          >
            {t({ id: 'page16.form.select-participant.refresh' })}
          </AppButtonV2> */}
        </div>
      </div>
    </div>
  );
};
