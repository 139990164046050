import { AppPage, HeaderToolbar, PageContainer } from '_01_CORE/_common-layout';
import { AppSelect, AppSelectOption } from '_01_CORE/_components-core';
import { useAppRouter } from '_01_CORE/_hooks';
import { useCompany } from '_01_CORE/app-contexts';
import { useCompanyRestos } from '_01_CORE/app-contexts/app-cache-context/hooks/company-resto-menu/useCompanyRestos.hook';
import clsx from 'clsx';
import {
  CMResto,
  CMRestoMenu,
  CMRestoMenuDish,
} from 'lib-common-model/entities/app-customer-mobile-web/CMResto';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router';
import {
  CompanyRestoCardHeader,
  CompanyRestoMenuDishDetailsDialog,
  CompanyRestoMenuSectionCard,
} from './components';

const SECTIONS_ALL_ID = '_____ALL_____';

type CompanyRestoMenuPageFilters = {
  restoId: string;
  menuId: string;
  sectionId?: string;
};

export const CompanyRestoMenuPage = React.memo(function CompanyRestoMenuPage() {
  const { companyReference } = useParams<{ companyReference: string }>();
  const { company } = useCompany({ companyReference });

  const restos: CMResto[] = useCompanyRestos({ companyReference });

  const filtersInitialValue = useMemo(() => {
    if (restos?.length > 0) {
      const resto = restos?.[0];
      const menu = resto.menus?.[0];
      const filters: CompanyRestoMenuPageFilters = {
        restoId: resto?._id,
        menuId: menu._id,
        sectionId: SECTIONS_ALL_ID,
      };
      return filters;
    }
    return {
      restoId: undefined,
      menuId: undefined,
      sectionId: SECTIONS_ALL_ID,
    };
  }, [restos]);

  const [filters, setFilters] = useState<CompanyRestoMenuPageFilters>(
    filtersInitialValue
  );

  const resto: CMResto = useMemo(
    () => restos.find((resto) => resto._id === filters.restoId),
    [filters.restoId, restos]
  );

  const menus = useMemo(() => resto?.menus ?? [], [resto?.menus]);

  const menu: CMRestoMenu = useMemo(
    () => menus.find((m) => m._id === filters.menuId) ?? menus?.[0],
    [filters.menuId, menus]
  );

  const menusOptions: AppSelectOption<string>[] = useMemo(() => {
    const options = (menus ?? []).map((menu) => {
      return {
        id: menu._id,
        label: menu.translation?.name,
      };
    });
    return options;
  }, [menus]);

  const sectionsOptions: AppSelectOption<string>[] = useMemo(() => {
    const options: AppSelectOption<string>[] = [
      {
        id: SECTIONS_ALL_ID,
        label: 'Catégories',
      },
    ].concat(
      (menu?.sections ?? []).map((section) => {
        return {
          id: section._id,
          label: section.translation?.name,
        };
      })
    );
    return options;
  }, [menu?.sections]);

  const appRouter = useAppRouter();

  const menuSections = useMemo(() => {
    const sections = menu?.sections ?? [];
    // if (filters.sectionId) {
    //   const { selected, other } = sections.reduce(
    //     (acc, x) => {
    //       if (x._id === filters.sectionId) {
    //         acc.selected.push(x);
    //       } else {
    //         acc.other.push(x);
    //       }
    //       return acc;
    //     },
    //     {
    //       selected: [],
    //       other: [],
    //     } as {
    //       selected: CMRestoMenuSection[];
    //       other: CMRestoMenuSection[];
    //     }
    //   );
    //   sections = selected.concat(other);
    // }
    return sections;
  }, [menu?.sections]);

  const { scrollTo, scrollToTop } = useAppRouter();

  const [state, setState] = useState<{
    selectedDish?: CMRestoMenuDish;
  }>({});

  return (
    <AppPage tabId="home" displayFooter={true}>
      <HeaderToolbar />
      {!!state.selectedDish && (
        <CompanyRestoMenuDishDetailsDialog
          className={'z-10'}
          dish={state.selectedDish}
          companyReference={companyReference}
          onClose={() =>
            setState({
              selectedDish: undefined,
            })
          }
        />
      )}
      <PageContainer
        className={clsx(
          'text-center bg-gray-50',
          !state.selectedDish && ' px-4'
        )}
        spacing="none"
      >
        <div className={clsx('grid')}>
          <CompanyRestoCardHeader
            className="mt-4"
            companyReference={companyReference}
            resto={resto}
          />
          {(menusOptions.length > 1 || sectionsOptions.length > 2) && (
            <div className="sticky z-10 top-0 py-4 bg-gray-50 flex flex-col gap-2 ml:flex-row md:justify-between">
              {menusOptions.length > 1 && (
                <AppSelect
                  className="w-full"
                  elements={menusOptions}
                  // label="Menu"
                  value={filters.menuId}
                  onChange={(menuId) => {
                    setFilters({
                      ...filters,
                      menuId,
                      sectionId: SECTIONS_ALL_ID,
                    });
                  }}
                  disableClearButton={true}
                />
              )}
              {sectionsOptions.length > 2 && (
                <AppSelect
                  className="w-full"
                  elements={sectionsOptions}
                  // label="Menu"
                  value={filters.sectionId}
                  onChange={(sectionId) => {
                    if (!sectionId) {
                      sectionId = SECTIONS_ALL_ID;
                    }
                    setFilters({ ...filters, sectionId });
                    if (sectionId === SECTIONS_ALL_ID) {
                      scrollToTop();
                    } else {
                      scrollTo('section-' + sectionId, {
                        offset: -80,
                      });
                    }
                  }}
                />
              )}
            </div>
          )}
          {menuSections.length > 0 && (
            <div className="grid gap-8">
              {menuSections.map((section) => (
                <CompanyRestoMenuSectionCard
                  key={section._id}
                  section={section}
                  onClickDish={(dish: CMRestoMenuDish) => {
                    setState({
                      selectedDish: dish,
                    });
                  }}
                />
              ))}
            </div>
          )}
        </div>
      </PageContainer>
    </AppPage>
  );
});
