import { dataSorter } from '_01_CORE/_components-core';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import { CompanyOptions } from 'lib-common-model';
import { useMemo } from 'react';
import {
  CustomerActivityParticipantsDailyActivitParticipantState,
  CustomerActivityParticipantsDailyActivity,
} from '../model';
import {
  ParticipantsByStatus,
  useGroupParticipantsByStatus,
} from './useGroupParticipantsByStatus.hook';

export type ActivityDialogParticipantsGroupsToDisplay = {
  isOpen: boolean;
  isPaid: boolean;
  participantsRegistration: CustomerActivityParticipantsDailyActivitParticipantState[];
  participantsRegistrationAcceptedPending: CustomerActivityParticipantsDailyActivitParticipantState[];
  participantsRegistrationWaiting: CustomerActivityParticipantsDailyActivitParticipantState[];
};

export function useActivityDialogParticipantsGroupsToDisplay({
  companyOptions,
  dailyActivity,
}: {
  companyOptions: CompanyOptions;
  dailyActivity: CustomerActivityParticipantsDailyActivity;
}): ActivityDialogParticipantsGroupsToDisplay {
  const {
    participantsRegistered,
    participantsNotRegistered,
    participantsWaiting,
    participantsAcceptedPending,
    participantsAll,
  }: ParticipantsByStatus = useGroupParticipantsByStatus({
    dailyActivity,
  });

  const appId = appWebConfig().appId;
  const registrationFeeEnabled =
    dailyActivity.selectedSession?.activitySession?.registrationFeeEnabled &&
    appId !== 'accropark';

  const waitingListEnabled = companyOptions?.waitingList?.enabled;

  return useMemo(() => {
    const isRegistrationOpen =
      dailyActivity.selectedSession.registrationStatus === 'open';
    const groupsToDisplay: ActivityDialogParticipantsGroupsToDisplay = {
      isOpen: false,
      isPaid: registrationFeeEnabled,
      participantsRegistration: [],
      participantsRegistrationAcceptedPending: [],
      participantsRegistrationWaiting: [],
    };
    if (
      dailyActivity.selectedSession.registrationStatus ===
      'registration-customer-disabled'
    ) {
      // registration disabled by configuration : aucun cadre affiché
      groupsToDisplay.isOpen = false;
    } else {
      const isActiveWaitingList =
        waitingListEnabled &&
        dailyActivity.selectedSession.registrationStatus ===
          'registration-full';

      if (isActiveWaitingList) {
        // WAITING LIST ACTIVE: non registered are displayed in "waiting list" area
        groupsToDisplay.participantsRegistration = participantsRegistered;

        groupsToDisplay.participantsRegistrationWaiting = participantsWaiting.concat(
          participantsNotRegistered
        );
      } else if (isRegistrationOpen) {
        // REGISTRATION OPEN non registered are displayed in "registration open" area
        groupsToDisplay.participantsRegistration = participantsRegistered.concat(
          participantsNotRegistered
        );
        groupsToDisplay.participantsRegistrationWaiting = participantsWaiting;
      } else {
        // REGISTRATION CLOSED
        groupsToDisplay.participantsRegistration = participantsRegistered
          .concat(participantsWaiting)
          .concat(participantsNotRegistered);
      }

      if (isRegistrationOpen || isActiveWaitingList) {
        groupsToDisplay.isOpen = true;
        // liste d'attente à confirmer (masquée si séance commencée ou terminée)
        groupsToDisplay.participantsRegistrationAcceptedPending = participantsAcceptedPending;
      } else {
        groupsToDisplay.isOpen = false;
        groupsToDisplay.participantsRegistration = groupsToDisplay.participantsRegistration.concat(
          participantsAcceptedPending
        );
      }
    }
    groupsToDisplay.participantsRegistration = dataSorter.sortMultiple(
      groupsToDisplay.participantsRegistration,
      {
        getSortAttributes: (p) => [
          {
            value: p.journeyParticipant.attributes.journeyAge,
          },
          {
            value: p.journeyParticipant?.member?.firstName,
            type: 'full-text',
          },
          {
            value: p.journeyParticipant?.member?.lastName,
            type: 'full-text',
          },
          {
            value: p.journeyParticipant?.journeyParticipantId,
          },
        ],
      }
    );
    return groupsToDisplay;
  }, [
    dailyActivity.selectedSession.registrationStatus,
    participantsAcceptedPending,
    participantsNotRegistered,
    participantsRegistered,
    participantsWaiting,
    registrationFeeEnabled,
    waitingListEnabled,
  ]);
}
