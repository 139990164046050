export type DateServiceIsoWeekDay = 1 | 2 | 3 | 4 | 5 | 6 | 7;

export const dateServiceCore = {
  getUTCWeekDayIso,
  add,
  addDays,
  addMonth,
  addYear,
  toISOStringDate,
  truncateTimeUTC,
  getDiffInDaysRelative,
  setTimeUTC,
};
function setTimeUTC(
  date: Date,
  hours = 0,
  minutes = 0,
  seconds = 0,
  ms = 0
): Date {
  if (!date) {
    return date;
  }
  // logger.debug('setTimeUTC date:', date);
  const dateTime = new Date(date);
  dateTime.setUTCHours(hours);
  dateTime.setUTCMinutes(minutes);
  dateTime.setUTCSeconds(seconds);
  dateTime.setUTCMilliseconds(ms);
  // logger.debug('setTimeUTC dateTime:', dateTime);
  return dateTime;
}
// pour faire la diff avec "getDiffInDays" qui existe ailleurs sur le projet
function getDiffInDaysRelative(date1: Date, date2: Date): number {
  date1 = new Date(date1);
  date2 = new Date(date2);
  const diffTime = date2.getTime() - date1.getTime();
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
}
function truncateTimeUTC(date: Date): Date {
  if (!date) {
    return undefined;
  }
  date = new Date(date);
  return new Date(
    Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
  );
}
function getUTCWeekDayIso(date: Date): DateServiceIsoWeekDay {
  return (((date.getUTCDay() + 6) % 7) + 1) as DateServiceIsoWeekDay;
}
function add(date: Date, nb: number, unit: 'day' | 'month' | 'year'): Date {
  if (unit === 'day') {
    return addDays(date, nb);
  }
  if (unit === 'month') {
    return addMonth(date, nb);
  }
  if (unit === 'year') {
    return addYear(date, nb);
  }
}
function addDays(date: Date, nb: number): Date {
  date = new Date(date);
  date.setUTCDate(date.getUTCDate() + nb);
  return date;
}
function addMonth(date: Date, nb: number): Date {
  date = new Date(date);
  date.setUTCMonth(date.getUTCMonth() + nb);
  return date;
}
function addYear(date: Date, nb: number): Date {
  date = new Date(date);
  date.setFullYear(date.getFullYear() + nb);
  return date;
}
function toISOStringDate(date: Date): string {
  return date.toISOString().substring(0, 10);
}
