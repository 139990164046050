import * as sentry from '@sentry/react';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import { appStore } from '_01_CORE/app-core/app-store';
import * as ky from 'ky';
import { appLogger } from 'lib-web-logger';
import { SimpleStore } from 'lib-web-redux';
import { from, NEVER, of, throwError } from 'rxjs';
import {
  catchError,
  delay,
  first,
  map,
  retryWhen,
  switchMap,
} from 'rxjs/operators';
import {
  httpClient,
  HttpClientMethod,
  HttpClientMethodRead,
  HttpClientMethodWrite,
} from '../http';
import { jsonParser } from '../json';
import { apiClientHttpClientFetchOptionsBuilder } from './apiClientHttpClientFetchOptionsBuilder.service';
import { ApiClientHttpError } from './ApiClientHttpError.type';
import {
  ApiClientRequestOptions,
  ApiClientRequestOverrideConfig,
  ApiClientRequestWithBodyOptions,
} from './ApiClientRequestOptions.model';
import { apiClientStoreProvider } from './apiClientStoreProvider.service';

export type ApiClientErrorCallback = ({
  httpStatus,
  json,
  err,
}: {
  httpStatus: number;
  json: any;
  err: Error;
}) => void;

const config: {
  errorCallback: ApiClientErrorCallback;
} = {
  errorCallback: ({
    httpStatus,
    json,
    err,
  }: {
    httpStatus: number;
    json: any;
    err: Error;
  }) => {
    // default callback
  },
};
export const apiClient = {
  init,
  delete_,
  get,
  post,
  put,
  request,
};

export type ApiClientConfiguration = {
  baseStore: SimpleStore<any>;
  baseUrl: string;
  appClientId: string;
  appVersion: string;
  errorCallback: ApiClientErrorCallback;
  clearAuth: boolean;
};

function init({
  baseStore,
  baseUrl,
  appClientId,
  appVersion,
  errorCallback,
  clearAuth,
}: ApiClientConfiguration) {
  const store = apiClientStoreProvider.init(baseStore);
  if (clearAuth) {
    store.authenticationToken.set(null);
  }
  store.baseUrl.set(baseUrl);
  store.appClientId.set(appClientId);
  store.appVersion.set(appVersion);
  if (errorCallback) {
    config.errorCallback = errorCallback;
  }
}

function _fetchCore<T>({
  resource,
  method,
  options,
  overrideConfig,
}:
  | {
      resource: string;
      method: HttpClientMethodWrite;
      options: ApiClientRequestWithBodyOptions;
      overrideConfig?: ApiClientRequestOverrideConfig;
    }
  | {
      resource: string;
      method: HttpClientMethodRead;
      options: ApiClientRequestOptions;
      overrideConfig?: ApiClientRequestOverrideConfig;
    }): Promise<T> {
  const apiClientStore = apiClientStoreProvider.get();

  return apiClientStore.baseUrl
    .get()
    .pipe(
      first(),
      switchMap(
        () => {
          return apiClientHttpClientFetchOptionsBuilder.buildHttpClientFetchOptions(
            {
              method,
              options,
            }
          );
        },
        (baseUrl, httpClientFetchOptions) => ({
          baseUrl,
          httpClientFetchOptions,
        })
      ),
      switchMap(({ baseUrl, httpClientFetchOptions }) => {
        return httpClient
          .fetch(
            `${overrideConfig?.baseUrl ?? baseUrl}${resource}`,
            httpClientFetchOptions
          )
          .pipe(
            catchError((err) => {
              const httpStatus =
                err?.response?.status ??
                err?.response?.statusText ??
                (err as any)?.status;

              if (httpStatus === 304) {
                // status 304 (non modifié) = pas de contenu dans la réponse : on n'essaie pas de le parser, et on ne log pas d'erreur
                return throwError(err);
              }
              console.error(err);
              sentry.addBreadcrumb({
                level: sentry.Severity.Info,
                message: '[apiClient] Http error fetch error',
                data: {
                  baseUrl,
                  resource,
                  method,
                  httpClientFetchOptions: JSON.stringify(
                    httpClientFetchOptions
                  ),
                },
              });
              return throwError(err);
            })
          );
      }),
      switchMap((response: Response) => {
        return from(response.text()).pipe(
          map((text) => {
            try {
              return jsonParser.parseJSONWithDates<T>(text);
            } catch (err) {
              console.error(err);
              sentry.addBreadcrumb({
                message: 'parseJSONWithDates error',
                data: { text, err },
              });
              throw new Error('Error parsing json response');
            }
          })
        );
      }),
      catchError((err: ky.HTTPError) => {
        const httpStatus =
          err?.response?.status ??
          err?.response?.statusText ??
          (err as any)?.status;

        if (httpStatus === 304) {
          // status 304 (non modifié) = pas de contenu dans la réponse : on n'essaie pas de le parser, et on ne log pas d'erreur
          return throwError(
            new ApiClientHttpError({
              cause: err,
              response: err.response,
            })
          );
        }
        if (err.response?.json) {
          return from(err.response.json()).pipe(
            switchMap((json) => {
              if (httpStatus !== 423) {
                // on ne log pas d'erreur pour le status 423 (ressource locked)
                appLogger.warn(
                  `[apiClient] Http error response ${httpStatus}`,
                  {
                    response: err.response,
                    json,
                  }
                );
                if (appWebConfig().debug.sentryDsn) {
                  const sentryData: any = {
                    resource,
                    method,
                  };
                  if (options?.authenticate) {
                    sentryData.authenticate = options?.authenticate;
                  }
                  if (options?.searchParams) {
                    sentryData.searchParams = options?.searchParams;
                  }
                  if (options?.headers) {
                    sentryData.headers = options?.headers;
                  }
                  if ((options as ApiClientRequestWithBodyOptions)?.body) {
                    sentryData.body = (options as ApiClientRequestWithBodyOptions)?.body;
                  }
                  if ((options as ApiClientRequestWithBodyOptions)?.json) {
                    sentryData.json = JSON.stringify(
                      (options as ApiClientRequestWithBodyOptions)?.json
                    );
                  }

                  sentry.addBreadcrumb({
                    message: 'HTTP error',
                    data: sentryData,
                  });
                }
              }
              if (config.errorCallback) {
                config.errorCallback({
                  httpStatus,
                  err,
                  json,
                });
              }

              return throwError(
                new ApiClientHttpError({
                  cause: err,
                  json: json,
                  response: err.response,
                })
              );
            }),
            catchError((err) => {
              if (config.errorCallback) {
                config.errorCallback({
                  httpStatus: err.response.status,
                  err,
                  json: undefined,
                });
              }
              // parse error: throw original error
              appLogger.warn('[apiClient] Http error (+ parse error)', err);
              return throwError(
                new ApiClientHttpError({
                  cause: err,
                  response: err.response,
                })
              );
            })
          );
        } else {
          appLogger.warn('[apiClient] Http error', err);
          return throwError(err);
        }
      }),
      retryWhen((errors$) => {
        return errors$.pipe(
          switchMap((err: ky.HTTPError, i) => {
            if (
              err.name === 'TimeoutError' ||
              err.name === 'SyntaxError' ||
              err.message === 'The network connection was lost.' ||
              err.message === 'cannot parse response'
            ) {
              // network timeout

              if (method === 'get' && i < (options.maxTries ?? 2)) {
                // NOTE: ne pas ré-essayer si ce n'est pas un GET, sinon risque d'effets de bords!
                // NOTE 2: il y a un mécanisme de refresh dans "ky" (désactivé pour le moment)
                return of(err).pipe(delay(500 * (1 + i))); // retry after delay
              } else {
                // NOTE: utiliser alwaysThrowError quand l'appelant gère correctement catch/finally (ça devrait être le défaut à terme)
                // do NOT throw error
                // TODO: gérer correctement un message d'erreur (global comme pour le errorCallback ???)
                if (options.alwaysThrowError) {
                  return throwError(err);
                } else {
                  console.error('Do not re-throw network error', err);
                  return NEVER;
                }
              }
            } else {
              return throwError(err);
            }
          })
        );
      })
    )
    .toPromise();
}

function get<T>(
  resource: string,
  { options }: { options: ApiClientRequestOptions }
) {
  return _fetchCore<T>({
    method: 'get',
    options,
    resource,
  });
}

function request<T>(
  resource: string,
  {
    ignoreExtraInfoToHeaders = false,
    method,
    options,
    overrideConfig,
  }: {
    ignoreExtraInfoToHeaders?: boolean;
    method: HttpClientMethod;
    options: ApiClientRequestWithBodyOptions;
    overrideConfig?: ApiClientRequestOverrideConfig;
  }
) {
  return _fetchCore<T>({
    method,
    options:
      ignoreExtraInfoToHeaders === true
        ? options
        : addDeviceTokenAndInfoToHeaders(options),
    resource,
    overrideConfig,
  });
}
function post<T>(
  resource: string,
  {
    options,
    overrideConfig,
  }: {
    options: ApiClientRequestWithBodyOptions;
    overrideConfig?: ApiClientRequestOverrideConfig;
  }
) {
  return _fetchCore<T>({
    method: 'post',
    options: addDeviceTokenAndInfoToHeaders(options),
    resource,
    overrideConfig,
  });
}

function addDeviceTokenAndInfoToHeaders(
  options: ApiClientRequestWithBodyOptions
) {
  if (!options) {
    options = {};
  }
  if (!options.headers) {
    options.headers = {};
  }
  const deviceToken = appStore.deviceToken.getSnapshot();
  if (deviceToken) {
    try {
      options.headers['deviceToken'] = JSON.stringify(deviceToken);
    } catch (err) {
      console.error(err);
      appLogger.error('Error trying to stringify device token');
    }
  }
  // const deviceInfo = appStore.deviceInfo.getSnapshot();
  // if (deviceInfo.model !== 'iPhone') {
  //   try {
  //     options.headers['deviceInfo'] = JSON.stringify({
  //       ...deviceInfo,
  //     });
  //   } catch (err) {
  //     console.error(err);
  //     appLogger.error('Error trying to stringify device info');
  //   }
  // }
  return options;
}

function put<T>(
  resource: string,
  {
    options,
    overrideConfig,
  }: {
    options: ApiClientRequestWithBodyOptions;
    overrideConfig?: ApiClientRequestOverrideConfig;
  }
) {
  return _fetchCore<T>({
    method: 'put',
    options: addDeviceTokenAndInfoToHeaders(options),
    resource,
    overrideConfig,
  });
}

function delete_<T>(
  resource: string,
  { options }: { options: ApiClientRequestWithBodyOptions }
) {
  return _fetchCore<T>({
    method: 'delete',
    options,
    resource,
  });
}
