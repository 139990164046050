import { dataSorter } from '_01_CORE/_components-core';
import { useMemo } from 'react';
import {
  CustomerActivityParticipantsDailyActivity,
  CustomerActivityParticipantsDailyActivitySession,
} from '../model';

export function useTimeSessionsToDisplayByDefault({
  dailyActivity,
  selectedSession,
  maxSessionsToDisplay,
}: {
  dailyActivity: CustomerActivityParticipantsDailyActivity;
  selectedSession: CustomerActivityParticipantsDailyActivitySession;
  maxSessionsToDisplay: number;
}): CustomerActivityParticipantsDailyActivitySession[] {
  return useMemo(() => {
    if (maxSessionsToDisplay + 1 === dailyActivity.sessions.length) {
      return dailyActivity.sessions;
    }
    const timeSessionsToDisplayByDefault: CustomerActivityParticipantsDailyActivitySession[] = [
      selectedSession,
    ];

    const openSessionsFuture: CustomerActivityParticipantsDailyActivitySession[] =
      dailyActivity.openSessionsFuture;

    const openSessionsBeforeSelectedSession: CustomerActivityParticipantsDailyActivitySession[] = dailyActivity.openSessions
      .filter(
        (x) =>
          x.activitySession.beginDateTime.getTime() <
            selectedSession.activitySession.beginDateTime.getTime() &&
          x.registrationStatus !== 'registration-full'
      )
      .reverse();
    const availableSessionstSelectedSession: CustomerActivityParticipantsDailyActivitySession[] = dailyActivity.openSessionsFuture.filter(
      (x) =>
        x.activitySession.beginDateTime.getTime() >=
        selectedSession.activitySession.beginDateTime.getTime()
    );

    if (
      timeSessionsToDisplayByDefault.length < maxSessionsToDisplay &&
      openSessionsBeforeSelectedSession.length
    ) {
      addSessionIfPossible({
        timeSessionsToDisplayByDefault,
        maxSessionsToDisplay,
        session: openSessionsBeforeSelectedSession[0],
      });
    }
    if (timeSessionsToDisplayByDefault.length < maxSessionsToDisplay) {
      for (const session of availableSessionstSelectedSession) {
        if (
          session.isCustomerRegistered ||
          session.registrationStatus !== 'registration-full'
        ) {
          addSessionIfPossible({
            timeSessionsToDisplayByDefault,
            maxSessionsToDisplay,
            session,
          });
        }
      }
    }
    if (timeSessionsToDisplayByDefault.length < maxSessionsToDisplay) {
      for (const session of openSessionsBeforeSelectedSession) {
        addSessionIfPossible({
          timeSessionsToDisplayByDefault,
          maxSessionsToDisplay,
          session,
        });
      }
    }
    if (timeSessionsToDisplayByDefault.length < maxSessionsToDisplay) {
      for (const session of openSessionsFuture) {
        if (
          session.isCustomerRegistered ||
          session.registrationStatus !== 'registration-full'
        ) {
          addSessionIfPossible({
            timeSessionsToDisplayByDefault,
            maxSessionsToDisplay,
            session,
          });
        }
      }
    }
    if (timeSessionsToDisplayByDefault.length < maxSessionsToDisplay) {
      // il reste de la place, on ajoute les sessions full
      for (const session of openSessionsFuture) {
        if (session.registrationStatus === 'registration-full') {
          addSessionIfPossible({
            timeSessionsToDisplayByDefault,
            maxSessionsToDisplay,
            session,
          });
        }
      }
    }
    if (timeSessionsToDisplayByDefault.length < maxSessionsToDisplay) {
      // il reste de la place, on ajoute les autres sessions
      const allSessions: CustomerActivityParticipantsDailyActivitySession[] = dailyActivity.sessions.reverse();
      for (const session of allSessions) {
        addSessionIfPossible({
          timeSessionsToDisplayByDefault,
          maxSessionsToDisplay,
          session,
        });
      }
    }

    return dataSorter.sortMultiple(timeSessionsToDisplayByDefault, {
      getSortAttributes: (item) => [
        {
          value: item.activitySession.beginDateTime,
        },
        {
          value: item.activitySession.endDateTime,
        },
        {
          value: item.activitySession.activitySessionId,
        },
      ],
    });
  }, [
    dailyActivity.openSessions,
    dailyActivity.openSessionsFuture,
    dailyActivity.sessions,
    maxSessionsToDisplay,
    selectedSession,
  ]);
}
function addSessionIfPossible({
  timeSessionsToDisplayByDefault,
  maxSessionsToDisplay,
  session,
}: {
  timeSessionsToDisplayByDefault: CustomerActivityParticipantsDailyActivitySession[];
  maxSessionsToDisplay: number;
  session: CustomerActivityParticipantsDailyActivitySession;
}) {
  if (
    timeSessionsToDisplayByDefault.length < maxSessionsToDisplay &&
    !timeSessionsToDisplayByDefault.find(
      (x) =>
        x.activitySession.activitySessionId ===
        session.activitySession.activitySessionId
    )
  ) {
    timeSessionsToDisplayByDefault.push(session);
  }
}
