import clsx from 'clsx';
import { COMPANY_DEFAULT_THEME, CompanyOptionsTheme } from 'lib-common-model';
import { FC, useMemo } from 'react';
import {
  AppButtonFreeColor,
  AppButtonFreeColorProps,
} from './AppButtonFreeColor';

export type AppButtonCompanyProps = Omit<
  AppButtonFreeColorProps,
  'color' | 'children' | 'style'
> & {
  children?: React.ReactNode | React.ReactNode[];
  size?: 'small' | 'normal' | 'large';
  label?: string;
  label2?: string;
  isFullWidth?: boolean;
  style: 'cancel' | 'secondary' | 'primary' | 'danger';
  theme?: CompanyOptionsTheme;
  roundedClassName?: string;
  className?: string;
};

export const AppButtonCompany: FC<AppButtonCompanyProps> = ({
  children,
  label,
  size = 'normal',
  roundedClassName,
  label2,
  isFullWidth,
  style = 'primary',
  theme,
  className,
  ...props
}) => {
  const { color, textColor } = useMemo(() => {
    switch (style) {
      case 'primary': {
        return {
          color:
            theme?.buttonPrimaryBgColor ??
            COMPANY_DEFAULT_THEME.buttonPrimaryBgColor,
          textColor:
            theme?.buttonPrimaryTextColor ??
            COMPANY_DEFAULT_THEME.buttonPrimaryTextColor,
        };
      }
      case 'danger': {
        return {
          color: '#aa0000',
          textColor: '#ffffff',
        };
      }
      case 'cancel':
      case 'secondary': {
        // TODO couleurs différentes pour secondary et cancel
        return {
          color:
            theme?.buttonCancelBgColor ??
            COMPANY_DEFAULT_THEME.buttonCancelBgColor,
          textColor:
            theme?.buttonCancelTextColor ??
            COMPANY_DEFAULT_THEME.buttonCancelTextColor,
        };
      }
    }
  }, [style, theme]);

  const buttonStyleClass = useMemo(() => {
    const classes =
      size === 'large'
        ? 'text-base sm:text-lg px-4 sm:px-8 py-4'
        : size === 'normal'
        ? 'text-sm px-4 py-2'
        : 'text-xs px-2 py-1';
    return classes;
  }, [size]);

  const iconStyleClass = useMemo(
    () =>
      size === 'large'
        ? 'ml-2 h-14 w-14 text-gray-800 rounded bg-white p-2'
        : size === 'normal'
        ? 'ml-2 h-5 w-5'
        : 'mr-1 h-3 w-3',
    [size]
  );

  const iconOpositePadding =
    size === 'large' ? 'pr-9' : size === 'normal' ? 'pr-7' : 'pr-2';

  return (
    <AppButtonFreeColor
      color={color}
      textColor={textColor}
      roundedClassName={roundedClassName}
      // buttonStyle="primary"
      className={clsx(
        `flex-1 text-center relative`,
        !isFullWidth && 'w-full max-w-xs',
        buttonStyleClass,
        className,
        iconOpositePadding
      )}
      iconStyleClass={iconStyleClass}
      {...props}
    >
      {children && children}
      {label && <div className={label2 ? '-mt-4' : ''}>{label}</div>}
      {label2 && (
        <div className="h-0 -mt-1 text-center text-app-xxs1">{label2}</div>
      )}
    </AppButtonFreeColor>
  );
};
