import {
  AppMessageLight,
  AppPage,
  HeaderToolbar,
  PageContainer,
} from '_01_CORE/_common-layout';
import {
  AppButtonCompany,
  AppIcons,
  AppLoader,
} from '_01_CORE/_components-core';
import { useAppRouter } from '_01_CORE/_hooks';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import {
  AnimLiveJourneyShoppingCartPaymentPageState,
  useALShoppingCartPaymentPageState,
} from './useALShoppingCartPaymentPageState.hook';

export const ALShoppingCartPaymentPage = () => {
  const appRouter = useAppRouter();
  const { formatMessage: t } = useIntl();
  const appId = appWebConfig().appId;

  const { customerJourneyId, shoppingCartId } = useParams<{
    customerJourneyId: string;
    shoppingCartId: string;
  }>();

  const localState: AnimLiveJourneyShoppingCartPaymentPageState = useALShoppingCartPaymentPageState(
    {
      customerJourneyId,
      shoppingCartId,
    }
  );

  const {
    operationInProgress,
    pageMode,
    goBackToActivities,
    goBackToShoppingCart,
    paymentLinkInfo,
    shoppingCartPaymentResume,
    debugMessages,
  } = localState;

  return (
    <AppPage displayFooter={false}>
      <HeaderToolbar hideLogout={true} />
      <PageContainer
        className="h-full bg-gray-50 flex flex-col items-stretch text-center"
        spacing="normal"
      >
        {operationInProgress ? (
          <AppLoader type={operationInProgress.type} className="my-4" />
        ) : (
          <>
            {shoppingCartPaymentResume &&
              paymentLinkInfo?.paymentLink &&
              pageMode.id !== 'app-root' && (
                <div className="flex-grow flex flex-col gap-8">
                  <div className="text-gray-700">
                    {`En cliquant sur le bouton "Payer maintenant", vous allez être
            redirigé sur la plateforme de paiement sécurisée Stripe.`}
                  </div>
                  <div>
                    <AppButtonCompany
                      className="text-base font-bold uppercase"
                      isFullWidth={true}
                      icon={AppIcons.paymentOnline}
                      href={paymentLinkInfo?.paymentLink}
                      style={'primary'}
                    >
                      Payer maintenant
                    </AppButtonCompany>
                  </div>
                  <div className="flex-grow"></div>
                  {/* <div>
            <AppButtonCompany
              className="flex-grow-0 w-full font-bold"
              isFullWidth={true}
              icon={AppIcons.actionCancel}
              onClick={(e) => {
                cancelPayment();
              }}
              style={'cancel'}
            >
              Annuler
            </AppButtonCompany>
          </div> */}
                </div>
              )}
          </>
        )}
        <div className="flex-grow-0">
          {shoppingCartPaymentResume?.shoppingCart?.status === 'completed' &&
            shoppingCartPaymentResume?.shoppingCart?.paymentStatus ===
              'payment-accepted' && (
              <AppMessageLight type="success" className="font-bold">
                Votre paiement a bien été enregistré
              </AppMessageLight>
            )}
          {pageMode?.paymentResult === 'cancel' && (
            <AppMessageLight type="danger" className="font-bold">
              Votre paiement a été annulé
            </AppMessageLight>
          )}
        </div>
        {shoppingCartPaymentResume?.shoppingCart?._id && (
          <div className="my-4">
            <AppButtonCompany
              className="flex-grow-0 w-full font-bold"
              isFullWidth={true}
              icon={AppIcons.actionBack}
              onClick={(e) => {
                e.stopPropagation();
                goBackToShoppingCart({
                  shoppingCartId: shoppingCartPaymentResume.shoppingCart._id,
                });
              }}
              style={'cancel'}
            >
              Retour au panier
            </AppButtonCompany>
          </div>
        )}
        {pageMode.showDebugArea && debugMessages?.length > 0 && (
          <div className="my-2 divide-y text-xs">
            {debugMessages.map((message, index) => (
              <div key={index}>{message}</div>
            ))}
          </div>
        )}
        <div className="flex-grow"></div>
        <div className="my-4">
          <AppButtonCompany
            className="flex-grow-0 w-full font-bold"
            isFullWidth={true}
            icon={AppIcons.actionBack}
            onClick={(e) => {
              e.stopPropagation();
              goBackToActivities();
            }}
            style={'cancel'}
          >
            Continuer mes achats
          </AppButtonCompany>
        </div>
      </PageContainer>
    </AppPage>
  );
};
