import { IonButton, IonIcon } from '@ionic/react';
import {
  dataSorter,
  dateTester,
  dateTransformer,
  useAppModalContext,
} from '_01_CORE/_components-core';
import { useAppRouter, useAppToasts } from '_01_CORE/_hooks';
import { appRoutesBuilder } from '_01_CORE/_pages-routes';
import {
  useAppCacheContext,
  useAppLocalizationContext,
  useJourney,
  useTimeLimit,
} from '_01_CORE/app-contexts';
import { appWebConfig } from '_01_CORE/app-core/app-config';
import { useNetworkStatus } from '_01_CORE/app-core/app-platform';
import { useAppSettings } from '_01_CORE/app-core/app-security';
import { useDeleteJourneyBookingAccroparkConfirm } from '_02_APP_COMMON/JOU-journey/_common';
import { format as dateFormat } from 'date-fns';
import { checkmark } from 'ionicons/icons';
import {
  ActivityCategory,
  ActivitySessionParticipantRegistrationStatus,
  CMJourney,
  CMJourneyActivities,
  CMProfileActivitySessionToDisplay,
} from 'lib-common-model';
import { appLogger } from 'lib-web-logger';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router';
import {
  ActivityPendingOperationDef,
  CustomerActivityParticipantsDailyActivityDisplayMode,
  CustomerJourneyActivitiesAggregatedData,
  CustomerJourneyActivitiesLocalFilters,
} from '../model';
import {
  CustomerJourneyActivitiesPageUrlParams,
  customerJourneyActivitiesDataAggregator,
  customerJourneyActivitiesPageUrlManager,
} from '../services';
import {
  JourneyActivitiesRepository,
  useJourneyActivitiesRepository,
} from './useJourneyActivitiesRepository.hook';

export type ActivitySessionDetailsDialogInputState = {
  isOpen: boolean;
  activityId?: string;
  activitySessionId?: string;
  displayMode?: CustomerActivityParticipantsDailyActivityDisplayMode;
};

export function useJourneyActivitiesLocalState() {
  const appRouter = useAppRouter();
  const { formatMessage: t } = useIntl();

  const { networkStatus } = useNetworkStatus();
  const { pushError } = useAppToasts();

  const appSettings = useAppSettings();

  const { customerJourneyId } = useParams<{ customerJourneyId: string }>();

  const appCacheContext = useAppCacheContext();
  const { auth, dispatchCacheContextAction } = useAppCacheContext();
  const customerAccountId = auth?.customerProfile?.account?._id;

  const { journey } = useJourney({ customerJourneyId });
  const { openTimeLimitWarningModal, openTimeLimitOverModal } = useTimeLimit();
  const deleteJourneyBookingConfirm = useDeleteJourneyBookingAccroparkConfirm({
    customerJourneyId,
  });

  const [pendingOperations, setPendingOperations] = useState<
    ActivityPendingOperationDef[]
  >([]);

  useEffect(() => {
    if (!journey) {
      appLogger.info(
        '[useJourneyActivitiesLocalState] No company in context: redirect to /journey'
      );
      appRouter.navigateAfterDelay(appRoutesBuilder.getRedirectToDefaultUrl(), {
        cause: 'joruney-missing',
      });
    }
  }, [appRouter, journey]);

  const defaultDate = useMemo(() => getDefaultSelectedDate(journey), [journey]);
  const initialParameters: CustomerJourneyActivitiesPageUrlParams = useMemo(
    () =>
      customerJourneyActivitiesPageUrlManager.getQueryParameters({
        category: 'animator',
        selectedDate: defaultDate,
      }),
    [defaultDate]
  );

  // const [category, setCategory] = useState<ActivityCategory>(
  //   initialParameters.category
  // );
  // const [selectedDate, setSelectedDate] = useState(defaultDate);

  const [params, setParams] = useState<CustomerJourneyActivitiesPageUrlParams>(
    initialParameters
  );

  const { category, selectedDate } = params;

  const activitySessionDetailsDialog: ActivitySessionDetailsDialogInputState = useMemo(() => {
    if (params.focusActivityOpenDialog) {
      return {
        isOpen: true,
        activityId: params.focusActivityId,
        activitySessionId: params.focusActivitySessionId,
      };
    }
    return {
      isOpen: false,
    };
  }, [
    params.focusActivityId,
    params.focusActivityOpenDialog,
    params.focusActivitySessionId,
  ]);

  const setActivitySessionDetailsDialog = useCallback(
    (inputState: ActivitySessionDetailsDialogInputState) => {
      setParams({
        ...params,
        focusActivityOpenDialog: inputState.isOpen,
        focusActivityId: inputState.activityId,
        focusActivitySessionId: inputState.activitySessionId,
      });
    },
    [params]
  );
  const setSelectedDate = useCallback(
    (selectedDate: Date) => {
      setParams({
        ...params,
        selectedDate,
      });
    },
    [params]
  );
  const setCategory = useCallback(
    (category: ActivityCategory) => {
      setParams({
        ...params,
        category,
      });
    },
    [params]
  );

  const history = useHistory();
  useEffect(() => {
    // update url params
    const searchParams: string[] = customerJourneyActivitiesPageUrlManager.buildSearchParams(
      params
    );

    history.replace({
      pathname: window.location.pathname,
      search: searchParams?.length ? `?${searchParams.join('&')}` : undefined,
    });
  }, [history, params]);

  const journeyDateText = useMemo<string>(() => {
    const date = dateFormat(
      selectedDate ?? new Date(),
      t({ id: 'common.date.format' })
    );
    return date;
  }, [selectedDate, t]);

  function getDefaultSelectedDate(journey: CMJourney) {
    const now = dateTransformer.getUTCDateSetTime(new Date());
    return dateTester.isDateWithinRange(now, {
      minDate: journey?.beginDateUTC,
      maxDateExclusive: journey?.endDateExclusiveUTC,
    })
      ? now
      : journey?.beginDateUTC;
  }

  const customerAccount = auth?.customerProfile?.account;
  const [
    customerJourneyActivitiesBeforeFilter,
    setCustomerJourneyActivitiesInner,
  ] = useState<CMJourneyActivities>(undefined);

  const {
    locales: { platformLanguageCode, appLanguageCode },
  } = useAppLocalizationContext();

  const journeyActivitiesRepo: JourneyActivitiesRepository = useJourneyActivitiesRepository(
    {
      customerAccountId,
      customerJourneyId,
      date: selectedDate,
      platformLanguageCode,
      appLanguageCode,
    }
  );

  const updateActivitySessionsToDisplay = useCallback(
    ({
      companyReference,
      activitySessionsToDisplay,
    }: {
      companyReference: string;
      activitySessionsToDisplay: CMProfileActivitySessionToDisplay[];
    }) => {
      // const {
      //   selectedDate,
      //   setSelectedDate,
      //   openActivityDetailsDialog,
      // } = localState;
      if (activitySessionsToDisplay?.length) {
        const {
          activityId,
          activitySessionId,
          beginDateTime,
        } = activitySessionsToDisplay[0];
        if (activitySessionId !== params.focusActivitySessionId) {
          if (dateTester.isSameDay(beginDateTime, selectedDate)) {
            //  même jour: on ouvre direct le dialog

            // openActivityDetailsDialog({
            //   activityId,
            //   activitySessionId,
            // });
            // setSelectedDate(beginDateTime);
            dispatchCacheContextAction({
              type: 'set-fetched-data',
              fetchedData: {
                activitySessionsToDisplay,
              },
              companyReference,
              context: 'update-activity-sessions-to-display',
            });
          } else {
            // setSelectedDate(beginDateTime);
            // autre jour, on redirige
            dispatchCacheContextAction({
              type: 'set-fetched-data',
              fetchedData: {
                activitySessionsToDisplay,
              },
              companyReference,
              context: 'update-activity-sessions-to-display',
            });
          }
        }
      }
    },
    [dispatchCacheContextAction, params.focusActivitySessionId, selectedDate]
  );

  useEffect(() => {
    if (
      journeyActivitiesRepo.state.isLoaded &&
      journeyActivitiesRepo.state.criteriaKey
    ) {
      const { activitySessionsToDisplay } = journeyActivitiesRepo.state.data;

      // force l'affichage d'une activité (place dispo sur liste d'attente)
      updateActivitySessionsToDisplay({
        activitySessionsToDisplay,
        companyReference: journey?.company?.reference,
      });
    }
  }, [
    journey?.company?.reference,
    journeyActivitiesRepo.state.criteriaKey,
    journeyActivitiesRepo.state.data,
    journeyActivitiesRepo.state.isLoaded,
    updateActivitySessionsToDisplay,
  ]);

  const [
    localFilters,
    setLocalFilters,
  ] = useState<CustomerJourneyActivitiesLocalFilters>({
    searchText: '',
    selectedChildId: undefined,
    selectedActivityId: undefined,
    registeredOrPresentOnly: false,
    lastSelectedSessionId: undefined,
  });

  const setLastSelectedSessionId = useCallback(
    (lastSelectedSessionId: string) => {
      setLocalFilters({
        ...localFilters,
        lastSelectedSessionId,
      });
    },
    [localFilters]
  );

  const isSearchTextActive = localFilters.searchText?.trim()?.length;

  const hasAnyFilter =
    localFilters.selectedChildId ||
    localFilters.selectedActivityId ||
    localFilters.registeredOrPresentOnly;

  const appId = appWebConfig().appId;

  // const appRouter = useAppRouter();

  const aggregatedData: CustomerJourneyActivitiesAggregatedData = useMemo(
    () =>
      customerJourneyActivitiesDataAggregator.aggregateData({
        fetchedData: journeyActivitiesRepo.state.data,
        customerJourney: journey,
        localFilters,
        params,
        pendingOperations,
        appSettings,
      }),
    [
      journey,
      journeyActivitiesRepo.state.data,
      localFilters,
      params,
      pendingOperations,
      appSettings,
    ]
  );

  const openActivityDetailsDialog = useCallback(
    ({
      activityId,
      activitySessionId,
    }: // displayMode,
    {
      activityId: string;
      activitySessionId: string;
      // une activité qui a des participants est affiché à la fois comme "single" et "multiple", donc pour la différencier, on utiliser le "displayMode"
      // displayMode: CustomerActivityParticipantsDailyActivityDisplayMode;
    }) => {
      // on réfraichi l'activité, en asynchrone
      journeyActivitiesRepo.actions
        .refreshPageActivities({
          cause: 'activity-open-details',
          activitySessionId,
        })
        .catch();
      setLastSelectedSessionId(activitySessionId);
      setActivitySessionDetailsDialog({
        isOpen: true,
        activityId,
        activitySessionId,
      });

      // setActivitySessionDetailsDialog({
      //   isOpen: false,
      // });
      // setActivitySessionDetailsDialog({
      //   isOpen: true,
      //   activityId,
      //   activitySessionId,
      //   displayMode,
      // });
    },
    [
      journeyActivitiesRepo.actions,
      setActivitySessionDetailsDialog,
      setLastSelectedSessionId,
    ]
  );
  const closeActivityDetailsDialog = useCallback(() => {
    setActivitySessionDetailsDialog({
      isOpen: false,
    });
  }, [setActivitySessionDetailsDialog]);

  const activitySessionDetailsDialogDailyActivity = useMemo(() => {
    // inputState.activitySessionId

    const activitySessionId =
      localFilters?.lastSelectedSessionId ??
      activitySessionDetailsDialog?.activitySessionId;

    if (
      activitySessionDetailsDialog.isOpen &&
      activitySessionId &&
      aggregatedData?.dailyActivities
    ) {
      const matchingActivities = aggregatedData?.dailyActivities.filter(
        (da) =>
          da.activity._id === activitySessionDetailsDialog?.activityId &&
          !!da.sessions.find(
            (s) => s.activitySession?.activitySessionId === activitySessionId
          )
      );

      const sortedMatchingActivities = dataSorter.sortMultiple(
        matchingActivities,
        {
          getSortAttributes: (x) => [
            {
              // si activité en double (multiple et simple), on sélectionne en priorité celle qui est multiple
              value: x.displayMode === 'multiple' ? 1 : 2,
            },
          ],
        }
      );

      const activitySessionDetailsDialogDailyActivity =
        sortedMatchingActivities.length > 0
          ? sortedMatchingActivities[0]
          : undefined;

      if (activitySessionDetailsDialogDailyActivity) {
        return {
          ...activitySessionDetailsDialogDailyActivity,
          selectedSession: activitySessionDetailsDialogDailyActivity.sessions.find(
            (s) => s.activitySession?.activitySessionId === activitySessionId
          ),
        };
      } else if (customerJourneyActivitiesBeforeFilter) {
        const activity = customerJourneyActivitiesBeforeFilter.activities.find(
          (x) => x._id === activitySessionDetailsDialog?.activityId
        );
        if (activity) {
          console.info('Activity FOUND in non-filtered activites', activity);
          const categories = activity.categories;
          if (categories.length) {
            if (!categories.includes(category)) {
              // l'activité n'est pas dans la categorie active: on switch sur la première
              console.info('Switch activity category');
              setCategory(categories[0]);
            }
          } else {
            console.error(`Activity ${activity._id} without category`);
          }
        } else {
          console.error('Activity NOT found in non-filtered activites');
        }
      }
    }
  }, [
    activitySessionDetailsDialog?.activityId,
    activitySessionDetailsDialog?.activitySessionId,
    activitySessionDetailsDialog.isOpen,
    aggregatedData?.dailyActivities,
    category,
    customerJourneyActivitiesBeforeFilter,
    localFilters?.lastSelectedSessionId,
    setCategory,
  ]);

  const isJourneyReplacementInProgress = useMemo(
    () =>
      journey?.status !== 'validated' &&
      !!journey?.replaceFromCustomerJourneyId,
    [journey?.replaceFromCustomerJourneyId, journey?.status]
  );

  const { openModal, closeModal } = useAppModalContext();

  const openWarningModal = useCallback(
    ({ title = '', message = '' }: { title?: string; message?: string }) => {
      openModal({
        title,
        content: <div>{message}</div>,
        actions: (
          <IonButton className="w-full" color="primary" onClick={closeModal}>
            <IonIcon icon={checkmark} className="mr-2" />
            {t({ id: 'common.action.ok' })}
          </IonButton>
        ),
      });
    },
    [closeModal, openModal, t]
  );

  const openWarningModalFromCause = useCallback(
    (
      cause: ActivitySessionParticipantRegistrationStatus = 'registration-full'
    ) => {
      const message = t({
        id: `page16.message.activity-registration-disabled.cause-id-${cause}`,
      });
      openWarningModal({ message });
    },
    [openWarningModal, t]
  );

  const { scrollToTop } = useAppRouter();

  const setCategoryAndScroll = useCallback(
    (category: ActivityCategory) => {
      appRouter.navigate(
        appRoutesBuilder.getJourneyActivitiesUrl({
          customerJourneyId: journey?._id,
          category: 'animator',
        }),
        { cause: 'set-company-category' }
      );
      setCategory(category);
      scrollToTop();
    },
    [appRouter, journey?._id, scrollToTop, setCategory]
  );

  const localState = {
    setCategoryAndScroll,
    openWarningModal,
    openWarningModalFromCause,
    aggregatedData,
    networkStatus,
    pushError,
    customerJourneyId,
    journey,
    openTimeLimitWarningModal,
    openTimeLimitOverModal,
    deleteJourneyBookingConfirm,
    defaultDate,
    selectedDate,
    journeyDateText,
    customerAccount,
    hasAnyFilter,
    localFilters,
    setLocalFilters,
    appId,
    setLastSelectedSessionId,
    activitySessionDetailsDialogDailyActivity,
    isJourneyReplacementInProgress,
    activitySessionDetailsDialog,
    setActivitySessionDetailsDialog,
    category,
    setParams,
    setSelectedDate,
    setCategory,
    companyOptions: journey?.company?.options,
    openActivityDetailsDialog,
    closeActivityDetailsDialog,
    updateActivitySessionsToDisplay,
    isSearchTextActive,
    pendingOperations,
    setPendingOperations,
    journeyActivitiesRepo,
  };
  return localState;
}
