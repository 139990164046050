import { IonLabel, IonToggle } from '@ionic/react';
import { UseFormReturn, useController } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { AccroparkBookingOptionsFormModel } from '.';

export const AccroparkBookingOptionsWeatherAssuranceCard = ({
  currencySymbol,
  form,
}: {
  currencySymbol: string;
  form: UseFormReturn<AccroparkBookingOptionsFormModel>;
}) => {
  const { formatMessage: t } = useIntl();

  const { control, register, formState, handleSubmit } = form;

  const {
    field: { onChange, onBlur, name, value, ref },
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name: 'weatherInsuranceEnabled',
    control,
    rules: { required: false },
    // defaultValue: false,
  });

  return (
    <div className="w-full text-center border-2 border-weather bg-white rounded-md py-2 px-1 mb-4 ml:items-stretch">
      <div className="flex items-center gap-2">
        <img src="/assets/images/sun.png" className="w-16 h-16" />
        <div className="font-semibold text-blue-400">
          {t({
            id: 'accropark.ecommerce.booking-card.offer.weather-insurance',
          })}
        </div>
      </div>
      <hr className="my-1 w-full" />
      <div className="flex-1">
        <div className="text-sm px-2 mb-2">
          {t({ id: 'page17.weather.explanation' })}
        </div>
        <div className="flex items-center font-bold justify-center ml:justify-start">
          <IonToggle
            checked={value}
            onIonChange={(e) => onChange(e.detail.checked)}
          />
          <IonLabel>
            {t(
              { id: 'page17.people.price-unit-type-participant' },
              { price: `20 ${currencySymbol}` }
            )}
          </IonLabel>
        </div>
      </div>
    </div>
  );
};
